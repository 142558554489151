import * as React from 'react';
import Svg, { Mask, Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const PetEmergencyAppointmentIcon = ({ width = 24, height = 24, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Mask
        id="path-1-outside-1_8450_2805"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
        fill="black">
        <Rect fill="white" x="1" y="1" width="22" height="22" />
        <Path d="M15.1958 8.53481C15.0916 8.53483 14.9877 8.52596 14.8851 8.5083C13.5522 8.28193 12.769 6.68132 13.1019 4.8641C13.4348 3.04688 14.7419 1.79959 16.0733 2.02666C17.4062 2.25302 18.1893 3.85364 17.8561 5.67086C17.7259 6.46458 17.3571 7.20431 16.7952 7.79885C16.5948 8.01985 16.3495 8.19913 16.0745 8.32568C15.7995 8.45224 15.5005 8.5234 15.1958 8.53481ZM15.7687 2.71516C14.929 2.71516 14.0689 3.70259 13.8329 4.98825C13.5812 6.36354 14.1222 7.65339 15.0142 7.80512C15.2486 7.82756 15.4852 7.79353 15.7026 7.70609C15.9199 7.61865 16.1113 7.48055 16.2593 7.30424C16.7197 6.81203 17.0207 6.20116 17.1251 5.54667C17.3771 4.17137 16.8365 2.88153 15.9442 2.72981C15.8863 2.72007 15.8275 2.71517 15.7687 2.71516Z" />
        <Path d="M19.4957 12.4919C19.321 12.4922 19.1476 12.4629 18.9834 12.4054C17.9475 12.0426 17.549 10.6656 18.0769 9.27005C18.6049 7.87452 19.8304 7.06497 20.866 7.42877C21.137 7.53535 21.3757 7.70591 21.5602 7.92471C21.7446 8.1435 21.8687 8.40346 21.9211 8.68059C22.0638 9.30891 22.0121 9.96369 21.7724 10.5641C21.3297 11.7343 20.3964 12.4919 19.4957 12.4919ZM20.3569 8.05766C19.776 8.05766 19.0983 8.65863 18.7746 9.51422C18.4022 10.4992 18.6139 11.516 19.2372 11.7343C19.862 11.9523 20.7021 11.3049 21.0748 10.3199C21.2642 9.85248 21.3077 9.34202 21.1999 8.85115C21.1741 8.68895 21.1069 8.53544 21.0043 8.40433C20.9018 8.27322 20.767 8.16861 20.6122 8.09984C20.5303 8.07153 20.4439 8.05726 20.3569 8.05766Z" />
        <Path d="M4.50425 12.4919C3.60359 12.4919 2.67066 11.7343 2.22758 10.5641C1.98786 9.96369 1.93618 9.30891 2.07892 8.68059C2.1313 8.40346 2.25543 8.1435 2.43984 7.92471C2.62425 7.70591 2.863 7.53535 3.13404 7.42877C4.16777 7.06497 5.3944 7.87451 5.92305 9.27005C6.45098 10.6656 6.0525 12.0426 5.01659 12.4054C4.85242 12.4629 4.67899 12.4922 4.50425 12.4919ZM2.92519 10.3199C3.29793 11.3049 4.13877 11.9526 4.76278 11.7343C5.38606 11.516 5.59781 10.4992 5.22544 9.51422C4.8527 8.52923 4.01186 7.88256 3.38785 8.09985C3.23298 8.16861 3.09824 8.27323 2.99568 8.40434C2.89311 8.53544 2.82592 8.68896 2.8001 8.85115C2.69231 9.34202 2.73578 9.85248 2.92519 10.3199Z" />
        <Path d="M8.80417 8.53481C8.49953 8.5234 8.20052 8.45223 7.9255 8.32567C7.65047 8.19911 7.40523 8.01984 7.20481 7.79884C6.64288 7.20429 6.27408 6.46457 6.14389 5.67084C5.81065 3.85362 6.59384 2.253 7.92671 2.02664C9.25778 1.79992 10.5649 3.04686 10.8981 4.86408C11.231 6.6813 10.4478 8.28192 9.1149 8.50828C9.01234 8.52595 8.90836 8.53483 8.80417 8.53481ZM8.23128 2.71516C8.17245 2.71517 8.11374 2.72007 8.05579 2.7298C7.16346 2.88152 6.62285 4.17137 6.87484 5.54666C6.97931 6.20115 7.28025 6.81203 7.74069 7.30424C7.88899 7.48026 8.08036 7.61815 8.29766 7.70557C8.51496 7.79298 8.75141 7.82719 8.98582 7.80512C9.87778 7.65339 10.4188 6.36355 10.1671 4.98826C9.93108 3.70259 9.07103 2.71516 8.23128 2.71516Z" />
        <Path d="M16.9634 22C16.4847 22 16.0075 21.9477 15.541 21.8441C14.9313 21.6788 14.3483 21.4335 13.8082 21.1151C13.1846 20.7841 12.5957 20.4712 12.0384 20.4712C11.4811 20.4712 10.8923 20.7841 10.2686 21.1151C9.7287 21.4335 9.14574 21.6788 8.53622 21.8441C7.07826 22.1706 4.97018 22.0687 3.83274 20.7032C2.91539 19.602 2.80734 17.6627 4.10794 16.5313C4.48633 16.2205 4.89408 15.9445 5.32586 15.7067C5.93732 15.4081 6.47002 14.9794 6.88354 14.4531C7.14945 14.0169 7.36097 13.552 7.51372 13.0681C7.6929 12.4965 7.95081 11.9505 8.28059 11.4448C9.00142 10.4319 10.3361 9.87416 12.0388 9.87416C13.9043 9.87452 15.198 10.411 15.8848 11.4685C16.1758 11.958 16.3996 12.4817 16.5505 13.0266C16.6907 13.5587 16.9152 14.0669 17.2162 14.5334C17.6325 15.0916 18.1939 15.535 18.8442 15.819C19.2485 16.0104 19.6264 16.2497 19.9689 16.5313C21.2695 17.6628 21.1611 19.602 20.2441 20.7032C19.4432 21.6648 18.1611 22 16.9634 22ZM12.0384 19.7569C12.7872 19.7569 13.4888 20.1294 14.1668 20.4897C14.6484 20.7743 15.1672 20.9958 15.7096 21.1486C16.9566 21.4287 18.7427 21.3628 19.6647 20.2567C20.3558 19.4266 20.4464 17.91 19.4707 17.0607C19.1718 16.8184 18.843 16.6125 18.4917 16.4475C17.7393 16.1107 17.0903 15.5924 16.6077 14.9428C16.2615 14.4169 16.003 13.8421 15.8416 13.2393C15.7086 12.753 15.5114 12.2851 15.2549 11.8469C14.7129 11.0123 13.6309 10.5888 12.0388 10.5885C11.199 10.5885 9.67329 10.7521 8.89336 11.8483C8.60148 12.3025 8.37337 12.7918 8.21496 13.3035C8.0436 13.8409 7.80625 14.3567 7.50792 14.8399C7.0369 15.4589 6.42285 15.9641 5.71421 16.3157C5.32172 16.5306 4.95078 16.78 4.60614 17.0607C3.63042 17.9097 3.72071 19.4262 4.41216 20.2567C5.33349 21.3627 7.11778 21.4283 8.36761 21.1486C8.90987 20.9958 9.42857 20.7742 9.91005 20.4897C10.5881 20.1294 11.2897 19.7569 12.0384 19.7569Z" />
        <Path d="M13.0076 18.609H10.9924C10.8666 18.6089 10.746 18.5608 10.657 18.4753C10.568 18.3898 10.5179 18.2738 10.5177 18.1528V16.999H9.31831C9.19253 16.9988 9.07195 16.9506 8.98304 16.865C8.89413 16.7794 8.84415 16.6634 8.84405 16.5424V14.6038C8.84415 14.4828 8.89413 14.3668 8.98304 14.2812C9.07195 14.1956 9.19253 14.1474 9.31831 14.1472H10.5177V12.9934C10.5179 12.8724 10.568 12.7564 10.657 12.6709C10.746 12.5854 10.8666 12.5373 10.9924 12.5372H13.0076C13.1334 12.5373 13.254 12.5854 13.343 12.6709C13.432 12.7564 13.4821 12.8724 13.4823 12.9934V14.1472H14.6817C14.8075 14.1474 14.928 14.1956 15.017 14.2812C15.1059 14.3668 15.1559 14.4828 15.156 14.6038V16.5424C15.1559 16.6634 15.1059 16.7794 15.017 16.865C14.928 16.9506 14.8075 16.9988 14.6817 16.999H13.4823V18.1528C13.4821 18.2738 13.432 18.3898 13.343 18.4753C13.254 18.5608 13.1334 18.6089 13.0076 18.609ZM11.2603 17.8947H12.7397V16.6418C12.7396 16.5949 12.7492 16.5485 12.7679 16.5051C12.7865 16.4618 12.8139 16.4224 12.8484 16.3892C12.8828 16.356 12.9238 16.3297 12.9688 16.3118C13.0139 16.2938 13.0622 16.2846 13.111 16.2847H14.4134V14.8616H13.111C13.0622 14.8616 13.0139 14.8524 12.9688 14.8344C12.9238 14.8165 12.8828 14.7902 12.8484 14.757C12.8139 14.7239 12.7865 14.6845 12.7679 14.6411C12.7492 14.5978 12.7396 14.5513 12.7397 14.5044V13.2515H11.2603V14.5044C11.2604 14.5513 11.2508 14.5978 11.2321 14.6411C11.2135 14.6845 11.1861 14.7239 11.1516 14.757C11.1172 14.7902 11.0762 14.8165 11.0312 14.8344C10.9861 14.8524 10.9378 14.8616 10.889 14.8616H9.58663V16.2847H10.889C10.9378 16.2846 10.9861 16.2938 11.0312 16.3118C11.0762 16.3297 11.1172 16.356 11.1516 16.3892C11.1861 16.4224 11.2135 16.4618 11.2321 16.5051C11.2508 16.5485 11.2604 16.5949 11.2603 16.6418V17.8947Z" />
      </Mask>
      <Path
        d="M15.1958 8.53481C15.0916 8.53483 14.9877 8.52596 14.8851 8.5083C13.5522 8.28193 12.769 6.68132 13.1019 4.8641C13.4348 3.04688 14.7419 1.79959 16.0733 2.02666C17.4062 2.25302 18.1893 3.85364 17.8561 5.67086C17.7259 6.46458 17.3571 7.20431 16.7952 7.79885C16.5948 8.01985 16.3495 8.19913 16.0745 8.32568C15.7995 8.45224 15.5005 8.5234 15.1958 8.53481ZM15.7687 2.71516C14.929 2.71516 14.0689 3.70259 13.8329 4.98825C13.5812 6.36354 14.1222 7.65339 15.0142 7.80512C15.2486 7.82756 15.4852 7.79353 15.7026 7.70609C15.9199 7.61865 16.1113 7.48055 16.2593 7.30424C16.7197 6.81203 17.0207 6.20116 17.1251 5.54667C17.3771 4.17137 16.8365 2.88153 15.9442 2.72981C15.8863 2.72007 15.8275 2.71517 15.7687 2.71516Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M19.4957 12.4919C19.321 12.4922 19.1476 12.4629 18.9834 12.4054C17.9475 12.0426 17.549 10.6656 18.0769 9.27005C18.6049 7.87452 19.8304 7.06497 20.866 7.42877C21.137 7.53535 21.3757 7.70591 21.5602 7.92471C21.7446 8.1435 21.8687 8.40346 21.9211 8.68059C22.0638 9.30891 22.0121 9.96369 21.7724 10.5641C21.3297 11.7343 20.3964 12.4919 19.4957 12.4919ZM20.3569 8.05766C19.776 8.05766 19.0983 8.65863 18.7746 9.51422C18.4022 10.4992 18.6139 11.516 19.2372 11.7343C19.862 11.9523 20.7021 11.3049 21.0748 10.3199C21.2642 9.85248 21.3077 9.34202 21.1999 8.85115C21.1741 8.68895 21.1069 8.53544 21.0043 8.40433C20.9018 8.27322 20.767 8.16861 20.6122 8.09984C20.5303 8.07153 20.4439 8.05726 20.3569 8.05766Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M4.50425 12.4919C3.60359 12.4919 2.67066 11.7343 2.22758 10.5641C1.98786 9.96369 1.93618 9.30891 2.07892 8.68059C2.1313 8.40346 2.25543 8.1435 2.43984 7.92471C2.62425 7.70591 2.863 7.53535 3.13404 7.42877C4.16777 7.06497 5.3944 7.87451 5.92305 9.27005C6.45098 10.6656 6.0525 12.0426 5.01659 12.4054C4.85242 12.4629 4.67899 12.4922 4.50425 12.4919ZM2.92519 10.3199C3.29793 11.3049 4.13877 11.9526 4.76278 11.7343C5.38606 11.516 5.59781 10.4992 5.22544 9.51422C4.8527 8.52923 4.01186 7.88256 3.38785 8.09985C3.23298 8.16861 3.09824 8.27323 2.99568 8.40434C2.89311 8.53544 2.82592 8.68896 2.8001 8.85115C2.69231 9.34202 2.73578 9.85248 2.92519 10.3199Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M8.80417 8.53481C8.49953 8.5234 8.20052 8.45223 7.9255 8.32567C7.65047 8.19911 7.40523 8.01984 7.20481 7.79884C6.64288 7.20429 6.27408 6.46457 6.14389 5.67084C5.81065 3.85362 6.59384 2.253 7.92671 2.02664C9.25778 1.79992 10.5649 3.04686 10.8981 4.86408C11.231 6.6813 10.4478 8.28192 9.1149 8.50828C9.01234 8.52595 8.90836 8.53483 8.80417 8.53481ZM8.23128 2.71516C8.17245 2.71517 8.11374 2.72007 8.05579 2.7298C7.16346 2.88152 6.62285 4.17137 6.87484 5.54666C6.97931 6.20115 7.28025 6.81203 7.74069 7.30424C7.88899 7.48026 8.08036 7.61815 8.29766 7.70557C8.51496 7.79298 8.75141 7.82719 8.98582 7.80512C9.87778 7.65339 10.4188 6.36355 10.1671 4.98826C9.93108 3.70259 9.07103 2.71516 8.23128 2.71516Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M16.9634 22C16.4847 22 16.0075 21.9477 15.541 21.8441C14.9313 21.6788 14.3483 21.4335 13.8082 21.1151C13.1846 20.7841 12.5957 20.4712 12.0384 20.4712C11.4811 20.4712 10.8923 20.7841 10.2686 21.1151C9.7287 21.4335 9.14574 21.6788 8.53622 21.8441C7.07826 22.1706 4.97018 22.0687 3.83274 20.7032C2.91539 19.602 2.80734 17.6627 4.10794 16.5313C4.48633 16.2205 4.89408 15.9445 5.32586 15.7067C5.93732 15.4081 6.47002 14.9794 6.88354 14.4531C7.14945 14.0169 7.36097 13.552 7.51372 13.0681C7.6929 12.4965 7.95081 11.9505 8.28059 11.4448C9.00142 10.4319 10.3361 9.87416 12.0388 9.87416C13.9043 9.87452 15.198 10.411 15.8848 11.4685C16.1758 11.958 16.3996 12.4817 16.5505 13.0266C16.6907 13.5587 16.9152 14.0669 17.2162 14.5334C17.6325 15.0916 18.1939 15.535 18.8442 15.819C19.2485 16.0104 19.6264 16.2497 19.9689 16.5313C21.2695 17.6628 21.1611 19.602 20.2441 20.7032C19.4432 21.6648 18.1611 22 16.9634 22ZM12.0384 19.7569C12.7872 19.7569 13.4888 20.1294 14.1668 20.4897C14.6484 20.7743 15.1672 20.9958 15.7096 21.1486C16.9566 21.4287 18.7427 21.3628 19.6647 20.2567C20.3558 19.4266 20.4464 17.91 19.4707 17.0607C19.1718 16.8184 18.843 16.6125 18.4917 16.4475C17.7393 16.1107 17.0903 15.5924 16.6077 14.9428C16.2615 14.4169 16.003 13.8421 15.8416 13.2393C15.7086 12.753 15.5114 12.2851 15.2549 11.8469C14.7129 11.0123 13.6309 10.5888 12.0388 10.5885C11.199 10.5885 9.67329 10.7521 8.89336 11.8483C8.60148 12.3025 8.37337 12.7918 8.21496 13.3035C8.0436 13.8409 7.80625 14.3567 7.50792 14.8399C7.0369 15.4589 6.42285 15.9641 5.71421 16.3157C5.32172 16.5306 4.95078 16.78 4.60614 17.0607C3.63042 17.9097 3.72071 19.4262 4.41216 20.2567C5.33349 21.3627 7.11778 21.4283 8.36761 21.1486C8.90987 20.9958 9.42857 20.7742 9.91005 20.4897C10.5881 20.1294 11.2897 19.7569 12.0384 19.7569Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M13.0076 18.609H10.9924C10.8666 18.6089 10.746 18.5608 10.657 18.4753C10.568 18.3898 10.5179 18.2738 10.5177 18.1528V16.999H9.31831C9.19253 16.9988 9.07195 16.9506 8.98304 16.865C8.89413 16.7794 8.84415 16.6634 8.84405 16.5424V14.6038C8.84415 14.4828 8.89413 14.3668 8.98304 14.2812C9.07195 14.1956 9.19253 14.1474 9.31831 14.1472H10.5177V12.9934C10.5179 12.8724 10.568 12.7564 10.657 12.6709C10.746 12.5854 10.8666 12.5373 10.9924 12.5372H13.0076C13.1334 12.5373 13.254 12.5854 13.343 12.6709C13.432 12.7564 13.4821 12.8724 13.4823 12.9934V14.1472H14.6817C14.8075 14.1474 14.928 14.1956 15.017 14.2812C15.1059 14.3668 15.1559 14.4828 15.156 14.6038V16.5424C15.1559 16.6634 15.1059 16.7794 15.017 16.865C14.928 16.9506 14.8075 16.9988 14.6817 16.999H13.4823V18.1528C13.4821 18.2738 13.432 18.3898 13.343 18.4753C13.254 18.5608 13.1334 18.6089 13.0076 18.609ZM11.2603 17.8947H12.7397V16.6418C12.7396 16.5949 12.7492 16.5485 12.7679 16.5051C12.7865 16.4618 12.8139 16.4224 12.8484 16.3892C12.8828 16.356 12.9238 16.3297 12.9688 16.3118C13.0139 16.2938 13.0622 16.2846 13.111 16.2847H14.4134V14.8616H13.111C13.0622 14.8616 13.0139 14.8524 12.9688 14.8344C12.9238 14.8165 12.8828 14.7902 12.8484 14.757C12.8139 14.7239 12.7865 14.6845 12.7679 14.6411C12.7492 14.5978 12.7396 14.5513 12.7397 14.5044V13.2515H11.2603V14.5044C11.2604 14.5513 11.2508 14.5978 11.2321 14.6411C11.2135 14.6845 11.1861 14.7239 11.1516 14.757C11.1172 14.7902 11.0762 14.8165 11.0312 14.8344C10.9861 14.8524 10.9378 14.8616 10.889 14.8616H9.58663V16.2847H10.889C10.9378 16.2846 10.9861 16.2938 11.0312 16.3118C11.0762 16.3297 11.1172 16.356 11.1516 16.3892C11.1861 16.4224 11.2135 16.4618 11.2321 16.5051C11.2508 16.5485 11.2604 16.5949 11.2603 16.6418V17.8947Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M15.1958 8.53481C15.0916 8.53483 14.9877 8.52596 14.8851 8.5083C13.5522 8.28193 12.769 6.68132 13.1019 4.8641C13.4348 3.04688 14.7419 1.79959 16.0733 2.02666C17.4062 2.25302 18.1893 3.85364 17.8561 5.67086C17.7259 6.46458 17.3571 7.20431 16.7952 7.79885C16.5948 8.01985 16.3495 8.19913 16.0745 8.32568C15.7995 8.45224 15.5005 8.5234 15.1958 8.53481ZM15.7687 2.71516C14.929 2.71516 14.0689 3.70259 13.8329 4.98825C13.5812 6.36354 14.1222 7.65339 15.0142 7.80512C15.2486 7.82756 15.4852 7.79353 15.7026 7.70609C15.9199 7.61865 16.1113 7.48055 16.2593 7.30424C16.7197 6.81203 17.0207 6.20116 17.1251 5.54667C17.3771 4.17137 16.8365 2.88153 15.9442 2.72981C15.8863 2.72007 15.8275 2.71517 15.7687 2.71516Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
      <Path
        d="M19.4957 12.4919C19.321 12.4922 19.1476 12.4629 18.9834 12.4054C17.9475 12.0426 17.549 10.6656 18.0769 9.27005C18.6049 7.87452 19.8304 7.06497 20.866 7.42877C21.137 7.53535 21.3757 7.70591 21.5602 7.92471C21.7446 8.1435 21.8687 8.40346 21.9211 8.68059C22.0638 9.30891 22.0121 9.96369 21.7724 10.5641C21.3297 11.7343 20.3964 12.4919 19.4957 12.4919ZM20.3569 8.05766C19.776 8.05766 19.0983 8.65863 18.7746 9.51422C18.4022 10.4992 18.6139 11.516 19.2372 11.7343C19.862 11.9523 20.7021 11.3049 21.0748 10.3199C21.2642 9.85248 21.3077 9.34202 21.1999 8.85115C21.1741 8.68895 21.1069 8.53544 21.0043 8.40433C20.9018 8.27322 20.767 8.16861 20.6122 8.09984C20.5303 8.07153 20.4439 8.05726 20.3569 8.05766Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
      <Path
        d="M4.50425 12.4919C3.60359 12.4919 2.67066 11.7343 2.22758 10.5641C1.98786 9.96369 1.93618 9.30891 2.07892 8.68059C2.1313 8.40346 2.25543 8.1435 2.43984 7.92471C2.62425 7.70591 2.863 7.53535 3.13404 7.42877C4.16777 7.06497 5.3944 7.87451 5.92305 9.27005C6.45098 10.6656 6.0525 12.0426 5.01659 12.4054C4.85242 12.4629 4.67899 12.4922 4.50425 12.4919ZM2.92519 10.3199C3.29793 11.3049 4.13877 11.9526 4.76278 11.7343C5.38606 11.516 5.59781 10.4992 5.22544 9.51422C4.8527 8.52923 4.01186 7.88256 3.38785 8.09985C3.23298 8.16861 3.09824 8.27323 2.99568 8.40434C2.89311 8.53544 2.82592 8.68896 2.8001 8.85115C2.69231 9.34202 2.73578 9.85248 2.92519 10.3199Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
      <Path
        d="M8.80417 8.53481C8.49953 8.5234 8.20052 8.45223 7.9255 8.32567C7.65047 8.19911 7.40523 8.01984 7.20481 7.79884C6.64288 7.20429 6.27408 6.46457 6.14389 5.67084C5.81065 3.85362 6.59384 2.253 7.92671 2.02664C9.25778 1.79992 10.5649 3.04686 10.8981 4.86408C11.231 6.6813 10.4478 8.28192 9.1149 8.50828C9.01234 8.52595 8.90836 8.53483 8.80417 8.53481ZM8.23128 2.71516C8.17245 2.71517 8.11374 2.72007 8.05579 2.7298C7.16346 2.88152 6.62285 4.17137 6.87484 5.54666C6.97931 6.20115 7.28025 6.81203 7.74069 7.30424C7.88899 7.48026 8.08036 7.61815 8.29766 7.70557C8.51496 7.79298 8.75141 7.82719 8.98582 7.80512C9.87778 7.65339 10.4188 6.36355 10.1671 4.98826C9.93108 3.70259 9.07103 2.71516 8.23128 2.71516Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
      <Path
        d="M16.9634 22C16.4847 22 16.0075 21.9477 15.541 21.8441C14.9313 21.6788 14.3483 21.4335 13.8082 21.1151C13.1846 20.7841 12.5957 20.4712 12.0384 20.4712C11.4811 20.4712 10.8923 20.7841 10.2686 21.1151C9.7287 21.4335 9.14574 21.6788 8.53622 21.8441C7.07826 22.1706 4.97018 22.0687 3.83274 20.7032C2.91539 19.602 2.80734 17.6627 4.10794 16.5313C4.48633 16.2205 4.89408 15.9445 5.32586 15.7067C5.93732 15.4081 6.47002 14.9794 6.88354 14.4531C7.14945 14.0169 7.36097 13.552 7.51372 13.0681C7.6929 12.4965 7.95081 11.9505 8.28059 11.4448C9.00142 10.4319 10.3361 9.87416 12.0388 9.87416C13.9043 9.87452 15.198 10.411 15.8848 11.4685C16.1758 11.958 16.3996 12.4817 16.5505 13.0266C16.6907 13.5587 16.9152 14.0669 17.2162 14.5334C17.6325 15.0916 18.1939 15.535 18.8442 15.819C19.2485 16.0104 19.6264 16.2497 19.9689 16.5313C21.2695 17.6628 21.1611 19.602 20.2441 20.7032C19.4432 21.6648 18.1611 22 16.9634 22ZM12.0384 19.7569C12.7872 19.7569 13.4888 20.1294 14.1668 20.4897C14.6484 20.7743 15.1672 20.9958 15.7096 21.1486C16.9566 21.4287 18.7427 21.3628 19.6647 20.2567C20.3558 19.4266 20.4464 17.91 19.4707 17.0607C19.1718 16.8184 18.843 16.6125 18.4917 16.4475C17.7393 16.1107 17.0903 15.5924 16.6077 14.9428C16.2615 14.4169 16.003 13.8421 15.8416 13.2393C15.7086 12.753 15.5114 12.2851 15.2549 11.8469C14.7129 11.0123 13.6309 10.5888 12.0388 10.5885C11.199 10.5885 9.67329 10.7521 8.89336 11.8483C8.60148 12.3025 8.37337 12.7918 8.21496 13.3035C8.0436 13.8409 7.80625 14.3567 7.50792 14.8399C7.0369 15.4589 6.42285 15.9641 5.71421 16.3157C5.32172 16.5306 4.95078 16.78 4.60614 17.0607C3.63042 17.9097 3.72071 19.4262 4.41216 20.2567C5.33349 21.3627 7.11778 21.4283 8.36761 21.1486C8.90987 20.9958 9.42857 20.7742 9.91005 20.4897C10.5881 20.1294 11.2897 19.7569 12.0384 19.7569Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
      <Path
        d="M13.0076 18.609H10.9924C10.8666 18.6089 10.746 18.5608 10.657 18.4753C10.568 18.3898 10.5179 18.2738 10.5177 18.1528V16.999H9.31831C9.19253 16.9988 9.07195 16.9506 8.98304 16.865C8.89413 16.7794 8.84415 16.6634 8.84405 16.5424V14.6038C8.84415 14.4828 8.89413 14.3668 8.98304 14.2812C9.07195 14.1956 9.19253 14.1474 9.31831 14.1472H10.5177V12.9934C10.5179 12.8724 10.568 12.7564 10.657 12.6709C10.746 12.5854 10.8666 12.5373 10.9924 12.5372H13.0076C13.1334 12.5373 13.254 12.5854 13.343 12.6709C13.432 12.7564 13.4821 12.8724 13.4823 12.9934V14.1472H14.6817C14.8075 14.1474 14.928 14.1956 15.017 14.2812C15.1059 14.3668 15.1559 14.4828 15.156 14.6038V16.5424C15.1559 16.6634 15.1059 16.7794 15.017 16.865C14.928 16.9506 14.8075 16.9988 14.6817 16.999H13.4823V18.1528C13.4821 18.2738 13.432 18.3898 13.343 18.4753C13.254 18.5608 13.1334 18.6089 13.0076 18.609ZM11.2603 17.8947H12.7397V16.6418C12.7396 16.5949 12.7492 16.5485 12.7679 16.5051C12.7865 16.4618 12.8139 16.4224 12.8484 16.3892C12.8828 16.356 12.9238 16.3297 12.9688 16.3118C13.0139 16.2938 13.0622 16.2846 13.111 16.2847H14.4134V14.8616H13.111C13.0622 14.8616 13.0139 14.8524 12.9688 14.8344C12.9238 14.8165 12.8828 14.7902 12.8484 14.757C12.8139 14.7239 12.7865 14.6845 12.7679 14.6411C12.7492 14.5978 12.7396 14.5513 12.7397 14.5044V13.2515H11.2603V14.5044C11.2604 14.5513 11.2508 14.5978 11.2321 14.6411C11.2135 14.6845 11.1861 14.7239 11.1516 14.757C11.1172 14.7902 11.0762 14.8165 11.0312 14.8344C10.9861 14.8524 10.9378 14.8616 10.889 14.8616H9.58663V16.2847H10.889C10.9378 16.2846 10.9861 16.2938 11.0312 16.3118C11.0762 16.3297 11.1172 16.356 11.1516 16.3892C11.1861 16.4224 11.2135 16.4618 11.2321 16.5051C11.2508 16.5485 11.2604 16.5949 11.2603 16.6418V17.8947Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.4"
        mask="url(#Path-1-outside-1_8450_2805)"
      />
    </Svg>
  );
};

export default PetEmergencyAppointmentIcon;
