import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TopLoaderBarProps, useTopLoaderBarProps } from '../types';
import { tempTopLoadingBar } from '../atom';

export const useTopLoadingBar = (): useTopLoaderBarProps => {
  const processTopLoadingBar = useRecoilValue(tempTopLoadingBar);
  const setProcessTopLoaderBar = useSetRecoilState(tempTopLoadingBar);

  const setLoadingBar = ({ progress, type, hideOnCompletion }: TopLoaderBarProps) => {
    setProcessTopLoaderBar({ progress, type, hideOnCompletion: hideOnCompletion ?? true })
  }

  return {
    processTopLoadingBar,
    setLoadingBar
  };
};
