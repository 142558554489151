import { Platform } from 'react-native';

export const TypographyVariantTypes = {
  h1: {
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: 52,
    letterSpacing: 0.25,
  },
  h2: {
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 44,
    letterSpacing: 0.25,
  },
  h3: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 36,
    letterSpacing: 0.25,
  },
  titleWelcome: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 36,
    letterSpacing: 0.25,
  },
  title: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 32,
    letterSpacing: 0.25,
  },
  subtitle: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  body1: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyItalic1: {
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: '400',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyHighlight1: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  body2: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyItalic2: {
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: '400',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyHighlight2: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyHighlight2Bold: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  helperText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  button: {
    fontSize: 19,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 28,
    letterSpacing: 0.5,
  },
  link: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    letterSpacing: 0.25,
    textDecorationLine: 'underline',
  },
  nav: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 26,
    letterSpacing: 0.25,
  },
  titleXsmall: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  titleXsmallBold: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  description: {
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
  },
};

export const TypographyVariantTypesNativeMobile = {
  h1: {
    fontFamily: 'Poppins-ExtraBold',
  },
  h2: {
    fontFamily: 'Poppins-Bold',
  },
  h3: {
    fontFamily: 'Poppins-Bold',
  },
  title: {
    fontFamily: 'Poppins-SemiBold',
  },
  titleWelcome: {
    fontFamily: 'Poppins-SemiBold',
  },
  subtitle: {
    fontFamily: 'Poppins-SemiBold',
  },
  body1: {
    fontFamily: 'Poppins-Regular',
  },
  bodyItalic1: {
    fontFamily: 'Poppins-Italic',
  },
  bodyHighlight1: {
    fontFamily: 'Poppins-SemiBold',
  },
  body2: {
    fontFamily: 'Poppins-Regular',
  },
  bodyItalic2: {
    fontFamily: 'Poppins-Italic',
  },
  bodyHighlight2: {
    fontFamily: 'Poppins-SemiBold',
  },
  bodyHighlight2Bold: {
    fontFamily: 'Poppins-SemiBold',
  },
  helperText: {
    fontFamily: 'Poppins-Regular',
  },
  button: {
    fontFamily: 'Poppins-Bold',
  },
  link: {
    fontFamily: 'Poppins-SemiBold',
  },
  titleXsmall: {
    fontFamily: 'Poppins-Regular',
  },
  titleXsmallBold: {
    fontFamily: 'Poppins-SemiBold',
  },
  nav: {
    fontFamily: 'Poppins-Regular',
  },
  description: {
    fontFamily: 'Poppins-Regular',
  },
};

const applyOnWebOnly = (value: string) => (Platform.OS === 'web' ? value : undefined);

export const TypographyVariantTypesMobile = {
  h1: {
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('800'),
    lineHeight: 40,
    letterSpacing: 0.25,
  },
  h2: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('700'),
    lineHeight: 36,
    letterSpacing: 0.25,
  },
  h3: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('700'),
    lineHeight: 36,
    letterSpacing: 0.25,
  },
  title: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  titleWelcome: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  subtitle: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  body1: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyItalic1: {
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  bodyHighlight1: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  body2: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  bodyItalic2: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  bodyHighlight2: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  bodyHighlight2Bold: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
  },
  helperText: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  button: {
    fontSize: 19,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('700'),
    lineHeight: 28,
    letterSpacing: 0.5,
  },
  link: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 28,
    letterSpacing: 0.25,
    textDecorationLine: 'underline',
  },
  titleXsmall: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 24,
    letterSpacing: 0.25,
  },
  titleXsmallBold: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 18,
    letterSpacing: 0.25,
  },
  nav: {
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('600'),
    lineHeight: 18,
    letterSpacing: 0.25,
  },
  description: {
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: applyOnWebOnly('400'),
    lineHeight: 20,
    letterSpacing: 0.25,
  },
};
