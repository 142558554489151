import React from 'react';
import { ImageSourcePropType, Platform } from 'react-native';
import { Typography, Button } from '../../../../../components/@tem-ui';
import { Spacing } from '../../../../../components/Spacing';
import * as S from './styles';
import { useNavigation } from '@react-navigation/core';
import { useMedia } from '~/components/@hello-ui';
type SliderProps = {
  image?: ImageSourcePropType;
  title: string;
  description: string;
  text: string;
};

export const SlideItem = ({ image, title, description, text }: SliderProps) => {
  const navigation = useNavigation();
  const media = useMedia();

  const imageStyle =
    Platform.OS === 'web' ? { borderTopLeftRadius: 20, borderTopRightRadius: 20 } : {};

  return (
    <>
      <S.ImageBackground resizeMode="cover" source={image ?? {}} imageStyle={imageStyle} />
      <S.SliderItem>
        <S.WrapperTitle>
          <Typography variant={media.isMobile ? 'title2' : 'title1'} color="white">
            {title}
          </Typography>
        </S.WrapperTitle>
        <S.WrapperBottom>
          <S.WrapperDescription>{description}</S.WrapperDescription>
          <S.WrapperText>
            <Spacing top={20} bottom={30}>
              <Typography variant="body2" color="black60">
                {text}
              </Typography>
            </Spacing>
          </S.WrapperText>
          <Spacing left={Platform.OS === 'web' ? 20 : 0} right={Platform.OS === 'web' ? 20 : 0}>
            <Button
              variant="primary"
              title="Carregar TemPay"
              onPress={() => navigation.navigate('TemPayRecharge')}
            />
          </Spacing>
        </S.WrapperBottom>
      </S.SliderItem>
    </>
  );
};
