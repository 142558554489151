import React, { useState, createContext } from 'react';
import { useCallback } from 'react';
import { NativeSyntheticEvent, NativeScrollEvent } from 'react-native';
import uuid from 'react-native-uuid';

export interface ScrollInstance {
  scrollEvents: {
    [key: string]: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    onScrollBeginDrag: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    onScrollEndDrag: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  };
  subscribe: (
    method: string,
    callback: (event: NativeSyntheticEvent<NativeScrollEvent>) => void,
  ) => Subscription;
  publish: (method: string, event: any) => void;
  scrollY: number;
  scrollX: number;
}

export interface ScrollProviderTypes {
  children: JSX.Element | JSX.Element[];
}

export interface Subscription {
  key: string;
  method: string;
  unsubscribe: () => void;
  callback: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

type SubscriptionList = Array<Subscription>;

export const ScrollContext = createContext<ScrollInstance>({} as ScrollInstance);

export const ScrollProvider = ({ children }: ScrollProviderTypes): JSX.Element => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [subscriberList, setSubscriberList] = useState<SubscriptionList>([]);

  const subscribe = (
    method: string,
    callback: (event: NativeSyntheticEvent<NativeScrollEvent>) => void,
  ): Subscription => {
    const key = uuid.v4().toString();
    const subscriberE = {
      method,
      callback,
      key,
      unsubscribe: () => {
        setSubscriberList(subscriberList.filter((s) => s.key !== key));
      },
    };

    setSubscriberList(subscriberList.concat(subscriberE));
    return subscriberE;
  };

  const publish = (method: string, event: any): void => {
    subscriberList.forEach((subscription: Subscription) => {
      if (subscription && subscription.method === method) {
        subscription.callback(event);
      }
    });
  };

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const {
        contentOffset: { y, x },
      } = event.nativeEvent;
      setScrollX(x);
      setScrollY(y);
      publish('onScroll', event);
    },
    [publish],
  );

  const onScrollBeginDrag = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    publish('onScrollBeginDrag', event);
  };

  const onScrollEndDrag = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    publish('onScrollEndDrag', event);
  };

  return (
    <ScrollContext.Provider
      value={{
        scrollY,
        scrollX,
        scrollEvents: {
          onScroll,
          onScrollBeginDrag,
          onScrollEndDrag,
        },
        subscribe,
        publish,
      }}>
      {children}
    </ScrollContext.Provider>
  );
};
