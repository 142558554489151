import React, { useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';

import { useAuth } from '~/auth/legacy/useAuth';
import { PrizeIcon } from '~/components/Icons/Prize';
import StarIcon from '~/components/Icons/Star';
import Finance from '~/components/Icons/Finance';
import { trackingEventType } from '~/contexts/tracking/types';
import { useTracking } from '~/services/tracking';

export const BenefitsFinancial = (): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { tracking } = useTracking();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <PrizeIcon />,
          title: 'Número da Sorte',
          description: 'Sorteio mensal pela loteria federal',
          onPress: () => navigation.navigate('BenefitsCapitalization'),
          visible: checkIsEnabledFeature('capitalization_title'),
          iconSideOfTitle: true,
        },
        {
          icon: <StarIcon />,
          title: 'Clube de Benefícios',
          description: 'Preços incríveis em compras online',
          onPress: async () => {
            tracking
              .addMovement({
                event: trackingEventType.ClickedOnDiscountClub,
              })
              .catch((err) => {
                console.error('Error on tracking clicked on discount club', err);
              });

            navigation.navigate('BenefitsFinancialClub');
          },
          visible: checkIsEnabledFeature('discount_club'),
          iconSideOfTitle: true,
        },
        {
          icon: <Finance width={24} height={24} />,
          title: 'Orientação Financeira',
          description: 'Para dúvidas e orientações sobre finanças pessoais por telefone',
          onPress: () => navigation.navigate('FinancialTeleorientation'),
          visible: checkIsEnabledFeature('financial_teleorientation'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Benefício Financeiro',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
