import React, { useState, useEffect } from 'react';

import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

import { format, addDays, subDays, compareAsc } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import * as S from './styles';
import { Typography } from '~/components/@tem-ui';
import { useMedia } from '~/components/@hello-ui';

interface SelectDateProps {
  onSelect: (day: Date) => void;
  ourDate: Date | string | undefined;
}

export const SelectDate = ({ onSelect, ourDate }: SelectDateProps): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const today = new Date();

  const [previousDate, setPreviousDate] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>(today);

  useEffect(() => {
    const checkCurrentDate = compareAsc(currentDate, new Date());
    if (checkCurrentDate === 1) setPreviousDate(true);
    if (checkCurrentDate === -1) setPreviousDate(false);
  }, [currentDate]);

  useEffect(() => {
    ourDate && setCurrentDate(ourDate);
  }, [ourDate]);

  return (
    <S.WrapperSelect>
      <S.WrapperClick
        disabled={!previousDate}
        onPress={() => {
          onSelect(subDays(currentDate, 1));
          setCurrentDate(subDays(currentDate, 1));
        }}>
        <Icon
          name="chevron-left"
          size={26}
          color={previousDate ? theme?.colors?.primary : theme?.colors?.gray20}
        />
      </S.WrapperClick>
      <Typography variant="heading3" color="black">
        {format(new Date(currentDate), 'EEEE, dd MMMM', {
          locale: ptBR,
        })}
      </Typography>
      <S.WrapperClick
        onPress={() => {
          onSelect(addDays(currentDate, 1));
          setCurrentDate(addDays(currentDate, 1));
        }}>
        <Icon name="chevron-right" size={26} color={theme?.colors?.primary} />
      </S.WrapperClick>
    </S.WrapperSelect>
  );
};
