import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from '../styles';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const User = (): JSX.Element => {
  return (
    <S.WrapperUser>
      <Icon name="user" size={30} color={StyledDefaultTheme.colors.white} />
    </S.WrapperUser>
  );
};
