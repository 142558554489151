import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const Wrapper = styled.View`
  max-width: 100%;
  width: 852px;
  height: 460px;
  align-items: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;
  `}
`;

export const WrapperSearch = styled.View`
  width: 100%;
  max-width: 600px;
  height: 56px;
  margin-bottom: 48px;
`;

export const WrapperLoading = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.black,
})``;

export const WrapperList = styled.FlatList`
  width: 600px;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;
  `}
`;

export const WrapperItem = styled.TouchableOpacity`
  height: 68px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-horizontal: 12px;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black16};
  width: 100%;
  height: 0.5px;
`;
