import { Page } from '../../components/Page';
import styled from 'styled-components/native';

export const Wrapper = styled(Page)`
  backgroundColor: white;
`;

export const WrapperBackground = styled.View`
  flex: 1;
  resize-mode: cover;
  position: relative;
`;

export const WrapperButtonBack = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  position: absolute;
  top: 48px;
  left: 24px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  z-index: 99;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
