import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const CopyIcon = ({ color, height, width }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        fill={color ?? theme.colors.primary}
        d="M15 20H5a5.006 5.006 0 0 1-5-5V5a5.006 5.006 0 0 1 5-5h10a5.006 5.006 0 0 1 5 5v10a5.006 5.006 0 0 1-5 5ZM5 2a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5Zm19 17V6a1 1 0 0 0-2 0v13a3 3 0 0 1-3 3H6a1 1 0 0 0 0 2h13a5.006 5.006 0 0 0 5-5Z"
      />
    </Svg>
  );
};

export default CopyIcon;
