import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dialogState, propsDialogTypes } from './atom';

interface useDialogTypes {
  propsDialog: propsDialogTypes;
  openDialog: (
    title: string | null | boolean,
    component: JSX.Element,
    subtitle?: string,
    closeButton?: boolean,
    widthAuto?: boolean,
  ) => void;
  closeDialog: () => void;
}

export const useDialog = (): useDialogTypes => {
  const propsDialog = useRecoilValue(dialogState);
  const setDialogProps = useSetRecoilState(dialogState);

  const openDialog = (
    title: string | null,
    component: JSX.Element,
    subtitle?: string,
    closeButton?: boolean,
    widthAuto?: boolean,
  ) => {
    setDialogProps({
      title: title !== null ? title : '',
      state: true,
      content: component,
      subtitle: subtitle !== null ? subtitle : '',
      closeButton: closeButton ?? true,
      widthAuto: widthAuto ?? false,
    });
  };

  const closeDialog = () => {
    setDialogProps({ ...propsDialog, state: false });
  };

  return { propsDialog, openDialog, closeDialog };
};
