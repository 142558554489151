import html2canvas from 'html2canvas';
import React from 'react';
import { View } from 'react-native';

export const downloadImage = async (viewRef: React.RefObject<View>) => {
  const element = viewRef?.current;

  const canvas = await html2canvas(element as unknown as HTMLElement, {
    useCORS: true,
    allowTaint: true,
  });

  const data = canvas.toDataURL('image/jpg');
  const link = document?.createElement('a');

  link.href = data;
  link.download = 'Cartão Tem.jpg';
  document?.body?.appendChild(link);
  link.click();
  document?.body?.removeChild(link);
};
