import React, { useEffect } from 'react';
import { ProgressBar } from 'react-native-paper';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';
import { useSweetAlert } from '../hooks';

import { iconType } from '../types';
import { IconAlert, Title, ContainerButton, ColumnButton } from './style';
import { Typography, useMedia, Button } from '~/components/@hello-ui';
import { Spacing } from '~/components/Spacing';

export const iconName: iconType = {
  success: 'check-circle-outline',
  error: 'cancel',
  warning: 'alert-circle',
  info: 'information',
};

export const AlertCustom = (): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();

  const { alertType, statusAlert, alertTitle, alertMessage, hideSweetAlert, autoClose, custom } =
    useSweetAlert();

  useEffect(() => {
    if (statusAlert && autoClose) {
      setTimeout(() => hideSweetAlert(), 3000);
    }
  }, [statusAlert]);

  let icon = <></>;
  if (custom?.icon) {
    if (typeof custom?.icon === 'string') {
      icon = (
        <Icon
          name={custom?.icon}
          size={media.isMobile ? 68 : 80}
          color={theme.colors[custom?.color]}
        />
      );
    } else {
      icon = custom?.icon;
    }
  } else {
    icon = (
      <Icon
        name={iconName[alertType]}
        size={media.isMobile ? 68 : 100}
        color={theme.colors[custom?.color ?? 'primary']}
      />
    );
  }

  return (
    <>
      <IconAlert backgroundColor={theme.colors[custom?.backgroundColor ?? 'white']}>
        {icon}
      </IconAlert>
      <Title>{alertTitle}</Title>
      <Typography
        variant={theme.isMobile ? 'body2' : 'body1'}
        color={theme.colors.black}
        style={{
          width: theme.isMobile ? 400 : 248,
          textAlign: 'center',
        }}>
        {alertMessage}
      </Typography>
      <ContainerButton>
        {custom?.buttons &&
          custom?.buttons.map((button, index) => (
            <ColumnButton key={index}>
              <Spacing left={5}>
                <Button
                  variant={button.background ?? 'secondary'}
                  testID={button.testID ?? ''}
                  onPress={button.callback ?? (() => hideSweetAlert())}>
                  {button.text}
                </Button>
              </Spacing>
            </ColumnButton>
          ))}
      </ContainerButton>
      {autoClose && (
        <ProgressBar
          style={{
            height: 4,
            width: media.isMobile ? 280 : 300,
            marginTop: 20,
          }}
          indeterminate
          color={theme.colors[custom?.color ?? 'primary']}
        />
      )}
    </>
  );
};
