import styled, { css } from 'styled-components/native';

export const WrapperSchedule = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    width: ${tokens.sizes.full};
    ${isMobile &&
    css`
      width: ${tokens.sizes.full};
    `}
  `}
`;

export const WrapperScheduleCard = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    padding-bottom: ${tokens.space.s8};
  `}
`;

export const WrapperSteps = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
  `}
`;

export const WrapperStepsRow = styled.View`
  ${({ theme: { tokens } }) => css`
    flex-direction: row;
    align-items: center;
    margin-horizontal: -7px;
    justify-content: space-between;
    padding-top: ${tokens.space.s12};
  `}
`;

export const WrapperStep = styled.View<{ current: boolean }>`
  ${({ theme: { tokens, colors }, current }) => css`
    flex: 1;
    width: ${tokens.sizes.full};
    height: ${tokens.borderWidths.b2};
    border-radius: ${tokens.borderWidths.b2};
    background-color: ${!current ? colors.primary20 : colors.primary};
    margin-horizontal: ${tokens.borderWidths.b8};
  `}
`;

export const WrapperTitle = styled.View`
  ${({ theme: { tokens } }) => css`
    margin-bottom: ${tokens.space.s24};
  `}
`;
