import { atom } from 'recoil';

export interface propsDialogTypes {
  title: string;
  state: boolean;
  content: JSX.Element | null;
  subtitle?: string;
  closeButton?: boolean;
  widthAuto?: boolean;
}

export const dialogState = atom<propsDialogTypes>({
  key: 'dialogState',
  default: {
    title: '',
    state: false,
    content: null,
  },
});
