import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { useTheme } from 'styled-components/native';
import { CheckboxTypes } from './types';
import * as S from './styles';

export const Checkbox = ({
  value,
  checked,
  onPress,
  disabled,
  variant,
}: CheckboxTypes): JSX.Element => {
  const [state, setState] = useState<boolean>(checked ?? false);
  const theme = useTheme();
  useEffect(() => {
    setState(checked ?? false);
  }, [checked]);

  return (
    <S.Wrapper
      style={{
        borderColor: !disabled
          ? checked
            ? theme.colors.primary
            : theme?.colors?.black20
          : theme?.colors.black16,
        backgroundColor: state
          ? theme?.colors?.primary
          : variant
          ? 'rgba(64, 64, 64, 0.8)'
          : theme?.colors.white,
      }}
      onPress={() => {
        !disabled && setState(!state);
        !disabled && onPress && onPress(value);
      }}>
      {state && <Icon name="check" size={15} color={theme?.colors.white} />}
    </S.Wrapper>
  );
};
