import React from 'react';
import { Platform } from 'react-native';
import { Card, Button, Typography, Wrapper, Collapse } from '~/components/@tem-ui';
import { useNavigation } from '@react-navigation/native';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { Spacing } from '~/components/Spacing';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

import * as S from './styles';

import DesktopBannerImage from '../../assets/images/surgicalProcedure/desk.png';
import MobileBannerImage from '../../assets/images/surgicalProcedure/mobile.png';
import IconSurgicalProcedure from '~/assets/icons/surgery_w30.svg';
import { useMedia } from '~/components/@hello-ui';

export const SurgicalProcedure = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();

  return (
    <>
      {!media.isMobile && <Header />}
      <Wrapper>
        <Card
          width="100%"
          style={media.isDesktop ? { overflow: 'auto' } : { minHeight: 850 }}
          height={Platform.OS === 'web' ? 1024 : 'auto'}
          disableShadow={media.isMobile}>
          <S.WrapperContainer testID="header-teleorientation">
            <S.WrapperHeader
              resizeMode="cover"
              source={media.isDesktop ? DesktopBannerImage : MobileBannerImage}
            />

            <S.HeaderIcon onPress={() => navigation.navigate('Dashboard')}>
              <Icon name="arrow-left" size={30} color={theme.colors.white} />
            </S.HeaderIcon>

            <S.WrapperContent>
              <S.PanelHeaderImage>
                <S.WrapperCard>
                  <Card width={!media.isMobile ? 608 : 348}>
                    <S.PanelHeaderFeatures>
                      <S.Features>
                        <S.IconPosition>
                          <IconSurgicalProcedure />
                        </S.IconPosition>

                        <S.TitlePosition>Procedimentos cirúrgicos</S.TitlePosition>

                        <Spacing top={15} bottom={15}>
                          <Typography variant="paragraph" color={theme.colors.black70}>
                            <S.between_lines>
                              A Vidia é uma empresa que viabiliza procedimentos cirúrgicos de baixa
                              e média complexidade para pessoas que não possuem plano de saúde.
                              Oferece mais de 400 cirurgias eletivas, como colecistectomia (retirada
                              da vesícula biliar), hérnias abdominais (inguinal e umbilical),
                              catarata, cirurgia refrativa, fimose e reversão de vasectomia.
                            </S.between_lines>
                          </Typography>
                        </Spacing>
                      </S.Features>
                    </S.PanelHeaderFeatures>
                  </Card>
                </S.WrapperCard>
              </S.PanelHeaderImage>
            </S.WrapperContent>
          </S.WrapperContainer>

          <S.Help>
            <S.WrapperItem
              last={true}
              onPress={() => {
                navigation.navigate('SurgicalProcedureHospital');
              }}>
              <Typography variant="body2" color={theme.colors.black}>
                Confira os hospitais disponíveis
              </Typography>
              <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
            </S.WrapperItem>

            {/* <S.WrapperItem>
              <Typography variant="body2" color="black">
                Consulte o Manual de Utilização
              </Typography>
              <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
            </S.WrapperItem> */}

            <Spacing top={20}>
              <Button
                variant="primary"
                title={'Solicitar orçamento'}
                onPress={() => navigation.navigate('SurgicalProcedureForm')}
              />
            </Spacing>
          </S.Help>
        </Card>
        <Footer />
      </Wrapper>
    </>
  );
};
