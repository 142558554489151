import { useRecoilState } from 'recoil';
import {
  tempAmount,
  tempBillCode,
  tempBilletUrl,
  tempCreditCard,
  tempCreditCardsList,
  tempInstallments,
  tempIsVisible,
  tempPixCode,
  tempPixExpiresAt,
  tempPixQrCode,
  tempSelected,
  tempStep,
} from './atom';
import { useRechargeTypes } from './types';

export const useRecharge = (): useRechargeTypes => {
  const [rechargeInstallments, setRechargeInstallments] = useRecoilState(tempInstallments);
  const [visibleCreditCardList, setVisibleCreditCardList] = useRecoilState(tempIsVisible);
  const [selectedCreditCard, setSelectedCreditCard] = useRecoilState(tempSelected);
  const [creditCards, setCreditCardsList] = useRecoilState(tempCreditCardsList);
  const [rechargeStep, setRechargeStep] = useRecoilState(tempStep);
  const [rechargeAmount, setRechargeAmount] = useRecoilState(tempAmount);
  const [creditCard, setCreditCard] = useRecoilState(tempCreditCard);
  const [billetUrl, setBilletUrl] = useRecoilState(tempBilletUrl);
  const [billetCode, setBilletCode] = useRecoilState(tempBillCode);

  const [pixQrCode, setPixQrCode] = useRecoilState(tempPixQrCode);
  const [pixCode, setPixCode] = useRecoilState(tempPixCode);
  const [pixExpiresAt, setPixExpiresAt] = useRecoilState(tempPixExpiresAt);

  const clearState = () => {
    setRechargeStep(0);
    setRechargeAmount(0);
    setRechargeInstallments({ installment: 1, value: 0, amount: 0 });
    setSelectedCreditCard({ id: null, label: '', customer_id: null });
    setVisibleCreditCardList(false);
    setBilletUrl('');
    setBilletCode('');
  };

  return {
    rechargeInstallments,
    visibleCreditCardList,
    selectedCreditCard,
    creditCards,
    rechargeStep,
    rechargeAmount,
    billetUrl,
    setBilletUrl,
    billetCode,
    setBilletCode,
    setSelectedCreditCard,
    setVisibleCreditCardList,
    setRechargeInstallments,
    setCreditCardsList,
    setRechargeStep,
    setRechargeAmount,
    clearState,
    creditCard,
    setCreditCard,
    pixQrCode,
    setPixQrCode,
    pixCode,
    setPixCode,
    pixExpiresAt,
    setPixExpiresAt,
  };
};
