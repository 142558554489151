import React, { useCallback, useState } from 'react';

import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsDigitalWellBeing/desktop.png';
import mobileBackground from '../../assets/images/benefitsDigitalWellBeing/mobile.png';
import { NamuIntegration } from '../NamuIntegration';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, Dialog, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import Wellbeing from '~/components/Icons/Wellbeing';
import { useAuth } from '~/auth/legacy/useAuth';
import { useApi } from '~/hooks/api';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { extractDigits } from '~/utils/strings';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsDigitalWellBeingSSO = (): JSX.Element => {
  const api = useApi();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;
  const [uri, setUri] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const { showSweetAlert } = useSweetAlert();

  const openViewNamu = async () => {
    setLoading(true);
    await getUriManu();
    setLoading(false);
  };

  const getUriManu = useCallback(async () => {
    try {
      const { data } = await api.getWebViewManu(extractDigits(user?.cpf));
      setUri(data.url);
    } catch (error) {
      showSweetAlert(
        'Falha na autenticação',
        'Por favor, entrar em contato com a Central de Atendimento',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: true,
        },
      );

      setUri(null);
    }
  }, [user]);

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <Wellbeing color="white" />,
          title: 'Bem-Estar Digital',
          onBack: () => {
            navigation.navigate('BenefitsWellBeing');
          },
        }}>
        <View>
          <Typography variant="body2">
            Conheça o aplicativo mais <Typography variant="bodyHighlight2">completo</Typography> de{' '}
            <Typography variant="bodyHighlight2">saúde</Typography> e{' '}
            <Typography variant="bodyHighlight2">bem-estar</Typography>. Acompanhe sua pressão
            arterial, frequência cardíaca, nível de oxigenação e estresse utilizando a câmera do seu
            celular. Acesse{' '}
            <Typography variant="bodyHighlight2">mais de 5 mil conteúdos</Typography> e{' '}
            <Typography variant="bodyHighlight2">cursos </Typography>
            sobre saúde, alimentação, meditação, yoga e muito mais. E ainda tenha uma rede de
            academias com pagamento por utilização.
          </Typography>
        </View>
        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="mb-8">
              <Typography variant="body2">
                Conheça as funcionalidades disponíveis no App:
              </Typography>
            </View>
            <View className="mb-8">
              <Typography variant="body2" className="mb-8 pl-8">
                • VitalScan: monitora seus sinais vitais e nível de estresse em tempo real usando a
                câmera do seu celular.
              </Typography>
              <Typography variant="body2" className="mb-8 pl-8">
                • Manu ChatGPT: uma assistente digital especializada em saúde física, mental e
                nutricional.
              </Typography>
              <Typography variant="body2" className="mb-8 pl-8">
                • Aulas e Cursos: mais de 5.000 conteúdos em formato vídeo.
              </Typography>
              <Typography variant="body2" className="pl-8">
                • NamuPass: rede de academias com pagamento por uso, com diárias a partir de R$
                4,90.
              </Typography>
            </View>
          </Collapse>
        </View>
        <MobileAutoSpace />

        <View className="mt-24 w-full items-center self-center text-center">
          <>
            <Button
              variant="primary"
              onPress={() => {
                void openViewNamu();
              }}
              loading={loading}>
              Acessar
            </Button>
          </>
        </View>
      </PageWithCard>

      <Dialog
        visible={uri !== null}
        onClose={() => {
          setUri(null);
        }}>
        <NamuIntegration uri={uri} />
      </Dialog>
    </>
  );
};
