import React from 'react';
import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import * as S from '../styles';
import { Button, Typography } from '../../../@hello-ui';
import { SpacingToken } from '~/components/SpacingToken';
import { extractDigits } from '~/utils/strings';

interface ContentContact {
  title: string;
  description: string;
}

interface ItemProps {
  title: string;
  description: string;
  idx: number;
}

export const PhoneFooter = (): JSX.Element => {
  const theme = useTheme();

  const renderDescription = ({ title, description }: ItemProps) => {
    return (
      <View key="phonefooter-description" className="float-left w-full">
        <Typography variant="h3" color={theme.colors.black}>
          {title}
        </Typography>
        <SpacingToken mTop="s8">
          <Typography variant="body2" color={theme.colors.black70}>
            {description}
          </Typography>
        </SpacingToken>
      </View>
    );
  };

  const renderItem = ({ title, description, idx }: ItemProps) => {
    return (
      <View className="w-1/2" key={`phonefooter-item-${idx}`}>
        <Button
          variant="link"
          textStyle={{
            textAlign: 'left',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            position: 'absolute',
            paddingTop: 10,
            paddingBottom: 5,
            left: 0,
          }}
          onPress={() => Linking.openURL(`tel:${extractDigits(title)}`)}>
          {title}
        </Button>

        <Typography variant="helperText" color={theme.colors.black70}>
          {description}
        </Typography>
      </View>
    );
  };

  const items =
    theme?.footer_phones && typeof theme?.footer_phones !== 'string' && theme?.footer_phones;

  const descriptionData = {
    title: items[0]?.title,
    description: items[0]?.description,
  };

  return (
    <S.PhoneNumberVariantWrapper testID="phone-footer">
      <S.WrapperWrapper>
        <S.WrapperPhoneNumberVariantColumn>
          {renderDescription(descriptionData)}

          <View className="w-full flex-row pt-8">
            {items.map(({ title, description }: ContentContact, idx: number) => {
              return idx !== 0 && renderItem({ title, description, idx });
            })}
          </View>
        </S.WrapperPhoneNumberVariantColumn>
      </S.WrapperWrapper>
    </S.PhoneNumberVariantWrapper>
  );
};
