import styled from 'styled-components/native';
import { TextInput } from 'react-native';
import { commonInputStyles } from '~/components/@hello-ui/Input/styles';
import { IconPosition } from '~/components/@hello-ui/Input/components/InputIcon/types';

// styled.TextInput has wrong typescript type for onSubmitEditing.
// Because of that we wrap TextInput instead of using it directly
export const WrapperInput = styled(TextInput).attrs(({ theme, editable }) => ({
  placeholderTextColor: editable ? theme.colors.placeholder : theme.colors['gray-disabled'],
}))<{ iconPosition?: IconPosition }>`
  ${commonInputStyles}
`;
