import React from 'react';
import { Platform } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  TWILIO_ACCOUNT_SID,
  TWILIO_PHONE_NOT_FOUND_FLOW_ID,
  TWILIO_AUTHOR_NAME,
  TWILIO_AUTHOR_IMAGE,
} from '@env';
import { Spacing } from '../../components/Spacing';
import { Typography } from '../../components/@tem-ui';
import * as S from './styles';
import PhoneCallIcon from './Icons/PhoneCall';
import { Divider } from '~/components/Listing/styles';
import { brandedColors, createChat } from '~/components/@tem-ui/WebChat/configWebChat';

export const PhoneNumberNotFound = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();

  if (Platform.OS === 'web') {
    localStorage.clear();
  }

  const renderChat = async () => {
    if (Platform.OS === 'web') {
      const data = await AsyncStorage.getItem('twilio-flex-cf');
      if (data === null) {
        const configChat = {
          config: {
            accountSid: TWILIO_ACCOUNT_SID,
            flexFlowSid: TWILIO_PHONE_NOT_FOUND_FLOW_ID,
            colorTheme: {
              overrides: brandedColors,
            },
            componentProps: {
              EntryPoint: {
                tagline: 'Chat de atendimento',
                isEntryPointExpanded: true,
              },
              MainHeader: {
                titleText: 'Chat de atendimento',
                showImage: false,
              },
              MessagingCanvas: {
                avatarCallback: (identity) => {
                  return TWILIO_AUTHOR_IMAGE;
                },
              },
            },
            context: {
              friendlyName: 'Eu',
            },
          },
          firstMessage:
            'Ola! Parece que está faltando o Número de celular no seu cadastro.\n\nInforme seu nome completo por favor.',
          authorName: TWILIO_AUTHOR_NAME,
          autostart: true,
        };

        createChat(configChat);
      }
    }
  };

  return (
    <>
      <S.WrapperButtonBack
        onPress={() => {
          Platform.OS === 'web' && localStorage.clear();
          navigation.navigate('Logout');
        }}>
        <Icon name="arrow-left" size={16} color={theme?.colors?.primary} />
      </S.WrapperButtonBack>
      <S.WrapperBody>
        <S.WrapperHeader>
          <S.WrapperImageText>¯\_(ツ)_/¯</S.WrapperImageText>
        </S.WrapperHeader>
        <S.WrapperPage>
          <S.WrapperContentPage>
            <Spacing bottom={theme.isMobile ? 10 : 25}>
              <S.WrapperTitleText>
                Ops, parece que está faltando um dado de segurança no seu cadastro:
              </S.WrapperTitleText>
            </Spacing>
            <Divider />
            <Spacing top={theme.isMobile ? 10 : 25} bottom={25}>
              <S.WrapperContentText>
                <S.WrapperContentIconText>
                  <PhoneCallIcon />
                </S.WrapperContentIconText>
                <S.WrapperText>Número de celular</S.WrapperText>
              </S.WrapperContentText>
            </Spacing>
            <Divider />
            <S.WrapperItemText>
              Para acessar sua conta digital com segurança, é necessário confirmar alguns dados com
              nossa equipe de atendimento.
            </S.WrapperItemText>
            <S.WrapperItemText>Não se preocupe, é rapidinho :)</S.WrapperItemText>

            <S.WrapperAlert>
              <Spacing bottom={20}>
                <Typography variant="body1" color="black">
                  O que fazer agora?
                </Typography>
              </Spacing>
              <Spacing bottom={15}>
                <Typography variant="body2" color="black70">
                  1. Entre em contato por telefone com a nossa equipe de atendimento;
                </Typography>
              </Spacing>
              <Spacing bottom={15}>
                <Typography variant="body2" color="black70">
                  2. Confirme seus dados de cadastro;
                </Typography>
              </Spacing>
              <Spacing bottom={15}>
                <Typography variant="body2" color="black70">
                  3. Acesse novamente sua conta digital.
                </Typography>
              </Spacing>
              <Spacing bottom={10}>
                <Typography variant="body2" color="black70">
                  Pronto, seu acesso estará liberado!
                </Typography>
              </Spacing>
            </S.WrapperAlert>

            <S.WrapperFooter>
              <Typography variant="paragraph" color="gray">
                Segunda à Sexta das 7h às 19h (exceto feriados)
              </Typography>
              <Spacing top={5}>
                <Spacing bottom={2}>
                  <Typography variant="body2" color={theme.colors.primary}>
                    (11) 4000 1640
                  </Typography>
                </Spacing>
                <Typography variant="paragraph" color="gray">
                  Capitais e regiões metropolitanas
                </Typography>
              </Spacing>
              <Spacing top={10}>
                <Spacing bottom={2}>
                  <Typography variant="body2" color={theme.colors.primary}>
                    0800 836 88 36
                  </Typography>
                </Spacing>
                <Typography variant="paragraph" color="gray">
                  Demais localidades
                </Typography>
              </Spacing>
            </S.WrapperFooter>
          </S.WrapperContentPage>
        </S.WrapperPage>
      </S.WrapperBody>
    </>
  );
};
