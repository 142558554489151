import React from 'react';
import { Content } from '~/components/@hello-ui/Dialog/components/DialogContent/styles';
import { LimitedContent } from '~/components/@hello-ui/Dialog/components/styles';

interface DialogContentProps {
  fill?: boolean;
}

export const DialogContent: React.FC<DialogContentProps> = ({ children, fill }) => {
  return (
    <Content testID="dialog-content" fill={fill}>
      <LimitedContent fill={fill}>{children}</LimitedContent>
    </Content>
  );
};
