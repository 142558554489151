import { IFeature } from '~/@types/auth/feature';

interface ValidateUserPermissionsParams {
  userOnix: string;
  userFeatures: IFeature[];
  features?: string[];
  onix?: string[];
  config?: {
    feature: 'every' | 'some';
    onix: 'every' | 'some';
  };
}

export function validateUserPermissions({
  userOnix,
  userFeatures,
  features,
  onix,
  config = {
    onix: 'some',
    feature: 'some',
  },
}: ValidateUserPermissionsParams) {
  const partnerFeatures = userFeatures.map(({ name }) => name);

  if (features && features.length > 0) {
    const hasValidfeatures = features[config.feature]((feature) => {
      return partnerFeatures.includes(feature);
    });

    if (!hasValidfeatures) {
      return false;
    }
  }

  if (onix && onix.length > 0) {
    const hasValidOnix = onix[config.onix]((onix) => {
      return userOnix === onix;
    });

    if (hasValidOnix) {
      return false;
    }
  }

  return true;
}
