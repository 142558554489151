import React from 'react';
import { View } from 'react-native';
import { clsx } from 'clsx';
import { useTheme } from 'styled-components/native';
import { pt } from 'date-fns/locale';
import { format } from 'date-fns';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { makeInfoByType } from '~/screens/MyAppointments/helpers/make-info-by-type';
import { makeTextByStatus } from '~/screens/MyAppointments/helpers/make-text-by-status';
import { capitalize, currency } from '~/utils/strings';
import { Footer } from '~/components/Footer';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { TypesEnum } from '~/enums/appointment';

export const HistoryAppointment: React.FC = () => {
  const theme = useTheme();
  const { myAppointment } = useMyAppointments();
  const { onixCode } = useAuth();

  const isMercadoPago = MERCADO_PAGO_ONIX.includes(onixCode?.onix_code?.toString() || '');

  const { clinic_address, specialty, status, type, value_cents, scheduled_date, online_process } =
    myAppointment!;

  const hasCompleted = status === 'finished';

  const appointmentType = makeInfoByType(theme);
  const statusType = makeTextByStatus(theme);

  const getDate = () => {
    try {
      if (!scheduled_date) {
        return '-';
      }

      const date = new Date(scheduled_date);

      return capitalize(format(date, "EEEE, d 'de' MMMM 'às' HH:mm", { locale: pt }));
    } catch (e) {
      return '-';
    }
  };

  return (
    <>
      <View className="flex-row">
        <View
          className={clsx(
            'mr-24 h-56 w-56 items-center justify-center rounded-full mobile:mr-16 mobile:h-48 mobile:w-48',
            hasCompleted ? 'bg-success' : 'bg-error',
          )}>
          {appointmentType[type]?.icon}
        </View>
        <View>
          <Typography variant="bodyHighlight2" color="title">
            {appointmentType[type]?.description}
          </Typography>
          <Typography variant="body2" color="title">
            {specialty?.name ?? appointmentType[type]?.resume ?? ''}
          </Typography>
        </View>
        <View className="rounded-full p-8">
          <Typography variant="bodyHighlight2" className="text-center">
            {statusType[type]?.text}
          </Typography>
        </View>
      </View>
      <View className="mb-40 mt-16 mobile:mb-24">
        <Typography variant="body2">
          Protocolo: <Typography variant="bodyHighlight2">{myAppointment?.protocol}</Typography>
        </Typography>
      </View>
      <Collapse title={hasCompleted ? 'Detalhes do atendimento' : 'Detalhes da solicitação'}>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Protocolo
          </Typography>
          <Typography variant="body2">{myAppointment?.protocol}</Typography>
        </View>
        {myAppointment?.type !== TypesEnum.CheckUp && (
          <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Dia e horário
            </Typography>
            <Typography variant="body2">{getDate()}</Typography>
          </View>
        )}

        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Para quem
          </Typography>
          <Typography variant="body2">{`${myAppointment?.patient?.name} - ${myAppointment?.patient?.holder ? 'Titular' : 'Dependente'}`}</Typography>
        </View>
        {!online_process ||
          (myAppointment?.type !== TypesEnum.CheckUp && (
            <View className="mb-16">
              <Typography variant="body2" color="placeholder">
                Endereço
              </Typography>
              <Typography variant="body2">{clinic_address || '-'}</Typography>
            </View>
          ))}
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Valor do atendimento
          </Typography>
          <Typography variant="body2">
            {value_cents ? `R$ ${value_cents ? currency(value_cents / 100) : ''}` : '-'}
          </Typography>
        </View>
        {!hasCompleted && (
          <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Motivo do cancelamento
            </Typography>
            <Typography variant="body2">-</Typography>
          </View>
        )}
      </Collapse>
      <View className="mt-40 mobile:mt-24" />
      {hasCompleted ? (
        <>
          <Typography variant="bodyHighlight2">Continue se cuidando!</Typography>
          <Typography variant="body2">
            Conte com a gente se precisar de um novo atendimento, estaremos por aqui.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="bodyHighlight2">Não se esqueça de se cuidar!</Typography>
          <Typography variant="body2">
            Conte com a gente para remarcar o seu atendimento.
          </Typography>
        </>
      )}
      <MobileAutoSpace />
      {!theme.isMobile && !isMercadoPago && (
        <Typography variant="bodyHighlight2" className="mt-24 text-center">
          Dúvidas? Fale conosco:
        </Typography>
      )}
      {!isMercadoPago && (
        <View className="mt-16 mobile:mt-24">
          <Footer
            variant="simple-phone-footer"
            showButtonOnMobile
            textButtonOnMobile="Dúvidas? Fale conosco:"
          />
        </View>
      )}
    </>
  );
};
