import React from 'react';
import styled from 'styled-components/native';
import * as S from '../styles';

const Default = styled(S.ButtonContainer)`
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export default ({ ...rest }): JSX.Element => (
  <S.ButtonWrapper>
    <Default {...rest} />
  </S.ButtonWrapper>
);
