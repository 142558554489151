import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { View, Platform, Linking } from 'react-native';
import desktopBackground from '../../assets/images/BenefitsPetFuneralAssistance/img_desk_pet_funeral.png';
import mobileBackground from '../../assets/images/BenefitsPetFuneralAssistance/img_mobile_pet_funeral.png';
import {
  PageWithCard,
  Button,
  Collapse,
  MobileAutoSpace,
  Typography,
  useMedia,
} from '~/components/@hello-ui';
import CircleGroup from '~/components/Icons/CircleGroup';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsPetFuneralAssistance = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;
  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <CircleGroup width={24} height={24} color={theme.colors.white} />,
        title: 'Assistência funeral pet',
        onBack: () => {
          navigation.navigate('BenefitsPetAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Criado para prestar{' '}
          <Typography variant="bodyHighlight2">apoio em momentos sensíveis</Typography>, garantindo
          tranquilidade e estabilidade por meio de assistência via telefone, disponível 24 horas, e
          serviços de profissionais especializados.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2" className="mb-8">
            O serviço tem o objetivo de{' '}
            <Typography variant="bodyHighlight2">
              cobrir os custos ou parte deles, na situação de morte do pet
            </Typography>
            , oferecendo suporte de forma eficiente e conveniente.
          </Typography>
          <Typography variant="body2">A assistência oferece:</Typography>
          <View className="p-8">
            <Typography variant="body2">• Limite de 1 intervenção por ano;</Typography>
            <Typography variant="body2">
              • Cobertura de até R$500,00 nos custos funerários;
            </Typography>
            <Typography variant="body2">• Atendimento 24 horas.</Typography>
          </View>
          <Typography variant="body2">
            A cobertura e o atendimento acontecem somente em casos de falecimento natural ou
            acidental.
          </Typography>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para utilizar o benefício, ligue e solicite a assistência:
        </Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.gray70} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 702 9897</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
