import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { PopUp } from './components/PopUp';
import { BottomSheet } from './components/BottomSheet';

export interface DialogProps extends DialogComponentProps {
  variant?: 'pop-up' | 'bottom-sheet';
}

export interface DialogComponentProps {
  visible: boolean;
  children: ReactNode;
  onClose?: () => void;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
}

export const Dialog = ({ variant, ...props }: DialogProps) => {
  const theme = useTheme();

  const componentByVariant = {
    'pop-up': <PopUp {...props} />,
    'bottom-sheet': <BottomSheet {...props} />,
  };

  return (
    <View testID={`${variant}-test`}>
      {componentByVariant[variant ?? theme.isDesktop ? 'pop-up' : 'bottom-sheet']}
    </View>
  );
};
