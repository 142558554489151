import React from 'react';
import { Typography } from '../Typography';
import { SelectWithSearch } from './SelectWithSearch';
import * as S from './styles';
import { SelectProps } from './types';

/**
 * Select Component
 *
 * A custom select component that provides a searchable dropdown interface.
 *
 * @component
 *
 * @param {Object[]} items - List of options for the dropdown.
 * @param {string} items[].label - Label of the option.
 * @param {string} items[].value - Value of the option.
 * @param {function} onSelect - Callback function invoked when an option is selected.
 * @param {string} placeholder - Text to display when no option is selected.
 * @param {string} label - Optional label to display above the select component.
 * @param {string} helperText - Optional helper text to display below the select component.
 *
 * @example
 * // Example usage:
 * const options = [
 *   { label: 'Option 1', value: 'option1' },
 *   { label: 'Option 2', value: 'option2' },
 *   { label: 'Option 3', value: 'option3' },
 * ];
 *
 * const handleSelect = (selectedItem) => {
 *   console.log('Selected item:', selectedItem);
 * };
 *
 * const MyComponent = () => {
 *   return (
 *     <Select
 *       items={options}
 *       placeholder="Select an option"
 *       label="Choose an option"
 *       helperText="Select an option from the dropdown."
 *       onSelect={handleSelect}
 *     />
 *   );
 * };
 */
export const Select = ({
  items,
  onSelect,
  placeholder,
  label,
  helperText,
  value,
  disabled,
  onFocus,
  reference,
  testID,
  search = true,
  disableAutoScroll = true,
  ...rest
}: SelectProps) => {
  return (
    <S.SelectContainer {...rest}>
      {label && (
        <Typography
          variant="bodyHighlight2"
          color={disabled ? 'gray-disabled' : 'paragraph'}
          style={{ fontSize: 16 }}>
          {label}
        </Typography>
      )}
      <SelectWithSearch
        testID={testID ?? 'select-test'}
        items={items}
        search={search}
        placeholder={placeholder}
        onSelect={(item) => {
          onSelect && onSelect(item);
        }}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        reference={reference}
        disableAutoScroll={disableAutoScroll}
      />
      <S.HelperTextContainer>
        {helperText && !disabled && <Typography variant="helperText">{helperText}</Typography>}
      </S.HelperTextContainer>
    </S.SelectContainer>
  );
};
