import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';
import { useAuth } from '~/auth/legacy/useAuth';
import CircleGroup from '~/components/Icons/CircleGroup';
import PetEmergencyAppointmentIcon from '~/components/Icons/PetEmergencyAppointmentIcon';
import PetTransportIcon from '~/components/Icons/PetTransportIcon';
import PetFoodIcon from '~/components/Icons/PetFoodIcon';
import PetAccommodationIcon from '~/components/Icons/PetAccommodationIcon';

export const BenefitsPetAssistance = (): JSX.Element => {
  const navigation = useNavigation();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <CircleGroup width={24} height={24} />,
          title: 'Assistência Funeral Pet',
          description: 'Apoio para o tutor e seu pet em momentos sensíveis',
          onPress: () => navigation.navigate('BenefitsPetFuneralAssistance'),
          visible: checkIsEnabledFeature('pet_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <PetEmergencyAppointmentIcon width={24} height={24} />,
          title: 'Consulta Emergencial',
          description: 'Consulta veterinária para situações de emergência',
          onPress: () => navigation.navigate('BenefitsPetEmergencyAppointment'),
          visible: checkIsEnabledFeature('pet_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <PetTransportIcon width={24} height={24} />,
          title: 'Transporte',
          description: 'Locomoção do seu pet em situações previstas ou imprevistas',
          onPress: () => navigation.navigate('BenefitsPetTransport'),
          visible: checkIsEnabledFeature('pet_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <PetFoodIcon width={24} height={24} />,
          title: 'Envio de Ração',
          description: 'Entrega de alimento para o pet da loja para sua residência',
          onPress: () => navigation.navigate('BenefitsPetFoodShipping'),
          visible: checkIsEnabledFeature('pet_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <PetAccommodationIcon width={24} height={24} />,
          title: 'Hospedagem',
          description: 'Estadia para seu pet em momentos sensíveis',
          onPress: () => navigation.navigate('BenefitsPetAccommodation'),
          visible: checkIsEnabledFeature('pet_assistance'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        onBack: () => {
          navigation.navigate('Dashboard');
        },
        title: 'Assistência Pet',
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
