import React from 'react';

import { FlatList, Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/BenefitsParentalAssistance/desktop.png';
import mobileBackground from '../../assets/images/BenefitsParentalAssistance/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';
import { useSweetAlert } from '~/components/@tem-ui';
import { ParentalAssistanceIcon } from '~/components/Icons/ParentalAssistance';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsParentalAssistance = (): JSX.Element => {
  const navigation = useNavigation();
  const { showSweetAlert } = useSweetAlert();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const bellaWomanParentalWhatsApp = '+551149498158';

  const redirectWhatsapp = async () => {
    try {
      if (Platform.OS === 'web') {
        window.open(`https://wa.me/${bellaWomanParentalWhatsApp}`, '_blank');
      } else {
        await Linking.openURL(`whatsapp://send?phone=${bellaWomanParentalWhatsApp}`);
      }
    } catch (err) {
      showSweetAlert('Ops, algo deu errado', 'Erro ao abrir whatsapp', 'error', true, false, {
        layout: 'helloUi',
        touchOutside: false,
      });
    }
  };

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <CallIcon />,
        title: 'Assistente Familiar Digital',
        onBack: () => {
          navigation.navigate('BenefitsWoman');
        },
      }}>
      <Typography variant="body2">
        Tenha uma Assistente Digital <Typography variant="bodyHighlight2">por WhatsApp</Typography>{' '}
        para ajudá-la nas questões do dia a dia com os filhos e aprenda mais sobre{' '}
        <Typography variant="bodyHighlight2">educação com afeto</Typography> e{' '}
        <Typography variant="bodyHighlight2">relações familiares mais saudáveis.</Typography>
      </Typography>
      <View className="mt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2" className="mb-8">
            O assistente digital oferece conteúdos em formato de{' '}
            <Typography variant="bodyHighlight2">e-book, vídeo</Typography> e{' '}
            <Typography variant="bodyHighlight2">podcast</Typography> com dicas sobre:
          </Typography>
          <Typography variant="body2" className="mb-8 pl-8">
            • Comportamento infantil (birras, rotina, introdução alimentar);
          </Typography>
          <Typography variant="body2" className="pl-8">
            • Inseguranças na criação e no acolhimento de seus filhos.
          </Typography>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício,</Typography>
        <Typography variant="bodyHighlight2">entre em contato com a assistente digital:</Typography>
      </View>
      <Button variant="primary" onPress={async () => redirectWhatsapp()}>
        Falar com o assistente
      </Button>
    </PageWithCard>
  );
};
