import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

const ToothIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M3.65 8.4C3.65 7.1864 4.05669 5.9787 4.75058 5.08656C5.43899 4.20146 6.38676 3.65 7.5 3.65C8.75559 3.65 9.34869 3.85455 9.8461 4.05351C9.90046 4.07526 9.95493 4.09755 10.0099 4.12006C10.5122 4.32564 11.0604 4.55 12 4.55C12.9396 4.55 13.4878 4.32564 13.9901 4.12007C14.0451 4.09755 14.0995 4.07526 14.1539 4.05351C14.6513 3.85455 15.2444 3.65 16.5 3.65C17.6132 3.65 18.561 4.20146 19.2494 5.08656C19.9433 5.9787 20.35 7.18641 20.35 8.4C20.35 9.26032 20.1433 10.3135 19.8216 11.2668C19.4932 12.2401 19.0821 12.9987 18.7404 13.3404C18.5368 13.5439 18.3847 13.8371 18.2663 14.1049C18.1395 14.3916 18.0159 14.7344 17.8943 15.0991C17.7774 15.4497 17.6577 15.8352 17.5357 16.2279L17.5193 16.2808C17.3908 16.6945 17.2591 17.1173 17.1209 17.532C16.8412 18.371 16.549 19.132 16.2334 19.6749C16.0757 19.9461 15.9309 20.1294 15.8062 20.2386C15.6861 20.3439 15.6213 20.35 15.6 20.35C14.7813 20.35 14.3696 20.0534 14.1099 19.6585C13.8131 19.2071 13.6698 18.5555 13.5892 17.7826C13.5574 17.4772 13.5368 17.1735 13.5162 16.8691C13.5113 16.7955 13.5063 16.7218 13.5011 16.6481C13.4755 16.2804 13.4454 15.9052 13.3877 15.5731C13.332 15.2527 13.2388 14.894 13.0405 14.6039C12.8136 14.2718 12.4599 14.05 12 14.05C11.5401 14.05 11.1864 14.2718 10.9595 14.6039C10.7612 14.894 10.668 15.2527 10.6123 15.5731C10.5546 15.9052 10.5245 16.2804 10.4989 16.6481C10.4937 16.7218 10.4887 16.7955 10.4838 16.8691C10.4632 17.1735 10.4426 17.4772 10.4108 17.7826C10.3302 18.5555 10.1869 19.2071 9.89007 19.6585C9.63038 20.0534 9.21868 20.35 8.4 20.35C8.37871 20.35 8.31389 20.3439 8.19376 20.2386C8.06905 20.1294 7.92431 19.9461 7.76662 19.6749C7.451 19.132 7.15882 18.371 6.87914 17.532C6.74093 17.1173 6.6092 16.6945 6.4807 16.2808L6.46425 16.2278C6.34228 15.8351 6.22256 15.4497 6.10571 15.0991C5.98414 14.7344 5.86049 14.3916 5.73373 14.1049C5.61531 13.8371 5.46316 13.5439 5.25962 13.3404C4.91793 12.9987 4.50684 12.2401 4.17838 11.2668C3.85668 10.3135 3.65 9.26032 3.65 8.4Z"
        stroke={theme?.colors.primary}
        strokeWidth="1.3"
      />
    </Svg>
  );
};

export default ToothIcon;
