import { API_R_URL } from '@env';
import { ApiBase } from '~/services/api/api-base';
import {
  IAxiosGetDependentsRes,
  IGetDependentsRes,
  IGetMyDependentsPayload,
} from '~/services/api/r/interfaces/get-my-dependents';
import { DependentStatus, DependentStatusDesc } from '~/@types/auth/dependent';
import { Gender } from '~/@types/auth/gender';
import { extractDigits } from '~/utils/strings';
import {
  IAxiosTokenCtoRes,
  IGetTokenCtoPayload,
  ITokenCtoRes,
} from '~/services/api/r/interfaces/get-token-cto';

export class RServiceClass extends ApiBase {
  constructor() {
    super({ baseUrl: API_R_URL });
  }

  async getMyDependents({
    onix,
    cpf,
    tokenzeus,
  }: IGetMyDependentsPayload): Promise<IGetDependentsRes> {
    try {
      const res = await this.axios.post<IAxiosGetDependentsRes>('tem_dependente', {
        CodOnix: onix,
        Cpf: cpf,
        tokenzeus,
      });

      return res.data.result.map((dependent) => ({
        titularCpf: dependent.CPF_TITULAR,
        name: dependent.NOME_DEPENDENTE,
        cpf: dependent.CPF_DEPENDENTE,
        onix: dependent.ONIX,
        birthdate: dependent.DATA_NASCIMENTO,
        status: dependent.COD_STATUS as DependentStatus,
        statusDesc: dependent.DESC_STATUS as DependentStatusDesc,
        associationStartDate: dependent.DATA_INICIO_ASSOCIACAO,
        email: dependent.EMAIL,
        gender: dependent.sexo as Gender,
        phone: dependent.TELEFONE,
      }));
    } catch (err) {
      const error = err as Record<string, unknown>;
      if (
        this.isAxiosError(error) &&
        error.response.status === 490 &&
        error.response.data?.[0]?.error.includes('BENEFICIARIO SEM DEPENDENTE')
      ) {
        return [];
      }

      throw err;
    }
  }

  async getTokenCto({ cpf, token, onix }: IGetTokenCtoPayload): Promise<ITokenCtoRes> {
    const cpfCleared = extractDigits(cpf);

    const res = await this.axios.post<IAxiosTokenCtoRes>('tem_usertoken_cto', {
      tokenzeus: token,
      CodOnix: onix,
      cpf: cpfCleared,
    });

    return {
      token: res.data.user_token,
      cpf: res.data.cpf,
      onix: res.data.CodOnix,
      userType: res.data.tipo_usuario,
    };
  }
}

export const RService = new RServiceClass();
