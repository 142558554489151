import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import {
  Input,
  MobileAutoSpace,
  PageWithCard,
  useSweetAlert,
  Button,
} from '~/components/@hello-ui';
import { isDateValid } from '~/utils/validations';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { UseRoute } from '~/@types/navigation/routes-helpers';

const schema = yup.object().shape({
  card: yup.string().min(19, 'Cartão inválido').required('Campo obrigatório'),
  cardHolderName: yup.string().required('Campo obrigatório'),
  cardValidate: yup
    .string()
    .max(5, 'Data inválida')
    .test({
      test: (value = '') => isDateValid(value),
      message: 'Data inválida',
    })
    .required('Campo obrigatório'),
  cardCvv: yup.string().min(3, 'CVV inválido').required('Campo obrigatório'),
});

export const RegisterCreditCard: React.FC = () => {
  const { params } = useRoute<UseRoute<'RegisterCreditCard'>>();
  const api = useApi();
  const navigation = useNavigation();
  const { user, partner } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      card: '',
      cardHolderName: '',
      cardValidate: '',
      cardCvv: '',
    },
  });

  useFocusEffect(
    useCallback(() => {
      reset();
    }, []),
  );

  const saveCreditCard = async (values) => {
    try {
      const payload = {
        card: values.card.replace(/\D/g, ''),
        expiresMonth: values.cardValidate.split('/')[0],
        expiresYear: `20${values.cardValidate.split('/')[1]}`,
        cardCvv: values.cardCvv,
        cardHolderName: values.cardHolderName,
        cpf: user?.cpf.replace(/\D/g, ''),
      };

      if (partner?.onix_code === 7410) {
        if (!payload.card.startsWith('506708')) {
          showSweetAlert(
            'Ops, algo deu errado',
            'O cartão informado não é aceito nessa operação. Use um cartão da BBC para continuar.',
            'error',
            false,
            false,
            {
              layout: 'helloUi',
              touchOutside: false,
              buttons: [
                {
                  text: 'OK',
                  variant: 'primary',
                  onPress: () => hideSweetAlert(),
                },
              ],
            },
          );
          return;
        }
      }

      await api.createCreditCard(payload);
      showSweetAlert('Tudo certo!', 'Seu cartão foi cadastrado', 'success', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'OK',
            variant: 'primary',
            onPress: () => {
              if (params?.returnPage) {
                navigation.navigate(params.returnPage, { keepState: true });
              } else {
                navigation.navigate('MyCreditCards');
              }
              hideSweetAlert();
            },
          },
        ],
      });
    } catch (err) {
      console.error(err);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível criar o cartão. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  const handleGoBack = () => {
    if (params?.returnPage) {
      navigation.navigate(params.returnPage, {
        keepState: !params.automaticRedirect,
      });
    } else {
      navigation.navigate('MyCreditCards');
    }
  };

  return (
    <PageWithCard simpleOptions={{ title: 'Cadastrar novo cartão', onBack: handleGoBack }}>
      <View className="mb-8">
        <Controller
          name="card"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              placeholder="Digite"
              label="Número do Cartão"
              variant="mask"
              error={error?.message}
              keyboardType="numeric"
              mask="9999 9999 9999 9999"
            />
          )}
        />
      </View>

      <View className="mb-8 flex-row">
        <View className="w-1/2 pr-8">
          <Controller
            name="cardValidate"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                placeholder="Digite"
                keyboardType="numeric"
                label="Validade"
                error={error?.message}
                mask="99/99"
                variant="mask"
              />
            )}
          />
        </View>
        <View className="w-1/2 pl-8">
          <Controller
            name="cardCvv"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                placeholder="Digite"
                keyboardType="numeric"
                label="CVV"
                error={error?.message}
                mask="999"
                variant="mask"
              />
            )}
          />
        </View>
      </View>
      <Controller
        name="cardHolderName"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={(text) => {
              onChange(
                text === ' '
                  ? text.replace(/( ){1,}/g, '')
                  : text.replace(/[0-9]/g, '').replace(/( ){2,}/g, ''),
              );
            }}
            placeholder="Digite"
            label="Nome do Titular"
            error={error?.message}
          />
        )}
      />
      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button
        disabled={!isValid || isSubmitting}
        variant="primary"
        onPress={handleSubmit(saveCreditCard)}>
        Cadastrar
      </Button>
    </PageWithCard>
  );
};
