import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import DesktopBannerImage from '../../assets/images/scheduleTeleorientation/desktop.jpg';
import MobileBannerImage from '../../assets/images/scheduleTeleorientation/mobile.jpg';
import * as S from './styles';
import { Card, Typography, Wrapper, Collapse } from '~/components/@tem-ui';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { Spacing } from '~/components/Spacing';

import { StyledDefaultTheme, useMedia } from '~/components/@hello-ui';

const items: string[] = [
  'Orientação com profissional de saúde por telefone',
  '24 horas por dia | 7 dias por semana',
  'Uso ilimitado',
];

export const ScheduleTeleorientation = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();

  const keyExtractor = useCallback((item, idx) => idx.toString(), []);
  const renderFeatures = useCallback(
    ({ item }) => (
      <S.FeatureItem>
        <Icon size={20} name="check" color={StyledDefaultTheme.colors.blue} />
        <Typography variant="body1" color="black70" style={{ marginLeft: 8 }}>
          {item}
        </Typography>
      </S.FeatureItem>
    ),
    [],
  );

  return (
    <>
      {!media.isMobile && <Header />}
      <Wrapper>
        <Card
          width="100%"
          style={media.isDesktop ? { overflow: 'auto' } : { minHeight: 850 }}
          height={Platform.OS === 'web' ? 1024 : 'auto'}
          disableShadow={media.isMobile}>
          <S.WrapperContainer testID="header-schedule-teleorientation">
            <S.WrapperHeader
              resizeMode="cover"
              source={media.isDesktop ? DesktopBannerImage : MobileBannerImage}
            />
            <S.HeaderIcon onPress={() => navigation.navigate('Dashboard')}>
              <Icon name="arrow-left" size={30} color={StyledDefaultTheme.colors.white} />
            </S.HeaderIcon>
            <S.WrapperContent>
              <S.PanelHeaderImage>
                <S.WrapperCard>
                  <Card width={!media.isMobile ? 608 : 320}>
                    <S.PanelHeaderFeatures>
                      <S.ScheduleFeatures>
                        <Spacing left={10} top={10} bottom={10}>
                          <Typography variant="title2" color="blue">
                            Teleorientação 24/7
                          </Typography>
                        </Spacing>
                        <S.ScheduleFeaturesList
                          scrollEnabled={false}
                          data={items}
                          keyExtractor={keyExtractor}
                          renderItem={renderFeatures}
                          ItemSeparatorComponent={S.Divider}
                          showsVerticalScrollIndicator={false}
                        />
                      </S.ScheduleFeatures>
                    </S.PanelHeaderFeatures>
                  </Card>
                </S.WrapperCard>
              </S.PanelHeaderImage>
              <S.PanelContent testID="content-schedule-teleorientation">
                <S.ScheduleInfo>
                  <Typography variant="body1" color="white" style={{ textAlign: 'center' }}>
                    Para falar com um profissional de saúde, ligue:
                  </Typography>
                  <S.ScheduleInfoContact>
                    <Icon size={32} name="smartphone" color={StyledDefaultTheme.colors.white} />
                    <Typography variant="title2" color="white" style={{ marginLeft: 8 }}>
                      0800 941 5455
                    </Typography>
                  </S.ScheduleInfoContact>
                </S.ScheduleInfo>
              </S.PanelContent>
            </S.WrapperContent>
          </S.WrapperContainer>
          <S.ScheduleHelp>
            <Collapse title="O que é">
              <Typography variant="paragraph" color="black70">
                Um serviço que você pode falar com um Profissional de saúde responsável por tirar
                dúvidas relacionadas à saúde e, orientar o que fazer em casos de acidentes.
              </Typography>
            </Collapse>
            <Collapse title="Quando usar">
              <Typography variant="paragraph" color="black70">
                Sempre que não estiver passando bem em qualquer lugar e não souber se deve ou não
                procurar atendimento médico;
              </Typography>
              <Spacing top={16}>
                <Typography variant="paragraph" color="black70">
                  Gestantes podem tirar dúvidas;
                </Typography>
              </Spacing>
              <Spacing top={16}>
                <Typography variant="paragraph" color="black70">
                  Mães que desejam saber sobre cuidados básicos com os filhos (amamentação e
                  alimentação);
                </Typography>
              </Spacing>
              <Spacing top={16}>
                <Typography variant="paragraph" color="black70">
                  Quando não souber qual médico especialista procurar logo após sentir um
                  desconforto e não identificar a sua origem;
                </Typography>
              </Spacing>
              <Spacing top={16}>
                <Typography variant="paragraph" color="black70">
                  Quando possuir alguma doença crônica (diabetes, hipertensão, obesidade, etc.);
                </Typography>
              </Spacing>
              <Spacing top={16}>
                <Typography variant="paragraph" color="black70">
                  Caso queira orientação sobre doenças, alimentação ou sobre a dosagem dos
                  medicamentos.
                </Typography>
              </Spacing>
            </Collapse>
          </S.ScheduleHelp>
        </Card>
        <Footer />
      </Wrapper>
    </>
  );
};
