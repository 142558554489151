import React, { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import MaterialCommunityIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import SimpleLineIcons from 'react-native-vector-icons/dist/SimpleLineIcons';
import { useTheme } from 'styled-components/native';
import * as S from './styles';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import { Card, CardHeader, Typography, useSweetAlert } from '~/components/@tem-ui';
import { Spacing } from '~/components/Spacing';
import { useAuth } from '~/auth/legacy/useAuth';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { useMedia } from '~/components/@hello-ui';
type NumberType = 'whatsapp' | 'default';

export const MyClinic = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();
  const { clinic } = useAuth();
  const { showSweetAlert } = useSweetAlert();

  const { tracking } = useTracking();

  const [whatsApp, setWhatsApp] = useState<string>('--');
  const [phone, setCelPhoneNumber] = useState<string>('--');

  const getNumber = (type: NumberType) => {
    const data = clinic?.affiliation.phones.find((data) => data.type === type);

    if (data) {
      const format = data?.phone.length > 10 ? /(\d{2})(\d{5})(\d{4})/ : /(\d{2})(\d{4})(\d{4})/;
      return data?.phone.replace(format, '($1) $2-$3');
    }

    return '';
  };

  const showAlert = () => {
    showSweetAlert(
      'Ops, algo deu errado',
      'Não foi possível carregar todos os dados da sua clinica.',
      'warning',
      true,
      true,
    );
  };

  const checkDataClinic = () => {
    if (clinic?.affiliation === undefined || clinic?.message) {
      showAlert();
      return;
    }

    const keys = Object.keys(clinic?.affiliation);
    const check = keys.filter(
      (key) => clinic?.affiliation[key] === undefined || clinic?.affiliation[key] === '',
    );

    check.length > 0 && showAlert();
  };

  useEffect(() => {
    checkDataClinic();
    if (clinic?.affiliation?.phones) {
      const whatsAppNumber = getNumber('whatsapp');
      const phoneNumber = getNumber('default');

      setWhatsApp(whatsAppNumber);
      setCelPhoneNumber(phoneNumber);
    }
  }, [clinic, whatsApp, phone]);

  const openWhatsApp = () => {
    void tracking.addMovement({
      event: trackingEventType.ClickedOnOpenWhatsAppMyClinic,
      metadata: {
        clinic_info: { ...clinic },
      },
    });

    void Linking.openURL(`whatsapp://send?phone=55${whatsApp}`);
  };

  const openPhoneCall = () => {
    void tracking.addMovement({
      event: trackingEventType.ClickedOnOpenPhoneNumberMyClinic,
      metadata: {
        clinic_info: { ...clinic },
      },
    });

    void Linking.openURL(`tel:${phone}`);
  };

  const formatAddress = () => {
    const street = clinic?.affiliation.address.street ?? '';
    const number = clinic?.affiliation.address.number ?? 'Sem número';
    const zipCode = clinic?.affiliation.address.zip_code ?? '';
    const city = clinic?.affiliation.address.city ?? '';
    const uf = clinic?.affiliation.address.uf ?? '';

    return `${street} - n ${number}, ${zipCode} - ${city}-${uf}`;
  };

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card height={!media.isMobile ? 769 : 'auto'} disableShadow={media.isMobile}>
          <CardHeader
            title=""
            titleElement={
              <Typography variant="heading2" color="black">
                Minha Clínica
              </Typography>
            }
            onBackPress={() => navigation.navigate('Dashboard')}
            noBorderBottom={false}
          />

          <S.WrapperBackground resizeMode="cover" />

          <S.WrapperBottom>
            <S.WrapperTextCards>
              <S.TextCard>
                <Typography variant="heading1" color="black">
                  <Icon name="map-pin" size={!media.isMobile ? 25 : 21} />{' '}
                  {clinic?.affiliation?.name ?? '--'}
                </Typography>
                <Typography variant="body1" color="black70">
                  {clinic?.affiliation?.address ? formatAddress() : '--'}
                </Typography>
              </S.TextCard>
            </S.WrapperTextCards>

            <S.WrapperSchedule>
              <S.Schedule>
                <Typography variant="body1" color="black">
                  Agendar
                </Typography>
                <S.WrapperScheduleCards>
                  <Spacing right={5} style={{ width: '50%' }}>
                    <Card height={100} style={{ width: '100%' }}>
                      <S.WrapperScheduleContent
                        onPress={() => {
                          openPhoneCall();
                        }}>
                        <S.WrapperCenterText>
                          <Typography variant="heading3" color="black">
                            <MaterialCommunityIcon
                              name="phone-in-talk-outline"
                              size={!media.isMobile ? 23 : 18}
                              color={theme?.colors?.primary}
                            />{' '}
                            Telefone
                          </Typography>
                          <Spacing top={10}>
                            <Typography variant="subtitle1" color="black70">
                              {phone}
                            </Typography>
                          </Spacing>
                        </S.WrapperCenterText>
                      </S.WrapperScheduleContent>
                    </Card>
                  </Spacing>
                  <Spacing left={5} style={{ width: '50%' }}>
                    <Card height={100} style={{ width: '100%' }}>
                      <S.WrapperScheduleContent
                        onPress={() => {
                          openWhatsApp();
                        }}>
                        <S.WrapperCenterText>
                          <Typography variant="heading3" color="black">
                            <MaterialCommunityIcon
                              name="whatsapp"
                              size={!media.isMobile ? 23 : 18}
                              color={theme?.colors?.primary}
                            />{' '}
                            WhatsApp
                          </Typography>
                          <Spacing top={10}>
                            <Typography variant="subtitle1" color="black70">
                              {whatsApp}
                            </Typography>
                          </Spacing>
                        </S.WrapperCenterText>
                      </S.WrapperScheduleContent>
                    </Card>
                  </Spacing>
                </S.WrapperScheduleCards>
              </S.Schedule>
            </S.WrapperSchedule>

            <S.WrapperContactCards>
              <S.TextCard>
                <Typography variant="heading3" color="black">
                  E-mail para contatos gerais e agendamento:
                </Typography>
                <Spacing top={5}>
                  <Typography variant="body2" color="black70">
                    <SimpleLineIcons name="envelope" size={18} color="black70" />{' '}
                    {clinic?.affiliation?.email ?? '--'}
                  </Typography>
                </Spacing>
              </S.TextCard>
            </S.WrapperContactCards>

            <S.WrapperOpeningHours>
              <S.OpeningHours>
                <S.WrapperContent>
                  <S.WrapperText>
                    <Spacing bottom={2}>
                      <Typography variant="heading3" color="black">
                        Horários de atendimento
                      </Typography>
                    </Spacing>
                    <Spacing top={5}>
                      <Typography variant="body2" color="black70">
                        Segunda a Sexta-feira - 8h às 19h
                      </Typography>
                    </Spacing>
                    <Spacing top={10}>
                      <Typography variant="body2" color="black70">
                        Sábado - 8h às 12h
                      </Typography>
                    </Spacing>
                  </S.WrapperText>
                </S.WrapperContent>
              </S.OpeningHours>
            </S.WrapperOpeningHours>
          </S.WrapperBottom>
        </Card>
        <Footer />
      </S.Container>
    </>
  );
};
