import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M6 6.21a1 1 0 001.41 0L11 2.58V23a1 1 0 001 1 1 1 0 001-1V2.59l3.62 3.62a1 1 0 001.42 0 1 1 0 000-1.41L14.13.88a3 3 0 00-4.24 0L6 4.8a1 1 0 000 1.41z"
      />
    </Svg>
  );
}

export default Icon;
