import React from 'react';
import styled from 'styled-components/native';
import { Typography } from '~/components/@hello-ui';
import { ErrorIcon } from '~/components/Icons/ErrorIcon';

interface DeeplinkErrorDialogProps {
  errorMessage: string;
}

export const DeeplinkErrorDialog = ({ errorMessage }: DeeplinkErrorDialogProps) => {
  return (
    <Container>
      <ErrorIcon height={100} width={100} />
      <Typography variant="h1">{errorMessage}</Typography>
    </Container>
  );
};

const Container = styled.View`
  padding: 20px;
  align-items: center;
  justify-content: center;
`;
