import React from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import DesktopBannerImage from '../../assets/images/benefitsPetAssistant/img_desk_pet.png';
import MobileBannerImage from '../../assets/images/benefitsPetAssistant/img_mobile_pet.png';
import IconPet from '../../assets/icons/pet.svg';
import IconPhone from '../../assets/icons/phone.svg';

import * as S from './styles';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import { Card, Collapse, Typography } from '~/components/@tem-ui';
import { StyledDefaultTheme, useMedia } from '~/components/@hello-ui';

export const BenefitsPetAssistant = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();
  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card
          width="100%"
          style={media.isDesktop ? { overflow: 'auto' } : { minHeight: 1100 }}
          height={media.isDesktop ? 1300 : 'auto'}
          disableShadow={media.isMobile}>
          <S.WrapperContainer>
            <S.WrapperHeader
              resizeMode="cover"
              source={media.isDesktop ? DesktopBannerImage : MobileBannerImage}
            />
            <S.HeaderIcon onPress={() => navigation.navigate('Dashboard')}>
              <Icon name="arrow-left" size={30} color={StyledDefaultTheme.colors.white} />
            </S.HeaderIcon>

            <S.WrapperContent>
              <S.PanelHeaderImage>
                <S.WrapperCard>
                  <Card width={!media.isMobile ? 608 : 350}>
                    <S.PanelHeaderFeatures>
                      <S.Features>
                        <S.IconPosition>
                          <IconPet />
                        </S.IconPosition>
                        <S.TitlePosition>Assistência Pet</S.TitlePosition>
                        <Typography variant="paragraph" color="black70">
                          <S.between_lines>
                            {`
Assistência emergencial para cães e gatos com atendimento telefônico 24h por dia. 
Consultas emergenciais, entrega de ração, transporte emergencial, hospedagem, 
cobertura funeral, agendamento de consultas, informações sobre vacinas e muito mais.`}
                          </S.between_lines>
                        </Typography>
                      </S.Features>
                    </S.PanelHeaderFeatures>
                  </Card>
                </S.WrapperCard>
              </S.PanelHeaderImage>
            </S.WrapperContent>
          </S.WrapperContainer>
          <S.Help>
            <Collapse title="Como funciona?">
              <Typography variant="paragraph" color="black70">
                <S.between_lines>
                  {`Os benefícios da Assistência Pet 24h são válidos nos casos de:
• Morte natural ou acidental do pet;
• Funeral com Limite: R$ 500,00 por evento e 01 intervenção por ano.
• Solicitação do usuário para envio de ração (custo da ração é de responsabilidade do usuário e deverá ser paga diretamente ao estabelecimento);
Limite: R$ 90,00 por evento e 03 intervenções por ano.
• Consulta veterinária emergencial em consequência de acidente ou doença súbita; Limite: R$ 350,00 por evento e 03 intervenções por ano.
• Transporte do animal: remoção do animal doméstico em consequência de acidente ou doença súbita;
Limite: R$ 200,00 por evento e 03 intervenções por ano.
• Hospedagem: despesas com hospedagem do animal, em estabelecimento próximo em consequência de acidente ou doença do usuário;
Limite: R$ 500,00 por evento e 03 intervenções por ano.
                     `}
                </S.between_lines>
              </Typography>
            </Collapse>
            <Collapse title="Como acessar o benefício?">
              <S.PanelContent testID="content-teleorientation">
                {Platform.OS === 'android' ? (
                  <>
                    <Typography variant="paragraph" color="black70">
                      {
                        'Para utilizar o benefício, ligue para o número abaixo e solicite a Assistência Residencial:'
                      }
                    </Typography>
                    <S.Info>
                      <S.Phone>
                        <IconPhone />
                        <Typography variant="body2" color="primary">
                          Ligar
                        </Typography>
                      </S.Phone>
                      <Typography variant="body2" color="black">
                        0800 702 9897
                      </Typography>
                    </S.Info>
                  </>
                ) : (
                  <S.Info>
                    <Typography variant="body1" color="white" style={{ textAlign: 'center' }}>
                      Para utilizar o benefício, ligue para o número abaixo e solicite a Assistência
                      Pet:
                    </Typography>
                    <S.InfoContact>
                      <Icon size={32} name="smartphone" color={StyledDefaultTheme.colors.white} />
                      <Typography variant="title3" color="white" style={{ marginLeft: 8 }}>
                        0800 702 9897
                      </Typography>
                    </S.InfoContact>
                  </S.Info>
                )}
              </S.PanelContent>
            </Collapse>
          </S.Help>
        </Card>
        <Footer />
      </S.Container>
    </>
  );
};
