import React, { useEffect, useState } from 'react';
import {
  BackgroundChip,
  DisabledText,
  EnabledText,
  IconChip,
  IconContainer,
  IconText,
} from './styles';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

interface ChipProps {
  value: string;
  text?: string;
  variant?: 'icon-click' | 'background-click';
  onSelect: (value: string) => void;
  disabled?: boolean;
  style?: any;
  shadow?: boolean;
  selected?: boolean;
}

/**
 * Chip Component
 *
 * @example
 * // Usage example:
 * const handleChipSelect = (value: string) => {
 *    console.log('Selected chip:', value);
 * };
 *
 * <Chip value="1" text="Chip 1" onSelect={handleChipSelect} />
 * <Chip
 *    value="2"
 *    text="Chip 2"
 *    variant="icon-click"
 *    onSelect={handleChipSelect}
 * />
 *
 * @param {string} props.value - The value associated with the chip.
 * @param {string} [props.text] - The text to be displayed inside the chip (optional).
 * @param {('icon-click' | 'background-click')} [props.variant='background-click'] - The variant of the chip. Can be either 'icon-click' or 'background-click'.
 * @param {(value: string) => void} props.onSelect - The callback function called when the chip is selected. It receives the chip value as an argument.
 * @param {boolean} [props.disabled=false] - Determines whether the chip is disabled or not (optional).
 * @param {any} [props.style] - Additional styles to be applied to the chip (optional).
 *
 * @returns {React.ReactNode} The rendered chip component based on the selected variant.
 */
export const Chip: React.FC<ChipProps> = ({
  value,
  text,
  variant = 'background-click',
  onSelect,
  disabled,
  style,
  shadow,
  selected = false,
}) => {
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(selected);

  const handleChipClick = () => {
    if (onSelect) {
      onSelect(value);
    }
    setIsClicked((prevIsClicked) => !prevIsClicked);
  };

  const disabledText = disabled ? <DisabledText>{text || value}</DisabledText> : null;

  useEffect(() => {
    setIsClicked(selected);
  }, [selected]);

  const renderIconClick = () => {
    return (
      <IconChip color={isClicked ? theme.colors.primary : theme.colors.white} style={{ ...style }}>
        {disabledText ?? <IconText isClicked={isClicked}>{text || value}</IconText>}
        <IconContainer onPress={handleChipClick} disabled={disabled} testID="icon-click-chip">
          <Icon
            name="x"
            color={
              disabled ? theme.colors.black16 : isClicked ? theme.colors.white : theme.colors.black
            }
            size={18}
          />
        </IconContainer>
      </IconChip>
    );
  };

  const renderBackgroundClick = () => {
    return (
      <BackgroundChip
        testID="background-click-chip"
        disabled={disabled}
        onPress={handleChipClick}
        color={isClicked ? theme.colors.primary : theme.colors.white}
        style={{ ...style }}
        shadow={shadow}>
        {disabledText ?? <EnabledText isClicked={isClicked}>{text || value}</EnabledText>}
      </BackgroundChip>
    );
  };

  const renderItem = {
    'icon-click': renderIconClick,
    'background-click': renderBackgroundClick,
  };

  return renderItem[variant]();
};
