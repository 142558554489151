import { AxiosRequestConfig } from 'axios';
import { AuthService } from '~/auth/auth-service';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { useAuthStore } from '~/store/auth.store';

const allowedErrors = ['TokenZeus não informado.'];
const refreshAuthLogic = async (failedRequest: any) => {
  if (allowedErrors.includes(failedRequest?.response?.data?.error)) {
    return;
  }

  const { token } = await AuthService.onBeforeTokenExpire();

  if (token) {
    failedRequest.response.config.headers['token'] = token;
    if (failedRequest.response.config?.params?.['token']) {
      failedRequest.response.config.params['token'] = token;
    }

    if (failedRequest.config.data) {
      failedRequest.config.data = failedRequest.config.data.replace(
        useAuthStore.getState().token,
        token,
      );
    }
    return Promise.resolve();
  } else {
    await AuthService.onTokenExpire();
    return Promise.reject(failedRequest);
  }
};

const requestInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = await AuthService.getToken();
  const deepLinkCode = DeepLink.getParams().deepLinkCode;

  request.headers.token = token;
  request.headers.tokenzeus = token;
  if (deepLinkCode) {
    request.headers['x-deeplink-code'] = deepLinkCode;
  }

  return request;
};

export { requestInterceptor, refreshAuthLogic };
