import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const WrapperWrapper = styled.View`
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    css`
      display: flex;
    `};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const PhoneNumberVariantWrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 100%;
  min-width: 357px;
  padding: 24px;
  padding-left: 0px;
  flex-direction: row;
  height: auto;
  min-height: 250px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex-direction: column;
    min-height: 100px;
    padding: 0 32px;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 0px;
  `}
`;

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 100%;
  margin-top: 76px;
  padding: 24px;
  flex-direction: row;
  height: auto;
  min-height: 200px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex-direction: column;
    min-height: auto;
    padding: 0 32px;
    padding-top: 32px;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 0px;
  `}
`;

export const Logo = styled.View<{ variant?: string }>`
  height: 56px;
  margin-bottom: 32px;

  ${({ variant }) =>
    variant === 'simple-footer' &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    align-items: center;
    justify-content: center;
  `}
`;

export const WrapperPhoneNumberVariantColumn = styled.View<{ variant?: string }>`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex: auto;
    margin-vertical: 16px;
  `}
  width:100%;
  max-width: 600px;
  height: auto;
`;

export const WrapperColumn = styled.View<{ variant?: string }>`
  flex: 1;
  ${({ variant }) =>
    variant === 'simple-footer' &&
    css`
      align-items: center;
      width: 100%;
    `}
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex: auto;
    margin-vertical: 16px;
  `}
`;

export const WrapperSocialButtons = styled.View`
  max-width: 198px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 16px auto;
    
  `}
`;

export const ButtonSocial = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;

export const SpacingContainer = styled.View<{ top?: number }>`
  padding-top: ${({ top }) => top ?? '20px'};
`;
