import React from 'react';
import { Platform } from 'react-native';
import { BenefitsCardsTypes } from '../../../Dashboard/components/Benefits/types';
import * as S from './styles';
import { CardButton } from '~/components/@hello-ui';

interface benefitsFilterType {
  benefitsFilter: BenefitsCardsTypes[];
}

export const BenefitsCard = ({ benefitsFilter }: benefitsFilterType): JSX.Element => {
  const benefits = benefitsFilter
    .filter(({ visible }) => visible)
    .map(({ title, description, footerDescription, icon }, idx: number) => {
      return (
        <S.WrapperCard key={`beneft-${idx}`}>
          <CardButton
            variant="small"
            style={{ height: Platform.OS !== 'web' ? 184 : '204px', alignItems: 'flex-start' }}
            icon={icon}
            title={title}
            description={description}
            footerDescription={footerDescription}
            key={`beneftbtn-${idx}`}
            activeOpacity={1}
            cursor="default"
          />
        </S.WrapperCard>
      );
    });

  return <S.WrapperContentCard>{benefits}</S.WrapperContentCard>;
};
