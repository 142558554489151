/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { SelectFiles } from '../ScheduleUploadExam/SelectFiles';
import { Button, Dialog, Listing, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { FileType, PickerOptionsEnum } from '~/components/@tem-ui/Uploader/types';
import { useUploader } from '~/components/@tem-ui/Uploader';
import { TypesEnum } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';

export const ScheduleUploadExamFiles = () => {
  const [dialogState, setDialogState] = useState<boolean>(false);

  const uploadField = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const { nextStep, toCurrentStep } = useStepper();
  const { files, deleteFile, addFile } = useUploader();
  const { appointment, setAppointment } = useAppointment();

  const onFilePicker = useCallback(
    async (type: PickerOptionsEnum, target?) => {
      await addFile(type, target);
      setDialogState(false);
    },
    [addFile],
  );

  const onUpload = useCallback(() => {
    if (Platform.OS === 'web') {
      uploadField?.current?.click();
    } else {
      setDialogState(true);
    }
  }, [uploadField, setDialogState]);

  useEffect(() => {
    if (files.length === 0) {
      toCurrentStep(0);
    }
  }, [files]);

  return (
    <>
      <View className="mb-40 mobile:mb-24">
        <Typography variant="title">Pedido(s) adicionado(s)</Typography>
      </View>
      <Listing
        useSeparator={false}
        hideBackToTop
        loading={false}
        data={files}
        renderItem={({ item, index }: { item: FileType; index: number }) => {
          const { name, size } = item;
          return (
            <View className="mb-8 h-[74px] w-full items-center justify-center">
              <View className="h-full w-full flex-row items-center rounded-lg bg-background-gray px-16">
                <FeatherIcon name="file" size={24} />
                <View className="ml-16 flex-1 pr-8">
                  <Typography variant="body2" numberOfLines={1}>
                    {name}
                  </Typography>
                  <Typography variant="helperText" color={theme.colors.placeholder}>
                    {size.text}
                  </Typography>
                </View>
                <TouchableOpacity
                  onPress={() =>
                    deleteFile(files, index, {
                      title: 'Excluir pedido',
                      subtitle: 'Tem certeza que deseja excluir o arquivo em anexo?',
                    })
                  }>
                  <FeatherIcon name="x" size={24} color={theme.colors.primary} />
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
      />
      {Platform.OS === 'web' && (
        <input
          multiple
          ref={uploadField}
          type="file"
          style={{ display: 'none' }}
          accept="image/*,.pdf"
          onChange={async (e) => await onFilePicker(PickerOptionsEnum.Archiver, e.target)}
        />
      )}
      <MobileAutoSpace />
      <Button
        variant="primary"
        disabled={files?.length === 0}
        onPress={() => {
          nextStep();
          // @ts-ignore
          setAppointment({ ...appointment, attatchment: files, type: TypesEnum.Exam });
        }}>
        Próximo
      </Button>
      <View className="mt-4">
        <Button variant="link" onPress={() => onUpload()}>
          Adicionar outro pedido médico
        </Button>
      </View>
      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <SelectFiles onAddFile={(type: PickerOptionsEnum) => onFilePicker(type)} />
      </Dialog>
    </>
  );
};
