import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigation } from '@react-navigation/core';
import { Image, TextInput } from 'react-native';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { PageWithCard } from '~/components/@hello-ui';
import {
  PaymentAmount,
  PaymentBillet,
  PaymentBilletSuccess,
  PaymentCreditCard,
  PaymentCreditCardSuccess,
  PaymentOptions,
} from '~/screens/TemPayRecharge/components';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { PaymentPix } from '~/screens/TemPayRecharge/components/PaymentPix';
import { PaymentPixSuccess } from '~/screens/TemPayRecharge/components/PaymentPixSuccess';

export const TemPayRecharge = (): JSX.Element => {
  const { params } = useRoute<UseRoute<'TemPayRecharge'>>();
  const navigation = useNavigation();

  const inputRef = useRef<TextInput>(null!);

  const { setRechargeAmount, rechargeStep, clearState, setRechargeStep } = useRecharge();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return clearState();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (!params?.keepState) {
        setRechargeStep(0);
      }
    }, [params]),
  );

  useEffect(() => {
    if (rechargeStep === 0) {
      setRechargeAmount(0);
    }
  }, [rechargeStep]);

  const handleGoBack = () => {
    if (rechargeStep !== 0) {
      setRechargeStep(0);
    } else {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate('Dashboard');
      }
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Recarga',
        onBack: handleGoBack,
      }}
      overrideBodyMaxWidth={[4, 5, 7].includes(rechargeStep) ? 'none' : undefined}
      overridePadding={
        [4, 5, 7].includes(rechargeStep) ? { top: 0, right: 0, bottom: 0, left: 0 } : undefined
      }>
      {rechargeStep === 0 && <PaymentAmount />}
      {rechargeStep === 1 && <PaymentOptions />}
      {rechargeStep === 2 && <PaymentCreditCard />}
      {rechargeStep === 3 && <PaymentBillet />}
      {rechargeStep === 4 && <PaymentCreditCardSuccess />}
      {rechargeStep === 5 && <PaymentBilletSuccess />}
      {rechargeStep === 6 && <PaymentPix />}
      {rechargeStep === 7 && <PaymentPixSuccess />}
    </PageWithCard>
  );
};
