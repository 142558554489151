import { fileSize } from '~/utils/strings';
import { decode as atob } from 'base-64';
import { differenceObjTypes } from './types';

const getFileInfo = (base64: string) => {
  const base64str = base64.split('base64,')[0];
  return atob(base64str).length;
};

export const getFileSize = (base64: string, size?: number) => {
  const fileInfo = size ?? getFileInfo(base64);
  return {
    text: fileSize(fileInfo.toString()),
    number: fileInfo / 1024 / 1024,
  };
};

export const fileTypeValidate = (type: string, allowedTypes?: string[]) => {
  if (!allowedTypes) {
    allowedTypes = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];
  }

  const validate = allowedTypes.find((allowedType) => allowedType === type);

  if (!validate) {
    throw new Error('Tipo de arquivo inválido');
  }
};

export const getFileType = (uri: string) => {
  const type = /[.]/.exec(uri) ? /[^.]+$/.exec(uri) : undefined;

  return type === 'pdf' ? `application/${type}` : `image/${type}`;
};

export const differenceOldAndNewValue = ({ oldValue, newValue }: differenceObjTypes) => {
  const oldVal = [];
  const newVal = [];

  let changed = false;
  Object.keys(oldValue).forEach((key) => {
    if (oldValue[key] !== newValue[key]) {
      oldVal[key] = oldValue[key];
      newVal[key] = newValue[key];

      changed = true;
    }
  });

  return changed
    ? {
      old: Object.assign({}, oldVal),
      new: Object.assign({}, newVal),
    }
    : null;
};
