import styled, { css } from 'styled-components/native';

export const Wrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    align-items: flex-start;
  `}
`;

export const WrapperList = styled.FlatList`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
  `}
`;

export const WrapperItem = styled.TouchableOpacity`
  ${({ theme: { tokens } }) => css`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: ${tokens.space.s76};
    z-index: ${tokens.zIndex.z9999};
  `}
`;

export const Divider = styled.View<{ color?: string }>`
  ${({ theme: { tokens, colors }, color }) => css`
    width: ${tokens.sizes.full};
    height: ${tokens.borderWidths.b1};
    background-color: ${color ?? colors.black16};
  `};
`;

export const NoDivider = styled.View``;

export const WrapperListScroll = styled.ScrollView<{ containerHeight: number; heightAuto?: boolean }>`
  ${({ containerHeight, theme: { tokens }, heightAuto }) => css`
    width: ${tokens.sizes.full};
    height: ${heightAuto ? 'auto' : `${containerHeight}px`};
  `};
`;
