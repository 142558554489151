import React, { useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { SvgUri } from 'react-native-svg';
import * as S from './styles';
import { CardData, CardDataLabel } from './styles';
import { DownloadComponentImage } from '~/components/@hello-ui/DownloadComponentImage';
import { useBlobUrl } from '~/hooks/blobUrl';
import { Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { abbreviateName } from '~/utils/strings';

interface CardProps {
  loading: boolean;
  name?: string;
  cardNumber?: string;
  cardVia?: string;
}

const hasSvgFile = (url: string) => url.split('.').includes('svg');
const fixURLAssets = (url: string) =>
  url.replace('s3-', '').replace('s3.amazonaws.com', 'temsaude.com');

export const PaymentCard = ({ loading, name, cardNumber, cardVia }: CardProps): JSX.Element => {
  const { onixCode } = useAuth();
  const theme = useTheme();

  const cardRef = useRef(null);

  /**
   * the "fake" parameter "t=1", helps to prevent errors
   * in the http request made through the useBlobUrl hook
   */
  const backgroundImageUrl = useBlobUrl(`${fixURLAssets(theme?.card_design_url)}?t=1`);
  const getCardIssuer = cardNumber?.replace(' ', '').substring(0, 6);

  return (
    <View className="relative mb-16 self-center mobile:mb-24">
      <S.Card ref={cardRef} isSvgFile={hasSvgFile(theme?.card_design_url as string)}>
        {loading ? (
          <S.WrapperLoading>
            <S.Loading />
          </S.WrapperLoading>
        ) : (
          <>
            {hasSvgFile(theme?.card_design_url as string) && theme.isMobile ? (
              <SvgUri
                width="274"
                height="178"
                uri={fixURLAssets(theme.card_design_url as string)}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            ) : (
              <S.ImageBackground
                source={{
                  uri: fixURLAssets(theme?.card_design_url as string) ?? backgroundImageUrl,
                }}
                resizeMode="cover"
              />
            )}
            <S.WrapperBottom>
              <Typography variant="bodyHighlight1" color="white">
                {abbreviateName(name ?? '')}
              </Typography>
              <Typography variant="bodyHighlight2" color="white">
                {cardNumber ?? '-'}
              </Typography>
              <S.CardDetailsRow>
                <View className="mr-12 flex-row">
                  <CardDataLabel variant="helperText" color="white">
                    Emissor
                  </CardDataLabel>
                  <CardData variant="bodyHighlight2" color="white">
                    {getCardIssuer ?? '-'}
                  </CardData>
                </View>

                <View className="mr-12 flex-row">
                  <CardDataLabel variant="helperText" color="white">
                    Produto
                  </CardDataLabel>
                  <CardData variant="bodyHighlight2" color="white">
                    {onixCode?.onix_code ?? '-'}
                  </CardData>
                </View>

                <CardDataLabel variant="helperText" color="white">
                  Via
                </CardDataLabel>
                <CardData variant="bodyHighlight2" color="white">
                  {cardVia?.padStart(3, '0') ?? '-'}
                </CardData>
              </S.CardDetailsRow>
            </S.WrapperBottom>
          </>
        )}
      </S.Card>
      {!loading && <DownloadComponentImage viewRef={cardRef} />}
    </View>
  );
};
