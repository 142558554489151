import { AxiosInstance } from 'axios';
import { Platform } from 'react-native';
import { useEffect, useState } from 'react';
import { Route } from '@react-navigation/native';
import DeviceInfo from 'react-native-device-info';
import { contextTrackingTypes, trackingEventType, trackingPayloadType } from './types';
import { useAuth } from '~/auth/legacy/useAuth';

export const contextTracking = (instance: AxiosInstance): contextTrackingTypes => {
  const { user, onixCode } = useAuth();

  const [screen, setScreen] = useState<Route<string, object | undefined> | undefined>(undefined);
  const [startTime, setStartTime] = useState(0);

  const addMovement = async (payload: trackingPayloadType) => {
    // ==========================
    // NOT WORKING ON PRODUCTION!
    // ==========================
    // payload.userId = payload.userId ?? user?.cpf.replace(/[^\d]/g, '') ?? null;
    // payload.onix = onixCode?.onix_code ?? null;
    // payload.origin = `HELLO_${Platform.OS}`.toUpperCase();
    //
    // if (payload.metadata && Object.keys(payload.metadata).length) {
    //   payload.metadata = { ...payload.metadata, ...userInfo(), ...deviceInfo() };
    // } else {
    //   payload.metadata = { ...userInfo(), ...deviceInfo() };
    // }
    //
    // return instance.post('v1/tracking/add-movement', payload);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (startTime !== 0) {
        const agora = new Date();
        const diferenca = agora.getTime() - startTime.getTime();

        const tempoEmSegundos = Math.floor(diferenca / 1000);
        if (tempoEmSegundos > 60) {
          void addMovement({
            event: trackingEventType.SameScreenMoreThan1Minute,
            metadata: { route: screen },
          });

          clearInterval(timer);
        }
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [screen]);

  const startTimerTracker = (_screen: Route<string, object | undefined> | undefined) => {
    if (_screen !== undefined && _screen?.key !== screen?.key) {
      setStartTime(new Date());
      setScreen(_screen);
    }
  };

  const deviceInfo = () => {
    let metadata = {};

    if (Platform.OS !== 'web') {
      metadata = {
        app_version: DeviceInfo.getVersion(),
        readable_version: DeviceInfo.getReadableVersion(),
        system_name: DeviceInfo.getSystemName(),
        system_version: DeviceInfo.getSystemVersion(),
      };
    } else {
      metadata = {
        browser_codename: navigator.appCodeName,
        browser_name: navigator.appName,
        browser_version: navigator.appVersion,
        cookies_enabled: navigator.cookieEnabled,
        system_name: navigator.platform,
        user_agent: navigator.userAgent,
      };
    }

    return { device_info: metadata };
  };

  const userInfo = () => {
    const metadata = {
      name: user?.name,
      email: user?.email,
      phone: user?.telephone_1,
    };

    return { user_info: metadata };
  };

  return {
    addMovement,
    startTimerTracker,
  };
};
