import React from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';
import { View } from 'react-native';
import { Benefits } from './components/Benefits';
import { RequestSchedule } from './components/RequestSchedule/RequestSchedule';
import { HealthAccount } from './components/HealthAccount/HealthAccount';
import { Services } from './components/Services/Services';
import * as S from './styles';
import { PoweredBy } from './components/PoweredBy';
import { Loader, Typography } from '~/components/@hello-ui';
import { capitalize, split } from '~/utils/strings';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Navbar } from '~/components/@hello-ui/Theme/components/Navbar';
import { Logo } from '~/components/Logo';

import { useAuth } from '~/auth/legacy/useAuth';
import { useTemPay } from '~/hooks/temPay';
import { useAuthStore } from '~/store/auth.store';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export const Dashboard = (): JSX.Element => {
  const theme = useTheme();
  const { user } = useAuth();
  const { authStrategy } = useAuthStore();
  const messageName = user?.social_name ? user.social_name : user?.name;
  const name = capitalize(split(messageName ?? '')[0]);

  const { loadCard, card } = useTemPay();

  const renderHeader = () => {
    return (
      <S.HeaderContainer>
        <S.LogoWrapper>
          <Logo height={32} width={100} />
        </S.LogoWrapper>
      </S.HeaderContainer>
    );
  };

  useFocusEffect(
    React.useCallback(() => {
      void loadCard();
    }, []),
  );

  return (
    <>
      <PageWithCard
        overrideHeader={theme.isMobile ? renderHeader : undefined}
        variant={theme.isMobile ? PageWithCardVariant.Simple : PageWithCardVariant.Headerless}>
        <Typography variant="h3" color={theme.colors.black}>
          {`Olá, ${name}`}
        </Typography>

        {authStrategy === EAuthStrategy.Certificate && (
          <Typography variant="subtitle" color={theme.colors.black}>
            {card?.numero_cartao ? (
              <>
                Número da conta:{' '}
                <Typography variant="subtitle" className="font-normal" color={theme.colors.black}>
                  {card?.numero_cartao.replace(/(\d{4})/g, '$1 ')}
                </Typography>
              </>
            ) : (
              <Loader variant="circle-loader" />
            )}
          </Typography>
        )}
        <S.WrapperContent>
          {authStrategy !== EAuthStrategy.Certificate && (
            <>
              <RequestSchedule />
              <Benefits />
            </>
          )}
          <HealthAccount />
          <Services />
        </S.WrapperContent>
        {theme.isMobile && <PoweredBy />}
        {theme.isMobile && (
          <View
            className={clsx(authStrategy === EAuthStrategy.Certificate ? '-mb-24' : 'h-[46px]')}
          />
        )}
      </PageWithCard>
      {theme.isMobile && authStrategy !== EAuthStrategy.Certificate && <Navbar />}
    </>
  );
};
