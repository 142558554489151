import React, { useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { clsx } from 'clsx';
import { Dimensions, ScrollView, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useStatusBarHeight } from '~/hooks/useStatusBarHeight';
import { Loader, PageWithCard, Select, Typography } from '~/components/@hello-ui';
import { useTemPay } from '~/hooks/temPay';
import { EmptyPage } from '~/screens/TemPayStatement/components/EmptyPage';
import { SelectItem } from '~/components/@hello-ui/Select/types';
import { CardStatementType } from '~/contexts/types';
import { Statement } from '~/screens/TemPayStatement/components/Statement';
import { ToTopButton } from '~/components/@hello-ui/Listing/components/toTop';

const MONTH_BY_INDEX = {
  '0': 'Janeiro',
  '1': 'Fevereiro',
  '2': 'Março',
  '3': 'Abril',
  '4': 'Maio',
  '5': 'Junho',
  '6': 'Julho',
  '7': 'Agosto',
  '8': 'Setembro',
  '9': 'Outubro',
  '10': 'Novembro',
  '11': 'Dezembro',
};

export const TemPayStatement = (): JSX.Element => {
  const navigation = useNavigation();
  const { statement, loadingStatement } = useTemPay();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [pageContentHeight, setPageContentHeight] = useState(0);
  const theme = useTheme();
  const statusBarHeight = useStatusBarHeight();

  const filteredStatements = statement?.filter(
    (item) => item.tipo_transacao !== 'AQUISICAO_CARTAO',
  );
  const scrollRef = useRef<ScrollView>(null);

  const getPossibleYearsForSelect = () => {
    const years = new Set<number>();

    filteredStatements?.forEach((item) => {
      const year = new Date(item.data_transacao).getFullYear();
      years.add(year);
    });

    return Array.from(years).map((year) => ({ label: String(year), value: year }));
  };

  const onSelectYear = (item: SelectItem) => {
    setSelectedYear(item.value as number);
  };

  const getStatementByYear = () => {
    return (
      filteredStatements?.filter(
        (item) => new Date(item.data_transacao).getFullYear() === selectedYear,
      ) ?? []
    );
  };

  const getStatementByMonthDesc = () => {
    const statementsByYear = getStatementByYear();

    return statementsByYear.reduce(
      (acc, statement) => {
        const month = new Date(statement.data_transacao).getMonth();
        return {
          ...acc,
          [month]: [...(acc[month] ?? []), statement],
        };
      },
      {} as Record<string, CardStatementType[]>,
    );
  };

  const calcBodyHeight = () => {
    const height = Dimensions.get('window').height;
    const headerHeight = theme.isMobile ? 68 : 105;
    const topSpace = theme.isMobile ? 0 : 40;
    const bottomSpace = theme.isMobile ? 0 : 40;
    const verticalPadding = theme.isMobile ? 24 * 2 : 40 * 2;

    return height - headerHeight - topSpace - verticalPadding - bottomSpace - statusBarHeight;
  };

  const isToShowBackToTop = () => {
    const pageHeight = calcBodyHeight();
    const selectHeight = theme.isMobile ? 77 : 88;
    const padding = theme.isMobile ? 8 : 24;

    return pageHeight < pageContentHeight + selectHeight + padding;
  };

  function invertArray<T>(array: T[]) {
    const newArray = [];
    for (let i = array.length - 1; i >= 0; i--) {
      newArray.push(array[i]);
    }
    return newArray;
  }

  return (
    <PageWithCard
      simpleOptions={{
        onBack: () => navigation.navigate('TemPayDashboard'),
        title: 'Extrato',
      }}
      overrideScrollEnabled={false}>
      {loadingStatement ? (
        <Loader variant="circle-loader" />
      ) : (
        <>
          {(filteredStatements?.length ?? 0) === 0 ? (
            <EmptyPage />
          ) : (
            <View style={{ height: calcBodyHeight() }}>
              <View className="w-1/2">
                <Select
                  label="Ano"
                  value={{ label: String(selectedYear), value: selectedYear }}
                  items={getPossibleYearsForSelect()}
                  search={false}
                  placeholder={String(new Date().getFullYear())}
                  onSelect={onSelectYear}
                />
              </View>

              <View className="h-24 mobile:h-8" />
              <ScrollView
                className="flex-1"
                onContentSizeChange={(_w, height) => setPageContentHeight(height)}
                ref={scrollRef}>
                {invertArray(Object.entries(getStatementByMonthDesc())).map(
                  ([month, statements], index) => (
                    <>
                      <Typography
                        variant="subtitle"
                        className={clsx('mb-8', index !== 0 && 'mt-32')}>
                        {MONTH_BY_INDEX[month as keyof typeof MONTH_BY_INDEX]}
                      </Typography>
                      {statements.map((statement, statementIndex) => (
                        <Statement
                          statement={statement}
                          key={`${statement.tipo_transacao}-${statement.data_transacao}-${statement.saldo_d}`}
                          showTopLine={statementIndex === 0}
                        />
                      ))}
                    </>
                  ),
                )}
                {isToShowBackToTop() && (
                  <>
                    <View className="h-40 mobile:h-24" />
                    <ToTopButton scrollRef={scrollRef} isScroll />
                  </>
                )}
              </ScrollView>
            </View>
          )}
        </>
      )}
    </PageWithCard>
  );
};
