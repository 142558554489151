import { css } from 'styled-components';
import styled from 'styled-components/native';

export const FirstRow = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const WrapperContentCard = styled.View`
  ${({ theme: { isMobile } }) => css`
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -16px;

    ${isMobile &&
    css`
      margin-left: -4px;
      margin-right: -4px;
      margin-bottom: -8px;
    `}
  `}
`;
