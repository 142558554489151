import React, { Fragment } from 'react';
import { useTheme } from 'styled-components/native';

import * as S from '../styles';
import { Spacing } from '../../../components/Spacing';
import { Typography } from '../../../components/@tem-ui';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

interface ContentContact {
  title: string;
  description: string;
}

export const FooterPhones: React.FC = () => {
  const theme = useTheme();
  const { showFooter, isDeepLink } = DeepLink.useParams();

  if (isDeepLink && !showFooter) {
    return <></>;
  }

  let footerPhones = [];
  if (theme?.footer_phones !== '') {
    footerPhones = theme?.footer_phones ?? [];
  }

  return (
    <S.WrapperFooter>
      <S.WrapperColumn>
        {footerPhones.map((item: ContentContact, idx: number) =>
          idx === 0 ? (
            <Fragment key={idx.toString()}>
              <Typography variant="heading2" color="black">
                {item.title}
              </Typography>
              <Typography variant="body2" color="black70">
                {item.description}
              </Typography>
            </Fragment>
          ) : (
            <Spacing top={16} key={idx.toString()}>
              <Typography variant="body2" color="link" underline>
                {item.title}
              </Typography>
              <Typography variant="body2" color="black70">
                {item.description}
              </Typography>
            </Spacing>
          ),
        )}
      </S.WrapperColumn>
    </S.WrapperFooter>
  );
};
