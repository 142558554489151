import React from 'react';
import { Calendar, LocaleConfig, CalendarProps } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

import './CalendarConfig';

LocaleConfig.defaultLocale = 'br';

interface CalendarInputProps {
  onSelect: (day: CalendarProps | string) => void;
}

export const CalendarInput = ({ onSelect }: CalendarInputProps): JSX.Element => {
  const theme = useTheme();
  // console.log('roda ai code push');
  return (
    <Calendar
      theme={{
        agendaDayNumColor: theme.colors.gray70,
        todayBackgroundColor: theme.colors.primary,
        todayTextColor: theme.colors.white,
        textDayFontFamily: 'Poppins',
        textDayHeaderFontFamily: 'Poppins',
        // textDayHeaderFontSize: 20,
      }}
      renderArrow={(direction) =>
        direction === 'left' ? (
          <Icon name="chevron-left" size={18} color={theme?.colors?.primary} />
        ) : (
          <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
        )
      }
      // Customize the appearance of the calendar
      style={{
        borderRadius: 10,
        height: '100%',
        width: '100%',
        padding: 10,
      }}
      // Specify the current date
      current={Date()}
      onDayPress={(day) => {
        onSelect(day);
      }}
      // Mark specific dates as marked
      markedDates={{}}
    />
  );
};
