import React, { ReactNode } from 'react';

import { Platform } from 'react-native';

import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';

import { WEB_MAP_API_KEY } from '@env';

interface WebProvidersProps {
  children: ReactNode;
}

export const WebProviders = ({ children }: WebProvidersProps) => {
  if (Platform.OS === 'web') {
    return (
      <>
        <GoogleMapsAPIProvider apiKey={WEB_MAP_API_KEY}>{children}</GoogleMapsAPIProvider>
      </>
    );
  }

  return <>{children}</>;
};
