import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';

interface RadioProps {
  label: string;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  disabled?: boolean;
}

export const RadioBox = ({ label, selected, onSelect, disabled = false }: RadioProps) => {
  const theme = useTheme();

  const borderColor = selected ? theme.colors.primary : theme.colors['gray-placeholder'];
  const backgroundColor = selected ? theme.colors.primary : theme.colors.white;

  return (
    <>
      <TouchableOpacity onPress={() => onSelect(!selected)} disabled={disabled}>
        <View className="flex-row items-center">
          <View
            style={{
              borderColor: disabled ? theme.colors['gray-disabled'] : borderColor,
            }}
            className="mr-8 h-16 w-16 items-center justify-center rounded-full border-2">
            <View
              style={{
                backgroundColor: disabled
                  ? selected
                    ? theme.colors['gray-disabled']
                    : theme.colors.white
                  : backgroundColor,
              }}
              className="h-8 w-8 items-center justify-center rounded-full"
            />
          </View>
          <Typography variant="body2">{label}</Typography>
        </View>
      </TouchableOpacity>
    </>
  );
};
