import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { Typography } from '~/components/@tem-ui';

const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.gray10};
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.primary,
})``;

export const Empty = ({ isVisibleSpecialty, loading }: boolean): JSX.Element => (
  <Wrapper>
    {loading ? (
      <Loading />
    ) : (
      <>
        <Icon name="search" size={72} color={StyledDefaultTheme.colors.gray20} />
        <Typography variant="body2" color="black70" style={{ textAlign: 'center' }}>
          {'Escolha a Localização'}
          {isVisibleSpecialty && 'e a\nEspecialidade que você precisa'}
        </Typography>
      </>
    )}
  </Wrapper>
);
