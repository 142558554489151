import React from 'react';
import { Linking } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Button, Typography, useSweetAlert } from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';

interface FooterMobileButtonProps {
  title?: string;
}

export const FooterMobileButton: React.FC<FooterMobileButtonProps> = ({ title }) => {
  const theme = useTheme();
  const { showSweetAlert } = useSweetAlert();

  const redirectsToDialer = () => {
    try {
      const allPhones =
        theme?.footer_phones && typeof theme?.footer_phones !== 'string' && theme?.footer_phones;

      Linking.openURL(`tel:${allPhones[1]?.title}`);
    } catch (err) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Erro ao abrir o discador do dispositivo',
        'error',
        true,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
        },
      );
    }
  };

  return (
    <>
      {title && (
        <Typography variant="bodyHighlight2" className="mb-8 text-center">
          {title}
        </Typography>
      )}
      <Button icon={<CallIcon />} variant="primary" onPress={() => redirectsToDialer()}>
        Central de Atendimento
      </Button>
    </>
  );
};
