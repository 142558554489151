import React, { useEffect, useState, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import MapView from 'react-native-maps';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import * as S from './styles';

import { SearchBar } from './components/SearchBar';
import { Search } from './components/Search';
import { Clinics } from './components/Clinics';
import { Empty } from './components/Empty';
import { NotNetworkSearch } from './components/NotNetworkSearch';
import { Specialties } from '~/components/Dialog/Specialties';
import { useApi } from '~/hooks/api';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import { Panel, useDialog } from '~/components/@tem-ui';
import { useGeolocation } from '~/components/@hello-ui/Adresses/Geolocation';
import { useMedia } from '~/components/@hello-ui';
import { NavigationProps } from '~/@types/navigation/routes-helpers';

interface BenefitsNetworkSearchProps extends NavigationProps<'BenefitsNetworkSearch'> {}

export const BenefitsNetworkSearch = ({ route }: BenefitsNetworkSearchProps): JSX.Element => {
  const api = useApi();
  const media = useMedia();
  const navigation = useNavigation();

  const { params } = route;
  const { openDialog, closeDialog } = useDialog();
  const { geolocationAddress, getGeolocationAddress } = useGeolocation();

  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [district, setDistrict] = useState<string | null>(null);
  const [speciality, setSpeciality] = useState<string | null>(null);
  const [showNotClinics, setShowNotClinics] = useState<boolean>(false);
  const [showLocalization, setShowLocalization] = useState<boolean>(false);
  const [localization, setLocalization] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const keyExtractor = useCallback((item, idx) => idx.toString(), []);
  const renderClinics = useCallback(({ item }) => <Clinics {...item} />, []);

  const handleClinics = useCallback(async () => {
    setLoading(true);
    setClinics([]);

    try {
      if (speciality && localization) {
        const { data } = await api.searchNetwork({
          latitude: localization.latitude.toString(),
          longitude: localization.longitude.toString(),
          distancia: 30,
          especialidadeMedica: speciality,
        });

        data.length === 0 && setShowNotClinics(true);
        setClinics(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [speciality, localization]);

  useEffect(() => {
    if (Object.keys(geolocationAddress).length) {
      const { neighborhood, latitude, longitude } = geolocationAddress;

      if (latitude && longitude) {
        setLocalization({ latitude, longitude });
      }

      speciality && void handleClinics();

      setDistrict(neighborhood ?? '');
      setLoading(false);
    }
  }, [geolocationAddress]);

  useEffect(() => {
    if (localization && speciality) {
      void handleClinics();
    }
  }, [localization, speciality]);

  useEffect(() => {
    if (speciality !== null) {
      closeDialog();
    }
    params?.type === 'laboratory' && setSpeciality('analises clinicas');
    params?.type === 'dentist' && setSpeciality('odontologia');
  }, [speciality]);

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Rede de Saúde"
          onBack={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('Dashboard');
            }
          }}>
          <S.WrapperMap>
            {!showLocalization && (
              <>
                <S.WrapperSearchBar>
                  <SearchBar
                    district={district}
                    speciality={speciality}
                    isVisibleSpecialty={params.type === 'clinic'}
                    onPress={() => {
                      setShowLocalization(true);
                      if (district) {
                        setSpeciality(null);
                        setDistrict(null);
                        setLocalization(null);
                      }
                    }}
                    onPressSpeciality={() =>
                      openDialog(
                        'Qual especialidade?',
                        <Specialties onSelect={(s) => setSpeciality(s.name)} />,
                      )
                    }
                    onLocalization={() => getGeolocationAddress()}
                  />
                </S.WrapperSearchBar>
              </>
            )}

            {!loading && !showLocalization && showNotClinics && (
              <NotNetworkSearch type={params?.type} />
            )}

            {(!showLocalization && speciality === null) ||
            (!showLocalization &&
              params.type !== 'clinic' &&
              clinics.length === 0 &&
              !showNotClinics) ? (
              <Empty isVisibleSpecialty={params?.type === 'clinic'} loading={loading} />
            ) : (
              speciality &&
              !showLocalization &&
              clinics.length > 0 &&
              !showNotClinics && (
                <>
                  <S.WrapperClinics>
                    <S.ListClinics
                      horizontal
                      showsHorizontalScrollIndicator={media.isMobile ? false : true}
                      data={clinics}
                      keyExtractor={keyExtractor}
                      renderItem={renderClinics}
                      ItemSeparatorComponent={S.DividerClinics}
                    />
                  </S.WrapperClinics>
                  {!loading && (
                    <S.WrapperMapElement>
                      <MapView
                        initialRegion={{
                          ...localization,
                          latitudeDelta: 0.01,
                          longitudeDelta: 0.01,
                        }}
                        style={{ height: '100%' }}
                        toolbarEnabled={false}
                        showsMyLocationButton={false}
                        showsCompass={false}
                        showsScale={false}
                        zoomControlEnabled={false}>
                        {clinics.length > 0 &&
                          clinics.map((clinic, idx) => (
                            <MapView.Marker
                              key={`marker_${idx}`}
                              coordinate={{
                                latitude: parseFloat(clinic.credenciado[0]['LATITUDE']),
                                longitude: parseFloat(clinic.credenciado[0]['LONGITUDE']),
                              }}
                              title={clinic.credenciado[0]['NOME FANTASIA']}
                            />
                          ))}
                      </MapView>
                    </S.WrapperMapElement>
                  )}
                </>
              )
            )}
            {showLocalization && (
              <Search
                onLocalization={({ latitude, longitude, district }) => {
                  setLocalization({ latitude, longitude });
                  setDistrict(district.title);
                  setShowLocalization(false);
                }}
              />
            )}
          </S.WrapperMap>
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};
