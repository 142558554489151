import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { CardTypes } from './types';

const WrapperVariations = {
  web: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: ${({ theme: { colors } }) => colors.black};
    shadow-offset: ${Platform.OS === 'ios' ? '{width: 5, height: 5}' : '{width: 1, height: 2}'};
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
  `,
};

export const WrapperCard = styled.View<CardTypes>`
  ${Platform.OS === 'web' && 'width: calc(100% - 64px)'}
  ${(props) => props.width && `width:${props.width}${typeof props.width !== 'string' ? 'px' : ''}`};
  ${(props) =>
    props.height && `min-height:${props.height}${typeof props.height !== 'string' ? 'px' : ''}`};
  border-radius: 10px;
  flex-direction: column;
  max-width: 1240px;
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-top: ${(props) => `${props.marginTop ?? 32}px`};
  ${({ theme: { isMobile }, ...props }) =>
    isMobile &&
    `
    width: 100%;
    ${props.width && `width:${props.width}${typeof props.width !== 'string' ? 'px' : ''}`};
      
    margin-top: 0px;
  `}
  ${(p) => p.disabled && 'opacity: 0.2;'}
  ${(p) =>
    !p.disableShadow &&
    `
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    elevation: 2;
  `}

  ${({ theme: { isMobile } }) =>
    isMobile ? WrapperVariations['mobile'] : WrapperVariations['web']}
`;

export const WrapperHeader = styled.View<{ noBorderBottom: boolean }>`
  width: 100%;
  height: 86px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors }, noBorderBottom }) =>
    noBorderBottom ? colors.white : colors.black16};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  position: relative;
`;

export const BackButton = styled.TouchableOpacity`
  margin-left: 16px;
  height: 100%;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  z-index: 1;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const Title = styled.Text`
  font-size: 24px;
  font-weight: bold;
  font-family: Inter;
`;

export const WrapperTitle = styled.View`
  flex: 1;
  height: 86px;
  justify-content: center;
`;

export const HelpButton = styled.TouchableOpacity`
  margin-left: 24px;
  margin-right: 32px;
`;

export const WrapperDescription = styled.View`
  width: 100%;
  max-height: 112px;
  max-width: 608px;
  margin: 0 auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
    max-height: 136px;
  `}
`;

export const DescriptionTitle = styled.Text`
  color: ${({ theme: { colors } }) => colors.black80};
  font-size: 20px;
  font-family: Inter;
  font-weight: 600;
  width: 100%;
  margin: 6px 0;
  margin-top: 28px;
`;

export const Description = styled.Text`
  color: ${({ theme: { colors } }) => colors.black70};
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin: 6px 0;
  margin-bottom: 28px;
`;

export const WrapperBody = styled.View`
  width: 100%;
  max-width: 608px;
  margin: 0 auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
  `}
`;
