import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components/native';

import Icon from 'react-native-vector-icons/dist/Feather';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import { clsx } from 'clsx';
import { Card } from '../Card';
import { Typography } from '../Typography';
import * as S from './styles';

interface CardButtonProps {
  variant?: 'xsmall' | 'small' | 'default' | 'large' | 'xlarge';
  icon?: ReactNode;
  title: string;
  description?: string;
  footerDescription?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  activeOpacity?: number;
  /**
   * @description If true, the icon will be displayed along with the title (same row)
   */
  iconSideOfTitle?: boolean;
  cursor?: 'pointer' | 'default';
}

export const CardButton = ({
  variant,
  icon,
  title,
  description,
  footerDescription,
  onPress,
  style,
  activeOpacity,
  iconSideOfTitle,
  cursor,
}: CardButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Card
      style={{
        flexDirection: 'row',
        alignItems: !description && variant !== 'xlarge' ? 'center' : 'flex-end',
        ...(style as ViewStyle),
      }}
      variant={variant}>
      <S.WrapperCardButton
        size={variant}
        orientation={variant === 'large' ? 'row' : 'column'}
        activeOpacity={activeOpacity}
        onPress={onPress}
        cursor={cursor}>
        {!iconSideOfTitle && (
          <S.WrapperIcon size={variant} hasIcon={!!icon}>
            {icon}
          </S.WrapperIcon>
        )}
        <S.WrapperText>
          <View
            className={clsx('flex-row items-center', variant === 'large' && description && 'mb-4')}>
            {iconSideOfTitle && (
              <S.WrapperIcon size={variant} hasIcon={!!icon}>
                {icon}
              </S.WrapperIcon>
            )}
            <Typography
              variant={variant === 'xsmall' ? 'helperText' : 'bodyHighlight2Bold'}
              color={variant === 'xsmall' ? theme.colors.paragraph : theme?.colors?.black}
              style={{ marginBottom: Platform.OS !== 'web' ? -2 : 0 }}>
              {title}
            </Typography>
          </View>

          {description && (
            <Typography
              variant={variant === 'xsmall' ? 'titleXsmallBold' : 'helperText'}
              color={variant === 'xsmall' ? theme.colors.black : theme.colors.paragraph}
              style={{ marginBottom: Platform.OS !== 'web' ? -1 : 0 }}>
              {description}
            </Typography>
          )}
          {footerDescription && (
            <Typography variant="description" color={theme?.colors?.black}>
              {footerDescription}
            </Typography>
          )}
        </S.WrapperText>
      </S.WrapperCardButton>
      {(variant === 'xlarge' || variant === 'large') && (
        <Icon
          name="chevron-right"
          size={28}
          color={theme?.colors?.primary}
          testID="chevron-icon"
          style={{
            position: 'absolute',
            right: 10,
            bottom: variant === 'large' && description ? 12 : 14,
          }}
          onPress={onPress}
        />
      )}
    </Card>
  );
};
