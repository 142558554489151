import React from 'react';
import { Linking } from 'react-native';
import { ItemMenuTypes } from './types';
import * as S from './styles';
import {
  HomeIcon,
  UserIcon,
  FamilyIcon,
  SignOutIcon,
  PlusIcon,
  TermIcon,
  ScheduleIcon,
  CreditCardIcon,
} from './MenuIcons';
import { Typography } from '~/components/@tem-ui';
import { useMenu } from '~/components/@tem-ui/Menu';

import SignatureIcon from '~/assets/images/assinatura_icon.svg';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { trackingEventType } from '~/contexts/tracking/types';
import { useTracking } from '~/services/tracking';
import { AuthService } from '~/auth/auth-service';
import { useAuth } from '~/auth/legacy/useAuth';

const menuItems = (): ItemMenuTypes[] => {
  const { signature, checkIsEnabledFeature, isDependent } = useAuth();
  const { clearState } = useMyAppointments();

  const isDisabled = (name: string) => {
    return !checkIsEnabledFeature(name);
  };

  const items: ItemMenuTypes[] = [
    {
      icon: <HomeIcon />,
      title: 'Início',
      route: 'Dashboard',
      description: 'tela inicial',
    },
    {
      icon: <SignatureIcon />,
      title: 'Assinatura',
      route: 'Signature',
      description: 'detalhes do plano e benefícios',
      disabled: isDisabled('signature'),
      visible: signature?.plan ? true : false,
    },
    {
      icon: <UserIcon />,
      title: 'Perfil',
      route: 'Profile',
      description: 'dados pessoais, endereço, senha de acesso',
    },
    {
      icon: <CreditCardIcon />,
      title: 'Meus cartões',
      route: 'MyCreditCards',
      description: 'Cartões de crédito',
      disabled: false,
    },
    {
      icon: <FamilyIcon />,
      title: 'Programa Familiar',
      route: 'DependentsPresentation',
      description: 'adicionar e remover dependentes',
      disabled: isDependent === null || isDependent || isDisabled('dependents'),
      tracking: trackingEventType.ClickedOnFamilyProgram,
    },
    {
      icon: <PlusIcon />,
      title: 'Meus Benefícios',
      route: 'BenefitsPresentation',
      description: 'benefícios e vantagens do meu plano',
      visible: signature?.plan ? false : true,
      tracking: trackingEventType.ClickedOnMyBenefits,
    },
    {
      icon: <ScheduleIcon />,
      title: 'Meus agendamentos',
      route: 'MyAppointments',
      description: 'Acompanhar minhas consultas e exames',
      visible: true,
      runBefore: () => {
        clearState();
      },
      tracking: trackingEventType.ClickedOnMyAppointments,
    },
    {
      icon: <TermIcon />,
      title: 'Regulamentos e Privacidade',
      route: 'RegulationAndPrivacy',
      description: 'condições gerais de uso do benefício',
      disabled: false,
    },
    {
      icon: <SignOutIcon />,
      title: 'Sair',
      description: 'sair da conta',
      tracking: trackingEventType.Logout,
      runBefore: async () => {
        await AuthService.logout();
      },
    },
  ];

  return items;
};

export const MenuItem = ({ route, navigation }: ItemMenuTypes): JSX.Element => {
  const { closeMenu } = useMenu();
  const { tracking } = useTracking();

  return (
    <>
      {menuItems().map((item: ItemMenuTypes, idx: number) =>
        item?.visible !== false ? (
          <S.Wrapper
            screenRoute={route === item.route}
            key={idx}
            disabled={item.disabled}
            onPress={() => {
              if (item.tracking) {
                void tracking.addMovement({
                  event: item.tracking,
                });
              }

              if (item.runBefore) {
                item.runBefore();
              }

              if (item.routeExternal) {
                Linking.openURL(item.route);
              } else {
                closeMenu();

                if (item.route) {
                  setTimeout(() => navigation?.navigate(item.route), 50);
                }
              }
            }}>
            {item.icon}
            <S.WrapperText>
              <Typography variant="heading3" color="black">
                {item.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color="black60"
                numberOfLines={1}
                ellipsizeMode="tail">
                {item.description}
              </Typography>
            </S.WrapperText>
          </S.Wrapper>
        ) : (
          <></>
        ),
      )}
    </>
  );
};
