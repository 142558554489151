import React, { PropsWithChildren } from 'react';
import { Platform, View } from 'react-native';
import { clsx } from 'clsx';
import { useTheme } from 'styled-components/native';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

interface PageLimitProps extends PropsWithChildren {
  overridePageMaxWidth?: string | number;
}

export const PageLimit: React.FC<PageLimitProps> = ({ children, overridePageMaxWidth }) => {
  const { isMobile, isTablet } = useTheme();
  const authStrategy = useAuthStore((st) => st.authStrategy);

  const transformedOverridePageMaxWidth =
    Platform.OS === 'web'
      ? overridePageMaxWidth
      : overridePageMaxWidth === 'none'
        ? undefined
        : overridePageMaxWidth;

  const commonStyle = {
    maxWidth: isMobile
      ? undefined
      : [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy)
        ? isTablet
          ? 562
          : 712
        : 894,
  };

  return (
    <View
      className={clsx(
        'flex-1 mobile:bg-amber-950',
        [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy) &&
          Platform.OS === 'web' &&
          !isMobile &&
          'w-[100vw]',
      )}
      style={
        overridePageMaxWidth
          ? { ...commonStyle, maxWidth: transformedOverridePageMaxWidth }
          : commonStyle
      }>
      {children}
    </View>
  );
};
