import {
  Certified,
  Pharmacy,
  SearchTemFarmaRes,
  SearchCertifiedTemRedeRes,
} from '~/contexts/types';

export interface ListItemAddress {
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  zipCode: string;
  latitude: number;
  longitude: number;
}

export interface ListItem<TData> {
  id: string;
  name: string;
  telephone?: string;
  address: ListItemAddress;
  data?: TData;
}

const postalCodeFormat = (data: string) => {
  return `${data.slice(0, 4)}-${data.slice(4)}`;
};

const CertifiedToLocation = (data: Certified): ListItem<Certified> => {
  return {
    id: String(data.id),
    name: data.trade_name,
    address: {
      state: data.state,
      city: data.city,
      neighborhood: data.neighborhood,
      street: `${data.street}${data.number ? `, ${data.number}` : ''}`,
      latitude: data.lat,
      longitude: data.long,
      zipCode: postalCodeFormat(data.postal_code),
    },
    data,
  };
};

const PharmacyToLocation = (data: Pharmacy): ListItem<Pharmacy> => {
  return {
    id: data.id,
    name: data.store_name.toLowerCase(),
    telephone: undefined,
    address: {
      state: data.state,
      city: data.city,
      neighborhood: data.neighborhood,
      street: data.street,
      zipCode: postalCodeFormat(data.postal_code),
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
    },
    data,
  };
};

export const PharmaciesToMapLocationsDTO = ({ data }: SearchTemFarmaRes) => {
  return data.map((pharmacy) => PharmacyToLocation(pharmacy));
};

export const CertifiedsToMapLocationsDTO = ({ data }: SearchCertifiedTemRedeRes) => {
  return data.map((certified) => CertifiedToLocation(certified));
};
