import React from 'react';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useGeolocation } from '../hooks';
import * as S from './styles';
import { GeolocationButtonProps } from './types';
import { Typography } from '~/components/@hello-ui';

export const GeolocationButton = ({ color }: GeolocationButtonProps): JSX.Element => {
  const theme = useTheme();
  const { getGeolocationAddress } = useGeolocation();

  return (
    <S.GeoCard onPress={() => getGeolocationAddress(color)} backgroundColor="white">
      <S.WrapperButtonLayout>
        <S.CardIcon>
          <Icon name="crosshairs-gps" size={23} color={theme.colors[color ?? 'primary']} />
        </S.CardIcon>
        <S.CardText>
          <Typography variant="button" color={theme.colors.paragraph}>
            Usar meu local
          </Typography>
        </S.CardText>
      </S.WrapperButtonLayout>
    </S.GeoCard>
  );
};
