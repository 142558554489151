import styled from 'styled-components/native';

export const TopLoadingBarContainer = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9;
  width: 100%;
`;
