/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PermissionsAndroid, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import Geolocation from 'react-native-geolocation-service';
import { useSweetAlert } from '../../SweetAlert';

import {
  AddressType,
  GeolocationProviderType,
  GeolocationAddressType,
  GeolocationType,
} from './types';
import { useApi } from '~/hooks/api';
import { Loader, Typography } from '~/components/@hello-ui';
import { SweetAlertColor } from '~/components/@hello-ui/SweetAlert/types';

const GeolocationContext = createContext<GeolocationAddressType | null>(null);

export const GeolocationProvider = ({ children }: GeolocationProviderType): JSX.Element => {
  const api = useApi();
  const theme = useTheme();

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState<boolean>(false);
  const [geolocationAddress, setGeolocationAddress] = useState<AddressType>({});

  const requestLocationPermission = async () => {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      {
        title: `"${theme?.partner_full_name}" Gostaria de usar sua Localização`,
        message: 'Para preencher os dados de endereço e localização automaticamente.',
        buttonNegative: 'Negar',
        buttonPositive: 'Permitir',
      },
    );

    return granted === PermissionsAndroid.RESULTS.GRANTED;
  };

  const getPermissions = async () => {
    if (Platform.OS === 'android') {
      return await requestLocationPermission();
    } else if (Platform.OS === 'ios') {
      await Geolocation.requestAuthorization('always');
    }

    return true;
  };

  const getAddressByLatLon = async (
    { latitude, longitude }: GeolocationType,
    color?: SweetAlertColor,
  ) => {
    const address: AddressType = {};

    await api
      .searchByLatLng(latitude, longitude)
      .then(({ data: { cep, city, country, lat, lng, provincy_short, route, sublocality } }) => {
        address.cep = cep;
        address.city = city;
        address.country = country;
        address.latitude = lat;
        address.longitude = lng;
        address.neighborhood = sublocality;
        address.state = provincy_short;
        address.street = route;

        hideSweetAlert();
        setGeolocationAddress(address);
      })
      .catch((error) => {
        console.log('Erro', error.message);
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível preencher o endereço',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'OK',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAddress = async (color?: SweetAlertColor) => {
    setLoading(true);

    if (Platform.OS === 'web') {
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          await getAddressByLatLon(coords, color);
          setLoading(false);
        },
        (error) => {
          showSweetAlert(
            'Ops, algo deu errado',
            'Não foi possível acessar sua localização. Verifique as configurações de privacidade do seu dispositivo.',
            'error',
            false,
            false,
            {
              layout: 'helloUi',
              buttons: [
                {
                  variant: color === 'success' ? 'successPrimary' : 'primary',
                  text: 'Ok',
                  onPress: () => {
                    hideSweetAlert();
                  },
                },
              ],
            },
          );
          console.log('Erro', error.message);
        },
      );
    } else {
      const permission = await getPermissions();

      if (permission) {
        const _watchId = Geolocation.getCurrentPosition(
          async ({ coords }) => {
            await getAddressByLatLon(coords, color);
            setLoading(false);
          },
          (error) => {
            showSweetAlert(
              'Ops, algo deu errado',
              'Não foi possível acessar sua localização. Certifique-se de ter habilitado o GPS.',
              'error',
              false,
              false,
              {
                layout: 'helloUi',
                buttons: [
                  {
                    variant: 'primary',
                    text: 'Ok',
                    onPress: () => {
                      hideSweetAlert();
                    },
                  },
                ],
              },
            );
            console.log('Erro', error.message);
          },
          {
            enableHighAccuracy: false,
            timeout: 120000,
            maximumAge: 1000,
          },
        );

        Geolocation.clearWatch(_watchId);
      } else {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível acessar sua localização, precisamos de sua permissão.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                variant: 'primary',
                text: 'Ok',
                onPress: () => {
                  hideSweetAlert();
                },
              },
            ],
          },
        );
      }
    }
  };

  const getGeolocationAddress = (color?: 'primary' | 'success') => {
    showSweetAlert(
      'Você permite que o aplicativo acesse a sua localização?',
      '',
      'localization',
      false,
      false,
      {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Não',
            color: 'primary',
            background: 'secondary',
            testID: 'accept-button',
            variant: color === 'success' ? 'successOutline' : 'outline',
            onPress: () => hideSweetAlert(),
          },
          {
            text: 'Sim',
            color: 'blue',
            background: 'secondary',
            testID: 'accept-button',
            variant: color === 'success' ? 'successPrimary' : 'primary',
            onPress: async () => {
              showSweetAlert(
                'Geolocalização',
                <View className="flex-col items-center justify-center text-center">
                  <Typography variant={theme.isMobile ? 'body2' : 'body1'} color="black">
                    Obtendo localização
                  </Typography>
                  <Loader variant="dot-loader" />
                </View>,
                'localization',
                false,
                false,
                {
                  layout: 'helloUi',
                  touchOutside: false,
                },
              );
              await getAddress(color);
            },
          },
        ],
      },
    );
  };

  const clearGeolocation = () => {
    setLoading(false);
    setGeolocationAddress({});
  };

  return (
    <GeolocationContext.Provider
      value={{
        getGeolocationAddress,
        geolocationAddress,
        clearGeolocation,
        getAddress,
        loading,
      }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocation = (): GeolocationAddressType => {
  const context = useContext(GeolocationContext);

  useEffect(() => {
    context?.clearGeolocation();
  }, []);

  if (!context) {
    throw new Error('useGeolocation must be used within an GeolocationProvider');
  }

  return context;
};
