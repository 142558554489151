import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const ElectricianIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24}>
      <Path
        d="M8.72728 1.50354V1.95H8.77728H8.77789H15.1075C15.3937 1.95 15.6262 2.18254 15.6262 2.4687C15.6262 2.54964 15.6074 2.62656 15.5745 2.69475L13.1028 8.42849H16.6337C16.9199 8.42849 17.1524 8.66102 17.1524 8.94719C17.1524 9.05839 17.1175 9.16174 17.0576 9.24634L9.21735 21.8056L8.72728 21.8056L9.21735 21.8056C9.06612 22.0483 8.74676 22.123 8.50394 21.9717C8.30779 21.8499 8.22169 21.6189 8.275 21.4065L9.87179 12.5189H6.4687C6.18253 12.5189 5.95 12.2863 5.95 12.0002C5.95 11.9433 5.95924 11.8885 5.97576 11.8376L8.27467 2.34675C8.27467 2.34672 8.27468 2.3467 8.27469 2.34667C8.32761 2.12632 8.51425 1.97386 8.72728 1.95354"
        fill="none"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1"
      />
    </Svg>
  );
};
