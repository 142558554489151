import React from 'react';
import { BodyPadding } from '~/components/@hello-ui/PageWithCard/components/BodyPadding';
import { HistoryAppointment } from '~/screens/MyAppointments/components/AppointmentDetails/HistoryAppointment';
import { InProgressAppointment } from '~/screens/MyAppointments/components/AppointmentDetails/InProgressAppointment';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';

export const AppointmentDetails = () => {
  const { myAppointment } = useMyAppointments();

  const { status } = myAppointment!;

  const isHistory = status === 'finished' || status === 'canceled';

  return (
    <BodyPadding>{isHistory ? <HistoryAppointment /> : <InProgressAppointment />}</BodyPadding>
  );
};
