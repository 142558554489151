import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path fill={color} d="M12 6a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z" />
      <Path
        fill={color}
        d="M12 24a5.271 5.271 0 01-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055 10.055 0 0120.11 0c0 2.538-1.933 6.49-5.744 11.747A5.271 5.271 0 0112 24zm0-21.819a7.883 7.883 0 00-7.874 7.874c0 2.01 1.893 5.727 5.329 10.466a3.145 3.145 0 005.09 0c3.436-4.739 5.329-8.456 5.329-10.466A7.883 7.883 0 0012 2.181z"
      />
    </Svg>
  );
}

export default Icon;
