import styled, { css } from 'styled-components/native';
import { Typography as TypographyUI } from '~/components/@hello-ui';

export const Typography = styled(TypographyUI)`
  marginvertical: 8px;
`;

export const WrapperTexts = styled.View`
  ${({ theme: { isMobile } }) =>
    !isMobile
      ? css`
          gap: 16px;
        `
      : `
    padding-horizontal: 16px;
  `}
  margin-top: 24px;
  margin-bottom: 40px;
  paddingvertical: -8px;
`;

export const WrapperButton = styled.TouchableOpacity`
  width: 100%;
  height: ${({ theme: { isMobile } }) => (isMobile ? 72 : 76)}px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 16px;
  `}
`;
