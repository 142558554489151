import React, { useCallback, useState } from 'react';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/native';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { SearchDrugstoresPayload, SearchDrugstoresRes } from '~/contexts/types';
import { Location, SelectLocal } from '~/components/SelectLocal';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { ListItem, MapViewList } from '~/components/MapViewList';
import { BodyPadding } from '~/components/@hello-ui/PageWithCard/components/BodyPadding';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';

const UNIVERS_ONIXES = ['7305', '7306', '7400'];

enum SearchScreen {
  SelectLocal = 'select-local',
  Results = 'results',
}

export const BenefitsDiscountMedicinesSearch = (): JSX.Element => {
  const navigation = useNavigation();
  const { onixCode, partner } = useAuth();
  const api = useApi();
  const [drugstores, setDrugstores] = useState<ListItem<SearchDrugstoresRes>[]>([]);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState(SearchScreen.SelectLocal);

  const resetValues = () => {
    setLoading(false);
    setScreen(SearchScreen.SelectLocal);
    setDrugstores([]);
  };

  useFocusEffect(
    useCallback(() => {
      resetValues();
    }, []),
  );

  const convertToListItem = (data: SearchDrugstoresRes[]): ListItem<SearchDrugstoresRes>[] => {
    return data.map((item) => ({
      data: item,
      name: item.name,
      telephone: item.telephone,
      address: {
        lat: String(item.latitude),
        long: String(item.longitude),
        city: item.city,
        state: item.state,
        number: item.number,
        neighbourhood: item.neighborhood,
        street: item.address,
        zipCode: item.postal_code,
      },
    }));
  };

  const isNotFoundError = (err: unknown) => {
    return (
      err?.response?.data?.data?.response?.message === 'Store(s) not found.' ||
      err?.response?.data?.data?.response?.message === 'Não encontrado'
    );
  };

  const handleConfirmLocation = async (location: Location) => {
    setLoading(true);
    try {
      const { data: zipData } = await api.searchZipCode(
        `${location.neighborhood[0].title}, ${location.city.title} / ${location.state.initials}`,
      );

      const payload: SearchDrugstoresPayload = {
        latitude: zipData.lat,
        longitude: zipData.lng,
        radius: '1',
      };

      if (
        UNIVERS_ONIXES.includes(onixCode?.onix_code as string) ||
        partner?.medicine_discount_provider === 'RDUnivers'
      ) {
        payload.supplier = 'univers';
      }

      const { data } = await api.searchDrugstores(payload);

      setDrugstores(convertToListItem(data));
      setScreen(SearchScreen.Results);
    } catch (err) {
      console.error(err);
      if (isNotFoundError(err)) {
        showSweetAlert(
          'Erro ao buscar farmácias',
          'Nenhuma farmácia foi encontrada na sua região.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                variant: 'primary',
                text: 'Ok',
                onPress: () => {
                  hideSweetAlert();
                },
              },
            ],
          },
        );
      } else {
        showSweetAlert(
          'Erro ao buscar farmácias',
          'Não foi possível buscar as farmácias próximas. Tente novamente mais tarde.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                variant: 'primary',
                text: 'Ok',
                onPress: () => {
                  hideSweetAlert();
                },
              },
            ],
          },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    if (screen === SearchScreen.Results) {
      resetValues();
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('BenefitsDiscountMedicines');
    }
  };

  const handleHomePress = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Dashboard',
          },
        ],
      }),
    );
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Encontrar Farmácias',
        onBack: handleGoBack,
      }}
      overridePadding={{ top: 0, right: 0, bottom: 0, left: 0 }}>
      {screen === SearchScreen.SelectLocal && (
        <BodyPadding>
          <SelectLocal
            title="Qual o local?"
            onConfirmedLocation={handleConfirmLocation}
            buttonLoading={loading}
          />
        </BodyPadding>
      )}
      {screen === SearchScreen.Results && (
        <MapViewList
          title="Farmácias encontradas"
          data={drugstores}
          loading={loading}
          onHomePress={handleHomePress}
          showHomeButton
          hasButton={false}
        />
      )}
    </PageWithCard>
  );
};
