import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Health = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="16" viewBox="0 0 24 16" fill="none">
      <G id="Group">
        <Path fillRule="evenodd" clipRule="evenodd" d="M14.1109 0.680321C14.7169 0.429237 15.3664 0.300003 16.0223 0.300003C16.6782 0.300003 17.3277 0.429237 17.9337 0.680321C18.5397 0.931405 19.0902 1.29942 19.5539 1.76334C20.0178 2.227 20.3857 2.77748 20.6367 3.38335C20.8878 3.98931 21.017 4.63882 21.017 5.29475C21.017 5.95068 20.8878 6.60018 20.6367 7.20615C20.3857 7.81207 20.0177 8.36259 19.5538 8.82627V8.82627L12.6533 15.7268C12.3799 16.0002 11.9367 16.0002 11.6633 15.7268L4.76281 8.82627C3.82619 7.88965 3.3 6.61932 3.3 5.29475C3.3 3.97017 3.82619 2.69984 4.76281 1.76322C5.69943 0.826607 6.96975 0.300421 8.29433 0.300421C9.61891 0.30042 10.8892 0.826607 11.8259 1.76322L12.1583 2.09569L12.4907 1.76334V1.76334C12.9543 1.29948 13.505 0.931385 14.1109 0.680321ZM18.5638 2.75306C18.2301 2.41921 17.8339 2.15438 17.3978 1.97369C16.9617 1.793 16.4943 1.7 16.0223 1.7C15.5503 1.7 15.0829 1.793 14.6468 1.97369C14.2107 2.15438 13.8145 2.41921 13.4808 2.75306L12.6533 3.58061C12.3799 3.85398 11.9367 3.85398 11.6633 3.58061L10.8359 2.75317C10.1618 2.07911 9.2476 1.70042 8.29433 1.70042C7.34105 1.70042 6.42682 2.07911 5.75276 2.75317C5.07869 3.42724 4.7 4.34147 4.7 5.29475C4.7 6.24802 5.07869 7.16225 5.75276 7.83632L12.1583 14.2419L18.5639 7.83632C18.8977 7.50263 19.1627 7.10631 19.3434 6.67024C19.524 6.23417 19.617 5.76677 19.617 5.29475C19.617 4.82272 19.524 4.35533 19.3434 3.91925C19.1627 3.48318 18.8976 3.08675 18.5638 2.75306Z" fill={color ?? theme.colors.primary}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M8.97141 3.35063C9.20711 3.34025 9.42993 3.45841 9.55358 3.65934L12.9793 9.22612L14.4356 6.67751C14.5514 6.47499 14.7667 6.35 15 6.35H23C23.359 6.35 23.65 6.64102 23.65 7C23.65 7.35899 23.359 7.65 23 7.65H15.3772L13.5644 10.8225C13.4505 11.0217 13.24 11.1462 13.0106 11.1499C12.7811 11.1536 12.5667 11.0361 12.4464 10.8407L9.05882 5.33581L5.58138 12.2907C5.47128 12.5109 5.24621 12.65 5.00001 12.65H1.00001C0.641021 12.65 0.350006 12.359 0.350006 12C0.350006 11.641 0.641021 11.35 1.00001 11.35H4.59828L8.41863 3.70931C8.52414 3.49829 8.73571 3.36101 8.97141 3.35063Z" fill={color ?? theme.colors.primary}/>
      </G>
    </Svg>
  );
};

export default Health;
