import React from 'react';
import { G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const HomeApplianceIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 15} height={height ?? 19} viewBox="0 0 15 19" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M14 5H1V17.5C1 17.7769 1.22314 18 1.5 18H13.5C13.7769 18 14 17.7769 14 17.5V5ZM14 4V1.5C14 1.22314 13.7769 1 13.5 1H1.5C1.22314 1 1 1.22314 1 1.5V4H14ZM13.5 19H1.5C0.670858 19 0 18.3291 0 17.5V1.5C0 0.670858 0.670858 0 1.5 0H13.5C14.3291 0 15 0.670858 15 1.5V17.5C15 18.3291 14.3291 19 13.5 19ZM11 11.5C11 9.56714 9.43286 8 7.5 8C5.56714 8 4 9.56714 4 11.5C4 13.4329 5.56714 15 7.5 15C9.43286 15 11 13.4329 11 11.5ZM12 11.5C12 13.9851 9.98514 16 7.5 16C5.01486 16 3 13.9851 3 11.5C3 9.01486 5.01486 7 7.5 7C9.98514 7 12 9.01486 12 11.5ZM6 11.5C6 11.7761 5.77614 12 5.5 12C5.22386 12 5 11.7761 5 11.5C5 10.1199 6.11986 9 7.5 9C7.77614 9 8 9.22386 8 9.5C8 9.77614 7.77614 10 7.5 10C6.67214 10 6 10.6721 6 11.5ZM2.5 3C2.22386 3 2 2.77614 2 2.5C2 2.22386 2.22386 2 2.5 2H3.5C3.77614 2 4 2.22386 4 2.5C4 2.77614 3.77614 3 3.5 3H2.5ZM5.5 3C5.22386 3 5 2.77614 5 2.5C5 2.22386 5.22386 2 5.5 2H6.5C6.77614 2 7 2.22386 7 2.5C7 2.77614 6.77614 3 6.5 3H5.5Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
    </Svg>
  );
};
