import { IRouteConfig } from '~/router/interfaces/route-config';
import {
  BenefitsCapitalization,
  BenefitsCheckup,
  BenefitsDigitalFemaleAssistance,
  BenefitsDigitalWellBeing,
  BenefitsDigitalWellBeingSSO,
  BenefitsDiscountMedicines,
  BenefitsDiscountMedicinesSearch,
  BenefitsFunerals,
  BenefitsHealthyHabitsProgram,
  BenefitsInsurance,
  BenefitsInsuranceDeathDisability,
  BenefitsKeychain,
  BenefitsNetwork,
  BenefitsNetworkSearch,
  BenefitsNutritionalAssistance,
  BenefitsPresentation,
  BenefitsResidentialAssistancePlumber,
  BenefitsWellBeing,
  BenefitsWoman,
  CreateDependents,
  Dashboard,
  DependentsPresentation,
  DependentsView,
  EditDependents,
  FinancialTeleorientation,
  HelpDependents,
  MyBenefits,
  MyClinic,
  Profile,
  ProfileData,
  ProfilePassword,
  PsychologicalAssistance,
  PsychologicalTherapy,
  RegisterCreditCard,
  ScheduleAppointment,
  Signature,
  SignatureCancel,
  SurgicalProcedure,
  SurgicalProcedureForm,
  SurgicalProcedureHospital,
  SurgicalProcedureSuccess,
  TemPayDashboard,
  TemPayPassword,
  TemPayPresentation,
  TemPayRecharge,
  TemPayRechargeBillet,
  TemPayRechargeCard,
  TemPayStatement,
  UpdateProfileAddress,
  UpdateProfileData,
  Dependents,
  ScheduleAppointmentSuccess,
  ScheduleTeleorientation,
  MagicLink,
  Login,
  NotFound,
  AttendanceWebChat,
  TemPayHelp,
  Schedule24h,
  BenefitsTeleOrientation,
  ScheduleSuccess,
  RegulationAndPrivacy,
  MyAppointments,
  ProfileAddress,
  PharmacyBenefit,
  BenefitsServicesConsultations,
  MyCreditCards,
  BenefitsPetAssistance,
  BenefitsPetFuneralAssistance,
  BenefitsPetEmergencyAppointment,
  BenefitsPetTransport,
  BenefitsPetFoodShipping,
  BenefitsPetAccommodation,
  BenefitsResidentialAssistant,
  BenefitsParentalAssistance,
  BenefitsPetAssistant,
  BenefitsBusinessAssistant,
  SubsidizedMedicines,
  BenefitsWelcomingWomen,
  BenefitsWomensHealth,
  BenefitsResidentialAssistanceElectrician,
  BenefitsResidentialAssistanceHomeAppliance,
  BenefitsBusinessAssistancePlumber,
  BenefitsBusinessAssistanceKeychain,
  BenefitsBusinessAssistanceElectrician,
  BenefitsBusinessAssistanceHomeAppliance,
  BenefitsFinancial,
  VideoAppointmentsSchedule,
  BenefitsFinancialClub,
  PhoneNotFoundWebChat,
  DeepLink,
  Products,
  CompleteData,
  DeepLinkFirstAccess,
  SSOCertificate,
} from '~/screens';
import { useAuthStore } from '~/store/auth.store';
import { Loading } from '~/components/Loading';
import { DesktopAuthenticatedWrapper } from '~/components/@hello-ui/Theme/components/DesktopAuthenticatedWrapper';
import DeepLinkLoggedOut from '~/screens/DeepLink/DeepLinkLoggedOut';

export const routes: Record<Routes, IRouteConfig> = {
  Dashboard: {
    title: 'Dashboard',
    component: Dashboard,
    path: '<partner>/dashboard',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayPresentation: {
    title: () => getTemPayName(),
    component: TemPayPresentation,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayDashboard: {
    title: () => getTemPayName(),
    component: TemPayDashboard,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/dashboard',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayRecharge: {
    title: () => `${getTemPayName()} - Recarga`,
    component: TemPayRecharge,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/recharge',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayRechargeBillet: {
    title: () => `${getTemPayName()} - Recarga`,
    component: TemPayRechargeBillet,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/recharge/billet',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayRechargeCard: {
    title: () => `${getTemPayName()} - Recarga`,
    component: TemPayRechargeCard,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/recharge/card',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  AttendanceWebChat: {
    title: () => `${getTemPayName()} - Chat`,
    component: AttendanceWebChat,
    includePartnerOnTitle: true,
    path: '<partner>/chat',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayStatement: {
    title: () => `${getTemPayName()} - Extrato`,
    component: TemPayStatement,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/statement',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayPassword: {
    title: () => `${getTemPayName()} - Senha`,
    component: TemPayPassword,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/password',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  TemPayHelp: {
    title: () => `${getTemPayName()} - FAQ`,
    component: TemPayHelp,
    includePartnerOnTitle: true,
    path: '<partner>/tem-pay/help',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ScheduleAppointment: {
    title: 'Agendamento',
    component: ScheduleAppointment,
    includePartnerOnTitle: true,
    path: '<partner>/schedule-appointment',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  Schedule24h: {
    title: 'Agendamento',
    component: Schedule24h,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/24-hours',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  FinancialTeleorientation: {
    title: 'Assistência Financeira',
    component: FinancialTeleorientation,
    includePartnerOnTitle: true,
    path: '<partner>/financial/teleorientation',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsTeleOrientation: {
    title: 'Orientação Médica por Telefone',
    component: BenefitsTeleOrientation,
    includePartnerOnTitle: true,
    wrapperComponent: DesktopAuthenticatedWrapper,
    // TODO shouldn't have a path?
  },
  BenefitsNutritionalAssistance: {
    title: 'Assistência Nutricional',
    component: BenefitsNutritionalAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/nutritional/assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  PsychologicalAssistance: {
    title: 'Assistência Psicológica',
    component: PsychologicalAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/psychological/assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SurgicalProcedure: {
    title: 'Procedimentos cirúrgicos',
    component: SurgicalProcedure,
    includePartnerOnTitle: true,
    path: '<partner>/surgical/procedure',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SurgicalProcedureForm: {
    title: 'Procedimentos cirúrgicos',
    component: SurgicalProcedureForm,
    includePartnerOnTitle: true,
    path: '<partner>/surgical/procedure/description',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SurgicalProcedureSuccess: {
    title: 'Procedimentos cirúrgicos',
    component: SurgicalProcedureSuccess,
    includePartnerOnTitle: true,
    path: '<partner>/surgical/procedure/success',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SurgicalProcedureHospital: {
    title: 'Procedimentos cirúrgicos',
    component: SurgicalProcedureHospital,
    includePartnerOnTitle: true,
    path: '<partner>/surgical/hospital/available',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ScheduleAppointmentSuccess: {
    title: 'Agendamento',
    component: ScheduleAppointmentSuccess,
    includePartnerOnTitle: true,
    wrapperComponent: DesktopAuthenticatedWrapper,
    // TODO shouldn't have a path?
  },
  ScheduleSuccess: {
    title: 'Agendamento',
    component: ScheduleSuccess,
    includePartnerOnTitle: true,
    path: '<partner>/schedule-appointment/success',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ScheduleTeleorientation: {
    title: 'Agendamento',
    component: ScheduleTeleorientation,
    includePartnerOnTitle: true,
    path: '<partner>/schedule-teleorientation',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  RegulationAndPrivacy: {
    title: 'Regulamentos e Privacidade',
    component: RegulationAndPrivacy,
    includePartnerOnTitle: true,
    path: '<partner>/regulation-privacy',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  MyAppointments: {
    title: 'Meus agendamentos',
    component: MyAppointments,
    includePartnerOnTitle: true,
    path: '<partner>/my-appointments',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  Dependents: {
    title: 'Dependentes',
    component: Dependents,
    includePartnerOnTitle: true,
    path: '<partner>/dependents',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  DependentsView: {
    title: 'Dependente',
    component: DependentsView,
    includePartnerOnTitle: true,
    path: '<partner>/dependents/view',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  HelpDependents: {
    title: 'Dependentes',
    component: HelpDependents,
    includePartnerOnTitle: true,
    path: '<partner>/dependents/help',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  Signature: {
    title: 'Assinatura',
    component: Signature,
    includePartnerOnTitle: true,
    path: '<partner>/signature',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SignatureCancel: {
    title: 'Cancelamento de assinatura',
    component: SignatureCancel,
    includePartnerOnTitle: true,
    path: '<partner>/signature-cancel',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  Profile: {
    title: 'Perfil',
    component: Profile,
    includePartnerOnTitle: true,
    path: '<partner>/profile',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  UpdateProfileData: {
    title: 'Atualizar Dados Pessoais',
    component: UpdateProfileData,
    includePartnerOnTitle: true,
    path: '<partner>/profile/data/edit',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ProfileData: {
    title: 'Perfil',
    component: ProfileData,
    includePartnerOnTitle: true,
    path: '<partner>/profile/data',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ProfilePassword: {
    title: 'Senha de Acesso',
    component: ProfilePassword,
    includePartnerOnTitle: true,
    path: '<partner>/profile/form/password',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  CreateDependents: {
    title: 'Dependentes',
    component: CreateDependents,
    includePartnerOnTitle: true,
    path: '<partner>/dependents/create',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  EditDependents: {
    title: 'Dependentes',
    component: EditDependents,
    includePartnerOnTitle: true,
    path: '<partner>/dependents/edit',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  UpdateProfileAddress: {
    title: 'Atualizar Endereço',
    component: UpdateProfileAddress,
    includePartnerOnTitle: true,
    path: '<partner>/profile/address/edit',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  ProfileAddress: {
    title: 'Perfil',
    component: ProfileAddress,
    includePartnerOnTitle: true,
    path: '<partner>/profile/address',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  DependentsPresentation: {
    title: 'Dependentes',
    component: DependentsPresentation,
    includePartnerOnTitle: true,
    path: '<partner>/dependents/presentation',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPresentation: {
    title: 'Benefícios',
    component: BenefitsPresentation,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/presentation',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsFunerals: {
    title: 'Benefícios',
    component: BenefitsFunerals,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/funeral',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  PsychologicalTherapy: {
    title: 'Benefícios',
    component: PsychologicalTherapy,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/psicologia_online',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  PharmacyBenefit: {
    title: 'Benefícios Farmácia',
    component: PharmacyBenefit,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pharmacy',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsNetwork: {
    title: 'Rede de Saúde',
    component: BenefitsNetwork,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/network',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsServicesConsultations: {
    title: 'Serviços e Consultas',
    component: BenefitsServicesConsultations,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/services-consultations',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  MyClinic: {
    title: 'Rede de Saúde',
    component: MyClinic,
    includePartnerOnTitle: true,
    path: '<partner>/my-clinic',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  MyCreditCards: {
    title: 'Meus cartões',
    component: MyCreditCards,
    includePartnerOnTitle: true,
    path: '<partner>/credit-cards',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  RegisterCreditCard: {
    title: 'Registrar novo cartão',
    component: RegisterCreditCard,
    includePartnerOnTitle: true,
    path: '<partner>/credit-cards/create',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsNetworkSearch: {
    title: 'Rede de Saúde - Encontrar Clínicas',
    component: BenefitsNetworkSearch,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/network/search',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsDiscountMedicines: {
    title: 'Medicamentos com Descontos',
    component: BenefitsDiscountMedicines,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/discount-medicines',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsDiscountMedicinesSearch: {
    title: 'Medicamentos com Descontos - Encontrar Fármacias',
    component: BenefitsDiscountMedicinesSearch,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/discount-medicines/search',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsDigitalWellBeing: {
    title: 'Bem-Estar Digital',
    component: BenefitsDigitalWellBeing,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/wellbeing/digital',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsDigitalWellBeingSSO: {
    title: 'Bem-Estar Digital',
    component: BenefitsDigitalWellBeingSSO,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/wellbeing/digital/sso',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsHealthyHabitsProgram: {
    title: 'Programa Hábitos Saudáveis',
    component: BenefitsHealthyHabitsProgram,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/wellbeing/healthy-habits-program',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsWellBeing: {
    title: 'Bem-Estar',
    component: BenefitsWellBeing,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/wellbeing',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsWoman: {
    title: 'Benefício Mulher',
    component: BenefitsWoman,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/woman',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsInsurance: {
    title: 'Coberturas de Seguros',
    component: BenefitsInsurance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/insurance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsInsuranceDeathDisability: {
    title: 'Morte e Invalidez por Acidente',
    component: BenefitsInsuranceDeathDisability,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/insurance/death',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsCapitalization: {
    title: 'Capitalização',
    component: BenefitsCapitalization,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/capitalization',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetAssistance: {
    title: 'Assistência Pet',
    component: BenefitsPetAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetFuneralAssistance: {
    title: 'Assistência funeral pet',
    component: BenefitsPetFuneralAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-funeral-assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetEmergencyAppointment: {
    title: 'Consulta Emergencial',
    component: BenefitsPetEmergencyAppointment,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-emergency-appointment',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetTransport: {
    title: 'Transporte',
    component: BenefitsPetTransport,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-transport',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetFoodShipping: {
    title: 'Envio de ração',
    component: BenefitsPetFoodShipping,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-food-shipping',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetAccommodation: {
    title: 'Hospedagem pet',
    component: BenefitsPetAccommodation,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-accommodation',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsCheckup: {
    title: 'Checkup',
    component: BenefitsCheckup,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/services-consultations/checkup',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsResidentialAssistant: {
    title: 'Assistência Residencial',
    component: BenefitsResidentialAssistant,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/residential-assistant',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsParentalAssistance: {
    title: 'Educando com afeto',
    component: BenefitsParentalAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/bella-parental-assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsPetAssistant: {
    title: 'Assistência Pet',
    component: BenefitsPetAssistant,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/pet-assistant',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsBusinessAssistant: {
    title: 'Assistência Empresarial',
    component: BenefitsBusinessAssistant,
    includePartnerOnTitle: true,
    // TODO shouldn't have a path?
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  SubsidizedMedicines: {
    title: 'Subsídio em Medicamentos',
    component: SubsidizedMedicines,
    includePartnerOnTitle: true,
    path: '<partner>/subsidized-medicines',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsWelcomingWomen: {
    title: 'Acolhimento à Mulher',
    component: BenefitsWelcomingWomen,
    includePartnerOnTitle: true,
    path: '<partner>/welcoming-women',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsWomensHealth: {
    title: 'Saúde da Mulher',
    component: BenefitsWomensHealth,
    includePartnerOnTitle: true,
    path: '<partner>/womens-health',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsDigitalFemaleAssistance: {
    title: 'Assistente Digital',
    component: BenefitsDigitalFemaleAssistance,
    includePartnerOnTitle: true,
    path: '<partner>/female-assistance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsResidentialAssistancePlumber: {
    title: 'Assistente Residencial - Encanador',
    component: BenefitsResidentialAssistancePlumber,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/residential-assistance/plumber',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsKeychain: {
    title: 'Assistente Residencial - Chaveiro',
    component: BenefitsKeychain,
    includePartnerOnTitle: true,
    path: '<partner>/keychain',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsResidentialAssistanceElectrician: {
    title: 'Assistente Residencial - Eletricista',
    component: BenefitsResidentialAssistanceElectrician,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/residential-assistance/electrician',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsResidentialAssistanceHomeAppliance: {
    title: 'Assistente Residencial - Conserto de Eletrodomésticos',
    component: BenefitsResidentialAssistanceHomeAppliance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/residential-assistance/home-appliance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsBusinessAssistancePlumber: {
    title: 'Assistente Empresarial - Encanador',
    component: BenefitsBusinessAssistancePlumber,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/business-assistance/plumber',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsBusinessAssistanceKeychain: {
    title: 'Assistente Empresarial - Chaveiro',
    component: BenefitsBusinessAssistanceKeychain,
    includePartnerOnTitle: true,
    path: '<partner>/business-assistance/keychain',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsBusinessAssistanceElectrician: {
    title: 'Assistente Empresarial - Eletricista',
    component: BenefitsBusinessAssistanceElectrician,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/business-assistance/electrician',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsBusinessAssistanceHomeAppliance: {
    title: 'Assistente Empresarial - Conserto de Eletrodomésticos',
    component: BenefitsBusinessAssistanceHomeAppliance,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/business-assistance/home-appliance',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsFinancial: {
    title: 'Benefício Financeiro',
    component: BenefitsFinancial,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/financial',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  VideoAppointmentsSchedule: {
    title: 'Consultas por Vídeo Agendadas',
    component: VideoAppointmentsSchedule,
    includePartnerOnTitle: true,
    path: '<partner>/schedule-video-appointment',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  BenefitsFinancialClub: {
    title: 'Benefício Financeiro - Clube de Descontos',
    component: BenefitsFinancialClub,
    includePartnerOnTitle: true,
    path: '<partner>/benefits/financial/club',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  MyBenefits: {
    title: 'Meus Benefícios',
    component: MyBenefits,
    includePartnerOnTitle: true,
    path: '<partner>/my-benefits',
    pathTemplate: addPartnerToUrl,
    wrapperComponent: DesktopAuthenticatedWrapper,
  },
  PhoneNotFoundWebChat: {
    title: '...',
    component: PhoneNotFoundWebChat,
    path: 'phone-not-found',
  },
  Login: {
    title: 'Login',
    component: Login,
    path: 'login',
  },
  MagicLink: {
    title: '...',
    component: MagicLink,
    path: ':partner/magic-link',
  },
  NotFound: {
    title: 'Not Found',
    component: NotFound,
  },
  DeepLink: {
    title: '...',
    component: DeepLink,
    path: ':partner/deep-link',
  },
  CompleteData: {
    title: 'Complete seu cadastro',
    component: CompleteData,
    path: 'complete-data',
  },
  Products: {
    title: 'Selecione o seu produto',
    component: Products,
    path: 'select-product',
  },
  Loading: {
    title: 'Carregando...',
    component: Loading,
    path: 'loading',
  },
  DeepLinkFirstAccess: {
    title: '...',
    component: DeepLinkFirstAccess,
    includePartnerOnTitle: true,
    pathTemplate: addPartnerToUrl,
    path: '<partner>/deep-link/first-access',
  },
  SSOCertificate: {
    title: '...',
    component: SSOCertificate,
    path: '/sso-certificate',
  },
  DeepLinkLoggedOut: {
    title: '...',
    component: DeepLinkLoggedOut,
    path: '/deep-link-logged-out',
  },
};

function getTemPayName() {
  return useAuthStore.getState().selectedProduct?.themeConfig?.payName || 'TemPay';
}

function addPartnerToUrl(path: string) {
  return path.replace('<partner>', getPartnerName());
}

function getPartnerName() {
  return useAuthStore.getState().selectedProduct?.slug || '...';
}
