import { useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { AuthService } from '~/auth/auth-service';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import { useSweetAlert } from '~/components/@hello-ui';

type ResponseError = {
  response?: {
    data?: {
      message?: string;
    };
  };
};

const SSOCertificate: React.FC = () => {
  const { params: queryParams } = useRoute<UseRoute<'SSOCertificate'>>();
  const { showSweetAlert } = useSweetAlert();

  const showSweetError = (title: string, message: string) => {
    showSweetAlert(title, message, 'error', false, false, {
      layout: 'helloUi',
      touchOutside: false,
      buttons: [],
    });
  };

  const handleAuthenticateCertificate = async () => {
    try {
      await AuthService.authenticate(EAuthStrategy.Certificate, {
        sign: decodeURIComponent(queryParams.sign),
        policy: decodeURIComponent(queryParams.policy),
        timestamp: +decodeURIComponent(queryParams.timestamp),
        isInternal: queryParams.internal
          ? decodeURIComponent(queryParams.internal) === 'true'
          : false,
      });
    } catch (error: unknown) {
      console.error('SSOCertificate', error);
      showSweetError(
        'Ops, algo deu errado',
        (error as ResponseError).response?.data?.message ||
          'Ocorreu um erro inesperado. Tente novamente.',
      );
    }
  };

  useEffect(() => {
    void handleAuthenticateCertificate();
  }, []);

  return <></>;
};

export default SSOCertificate;
