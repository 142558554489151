import { StyleProp, View, ViewStyle } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import * as S from '~/screens/Dashboard/components/Benefits/components/BenefitCard/styles';
import { CardButton } from '~/components/@hello-ui';
import { BenefitsCardsTypes } from '~/screens/Dashboard/components/Benefits/types';
import { useTracking } from '~/services/tracking';
import { useDialog } from '~/components/@tem-ui';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useBottomSheet } from '~/components/@tem-ui/BottomSheet';
import { useAppointment } from '~/hooks/appointment';

interface BenefitCardProps {
  card: BenefitsCardsTypes;
  styleWrapper?: StyleProp<ViewStyle>;
  noFixedWidth?: boolean;
}

export const BenefitCard: React.FC<BenefitCardProps> = ({ card, styleWrapper, noFixedWidth }) => {
  const navigation = useNavigation();
  const trackingApi = useTracking();

  const { openDialog } = useDialog();
  const { finalizeSteps } = useStepper();
  const { handleBottomSheet } = useBottomSheet();
  const { clearAppointments } = useAppointment();

  const { title, route, content, icon, params, onPress, tracking } = card;

  return (
    <S.WrapperCard key={`beneft-${route}`} style={styleWrapper}>
      <View className="flex-row">
        <View className="w-8 mobile:w-4" />
        <CardButton
          variant="xsmall"
          style={{
            width: noFixedWidth ? '100%' : undefined,
            maxWidth: noFixedWidth ? 'auto' : undefined,
          }}
          icon={icon}
          title={title}
          description={content}
          onPress={() => {
            if (tracking) {
              void trackingApi.tracking.addMovement({
                event: tracking,
              });
            }

            if (route === 'BenefitsNetwork') {
              clearAppointments();
            }

            if (onPress) {
              onPress({ openDialog, handleBottomSheet, navigation });
            } else {
              finalizeSteps();
              route ? navigation.navigate(route, params) : null;
            }
          }}
        />
        <View className="w-8 mobile:w-4" />
      </View>

      <View className="h-16 mobile:h-8" />
    </S.WrapperCard>
  );
};
