import React, { ReactNode } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from './style';

import { StyledDefaultTheme } from '~/components/@hello-ui';

interface headerProps {
  logo: ReactNode;
  align?: 'center' | 'left';
  onPress?: () => void;
  back?: () => void;
}

export const Header = ({ logo, align, onPress, back }: headerProps): JSX.Element => {
  return (
    <S.WrapperHeader>
      {back && (
        <S.WrapperGoBack onPress={back}>
          <Icon name="arrow-left" size={27} color={StyledDefaultTheme.colors.black} />
        </S.WrapperGoBack>
      )}
      <S.WrapperLogo onPress={onPress} align={align}>
        {logo}
      </S.WrapperLogo>
    </S.WrapperHeader>
  );
};
