import React, { Ref, ReactNode, useMemo } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import Swiper, { SwiperControlsProps } from 'react-native-web-swiper';
import { useTheme } from 'styled-components/native';
import * as S from './styles';

interface SwiperType {
  elementRef?: Ref<Swiper>;
  children: ReactNode;
  onIndexChanged?(index: number): void;
}

export default function SwiperComponent({ elementRef, children, ...props }: SwiperType) {
  const theme = useTheme();

  const config = useMemo(
    () =>
      ({
        dotsTouchable: true,
        dotsPos: 'bottom',
        prevPos: 'bottom',
        nextPos: 'bottom',
        dotsWrapperStyle: { height: 24, bottom: 0 },
        dotActiveStyle: { backgroundColor: theme?.colors?.primary, opacity: 1 },
        NextComponent: ({ onPress }: { onPress: () => void }) => (
          <S.ButtonSwiper onPress={onPress} left>
            <Icon name="chevron-right" size={28} color={theme?.colors?.primary} />
          </S.ButtonSwiper>
        ),
        PrevComponent: ({ onPress }: { onPress: () => void }) => (
          <S.ButtonSwiper onPress={onPress}>
            <Icon name="chevron-left" size={28} color={theme?.colors?.primary} />
          </S.ButtonSwiper>
        ),
      }) as unknown as SwiperControlsProps,
    [],
  );

  return (
    <Swiper {...props} ref={elementRef} from={0} minDistanceForAction={0.1} controlsProps={config}>
      {children}
    </Swiper>
  );
}
