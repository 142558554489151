import React, { forwardRef, PropsWithChildren } from 'react';
import { Platform, ScrollView, StyleProp, ViewStyle } from 'react-native';

interface PageScrollProps extends PropsWithChildren {
  scrollEnabled: boolean;
  contentStyle?: StyleProp<ViewStyle>;
}

export const PageScroll = forwardRef<ScrollView, PageScrollProps>(
  ({ children, scrollEnabled, contentStyle }, ref) => {
    return (
      <ScrollView
        className="relative h-full w-full"
        style={Platform.OS === 'web' ? ({ WebkitOverflowScrolling: 'touch' } as never) : {}}
        scrollEnabled={scrollEnabled}
        keyboardShouldPersistTaps="always"
        ref={ref}
        contentContainerStyle={{
          flexGrow: 1,
          display: 'flex',
          ...(contentStyle && { ...(contentStyle as ViewStyle) }),
        }}>
        {children}
      </ScrollView>
    );
  },
);
