import React from 'react';

import { useTheme } from 'styled-components/native';

import { Button } from '~/components/@hello-ui';

import IconList from '~/components/Icons/List';
import IconMapMarker from '~/components/Icons/MapMarker';

interface ToggleHeaderProps {
  option: 'list' | 'map';
  handleDisplayType: () => void;
}

export const ToggleHeader = ({ option, handleDisplayType }: ToggleHeaderProps) => {
  const theme = useTheme();

  const Icon =
    option === 'map' ? (
      <IconList color={theme.colors.white} />
    ) : (
      <IconMapMarker color={theme.colors.white} />
    );

  const buttonText = option === 'map' ? 'Lista' : 'Mapa';

  return (
    <Button
      variant="primary"
      className="h-48 w-[127px] mobile:w-48"
      style={
        theme.isMobile && {
          paddingHorizontal: 7,
        }
      }
      textStyle={
        theme.isMobile
          ? {
              display: 'none',
            }
          : {
              fontWeight: '600',
            }
      }
      onPress={handleDisplayType}
      icon={Icon}
      iconPosition={theme.isMobile ? 'center' : 'left'}>
      {theme.isMobile ? '' : buttonText}
    </Button>
  );
};
