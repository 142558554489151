import styled, { css } from 'styled-components/native';

export const WrapperHeader = styled.View`
  ${({ theme: { tokens, colors } }) => css`
    width: ${tokens.sizes.full};
    height: ${tokens.space.s60};
    padding-top: ${tokens.space.s16};
    padding-bottom: ${tokens.space.s16};
    border-bottom-width: 2px;
    border-bottom-color: ${colors.black8};
    box-shadow: 1px 2px 4px  ${colors.black8};
    ${tokens.shadows.header}
  `};
`;


export const WrapperLogo = styled.TouchableOpacity<{ align?: string }>`
  ${({ theme: { tokens }, align }) => css`
    display: flex;
    flex-direction: row;
    width: ${tokens.sizes.full};
    min-height: ${tokens.space.s20};
    justify-content: ${align ?? 'center'};
  `};
`;

export const WrapperGoBack = styled.TouchableOpacity`
  ${({ theme: { tokens } }) => css`
    min-width: ${tokens.space.s24};
    min-height: ${tokens.space.s24};
    margin-left: ${tokens.space.s16};
  `};
`;
