import React, { createContext, useCallback, useContext } from 'react';
import {
  AddressPatternType,
  AddressProviderType,
  AdressesContextType,
  CityPatternType,
} from './types';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { localCapitalize } from '~/utils/strings';

const AdressesContext = createContext<AdressesContextType | null>(null);

export const AdressesProvider = ({ children }: AddressProviderType): JSX.Element => {
  const api = useApi();
  const { user } = useAuth();

  const getStates = useCallback(async () => {
    const { data } = await api.states();

    return data.map((state: any) => ({
      id: state.id,
      title: state.name,
      initials: state.sigla,
    }));
  }, []);

  const getCities = useCallback(async (stateId: number) => {
    const { data } = await api.cities(stateId);

    return data.map(({ id, nome, cod_ibge, uf }) => ({
      id,
      title: nome,
      cod_ibge,
      uf,
    }));
  }, []);

  const getState = async (state?: string) => {
    const allStates = await getStates();

    return allStates.find(({ title, initials }: AddressPatternType) => {
      if (state === undefined) {
        return initials.toUpperCase() === (user?.address?.state ?? '').toUpperCase();
      }

      return (
        title.toUpperCase() === state.toUpperCase() ||
        initials.toUpperCase() === state.toUpperCase()
      );
    });
  };

  const getCity = async (state: number, city?: string) => {
    const allCities = await getCities(state);

    return allCities.find(({ title }: CityPatternType) => {
      if (!city) {
        return title.toUpperCase() === (user?.address?.city ?? '').toUpperCase();
      }

      return title.toUpperCase() === city.toUpperCase();
    });
  };

  const getNeighborhoods = async (cityId: number, value: string) => {
    const { data } = await api.neighborhoods(cityId, value);
    return data.map((neighborhood) => ({
      id: neighborhood.id,
      title: localCapitalize(neighborhood.nome),
    }));
  };

  return (
    <AdressesContext.Provider
      value={{
        getStates,
        getState,
        getCities,
        getCity,
        getNeighborhoods,
      }}>
      {children}
    </AdressesContext.Provider>
  );
};

export const useAdresses = (): AdressesContextType => {
  const context = useContext(AdressesContext);

  if (!context) {
    throw new Error('useAdresses must be used within an AdressesProvider');
  }

  return context;
};
