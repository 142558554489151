import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { Card } from '~/components/@hello-ui';

export const StyledCard = styled(Card)<{ hasShadow?: boolean; withGap?: boolean }>(
  ({ theme, hasShadow, withGap }) => css`
    ${withGap &&
    css`
      margin-horizontal: ${theme.tokens.space.s8};
      margin-bottom: ${theme.isMobile ? theme.tokens.space.s8 : theme.tokens.space.s16};
    `}

    width: auto;

    ${Platform.OS === 'web' &&
    css`
      max-width: none;
    `}

    ${!theme.isMobile &&
    css`
      max-width: 348px;
    `};

    ${!hasShadow &&
    css`
      border: 1px solid ${theme.colors.gray60};

      ${Platform.OS === 'web'
        ? 'box-shadow: none'
        : css`
            elevation: 0;
            shadow-color: rgb(0, 0, 0);
            shadow-opacity: 0;
            shadow-radius: 0px;
          `}
    `}
  `,
);

export const SwitchUnitIconWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${tokens.space.s4};
  `}
`;

export const SwitchUnitTitleWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    margin-left: ${tokens.space.s8};
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  `}
`;

export const Wrapper = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const InfoWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
  `}
`;

export const ButtonWrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    padding-top: ${tokens.space.s16};
    margin-top: auto;
  `}
`;
