export enum TypesEnum {
  Appointment,
  Video,
  VideoAllTime,
  Exam,
  Teleorientation,
  Terapiaonline,
  Surgical,
  Dentistry,
  VideoPsychology,
  CheckUp
}

export enum AppointmentFlowType {
  Clinic = 'clinic',
  ClinicNotFound = 'clinicNotFound',
  Indications = 'indications',
}

export const scheduleTypes = {
  [TypesEnum.Appointment]: 'Consulta Presencial',
  [TypesEnum.VideoAllTime]: 'Consulta por Vídeo',
  [TypesEnum.Video]: 'Consulta por vídeo',
  [TypesEnum.Exam]: 'Exames ou Procedimentos',
  [TypesEnum.Teleorientation]: 'Teleorientação 24/7',
  [TypesEnum.Terapiaonline]: 'Terapia por Vídeo',
  [TypesEnum.Surgical]: 'Procedimentos Cirúrgicos',
  [TypesEnum.Dentistry]: 'Consulta Odontológica',
  [TypesEnum.VideoPsychology]: 'Psicologia por Vídeo',
  [TypesEnum.CheckUp]: 'Checkup',
};
