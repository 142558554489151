import React, { useEffect, useRef, useState } from 'react';
import { Calendar as Calendars, DateData, LocaleConfig } from 'react-native-calendars';
import {
  format,
  addDays,
  isSunday,
  startOfMonth,
  endOfMonth,
  isBefore,
  subMonths,
  addMonths,
} from 'date-fns';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

import './CalendarConfig';
import { Wrapper } from '~/components/@hello-ui/Calendar/styles';
import { Platform } from 'react-native';
import { TypographyVariantTypesNativeMobile } from '~/components/@hello-ui/Typography/typography-variants';
import { MarkedDates } from 'react-native-calendars/src/types';

LocaleConfig.defaultLocale = 'br';

interface CalendarProps {
  selectedDays: Date[];
  onSelect?: (day: string) => void;
  startDate?: Date;
  endDate?: Date;
  maxSelected?: number;
}

export const Calendar = ({
  onSelect,
  startDate,
  endDate,
  selectedDays,
  maxSelected,
}: CalendarProps): JSX.Element => {
  const theme = useTheme();

  const hasReachedLimit = () => {
    return !!(maxSelected && selectedDays.length >= maxSelected);
  };

  const loopDays = (callback: (date: Date) => void) => {
    // To prevent flashing on screen, we calculate for previous and next month
    const startDate = startOfMonth(subMonths(currentMonthDate.current, 1));
    const endDate = endOfMonth(addMonths(currentMonthDate.current, 1));

    let currentDate = new Date(startDate);
    while (isBefore(currentDate, endDate)) {
      callback(currentDate);
      currentDate = addDays(currentDate, 1);
    }
  };

  const makeAllDaysDisabled = () => {
    const markedDates: MarkedDates = {};
    const selectedDayTimestamps = selectedDays.map((day) => day.getTime());

    loopDays((currentDate) => {
      const dateString = format(currentDate, 'yyyy-MM-dd');

      if (selectedDayTimestamps.includes(currentDate.getTime())) {
        return;
      }

      markedDates[dateString] = {
        disabled: true,
        disableTouchEvent: true,
      };
    });

    return markedDates;
  };

  const getMarkedDisabledDates = (): MarkedDates => {
    let markedDates: MarkedDates = {};

    if (hasReachedLimit()) {
      markedDates = makeAllDaysDisabled();

      return markedDates;
    }

    loopDays((currentDate) => {
      if (isSunday(currentDate)) {
        const dateString = format(currentDate, 'yyyy-MM-dd');

        markedDates[dateString] = {
          disabled: true,
          disableTouchEvent: true,
        };
      }
    });

    return markedDates;
  };

  const getMarkedSelectedDates = (): MarkedDates => {
    return selectedDays.reduce<MarkedDates>((acc, day) => {
      acc[format(day, 'yyyy-MM-dd')] = {
        selected: true,
        customStyles: {
          container: {
            backgroundColor: theme.colors.primary,
            borderRadius: 4,
          },
          text: {
            color: 'white',
            fontFamily:
              Platform.OS !== 'web'
                ? TypographyVariantTypesNativeMobile.bodyHighlight2.fontFamily
                : 'Poppins',
            fontWeight: '600',
          },
        },
      };
      return acc;
    }, {});
  };

  const calculateMarkedDates = () => {
    return { ...getMarkedDisabledDates(), ...getMarkedSelectedDates() };
  };

  const currentMonthDate = useRef(new Date());
  const [markedDates, setMarkedDates] = useState<MarkedDates>(calculateMarkedDates());

  useEffect(() => {
    setMarkedDates(calculateMarkedDates());
  }, [selectedDays]);

  const handleDayPress = (day: DateData) => {
    onSelect?.(day.dateString);
  };

  return (
    <Wrapper>
      <Calendars
        theme={{
          agendaDayNumColor: theme.colors.gray70,

          todayButtonFontFamily: 'Poppins',
          todayTextColor: theme.colors.primary,

          monthTextColor: theme.colors.title,
          textMonthFontWeight: '600',
          textMonthFontSize: theme.isMobile ? 14 : 16,
          textMonthFontFamily:
            Platform.OS !== 'web' ? TypographyVariantTypesNativeMobile.title.fontFamily : 'Poppins',

          textDayFontFamily: 'Poppins',
          textDayFontSize: theme.isMobile ? 16 : 18,
          textDayStyle: {
            marginTop: 3,
          },

          textDayHeaderFontFamily:
            Platform.OS !== 'web' ? TypographyVariantTypesNativeMobile.h3.fontFamily : 'Poppins',
          textDayHeaderFontSize: theme.isMobile ? 20 : 24,
          textDayHeaderFontWeight: '700',

          textSectionTitleColor: theme.colors.black,

          arrowStyle: {
            marginLeft: -10,
            marginRight: -10,
          },
        }}
        renderArrow={(direction) =>
          direction === 'left' ? (
            <Icon name="chevron-left" size={22} color={theme?.colors?.primary} />
          ) : (
            <Icon name="chevron-right" size={22} color={theme?.colors?.primary} />
          )
        }
        onMonthChange={(date) => {
          currentMonthDate.current = new Date(date.timestamp);
          setMarkedDates(calculateMarkedDates());
        }}
        style={{
          borderRadius: 10,
          maxWidth: 347,
          width: '100%',
          padding: 0,
        }}
        current={Date()}
        minDate={startDate ? format(startDate, 'yyyy-MM-dd') : undefined}
        maxDate={endDate ? format(endDate, 'yyyy-MM-dd') : undefined}
        markingType="custom"
        hideExtraDays={true}
        onDayPress={handleDayPress}
        markedDates={markedDates}
      />
    </Wrapper>
  );
};
