import { createElement, PropsWithChildren } from 'react';
import { Platform, TextProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { getColorByVariant } from '../Theme/theme/color-by-typography';
import TextWrapper from './TextWrapper';
import {
  TypographyVariantTypes,
  TypographyVariantTypesMobile,
  TypographyVariantTypesNativeMobile,
} from './typography-variants';

interface TypographyProps extends TextProps {
  testID?: string;
  variant: keyof typeof TypographyVariantTypes;
  color?: string;
}

export const Typography = ({
  variant,
  color,
  style,
  ...rest
}: PropsWithChildren<TypographyProps>): JSX.Element => {
  const theme = useTheme();

  const typographyProps = {
    style: [
      theme.isMobile ? TypographyVariantTypesMobile[variant] : TypographyVariantTypes[variant],
      Platform.OS !== 'web' ? TypographyVariantTypesNativeMobile[variant] : {},
      {
        color: color
          ? theme.colors[color as keyof typeof theme.colors] || color
          : getColorByVariant(variant, theme),
      },
      ...(Array.isArray(style) ? style : [style]),
    ],
    ...rest,
  };

  return createElement(TextWrapper, {
    ...typographyProps,
  });
};
