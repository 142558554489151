import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from './styles';
import { useTheme } from 'styled-components/native';
import { Spacing } from '~/components/Spacing';
import { Typography } from '~/components/@tem-ui';
import { Platform } from 'react-native';
import { FileType } from '../@tem-ui/Uploader/types';
import { useUploader } from '../@tem-ui/Uploader/useUploader';

export const Files = (): JSX.Element => {
  const theme = useTheme();

  const { deleteFile, files, maxFileSize } = useUploader();

  return (
    <S.WrapperSchedule>
      {files.map((item: FileType, idx: number) => (
        <S.WrapperScheduleType key={idx} redShadow={item.size?.number > maxFileSize}>
          <S.WrapperScheduleInfo>
            <Spacing>
              <S.File>
                <>
                  <Typography variant="paragraph" color={theme.colors.black} bold numberOfLines={1}>
                    {item.name}
                  </Typography>
                  <Typography variant="paragraph" color={theme.colors.black}>
                    {item.size?.text}{' '}
                    {item.size?.number > maxFileSize && (
                      <Typography
                        variant={Platform.OS === 'web' ? 'heading3' : 'subtitle1'}
                        color={theme.colors.red}>
                        {`(Arquivo maior que ${maxFileSize}MB)`}
                      </Typography>
                    )}
                  </Typography>
                </>
              </S.File>
            </Spacing>
            <Spacing>
              <S.DeleteFile
                onPress={() => {
                  deleteFile(files, idx);
                }}>
                <Icon name="trash" size={24} color={theme?.colors?.primary} />
              </S.DeleteFile>
            </Spacing>
          </S.WrapperScheduleInfo>
        </S.WrapperScheduleType>
      ))}
    </S.WrapperSchedule>
  );
};
