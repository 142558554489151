import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconIoni from 'react-native-vector-icons/dist/Ionicons';
import { CardTypes, CardHeaderTypes, CardDescriptionTypes } from './types';
import * as S from './styles';
import { Typography } from '../Typography';
import { useTheme } from 'styled-components/native';

export const Card = ({
  width,
  height,
  children,
  disableShadow,
  disabled,
  marginTop,
  ...props
}: CardTypes): JSX.Element => (
  <S.WrapperCard
    {...props}
    width={width}
    height={height}
    marginTop={marginTop}
    disableShadow={disableShadow}
    disabled={disabled}>
    {children}
  </S.WrapperCard>
);

export const CardHeader = ({
  testID,
  onBackPress,
  title,
  titleElement,
  onHelpPress,
  noBorderBottom,
}: CardHeaderTypes): JSX.Element => {
  const theme = useTheme();

  return (
    <S.WrapperHeader noBorderBottom={noBorderBottom}>
      <S.BackButton testID="back-button" onPress={onBackPress}>
        <Icon name="arrow-left" size={26} color={theme?.colors.black} />
      </S.BackButton>
      <S.WrapperTitle>
        {!titleElement ? (
          <Typography variant="heading1" color="black" bold>
            {title}
          </Typography>
        ) : (
          titleElement
        )}
      </S.WrapperTitle>

      {onHelpPress && (
        <S.HelpButton onPress={onHelpPress}>
          <IconIoni name="help-circle-outline" size={36} color={theme?.colors?.primary} />
        </S.HelpButton>
      )}
    </S.WrapperHeader>
  );
};

export const CardDescription = ({ title, description }: CardDescriptionTypes): JSX.Element => (
  <S.WrapperDescription>
    <S.DescriptionTitle>{title}</S.DescriptionTitle>
    {description && <S.Description>{description}</S.Description>}
  </S.WrapperDescription>
);

export const CardBody = ({ children }: any): JSX.Element => (
  <S.WrapperBody>{children}</S.WrapperBody>
);
