import React, { useCallback, useEffect, useState } from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, parseISO } from 'date-fns';
import * as S from './styles';
import { Button, Typography, Select } from '~/components/@hello-ui';
import { useAdresses } from '~/components/@hello-ui/Adresses';
import { useGeolocation } from '~/components/@hello-ui/Adresses/Geolocation';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { Checkbox } from '~/components/@hello-ui/Checkbox';
import { GeolocationButton } from '~/components/@hello-ui/Adresses/Geolocation/component/GeolocationButton';
import { capitalize, extractDigits } from '~/utils/strings';

import { useApi } from '~/hooks/api';
import { Input } from '~/components/@hello-ui/Input';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';
import { SelectItem } from '~/components/@hello-ui/Select/types';
import { useAuth } from '~/auth/legacy/useAuth';
import { AuthService } from '~/auth/auth-service';
import { PageWrapperWithScroll } from '~/screens/Login/components/PageWrapper/PageWrapperWithScroll';
import { useAuthStore } from '~/store/auth.store';

const schema = yup.object().shape({
  email: yup.string().email('Digite um email válido').optional(),
  cep: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  neighborhood: yup.string().required('Campo obrigatório'),
  number: yup.string().when('noNumber', {
    is: true,
    then: yup.string().optional().default(''),
    otherwise: yup.string().required('Campo obrigatório'),
  }),
  complement: yup.string().optional().default(''),
  noNumber: yup.boolean(),
});

export const CompleteData = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { getStates, getCities, getCity, getState } = useAdresses();
  const { geolocationAddress } = useGeolocation();
  const { user } = useAuth();

  const [states, setStates] = useState<SelectItem[]>([]);
  const [cities, setCities] = useState<SelectItem[]>([]);
  const [isLoadingCEP, setIsLoadingCEP] = useState(false);
  const [isStreet, setIsStreet] = useState(true);
  const [isNeighborhood, setIsNeighborhood] = useState(true);
  const [isCity, setIsCity] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { isValid, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      cep: '',
      street: '',
      neighborhood: '',
      complement: '',
      number: '',
      noNumber: false,
      state: {
        id: undefined,
        label: '',
        value: '',
      } as SelectItem,
      city: {
        id: undefined,
        label: '',
        value: '',
      } as SelectItem,
    },
  });

  const allFields = watch();

  useEffect(() => {
    if (allFields.state) {
      allFields.state?.id && onGetCities(allFields.state.id as number);
    }
  }, [allFields.state]);

  useEffect(() => {
    onGetStates();
    fulfillFields();

    register('state', { required: true });
    register('city', { required: true });
  }, []);

  useEffect(() => {
    const { cep } = geolocationAddress;

    if (cep) {
      const formatCep = cep.match?.(/\d/g)?.join('') ?? '';

      setValue('cep', formatCep);
      onSearchCEP(formatCep);
    }
  }, [geolocationAddress]);

  const fulfillFields = async () => {
    try {
      if (user) {
        const { address, neighborhood, city, complement, number, postal_code, state } =
          user.address;

        const stateData = await getState(state);

        if (stateData) {
          setValue(
            'state',
            {
              label: stateData.initials,
              value: stateData.initials,
              id: stateData.id,
            },
            { shouldValidate: true },
          );

          await trigger('state');

          const cityData = await getCity(stateData.id, city);
          if (cityData) {
            setValue(
              'city',
              {
                label: cityData.title,
                value: cityData.id,
                id: cityData.id,
              },
              { shouldValidate: true },
            );
            await trigger('city');
          }
        }

        const email = user?.email;

        setValue('email', email ?? '', { shouldValidate: !!email });
        setValue('street', address ?? '', { shouldValidate: !!address });
        setValue('neighborhood', neighborhood ?? '', { shouldValidate: !!neighborhood });
        setValue('complement', complement ?? '', { shouldValidate: !!complement });
        setValue('number', number ?? '', { shouldValidate: !!number });
        if (number === ' ') {
          setValue('noNumber', true);
        }
        setValue('cep', postal_code ?? '', { shouldValidate: !!postal_code });
      }
    } catch (err) {
      console.error('error while fulfilling fields', err);
    }
  };

  const onGetStates = useCallback(async () => {
    try {
      const result = await getStates();

      setStates(
        result.map(({ id, initials }: { id: number; title: string; initials: string }) => ({
          id,
          label: initials,
          value: initials,
        })),
      );
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível listar os estados.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  }, [setStates, getStates]);

  const onGetCities = useCallback(
    async (cityId: number) => {
      try {
        const result = await getCities(cityId);

        setCities(
          result.map(({ id, title }: { id: number; title: string }) => ({
            label: capitalize(title),
            value: id,
          })),
        );
      } catch (error) {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível listar as cidades.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      }
    },
    [setCities, getCities],
  );

  const onSearchCEP = useCallback(
    async (cep: string) => {
      try {
        setIsLoadingCEP(true);
        const {
          data: { uf, city, route, sublocality },
        } = await api.getAddressByCep(cep);
        const currentState = states?.find?.((state) => state.value === uf) ?? {};
        const currentCity = await getCity(currentState.id, city);

        !route ? setIsStreet(false) : setIsStreet(true);
        !sublocality ? setIsNeighborhood(false) : setIsNeighborhood(true);
        !currentState ? setIsCity(false) : setIsCity(true);

        setValue('street', route);
        setValue('neighborhood', sublocality);
        setValue('state', currentState, { shouldValidate: true });

        if (currentCity) {
          setValue(
            'city',
            {
              id: currentCity.id,
              label: capitalize(currentCity.title),
              value: currentCity.id,
            },
            { shouldValidate: true },
          );
        }

        await trigger('city');
        await trigger('state');
      } catch (error) {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível encontrar o CEP informado.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      } finally {
        setIsLoadingCEP(false);
      }
    },
    [api, setValue, getCity, states],
  );

  const updateEmail = async (email: string) => {
    try {
      const userData = {
        name: user?.name,
        document_number: user?.cpf,
        email,
        phone: user?.telephone_1,
        birthdate: format(parseISO(user?.birthdate?.split('T')[0]), 'dd/MM/yyyy'),
        gender: user?.gender.charAt(0).toUpperCase() + user.gender.slice(1),
        firstAccess: true,
      };

      await api.updateProfile(userData);
    } catch (error) {
      console.error('An error occurred while updating the profile:', error);
      throw error;
    }
  };

  const onNext = useCallback(
    async (values) => {
      try {
        const { email } = values;
        await updateEmail(email);

        await api.updateProfileAddress({
          ...values,
          number: values.noNumber ? ' ' : values.number,
          cpf: extractDigits(user?.cpf) || undefined,
          state: values.state.value,
          city: values.city.label,
          isMandatory: true,
          firstAccess: true,
        });

        await AuthService.updateProfileData();
        useAuthStore.setState({ isFirstAccess: false });
        await AuthService.triggerGatekeeperValidation();
      } catch (error) {
        console.error(error);
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível atualizar seus dados.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      }
    },
    [cities, states],
  );

  const handleChangeEnableNumber = () => {
    if (!allFields.noNumber) {
      setValue('number', '');
    }
  };

  return (
    <PageWrapperWithScroll>
      <PageWrapper>
        <View className="mb-40 mobile:mb-32">
          <S.Typography variant="title">Complete seu cadastro</S.Typography>
        </View>
        <View className="mb-16">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                keyboardType="email-address"
                value={value}
                onChangeText={onChange}
                label="E-mail"
                placeholder="Digite"
                error={error?.message ?? ''}
              />
            )}
          />
        </View>

        <View className="mb-8">
          <S.Typography variant="title">Endereço</S.Typography>
        </View>
        <View className="mb-24">
          <GeolocationButton color="success" />
        </View>
        <View className="mb-8 flex-row">
          <View className="w-1/2 pr-8">
            <Controller
              name="cep"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  label="CEP"
                  placeholder="00000-000"
                  mask="99999-999"
                  isLoading={isLoadingCEP}
                  variant="mask"
                  keyboardType="number-pad"
                  value={value}
                  isDisabled={isLoadingCEP}
                  onChangeText={(value) => {
                    const cep = value?.match?.(/\d/g)?.join('');
                    onChange(cep);
                    cep?.length === 8 && onSearchCEP(cep);
                  }}
                  error={error?.message ?? ''}
                />
              )}
            />
          </View>
          <View className="w-1/2 pl-8">
            <Select
              disabled
              items={states}
              placeholder="Digite"
              label="Estado"
              value={allFields.state}
              onSelect={(state) => setValue('state', state, { shouldValidate: true })}
            />
          </View>
        </View>
        <View className="mb-16 flex-row">
          <TouchableOpacity
            className="w-auto"
            onPress={() =>
              Linking.openURL('https://buscacepinter.correios.com.br/app/endereco/index.php')
            }
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}>
            <Typography
              variant="link"
              color="title"
              style={{
                fontSize: theme.isMobile ? 12 : 14,
                lineHeight: theme.isMobile ? 18 : 24,
                width: 'auto',
              }}>
              Não sei meu CEP
            </Typography>
          </TouchableOpacity>
        </View>

        <View className="mb-8">
          <Select
            disabled={isCity}
            items={cities}
            placeholder="Digite"
            label="Cidade"
            value={allFields.city}
            onSelect={(city) => setValue('city', city, { shouldValidate: true })}
          />
        </View>

        <View className="mb-8">
          <Controller
            name="street"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                keyboardType="default"
                isDisabled={isStreet}
                label="Endereço"
                placeholder="Digite"
                value={value}
                onChangeText={onChange}
                error={error?.message ?? ''}
              />
            )}
          />
        </View>

        <View className="mb-8">
          <Controller
            name="neighborhood"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                keyboardType="default"
                label="Bairro"
                isDisabled={isNeighborhood}
                placeholder="Digite"
                value={value}
                onChangeText={onChange}
                error={error?.message ?? ''}
              />
            )}
          />
        </View>

        <View className="mb-8 flex-row">
          <View className="w-1/2 pr-8">
            <Controller
              name="number"
              control={control}
              defaultValue=""
              disabled={allFields.noNumber}
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  isDisabled={allFields.noNumber}
                  keyboardType="number-pad"
                  label="Número"
                  placeholder="Digite"
                  value={value}
                  onChangeText={(text) => {
                    const number = text?.match?.(/\d/g)?.join('');
                    onChange(number);
                  }}
                  error={error?.message ?? ''}
                />
              )}
            />
          </View>
          <View className="w-1/2 justify-center pl-32">
            <Controller
              name="noNumber"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Checkbox
                  checked={watch('noNumber')}
                  label="Sem número"
                  onPress={() => {
                    handleChangeEnableNumber();
                    onChange(!watch('noNumber'));
                  }}
                />
              )}
            />
          </View>
        </View>

        <View className="flex-row">
          <View className="w-1/2 pr-8">
            <Controller
              name="complement"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  keyboardType="default"
                  label="Complemento"
                  placeholder="Digite"
                  value={value}
                  onChangeText={onChange}
                  error={error?.message ?? ''}
                />
              )}
            />
          </View>
          <View className="w-1/2" />
        </View>
        <View className="mt-40">
          <Button
            disabled={!isValid || isSubmitting}
            variant="successPrimary"
            onPress={handleSubmit(onNext)}
            loading={isSubmitting}>
            Próximo
          </Button>
        </View>
      </PageWrapper>
    </PageWrapperWithScroll>
  );
};
