import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/@hello-ui';

import { useLogin } from '~/screens/Login/state/useLogin';

const RESEND_INTERVAL: number = 60;

export const ReSendCode = ({ onPress }: { onPress: () => void }): JSX.Element => {
  const theme = useTheme();
  const { setStep, setAttempts, attempts } = useLogin();

  const [seconds, setSeconds] = useState<number>(RESEND_INTERVAL);

  useEffect(() => {
    const timer = seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [seconds]);

  useEffect(() => {
    if (attempts > 3) {
      setStep('error_code');
    }
  }, [attempts, setStep]);

  return (
    <Button
      disabled={seconds !== 0}
      variant="link"
      style={{
        color: theme.colors.paragraph,
        paddingHorizontal: 0,
        paddingVertical: 0,
      }}
      onPress={() => {
        if (attempts <= 3) setAttempts(attempts + 1);

        if (attempts < 3) {
          setSeconds(RESEND_INTERVAL);
          onPress();
        }
      }}>
      <>Reenviar código{seconds > 0 && ` (${seconds}s)`}</>
    </Button>
  );
};
