import { useLayoutEffect, useState } from 'react';
import { NativeModules, Platform, StatusBar } from 'react-native';

const { StatusBarManager } = NativeModules;

export function useStatusBarHeight() {
  const [statusBarHeight, setStatusBarHeight] = useState(StatusBar.currentHeight ?? 0);

  useLayoutEffect(() => {
    if (Platform.OS === 'ios') {
      StatusBarManager?.getHeight?.((statusBarFrameData: { height: number }) => {
        setStatusBarHeight(statusBarFrameData.height);
      });
    }
  }, []);

  return statusBarHeight;
}
