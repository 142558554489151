import React from 'react';
import { Typography, Button, useDialog } from '~/components/@tem-ui';
import { useNavigation } from '@react-navigation/native';
import { Dependents } from '../../../components/Dialog/Dependents';
import * as S from './styles';

export const NotNetworkSearch = ({ type }: string): JSX.Element => {
  const navigation = useNavigation();
  const { openDialog } = useDialog();

  return (
    <S.Wrapper>
      <S.WrapperContent>
        <S.AlertCard>
          <Typography variant="body2" color="black60" style={{ textAlign: 'center' }}>
            {type === 'laboratory'
              ? 'Ainda não temos um laboratório nessa região, mas fica tranquilo(a), iremos garantir o seu agendamento.'
              : 'Ainda não temos uma clínica nessa região, mas fica tranquilo(a), iremos garantir o seu agendamento.'}
          </Typography>
        </S.AlertCard>
        <Typography variant="heading2" color="black70" style={{ textAlign: 'center' }}>
          {type === 'laboratory'
            ? 'Nós podemos buscar outros laboratórios próximos de você :)'
            : 'Nós podemos buscar outras clínicas próximas de você :)'}
        </Typography>
        <S.WrapperDescription>
          <Typography variant="body2" color="black60">
            {type === 'laboratory'
              ? 'É só solicitar um agendamento de Exames aqui mesmo e, nossa central de agendamentos vai te enviar os endereços dos laboratórios mais próximas da região que você precisa e, com os melhores preços. \n\nOu se preferir, você pode fazer uma nova busca em outra região.'
              : 'É só solicitar um agendamento de Consulta aqui mesmo e, nossa central de agendamentos vai te enviar os endereços das clínicas mais próximas da região que você precisa e, com os melhores preços. \n\nOu se preferir, você pode fazer uma nova busca em outra região.'}
          </Typography>
        </S.WrapperDescription>
        <S.WrapperButton>
          <Button
            variant="primary"
            title={type === 'laboratory' ? 'Agendar Exame' : 'Agendar Consulta'}
            onPress={() =>
              openDialog('Para quem é o agendamento?', <Dependents navigation={navigation} />)
            }
          />
        </S.WrapperButton>
      </S.WrapperContent>
    </S.Wrapper>
  );
};
