import React from 'react';
import { View } from 'react-native';
import { TextInput, HelperText } from 'react-native-paper';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { InputIcon } from '../types';
import * as S from '../styles';

const getIcon = (iconProps: string | InputIcon) => {
  const theme = useTheme();

  if (iconProps) {
    const iconIsObject = typeof iconProps === 'object' && iconProps !== null;

    let size = 25;
    let item = iconProps;
    let position = 'left';
    let isIconClassType = true;

    if (iconIsObject) {
      const isReactElement = React.isValidElement(iconProps);

      item = iconProps.item ?? (isReactElement ? iconProps : '');
      size = iconProps.size ?? size;
      position = iconProps.position ?? position;

      isIconClassType = typeof item === 'string' && item !== null;
    }

    let themeIcon: JSX.Element = <></>;

    if (isIconClassType) {
      themeIcon = <Icon name={item} color={theme?.colors?.primary} size={size} />;
    } else {
      themeIcon = <View>{item}</View>;
    }

    const icon: JSX.Element[] = [];
    icon[position] = <TextInput.Icon name={() => themeIcon} size={size} />;

    return Object.assign({}, icon);
  }

  return {};
};

export const MaterialInput = React.forwardRef((props: any, ref) => {
  const theme = useTheme();

  const iconProps = getIcon(props?.icon);

  return (
    <S.MaterialTextInputWrapper>
      <TextInput
        ref={ref}
        {...props}
        {...iconProps}
        outlineColor={props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white15}
        placeholderTextColor={theme?.colors.white15}
        theme={{
          colors: {
            primary: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white,
            placeholder: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white15,
            text: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white,
            background: props.variant === 'light' ? theme?.colors.white : 'rgba(64,64,64, 0.8)',
          },
        }}
        mode="outlined"
      />
      {props.error && <HelperText type="error">{props.error.message}</HelperText>}
    </S.MaterialTextInputWrapper>
  );
});
