import React, { PropsWithChildren, useMemo } from 'react';
import { ThemeProvider } from 'styled-components/native';
import merge from 'lodash.merge';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMedia } from '~/components/@hello-ui/Theme/hooks/useMedia';
import { StyledDefaultTheme } from '~/components/@hello-ui/Theme/theme/styled-default-theme';
import { colorMixer, getDarkerColor } from '~/utils/colorsTheme';
import { IFullTheme } from '~/@types/theme/full-theme.d';

export const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const media = useMedia();
  const { partner } = useAuth();

  function buildTheme() {
    const mergedTheme: IFullTheme = merge({}, StyledDefaultTheme, partner?.theme_config, {
      ...media,
    });

    const partnerThemeColors = partner?.theme_config?.colors;
    if (partnerThemeColors) {
      mergedTheme.colors.primaryDarker = getDarkerColor(
        partnerThemeColors.primary ?? mergedTheme.colors.primary,
      );
      mergedTheme.colors.primary50 = colorMixer(
        partnerThemeColors.primary ?? mergedTheme.colors.primary,
        mergedTheme.colors.white,
        0.5,
      );
      mergedTheme.colors.buttonDisabled = colorMixer(
        partnerThemeColors.primary ?? mergedTheme.colors.primary,
        mergedTheme.colors.white,
        0.3,
      );
      mergedTheme.colors.backgroundDisabled = colorMixer(
        partnerThemeColors.primary ?? mergedTheme.colors.primary,
        mergedTheme.colors.white,
        0.1,
      );
    }

    return mergedTheme;
  }

  const theme = useMemo(() => {
    return buildTheme();
  }, [media.isMobile, media.isTablet, media.isDesktop, JSON.stringify(partner?.theme_config)]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
