import styled from 'styled-components/native';
import { ImageBackground } from 'react-native';

import image from '~/assets/images/img_beneficios_mob.jpeg';

export interface MediaProps {
  isMobile: boolean;
}

export interface RectangleProps {
  width: number;
  height: number;
}

export const WrapperHeader = styled(ImageBackground).attrs({
  source: image,
})`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 572px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    min-height: 250px;
    margin-top: 0px;
    height: 250px;
    border-top-left-radius: 0px; border-top-right-radius: 0px;
  `}
`;

export const HeaderIcon = styled.TouchableOpacity`
  position: absolute;
  margin-top: 32px;
  margin-left: 22px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  align-items: center;
  justify-content: center;
`;

export const WrapperBackButton = styled.TouchableOpacity`
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  margin-left: 16px;
  margin-top: 26px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const HeaderContent = styled.View<MediaProps>`
  width: ${(props) => (props.isMobile ? 280 : 380)}px;
  flex-direction: row;
  margin-top: 380px;
  margin-bottom: 20px;
  margin-left: ${(props) => (props.isMobile ? 50 : 200)}px;
`;

export const WrapperContent = styled.View`
  width: auto;
  margin: 50px 110px 0 110px;
  ${({ theme: { isMobile } }) => isMobile && 'margin: 20px 20px 0 20px'}
`;

export const WrapperContentCards = styled.View`
  width: auto;
  margin: 0 50px 50px 110px;
  ${({ theme: { isMobile } }) => isMobile && 'margin: 0 20px 0px 30px'}
`;

export const WrapperTitleContent = styled.Text`
  width: auto;
  margin-top: 10px;
  ${({ theme: { isMobile } }) => isMobile && 'margin: 0 10px 0 10px'}
`;

export const WrapperDescriptionContent = styled.Text`
  width: auto;
  margin-top: 10px;
  ${({ theme: { isMobile } }) => isMobile && 'margin: 10px 10px 0 10px'}
`;

export const WrapperCard = styled.View`
  align-items: center;
  padding: 10px 20px;
  height: 100%;
  justify-content: space-between;
  text-align: center;
`;

export const Rectangle1 = styled.View<RectangleProps>`
  position: absolute;
  top: -5px;
  right: 0;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${({ theme: { colors } }) => colors.white};
  opacity: 0.6;
  border-radius: ${(props) => props.width / 2}px;
`;

export const Rectangle2 = styled.View<RectangleProps>`
  position: absolute;
  top: ${(props) => props.height / 2 - props.width / 2 - 5}px;
  right: ${(props) => -(props.height / 2 - props.width / 2)}px;
  width: ${(props) => props.height}px;
  height: ${(props) => props.width}px;
  background-color: ${({ theme: { colors } }) => colors.white};
  opacity: 0.4;
  border-radius: ${(props) => props.width / 2}px;
`;

export const Text = styled.View`
  text-align: justify;
`;
