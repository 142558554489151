import styled, { css } from 'styled-components/native';

export const Content = styled.View<{ fill?: boolean }>(
  ({ theme: { tokens }, fill }) => css`
    width: ${tokens.sizes.full};
    height: auto;
    flex-grow: 1;
    flex-basis: 0%;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;

    ${fill &&
    css`
      flex: 1;
    `}
  `,
);
