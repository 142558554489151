import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from '~/components/Icons/types';

const PhoneIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5 18H18.4615"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <Path
        d="M10.3846 20H13.6154"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default PhoneIcon;
