import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const VideoCameraIcon = ({ width = 24, height = 24, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <Path
        d="M15.3251 4.85156L10.7734 8.1389L15.3251 11.4262V4.85156Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth={1.04}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.43028 3.33203H2.01271C1.26787 3.33203 0.664062 3.94662 0.664062 4.70476V11.5684C0.664062 12.3266 1.26787 12.9412 2.01271 12.9412H9.43028C10.1751 12.9412 10.7789 12.3266 10.7789 11.5684V4.70476C10.7789 3.94662 10.1751 3.33203 9.43028 3.33203Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth={1.04}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default VideoCameraIcon;
