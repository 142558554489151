import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsFunerals/desktop.png';
import mobileBackground from '../../assets/images/benefitsFunerals/mobile.png';
import * as S from './styles';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { goBack } from '~/helpers';
import CircleGroup from '~/components/Icons/CircleGroup';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

type FuneralProviderType = {
  phone: string;
  logo: JSX.Element;
};

export const BenefitsFunerals = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();
  const { partner } = useAuth();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const funeralProvider: Record<string, FuneralProviderType> = {
    Memorial: {
      phone: '0800 591 2053',
      logo: <S.ImageMemorial />,
    },
    IGS: {
      phone: '0800 721 4095',
      logo: <S.ImageIGS />,
    },
  };

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <CircleGroup width={26} height={26} color="white" />,
        title: 'Assistência Funeral',
        onBack: () => {
          goBack(navigation, 'Dashboard');
        },
      }}>
      <View>
        <Typography variant="body2">
          Criado para que você tenha{' '}
          <Typography variant="bodyHighlight2">
            apoio e segurança no momento mais sensível da vida.{' '}
          </Typography>
          Serviço 24 horas disponível para acionamento por telefone com atendimento de profissionais
          especializados.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">
              Oferece{' '}
              <Typography variant="bodyHighlight2">
                cobertura para os custos do funeral ou parte dele,{' '}
              </Typography>
              em caso de morte do titular, oferecendo suporte de forma eficiente e conveniente,
              conforme regras abaixo:
            </Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2">
              • Cobertura de R$ 2 mil à R$ 5 mil nos custos funerários;
            </Typography>
            <Typography variant="body2">• Translado do corpo de até 250km no Brasil;</Typography>
            <Typography variant="body2">
              • Válido para falecimento por mal súbito ou acidente.
            </Typography>
          </View>
          <View>
            <Typography variant="body2">
              A cobertura e atendimento acontece somente em casos de falecimento por mal súbito ou
              acidente.
            </Typography>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para utilizar, ligue e solicite a Assitência Funeral:
        </Typography>
        <View className="mt-16 mobile:mt-8">
          {funeralProvider[partner?.funeral_provider ?? 'Memorial'].logo}
        </View>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() =>
            Linking.openURL(
              `tel:${funeralProvider[partner?.funeral_provider ?? 'Memorial'].phone.replaceAll(
                ' ',
                '',
              )}`,
            )
          }>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">
            {funeralProvider[partner?.funeral_provider ?? 'Memorial'].phone}
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};
