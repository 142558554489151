import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

const WrapperVariations = {
  web: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    shadow-color: #4b3f4085;
    shadow-offset: 1px 2px;
    shadow-opacity: 1;
    shadow-radius: 20px;
    elevation: 9;
  `,
};

export const Button = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  border-radius: 20px;

  position: absolute;
  right: -20px;
  bottom: -12px;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.white};

  align-items: center;
  justify-content: center;

  ${Platform.OS === 'web' ? WrapperVariations['web'] : WrapperVariations['mobile']}
`;
