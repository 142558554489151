import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const Phone24 = ({ color, width, height }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5 18H18.4615"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <Path
        d="M10.3846 20H13.6154"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <Path
        d="M6.58168 14V13.1435L8.85405 10.9158C9.07138 10.6964 9.25249 10.5014 9.39737 10.331C9.54226 10.1605 9.65092 9.99538 9.72337 9.83558C9.79581 9.67578 9.83203 9.50533 9.83203 9.32422C9.83203 9.11754 9.78516 8.9407 9.69141 8.79368C9.59766 8.64453 9.46875 8.52947 9.30469 8.44851C9.14062 8.36754 8.95419 8.32706 8.74538 8.32706C8.53018 8.32706 8.34162 8.3718 8.17969 8.46129C8.01776 8.54865 7.89205 8.6733 7.80256 8.83523C7.7152 8.99716 7.67152 9.18999 7.67152 9.41371H6.54332C6.54332 8.99822 6.63814 8.63707 6.82777 8.33026C7.0174 8.02344 7.27841 7.78587 7.6108 7.61754C7.94531 7.44922 8.32884 7.36506 8.76136 7.36506C9.20028 7.36506 9.58594 7.44709 9.91832 7.61115C10.2507 7.77521 10.5085 8 10.6918 8.28551C10.8771 8.57102 10.9698 8.89702 10.9698 9.26349C10.9698 9.50852 10.9229 9.74929 10.8292 9.9858C10.7354 10.2223 10.5703 10.4844 10.3338 10.772C10.0994 11.0597 9.77024 11.408 9.34624 11.8171L8.21804 12.9645V13.0092H11.0689V14H6.58168ZM12.1626 12.7855V11.8427L14.94 7.45455H15.7262V8.79688H15.2468L13.3771 11.7596V11.8107H17.2539V12.7855H12.1626ZM15.2852 14V12.4979L15.2979 12.076V7.45455H16.4165V14H15.2852Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default Phone24;
