import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({ size, color, width: iconWidth = 24, height: iconHeight = 24, ...rest }: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M13 1a1 1 0 011-1 10.011 10.011 0 0110 10 1 1 0 01-2 0 8.009 8.009 0 00-8-8 1 1 0 01-1-1zm1 5a4 4 0 014 4 1 1 0 002 0 6.006 6.006 0 00-6-6 1 1 0 000 2zm9.093 10.739a3.1 3.1 0 010 4.378l-.91 1.049c-8.19 7.841-28.12-12.084-20.4-20.3l1.15-1a3.081 3.081 0 014.327.04c.031.031 1.884 2.438 1.884 2.438a3.1 3.1 0 01-.007 4.282L7.979 9.082a12.781 12.781 0 006.931 6.945l1.465-1.165a3.1 3.1 0 014.281-.006s2.406 1.852 2.437 1.883zm-1.376 1.454s-2.393-1.841-2.424-1.872a1.1 1.1 0 00-1.549 0c-.027.028-2.044 1.635-2.044 1.635a1 1 0 01-.979.152A15.009 15.009 0 015.9 9.3a1 1 0 01.145-1s1.607-2.018 1.634-2.044a1.1 1.1 0 000-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1 1.1 0 00-1.51.039l-1.15 1C-2.495 10.105 14.776 26.418 20.721 20.8l.911-1.05a1.121 1.121 0 00.085-1.557z"
      />
    </Svg>
  );
}

export default Icon;
