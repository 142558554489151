import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Family = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 22} height={height ?? 22} viewBox="0 0 22 22" fill="none">
      <G id="Group 1551">
        <G id="Group">
          <Path
            id="Vector"
            d="M15.6431 4C17.2164 4 18.5002 5.28173 18.5002 6.8559C18.5002 8.43008 17.2164 9.71433 15.6431 9.71433C14.0697 9.71433 12.7859 8.42987 12.7859 6.8559C12.7859 5.28177 14.0697 4 15.6431 4ZM15.6431 4.71188C14.4557 4.71188 13.5002 5.66773 13.5002 6.8557C13.5002 8.04367 14.4557 8.99951 15.6431 8.99951C16.8304 8.99951 17.7859 8.04367 17.7859 6.8557C17.7859 5.66773 16.8304 4.71188 15.6431 4.71188Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_2"
            d="M15.8781 10.4348C15.6809 10.4348 15.521 10.5947 15.521 10.792C15.521 10.8867 15.5586 10.9775 15.6256 11.0445C15.6927 11.1115 15.7834 11.1491 15.8781 11.1491C18.3444 11.2719 20.2859 13.2756 20.2859 15.7549V16.8571H18.1381C17.9409 16.8571 17.781 17.017 17.781 17.2142C17.781 17.4114 17.9409 17.5714 18.1381 17.5714H20.6443C20.8405 17.5707 20.9993 17.4118 21.0001 17.2157V15.755C21.0001 12.8886 18.7284 10.5578 15.878 10.435L15.8781 10.4348Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_3"
            d="M6.35766 4C7.93102 4 9.21482 5.28173 9.21482 6.8559C9.21482 8.43008 7.93102 9.71433 6.35766 9.71433C4.7843 9.71433 3.50049 8.42987 3.50049 6.8559C3.50049 5.28177 4.7843 4 6.35766 4ZM6.35766 4.71188C5.1703 4.71188 4.21478 5.66773 4.21478 6.8557C4.21478 8.04367 5.1703 8.99951 6.35766 8.99951C7.54501 8.99951 8.50053 8.04367 8.50053 6.8557C8.50053 5.66773 7.54501 4.71188 6.35766 4.71188Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_4"
            d="M6.12412 10.4348C3.27288 10.5568 1 12.888 1 15.7549V17.2155C1.00064 17.4119 1.16008 17.5709 1.35651 17.5712H3.86212C4.05935 17.5712 4.21927 17.4113 4.21927 17.2141C4.21927 17.0168 4.05935 16.8569 3.86212 16.8569H1.71508V15.7547C1.71508 13.2741 3.65641 11.2698 6.1241 11.1489V11.1491C6.21881 11.1491 6.30969 11.1115 6.37665 11.0445C6.44362 10.9775 6.48125 10.8867 6.48125 10.792C6.48125 10.5947 6.32133 10.4348 6.1241 10.4348L6.12412 10.4348Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_5"
            d="M8.14373 11.1429C6.96482 11.1429 6.00085 12.1074 6.00085 13.2871C6.00085 14.4668 6.96482 15.4287 8.14373 15.4287C9.32264 15.4287 10.2866 14.4668 10.2866 13.2871C10.2866 12.1074 9.32264 11.1429 8.14373 11.1429ZM8.14373 11.8577C8.9368 11.8577 9.57231 12.4937 9.57231 13.2871C9.57231 14.0806 8.9368 14.7166 8.14373 14.7166C7.35066 14.7166 6.71515 14.0806 6.71515 13.2871C6.71515 12.4936 7.35066 11.8577 8.14373 11.8577Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_6"
            d="M8.14256 16.143C5.77425 16.143 3.85803 18.0612 3.85803 20.4287V20.7846V20.7845C3.85772 20.8792 3.89502 20.9702 3.96183 21.0375C4.02848 21.1048 4.1192 21.1427 4.21407 21.143H12.0713H12.0711C12.1663 21.1433 12.2578 21.1057 12.3251 21.0384C12.3924 20.9712 12.4302 20.8796 12.4297 20.7844V20.4286C12.4297 18.061 10.5108 16.1428 8.14272 16.1428L8.14256 16.143ZM8.14256 16.8573C10.1274 16.8573 11.7151 18.4445 11.7151 20.4287H4.57261C4.57261 18.4445 6.15761 16.8573 8.14248 16.8573H8.14256Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_7"
            d="M13.8585 11.1429C12.6788 11.1429 11.7144 12.1074 11.7144 13.2871C11.7144 14.4668 12.6788 15.4287 13.8585 15.4287C15.0382 15.4287 16.0001 14.4668 16.0001 13.2871C16.0001 12.1074 15.0382 11.1429 13.8585 11.1429ZM13.8585 11.8577C14.652 11.8577 15.288 12.4937 15.288 13.2871C15.288 14.0806 14.652 14.7166 13.8585 14.7166C13.065 14.7166 12.429 14.0806 12.429 13.2871C12.429 12.4936 13.065 11.8577 13.8585 11.8577Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
          <Path
            id="Vector_8"
            d="M13.8589 16.143C13.1841 16.143 12.5448 16.298 11.9769 16.5754C11.8061 16.6739 11.7474 16.892 11.8458 17.063C11.9445 17.2339 12.1521 17.2728 12.334 17.1934C12.7957 16.9773 13.3119 16.8571 13.8589 16.8571C15.8438 16.8571 17.4283 18.4444 17.4283 20.4286H13.5011C13.3039 20.4286 13.1439 20.5885 13.1439 20.7857C13.1439 20.9829 13.3039 21.1429 13.5011 21.1429H17.7868C17.9844 21.1425 18.1441 20.9818 18.1433 20.7843V20.4286C18.1433 18.061 16.2272 16.1428 13.8592 16.1428L13.8589 16.143Z"
            fill={color ?? theme.colors.primary}
            stroke={color ?? theme.colors.primary}
            strokeWidth="0.16"
          />
        </G>
        <Path
          id="Vector_9"
          d="M11.0375 1.22022L11 1.24603L10.9625 1.22022C10.4652 0.878261 9.794 0.940063 9.36654 1.36738C8.87782 1.85624 8.87782 2.6516 9.36654 3.14032L10.9864 4.76028L10.9999 4.74678L11.0135 4.76041L12.6335 3.14032C13.1222 2.6516 13.1222 1.85624 12.6335 1.36738C12.206 0.940196 11.5349 0.878129 11.0375 1.22022ZM12.3272 2.83423L11.0135 4.14782L11 4.13432L10.9864 4.14795L9.67251 2.83423C9.35265 2.51423 9.35265 1.99361 9.67251 1.67362C9.82722 1.51878 10.0334 1.43355 10.2528 1.43355C10.4724 1.43355 10.6784 1.51878 10.8331 1.67348L10.9997 1.84023L11.1663 1.67348C11.4865 1.35362 12.0072 1.35375 12.327 1.67348C12.6471 1.99361 12.6472 2.51423 12.3272 2.83423Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.32"
        />
      </G>
    </Svg>
  );
};

export default Family;
