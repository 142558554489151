import React from 'react';
import Svg, { Path, Circle, G } from 'react-native-svg';
import { IconProps } from './types';
import { useTheme } from 'styled-components/native';

const PrivacyIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 80} height={height ?? 80} viewBox="0 0 80 80" fill="none">
      <G>
        <Circle cx="39" cy="39" r="32" fill={color ?? theme.colors.info} />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M51.1 29.695L51.1017 29.6048L51.0122 29.5938C48.2619 29.2567 46.2566 28.902 44.4476 28.1032C42.6407 27.3054 41.0223 26.061 39.055 23.9321L38.9815 23.8526L38.9081 23.9322C36.9588 26.0426 35.3588 27.2871 33.561 28.0896C31.7613 28.8929 29.7562 29.2568 26.9879 29.5938L26.8986 29.6047L26.9 29.6947L27.0809 40.9681L27.081 40.9685C27.1542 44.5785 28.7543 47.2154 31.0188 49.2707C33.2799 51.323 36.2065 52.7985 38.9391 54.0904L38.9818 54.1106L39.0246 54.0904C41.7571 52.7985 44.6929 51.3231 46.9585 49.2708C49.2276 47.2154 50.8279 44.5784 50.8827 40.968L51.1 29.695Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
    </Svg>
  );
};

export default PrivacyIcon;
