import React, { useState, createContext, useContext, useEffect } from 'react';
import { SweetAlertProviderTypes, SweetAlertContextTypes, SweetAlertCustomConfig } from './types';

const SweetAlertContext = createContext<SweetAlertContextTypes | null>(null);

export const SweetAlertProvider = ({ children }: SweetAlertProviderTypes): JSX.Element => {
  const [statusAlert, setStatusAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>('success');
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>('');
  const [autoClose, setAutoClose] = useState<boolean>(false);
  const [hideButtons, setHideButtons] = useState<boolean>(false);
  const [custom, setCutoms] = useState<SweetAlertCustomConfig>();
  const [onResult, setOnResult] = useState<boolean>(false);
  const [dimiss, setDimiss] = useState<boolean>(false);

  const showSweetAlert = (
    title: string,
    message: string | JSX.Element,
    type?: string,
    autoclose = false,
    hideBtns = false,
    customsButtons?: SweetAlertCustomConfig,
  ) => {
    setStatusAlert(true);
    type && setAlertType(type);
    setAlertTitle(title);
    setAlertMessage(message);
    setAutoClose(autoclose);
    setHideButtons(hideBtns);
    setCutoms(customsButtons);
  };

  const hideSweetAlert = (onresult?: boolean) => {
    setStatusAlert(false);
    onresult && setOnResult(onresult);
  };

  useEffect(() => {
    if (dimiss) {
      setDimiss(false);
    }
  }, [dimiss]);

  return (
    <SweetAlertContext.Provider
      value={{
        alertType,
        statusAlert,
        alertTitle,
        alertMessage,
        autoClose,
        hideButtons,
        onResult,
        custom,
        hideSweetAlert,
        showSweetAlert,
        dimiss,
        setDimiss,
      }}>
      {children}
    </SweetAlertContext.Provider>
  );
};

export const useSweetAlert = (): SweetAlertContextTypes => {
  const context = useContext(SweetAlertContext);

  if (!context) {
    throw new Error('useSweetAlert must be used within an SweetAlertProvider');
  }

  return context;
};
