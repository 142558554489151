import React, { useEffect, useState, useMemo } from 'react';
import { ViewStyle, TextStyle, StyleProp, View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';
import { Loader } from '../Loader';
import { ButtonContainer, ButtonIcon } from './styles';

export interface CustomButtonProps {
  variant:
    | 'primary'
    | 'secondary'
    | 'action'
    | 'text'
    | 'outline'
    | 'link'
    | 'simple'
    | 'success'
    | 'successPrimary'
    | 'successOutline'
    | 'disabled'
    | 'outLine';
  disabled?: boolean;
  icon?: string | JSX.Element;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  iconSize?: number;
  children: string | JSX.Element;
  loading?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle & TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  className?: string;
  testID?: string;
}

export const Button = ({
  variant,
  disabled = false,
  icon,
  iconPosition = 'left',
  iconSize = 25,
  children,
  textStyle,
  loading = false,
  onPress,
  style,
  iconColor,
  testID,
}: CustomButtonProps) => {
  const state = useMemo(() => disabled, [disabled]);

  const theme = useTheme();

  const variantStyles: Record<CustomButtonProps['variant'], ViewStyle> = {
    primary: {
      backgroundColor: disabled ? theme.colors.backgroundDisabled : theme?.colors.primary,
    },
    disabled: {
      backgroundColor: theme.colors.backgroundDisabled,
    },
    secondary: {
      backgroundColor: theme?.colors.backgroundDisabled,
    },
    action: {
      backgroundColor: theme?.colors.white,
    },
    text: {
      backgroundColor: theme?.colors.white,
    },
    outline: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme?.colors.primary,
    },
    successOutline: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme?.colors.success,
    },
    link: {
      backgroundColor: 'transparent',
    },
    simple: {
      margin: 0,
      paddingLeft: 8,
      backgroundColor: theme?.colors.white,
    },
    success: {
      backgroundColor: 'transparent',
    },
    successPrimary: {
      ...(state
        ? {
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderColor: theme?.colors.textDisabled,
          }
        : {
            backgroundColor: theme.colors.success,
          }),
    },
    outLine: {
      borderWidth: 1,
      borderColor: disabled ? theme?.colors.textDisabled : theme?.colors.primary,
      backgroundColor: disabled ? '' : theme?.colors.primary,
    },
  };

  const iconColors: Record<CustomButtonProps['variant'], string> = {
    primary: theme.colors.white,
    secondary: theme.colors.primary,
    action: theme.colors.primary,
    text: theme.colors.black,
    outline: theme.colors.black,
    link: theme?.colors.primary,
    simple: theme.colors.primary,
    success: theme.colors.success,
    successOutline: theme.colors.success,
    successPrimary: theme.colors.white,
    disabled: theme.colors.primary,
    outLine: '#ac0c0c',
  };

  const textColors: Record<CustomButtonProps['variant'], string> = {
    primary: disabled ? theme.colors.buttonDisabled : theme.colors.white,
    secondary: theme.colors.primary,
    action: theme.colors.black,
    text: theme.colors.black,
    link: theme.colors.primary,
    outline: theme?.colors.primary,
    simple: theme.colors.black,
    success: theme.colors.success,
    successOutline: theme.colors.success,
    successPrimary: state ? theme?.colors.textDisabled : theme.colors.white,
    disabled: theme.colors.primary,
    outLine: disabled ? theme.colors.textDisabled : '',
  };

  const typographyVariants: Record<CustomButtonProps['variant'], 'button' | 'link' | 'helperText'> =
    {
      primary: 'button',
      secondary: 'button',
      action: 'button',
      text: 'button',
      link: 'link',
      outline: 'button',
      simple: 'helperText',
      success: 'link',
      successPrimary: 'button',
      successOutline: 'button',
      disabled: 'button',
      outLine: 'button',
    };

  const loaderItem = (
    <View className="h-28 justify-center">
      <Loader data-testid="loader-element" color={textColors[variant]} />
    </View>
  );

  const [buttonBody, setButtonBody] = useState<JSX.Element>(loaderItem);

  const renderIcon = () => {
    if (icon) {
      return (
        <ButtonIcon data-testid="icon-element" iconPosition={iconPosition}>
          {typeof icon == 'string' ? (
            <Icon name={icon} size={iconSize} color={iconColor ?? iconColors[variant]} data />
          ) : (
            icon
          )}
        </ButtonIcon>
      );
    }
    return null;
  };

  useEffect(() => {
    if (loading) {
      setButtonBody(loaderItem);
    } else {
      setButtonBody(
        <>
          {iconPosition === 'left' && renderIcon()}
          <Typography
            color={style?.color ?? textColors[variant]}
            variant={typographyVariants[variant]}
            style={{ textAlign: 'center', ...(textStyle as TextStyle) }}>
            {children}
          </Typography>
          {iconPosition === 'right' && renderIcon()}
        </>,
      );
    }
  }, [loading, disabled, icon, children, variant, state]);

  return (
    <ButtonContainer
      testID={testID}
      style={[variantStyles[variant], style]}
      iconPosition={iconPosition}
      disabled={state}
      variant={variant}
      loader={loading}
      onPress={onPress}
      data-testid="button-element">
      {buttonBody}
    </ButtonContainer>
  );
};
