import styled from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';

export const Container = styled(Wrapper)``;

export const WrapperBillet = styled.View`
  width: 100%;
  max-width: 608px;
  margin: 0 auto 64px;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const WrapperList = styled.View`
  width: 100%;
  max-width: 608px;
  margin: 35px auto;
  align-items: center;
  justify-content: center;
  border-top-color: ${({ theme: { colors } }) => colors.black16};
  border-top-width: 0.5px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  border-bottom-width: 0.5px;
`;

export const WrapperItem = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: 24px;
`;

export const ItemIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.green20};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

export const ItemContent = styled.View`
  flex: 1;
`;

export const ItemAmount = styled.View``;

export const ItemIconContent = styled.View`
  height: 45px;
  text-align: center;
  margin: auto;
`;

export const StrongText = styled.Text`
  font-weight: 600;
`;

export const CenterText = styled.Text`
  text-align: center;
`;
