import React from 'react';
import Svg, { Path, Circle, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M7 6h16a1 1 0 000-2H7a1 1 0 000 2zM23 11H7a1 1 0 000 2h16a1 1 0 000-2zM23 18H7a1 1 0 000 2h16a1 1 0 000-2z"
      />
      <Circle fill={color} cx="2" cy="5" r="2" />
      <Circle fill={color} cx="2" cy="12" r="2" />
      <Circle fill={color} cx="2" cy="19" r="2" />
    </Svg>
  );
}

export default Icon;
