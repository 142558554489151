import React from 'react';
import { useNavigation } from '@react-navigation/core';

import { Typography } from '../../components/@tem-ui';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Spacing } from '../../components/Spacing';
import * as S from './styles';

import { FooterPhones } from './components/FooterPhones';
import { useTheme } from 'styled-components/native';

import Icon from 'react-native-vector-icons/dist/Feather';
import IconS from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useMedia } from '~/components/@hello-ui';

export const SurgicalProcedureSuccess = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <S.WrapperHeader>
          <S.WrapperHeaderContainer>
            <S.WrapperIcon>
              <IconS
                name={'check-circle-outline'}
                size={68}
                color={theme.colors.white}
                style={{ textAlign: 'center' }}
              />
            </S.WrapperIcon>

            <Typography
              variant="title2"
              color={theme?.colors.white}
              style={{ textAlign: 'center' }}>
              Orçamento solicitado
            </Typography>
          </S.WrapperHeaderContainer>
        </S.WrapperHeader>
        <S.WrapperContent>
          <Typography variant="heading1" color={theme.colors.black}>
            O que fazer agora?
          </Typography>

          <Spacing top={15}>
            <Typography variant="body2" color={theme.colors.black70}>
              Agora é só aguardar.
            </Typography>
            <Typography variant="body2" color={theme.colors.black70}>
              Dentro de 5 dias úteis, a Vidia entrará em contato com você com maiores informações
              sobre o seu procedimento cirúrgico.
            </Typography>
          </Spacing>

          <Spacing top={15}>
            <Typography variant="heading3" color={theme.colors.black80}>
              Está com dúvidas?
            </Typography>
            <Typography variant="body2" color={theme.colors.black70}>
              O que mais importa pra gente é cuidar de você , por isso, nossa{' '}
              <Typography variant="heading3" color={theme.colors.black70}>
                Central de Agendamento
              </Typography>{' '}
              vai estar disponível pra te auxiliar a todo momento, tá bom?
            </Typography>
          </Spacing>

          <Spacing top={15}>
            <Typography variant="body2" color={theme.colors.black70}>
              Até mais :)
            </Typography>
          </Spacing>

          <S.WrapperCards>
            <S.CardItem onPress={() => navigation.navigate('Dashboard')}>
              <S.CardItemIcon>
                <Icon name="home" size={20} color={theme?.colors.primary} />
              </S.CardItemIcon>
              <Typography variant="heading3" color="black">
                Início
              </Typography>
            </S.CardItem>
            <S.CardItem onPress={() => navigation.navigate('TemPayDashboard')}>
              <S.CardItemIcon>
                <Icon name="credit-card" size={20} color={theme?.colors.primary} />
              </S.CardItemIcon>
              <Typography variant="heading3" color="black">
                TemPay
              </Typography>
            </S.CardItem>
          </S.WrapperCards>
        </S.WrapperContent>
        {media.isMobile && <FooterPhones />}
        <Footer />
      </S.Container>
    </>
  );
};
