import React from 'react';
import * as S from './styles';
import { ItemType } from './types/itemsTypes';
import { StepperListType } from './types';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useTracking } from '~/services/tracking';
import { CardButton, Typography } from '~/components/@hello-ui';

export const StepperList = ({ items, title, description }: StepperListType): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const { toStepper, setStepData } = useStepper();
  const { tracking } = useTracking();

  return (
    <S.WrapperSchedule>
      {title ? (
        <S.WrapperTitle>
          <Typography variant="subtitle">{title}</Typography>
          <Typography variant="body2">{description ?? ''}</Typography>
        </S.WrapperTitle>
      ) : (
        <></>
      )}

      {items.map(
        (schedule: ItemType, idx: number) =>
          schedule.visible && (
            <S.WrapperScheduleCard key={idx}>
              <CardButton
                variant="xlarge"
                title={schedule.title}
                iconSideOfTitle={schedule.iconSideOfTitle}
                description={schedule.description}
                icon={schedule.icon}
                onPress={() => {
                  if (schedule.tracking) {
                    void tracking.addMovement({
                      event: schedule.tracking,
                    });
                  }

                  if (!schedule.disabled && !schedule.onPress) {
                    toStepper(idx);
                    setStepData(schedule);
                  } else {
                    schedule.onPress ? schedule.onPress() : null;
                  }
                }}
              />
            </S.WrapperScheduleCard>
          ),
      )}
    </S.WrapperSchedule>
  );
};
