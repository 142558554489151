import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import * as S from '../../styles';
import { DependentsList } from './DependentList';
import { useAppointment } from '~/hooks/appointment';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useAuth } from '~/auth/legacy/useAuth';
import { Typography } from '~/components/@hello-ui';

export const SelectDependents = ({ isCheckup }: { isCheckup?: boolean }): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: false });
  const theme = useTheme();

  const { dependents, isDependent, checkIsEnabledFeature } = useAuth();
  const { nextStep } = useStepper();
  const { patient, scheduleSubmissionStatus, appointment } = useAppointment();

  const [requestingConsultation, setRequestingConsultation] = useState(false);
  useEffect(() => {
    const activeDependents = getActiveDependents();

    if (appointment && appointment.days && (!activeDependents || patient)) {
      setRequestingConsultation(true);
    }
  }, [patient, dependents]);

  useEffect(() => {
    const activeDependents = getActiveDependents();

    if (appointment && appointment?.days && (!activeDependents || patient)) {
      setRequestingConsultation(false);
      nextStep();
    }
  }, [scheduleSubmissionStatus, patient]);

  const getActiveDependents = () => {
    const dependentsLimit = theme.dependents_limit === '' ? 0 : theme.dependents_limit;

    const activeDependents =
      dependents?.filter(({ status }, idx): boolean => {
        return status !== 3 && idx < parseInt(dependentsLimit);
      }) ?? [];

    const disabledDependent =
      isDependent === null || isDependent || !checkIsEnabledFeature('dependents');

    return activeDependents.length > 0 && !disabledDependent;
  };

  return (
    <>
      <S.TitleWrapper>
        <Typography variant="title" color={theme.colors.title}>
          Para quem?
        </Typography>
      </S.TitleWrapper>
      <S.BodyWrapper>
        <Typography variant="body2" color={theme.colors.paragraph}>
          Selecione para quem será o agendamento
        </Typography>
      </S.BodyWrapper>
      <DependentsList isCheckup={isCheckup} />
    </>
  );
};
