import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { Typography, useMenu } from '../@tem-ui';
import { useAuth } from '../../hooks/auth';
import { Spacing } from '../Spacing';
import { MenuItem } from '../MenuItem';
import { split, capitalize } from '../../utils/strings';
import { useMedia } from '../@hello-ui';
import { HelthAccount } from '../@hello-ui/HelthAccount';
import * as S from './styles';
import { User } from './components/User';

export const Header = (): JSX.Element => {
  const { user } = useAuth();
  const name = capitalize(split(user?.name ?? '')[0]);
  const navigation = useNavigation();
  const route = useRoute();
  const { openMenu } = useMenu();
  const media = useMedia();

  return (
    <S.Wrapper testID="header-component">
      <S.WrapperColumnTouch
        testID="dashboardRedirect"
        onPress={() => navigation.navigate('Dashboard')}>
        <Typography
          testID="headerTest"
          variant={media.isMobile ? 'heading1' : 'title2'}
          color="black">
          {`Olá, ${name}`}
        </Typography>
      </S.WrapperColumnTouch>
      <S.WrapperColumnRight>
        <HelthAccount />
        <S.WrapperClick
          testID="menu"
          onPress={() =>
            openMenu('Minha Conta', <MenuItem route={route.name} navigation={navigation} />)
          }>
          <Spacing left={media.isMobile ? 0 : 64}>
            <User testID="user-component" />
          </Spacing>
        </S.WrapperClick>
      </S.WrapperColumnRight>
    </S.Wrapper>
  );
};
