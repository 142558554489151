import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { useRecoilState } from 'recoil';
import { useSweetAlert } from '../../@hello-ui/SweetAlert';
import { tempFiles, tempLoading, tempFileWithBigSize, tempMaxFileSize } from './atom';
import { DeleteOptionsType, FileType, PickerOptionsEnum, usePickerDocument } from './types';
import { pickerDocument } from './pickerDocument';

export const useUploader = (): usePickerDocument => {
  const [maxFileSize, setMaxFileSize] = useRecoilState(tempMaxFileSize);
  const [files, setFiles] = useRecoilState(tempFiles ?? []);
  const [isLoading, setLoading] = useRecoilState(tempLoading);
  const [haveLargeSizeFile, setFileWithBigSize] = useRecoilState(tempFileWithBigSize);

  const regexValidFormats = /^image\/(jpe?g|png)$|^application\/(pdf)$/gm;

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const onAlertSizeFile = useCallback(() => {
    showSweetAlert(
      'Tamanho excedido',
      'O tamanho do arquivo é maior que o permitido',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            testID: 'link',
            onPress: () => hideSweetAlert(),
          },
        ],
      },
    );
  }, []);

  const onAlertFormatFile = useCallback(() => {
    showSweetAlert(
      'Formato inválido',
      'O formato do arquivo selecionado não é aceito',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            testID: 'link',
            onPress: () => hideSweetAlert(),
          },
        ],
      },
    );
  }, []);

  useEffect(() => {
    const bigSizeFiles = files?.filter((file) => {
      return file?.size && file?.size.number > maxFileSize;
    });

    setFileWithBigSize(bigSizeFiles?.length ? true : false);
    setLoading(false);
  }, [files]);

  useEffect(() => {
    if (haveLargeSizeFile) {
      onAlertSizeFile();
    }
  }, [haveLargeSizeFile]);

  const addFile = async (typePicker: PickerOptionsEnum, target?: any) => {
    try {
      const filePicker = await pickerDocument(typePicker, target);

      if (filePicker) {
        setLoading(true);
        const uploadedFile: FileType = Platform.OS === 'web' ? filePicker[0] : filePicker;

        if (uploadedFile.size.number > maxFileSize) {
          onAlertSizeFile();
          return;
        }

        if (!regexValidFormats.test(uploadedFile.type as string)) {
          onAlertFormatFile();
          return;
        }

        setFiles(files?.length > 0 ? [...Array.from(files), uploadedFile] : [uploadedFile]);
      }
    } catch (error: any) {
      showSweetAlert('Ops, algo deu errado', error.message, 'error');
    }
  };

  const deleteFile = (items: FileType[], idx: number, options?: DeleteOptionsType) => {
    const selectedItem = items[idx];

    if (selectedItem?.size?.number > maxFileSize) {
      setFiles(items.filter((item, i) => idx !== i));
    } else {
      showSweetAlert(
        options?.title ?? '',
        options?.subtitle ?? 'Deseja excluir o arquivo?',
        'info',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Não',
              variant: 'outline',
              testID: 'link',
              onPress: () => hideSweetAlert(),
            },
            {
              text: 'Sim',
              variant: 'primary',
              testID: 'accept-button',
              onPress: () => {
                setFiles(items.filter((item, i) => idx !== i));
                // hideSweetAlert();
                showSweetAlert('Tudo certo!', 'O pedido foi excluído', 'success', false, false, {
                  layout: 'helloUi',
                  buttons: [
                    {
                      text: 'Ok',
                      variant: 'primary',
                      testID: 'link',
                      onPress: () => hideSweetAlert(),
                    },
                  ],
                });
              },
            },
          ],
        },
      );
    }
  };

  const clearUploader = () => {
    setFiles([]);
    setMaxFileSize(5);
    setLoading(false);
    setFileWithBigSize(false);
  };

  return {
    setMaxFileSize,
    maxFileSize,
    setFiles,
    files,
    setLoading,
    isLoading,
    setFileWithBigSize,
    haveLargeSizeFile,
    addFile,
    deleteFile,
    clearUploader,
  };
};
