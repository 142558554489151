import React from 'react';
import { ListRenderItemInfo, Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Listing } from '../@hello-ui/Listing';
import { LocationCard } from './LocationCard';
import { ListItem } from '~/components/MapViewList/MapViewListDTO';

interface LocationCardListProps<T> {
  data: ListItem<T>[];
  loading?: boolean;
  onPress?: (item: ListItem<T>) => void;
  handleNextPage?: () => void;
  showNextPageButton?: boolean;
  hasButton: boolean;
}

export const LocationCardList = <T,>({
  data,
  onPress,
  loading,
  hasButton,
  handleNextPage,
  showNextPageButton = false,
}: LocationCardListProps<T>) => {
  const theme = useTheme();

  const isPair = data && data.length % 2 === 0;

  return (
    <Listing
      testID="unit-list"
      twoColumns
      data={data}
      loading={loading}
      fixedBackToTop
      showNextPageButton={showNextPageButton}
      handleNextPage={handleNextPage}
      keyExtractor={(item: ListItem<T>) => item.id}
      renderItem={(props: ListRenderItemInfo<ListItem<T>>, metadata) => {
        return (
          <LocationCard
            wrapperStyle={{
              ...(Platform.OS === 'web' && {
                flex: 1,
                ...(!isPair &&
                  props.index === data.length - 1 &&
                  metadata.columnsCount === 2 && {
                    maxWidth: '50%',
                  }),
              }),
            }}
            style={{
              ...(Platform.OS === 'web' && {
                flex: 1,
              }),
            }}
            contentStyle={{
              ...(Platform.OS === 'web' && {
                flex: 1,
              }),
            }}
            locationData={props.item}
            onPressButton={onPress}
            hasButton={hasButton}
            hasShadow
            withGap={true}
          />
        );
      }}
      heightValForCalc={theme.isMobile ? 147 : 202}
      contentStyle={{
        paddingTop: 12,
        marginHorizontal: theme.isMobile ? 8 : 16,
      }}
    />
  );
};
