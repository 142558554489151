import { View } from 'react-native';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { CardButton, Typography } from '~/components/@hello-ui';
import LockPasswordIcon from '~/components/Icons/LockPasswordIcon';
import { Spacing } from '~/components/Spacing';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

interface DetailsProps {
  openTokenOrientationModal: () => void;
}

export const Details: React.FC<DetailsProps> = ({ openTokenOrientationModal }) => {
  const theme = useTheme();
  const { isDeepLink } = DeepLink.useParams();

  const content: JSX.Element = (
    <>
      <View className="mb-8">
        <Typography variant="body2">
          Utilize a senha da Conta Saúde para{' '}
          <Typography variant="bodyHighlight2">confirmar o pagamento</Typography> das suas consultas
          e exames na rede credenciada.
        </Typography>
      </View>
      <View className="mb-40">
        <Typography variant="body2">
          Informe essa senha ao atendente quando estiver na clínica ou laboratório para liberação do
          seu atendimento.
        </Typography>
      </View>
      <CardButton
        variant="large"
        title="Senha da Conta Saúde"
        description="Atualize sua senha para pagamentos"
        iconSideOfTitle
        icon={<LockPasswordIcon color={theme.colors.primary} />}
        onPress={openTokenOrientationModal}
      />
    </>
  );

  return (
    <>
      {isDeepLink ? (
        <Spacing top={20} right={20} left={20}>
          {content}
        </Spacing>
      ) : (
        content
      )}
    </>
  );
};
