import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconS from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useAppointment } from '../../hooks/appointment';
import { Typography } from '../../components/@tem-ui';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Spacing } from '../../components/Spacing';
import { Card } from './components/Card';
import { Steps } from './components/Steps';
import * as S from './styles';
import { FooterPhones } from './components/FooterPhones';
import { TypesEnum } from '~/enums/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMedia } from '~/components/@hello-ui';

export const ScheduleAppointmentSuccess = (): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();
  const navigation = useNavigation();

  const { onixCode } = useAuth();
  const { appointment, clearAppointments } = useAppointment();

  const [specialty, setSpecilty] = useState<string>('');
  const [isConectaTelemedicine, setIsConectaTelemedicine] = useState(undefined);
  const onixConecta = ['7208', '7209', '7210', '7309', '7310', '7311'];

  useEffect(() => {
    setSpecilty(appointment?.type);
    if (appointment) {
      setIsConectaTelemedicine(
        onixConecta?.includes(onixCode?.onix_code) && appointment?.type === 1,
      );
    }
  }, []);

  useEffect(() => {
    if (specialty !== '' && isConectaTelemedicine !== undefined) {
      clearAppointments();
    }
  }, [specialty]);

  const checkTitleByAppointmentType = (type: TypesEnum) => {
    if (type === TypesEnum.Exam) {
      return 'Pronto, já estamos buscando os laboratórios mais próximos de você';
    }

    if (type === TypesEnum.Terapiaonline || type === TypesEnum.Video) {
      return 'Pronto, já estamos buscando as clínicas com horários disponíveis :)';
    }

    if (type === TypesEnum.Surgical) {
      return 'Pronto, já estamos providenciando seu atendimento.';
    }

    return 'Pronto, já estamos buscando as clínicas mais próximas de você';
  };

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card />
        <S.WrapperHeader>
          <S.WrapperHeaderContainer>
            {isConectaTelemedicine && (
              <S.WrapperTelemedicineHeader>
                <S.WrapperIcon>
                  <IconS name={'check-circle-outline'} size={68} color={theme.colors.white} />
                </S.WrapperIcon>
                <Spacing top={15} bottom={15}>
                  <Typography variant="title2" color={theme?.colors.white}>
                    Agendamento realizado
                  </Typography>
                </Spacing>

                <Typography variant="subtitle1" color={theme?.colors.white}>
                  {specialty === TypesEnum.Surgical
                    ? 'Tudo certo ;)'
                    : 'Seu atendimento está marcado :)'}
                </Typography>
              </S.WrapperTelemedicineHeader>
            )}

            {!isConectaTelemedicine && (
              <Typography variant="title2" color={theme?.colors.white}>
                {checkTitleByAppointmentType(specialty)}
              </Typography>
            )}

            {!isConectaTelemedicine && <Steps type={specialty} />}
          </S.WrapperHeaderContainer>
        </S.WrapperHeader>
        <S.WrapperContent>
          {!isConectaTelemedicine && (
            <Typography variant="heading1" color="black">
              O que fazer agora?
            </Typography>
          )}

          <Spacing top={15}>
            {isConectaTelemedicine ? (
              <>
                <Spacing bottom={10}>
                  <Typography variant="body2" color="black">
                    A sua consulta por vídeo está agendada e, em breve, você receberá também um
                    e-mail e SMS de confirmação.
                  </Typography>
                </Spacing>
                <Typography variant="body2" color="black">
                  Na data do seu agendamento, algumas horas antes do horário marcado, você receberá
                  um e-mail com o link para que acesse a sua consulta por vídeo.
                </Typography>
                <Typography variant="body2" color="black">
                  Até mais!
                </Typography>
              </>
            ) : (
              <>
                {specialty === TypesEnum.Terapiaonline || specialty === TypesEnum.Video ? (
                  <Typography variant="body2" color="black">
                    1. Nossa equipe vai encontrar as clínicas com horários disponíveis;{'\n\n'}
                    2. Em até 2 dias úteis, vamos entrar em contato com você por Email e Whatsapp
                    para marcar a DATA e HORA da consulta por vídeo;{'\n\n'}
                    3. No dia da consulta, você vai receber um SMS com o link de acesso da sala
                    virtual para fazer a consulta com
                    <Typography variant="body2" color="black" bold>
                      {specialty === TypesEnum.Terapiaonline
                        ? ' seu(a) psicólogo(a).'
                        : ' seu(a) médico(a).'}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="body2" color="black">
                    Recebemos sua solicitação de agendamento e em até dois dias úteis, a nossa
                    equipe vai te enviar por e-mail e WhatsApp as melhores opções de região, data, e
                    horário para você marcar sua consulta.
                    {'\n\n'}O pagamento será só depois que você aprovar sua opção de agendamento :)
                  </Typography>
                )}
              </>
            )}
          </Spacing>
          <S.WrapperCards>
            <S.CardItem onPress={() => navigation.navigate('Dashboard')}>
              <S.CardItemIcon>
                <Icon name="home" size={20} color={theme?.colors.primary} />
              </S.CardItemIcon>
              <Typography variant="heading3" color="black">
                Início
              </Typography>
            </S.CardItem>
            <S.CardItem onPress={() => navigation.navigate('TemPayDashboard')}>
              <S.CardItemIcon>
                <Icon name="credit-card" size={20} color={theme?.colors.primary} />
              </S.CardItemIcon>
              <Typography variant="heading3" color="black">
                TemPay
              </Typography>
            </S.CardItem>
          </S.WrapperCards>
          <Typography variant="body2" color="black" style={{ textAlign: 'center' }}>
            Ficou alguma dúvida?
          </Typography>
        </S.WrapperContent>
        {media.isMobile && <FooterPhones />}
        <Footer />
      </S.Container>
    </>
  );
};
