import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const House = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 16} height={height ?? 16} viewBox="0 0 16 16" fill="none">
      <G id="icon-home">
        <Path
          id="Vector"
          d="M7.77844 1.40518L2.14205 5.62739C2.05365 5.69305 1.99962 5.79993 2 5.9078V13.6482C2 13.8324 2.17325 14 2.36361 14H6.18181C6.37218 14 6.54542 13.8324 6.54542 13.6482V11.1852C6.54542 10.3994 7.18779 9.77776 8 9.77776C8.81208 9.77776 9.45459 10.3993 9.45459 11.1852V13.6482C9.45459 13.8324 9.62783 14 9.81819 14H13.6364C13.8268 14 14 13.8324 14 13.6482V5.9078C14 5.79993 13.9464 5.69317 13.858 5.62739L8.22156 1.40518C8.08573 1.30222 7.89627 1.31694 7.77844 1.40518ZM8.00001 2.1254L13.2728 6.07808V13.2964H10.1819V11.1853C10.1819 10.0218 9.20251 9.07422 8.00007 9.07422C6.79763 9.07422 5.81828 10.0218 5.81828 11.1853V13.2964H2.72738V6.07808L8.00001 2.1254Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.2"
        />
      </G>
    </Svg>
  );
};

export default House;
