import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const SafeWrap = styled.SafeAreaView`
  align-items: flex-end;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled(Animated.ScrollView)`
  width: 100%;
  max-width: 472px;
  background-color: ${({ theme: { colors } }) => colors.black5};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  z-index: 1;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      max-width: ${Dimensions.get('window').width}px;
  `}
`;

export const WrapperClose = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  right: 40px;
  top: 20px;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.black8};
  z-index: 2;
`;

export const WrapperTitle = styled.View`
  height: 214px;
  justify-content: flex-end;
  padding-left: 70px;
  padding-bottom: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  max-width: 100%;
`;

export const WrapperItens = styled.View``;

export const ModalMenuBackdrop = styled.Pressable`
  height: ${Dimensions.get('window').height}px;
  width: ${Dimensions.get('window').width}px;
  position: absolute;
  z-index: -1;
`;
