import cardValidator from 'card-validator';

export const isDateValid = (number: string): boolean => {
  if (cardValidator.expirationDate(number).isValid) {
    return true;
  }

  return false;
};

export const isBirthdateValid = (number: string): boolean => {
  const [day, month, year] = number.split('/');
  const validDate = new Date(Number(year), Number(month - 1), Number(day));
  const currentDate = new Date();

  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  if (validDate <= currentDate) return true;

  return false;
};

export const isBrandValid = (number: string): string => {
  return cardValidator.number(number).card?.type.toUpperCase();
};
