import { atom } from 'recoil';
import type { StepTypes, UserType } from './types';

export const stepState = atom<StepTypes>({
  key: 'loginStepState',
  default: 'welcome',
});

export const userState = atom<UserType>({
  key: 'loginUserState',
  default: {},
});

export const tokenState = atom<string | null>({
  key: 'loginTokenState',
  default: null,
});

export const attemptState = atom<number>({
  key: 'loginAttemptState',
  default: 0,
});
