import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const WrapperPeriodTimes = styled.View`
  flex-flow: row wrap;
  margin-top: 10px;
  margin-bottom: 12px;
  width: 100%;
`;

export const TimetableWrapper = styled.View`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
`;

export const WrapperPeriod = styled.TouchableOpacity<{
  selected?: boolean;
  disabled?: boolean;
}>`
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.white25 : colors.white};

  width: 25%;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: ${Platform.OS !== 'web' ? 80 : 95}px;
  text-align: center;
`;

export const WrapperHour = styled.View<{
  selected?: boolean;
  disabled?: boolean;
}>`
  text-align: center;
  align-items: center;
  padding-top: 8px;
  width: 93%;
  height: 85%;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${({ theme: { colors }, selected, disabled }) =>
    selected ? colors.primary : disabled ? colors.black8 : colors.black16};

  background-color: ${({ theme: { colors }, selected, disabled }) =>
    selected ? colors.primary : disabled ? colors.white25 : colors.white};
`;

export const WrapperLoading = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.primary,
})``;
