import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const SubsidizedMedicines = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19.2552 22.0693C18.2551 22.0693 17.3146 21.6796 16.6072 20.9722C16.4372 20.8026 16.2873 20.6216 16.1621 20.4351L16.9193 19.9264C17.0118 20.0641 17.1238 20.1991 17.252 20.327C17.7874 20.8623 18.4986 21.1571 19.2552 21.1571C20.0121 21.1571 20.7233 20.8623 21.2584 20.3274C21.7935 19.7925 22.0882 19.081 22.0882 18.3244C22.0882 17.5675 21.7935 16.8563 21.2584 16.3214C20.5462 15.6092 19.4996 15.3263 18.5261 15.5874C18.1934 15.6751 17.8798 15.824 17.6007 16.0247L18.1075 16.5315L17.4624 17.1766L16.2845 15.9989L16.607 15.6764C17.0743 15.2091 17.6569 14.8733 18.2917 14.7053C19.5789 14.3628 20.9621 14.7346 21.903 15.6764C22.6106 16.3837 23.0001 17.324 23.0001 18.3244C23.0001 19.3248 22.6106 20.265 21.903 20.9724C21.1961 21.6796 20.2556 22.0693 19.2552 22.0693L19.2552 22.0693Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M16.6074 16.3208L17.2523 15.6758L21.9034 20.3268L21.2586 20.9718L16.6074 16.3208Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M14.6616 21.7378C14.3581 21.7382 14.0498 21.6853 13.7499 21.5758C12.3824 21.0795 11.6734 19.5597 12.1706 18.1884L13.8814 13.4912C14.2593 12.4507 15.2582 11.752 16.3667 11.752C16.6569 11.752 16.9458 11.8004 17.2258 11.8959C18.2135 12.2333 18.8767 13.2078 18.8767 14.3208V15.2273H17.9645V14.3208C17.9645 13.609 17.5395 12.9667 16.9309 12.7586C16.0403 12.4553 15.0503 12.9431 14.7386 13.8028L13.0278 18.4995C12.7024 19.3975 13.1661 20.3934 14.0622 20.7181C14.774 20.9787 15.5535 20.7489 16.0098 20.1712C15.4368 19.1597 15.3637 17.9076 15.8312 16.8319C16.0183 16.4003 16.2832 16.0116 16.6192 15.6756L16.9417 15.3531L18.1196 16.5308L17.4746 17.1758L16.9672 16.669C16.8489 16.833 16.7487 17.0089 16.6679 17.195C16.2826 18.0823 16.3826 19.1267 16.9285 19.9218L17.0904 20.1578L16.9486 20.4064C16.4669 21.2506 15.5852 21.7378 14.6616 21.7378L14.6616 21.7378Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M13.2871 16.4234L13.5995 15.5664L16.3941 16.5851L16.0817 17.4421L13.2871 16.4234Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M12.2997 6.10493H6.29327C5.18274 6.10493 4.2793 5.20128 4.2793 4.09075V4.01419C4.2793 2.90365 5.18274 2 6.29327 2H12.2997C13.4102 2 14.3137 2.90365 14.3137 4.01419V4.09075C14.3137 5.20128 13.4102 6.10493 12.2997 6.10493ZM6.29327 2.91224C5.6857 2.91224 5.1915 3.40662 5.1915 4.01423V4.09079C5.1915 4.69837 5.68567 5.19278 6.29327 5.19278H12.2997C12.9073 5.19278 13.4015 4.6984 13.4015 4.09079V4.01423C13.4015 3.40665 12.9073 2.91224 12.2997 2.91224H6.29327Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M13.1657 21.6127H5.47407C3.55845 21.6127 2 20.0542 2 18.1386V8.66216C2 6.74962 3.55621 5.19336 5.46901 5.19336H13.1211C15.0369 5.19336 16.5953 6.75181 16.5953 8.66743V12.4909H15.6831V8.66743C15.6831 7.25472 14.5337 6.10556 13.1213 6.10556H5.46922C4.05938 6.10556 2.91241 7.25253 2.91241 8.66216V18.1386C2.91241 19.5515 4.06183 20.7007 5.47428 20.7007H13.1659C13.3058 20.7007 13.4473 20.6879 13.5754 20.6628L13.7491 21.5581C13.562 21.5946 13.3659 21.6129 13.1659 21.6129L13.1657 21.6127Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M12.4903 18.4189H2V8.38477H16.5952V12.0336H15.683V9.29696H2.9122V17.5068H12.4903V18.4189Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M10.1535 10H8.2365C8.06006 10 7.917 10.1431 7.917 10.3195V11.917H6.3195C6.14306 11.917 6 12.0601 6 12.2365V14.1535C6 14.2382 6.03366 14.3195 6.09357 14.3794C6.15347 14.4393 6.23478 14.473 6.3195 14.473H7.917V16.0705C7.917 16.1552 7.95066 16.2365 8.01057 16.2964C8.07047 16.3563 8.15178 16.39 8.2365 16.39H10.1535C10.2382 16.39 10.3195 16.3563 10.3794 16.2964C10.4393 16.2365 10.473 16.1552 10.473 16.0705V14.473H12.0705C12.1552 14.473 12.2365 14.4393 12.2964 14.3794C12.3563 14.3195 12.39 14.2382 12.39 14.1535V12.2365C12.39 12.1518 12.3563 12.0705 12.2964 12.0106C12.2365 11.9507 12.1552 11.917 12.0705 11.917H10.473V10.3195C10.473 10.2348 10.4393 10.1535 10.3794 10.0936C10.3195 10.0337 10.2382 10 10.1535 10ZM11.751 12.556V13.834H10.1535C9.97706 13.834 9.834 13.9771 9.834 14.1535V15.751H8.556V14.1535C8.556 14.0688 8.52234 13.9875 8.46243 13.9276C8.40253 13.8677 8.32122 13.834 8.2365 13.834H6.639V12.556H8.2365C8.32122 12.556 8.40253 12.5223 8.46243 12.4624C8.52234 12.4025 8.556 12.3212 8.556 12.2365V10.639H9.834V12.2365C9.834 12.3212 9.86766 12.4025 9.92757 12.4624C9.98747 12.5223 10.0688 12.556 10.1535 12.556H11.751Z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
      />
    </Svg>
  );
};

export default SubsidizedMedicines;
