import { css } from 'styled-components';
import styled from 'styled-components/native';
import { getDimensions } from '~/components/@hello-ui/Theme/utils';

export const WrapperContentCard = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -16px;
  `}
`;

export const WrapperCard = styled.View`
  ${({ theme: { isMobile } }) => css`
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
    padding-horizontal: 8px;
    padding-top: 16px;
    ${getDimensions().width < 1366 &&
    css`
      width: 100%;
    `};
    ${isMobile &&
    css`
      width: 100%;
    `};
  `}
`;
