import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Card, Wrapper } from '~/components/@tem-ui';

export const Container = styled(Wrapper)``;

export interface FilterProps {
  color: string;
  opacity: number;
}

export interface RectangleProps {
  width: number;
  height: number;
}

export const WrapperHeader = styled.ImageBackground`
  width: 100%;
  height: 350px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    height: 200px;
  `}
`;

export const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 15px auto 0;
`;

export const WrapperContainer = styled.View`
  margin-bottom: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-bottom: 30px;
  `}
`;

export const HeaderIcon = styled.TouchableOpacity`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-top: 26px;
  z-index: 9;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const PanelHeaderImage = styled.View`
  width: 387px;
  height: 387px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 60px;
`;

const WrapperVariations = {
  web: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: ${({ theme: { colors } }) => colors.black};
    shadow-offset: ${Platform.OS === 'ios' ? '{width: 5, height: 5}' : '{width: 1, height: 2}'};
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
  `,
};

export const PanelHeaderFeatures = styled.View`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  align-self: flex-start;
  text-align: left;
  border-radius: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile ? WrapperVariations['mobile'] : WrapperVariations['web']}
`;

export const Features = styled.View`
  width: 100%;
  max-width: 608px;
  align-items: center;
  padding: 20px;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black16};
  width: 100%;
  height: 0.5px;
`;

export const FeaturesList = styled.FlatList`
  border-top-color: ${({ theme: { colors } }) => colors.black16};
  border-top-width: 0.5px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 15px;
  `}
`;

export const FeatureItem = styled.View`
  padding: 15px;
  flex-direction: row;
  align-items: center;
`;

export const WrapperContent = styled.View`
  align-items: center;
  margin: 30px 10px 0px 10px;
  height: 40px;
  justify-content: space-between;
  text-align: left;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 30px 15px 80px 15px;
  `}
`;

export const WrapperCard = styled.View`
  margin-top: 760px;
  width: 650px;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  text-align: center;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top:500px; 
    height: 230px;
    width: 100%;
  `}
`;

export const PanelContent = styled.View`
  top: 10px;
  width: 100%;
  margin: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding-horizontal: 16px;
      top: 10px;
    `};
`;

export const Info = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 10px;
  width: 100%;
  max-width: 608px;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-vertical: 24px;
  text-align: center;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  elevation: 2;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding-horizontal: 16px;
    `}
`;

export const InfoContact = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Help = styled.View`
  margin-top: 200px;
  margin-left: 24%;
  width: 650px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;  
    margin-top: 0px;
    margin-left:0%;
  `}
`;

export const TitlePosition = styled.Text`
  width: 100%;
  margin-left: 70px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  `}
`;

export const IconPosition = styled.View`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: -1;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top:-6px;
  `}
`;

export const between_lines = styled.Text`
  line-height: 28px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    line-height: 18px;
  `}
`;

export const left_position = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 0;
    padding: 16px;
    width: 100%;
  `}
`;

export const WrapperBox = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black8};
  border-radius: 10px;
  margin: 4px 0;
  padding: 24px 16px;
`;

export const WrapperContainerView = styled.View`
  max-width: 600px;
  margin: 0 auto 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 15px 0 15px;
  `}
  
`;

export const WrapperText = styled.View`
  padding: 0 0 16px 0;
`;

