import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { CHIP_IDEAL_WIDTH } from '~/components/@hello-ui';

export const TimetableWrapper = styled.View`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;

  ${Platform.OS === 'web'
    ? css`
        gap: ${(props) =>
          props.theme.isMobile ? props.theme.tokens.space.s8 : props.theme.tokens.space.s16};
      `
    : css`
        padding: ${8 / -2}px;
        margin-horizontal: ${8 / -2}px;
      `}
`;

export const EmptyChipSpace = styled.View`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${() => CHIP_IDEAL_WIDTH}px;

  ${Platform.OS !== 'web' &&
  css`
    margin: ${8 / 2}px;
  `}
`;
