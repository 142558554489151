import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Woman = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="10" height="14" viewBox="0 0 10 14" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M9.075 4.33337C9.075 2.0858 7.24758 0.258374 5 0.258374C2.75242 0.258374 0.925 2.0858 0.925 4.33337C0.925 6.45403 2.52907 8.19151 4.61731 8.38687V10.4122H3.15385C2.94319 10.4122 2.77115 10.5843 2.77115 10.7949C2.77115 11.0056 2.94319 11.1776 3.15385 11.1776H4.61731V12.9488C4.61731 13.1594 4.78935 13.3315 5 13.3315C5.21065 13.3315 5.38269 13.1594 5.38269 12.9488V11.1776H6.84615C7.05681 11.1776 7.22885 11.0056 7.22885 10.7949C7.22885 10.5843 7.05681 10.4122 6.84615 10.4122H5.38269V8.38687C7.47093 8.19151 9.075 6.45403 9.075 4.33337ZM5 7.64299C3.17373 7.64299 1.69038 6.15964 1.69038 4.33337C1.69038 2.5071 3.17373 1.02376 5 1.02376C6.82627 1.02376 8.30962 2.5071 8.30962 4.33337C8.30962 6.15964 6.82627 7.64299 5 7.64299Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
      </G>
    </Svg>
  );
};

export default Woman;
