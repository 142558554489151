export const RDUNIVERS_FEATURES = ['desconto_medicamentos_rdunivers'];

export const DROGAL_FEATURES = ['desconto_medicamentos_drogal'];

export const PAGMENOS_EXTRAFARMA_FEATURES = ['desconto_medicamentos_pagmenos_extrafarma'];

export const DPSP_FEATURES = ['desconto_medicamentos_dpsp'];

export const TEMFARMA_FEATURES = [
  ...RDUNIVERS_FEATURES,
  ...DROGAL_FEATURES,
  ...PAGMENOS_EXTRAFARMA_FEATURES,
  ...DPSP_FEATURES,
];
