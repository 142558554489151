import AsyncStorage from '@react-native-async-storage/async-storage';

interface createChatProps {
  config: any;
  firstMessage: string;
  authorName: string;
  autostart: boolean;
}

const personalizedColors = {
  whiteText: '#FFFFFF',
  entryPointBackground: '#f16145',
};

export const brandedColors = {
  EntryPoint: {
    Container: {
      background: personalizedColors.entryPointBackground,
      color: personalizedColors.whiteText,
      '&:hover': {
        backgroundColor: personalizedColors.entryPointBackground,
        backgroundBlendMode: 'color',
      },
    },
  },
  Chat: {
    MessageInput: {
      Container: {
        Button: {
          background: personalizedColors.entryPointBackground,
          color: personalizedColors.whiteText,
        },
      },
    },
  },
};

const translate = (webChat: { init?: () => void; _manager?: any }) => {
  webChat._manager._strings.InputPlaceHolder = 'Digite a mensagem';
  webChat._manager._strings.AttachFileImageTooltip = 'Anexar arquivo ou imagem';
  webChat._manager._strings.AttachFileInvalidSize =
    '{{fileName}} é muito grande para anexar. O tamanho máximo do arquivo é {{maxFileSize}}.';
  webChat._manager._strings.AttachFileInvalidType =
    '{{fileName}} não pode ser anexado porque o tipo de arquivo não é compatível. Por favor, tente um arquivo diferente.';
  webChat._manager._strings.ChatInputUserNotMemberDisabledText =
    'Você não é um participante do bate-papo';
  webChat._manager._strings.ConfirmEndEngagementConfirmButton = 'Sim, acabei';
  webChat._manager._strings.ConfirmEndEngagementDenyButton = 'Não, me leve de volta';
  webChat._manager._strings.ConfirmEndEngagementMessage =
    'Tem certeza de que deseja encerrar este bate-papo?';
  webChat._manager._strings.Connecting = 'Conectando…';
  webChat._manager._strings.Disconnected = 'Conexão perdida';
  webChat._manager._strings.DownloadFileInvalidSize =
    '{{fileName}} é muito grande para baixar. O tamanho máximo do arquivo é {{maxFileSize}}.';
  webChat._manager._strings.DownloadFileInvalidType =
    '{{fileName}} não pode ser baixado porque o tipo de arquivo não é suportado. Por favor, tente um arquivo diferente.';
  webChat._manager._strings.DownloadFileNotParticipant =
    'Não é possível baixar o arquivo. Você não é um participante do bate-papo.';
  webChat._manager._strings.EntryPointTagline = 'Chat de atendimento';
  webChat._manager._strings.ErrorRefreshButton = 'Tente novamente';
  webChat._manager._strings.FieldValidationInvalidEmail =
    'Por favor, forneça um endereço de e-mail válido';
  webChat._manager._strings.FieldValidationRequiredField = 'Campo requerido';
  webChat._manager._strings.FileAttachmentDropAreaSubtitle = 'Solte o arquivo aqui';
  webChat._manager._strings.FileAttachmentDropAreaTitle = 'Anexar um arquivo ou uma imagem';
  webChat._manager._strings.FilterItemAmountSelected = '{{amount}} selecionado';
  webChat._manager._strings.FilterItemAny = 'Algum';
  webChat._manager._strings.FilterItemOnly = '{{item.label}} só';
  webChat._manager._strings.InputPlaceHolder = 'Digite a mensagem';
  webChat._manager._strings.InvalidPreEngagementButton = 'Saber mais';
  webChat._manager._strings.InvalidPreEngagementMessage =
    'Os formulários de pré-compromisso não foram definidos e são necessários para iniciar o bate-papo na web. Por favor, configure-os agora na configuração.';
  webChat._manager._strings.LegacyFiltersEmptySelectLabel = 'Nenhum';
  webChat._manager._strings.LegacyFiltersTitle = 'Filtros personalizados';
  webChat._manager._strings.ManageAgentsToQueueBreadCrumb = 'Gerenciar agentes';
  webChat._manager._strings.MediaMessageClickToOpen = 'Abrir arquivo';
  webChat._manager._strings.MediaMessageError = 'As mensagens de mídia não são compatíveis';
  webChat._manager._strings.MediaMessageTransferFailed = 'Falha no envio';
  webChat._manager._strings.MessageCanvasTrayButton = 'INICIAR NOVO CHAT';
  webChat._manager._strings.MessageCanvasTrayContent =
    '\n<h6>Obrigado por conversar conosco!</h6>\n<p>Se você tiver mais alguma dúvida, entre em contato conosco novamente.</p>';
  webChat._manager._strings.MessageCharacterCountReached =
    'Contagem de caracteres alcançada - {{currentCharCount}} / {{maxCharCount}}';
  webChat._manager._strings.MessageCharacterCountStatus = '{{currentCharCount}} / {{maxCharCount}}';
  webChat._manager._strings.MessageSendingDisabled = 'O envio de mensagens foi desativado';
  webChat._manager._strings.NewChatMessageNotificationBody =
    '{{#if lastMessage.source.body}} {{lastMessage.source.body}} {{else}} arquivo enviado {{/if}}';
  webChat._manager._strings.NewChatMessageNotificationTitle =
    'Nova mensagem de {{lastMessage.authorName}}';
  webChat._manager._strings.NotificationMessage = 'Não foi possível executar a ação: {{message}}';
  webChat._manager._strings.PendingEngagementCancelButton = 'Cancelar';
  webChat._manager._strings.PendingEngagementMessage =
    'Aguarde enquanto conectamos você a um agente disponível';
  webChat._manager._strings.PreEngagementMandatoryFieldMessage = 'Campo obrigatório *';
  webChat._manager._strings.PredefinedChatMessageAuthorName = 'Maria';
  webChat._manager._strings.PredefinedChatMessageBody = 'Olá! Como podemos te ajudar hoje?';
  webChat._manager._strings.QueueStatsBreadCrumb = 'Estatísticas da fila';
  webChat._manager._strings.Read = 'Ler';
  webChat._manager._strings.Reset = 'REDEFINIR';
  webChat._manager._strings.Save = 'SALVE';
  webChat._manager._strings.SendMediaMessageInvalidSize =
    '{{fileName}} é muito grande para enviar. O tamanho máximo do arquivo é {{maxFileSize}}.';
  webChat._manager._strings.SendMediaMessageInvalidType =
    '{{fileName}} não pode ser enviado porque o tipo de arquivo não é compatível. Por favor, tente um arquivo diferente.';
  webChat._manager._strings.SendMessageTooltip = 'Enviar mensagem';
  webChat._manager._strings.Today = 'HOJE';
  webChat._manager._strings.TypingIndicator = '{{name}} está digitando…';
  webChat._manager._strings.TypingIndicatorMultiple = '{{count}} as pessoas estão digitando… ';
  webChat._manager._strings.WelcomeMessage = 'Bem-vindo ao atendimento ao cliente';
  webChat._manager._strings.Yesterday = 'ONTEM';
};

export const createChat = ({
  config,
  firstMessage,
  authorName,
  autostart,
}: createChatProps): void => {
  if (Twilio) {
    Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = {
      body: firstMessage,
      authorName,
      isFromMe: false,
    };

    Twilio.FlexWebChat.createWebChat(config).then((webChat: { init: () => void }) => {
      translate(webChat);
      webChat.init();

      if (autostart === true) {
        Twilio.FlexWebChat.Actions.invokeAction('ToggleChatVisibility');
      }
    });
  }
};
