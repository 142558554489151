import React from 'react';
import styled from 'styled-components/native';
import IconFa from 'react-native-vector-icons/dist/FontAwesome';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { Spacing } from '~/components/Spacing';
import { Card, Typography } from '~/components/@tem-ui';
import { useTheme } from 'styled-components/native';
import { SearchBarTypes } from '../types';
import { useMedia } from '~/components/@hello-ui';
const Wrapper = styled.View`
  flex-direction: row;
`;

const WrapperNeighborhood = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-horizontal: 8px;
`;

const WrapperLocation = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const WrapperSpecialty = styled(WrapperNeighborhood)`
  height: 48px;
  padding-horizontal: 16px;
`;

const WrapperCard = styled.View`
  padding-top: ${({ theme: { isMobile } }) => (isMobile ? '16px' : '20px')};
  margin-bottom: 16px;
`;

export const SearchBar = ({
  district,
  speciality,
  onPress,
  onPressSpeciality,
  onLocalization,
  isVisibleSpecialty,
}: SearchBarTypes): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();
  return (
    <>
      <Wrapper>
        <Card width={media.isMobile ? 244 : 544} height={48}>
          <WrapperNeighborhood onPress={onPress}>
            <Icon name="room" size={26} color={theme?.colors?.primary} />
            <Typography variant="subtitle1" color="black70" style={{ marginLeft: 8 }} bold>
              {district ?? 'Localização'}
            </Typography>
          </WrapperNeighborhood>
        </Card>
        <Spacing left={8}>
          <Card width={48} height={48}>
            <WrapperLocation onPress={onLocalization}>
              <Icon name="gps-fixed" size={26} color={theme?.colors?.primary} />
            </WrapperLocation>
          </Card>
        </Spacing>
      </Wrapper>

      <WrapperCard style={{ opacity: district ? 1 : 0.2 }}>
        {isVisibleSpecialty && (
          <Card width={media.isMobile ? 300 : 600} height={48}>
            <WrapperSpecialty
              activeOpacity={!district ? 1 : 0.2}
              onPress={() => (!district ? null : onPressSpeciality())}>
              <IconFa name="stethoscope" size={26} color={theme?.colors?.primary} />
              <Typography variant="subtitle1" color="black70" style={{ marginLeft: 8 }} bold>
                {speciality ?? 'Especialidade'}
              </Typography>
            </WrapperSpecialty>
          </Card>
        )}
      </WrapperCard>
    </>
  );
};
