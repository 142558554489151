import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Medicaments = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 14} height={height ?? 14} viewBox="0 0 14 14" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M12.7713 6.8855L7.10762 1.22177C6.55229 0.638739 5.80268 0.333374 4.99757 0.333374C4.19247 0.333374 3.44286 0.638739 2.85983 1.22177C2.27679 1.7771 1.97133 2.55441 1.97133 3.35961C1.97133 4.16472 2.2767 4.91433 2.85973 5.49736L3.88701 6.52464C1.83255 6.77451 0.333374 7.7184 0.333374 8.88446V11.2444C0.333374 12.6048 2.38783 13.6598 5.02546 13.6598C7.2465 13.6598 9.07884 12.8824 9.57858 11.8274C9.91174 11.9662 10.2727 12.0218 10.6336 12.0218C11.411 12.0218 12.1883 11.7164 12.7713 11.1334C13.9652 9.96723 13.9651 8.05156 12.7713 6.8855ZM3.24843 5.08091C2.77649 4.60897 2.52662 3.99814 2.52662 3.33182C2.52662 2.6655 2.77649 2.05467 3.24843 1.58273C3.72038 1.13848 4.3312 0.88861 4.99753 0.88861C5.66385 0.88861 6.27467 1.13848 6.74662 1.61043L9.38406 4.24787L6.96865 6.66328C6.38561 6.5245 5.74709 6.46891 5.05297 6.46891H4.63652L3.24843 5.08091ZM7.49623 10.3836L3.24843 7.19101C3.80367 7.07992 4.3867 6.99663 5.02532 6.99663C7.27415 6.99663 9.16198 7.85733 9.16198 8.88451C9.18978 9.46754 8.49571 10.0228 7.49623 10.3836ZM2.58211 7.38519L6.80206 10.5781C6.24683 10.6891 5.6638 10.7724 5.02518 10.7724C2.77644 10.7446 0.888515 9.88394 0.888515 8.88446C0.888515 8.27373 1.55484 7.71835 2.58211 7.38519ZM5.02537 13.1046C2.77654 13.1046 0.888467 12.2439 0.888467 11.2166V10.0228C1.66587 10.7724 3.22059 11.2999 5.02513 11.2999C6.82972 11.2999 8.38458 10.8002 9.16179 10.0228V11.2166C9.18968 12.2439 7.27425 13.1046 5.02537 13.1046ZM12.3825 10.7447C11.6884 11.4388 10.6334 11.6331 9.71722 11.2722V11.2167V8.85681C9.71722 7.96841 8.85652 7.2188 7.55168 6.80235L9.74501 4.60901L12.3825 7.24646C13.3542 8.21814 13.3543 9.80065 12.3825 10.7447Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.24"
        />
      </G>
    </Svg>
  );
};

export default Medicaments;
