import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const WrapperSchedule = styled.View`
  width: 100%;
  max-width: 600px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 0;
    width: 100%;
  `}
`;

export const WrapperDays = styled.View`
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid ${StyledDefaultTheme?.colors?.gray20};
  border-radius: 10px;
  padding: 16px;
`;

export const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 15px auto 0;
`;

export const WrapperClick = styled.TouchableOpacity`
  width: auto;
  height: 24px;
`;

export const WrapperLinkCalendar = styled.TouchableOpacity`
  min-height: 28px;
  width: 70%;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
`;

export const WrapperTerms = styled.View`
  width: 100%;
  height: 292px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.black5};
  border-radius: 10px;
  overflow: auto;
  padding: 16px;
`;

export const WrapperCheckbox = styled.View`
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const block = styled.View<{ show?: boolean }>`
  background-color: ${StyledDefaultTheme?.colors?.black};
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`;

export const Description = styled.View`
  background-color: ${StyledDefaultTheme?.colors?.white};

  bottom: 0;
  margin: 20px;
  ${({ theme: { isMobile } }) => (isMobile ? ' width: 280px' : 'width: 400px')};
  ${({ theme: { isMobile } }) => (isMobile ? ' height: 90px;' : 'height: 90px;')};
`;

export const PrivacyWrapper = styled.View<{ show?: boolean }>`
  background-color: ${StyledDefaultTheme?.colors?.white};
  width: 100%;
  height: 200px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  ${({ show }) =>
    !show &&
    `
    display: none;
  `}

  ${({ theme: { isMobile } }) =>
    Platform.OS === 'web' &&
    isMobile &&
    `
    text-align: center;
  `}
`;

export const Link = styled.TouchableOpacity``;

export const TextLink = styled.Text`
  font-size: 14px;
  text-decoration: underline;
  color: ${StyledDefaultTheme?.colors?.primary};
  text-decoration-color: ${StyledDefaultTheme?.colors?.primary};
`;

export const Text = styled.Text`
  font-size: 14px;
  color: ${StyledDefaultTheme?.colors?.black60};
`;
