import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import * as S from './styles';

interface CardProps {
  variant?: 'default' | 'info' | 'outline' | 'xsmall' | 'small' | 'large' | 'xlarge';
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const Card = ({ variant, children, style }: CardProps): JSX.Element => {
  return (
    <S.WrapperCard variant={variant ?? 'default'} style={style} testID="card-wrapper">
      {children}
    </S.WrapperCard>
  );
};
