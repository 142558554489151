import React, { useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { UserDependentType } from './types';
import { Listing, Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { toCamelCase } from '~/utils/strings';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { IDependent } from '~/@types/auth/dependent';

export const ScheduleAllTime = (): JSX.Element => {
  const theme = useTheme();
  const { user, onixCode: currentProduct, dependents, loadingDependets } = useAuth();

  const { setPatient } = useAppointment();
  const { nextStep } = useStepper();

  const data = useMemo(
    () =>
      [
        user
          ? {
              name: user.name,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              cpf: user.cpf.replaceAll(/\W+/g, ''),
              birthdate: user?.birthdate?.split('T')[0],
              email: user.email,
              sexo: user?.gender?.toUpperCase(),
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
              Telefone: (user?.telephone_1 ?? user.telephone_2).replaceAll(/\W+/g, ''),
              status: 1,
              desc_status: 'ATIVO',
              onix: currentProduct?.onix_code,
              isTitular: true,
            }
          : {},
        ...((dependents as IDependent[])?.map?.((d) => ({ ...d, isTitular: false })) ?? []),
      ].filter(({ status }) => status !== 3),
    [user, dependents, currentProduct],
  );

  return (
    <View className="min-h-full">
      <View className="mb-8">
        <Typography variant="title">Para quem?</Typography>
      </View>
      <View className="mb-24">
        <Typography variant="body2">Selecione para quem é o atendimento</Typography>
      </View>
      <Listing
        useSeparator
        hideBackToTop
        separatorColor={theme.colors.black5}
        loading={loadingDependets}
        data={data}
        containerStyle={{ height: 'auto' }}
        renderItem={({ item }: { item: UserDependentType }) => {
          const { name, isTitular } = item;
          return (
            <TouchableOpacity
              onPress={() => {
                setPatient(item);
                nextStep();
              }}>
              <View className="h-[104px] w-full flex-row items-center justify-between">
                <View>
                  <Typography variant="bodyHighlight2">{toCamelCase(name)}</Typography>
                  <Typography variant="body2">{!isTitular ? 'Dependente' : 'Titular'}</Typography>
                </View>
                <Icon name="chevron-right" size={24} color={theme.colors.primary} />
              </View>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};
