import styled from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';
import { Dimensions } from 'react-native';

export const Container = styled(Wrapper)``;

export const WrapperChat = styled.View`
  width: 100%;
  height: ${Dimensions.get('screen').height - 190}px;
`;

export const KeyboardAvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
`;
