import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { getDimensions } from '~/components/@hello-ui/Theme/utils';

export interface IWindowSizeType {
  height: number;
  width: number;
}

export function useWindowSize(): IWindowSizeType {
  const [windowSize, setWindowSize] = useState({
    height: getDimensions().height,
    width: getDimensions().width,
  });

  function handleResize() {
    const dimensions = getDimensions();

    setWindowSize(dimensions);
  }

  useEffect(() => {
    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  return windowSize;
}
