import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, Typography, Input } from '~/components/@tem-ui';
import * as S from '../styles';
import { useAppointment } from '~/hooks/appointment';
import { ScheduleStepType } from '~/screens/ScheduleAppointment/types';
import { useTheme } from 'styled-components/native';

export const Form = ({ onSelect, type }: ScheduleStepType): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();

  const { appointment, setAppointment, scheduleSubmissionStatus } = useAppointment();

  const [loading] = useState<boolean>(false);
  const [description, setDescription] = useState<string | null>(null);

  useEffect(() => {
    if (appointment && appointment?.description !== null && appointment?.description !== '') {
      if (onSelect) {
        void onSelect();
      }
    }
  }, [appointment]);

  useEffect(() => {
    if (scheduleSubmissionStatus && appointment && appointment.description) {
      navigation.navigate('SurgicalProcedureSuccess');
    }
  }, [scheduleSubmissionStatus, appointment]);

  return (
    <>
      <S.SurgicalProcedureContainer>
        <Typography variant="heading1" color={theme.colors.black}>
          Solicitação de orçamento para cirurgia
        </Typography>

        <S.WrapperText>
          <Typography variant="paragraph" color={theme.colors.black70}>
            Descreva abaixo, de forma simplificada, qual é o procedimento que precisa.
          </Typography>
        </S.WrapperText>

        <Typography variant="heading3" color={theme.colors.black}>
          Descrição do procedimento:
        </Typography>

        <Input
          value={description}
          multiline
          editable={true}
          placeholder="Digite aqui"
          label="Digite aqui"
          variant="light"
          style={{ height: 100 }}
          onChangeText={(text) => setDescription(text)}
        />

        <S.WrapperButton>
          <Button
            disabled={loading || !description}
            variant="primary"
            title={loading ? 'Aguarde...' : 'Próximo'}
            onPress={() => {
              setAppointment({
                ...appointment,
                type,
                description: description ?? '',
              });
            }}
          />
        </S.WrapperButton>
      </S.SurgicalProcedureContainer>
    </>
  );
};
