import styled, { css } from 'styled-components/native';

export const LogoWrapper = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.View`
  height: 60px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;

  ${({ theme }) => theme.tokens.shadows.header};
`;

export const WrapperPage = styled.View`
  width: 100%;
  height: 100%;
`;

export const WrapperContent = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenXl};
    margin-bottom: ${tokens.space.s32};
    margin-top: ${tokens.space.s16};

    ${isMobile &&
    css`
      margin-top: ${tokens.space.s8};
      margin-bottom: ${tokens.space.s24};
    `}
  `};
`;

export const WrapperSchedule = styled.View`
  ${({ theme: { tokens } }) => css`
    max-width: 530px;
    align-items: center;
    justify-content: center;
    width: ${tokens.sizes.full};
    border-radius: ${tokens.borderRadius.full};
  `};
`;
