import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import UpdateProfileDataForm from './components/Form';
import { PageWithCard } from '~/components/@hello-ui';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';

export const UpdateProfileData = (): JSX.Element => {
  const navigation = useNavigation();
  const { params } = useRoute<UseRoute<'UpdateProfileData'>>();
  const { isDeepLink } = DeepLink.useParams();

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Atualizar Dados Pessoais',
        onBack: () => {
          if (params?.returnPage) {
            if (isDeepLink) {
              DeepLink.sendEvent(DeepLinkEvent.Cancel);
            } else {
              navigation.navigate(params.returnPage, { keepState: true });
            }
          } else {
            navigation.navigate('ProfileData');
          }
        },
      }}>
      <UpdateProfileDataForm />
    </PageWithCard>
  );
};
