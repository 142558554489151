import React from 'react';
import Image1 from '../../../../assets/images/foto-01.jpg';
import Image2 from '../../../../assets/images/foto-02.jpg';
import Image3 from '../../../../assets/images/foto-03.jpg';
import Swiper from 'react-native-web-swiper';
import { SlideItem } from './components/SlideItem';
import { useTheme } from 'styled-components/native';
import * as S from './styles';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useNavigation } from '@react-navigation/core';

const slides = (pay_name) => [
  {
    image: Image1,
    title: 'Realize os pagamentos de forma simples pelo seu celular',
    description: `Com ${pay_name}, você paga suas consultas e exames pelo aplicativo de forma rápida e fácil.`,
    text: 'Vai uma recarga?',
  },
  {
    image: Image2,
    title: `Compre créditos e use seu ${pay_name} na rede de saúde como meio de pagamento`,
    description:
      'Parcele em até 12x no cartão de crédito e pague seus agendamentos com mais tranquilidade.',
    text: 'Agora sim, né?',
  },
  {
    image: Image3,
    title: 'Descontos especiais pra te dar aquela mãozinha na hora de pagar',
    description: `Vai pagar com ${pay_name}? Então você tem descontos mais que especiais em nossa rede de saúde.`,
    text: 'Tá bom, me convenceu!',
  },
];

export const Presentation = () => {
  const navigation = useNavigation();
  const theme = useTheme();

  const payName = theme?.pay_name !== '' ? theme?.pay_name : theme?.partner_full_name;

  return (
    <S.Wrapper>
      <S.Header>
        <S.WrapperBackButton
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('TemPayDashboard');
            }
          }}>
          <Icon name="arrow-left" size={26} color={theme?.colors.white} />
        </S.WrapperBackButton>
      </S.Header>
      <S.SwiperView>
        <Swiper
          from={1}
          loop
          minDistanceForAction={0.1}
          controlsProps={{
            dotsTouchable: true,
            dotsPos: 'center',
            prevPos: 'center',
            nextPos: 'center',
            dotsWrapperStyle: { marginTop: 64 },
            dotActiveStyle: { backgroundColor: theme?.colors?.primary },
            NextComponent: ({ onPress }: any) => (
              <S.ButtonSwiper onPress={onPress} left>
                <Icon name="chevron-right" size={28} color={theme?.colors?.primary} />
              </S.ButtonSwiper>
            ),
            PrevComponent: ({ onPress }: any) => (
              <S.ButtonSwiper onPress={onPress}>
                <Icon name="chevron-left" size={28} color={theme?.colors?.primary} />
              </S.ButtonSwiper>
            ),
          }}>
          {slides(payName).map((slide) => (
            <SlideItem
              image={slide.image}
              title={slide.title}
              description={slide.description}
              text={slide.text}
              key={slide.title}
            />
          ))}
        </Swiper>
      </S.SwiperView>
    </S.Wrapper>
  );
};
