import React, { useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { checkBmgTelemedicine } from '../ScheduleAppointment/components/ScheduleType';
import { ScheduleDate } from '../ScheduleAppointment/components/ScheduleDate';
import { SelectDependents } from '../ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleReview } from '../ScheduleAppointment/components/ScheduleReview';
import { ScheduleSuccess } from '../ScheduleSuccess';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useAuth } from '~/auth/legacy/useAuth';
import { trackingEventType } from '~/contexts/tracking/types';
import VideoIcon from '~/components/Icons/Video';
import { Stepper, useSweetAlert } from '~/components/@hello-ui';
import { TypesEnum } from '~/enums/appointment';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useUploader } from '~/components/@tem-ui/Uploader';

export const VideoAppointmentsSchedule = (): JSX.Element => {
  const { checkIsEnabledFeature, onixCode } = useAuth();
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { currentStep, stepper, finalizeSteps, setCurrentStepState } = useStepper();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments } = useAppointment();
  const { clearUploader } = useUploader();

  const cards = useMemo(
    () =>
      [
        {
          icon: <VideoIcon />,
          title: 'Consulta por vídeo',
          customTitle: 'Agendar consulta por vídeo',
          description: 'Telemedicina com especialidades médicas',
          disabled: !checkIsEnabledFeature('telemedicine'),
          visible: checkIsEnabledFeature('telemedicine'),
          visibilitySteps: false,
          tracking: trackingEventType.ClickedOnVideoAppointment,
          steps: checkBmgTelemedicine(onixCode?.onix_code),
          iconSideOfTitle: true,
        },
        {
          icon: <VideoIcon />,
          title: 'Psicologia por Vídeo',
          customTitle: 'Solicitação terapia por vídeo',
          description: 'Telemedicina com psicólogos',
          disabled: !checkIsEnabledFeature('psicologia_online'),
          visible: checkIsEnabledFeature('psicologia_online'),
          visibilitySteps: false,
          tracking: trackingEventType.ClickedOnPsychologicalVideoTherapy,
          steps: [
            <ScheduleDate key="ScheduleDate" />,
            <SelectDependents key="SelectDependent" />,
            <ScheduleReview
              key="ScheduleReview"
              scheduleType={TypesEnum.VideoPsychology}
              trackingType={trackingEventType.RequestedInPsychologicalVideoTherapy}
              specialtyName="psicologia"
            />,
            <ScheduleSuccess key="presentionSuccess" />,
          ],
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  const showCancelMessage = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              clearUploader();
              finalizeSteps();
              setCurrentStepState(0);
              clearAppointments();

              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Consultas por Vídeo Agendadas',
        onBack: () => {
          if (currentStep >= 0 && stepper !== null) {
            showCancelMessage();
          } else {
            finalizeSteps();
            navigation.navigate('Dashboard');
          }
        },
      }}>
      <View className="min-h-full">
        <Stepper items={cards} validateDependentFeatures />
      </View>
    </PageWithCard>
  );
};
