import React from 'react';
import { BottomSheet as LibBottomSheet } from 'react-spring-bottom-sheet';
import { View } from 'react-native';
import DialogHeader from '../DialogHeader/DialogHeader';
import { CloseButton } from '../CloseButton';
import * as S from './style';
import { DialogContent } from '~/components/@hello-ui/Dialog/components/DialogContent/DialogContent';
import { DialogComponentProps } from '~/components/@hello-ui';

export const BottomSheet: React.FC<DialogComponentProps> = ({
  visible,
  title,
  subtitle,
  onClose,
  children,
}) => {
  return (
    <View>
      <LibBottomSheet open={visible ?? false} onDismiss={onClose}>
        <CloseButton onClose={onClose} />
        <S.WrapperDialog>
          <DialogHeader title={title} subtitle={subtitle} onClose={onClose} />
          <DialogContent>{children}</DialogContent>
        </S.WrapperDialog>
      </LibBottomSheet>
    </View>
  );
};
