import styled from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';
import { StyledDefaultTheme } from '~/components/@hello-ui';
export const Container = styled(Wrapper)``;

export const WrapperTop = styled.View`
  width: 100%;
  max-width: 600px;
  margin-bottom: 48px;
`;

export const WrapperSearch = styled.View`
  width: 100%;
  max-width: 600px;
  height: 56px;
  margin-bottom: 48px;
`;

export const WrapperSchedule = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 16px;
    width: 100%;
    margin-left: 1px;
  `}
`;

export const WrapperScheduleForm = styled.View`
  flex-direction: column;
`;

export const WrapperInput = styled.View`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;

export const InputField = styled.TouchableOpacity`
  height: 58px;
  top: 22px;
  margin-bottom: -58px;
`;

export const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 24px auto 0;
`;

export const WrapperCard = styled.View`
  padding-bottom: 16px;
  padding-right: 10px;
  margin-left: 8px;
  margin-top: 8px;
`;

export const WrapperContentCard = styled.View`
  height: 190px;
  margin: 15px;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    height: 200px;
  `}
`;

export const WrapperCardIcon = styled.View`
  min-width: 100px;
  width: 20%;
  height: 300px;
  position: absolute;
`;

export const WrapperBackgroundIcon = styled.View`
  background: #eeeeee;
  width: 60px;
  height: 60px;
  padding: 16px 10px 12px 15px;
  border-bottom-left-radius: 50;
  border-bottom-right-radius: 50;
  border-top-left-radius: 50;
  border-top-right-radius: 50;
`;

export const WrapperCardText = styled.View`
  position: absolute;
  width: 80%;
  padding-left: 75px;
`;

export const WrapperTitleCard = styled.Text`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.black};
`;

export const WrapperSubTitleCard = styled.Text`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperList = styled.FlatList`
  width: 600px;
  padding: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;
  `}
`;

export const WrapperItem = styled.View`
  align-items: center;
  padding-horizontal: 12px;
  width: 100%;
  padding: 15px 0 15px 0;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black16};
  width: 100%;
  height: 0.5px;
`;

export const WrapperLoading = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.black,
})``;

export const WrapperText = styled.Text`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperTitle = styled.Text`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 20px 0 15px 0;
`;

export const WrapperItemTitle = styled.Text`
  width: 100%;
  font-weight: 600;
  padding: 0 10px 5px 10px;
  font-size: 14px;
  line-height: 24px;
`;

export const WrapperItemSubTitle = styled.Text`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.black70};
  padding: 0 10px 0 10px;
`;

export const WrapperItemAddress = styled.Text`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.black70};
  padding: 0 10px 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const WrapperControl = styled.View`
  width: 100%;
`;

export const WrapperControlLeftList = styled.View`
  position: absolute;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
`;

export const WrapperControlCenter = styled.View`
  flex-direction: column;
  align-self: center;
  flex: 2;
  margin-top: 30px;
  position: absolute;
`;

export const WrapperControlRightList = styled.View`
  position: absolute;
  flex-direction: column;
  align-self: flex-end;
  flex: 3;
`;

export const ControlListFirst = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  left: 0px;
`;

export const ControlListPrevious = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  left: 50px;
`;

export const ControlListNext = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  right: 50px;
`;

export const ControlListLast = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  right: 0px;
`;

export const Text = styled.Text``;

export const WrapperClinicsList = styled.View`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
`;
