import React from 'react';

import { Linking, Platform, TouchableWithoutFeedback, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsDigitalWellBeing/desktop.png';
import mobileBackground from '../../assets/images/benefitsDigitalWellBeing/mobile.png';
import AndroidBadge from '../../assets/images/AndroidBadge.png';
import iPhoneBadge from '../../assets/images/iPhoneBadge.png';
import * as S from './styles';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import Wellbeing from '~/components/Icons/Wellbeing';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsDigitalWellBeing = (): JSX.Element => {
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <Wellbeing color="white" />,
        title: 'Bem-Estar Digital',
        onBack: () => {
          navigation.navigate('BenefitsWellBeing');
        },
      }}>
      <View>
        <Typography variant="body2">
          Conheça o aplicativo mais <Typography variant="bodyHighlight2">completo</Typography> de{' '}
          <Typography variant="bodyHighlight2">saúde</Typography> e{' '}
          <Typography variant="bodyHighlight2">bem-estar</Typography>. Acompanhe sua pressão
          arterial, frequência cardíaca, nível de oxigenação e estresse utilizando a câmera do seu
          celular. Acesse <Typography variant="bodyHighlight2">mais de 5 mil conteúdos</Typography>{' '}
          e <Typography variant="bodyHighlight2">cursos </Typography>
          sobre saúde, alimentação, meditação, yoga e muito mais. E ainda tenha uma rede de
          academias com pagamento por utilização.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">Conheça as funcionalidades disponíveis no App:</Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2" className="mb-8 pl-8">
              • VitalScan: monitora seus sinais vitais e nível de estresse em tempo real usando a
              câmera do seu celular.
            </Typography>
            <Typography variant="body2" className="mb-8 pl-8">
              • Manu ChatGPT: uma assistente digital especializada em saúde física, mental e
              nutricional.
            </Typography>
            <Typography variant="body2" className="mb-8 pl-8">
              • Aulas e Cursos: mais de 5.000 conteúdos em formato vídeo.
            </Typography>
            <Typography variant="body2" className="pl-8">
              • NamuPass: rede de academias com pagamento por uso, com diárias a partir de R$ 4,90.
            </Typography>
          </View>
        </Collapse>
      </View>
      <Collapse title="Como acessar?">
        <View className="mb-8">
          <View className="mb-8 flex-row pl-8">
            <Typography variant="body2">1.</Typography>
            <Typography variant="body2" className="ml-8 flex-1">
              Baixe o <Typography variant="bodyHighlight2">app NAMU</Typography> e clique no botão{' '}
              <Typography variant="bodyHighlight2">"Quero um perfil pessoal"</Typography>;
            </Typography>
          </View>
          <View className="mb-8 flex-row pl-8">
            <Typography variant="body2">2.</Typography>
            <Typography variant="body2" className="ml-8 flex-1">
              Crie sua <Typography variant="bodyHighlight2">senha de acesso</Typography> para sua
              conta NAMU;
            </Typography>
          </View>
          <View className="mb-8 flex-row pl-8">
            <Typography variant="body2">3.</Typography>
            <Typography variant="body2" className="ml-8 flex-1">
              <Typography variant="bodyHighlight2">Finalize o seu cadastro</Typography> com mais
              algumas informações pessoais;
            </Typography>
          </View>
          <View className="mb-8 flex-row pl-8">
            <Typography variant="body2">4.</Typography>
            <Typography variant="body2" className="ml-8 flex-1">
              Preencha a <Typography variant="bodyHighlight2">ficha de anamnese</Typography> para
              atendimento personalizado;
            </Typography>
          </View>
          <View className="mb-8 flex-row pl-8">
            <Typography variant="body2">5.</Typography>
            <Typography variant="body2" className="ml-8 flex-1">
              <Typography variant="bodyHighlight2">Pronto!</Typography> Agora é só começar a sua
              jornada de bem-estar.
            </Typography>
          </View>
        </View>
      </Collapse>
      <MobileAutoSpace />
      <View className="mt-24 w-full items-center self-center text-center">
        <>
          <Typography variant="bodyHighlight2" className="mb-24 mobile:mb-16">
            Para utilizar o benefício, baixe em seu celular:
          </Typography>
          <S.WrapperImageAuthorizer>
            <TouchableWithoutFeedback
              onPress={() =>
                Linking.openURL(
                  'https://play.google.com/store/apps/details?id=com.namu&hl=en_US&gl=US',
                )
              }>
              <S.ImageAuthorizer source={AndroidBadge} flex={10} marginRight />
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback
              onPress={() =>
                Linking.openURL('https://apps.apple.com/br/app/namu-wellness/id1544610300')
              }>
              <S.ImageAuthorizer source={iPhoneBadge} flex={9} marginLeft />
            </TouchableWithoutFeedback>
          </S.WrapperImageAuthorizer>
        </>
      </View>
    </PageWithCard>
  );
};
