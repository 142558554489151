import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

const Surgery = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M6.95999 9.79998V1.44C6.95999 1.19722 6.76278 1 6.51999 1C4.8217 1 3.44 2.3817 3.44 4.07999V8.47998C3.44 8.72277 3.63721 8.91998 3.88 8.91998C4.12239 8.91998 4.32 9.11759 4.32 9.35998V9.79998H3.44C3.19722 9.79998 3 9.99719 3 10.24V20.8C3 22.0131 3.98685 22.9999 5.19999 22.9999C6.41313 22.9999 7.39999 22.0131 7.39999 20.8V10.24C7.39999 9.99719 7.20277 9.79998 6.95999 9.79998ZM4.32 8.11521V4.07999C4.32 3.01731 5.07725 2.12866 6.07999 1.9244V9.80013H5.19999V9.36013C5.19999 8.78637 4.83209 8.29704 4.32 8.11536V8.11521ZM6.51999 10.68V11.56H3.88V10.68H6.51999ZM5.19999 22.12C4.47221 22.12 3.88 21.5277 3.88 20.8V12.44H6.51999V20.8C6.51999 21.5277 5.92778 22.12 5.19999 22.12Z"
        fill={theme.colors.primary}
      />
      <Path
        d="M14.08 18.6C13.3522 18.6 12.76 19.1922 12.76 19.92C12.76 20.6478 13.3522 21.24 14.08 21.24C14.8078 21.24 15.4 20.6478 15.4 19.92C15.4 19.1922 14.8078 18.6 14.08 18.6ZM14.08 20.36C13.8376 20.36 13.64 20.1624 13.64 19.92C13.64 19.6776 13.8376 19.48 14.08 19.48C14.3224 19.48 14.52 19.6776 14.52 19.92C14.52 20.1624 14.3224 20.36 14.08 20.36Z"
        fill={theme.colors.primary}
      />
      <Path
        d="M19.3599 18.6C18.6322 18.6 18.0399 19.1922 18.0399 19.92C18.0399 20.6478 18.6322 21.24 19.3599 21.24C20.0877 21.24 20.6799 20.6478 20.6799 19.92C20.6799 19.1922 20.0877 18.6 19.3599 18.6ZM19.3599 20.36C19.1176 20.36 18.9199 20.1624 18.9199 19.92C18.9199 19.6776 19.1176 19.48 19.3599 19.48C19.6023 19.48 19.7999 19.6776 19.7999 19.92C19.7999 20.1624 19.6023 20.36 19.3599 20.36Z"
        fill={theme.colors.primary}
      />
      <Path
        d="M19.8 16.8752V7.72518C19.8 5.25727 18.8386 2.93664 17.0936 1.19158L17.0311 1.12911C16.9907 1.08865 16.9418 1.05643 16.8881 1.03404C16.7803 0.98965 16.6593 0.98965 16.552 1.03404C16.4984 1.05643 16.4495 1.08865 16.409 1.12911L16.3466 1.19158C14.6013 2.93664 13.64 5.25727 13.64 7.72518V16.8752C12.1497 17.0898 11 18.3715 11 19.92C11 21.6183 12.3817 23 14.08 23C15.2012 23 16.181 22.3958 16.72 21.4983C17.259 22.3958 18.2388 23 19.36 23C21.0583 23 22.44 21.6183 22.44 19.92C22.44 18.3715 21.2903 17.0899 19.8 16.8752ZM17.16 2.59806C18.0892 3.78958 18.667 5.19758 18.85 6.6975C18.3965 6.2628 17.8107 5.96484 17.16 5.8711V2.59806ZM14.08 22.1199C12.8668 22.1199 11.88 21.1331 11.88 19.9199C11.88 18.7068 12.8668 17.7199 14.08 17.7199C14.3228 17.7199 14.52 17.5227 14.52 17.2799V11.0717C14.9824 11.5443 15.5948 11.8663 16.28 11.9653V19.9199C16.28 21.1331 15.2931 22.1199 14.08 22.1199ZM19.36 22.1199C18.1468 22.1199 17.16 21.1331 17.16 19.9199V11.56C17.16 11.3172 16.9628 11.12 16.72 11.12C15.5068 11.12 14.52 10.1331 14.52 8.91997V7.72488C14.52 5.84309 15.1409 4.05876 16.28 2.59827V6.28013C16.28 6.52291 16.4772 6.72013 16.72 6.72013C17.9331 6.72013 18.92 7.70698 18.92 8.92012V17.2801C18.92 17.5229 19.1172 17.7201 19.36 17.7201C20.5731 17.7201 21.56 18.707 21.56 19.9201C21.56 21.1332 20.5731 22.1199 19.36 22.1199Z"
        fill={theme.colors.primary}
      />
      <Path
        d="M16.72 7.82024C16.1136 7.82024 15.62 8.31386 15.62 8.92023C15.62 9.52661 16.1136 10.0202 16.72 10.0202C17.3264 10.0202 17.82 9.52661 17.82 8.92023C17.82 8.31386 17.3264 7.82024 16.72 7.82024ZM16.72 9.14023C16.5986 9.14023 16.5 9.04163 16.5 8.92023C16.5 8.79884 16.5986 8.70023 16.72 8.70023C16.8414 8.70023 16.94 8.79884 16.94 8.92023C16.94 9.04163 16.8414 9.14023 16.72 9.14023Z"
        fill={theme.colors.primary}
      />
    </Svg>
  );
};

export default Surgery;
