import React, { forwardRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { TextInput, View } from 'react-native';
import { SimpleInputProps } from '~/components/@hello-ui/Input/components/SimpleInput/types';
import { WrapperInput } from '~/components/@hello-ui/Input/components/SimpleInput/styles';
import { Icon, InputIcon } from '~/components/@hello-ui/Input/components/InputIcon';
import { Loader } from '~/components/@hello-ui';

export const SimpleInputComponent: React.ForwardRefRenderFunction<TextInput, SimpleInputProps> = (
  props,
  ref,
) => {
  const { isPassword, icon, isDisabled, passwordIconColor, isLoading, style, ...inputProps } =
    props;
  const theme = useTheme();

  const [hidePassword, setHidePassword] = useState(isPassword);

  const toggleHidePassword = () => {
    setHidePassword((hidePassword) => !hidePassword);
  };

  const getIcon = () => {
    if (isLoading) {
      return (
        <View className="h-24, w-24">
          <Loader size={24} variant="circle-loader" />
        </View>
      );
    }

    if (isPassword) {
      return (
        <InputIcon
          icon={{
            item: hidePassword ? 'eye-off' : 'eye',
            position: 'right',
            color: passwordIconColor ?? theme.colors.primary,
          }}
          onPress={toggleHidePassword}
        />
      );
    }

    return icon && <InputIcon icon={icon} />;
  };

  return (
    <>
      <WrapperInput
        {...inputProps}
        style={Array.isArray(style) ? style : [style]}
        ref={ref}
        editable={!isDisabled}
        secureTextEntry={hidePassword}
        iconPosition={isPassword ? 'right' : (icon as Icon)?.position}
      />
      {getIcon()}
    </>
  );
};

export const SimpleInput = forwardRef(SimpleInputComponent);
