/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import * as S from './styles';
import { useMedia } from '~/components/@hello-ui';
import { Card, CardHeader } from '~/components/@tem-ui';
import { useNavigation } from '@react-navigation/native';
import { Typography } from '~/components/@tem-ui';
import WarningIcon from '~/assets/images/warning.svg';
import MaterialCommunityIcons from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { Linking } from 'react-native';
import { Spacing } from '~/components/Spacing';

export const SignatureCancel = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();

  const openWhatsApp = () => {
    void Linking.openURL('https://api.whatsapp.com/send?phone=5511978490071&text=Quero cancelar');
  };

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card height={!media.isMobile ? 769 : 'auto'} disableShadow={media.isMobile}>
          <CardHeader
            title=""
            titleElement={
              <Typography variant="heading2" color="black">
                Cancelamento
              </Typography>
            }
            onBackPress={() => navigation.navigate('Signature')}
            noBorderBottom={false}
          />
          <S.WrapperContainer>
            <S.WrapperTitle>
              <S.Title>Ahhh, vamos sentir sua falta :(</S.Title>
              <S.Icon>
                <WarningIcon />
              </S.Icon>
            </S.WrapperTitle>
            <S.WrapperDescription>
              <S.DescriptionText>
                <MaterialCommunityIcons name="checkbox-blank-circle" size={9} color="#19759B" /> As
                cobranças das parcelas vão se encerrar no momento do cancelamento.
              </S.DescriptionText>
              <S.DescriptionText>
                <MaterialCommunityIcons name="checkbox-blank-circle" size={9} color="#19759B" />{' '}
                Você não terá mais acesso aos benefícios do seu plano.
              </S.DescriptionText>
              <S.WrapperRectangle>
                <Typography variant="body2" color="black60">
                  <S.RectangleTitle>
                    Para cancelar, entre em contato em nosso WhatsApp{' '}
                    <Typography variant="body2" color="primary" onPress={() => openWhatsApp()}>
                      (11) 97849-0071
                    </Typography>{' '}
                    e digite "Quero cancelar".
                  </S.RectangleTitle>
                </Typography>

                <Spacing top={20} bottom={20}>
                  <Typography variant="body2" color="gray70">
                    Entraremos em contato em até 48hrs!
                  </Typography>
                </Spacing>
              </S.WrapperRectangle>
            </S.WrapperDescription>
          </S.WrapperContainer>
        </Card>
        <Footer />
      </S.Container>
    </>
  );
};
