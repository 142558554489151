import React, { useEffect, useState, createContext, useContext } from 'react';
import { DialogProviderTypes, DialogContextTypes } from './types';

const DialogContext = createContext<DialogContextTypes | null>(null);

export const DialogProvider = ({ children }: DialogProviderTypes): JSX.Element => {
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<JSX.Element>(<></>);

  const closeDialog = () => setDialogState(false);

  const openDialog = (title: string | null, component: JSX.Element) => {
    title !== null && setDialogTitle(title);
    setDialogContent(component);
    setDialogState(true);
  };

  useEffect(() => {
    if (!dialogState) {
      setTimeout(() => {
        setDialogTitle('');
        setDialogContent(<></>);
      }, 200);
    }
  }, [dialogState]);

  return (
    <DialogContext.Provider
      value={{
        dialogState,
        dialogTitle,
        dialogContent,
        closeDialog,
        openDialog,
      }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextTypes => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog must be used within an DialogProvider');
  }

  return context;
};
