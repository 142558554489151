import React, { useEffect } from 'react';

import { useRoute } from '@react-navigation/native';
import { AuthService } from '~/auth/auth-service';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import { UseRoute } from '~/@types/navigation/routes-helpers';

export const MagicLink: React.FC = () => {
  const route = useRoute<UseRoute<'MagicLink'>>();

  useEffect(() => {
    void AuthService.authenticate(EAuthStrategy.MagicLink, {
      token: route.params?.tokenzeus as string,
      refreshToken: route.params?.refreshtoken as string,
    });
  }, []);

  return <></>;
};
