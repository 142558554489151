import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled.View`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Platform.OS === 'web' && 'overflow: auto;'}
`;
