import { useAuthStore } from '~/store/auth.store';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';

/**
 * Validates if the user has chosen a product. If not redirect to Products
 */
export class ProductGatekeeper implements IGatekeeper {
  async canPass() {
    // If it has more than one product and doesn't have selected one, show this screen
    return useAuthStore.getState().selectedProduct !== null;
  }

  getGatekeeperRoutes(): Routes[] {
    return ['Products', 'Loading'];
  }
}
