import React from 'react';
import { Linking, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { useTheme } from 'styled-components/native';
import { Logo } from '../../../Logo';
import * as S from '../styles';

export const SimpleFooter = (): JSX.Element => {
  const theme = useTheme();

  return (
    <S.Wrapper testID="simple-footer">
      <S.WrapperColumn variant="simple-footer">
        <S.Logo>
          <Logo height={56} width={120} />
        </S.Logo>
        <S.WrapperSocialButtons>
          {theme?.social_links &&
            typeof theme?.social_links !== 'string' &&
            theme?.social_links &&
            Object.keys(theme?.social_links).map((socialName, idx) => (
              <S.ButtonSocial
                testID={`button-${socialName}`}
                onPress={() => Linking.openURL(theme?.social_links[socialName])}
                key={idx}>
                <Icon
                  name={socialName}
                  color={theme?.colors.primary}
                  size={Platform.OS === 'web' ? 18 : 16}
                />
              </S.ButtonSocial>
            ))}
        </S.WrapperSocialButtons>
      </S.WrapperColumn>
    </S.Wrapper>
  );
};
