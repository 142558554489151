import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import {
  TWILIO_AUTHOR_NAME,
  TWILIO_AUTHOR_IMAGE,
  TWILIO_ACCOUNT_SID,
  TWILIO_PHONE_NOT_FOUND_FLOW_ID,
} from '@env';
import { Panel } from '~/components/@tem-ui';
import * as S from '~/components/@tem-ui/WebChat/styles';
import { useSweetAlert } from '~/components/@tem-ui';
import { WebChat } from '~/components/@tem-ui/WebChat/WebChat';
import { useAuth } from '~/auth/legacy/useAuth';

export const PhoneNotFoundWebChat = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();

  const { user } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  useEffect(() => {
    if (!user) {
      navigation.navigate('Login');
    }
  }, [user]);

  return (
    <S.Container>
      <Panel
        title="Chat de atendimento"
        onBack={() => {
          showSweetAlert(
            'Você tem certeza?',
            'A conversa será encerrada e caso deseje você terá que começar de novo.',
            'warning',
            false,
            false,
            {
              color: 'warning',
              buttons: [
                {
                  text: 'Sair do chat',
                  color: theme.colors.black60,
                  background: 'secondary',
                  callback: async () => {
                    hideSweetAlert();
                    navigation.navigate('Login');
                  },
                },
                {
                  text: 'Continuar',
                  color: 'white',
                  background: 'primary',
                  callback: () => {
                    hideSweetAlert();
                  },
                },
              ],
            },
          );
        }}>
        <S.WrapperChat>
          <WebChat
            authorName={TWILIO_AUTHOR_NAME}
            authorImage={TWILIO_AUTHOR_IMAGE}
            accountSid={TWILIO_ACCOUNT_SID}
            flowID={TWILIO_PHONE_NOT_FOUND_FLOW_ID}
            friendlyName="Eu"
            firstMessage="Ola! Parece que está faltando o Número de celular no seu cadastro.\n\nInforme seu nome completo por favor."
          />
        </S.WrapperChat>
      </Panel>
    </S.Container>
  );
};
