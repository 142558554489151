import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const CardIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Rect
        width={20.15}
        height={15.112}
        x={2}
        y={4}
        stroke={color ?? theme.colors.primary}
        strokeWidth={1.3}
        rx={2}
      />
      <Path
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        d="M2.839 12.395h18.47v2.519H2.839z"
      />
    </Svg>
  );
};

export default CardIcon;
