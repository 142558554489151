import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

interface PropsBar {
  duration?: number;
  color: string;
}

const Container = styled.View`
  width: 100%;
  height: 2px;
  background: #eaeaea;
  margin-top: 30px;
`;

const Bar = styled(Animated.View)`
  height: 100%;
`;

export const ProgressBar = ({ duration = 3000, color }: PropsBar): JSX.Element => {
  const progressAnim = useRef(new Animated.Value(0)).current;
  const style = {
    width: progressAnim.interpolate({ inputRange: [0, 100], outputRange: ['0%', '100%'] }),
    backgroundColor: color,
  };

  const progressToEnd = () => {
    Animated.timing(progressAnim, {
      toValue: 100,
      duration,
    }).start();
  };

  useEffect(() => {
    progressToEnd();
  }, []);

  return (
    <Container>
      <Bar style={style} />
    </Container>
  );
};
