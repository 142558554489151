import React from 'react';
import { Svg, Path, G, Defs, Rect, ClipPath } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const VideoIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24" fill="none">
      <Path
        d="M17.4736 18.0457H21.0961C21.4399 18.0457 21.7308 17.7548 21.7308 17.411V4.9039C21.7308 4.5601 21.4399 4.26924 21.0961 4.26924H2.90397C2.56017 4.26924 2.26931 4.56012 2.26931 4.9039V17.4374C2.26931 17.7813 2.56019 18.0721 2.90397 18.0721H6.55284C11.5296 18.0455 8.30954 18.0721 10.1603 18.0721C12.011 18.0721 12.3561 18.0457 17.4736 18.0457ZM23 4.90395V17.4375C23 18.4687 22.1538 19.3413 21.0962 19.3413H17.1563L13 19.3411V21.2714H17.1826C17.3148 21.2714 17.4205 21.3771 17.4205 21.5093V21.8795C17.4205 22.0117 17.3148 22.1174 17.1826 22.1174L6.8173 22.1176C6.68506 22.1176 6.57937 22.0119 6.57937 21.8797V21.4829C6.57937 21.3507 6.68506 21.245 6.8173 21.245H11V19.3148H2.90383C1.87259 19.3148 1 18.4685 1 17.4109V4.90383C1 3.84624 1.84624 3 2.90383 3H21.1224C22.1537 3 23 3.84617 23 4.90395Z"
        fill={color ?? theme.colors.primary}
      />
      <G clipPath="url(#clip0_998_2528)">
        <Path
          d="M17.8333 17.8333V16.4722C17.8333 15.7502 17.526 15.0578 16.979 14.5473C16.432 14.0368 15.6902 13.75 14.9166 13.75H9.08329C8.30974 13.75 7.56788 14.0368 7.0209 14.5473C6.47392 15.0578 6.16663 15.7502 6.16663 16.4722V17.8333"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M11.9999 12C13.6107 12 14.9166 10.6942 14.9166 9.08332C14.9166 7.47249 13.6107 6.16666 11.9999 6.16666C10.3891 6.16666 9.08325 7.47249 9.08325 9.08332C9.08325 10.6942 10.3891 12 11.9999 12Z"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_998_2528">
          <Rect width="14" height="14" fill="white" transform="translate(5 5)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default VideoIcon;
