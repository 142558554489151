import React from 'react';
import { useTheme } from 'styled-components/native';
import { FlatList, ScrollView, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Typography } from '../../Typography';

interface ToTopButtonProps {
  isScroll?: boolean;
  scrollRef: React.MutableRefObject<FlatList | ScrollView | null>;
}

export const ToTopButton = ({ isScroll = false, scrollRef }: ToTopButtonProps): JSX.Element => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity
      onPress={() => {
        if (isScroll) {
          scrollRef.current.scrollTo({ y: 0, animated: true });
          return;
        }

        scrollRef.current?.scrollToOffset({ animated: true, offset: 0 });
      }}
      className={`mb-12 ${
        isScroll ? '' : 'mt-40'
      } w-full flex-row items-center justify-center mobile:mb-24`}>
      <View className="mr-8">
        <Icon name="arrow-up" size={24} color={colors.primary} />
      </View>
      <Typography variant="body1">Voltar para o topo</Typography>
    </TouchableOpacity>
  );
};
