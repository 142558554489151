import React from 'react';
import { Image, Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { SvgCssUri } from 'react-native-svg';

type Props = {
  height?: number;
  width?: number;
};

export const Logo = (props: Props): JSX.Element => {
  const theme = useTheme();

  const isDesktopOrSvgFile = Platform.OS === 'web' || !theme?.logo.includes('.svg');

  const element = isDesktopOrSvgFile ? (
    <Image source={{ uri: theme?.logo }} style={{ ...props }} resizeMode="contain" />
  ) : (
    <SvgCssUri uri={theme?.logo} {...props} />
  );

  return element;
};
