import { css } from 'styled-components';
import styled from 'styled-components/native';

export const Wrapper = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${({ theme: { isMobile } }) => isMobile ? `
    max-width: 242px;
  ` : `
    max-width: 317px;
  `}
`;

export const WrapperTexts = styled.View`
  width: ${({ theme: { isMobile }}) => isMobile ? '242px' : '315px'};
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 33px;
  text-align: center;
`;

export const WrapperButton = styled.View`
  width: 100%;
  padding-left: 16px;
  justify-content: center;
  align-items: center;
`;

export const WrapperIcon = styled.View`
  position: absolute;
  top: ${({ theme: { isMobile } }) => (isMobile ? '18px' : '42px')};
  right: 0;

  ${({ theme: { isMobile } }) => isMobile && css`
    width: 144px;
    height: 188px;
  `}

  ${({ theme: { isTablet } }) => isTablet && css`
    width: 164px;
    height: 216px;
  `}

  ${({ theme: { isDesktop } }) => isDesktop && css`
    width: 328px;
    height: 431px;
  `}
`;

export const WrapperFooter = styled.View`
  width: 100%;
  height: 56px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.black8};
`;
