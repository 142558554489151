import React from 'react';
import { Dimensions, Platform } from 'react-native';
import WebView from 'react-native-webview';
import * as S from './styles';

interface webChatConfig {
  accountSid: string;
  flowID: string;
  friendlyName: string;
  welcomeMessage?: string;
  firstMessage?: string;
  authorName: string;
  authorImage: string;
}

export const WebChat = ({
  flowID,
  friendlyName,
  firstMessage,
  welcomeMessage,
  authorName,
  accountSid,
  authorImage,
}: webChatConfig): JSX.Element => {
  const html = `
<!DOCTYPE html>
<html lang="pt_BR">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0">
  <script src="https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js"
    integrity="sha512-yBmOHVWuWT6HOjfgPYkFe70bboby/BTj9TGHXTlEatWnYkW5fFezXqW9ZgNtuRUqHWrzNXVsqu6cKm3Y04kHMA=="
    crossorigin>
    </script>
    <script>
      setTimeout(function(){
        const titleMsg = document.getElementsByTagName('textarea');
        titleMsg[0].placeholder = 'Digite sua mensagem';
      }, 6000);
    </script>
  <title>Tem Saúde - Chat de Atendimento</title>
  <style>
    .Twilio-EntryPoint {
      display: none !important;
    }
    .Twilio-MainHeader {
      display: none !important;
    }
    .Twilio-MainContainer {
      flex: 1 1 auto;
      flex-flow: column;
      max-height: 100vh;
      height: ${Dimensions.get('screen').height}px !important;
      width: 100%;
      left: 0px;
      overflow: hidden auto;
      bottom: 30px;
      z-index: 100;
      box-shadow: none !important;
      display: flex;
      position: fixed !important;
    }
    .Twilio-MessagingCanvas {
      align-items: center;
      padding-top: 30px;
    }
    .css-10po79b {
      margin: 0px;
      padding: 0px;
    }
    .Twilio-MessageInput-SendButton {
      background-color: rgba(242, 97, 68, 1) !important;
    }
  </style>
</head>
<body>
  <script>
    const appConfig = {
      accountSid: "${accountSid}",
      flexFlowSid: "${flowID}",
      componentProps: {
        MessagingCanvas: {
          useFriendlyName: 'Atendimento',
          avatarCallback: (identity) => {
            return '${authorImage}'
          }
        },
        MainHeader: {
          titleText: 'Chat de atendimento',
          showImage: false,
        },
        MainContainer: {
          width: "90%",
          bottom: "0px",
        }
      },
      context: {
        friendlyName: "${friendlyName}",
      },
    };
    Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = {
      body: '${firstMessage ?? 'Olá como podemos te ajudar hoje?'}',
      authorName: '${authorName}',
      isFromMe: false,
    };

    Twilio.FlexWebChat.createWebChat(appConfig).then((webChat) => {
      webChat._manager._strings.InputPlaceHolder = 'Digite a mensagem';
      webChat._manager._strings.AttachFileImageTooltip = 'Anexar arquivo ou imagem';
      webChat._manager._strings.AttachFileInvalidSize = '{{fileName}} é muito grande para anexar. O tamanho máximo do arquivo é {{maxFileSize}}.';
      webChat._manager._strings.AttachFileInvalidType = '{{fileName}} não pode ser anexado porque o tipo de arquivo não é compatível. Por favor, tente um arquivo diferente.';
      webChat._manager._strings.ChatInputUserNotMemberDisabledText = 'Você não é um participante do bate-papo';
      webChat._manager._strings.ConfirmEndEngagementConfirmButton = 'Sim, acabei';
      webChat._manager._strings.ConfirmEndEngagementDenyButton = 'Não, me leve de volta';
      webChat._manager._strings.ConfirmEndEngagementMessage = 'Tem certeza de que deseja encerrar este bate-papo?';
      webChat._manager._strings.Connecting = 'Conectando…';
      webChat._manager._strings.Disconnected = 'Conexão perdida';
      webChat._manager._strings.DownloadFileInvalidSize = '{{fileName}} é muito grande para baixar. O tamanho máximo do arquivo é {{maxFileSize}}.';
      webChat._manager._strings.DownloadFileInvalidType = '{{fileName}} não pode ser baixado porque o tipo de arquivo não é suportado. Por favor, tente um arquivo diferente.';
      webChat._manager._strings.DownloadFileNotParticipant = 'Não é possível baixar o arquivo. Você não é um participante do bate-papo.';
      webChat._manager._strings.EntryPointTagline = 'Chat de atendimento';
      webChat._manager._strings.ErrorRefreshButton = 'Tente novamente';
      webChat._manager._strings.FieldValidationInvalidEmail = 'Por favor, forneça um endereço de e-mail válido';
      webChat._manager._strings.FieldValidationRequiredField = 'Campo requerido';
      webChat._manager._strings.FileAttachmentDropAreaSubtitle = 'Solte o arquivo aqui';
      webChat._manager._strings.FileAttachmentDropAreaTitle = 'Anexar um arquivo ou uma imagem';
      webChat._manager._strings.FilterItemAmountSelected = '{{amount}} selecionado';
      webChat._manager._strings.FilterItemAny = 'Algum';
      webChat._manager._strings.FilterItemOnly = '{{item.label}} só';
      webChat._manager._strings.InputPlaceHolder = 'Digite a mensagem';
      webChat._manager._strings.InvalidPreEngagementButton = 'Saber mais';
      webChat._manager._strings.InvalidPreEngagementMessage = 'Os formulários de pré-compromisso não foram definidos e são necessários para iniciar o bate-papo na web. Por favor, configure-os agora na configuração.';
      webChat._manager._strings.LegacyFiltersEmptySelectLabel = 'Nenhum';
      webChat._manager._strings.LegacyFiltersTitle = 'Filtros personalizados';
      webChat._manager._strings.ManageAgentsToQueueBreadCrumb = 'Gerenciar agentes';
      webChat._manager._strings.MediaMessageClickToOpen = 'Abrir arquivo';
      webChat._manager._strings.MediaMessageError = 'As mensagens de mídia não são compatíveis';
      webChat._manager._strings.MediaMessageTransferFailed = 'Falha no envio';
      webChat._manager._strings.MessageCanvasTrayButton = 'INICIAR NOVO CHAT';
      webChat._manager._strings.MessageCanvasTrayContent = "<br><h6>Obrigado por conversar conosco!</h6><br><p>Se você tiver mais alguma dúvida, entre em contato conosco novamente.</p>";
      webChat._manager._strings.MessageCharacterCountReached = 'Contagem de caracteres alcançada - {{currentCharCount}} / {{maxCharCount}}';
      webChat._manager._strings.MessageCharacterCountStatus = '{{currentCharCount}} / {{maxCharCount}}';
      webChat._manager._strings.MessageSendingDisabled = 'O envio de mensagens foi desativado';
      webChat._manager._strings.NewChatMessageNotificationBody = '{{#if lastMessage.source.body}} {{lastMessage.source.body}} {{else}} arquivo enviado {{/if}}';
      webChat._manager._strings.NewChatMessageNotificationTitle = 'Nova mensagem de {{lastMessage.authorName}}';
      webChat._manager._strings.NotificationMessage = 'Não foi possível executar a ação: {{message}}';
      webChat._manager._strings.PendingEngagementCancelButton = 'Cancelar';
      webChat._manager._strings.PendingEngagementMessage = 'Aguarde enquanto conectamos você a um agente disponível';
      webChat._manager._strings.PreEngagementMandatoryFieldMessage = 'Campo obrigatório *';
      webChat._manager._strings.PredefinedChatMessageAuthorName = '${authorName}';
      webChat._manager._strings.PredefinedChatMessageBody = 'Olá! Como podemos te ajudar hoje?';
      webChat._manager._strings.QueueStatsBreadCrumb = 'Estatísticas da fila';
      webChat._manager._strings.Read = 'Ler';
      webChat._manager._strings.Reset = 'REDEFINIR';
      webChat._manager._strings.Save = 'SALVE';
      webChat._manager._strings.SendMediaMessageInvalidSize = '{{fileName}} é muito grande para enviar. O tamanho máximo do arquivo é {{maxFileSize}}.';
      webChat._manager._strings.SendMediaMessageInvalidType = '{{fileName}} não pode ser enviado porque o tipo de arquivo não é compatível. Por favor, tente um arquivo diferente.';
      webChat._manager._strings.SendMessageTooltip = 'Enviar mensagem';
      webChat._manager._strings.Today = 'HOJE';
      webChat._manager._strings.TypingIndicator = '{{name}} está digitando…';
      webChat._manager._strings.TypingIndicatorMultiple = '{{count}} as pessoas estão digitando… ';
      webChat._manager._strings.WelcomeMessage = '${
        welcomeMessage ?? 'Bem-vindo ao atendimento ao cliente'
      }';
      webChat._manager._strings.Yesterday = 'ONTEM';
      
      webChat.init();
    });
  </script>
  <script defer>
    const openChat = document.getElementsByClassName('Twilio-EntryPoint');
    openChat[0].click();
  </script>
</body>
</html>
`;

  return (
    <S.KeyboardAvoidingView behavior="padding" enabled={Platform.OS === 'android'}>
      <WebView source={{ html }} originWhitelist={['*']} />
    </S.KeyboardAvoidingView>
  );
};
