import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M18.71 8.21a1 1 0 00-1.42 0l-4.58 4.58a1 1 0 01-1.42 0L6.71 8.21a1 1 0 00-1.42 0 1 1 0 000 1.41l4.59 4.59a3 3 0 004.24 0l4.59-4.59a1 1 0 000-1.41z"
      />
    </Svg>
  );
}

export default Icon;
