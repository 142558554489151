/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import { Button, Dialog, MobileAutoSpace, Typography } from '../../../../components/@hello-ui';
import { SelectFiles } from './SelectFiles';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useUploader } from '~/components/@tem-ui/Uploader/useUploader';
import { ItemType } from '~/components/@tem-ui/Stepper/types';
import { PickerOptionsEnum } from '~/components/@tem-ui/Uploader/types';
import { useAppointment } from '~/hooks/appointment';

type ScheduleUploadExamProps = {
  stepperData: ItemType;
};

export const ScheduleUploadExam = ({ stepperData }: ScheduleUploadExamProps): JSX.Element => {
  const [dialogState, setDialogState] = useState<boolean>(false);

  const uploadField = useRef<HTMLInputElement>(null);

  usePageWithCardSettings({ scrollEnabled: true });

  const { files, maxFileSize, addFile } = useUploader();
  const { setStepData, nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();

  const onFilePicker = useCallback(
    async (type: PickerOptionsEnum, target?) => {
      await addFile(type, target);
      setDialogState(false);
    },
    [addFile],
  );

  const onUpload = useCallback(() => {
    if (Platform.OS === 'web') {
      uploadField?.current?.click();
    } else {
      setDialogState(true);
    }
  }, [uploadField, setDialogState]);

  useEffect(() => {
    if (files.length > 0) {
      setStepData(stepperData);
      nextStep();
    }
  }, [files]);

  return (
    <>
      <View className="mb-8">
        <Typography variant="title">Precisamos do seu pedido médico</Typography>
      </View>
      <View className="mb-24">
        <Typography variant="body2">
          Para prosseguir com seu agendamento, precisamos que você envie o seu pedido médico.
        </Typography>
      </View>
      <View className="mb-80 max-h-[156px] w-full rounded-lg bg-background-gray p-16 mobile:mb-0">
        <View className="mb-16">
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">Selecione o arquivo</Typography> da sua biblioteca
            clicando no botão “Enviar pedido”. A imagem será anexada na sua solicitação de exame.
          </Typography>
        </View>
        <View>
          <Typography variant="helperText">
            Formatos permitidos: PDF, JPG ou PNG até {maxFileSize}MB.
          </Typography>
        </View>
      </View>
      {Platform.OS === 'web' && (
        <input
          multiple
          ref={uploadField}
          type="file"
          style={{ display: 'none' }}
          accept="image/*,.pdf"
          onChange={async (e) => await onFilePicker(PickerOptionsEnum.Archiver, e.target)}
        />
      )}
      <MobileAutoSpace />
      <Button variant="primary" onPress={() => onUpload()}>
        Enviar pedido
      </Button>
      <View className="mt-4">
        <Button
          variant="link"
          // @ts-ignore
          onPress={() => setAppointment({ ...appointment, notFoundAttatchment: true })}>
          Não tenho pedido médico
        </Button>
      </View>
      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <SelectFiles onAddFile={(type: PickerOptionsEnum) => onFilePicker(type)} />
      </Dialog>
    </>
  );
};
