import { createElement } from 'react';
import { TypographyTypes, TypographyVariantTypes, TypographyVariantTypesMobile } from './types';
import {
  Title1,
  Title2,
  Title3,
  Heading1,
  Heading2,
  Heading3,
  Subtitle1,
  Body1,
  Body2,
  Paragraph,
} from './elements';
import { useMedia } from '~/components/@hello-ui';

const Components = {
  title1: Title1,
  title2: Title2,
  title3: Title3,
  heading1: Heading1,
  heading2: Heading2,
  heading3: Heading3,
  subtitle1: Subtitle1,
  body1: Body1,
  bodyHighlight1: Body1,
  body2: Body2,
  bodyHighlight2: Body2,
  paragraph: Paragraph,
};

export const Typography = ({
  style,
  variant,
  color,
  bold,
  weight,
  ...rest
}: TypographyTypes): any => {
  const media = useMedia();

  const typographyProps = {
    'data-testid': 'typography-testid',
    style: {
      ...(media.isMobile ? TypographyVariantTypesMobile[variant] : TypographyVariantTypes[variant]),
      fontWeight: weight ? weight : bold ? '700' : TypographyVariantTypes[variant].fontWeight,
      color,
    },
    ...rest,
  };

  return createElement(Components[variant], {
    ...typographyProps,
  });
};
