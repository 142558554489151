import React from 'react';

import { View } from 'react-native';

import { ToggleHeader } from './ToggleHeader';
import { Typography } from '~/components/@hello-ui';

interface MapViewListHeaderProps {
  location: string;
  currentDisplayType: 'list' | 'map';
  handleDisplayType: () => void;
}

export const MapViewListHeader = ({
  location,
  handleDisplayType,
  currentDisplayType,
}: MapViewListHeaderProps) => {
  return (
    <View className="absolute left-0 right-0 top-0 z-10 w-full flex-row items-center justify-between bg-white/[0.65] px-24 py-8 mobile:p-16">
      <Typography variant="bodyHighlight1" className="max-w-[75%]">
        {location}
      </Typography>
      <ToggleHeader handleDisplayType={handleDisplayType} option={currentDisplayType} />
    </View>
  );
};
