import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';
import { CheckboxTypes } from './types';
import * as S from './styles';

export const Checkbox = ({
  checked,
  onPress,
  disabled,
  color,
  label,
}: CheckboxTypes): JSX.Element => {
  const [state, setState] = useState<boolean>(checked ?? false);
  const theme = useTheme();

  useEffect(() => {
    setState(checked ?? false);
  }, [checked]);

  return (
    <S.WrapperCheckbox
      onPress={() => {
        !disabled && setState(!state);
        !disabled && onPress && onPress(!state);
      }}
      activeOpacity={0.8}>
      <S.Wrapper
        style={{
          borderColor: !disabled
            ? state
              ? color ?? theme.colors.success
              : theme?.colors?.paragraph
            : theme?.colors.black16,
          backgroundColor: state ? color ?? theme.colors.success : theme?.colors.white,
        }}>
        {state && <Icon name="check" size={16} color={theme?.colors.white} />}
      </S.Wrapper>
      {label && (
        <Typography variant="body2" color="paragraph" style={{ marginLeft: 8 }}>
          {label}
        </Typography>
      )}
    </S.WrapperCheckbox>
  );
};
