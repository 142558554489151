import styled from 'styled-components/native';

export const SliderItem = styled.View`
  flex: 1;
  height: 337px;
  z-index: 2;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    border-radius: 20px;
  `}
  overflow: hidden;
`;

export const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 440px;
  position: absolute;
`;

export const WrapperTitle = styled.View`
  position: absolute;
  bottom: 50%;
  margin-horizontal: 70px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-horizontal: 16px;
  `}
`;

export const WrapperBottom = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  height: 280px;
  justify-content: center;
  z-index: 4;
  margin-top: 460px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 20px;
  `}
`;

export const WrapperDescription = styled.Text`
  position: relative;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding-bottom: 0px;
  max-width: 610px;
  font-size: 16px;
  line-height: 24;
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.black};
`;

export const WrapperText = styled.View`
  position: relative;
  margin: 0 auto;
  margin-top: 0px;
  text-align: center;
  max-width: 610px;
  color: ${({ theme: { colors } }) => colors.black60};
`;
