import styled from 'styled-components/native';

export const WrapperCheckbox = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const Wrapper = styled.View`
  border-width: 1.5px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
`;
