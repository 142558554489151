import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const FileIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        stroke={color ?? theme.colors.primary}
        strokeWidth={1.3}
        d="M3 8.914a1 1 0 0 1 .293-.707l5.914-5.914A1 1 0 0 1 9.914 2h10.084C20.55 2 21 2.455 21 2.992v18.016a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8.914Zm6-5.922L3.993 8H9V2.992Z"
      />
    </Svg>
  );
};

export default FileIcon;
