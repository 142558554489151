import React from 'react';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useSweetAlert } from './hooks';
import { AlertDefaults } from './AlertViews/AlertsDefaults';
import { AlertCustom } from './AlertViews/AlertCustom';
import { AlertIconTitle } from './AlertViews/AlertIconTitle';
import { HelloUiLayout } from './AlertViews/HelloUi';
import { useMedia } from '~/components/@hello-ui';

export const SweetAlert = (): JSX.Element => {
  const { statusAlert, hideSweetAlert, custom, setDimiss } = useSweetAlert();
  const media = useMedia();

  function contentAlert() {
    if (custom?.layout === 'helloUi') {
      return <HelloUiLayout />;
    }

    return custom ? custom.iconTitle ? <AlertIconTitle /> : <AlertCustom /> : <AlertDefaults />;
  }

  return (
    <AwesomeAlert
      modalProps={{ testID: 'sweet-alert' }}
      show={statusAlert}
      customView={contentAlert()}
      closeOnTouchOutside={custom?.touchOutside ?? true}
      closeOnHardwareBackPress={true}
      onDismiss={() => {
        hideSweetAlert();
        setDimiss(true);
      }}
      contentStyle={{ flexGrow: 1, padding: 0, marginLeft: 0 }}
      contentContainerStyle={{
        width: media.isMobile ? 400 : 600,
        minHeight: media.isMobile ? 300 : 440,
        backgroundColor: '#FFFFFF',
        shadowColor: '#000',
        shadowOffset: {
          width: 1,
          height: 2,
        },
        padding: 16,
        shadowOpacity: 0.15,
        elevation: 8,
        shadowRadius: 10,
        borderRadius: 10,
      }}
    />
  );
};
