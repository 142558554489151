import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Platform } from 'react-native';
import { Chip } from '../Chip';
import { EmptyChipSpace, TimetableWrapper } from './styles';

interface TimeTableProps {
  onSelectTime: (time: string) => void;
  selected: string[];
  maxSelected?: number;
}

export const timeTableData: string[] = [
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
];

export const CHIP_IDEAL_WIDTH = 75;

export const TimeTable = ({ onSelectTime, selected, maxSelected }: TimeTableProps) => {
  const theme = useTheme();

  const [containerWidth, setContainerWidth] = useState(0);

  const handleChipSelect = (value: string) => {
    onSelectTime?.(value);
  };

  const isSelected = (time: string) => {
    return selected && !!selected.find((item) => item === time);
  };

  const isDisabled = (time: string) => {
    if (maxSelected && selected.length >= maxSelected) {
      return !selected.find((item) => item === time);
    }

    return false;
  };

  const calcChipsColumns = () => {
    const padding = theme.isMobile ? 8 : 16;
    const chipIdealTotalSize = CHIP_IDEAL_WIDTH + padding;

    const totalColumnsWithExtraPadding = Math.floor(containerWidth / chipIdealTotalSize);
    const occupiedSpace = totalColumnsWithExtraPadding * chipIdealTotalSize;

    // Remove the last padding and check if fits another column
    const realOccupiedSpace = occupiedSpace - padding;
    const fitsAnotherColumn = containerWidth - realOccupiedSpace >= CHIP_IDEAL_WIDTH;

    return totalColumnsWithExtraPadding + (fitsAnotherColumn ? 1 : 0);
  };

  const renderTimeChips = () => {
    return timeTableData.map((time, index) => (
      <Chip
        key={`time-${index}`}
        value={time}
        text={time}
        variant="background-click"
        onSelect={handleChipSelect}
        selected={isSelected(time)}
        disabled={isDisabled(time)}
        style={{
          borderRadius: 10,
          flexBasis: CHIP_IDEAL_WIDTH,
          height: 40,
          flexGrow: 1,
          flexShrink: 0,
          justifyContent: 'center',
          alignItems: 'center',
          ...(Platform.OS !== 'web' && { margin: 8 / 2 }),
        }}
      />
    ));
  };

  const renderEmptyChipSpaces = () => {
    const chipColumns = calcChipsColumns();
    const timesCount = timeTableData.length;
    const remainder = timesCount % (chipColumns || 1);
    const emptyChipsCount = (remainder - chipColumns) * -1;

    return new Array(emptyChipsCount)
      .fill(null)
      .map((val, idx) => <EmptyChipSpace key={`empt-${idx}`} />);
  };

  return (
    <TimetableWrapper onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)}>
      {renderTimeChips()}
      {renderEmptyChipSpaces()}
    </TimetableWrapper>
  );
};
