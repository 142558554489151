import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { Typography } from '~/components/@hello-ui';

export const Card = styled.View<{ isSvgFile?: boolean }>`
  border-radius: 24px;
  width: 348px;
  height: 227px;
  box-shadow: 1px 2px 20px ${({ theme: { colors } }) => colors.black16};
  elevation: 2;
  position: relative;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      width: 274px;
      height: 178px;
      border-radius: 18px;
  `}

  ${({ theme: { colors }, isSvgFile }) =>
    !isSvgFile &&
    `
      background-color: ${colors.primary};
      overflow: hidden;
    `}
`;

export const WrapperBottom = styled.View`
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  padding: ${({ theme }) => (theme.isMobile ? theme.tokens.space.s24 : theme.tokens.space.s32)};
`;

export const CardDetailsRow = styled.View`
  flex-direction: row;
`;

export const ImageBackground = styled.Image`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const WrapperLoading = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.white,
})``;

export const CardDataLabel = styled(Typography)`
  font-size: ${({ theme }) => (theme.isMobile ? '10px' : '12px')};
  font-weight: 700;
  line-height: 24px;
  text-align-vertical: center;

  ${Platform.OS !== 'web' &&
  css`
    font-family: 'Poppins-Regular';
    font-weight: 500;
  `}
`;

export const CardData = styled(Typography)`
  font-size: ${({ theme }) => (theme.isMobile ? '9px' : '12px')};
  font-weight: 700;
  line-height: 24px;
  margin-left: 4px;
  margin-top: ${Platform.OS === 'android' ? -1 : 0}px;

  ${Platform.OS !== 'web' &&
  css`
    font-family: 'Poppins-Bold';
  `}
`;
