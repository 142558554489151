import React, { useState, useRef } from 'react';
import { TextInputMask } from 'react-native-masked-text';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import Icon from 'react-native-vector-icons/dist/Feather';

import { InputTypes } from './types';
import * as S from './styles';
import { MaterialInput } from './components/MaterialInput';
import { StyledDefaultTheme } from '~/components/@hello-ui';

const CODE_INPUTS = 4;

export const Input = ({
  testID,
  password,
  passwordToggle,
  code,
  codeStyle,
  passwordToggleStyle,
  mask,
  error,
  onChange,
  onFocus,
  variant,
  icon,
  required,
  focusing,
  inputRef,
  ...rest
}: InputTypes): JSX.Element => {
  const [passwordState, setPasswordState] = useState(password);

  const [value, setValue] = useState('');
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  rest.label = `${rest.label} ${required ? '*' : ''}`;
  const [inputLabel, setInputLabel] = useState<string>(rest.label);

  const commonProps = {
    testID,
    onChangeText: onChange,
    secureTextEntry: passwordState,
    onFocus,
    error,
    variant,
    icon,
    ...rest,
  };

  const _ref = useRef<null>(null);
  const refInputCode = useBlurOnFulfill({ value, cellCount: CODE_INPUTS });

  if (mask) {
    return (
      <S.WrapperContainer>
        <TextInputMask
          {...commonProps}
          label={inputLabel}
          type="custom"
          options={{ mask }}
          customTextInput={MaterialInput}
          onFocus={() => {
            if (focusing?.label) {
              setInputLabel(`${focusing?.label} ${required ? '*' : ''}`);
            }
          }}
          onBlur={() => {
            if (focusing?.label) {
              setInputLabel(rest.label);
            }
          }}
          ref={inputRef ?? _ref}
        />
        {password && passwordToggle && (
          <S.WrapperIcon onPress={() => setPasswordState(!passwordState)}>
            <Icon
              name={passwordState ? 'eye-off' : 'eye'}
              size={24}
              color={
                passwordToggleStyle !== 'black'
                  ? StyledDefaultTheme.colors.white70
                  : StyledDefaultTheme.colors.black
              }
            />
          </S.WrapperIcon>
        )}
      </S.WrapperContainer>
    );
  }

  if (code) {
    return (
      <CodeField
        ref={inputRef ?? refInputCode}
        {...props}
        value={rest.value ?? value}
        onChangeText={(input) => {
          const value = input.replace(/\D+/gim, '');

          setValue(value);
          onChange && onChange(value);
        }}
        autoFocus={rest.autoFocus ?? false}
        testID={testID}
        caretHidden={false}
        cellCount={CODE_INPUTS}
        textContentType="oneTimeCode"
        keyboardType={commonProps.keyboardType}
        renderCell={({ index, symbol, isFocused }) => (
          <S.WrapperCode codeStyle={codeStyle} key={index}>
            <S.TextCode codeStyle={codeStyle} onLayout={getCellOnLayoutHandler(index)}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </S.TextCode>
          </S.WrapperCode>
        )}
      />
    );
  }

  return (
    <S.WrapperContainer>
      <MaterialInput {...commonProps} ref={inputRef ?? _ref} />
      {password && passwordToggle && (
        <S.WrapperIcon onPress={() => setPasswordState(!passwordState)}>
          <Icon
            name={passwordState ? 'eye-off' : 'eye'}
            size={24}
            color={
              passwordToggleStyle !== 'black'
                ? StyledDefaultTheme.colors.white70
                : StyledDefaultTheme.colors.black
            }
          />
        </S.WrapperIcon>
      )}
    </S.WrapperContainer>
  );
};
