import React, { createContext, useCallback, useContext, useState } from 'react';
import { BottomSheetProviderTypes, BottomSheetType } from './types';

const BottomSheetContext = createContext<BottomSheetType | null>(null);

export const BottomSheetProvider = ({ children }: BottomSheetProviderTypes): JSX.Element => {
  const [bottomSheetState, setBottomSheetState] = useState<boolean>(false);

  const handleBottomSheet = useCallback(() => {
    setBottomSheetState(!bottomSheetState);
  }, [bottomSheetState]);

  const openBottomSheet = useCallback(() => {
    setBottomSheetState(true);
  }, [bottomSheetState]);

  const closeBottomSheet = () => {
    setBottomSheetState(false);
  };

  return (
    <BottomSheetContext.Provider
      value={{ bottomSheetState, handleBottomSheet, openBottomSheet, closeBottomSheet }}>
      {children}
    </BottomSheetContext.Provider>
  );
};

export const useBottomSheet = (): BottomSheetType => {
  const context = useContext(BottomSheetContext);

  if (!context) {
    throw new Error('useBottomSheet must be used within an BottomSheetProvider');
  }

  return context;
};
