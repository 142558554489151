import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { Panel, Typography, Button } from '../../components/@tem-ui';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Spacing } from '~/components/Spacing';
import * as S from './styles';
import CardSuccess from '~/assets/images/card-success.svg';
import { useTemPay } from '~/hooks/temPay';
import { useMedia } from '~/components/@hello-ui';

export const TemPayRechargeCard = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();

  const { loadCard, loadingCard } = useTemPay();

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Recarga Créditos"
          onBack={async () => {
            await loadCard();

            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('Dashboard');
            }
          }}>
          <S.WrapperBillet>
            <Spacing bottom={10}>
              <CardSuccess />
            </Spacing>
            <Typography variant="heading1" color="black" bold>
              Crédito no Cartão :)
            </Typography>

            <Spacing top={10} bottom={40}>
              <Typography variant="body1" color="black" style={styles.center}>
                <S.CenterText>
                  Sua compra está sendo processada, você receberá um e-mail sempre que o status da
                  sua compra for atualizado.
                </S.CenterText>
              </Typography>
            </Spacing>
            <Button
              variant="primary"
              title="OK"
              disabled={loadingCard}
              onPress={async () => {
                await loadCard();
                navigation.navigate('TemPayDashboard');
              }}
            />
          </S.WrapperBillet>
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};

const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
  },
});
