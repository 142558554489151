import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Panel } from '~/components/@tem-ui';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import * as S from './styles';
import { Stepper } from '~/components/@tem-ui/Stepper/Stepper';
import { benefitsTypes } from './components/benefitsTypes';
import { useMedia } from '~/components/@hello-ui';

export const BenefitsNetwork = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();
  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Rede de Saúde"
          onBack={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('Dashboard');
            }
          }}>
          <>
            <Stepper
              title="O que você precisa?"
              items={benefitsTypes()}
              validateDependentFeatures
            />
          </>
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};
