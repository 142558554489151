import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export interface FilterProps {
  color: string;
  opacity: number;
}

export interface RectangleProps {
  width: number;
  height: number;
}

export const WrapperHeader = styled.ImageBackground`
  width: 100%;
  height: 350px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    height: 200px;
  `}
`;

export const WrapperContainer = styled.View`
  margin-bottom: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-bottom: 30px;
  `}
`;

export const HeaderIcon = styled.TouchableOpacity`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-top: 26px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const PanelHeaderImage = styled.View`
  width: 387px;
  height: 387px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 60px;
`;

const WrapperVariations = {
  web: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: ${({ theme: { colors } }) => colors.black};
    shadow-offset: ${Platform.OS === 'ios' ? '{width: 5, height: 5}' : '{width: 1, height: 2}'};
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
  `,
};

export const PanelHeaderFeatures = styled.View`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  align-self: flex-start;
  text-align: left;
  border-radius: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile ? WrapperVariations['mobile'] : WrapperVariations['web']}
`;

export const ScheduleFeatures = styled.View`
  width: 100%;
  max-width: 608px;
  elevation: 2;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black16};
  width: 100%;
  height: 0.5px;
`;

export const ScheduleFeaturesList = styled.FlatList`
  border-top-color: ${({ theme: { colors } }) => colors.black16};
  border-top-width: 0.5px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 15px;
  `}
`;

export const FeatureItem = styled.View`
  padding: 15px;
  flex-direction: row;
  align-items: center;
`;

export const WrapperContent = styled.View`
  align-items: center;
  margin: 30px 10px 0px 10px;
  height: 280px;
  justify-content: space-between;
  text-align: left;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 30px 15px 80px 15px;
  `}
`;

export const WrapperCard = styled.View`
  width: 650px;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    height: 230px;
    width: 100%;
  `}
`;

export const PanelContent = styled.View`
  top: 120px;
  width: 100%;
  margin: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding-horizontal: 16px;
      top: 220px;
    `};
`;

export const ScheduleInfo = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 10px;
  width: 100%;
  max-width: 608px;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-vertical: 24px;
  text-align: center;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  elevation: 2;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding-horizontal: 16px;
    `}
`;

export const ScheduleInfoContact = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ScheduleHelp = styled.View`
  width: 100%;
`;
