/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { API_R_URL, APPOINTMENT_SERVICE, API_BACKOFFICE, API_URL } from '@env';
import { format, parse, parseISO } from 'date-fns';
import qs from 'qs';
import { refreshAuthLogic, requestInterceptor } from './apiConfig';

import { CreateTelemedicine24h, GetQueue } from './api/interfaces/conexa';
import {
  CheckConfirmationTokenType,
  ConfirmationTokenType,
  CreditCard,
  LocationsType,
  Recharges,
  SearchCertifiedTemRedePayload,
  SearchCertifiedTemRedeRes,
  SearchTemFarmaPayload,
  SearchTemFarmaRes,
  Specialty,
  SpecialtyType,
  ValidateDeeplinkRes,
} from '~/contexts/types';
import { MyAppointments } from '~/@types/appointments';
import { AppointmentSendPayloadType } from '~/contexts/appointment';
import { extractDigits } from '~/utils/strings';
import { AuthService } from '~/auth/auth-service';
import { useAuthStore } from '~/store/auth.store';
interface ApiTypes {
  baseUrl?: string;
}

export default class ApiService {
  public readonly instanceR: AxiosInstance;
  public readonly instance: AxiosInstance;
  public readonly instanceAppointment: AxiosInstance;
  public readonly instanceBackoffice: AxiosInstance;

  public constructor({ baseUrl }: ApiTypes = {}) {
    this.instanceAppointment = axios.create({
      baseURL: APPOINTMENT_SERVICE,
    });

    this.instanceR = axios.create({
      baseURL: API_R_URL,
    });

    this.instanceBackoffice = axios.create({
      baseURL: API_BACKOFFICE,
    });

    this.instance = axios.create({
      baseURL: baseUrl ?? '',
      headers: {},
    });

    createAuthRefreshInterceptor(this.instance, refreshAuthLogic, {
      statusCodes: [401],
    });

    createAuthRefreshInterceptor(this.instanceR, refreshAuthLogic, {
      statusCodes: [401, 500],
    });

    createAuthRefreshInterceptor(this.instanceAppointment, refreshAuthLogic, {
      statusCodes: [401],
    });

    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private readonly _initializeRequestInterceptor = (): undefined => {
    this.instanceR.interceptors.request.use(requestInterceptor);
    this.instance.interceptors.request.use(requestInterceptor);
    this.instanceAppointment.interceptors.request.use(requestInterceptor);
  };

  private readonly _initializeResponseInterceptor = (): number =>
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);

  private readonly _handleResponse = ({ data, headers }: AxiosResponse): AxiosResponse => {
    const dataReturned = data.data ? data : { data };
    return { ...dataReturned, headers };
  };

  protected _handleError = (error: any): Promise<any> => Promise.reject(error);

  async getTokens(): Promise<{ tokenzeus: string; onix: string; cnpj?: string }> {
    return {
      tokenzeus: await AuthService.getToken(),
      onix: AuthService.getSelectedOnix(),
      cnpj: useAuthStore.getState().selectedProduct?.cnpj,
    };
  }

  async getMySignature(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/my-signature');
  }

  async getMyClinic(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/my-clinic');
  }

  async login(documentNumber: string, password: string): Promise<AxiosResponse<any>> {
    return this.instance.post('v1/hello/login', {
      cpf: documentNumber,
      password,
    });
  }

  async checkFirstAccess(documentNumber: string): Promise<AxiosResponse<any>> {
    return await this.instance.get(`v1/hello/check-first-access/${documentNumber}`);
  }

  async getBenefits(cardNumber: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`v1/hello/cards/${cardNumber}/benefits`);
  }

  async getPartner(slug: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`v1/hello/partners/${slug}`);
  }

  async passwordFirstAccess(
    documentNumber: string,
    password: string,
    code: string,
  ): Promise<AxiosResponse<any>> {
    const validationToken = await AsyncStorage.getItem('@tem::ValidationToken');
    return this.instance.post('v1/hello/new-password', {
      documentNumber,
      password,
      code: Number(code),
      validationToken,
    });
  }

  async sendToken(
    documentNumber: string,
    onix_code?: string,
    smsHashcode?: string | string[],
  ): Promise<ConfirmationTokenType> {
    return this.instance.post('v1/hello/send-confirmation-token', {
      cpf: documentNumber,
      onix_code,
      smsHashcode,
    });
  }

  async checkToken(code: number): Promise<CheckConfirmationTokenType> {
    const validationToken = await AsyncStorage.getItem('@tem::ValidationToken');
    return this.instance.post('v1/hello/check-confirmation-token', {
      code,
      validationToken,
    });
  }

  async myProfile(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/profile');
  }

  async myProducts(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/profile/my-products');
  }

  async appointmentGET(url: string, cpf: string): Promise<AxiosResponse<any>> {
    const { user_token } = await this.getUsertokenCto(cpf);
    const { tokenzeus } = await this.getTokens();

    return this.instanceAppointment.get(url, {
      params: { token: tokenzeus, user_token },
      headers: {
        'x-document': cpf,
      },
    });
  }

  async appointmentPOST(url: string, cpf: string, data: any): Promise<AxiosResponse<any>> {
    const { user_token } = await this.getUsertokenCto(cpf);
    const newTokens = await this.getTokens();

    return this.instanceAppointment.post(url, data, {
      params: {
        ...newTokens,
        token: newTokens.tokenzeus,
        user_token,
        isTelemedicine: data?.type === 'online',
      },
    });
  }

  async appointmentPUT(url: string, cpf: string, data: any): Promise<AxiosResponse<any>> {
    const { user_token } = await this.getUsertokenCto(cpf);
    const newTokens = await this.getTokens();

    return this.instanceAppointment.put(url, data, {
      params: { ...newTokens, token: newTokens.tokenzeus, user_token },
    });
  }

  async specialities({ type = 'medical' }: SpecialtyType = {}): Promise<
    AxiosResponse<Specialty[]>
  > {
    const { tokenzeus, onix } = await this.getTokens();
    return this.instanceAppointment.get(`${APPOINTMENT_SERVICE}/specialties/${onix}/${type}`, {
      params: {
        token: tokenzeus,
      },
    });
  }

  async findSpecialtyByName(name: string): Promise<AxiosResponse<Specialty>> {
    const { tokenzeus } = await this.getTokens();

    return this.instanceAppointment.get(`${APPOINTMENT_SERVICE}/specialties/find/${name}`, {
      params: {
        token: tokenzeus,
      },
    });
  }

  async getRegulation(): Promise<AxiosResponse<any>> {
    const tokens = await this.getTokens();
    return await this.instanceBackoffice.get(`api/v1/external/produto/${tokens.onix}`);
  }

  async states(value?: string): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/painel/uf', { params: { q: value } });
  }

  async cities(id: number, value?: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`v1/painel/municipios/${id}`, { params: { q: value } });
  }

  async neighborhoods(id: number, values?: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`v1/painel/bairro/${id}`, { params: { q: values } });
  }

  async requestAddress(address: LocationsType, cpf: string): Promise<AxiosResponse<any>> {
    return await this.appointmentPOST(
      `${APPOINTMENT_SERVICE}/v1/addresses`,
      extractDigits(cpf),
      address,
    );
  }

  async createSchedule(appointmentData: AppointmentSendPayloadType): Promise<AxiosResponse<any>> {
    appointmentData.source = 'aplicativo_tem_saude_digital';
    return await this.appointmentPOST(
      `${APPOINTMENT_SERVICE}/appointment`,
      appointmentData.cpf,
      appointmentData,
    );
  }

  async updateAttachments(
    appointment_id: string,
    file: any,
    cpf: string,
  ): Promise<AxiosResponse<any>> {
    return await this.appointmentPUT(`${APPOINTMENT_SERVICE}/v1/appointments`, cpf, {
      acao: 'anexo',
      protocolo: appointment_id,
      descricao: 'Pedido Médico',
      tipo: file.type,
      nome: file.name,
      base64: file.base_64,
    });
  }

  async getMyAppointments({
    cpf,
    page,
    perPage,
    finished,
  }: MyAppointments): Promise<AxiosResponse<any>> {
    const { onix } = await this.getTokens();
    const document = extractDigits(cpf);

    return await this.appointmentGET(
      `${APPOINTMENT_SERVICE}/appointment/${document}/${page}/${perPage ?? 4}?finished=${
        finished ?? false
      }&onix=${onix}`,
      document,
    );
  }

  async getMyAppointmentById(id: number, cpf: string, type?: string): Promise<AxiosResponse<any>> {
    const { onix } = await this.getTokens();
    const typeMap = {
      online: 'telemedicine',
    };
    return await this.appointmentGET(
      `${APPOINTMENT_SERVICE}/appointment/ticket/${id}?onix=${onix}&type=${typeMap[type] || type}`,
      extractDigits(cpf),
    );
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<any>> {
    return this.instance.put('v1/hello/profile/new-password', {
      oldPassword,
      newPassword,
    });
  }

  async recharge(payload: any): Promise<AxiosResponse<any>> {
    return this.instance.post('v1/hello/recharges', payload);
  }

  async rechargeWithRegisteredCreditCard(payload: Recharges): Promise<AxiosResponse<any>> {
    return this.instance.post('v1/hello/recharges/credit-card', payload);
  }

  async sendingSurgicalProcedure(payload: any): Promise<AxiosResponse<any>> {
    return await this.instance.post('v1/hello/schedule-appointment', payload);
  }

  async getCreditCards(cpf: string): Promise<AxiosResponse<any>> {
    return await this.instance.get(`v1/hello/credit-cards/${cpf}`);
  }

  async createCreditCard(payload: CreditCard): Promise<AxiosResponse<any>> {
    return await this.instance.post('v1/hello/credit-cards', payload);
  }

  async deleteCreditCard(id: number): Promise<AxiosResponse<any>> {
    return await this.instance.delete(`v1/hello/credit-cards/${id}`);
  }

  async luckyNumber(cardNumber: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`v1/hello/cards/${cardNumber}/lucky-numbers`);
  }

  async lotteryNumbers(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/cards/lottery-numbers');
  }

  async searchZipCode(address: string): Promise<AxiosResponse<any>> {
    return this.instance.post('v1/maps/search-by-zipcode', {
      address,
    });
  }

  async searchByLatLng(lat: number, lng: number): Promise<AxiosResponse<any>> {
    return this.instance.post('v1/maps/search-by-latlng', {
      lat,
      lng,
    });
  }

  async searchDrugstores(searchParams: {
    latitude: string;
    longitude: string;
    radius: string;
    description?: string;
    onix?: string;
  }): Promise<AxiosResponse<any>> {
    const { onix } = await this.getTokens();

    searchParams.onix = onix;
    return this.instance.get('v1/hello/drugstores', {
      params: {
        ...searchParams,
      },
    });
  }

  async searchTemFarma(
    searchParams: SearchTemFarmaPayload,
  ): Promise<AxiosResponse<SearchTemFarmaRes>> {
    return this.instance.get('v1/hello/tem-farma/search', {
      params: searchParams,
    });
  }

  async updateProfile(data: {
    phone: string;
    email: string;
    birthdate: string;
    name: string;
    gender: string;
    document_number: string;
    firstAccess?: boolean;
  }): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    const genderMapping: Record<string, number> = {
      Masculino: 1,
      Feminino: 0,
      Indiferente: 99,
    };

    let CodOnix = onix;
    if (data.firstAccess) {
      CodOnix = await this.getFirstOnix(onix);
    }

    return this.instanceR.post('tem_alteracao', {
      tokenzeus,
      CodOnix,
      Telefone: extractDigits(data.phone),
      email: data.email,
      data_nascimento: format(parse(data.birthdate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      Nome: data.name,
      Sexo: genderMapping[data.gender],
      cpf: extractDigits(data.document_number),
    });
  }

  async updateProfileAddress(data: {
    cpf: string;
    cep: string;
    state: string;
    city: string;
    neighborhood: string;
    complement: string;
    number: string;
    street: string;
    firstAccess?: boolean;
  }): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    let CodOnix = onix;
    if (data.firstAccess) {
      CodOnix = await this.getFirstOnix(onix);
    }

    return this.instanceR.post('/tem_alteracao', {
      tokenzeus,
      CodOnix,
      cpf: data.cpf,
      CEP: extractDigits(data.cep),
      Estado: data.state,
      Cidade: data.city,
      Bairro: data.neighborhood,
      Complemento: data.complement,
      NumeroEndereco: data.number,
      Logradouro: data.street,
    });
  }

  async getFirstOnix(onix: string) {
    const { data: products } = await this.myProducts();

    if (products.length) {
      return products[0].onix_code;
    }

    return onix;
  }

  async cardList(cpf: string): Promise<AxiosResponse<any>> {
    const cleanCpf = extractDigits(cpf);

    const { onix, tokenzeus } = await this.getTokens();

    return await this.instance.post('/v1/hello/tem_saldo_cto', {
      tokenzeus: tokenzeus,
      CodOnix: onix,
      cpf: cleanCpf,
    });
  }

  async cardBalance(documentNumber: string, number: string): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    return await this.instanceR.post('tem_saldo_cto', {
      cpf: documentNumber,
      NumeroCartao: number,
      tokenzeus: tokenzeus,
      CodOnix: onix,
    });
  }

  async cardStatement(documentNumber: string, number: string): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    return this.instanceR.post('tem_extrato_cto', {
      Cpf: documentNumber,
      NumeroCartao: number,
      tokenzeus,
      CodOnix: onix,
    });
  }

  async getDependents(cpf: string): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    return this.instanceR.post('tem_dependente', {
      tokenzeus,
      CodOnix: onix,
      Cpf: extractDigits(cpf),
    });
  }

  async createDependent(data: {
    cpfTitular: string;
    birthdate: string;
    name: string;
    cpf: string;
    email: string;
    Telefone: string;
    sexo: string;
  }): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus, cnpj } = await this.getTokens();
    const cnpjValue = cnpj ? cnpj : await this.getCnpjByOnixCode(onix);

    return this.instanceR.post('tem_adesao', {
      tokenzeus,
      CodOnix: onix,
      cpfTitular: extractDigits(data.cpfTitular),
      cnpj: cnpjValue,
      data_nascimento: format(parse(data.birthdate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      Nome: data.name,
      cpf: extractDigits(data.cpf),
      email: data.email ?? '',
      Telefone: extractDigits(data.Telefone),
      Sexo: data?.sexo === 'MASCULINO' ? '1' : data.sexo === 'FEMININO' ? '0' : '99',
    });
  }

  async getCnpjByOnixCode(onix: string): Promise<AxiosResponse<any>> {
    try {
      const response = await this.instance.get(`v1/hello/getCnpjByOnixCode/${onix}`);
      if (response.data && response.data.cnpj) {
        return response.data.cnpj.replace(/[^\d]/g, '');
      } else {
        throw new Error('CNPJ not found in API response.');
      }
    } catch (error) {
      console.error('Error fetching CNPJ from API: ', error);
      throw error;
    }
  }

  async getCardNumberByCpfOnix(cpf: string, onix: string): Promise<AxiosResponse<any>> {
    try {
      const response = await this.instance.get(`v1/hello/getCardNumberByCpfOnix/${cpf}/${onix}`);
      if (response.data && response.data.card_number) {
        return response.data.card_number;
      } else {
        throw new Error('card number not found in API response.');
      }
    } catch (error) {
      console.error('Error fetching card number from API: ', error);
      throw error;
    }
  }

  async updateDependent(data: {
    birthdate: string;
    name: string;
    cpf: string;
    email: string;
    Telefone: string;
    sexo: string;
  }): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    return this.instanceR.post('tem_alteracao', {
      tokenzeus,
      CodOnix: onix,
      data_nascimento: format(parse(data.birthdate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      Nome: data.name,
      cpf: extractDigits(data.cpf),
      email: data?.email ?? '',
      Telefone: extractDigits(data?.Telefone),
      Sexo: data?.sexo === 'MASCULINO' ? 1 : data.sexo === 'FEMININO' ? 0 : 99,
    });
  }

  async deleteDependent(cpf: string): Promise<AxiosResponse<any>> {
    const cleanCpf = extractDigits(cpf);

    const { onix, tokenzeus } = await this.getTokens();
    const { user_token } = await this.getUsertokenCto(cleanCpf);

    return this.instanceR.post('tem_alteracao_status_cto', {
      tokenzeus,
      CodOnix: onix,
      cpf: cleanCpf,
      UserToken: user_token,
      NovoStatus: 3,
    });
  }

  async hasPrivacyPolicy(
    cardNumber: string,
    cpf: string,
    policyType = 'PRIVACIDADE',
  ): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();
    const data = {
      tipo: policyType,
      tokenzeus: tokenzeus,
      cpf: cpf,
      numeroCartao: cardNumber,
      codOnix: onix,
    };

    return this.instanceR.get('tem_politica', { params: data });
  }

  async hasPrivacyPolicyUpdate(
    cpf: string,
    policyType = 'PRIVACIDADE',
  ): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    const data = {
      tipo: policyType,
      tokenzeus: tokenzeus,
      codOnix: onix,
      cpf: extractDigits(cpf),
    };

    return await this.instanceR.get('tem_verifica_politica', { params: data });
  }

  async returnPrivacyPolicy(): Promise<AxiosResponse<any>> {
    const { onix, tokenzeus } = await this.getTokens();

    const data = {
      tipo: 'PRIVACIDADE',
      tokenzeus: tokenzeus,
      codOnix: onix,
    };

    return await this.instanceR.get('tem_politica_vigente', { params: data });
  }

  async acceptPrivacyPolicy(
    cardNumber: string,
    cpf: string,
    ip: string,
    policyType = 'PRIVACIDADE',
  ) {
    const { onix, tokenzeus } = await this.getTokens();

    const { user_token } = await this.getUsertokenCto(extractDigits(cpf));

    const data = {
      userToken: user_token,
      tipo: policyType,
      tokenzeus: tokenzeus,
      cpf: cpf,
      numeroCartao: cardNumber,
      codOnix: onix,
      ip: ip,
    };

    return this.instanceR.post('tem_cadastra_politica', data);
  }

  async searchCertifiedTemRede(
    type: 'clinic' | 'laboratory' | 'ontological_clinic',
    searchParams: SearchCertifiedTemRedePayload,
  ): Promise<AxiosResponse<SearchCertifiedTemRedeRes>> {
    return this.instance.get(`v1/hello/tem-rede/certified/${type}`, {
      params: searchParams,
    });
  }

  async searchNetwork(searchParams: {
    latitude: string;
    longitude: string;
    distancia: number;
    especialidadeMedica: string;
  }) {
    const { tokenzeus } = await this.getTokens();

    return await this.instanceR.get('tem_rede', {
      headers: {
        tokenzeus,
      },
      params: {
        ...searchParams,
        tipoConsulta: 'especialidade',
      },
      paramsSerializer: (params) => {
        let result = '';
        Object.keys(params).forEach((key) => {
          result += `${key}=${encodeURIComponent(params[key])}&`;
        });
        return result.substring(0, result.length - 1);
      },
    });
  }

  async updateTemPayPassword(cardNumber: string, password: string): Promise<AxiosResponse<any>> {
    return this.instance.put(`v1/hello/cards/${cardNumber}/new-password`, {
      password,
    });
  }

  async telemedicine24h(
    cpf: string,
    birthDate: string,
    name: string,
    onix: string,
    dependentCpf?: string | null,
  ): Promise<AxiosResponse<CreateTelemedicine24h>> {
    const titularCPF = cpf?.replace(/[^\d]/g, '');
    const dependentCpfCleared = dependentCpf?.replace(/[^\d]/g, '');
    const { tokenzeus } = await this.getTokens();

    let userCpf = titularCPF;
    if (dependentCpfCleared !== undefined) {
      userCpf = titularCPF === dependentCpfCleared ? titularCPF : dependentCpfCleared;
    }

    return await this.instance.post('v1/hello/tem-agenda/appointment/telemedicine24h', {
      tokenzeus,
      cpf: userCpf,
      birthDate,
      name,
      onix,
    });
  }

  async telemedicine24hUrl(patientId: number): Promise<AxiosResponse<GetQueue>> {
    return await this.instance.get(`v1/hello/tem-agenda/appointment/telemedicine24h/${patientId}`);
  }

  async telemedicine24hCancelQueue(patientId: number): Promise<AxiosResponse<GetQueue>> {
    return await this.instance.delete(
      `v1/hello/tem-agenda/appointment/telemedicine24h/${patientId}`,
    );
  }

  async getAddressByCep(cep: string): Promise<AxiosResponse<any>> {
    return await this.instance.get(`v1/hello/address-bycep/${cep}`);
  }

  async accessByProduct(cpf?: string, _onix?: object): Promise<void> {
    const { tokenzeus, onix } = await this.getTokens();

    const codOnix = AuthService.getSelectedOnix();

    if (onix) {
      await this.instanceR.get('tem_registra_login', {
        params: {
          cpf: extractDigits(cpf),
          canal_login: 'helloapp',
          codOnix,
          tokenzeus,
        },
      });
    }
  }

  async getOTPKey(): Promise<AxiosResponse<any>> {
    return this.instance.get('v1/hello/authorization/otp-token');
  }

  async accessesBrasilTelecom(
    name: string,
    cpf: string,
    email: string,
    phone_number: string,
    gender: string,
    birthdate: string,
    zip_code: string,
    address: string,
    number: string,
    neighborhood: string,
    city: string,
    state: string,
    pay_type: number,
    onix_code: string,
  ): Promise<AxiosResponse<any>> {
    const config = {
      method: 'post',
      url: 'https://plataforma.medico24hs.com.br/api/integration/auth',
      headers: {
        Authorization: 'apikey',
        user: 'temsaude',
        pass: 'y1sG4Y^a~kpY=|CCu7MNBOB-^pB6pGGe,?E%vFL.',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        name,
        cpf: extractDigits(cpf),
        desconto: 0,
        type: pay_type,
        email,
        phone_number,
        gender,
        birthdate,
        zip_code,
        address,
        number,
        neighborhood,
        city,
        state,
        integration_extra_data: onix_code,
      }),
    };

    return await axios(config);
  }

  async getUsertokenCto(cpf: string): Promise<{ user_token: string; tipo_usuario: string }> {
    const cpfCleared = extractDigits(cpf);
    const tokens = await this.getTokens();
    return await this.instanceR
      .post(
        'tem_usertoken_cto',
        {
          tokenzeus: tokens.tokenzeus,
          CodOnix: tokens.onix,
          cpf: cpfCleared,
        },
        { params: { token: tokens.tokenzeus } },
      )
      .then((resp: { data: { user_token: string; tipo_usuario: string } }) => resp?.data);
  }

  // Calls End-points Olimpo >> Conecta

  async getScheduleDays(month: string, specialtyId: string): Promise<AxiosResponse<any>> {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
      provider: 'conecta',
      date: month, //2023-03
      specialtyId,
      onix: newTokens?.onix,
    };

    return this.instanceAppointment.get('/v1/shedule/days', { params: data });
  }

  async getScheduleTimes(day: string, specialtyId: string): Promise<AxiosResponse<any>> {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
      date: day, //2023-03-23
      specialtyId,
      onix: newTokens?.onix,
    };

    return this.instanceAppointment.get('/appointment/schedules', {
      params: data,
    });
  }

  async getSchedulesConecta(cpf: string): Promise<AxiosResponse<any>> {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
      cpf,
      onix: newTokens.onix,
    };

    return this.instanceAppointment.get('/v1/conecta/appointments', { params: data });
  }

  async createScheduleConecta(cpf: string): Promise<AxiosResponse<any>> {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
      cpf,
      onix: newTokens.onix,
    };

    return this.instanceAppointment.post('/v1/conecta/appointments', { params: data });
  }

  async cancelScheduleConecta(cpf: string): Promise<AxiosResponse<any>> {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
      cpf,
      onix: newTokens.onix,
    };

    return this.instanceAppointment.delete('/v1/conecta/appointments', {
      params: data,
    });
  }

  async getConectaAppointments(cpf: string, onix: string) {
    const cpfCleared = extractDigits(cpf);
    const tokens = await this.getTokens();

    return await this.instanceAppointment.get('/v1/conecta/appointments', {
      params: {
        tokenzeus: tokens?.tokenzeus,
        token: tokens?.tokenzeus,
        cpf: cpfCleared,
        onix,
      },
    });
  }

  async getWomensHealthBella(
    name: string,
    email: string,
    telephone: string,
    cpf: string,
    birthday: string,
  ) {
    return await this.instance.post('/v1/hello/bella/link', {
      name,
      email,
      telephone,
      cpf: cpf?.match(/\d/g)?.join(''),
      birthday: format(parseISO(birthday), 'yyyy-MM-dd'),
    });
  }

  async getWebViewManu(cpf: string) {
    return await this.instance.post('/v1/hello/namu/link', {
      cpf: cpf?.match(/\d/g)?.join(''),
    });
  }
  async cancelConectaAppointment(id: string) {
    const tokens = await this.getTokens();
    return await this.instanceAppointment.delete(`/appointment/${id}`, {
      params: {
        token: tokens.tokenzeus,
        tokenzeus: tokens?.tokenzeus,
        onix: tokens?.onix,
      },
    });
  }

  async getMedexLink(cpf: string, onix: string) {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
    };
    return await this.instanceAppointment.get(`appointment/medex/online-schedule/${cpf}/${onix}`, {
      params: data,
    });
  }

  async validateDeepLink(deeplinkCode: string): Promise<AxiosResponse<ValidateDeeplinkRes>> {
    return await this.instance.get('/v1/hello/validate_deep_link', {
      params: {
        code: deeplinkCode,
      },
    });
  }

  async getMedexLink(cpf: string, onix: string) {
    const newTokens = await this.getTokens();

    const data = {
      token: newTokens.tokenzeus,
      tokenzeus: newTokens.tokenzeus,
    };
    return await this.instanceAppointment.get(`/appointment/medex/online-schedule/${cpf}/${onix}`, {
      params: data,
    });
  }

  async updateDeepLinkFirstAccessData(data: { phone?: string; email?: string; gender?: string }) {
    const tokens = await this.getTokens();
    return await this.instance.put('/v1/hello/first_access_update_data', {
      ...data,
      tokenZeus: tokens.tokenzeus,
    });
  }

  async updatePhoneFirstAccess(data: { phone?: string; cpf?: string }) {
    return await this.instance.put('/v1/hello/first_access_update_phone', {
      ...data,
    });
  }

  async sendSmsConfirmation(data: { cpf?: string; onix_code?: string; message?: string }) {
    return this.instance.post('v1/hello/send-sms-confirmation', {
      ...data,
    });
  }
}
