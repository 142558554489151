import React from 'react';
import * as S from './styles';
import { useTheme } from 'styled-components/native';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { ItemType } from './types/itemsTypes';
import { StepperListType } from './types';
import { useTracking } from '~/services/tracking';
import { CardButton, Typography } from '~/components/@hello-ui';

export const StepperList = ({ items, title, description }: StepperListType): JSX.Element => {
  const theme = useTheme();

  const { toStepper, setStepData } = useStepper();
  const { tracking } = useTracking();

  return (
    <S.WrapperSchedule>
      <S.WrapperTitle>
        <Typography variant="subtitle" color={theme.colors.black}>
          {title}
        </Typography>
        <Typography variant="body2" color={theme.colors.black70}>
          {description ?? ''}
        </Typography>
      </S.WrapperTitle>
      {items.map(
        (schedule: ItemType, idx: number) =>
          schedule.visible && (
            <S.WrapperScheduleCard key={idx}>
              <CardButton
                key={`card-${idx}`}
                variant="xlarge"
                title={schedule.title}
                description={schedule.description}
                icon={schedule.icon}
                onPress={() => {
                  if (schedule.tracking) {
                    void tracking.addMovement({
                      event: schedule.tracking,
                    });
                  }

                  if (!schedule.disabled && !schedule.onPress) {
                    toStepper(idx);
                    setStepData(schedule);
                  } else {
                    schedule.onPress ? schedule.onPress() : null;
                  }
                }}
              />
            </S.WrapperScheduleCard>
          ),
      )}
    </S.WrapperSchedule>
  );
};
