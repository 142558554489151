import React, { useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PageWithCardProps } from '~/components/@hello-ui/PageWithCard/types';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';
import { FeaturedHeader } from '~/components/@hello-ui/PageWithCard/components/FeaturedHeader';
import { PageScroll } from '~/components/@hello-ui/PageWithCard/components/PageScroll';
import { Page } from '~/components/@hello-ui/PageWithCard/components/Page';
import { BodyScroll } from '~/components/@hello-ui/PageWithCard/components/BodyScroll';
import { PageWithCardProvider } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { ContentLimit } from '~/components/@hello-ui/PageWithCard/components/ContentLimit';
import { PageLimit } from '~/components/@hello-ui/PageWithCard/components/PageLimit';

export const PageWithCardFeaturedImage = ({
  overridePadding,
  onScrollViewRefUpdate,
  overrideBodyMaxWidth,
  overrideScrollEnabled,
  overrideHeader,
  featuredImageOptions,
  children,
}: PageWithCardProps) => {
  const pageScrollViewRef = useRef<ScrollView>(null);
  const bodyScrollRef = useRef<ScrollView>(null);
  const { isMobile } = useTheme();

  const { backgroundImage, onBack, titleStartContent, title } = featuredImageOptions!;

  useEffect(() => {
    onScrollViewRefUpdate?.(pageScrollViewRef);
  }, [pageScrollViewRef.current]);

  return (
    <PageWithCardProvider
      pageScroll={pageScrollViewRef}
      bodyScroll={bodyScrollRef}
      currentScroll={pageScrollViewRef}>
      <CustomKeyboardAvoidingView>
        <PageScroll
          scrollEnabled={overrideScrollEnabled !== undefined ? overrideScrollEnabled : true}
          ref={pageScrollViewRef}>
          <Page>
            <PageLimit>
              {overrideHeader ? (
                overrideHeader()
              ) : (
                <FeaturedHeader
                  backgroundImage={backgroundImage}
                  title={title}
                  onBack={onBack}
                  titleStartContent={titleStartContent}
                />
              )}

              <BodyScroll
                topBorderRadius={isMobile ? 12 : 0}
                ref={bodyScrollRef}
                scrollEnabled={overrideScrollEnabled !== undefined ? overrideScrollEnabled : true}
                overridePadding={overridePadding}>
                <ContentLimit overrideBodyMaxWidth={overrideBodyMaxWidth}>{children}</ContentLimit>
              </BodyScroll>
            </PageLimit>
          </Page>
        </PageScroll>
      </CustomKeyboardAvoidingView>
    </PageWithCardProvider>
  );
};
