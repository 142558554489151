import React, { useEffect, useCallback, useState } from 'react';
import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { useSweetAlert } from '../../components/@tem-ui';
import desktopBackground from '../../assets/images/benefitsCapitalization/desktop.png';
import mobileBackground from '../../assets/images/benefitsCapitalization/mobile.png';
import { Number } from './components/Number';
import { PrizeListTypes } from './types';

import { useApi } from '~/hooks/api';
import { useTemPay } from '~/hooks/temPay';
import { PageWithCard, Typography, Collapse } from '~/components/@hello-ui';
import { PrizeIcon } from '~/components/Icons/Prize';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsCapitalization = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();
  const navigation = useNavigation();

  const { card } = useTemPay();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState<boolean>(true);

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const [prize, setPrize] = useState<number>(0);
  const [luckyNumber, setLuckyNumber] = useState<string | null>(null);

  const getLuckyNumber = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api.luckyNumber(card?.numero_cartao ?? '');

      if (data.length) {
        const numbers: PrizeListTypes[] = data.sort(
          (a: PrizeListTypes, b: PrizeListTypes) => new Date(b.endDate) - new Date(a.endDate),
        );

        setLuckyNumber(numbers[0].number);
        setPrize(numbers[0].prize);
      }
    } catch (err) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível carregar seu número. Que tal tentar novamente?',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              variant: 'primary',
              text: 'Ok',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );
    } finally {
      setLoading(false);
    }
  }, [card]);

  useEffect(() => {
    if (card) void getLuckyNumber();
  }, [card]);

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        title: 'Número da Sorte',
        titleStartContent: <PrizeIcon color={theme.colors.white} />,
        onBack: () => {
          navigation.navigate('BenefitsFinancial');
        },
      }}>
      <View>
        <Typography variant="body2">
          Participe do <Typography variant="bodyHighlight2">sorteio mensal</Typography> com seu
          número da sorte e concorra a{' '}
          <Typography variant="bodyHighlight2">prêmios em dinheiro</Typography> pela loteria
          federal.
        </Typography>
      </View>
      <Number luckyNumber={luckyNumber} prize={prize} loading={loading} />
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">
              Seu número da sorte foi{' '}
              <Typography variant="bodyHighlight2">ativado automaticamente</Typography> ao realizar
              o primeiro acesso no App TEM Saúde ou na área logada e você{' '}
              <Typography variant="bodyHighlight2">já está concorrendo aos sorteios </Typography>
              que acontecem no último sábado de cada mês pela loteria federal.
            </Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2">
              Você também receberá seu número da sorte por SMS no telefone cadastrado.
            </Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2">
              Caso você seja o vencedor do sorteio, entraremos em contato solicitando a documentação
              necessária para o pagamento. O prêmio será pago em um{' '}
              <Typography variant="bodyHighlight2">prazo máximo de 15 dias corridos</Typography>, a
              contar da data de recebimento de toda a documentação.
            </Typography>
          </View>
          <View className="mb-12">
            <Typography variant="body2">
              <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Mantenha seus dados
              cadastrais atualizados.
            </Typography>
          </View>
        </Collapse>
      </View>
    </PageWithCard>
  );
};
