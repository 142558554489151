import React from 'react';
import Select, { DropdownIndicatorProps, StylesConfig, components } from 'react-select';
import Icon from 'react-native-vector-icons/dist/Feather';
import { DefaultTheme, useTheme } from 'styled-components/native';
import { StyledDefaultTheme } from '../Theme';
import { SelectItem, SelectWithSearchProps } from './types';

export const SelectWithSearch = ({
  items,
  placeholder,
  onSelect,
  value,
  disabled,
  reference,
  onFocus,
  search,
}: SelectWithSearchProps) => {
  const theme = useTheme();

  return (
    <Select
      onFocus={onFocus}
      options={items}
      isDisabled={disabled}
      value={value ?? null}
      onChange={(option) => {
        onSelect && onSelect(option as SelectItem);
      }}
      isSearchable={search}
      placeholder={placeholder}
      noOptionsMessage={() => ''}
      components={{
        DropdownIndicator: DropDownIndicator,
      }}
      ref={reference}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      styles={makeStyles(theme)}
    />
  );
};

const DropDownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name={props.isFocused ? 'chevron-up' : 'chevron-down'}
        size={24}
        color={StyledDefaultTheme?.colors.primary}
      />
    </components.DropdownIndicator>
  );
};

function makeStyles(theme: DefaultTheme) {
  const styles: StylesConfig = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: theme.colors.white,
      borderRadius: '8px',
      cursor: 'pointer',
      span: {
        display: 'none',
      },
      boxShadow: 'none',
      borderColor: isDisabled ? theme.colors['gray-disabled'] : theme.colors.placeholder,
      borderWidth: 1,
      '&:hover': {
        boxShadow: 'none',
        borderColor: 'none',
      },
    }),
    option: (styles) => ({
      ...styles,
      color: theme.colors.black80,
      padding: '14px',
      margin: 0,
      fontWeight: 400,
      cursor: 'pointer',
      backgroundColor: theme.colors.white,
      borderBottom: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.background,
      fontFamily: 'Poppins !important',
    }),
    menu: (styles) => ({ ...styles, borderRadius: '8px', backgroundColor: theme.colors.white }),
    input: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? theme.colors['gray-disabled'] : theme.colors.paragraph,
      fontSize: theme.isMobile ? 16 : 18,
      lineHeight: '28px',
      margin: 0,
      padding: theme.isMobile ? '11px 0px 11px 15px' : '15px 0px 15px 15px',
      ':focus': {
        border: 'none',
        backgroundColor: 'red',
      },
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({ ...styles, opacity: isDisabled ? 0.5 : 1 }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? theme.colors['gray-disabled'] : theme.colors.paragraph,
      fontSize: theme.isMobile ? 16 : 18,
      padding: theme.isMobile ? '11px 0px 11px 15px' : '15px 0px 15px 15px',
      lineHeight: '28px',
    }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? theme.colors['gray-disabled'] : theme.colors.placeholder,
      marginLeft: 16,
      fontFamily: 'Poppins',
      fontSize: theme.isMobile ? 16 : 18,
    }),
    container: (styles) => ({ ...styles, fontFamily: 'Poppins !important' }),
  };

  return styles;
}
