import React from 'react';
import { Platform, Switch } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ToggleContainer } from './styles';

interface CustomToggleProps {
  value: boolean;
  disabled?: boolean;
  toggleSwitch: () => void;
}

export const Toggle: React.FC<CustomToggleProps> = ({
  disabled = false,
  value = false,
  toggleSwitch,
}) => {
  const theme = useTheme();

  const customStyleWeb =
    Platform.OS === 'web'
      ? {
          activeThumbColor: theme.colors.primary,
        }
      : {};

  return (
    <ToggleContainer>
      <Switch
        disabled={disabled}
        trackColor={{ false: theme.colors.gray60, true: theme.colors.gray60 }}
        thumbColor={value ? theme.colors.primary : theme.colors.gray}
        ios_backgroundColor={theme.colors.white}
        {...customStyleWeb}
        onValueChange={toggleSwitch}
        value={value}
      />
    </ToggleContainer>
  );
};
