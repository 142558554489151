import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from '~/components/Icons/types';
const TestTubeIcon = ({ color, width, height }: IconProps) => (
  <Svg width={width ?? 30} height={height ?? 27} fill="none">
    <Path
      fill={color ?? '#EC6338'}
      d="M12.338 7.916a.596.596 0 0 1 0-.849l.056-.056a.614.614 0 0 1 .845.015.595.595 0 0 1 .015.834l-.057.056a.613.613 0 0 1-.86 0Zm9.219 3.286L8.61 23.975a4.765 4.765 0 0 1-3.404 1.562 4.772 4.772 0 0 1-3.487-1.373 4.654 4.654 0 0 1-1.384-3.447 4.661 4.661 0 0 1 1.587-3.36L12.366 7.038a.625.625 0 0 1 .873-.013.574.574 0 0 1 .015.834L2.782 18.206a3.463 3.463 0 0 0-1.1 2.48 3.46 3.46 0 0 0 1.034 2.51 3.547 3.547 0 0 0 2.54 1.018 3.546 3.546 0 0 0 2.511-1.088l.876-.866-2.105-2.079a.596.596 0 0 1 .03-.816.614.614 0 0 1 .826-.034l2.105 2.08 1.695-1.675-2.105-2.08a.596.596 0 0 1 .015-.834.613.613 0 0 1 .845-.014l2.113 2.07 1.695-1.674-2.105-2.075a.596.596 0 0 1 .015-.835.614.614 0 0 1 .845-.015l2.105 2.08 1.7-1.675-2.11-2.08a.595.595 0 0 1 .015-.834.613.613 0 0 1 .845-.015l2.105 2.08 3.37-3.33-4.984-4.92-.892.873c-.26.279-2.045 2.237-2.441 2.04-.581.197-.672.118-.793 0-.12-.117-.402.168-.396 0 .005-.167 2.638-2.771 2.766-2.881l1.05-1.038a2.8 2.8 0 0 1 .165-2.497 2.885 2.885 0 0 1 4.504-.589l5.163 5.097a2.808 2.808 0 0 1-.597 4.454 2.896 2.896 0 0 1-2.53.158Zm-5.24-6.555 5.175 5.096a1.662 1.662 0 0 0 1.592.422 1.639 1.639 0 0 0 1.166-1.152 1.616 1.616 0 0 0-.426-1.573L18.66 2.343a1.668 1.668 0 0 0-1.596-.427c-.571.15-1.018.59-1.173 1.152a1.62 1.62 0 0 0 .424 1.58Zm13.347 17.269a3.933 3.933 0 0 1-1.127 2.88A4.031 4.031 0 0 1 25.66 26a4.032 4.032 0 0 1-2.878-1.204 3.933 3.933 0 0 1-1.127-2.88c0-2.056 3.245-5.065 3.593-5.401a.614.614 0 0 1 .812 0c.397.34 3.605 3.345 3.605 5.4Zm-1.216 0c0-1.034-1.587-2.925-2.803-4.123-1.217 1.202-2.79 3.089-2.79 4.123a2.738 2.738 0 0 0 .777 2.017c.527.54 1.252.844 2.01.844.76 0 1.485-.305 2.011-.845s.807-1.268.778-2.016h.017Z"
    />
    <Path
      stroke={color ?? '#EC6338'}
      strokeWidth={0.2}
      d="M13.24 7.026a.614.614 0 0 0-.846-.015l-.056.056a.596.596 0 0 0 0 .85.613.613 0 0 0 .86 0l.056-.057m-.015-.834a.595.595 0 0 1 .015.834m-.015-.834a.625.625 0 0 0-.873.013L1.922 17.356a4.661 4.661 0 0 0-1.587 3.36 4.654 4.654 0 0 0 1.384 3.448 4.772 4.772 0 0 0 3.487 1.373 4.765 4.765 0 0 0 3.404-1.562l12.947-12.773c.822.34 1.758.281 2.53-.158a2.808 2.808 0 0 0 .596-4.454L19.52 1.494a2.885 2.885 0 0 0-4.503.588 2.8 2.8 0 0 0-.165 2.497l-1.05 1.038c-.128.11-2.76 2.714-2.766 2.882-.006.167.275-.118.396 0 .12.117.212.196.793 0 .396.196 2.18-1.762 2.44-2.04l.893-.874 4.984 4.92-3.37 3.33-2.105-2.08a.613.613 0 0 0-.845.015.595.595 0 0 0-.014.834l2.109 2.08-1.7 1.675-2.105-2.08a.614.614 0 0 0-.845.015.596.596 0 0 0-.015.835l2.105 2.075-1.695 1.675-2.113-2.072a.613.613 0 0 0-.845.015.596.596 0 0 0-.015.835l2.105 2.08-1.695 1.674-2.105-2.08a.614.614 0 0 0-.826.034.596.596 0 0 0-.03.816l2.105 2.08-.876.865a3.546 3.546 0 0 1-2.51 1.088 3.547 3.547 0 0 1-2.541-1.018 3.46 3.46 0 0 1-1.033-2.51c.012-.939.409-1.834 1.1-2.48L13.253 7.86m-.015-.834a.574.574 0 0 1 .015.834m3.062-3.213 5.176 5.096a1.662 1.662 0 0 0 1.592.422 1.639 1.639 0 0 0 1.166-1.152 1.616 1.616 0 0 0-.426-1.573L18.66 2.343a1.668 1.668 0 0 0-1.596-.427c-.571.15-1.018.59-1.173 1.152a1.62 1.62 0 0 0 .424 1.58Zm13.348 17.269a3.933 3.933 0 0 1-1.127 2.88A4.032 4.032 0 0 1 25.66 26a4.032 4.032 0 0 1-2.878-1.204 3.933 3.933 0 0 1-1.127-2.88c0-2.056 3.245-5.065 3.593-5.401a.614.614 0 0 1 .812 0c.397.34 3.605 3.345 3.605 5.4Zm-1.216 0c0-1.034-1.587-2.925-2.803-4.123-1.217 1.202-2.79 3.089-2.79 4.123a2.738 2.738 0 0 0 .777 2.017c.527.54 1.252.844 2.01.844.76 0 1.485-.305 2.011-.845s.807-1.268.778-2.016h.017Z"
    />
  </Svg>
);
export default TestTubeIcon;
