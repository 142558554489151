import React, { useEffect, useState, createContext, useContext } from 'react';
import { MenuProviderTypes, MenuContextTypes } from './types';

const MenuContext = createContext<MenuContextTypes | null>(null);

export const MenuProvider = ({ children }: MenuProviderTypes): JSX.Element => {
  const [menuTitle, setMenuTitle] = useState<string | undefined>('');
  const [menuState, setMenuState] = useState<boolean>(false);
  const [menuContent, setMenuContent] = useState<JSX.Element | undefined>(<></>);

  const closeMenu = () => setMenuState(false);

  const openMenu = (title: string, component: JSX.Element) => {
    setMenuTitle(title);
    setMenuContent(component);
    setMenuState(true);
  };

  useEffect(() => {
    if (!menuState) {
      setMenuTitle(undefined);
      setMenuContent(undefined);
    }
  }, [menuState]);

  return (
    <MenuContext.Provider
      value={{
        menuState,
        menuTitle,
        menuContent,
        closeMenu,
        openMenu,
      }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = (): MenuContextTypes => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }

  return context;
};
