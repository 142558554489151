import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { IRefreshedTokens } from '~/auth/interfaces/refreshed-tokens';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class UnauthenticatedStrategy extends BaseStrategy {
  static StrategyName = EAuthStrategy.Unauthenticated;

  getGateKeepers(): IGatekeeper[] {
    return [];
  }

  authenticate(): Promise<void> {
    return Promise.resolve(undefined);
  }

  override async refreshToken(): Promise<IRefreshedTokens> {
    return Promise.resolve({ token: null, refreshToken: null });
  }

  override async onBeforeTokenExpire(): Promise<IRefreshedTokens> {
    return Promise.resolve({ token: null, refreshToken: null });
  }

  override async selectFirstProduct() {
    console.warn('Tried select first product on unauthenticated strategy');
  }

  override async selectProduct() {
    console.warn('Tried select product on unauthenticated strategy');
  }

  override async updateAllAuthData() {}

  override async updateMyDependents() {}

  override async updateMyProducts() {}

  override async updateProductConfig() {}

  override mountAuthorizedRoutes(): Routes[] {
    return ['Login', 'PhoneNotFoundWebChat'];
  }
}
