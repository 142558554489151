import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';

import { useAuth } from '~/auth/legacy/useAuth';
import { ElectricianIcon } from '~/components/Icons/Electrician';
import { KeyIcon } from '~/components/Icons/Key';
import { PlumberIcon } from '~/components/Icons/Plumber';
import { HomeApplianceIcon } from '~/components/Icons/HomeAppliance';

export const BenefitsResidentialAssistant = (): JSX.Element => {
  const navigation = useNavigation();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <KeyIcon />,
          title: 'Chaveiro',
          description: 'Para arrombamento, roubo, furto ou conserto',
          onPress: () => navigation.navigate('BenefitsKeychain'),
          visible: checkIsEnabledFeature('residential_assistant'),
          iconSideOfTitle: true,
        },
        {
          icon: <PlumberIcon />,
          title: 'Encanador',
          description: 'Para alagamentos e problemas hidráulicos',
          onPress: () => navigation.navigate('BenefitsResidentialAssistancePlumber'),
          visible: checkIsEnabledFeature('residential_assistant'),
          iconSideOfTitle: true,
        },
        {
          icon: <ElectricianIcon />,
          title: 'Eletricista',
          description: 'Para danos elétricos da residência cadastrada',
          onPress: () => navigation.navigate('BenefitsResidentialAssistanceElectrician'),
          visible: checkIsEnabledFeature('residential_assistant'),
          iconSideOfTitle: true,
        },
        {
          icon: <HomeApplianceIcon />,
          title: 'Conserto de Eletrodomésticos',
          description: 'Para conserto de eletrônicos da residência cadastrada',
          onPress: () => navigation.navigate('BenefitsResidentialAssistanceHomeAppliance'),
          visible: checkIsEnabledFeature('residential_assistant'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Assistência Residencial',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
