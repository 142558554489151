import { useAuthStore } from '~/store/auth.store';
import { IGatekeeper, IGatekeeperContext } from '~/auth/interfaces/gatekeeper';

export class CompleteDataGatekeeper implements IGatekeeper {
  async canPass(ctx: IGatekeeperContext) {
    const user = useAuthStore.getState().user;
    const firstAccess = useAuthStore.getState().isFirstAccess;

    if (!user) {
      await ctx.strategy.onLogout();
      console.warn('User not found on CompleteDataGatekeeper. Logging out.');
      return false;
    }

    return !!user.email && !!user.addresses_id_fk && !firstAccess;
  }

  getGatekeeperRoutes(): Routes[] {
    return ['CompleteData', 'Loading'];
  }
}
