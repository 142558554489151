import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { Typography } from '../Typography';
import { CollapseTypes } from './types';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from './styles';
import { useTheme } from 'styled-components/native';

export const Collapse = ({ title, children }: CollapseTypes): JSX.Element => {
  const theme = useTheme();
  const [toggle, setToggle] = useState<boolean>(false);
  const animatedValue = useRef(new Animated.Value(0)).current;

  const rotate = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  useEffect(() => {
    Animated.spring(animatedValue, {
      toValue: toggle ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [toggle]);

  return (
    <S.Wrapper>
      <S.WrapperHeader onPress={() => setToggle(!toggle)}>
        <Typography variant="paragraph" color="black70" bold>
          {title}
        </Typography>
        <S.WrapperHeaderIcon style={{ transform: [{ rotate }] }}>
          <Icon name="chevron-down" size={24} color={theme?.colors?.primary} />
        </S.WrapperHeaderIcon>
      </S.WrapperHeader>
      {toggle && <S.WrapperContent>{children}</S.WrapperContent>}
    </S.Wrapper>
  );
};
