import React from 'react';
import { Circle, ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const RechargeIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_840_5273)">
        <Circle cx="12" cy="12" r="11" stroke={color ?? theme.colors.primary} strokeWidth="1.3" />
        <Path
          d="M15.616 14.228C15.616 14.6947 15.4993 15.1333 15.266 15.544C15.042 15.9547 14.706 16.3 14.258 16.58C13.8193 16.86 13.2967 17.028 12.69 17.084V18.218H11.78V17.084C10.9027 17 10.1933 16.7247 9.652 16.258C9.11067 15.7913 8.84 15.1753 8.84 14.41H10.548C10.576 14.774 10.6927 15.0773 10.898 15.32C11.1033 15.5533 11.3973 15.7027 11.78 15.768V12.688C11.1547 12.5293 10.646 12.3707 10.254 12.212C9.862 12.044 9.526 11.7827 9.246 11.428C8.97533 11.0733 8.84 10.588 8.84 9.972C8.84 9.19733 9.11067 8.558 9.652 8.054C10.1933 7.54067 10.9027 7.242 11.78 7.158V6.024H12.69V7.158C13.5113 7.23267 14.1693 7.494 14.664 7.942C15.168 8.38067 15.4527 8.98733 15.518 9.762H13.81C13.782 9.47267 13.67 9.216 13.474 8.992C13.278 8.75867 13.0167 8.6 12.69 8.516V11.54C13.3153 11.6893 13.824 11.848 14.216 12.016C14.608 12.1747 14.9393 12.4313 15.21 12.786C15.4807 13.1313 15.616 13.612 15.616 14.228ZM10.464 9.888C10.464 10.2707 10.576 10.5693 10.8 10.784C11.0333 10.9987 11.36 11.1713 11.78 11.302V8.474C11.3787 8.52067 11.0567 8.66533 10.814 8.908C10.5807 9.15067 10.464 9.47733 10.464 9.888ZM12.69 15.768C13.1007 15.7027 13.4227 15.5393 13.656 15.278C13.8893 15.0073 14.006 14.6853 14.006 14.312C14.006 13.9387 13.8893 13.6493 13.656 13.444C13.432 13.2293 13.11 13.0567 12.69 12.926V15.768Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_840_5273">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default RechargeIcon;
