import React from 'react';
import { Wrapper } from '../../components/@tem-ui';
import { Header } from '../../components/Header';
import { Presentation } from './components/Presentation';
import { Footer } from '../../components/Footer';
import * as S from './styles';
import { useMedia } from '~/components/@hello-ui';

export const TemPayPresentation = () => {
  const media = useMedia();
  return (
    <>
      {!media.isMobile && <Header />}
      <Wrapper>
        <S.WrapperContent>
          <Presentation />
        </S.WrapperContent>
        <Footer />
      </Wrapper>
    </>
  );
};
