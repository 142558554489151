import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';
import { IUser } from '~/@types/auth/user';
import { IOlimpoRes } from '~/services/api/olimpo/interfaces/olimpo-res';
import { IAxiosMyProductsRes } from '~/services/api/olimpo/interfaces/profile-domain';
import { IProduct } from '~/@types/auth/product';

export class ProfileDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async getMyProfile() {
    const res = await this.olimpoService.axios.get<IOlimpoRes<IUser>>('/v1/hello/profile');
    return res.data.data;
  }

  async getMyProducts(): Promise<IProduct[]> {
    const res = await this.olimpoService.axios.get<IOlimpoRes<IAxiosMyProductsRes[]>>(
      '/v1/hello/profile/my-products',
    );

    const products = res.data.data.map((product) => ({
      name: product.name,
      onixCode: product.onix_code,
      cnpj: product.cnpj,
      slug: product.slug,
    }));

    return products;
  }
}
