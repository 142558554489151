import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { Button, Typography } from '~/components/@hello-ui';
import { usePageWithCardSettings } from '~/components/@tem-ui/Stepper';
import { Map } from '~/components/@hello-ui/Map';
import { LocationCardList } from '~/components/LocationCardList';
import { HomeButton } from '~/components/MapViewList/styles';

export interface ListItemAddress {
  number: string;
  street: string;
  city: string;
  neighbourhood: string;
  state: string;
  zipCode: string;
  lat: string;
  long: string;
}

export interface ListItem<TData> {
  name: string;
  address: ListItemAddress;
  telephone?: string;
  data?: TData;
}

interface MapViewListProps<TData> {
  title: string;
  onClickCard?: (item: ListItem<TData>) => void;
  data?: ListItem<TData>[];
  hasButton?: boolean;
  showHomeButton?: boolean;
  onHomePress?: () => void;
  loading?: boolean;
}

enum DisplayType {
  List = 'list',
  Map = 'map',
}

export const MapViewList = <TData,>({
  title,
  data = [],
  loading,
  onClickCard,
  showHomeButton,
  onHomePress,
  hasButton = true,
}: MapViewListProps<TData>): JSX.Element => {
  usePageWithCardSettings({
    scrollEnabled: false,
    customPadding: { top: 0, left: 0, right: 0, bottom: 0 },
  });

  const theme = useTheme();

  const [displayType, setDisplayType] = useState(DisplayType.List);

  const switchDisplayType = () => {
    setDisplayType((displayType) =>
      displayType === DisplayType.List ? DisplayType.Map : DisplayType.List,
    );
  };

  return (
    <>
      <View className="px-16 pt-16 desktop:px-96 desktop:pt-40">
        <Typography variant="title">{title}</Typography>

        <View className="mt-16 flex-row">
          <Button
            testID="map-list-toggle-button"
            style={{
              height: 45,
              borderRadius: 8,
              width: 'auto',
            }}
            variant="simple"
            icon={
              <FeatherIcon
                name={displayType === DisplayType.Map ? 'list' : 'map'}
                color={theme.colors.primary}
                size={18}
              />
            }
            textStyle={{
              lineHeight: 18,
            }}
            onPress={switchDisplayType}>
            {`visualizar ${displayType === DisplayType.Map ? 'lista' : 'mapa'}`}
          </Button>
          {showHomeButton && (
            <HomeButton onPress={onHomePress}>
              <View className="flex-row items-center">
                <View className="mr-4">
                  <MaterialCommunityIcon
                    name="home-outline"
                    color={theme.colors.primary}
                    size={24}
                  />
                </View>

                <Typography variant="link">Início</Typography>
              </View>
            </HomeButton>
          )}
        </View>
      </View>

      {displayType === DisplayType.List ? (
        <View className="mt-20 flex-1 basis-auto mobile:mt-8">
          <LocationCardList
            loading={loading}
            data={data}
            onPress={onClickCard}
            hasButton={hasButton}
          />
        </View>
      ) : (
        <View className="mt-24 flex-1 mobile:mt-16">
          <Map loading={loading} data={data} onPress={onClickCard} clickEnabled={hasButton} />
        </View>
      )}
    </>
  );
};
