import React, { createContext, PropsWithChildren, RefObject, useState } from 'react';
import { ScrollView } from 'react-native';

const HEADER_DEFAULT_HEIGHT = 61;

export interface IPageWithCardContext {
  headerHeight: number;
  setHeaderHeight: (newValue: number) => void;
  pageScroll: RefObject<ScrollView>;
  bodyScroll: RefObject<ScrollView>;
  currentScroll: RefObject<ScrollView>;
}

export const PageWithCardContext = createContext<IPageWithCardContext>({
  headerHeight: HEADER_DEFAULT_HEIGHT,
  setHeaderHeight: () => {},
  pageScroll: { current: null },
  bodyScroll: { current: null },
  currentScroll: { current: null },
});

export interface IPageWithCardProviderProps {
  pageScroll: RefObject<ScrollView>;
  bodyScroll: RefObject<ScrollView>;
  currentScroll: RefObject<ScrollView>;
}

export const PageWithCardProvider: React.FC<PropsWithChildren<IPageWithCardProviderProps>> = ({
  children,
  pageScroll,
  bodyScroll,
  currentScroll,
}) => {
  const [headerHeight, setHeaderHeight] = useState(HEADER_DEFAULT_HEIGHT);

  return (
    <PageWithCardContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        pageScroll,
        bodyScroll,
        currentScroll,
      }}>
      {children}
    </PageWithCardContext.Provider>
  );
};
