import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const SoundIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 43 21" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.565 0.967918C21.0154 1.18439 21.3018 1.63989 21.3018 2.13961V30.1425C21.3018 30.6423 21.0154 31.0978 20.565 31.3142C20.1146 31.5307 19.58 31.4699 19.1897 31.1577L9.54477 23.4417H1.99995C1.28198 23.4417 0.699951 22.8597 0.699951 22.1417V10.1405C0.699951 9.42248 1.28198 8.84045 1.99995 8.84045H9.54478L19.1897 1.12448C19.58 0.812306 20.1146 0.751442 20.565 0.967918ZM18.7018 4.84442L10.8129 11.1556C10.5824 11.34 10.296 11.4405 10.0008 11.4405H3.29995V20.8417H10.0008C10.296 20.8417 10.5824 20.9422 10.8129 21.1266L18.7018 27.4377V4.84442Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1416 1.08057C34.6493 0.572969 35.4725 0.573093 35.9801 1.08085C39.9736 5.07558 42.217 10.4929 42.217 16.1414C42.217 21.79 39.9736 27.2073 35.9801 31.202C35.4725 31.7098 34.6493 31.7099 34.1416 31.2023C33.6338 30.6947 33.6337 29.8716 34.1413 29.3638C37.6474 25.8567 39.617 21.1006 39.617 16.1414C39.617 11.1823 37.6474 6.42621 34.1413 2.91905C33.6337 2.41129 33.6338 1.58818 34.1416 1.08057ZM27.0809 8.14131C27.5886 7.63371 28.4117 7.63383 28.9193 8.14159C31.0379 10.2609 32.2281 13.1348 32.2281 16.1314C32.2281 19.1281 31.0379 22.002 28.9193 24.1213C28.4117 24.629 27.5886 24.6292 27.0809 24.1216C26.5731 23.6139 26.573 22.7908 27.0806 22.2831C28.7118 20.6514 29.6281 18.4386 29.6281 16.1314C29.6281 13.8242 28.7118 11.6115 27.0806 9.97979C26.573 9.47203 26.5731 8.64892 27.0809 8.14131Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default SoundIcon;
