import styled from 'styled-components/native';

export const Wrapper = styled.TouchableOpacity`
  border-width: 3px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
`;
