import { navigationRef } from '~/router/navigator';

export const goBack = (navigation: any, fallback: string) => {
  navigation.canGoBack() ? navigation.goBack() : navigation.navigate(fallback);
};

export const mapDependent = (dependent) => ({
  cpf: dependent.CPF_DEPENDENTE,
  onix: dependent.ONIX,
  birthdate: dependent.DATA_NASCIMENTO,
  status: dependent.COD_STATUS,
  desc_status: dependent.DESC_STATUS,
  name: dependent.NOME_DEPENDENTE,
  start_association: dependent.DATA_INICIO_ASSOCIACAO,
  email: dependent.EMAIL,
  Telefone: dependent.TELEFONE,
  sexo: dependent.sexo,
});

export const getSafeNavigator = (): Promise<typeof navigationRef> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (navigationRef.isReady()) {
        interval && clearInterval(interval);
        resolve(navigationRef);
      }
    }, 250);
  });
};
