import React, { PropsWithChildren } from 'react';
import { useContext } from 'react';
import { Dimensions, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { ScrollContext } from '~/contexts/scroll';

export const SafeAreaWrapper = styled.SafeAreaView`
  flex: 1;
`;

export const WrapperE = styled.ScrollView.attrs(({ contentContainerStyle }) => ({
  contentContainerStyle: {
    ...((contentContainerStyle as object) || {}),
    alignItems: 'center',
  },
}))<{ withHeader?: number | undefined }>`
  ${({ withHeader }) => `max-height: ${Dimensions.get('window').height - (withHeader ? 84 : 0)}px;`}
  margin-top: 84px;
  flex-grow: 1;
  ${({ theme: { isMobile }, withHeader }) =>
    isMobile &&
    `
    margin-top: ${withHeader ? 150 : 0}px;
    max-height: ${Dimensions.get('window').height - (withHeader ? 150 : 0)}px;
  `}
  ${({ withHeader }) => `padding-bottom: ${withHeader ? 0 : 84}px;`}
`;

export const Wrapper = ({
  children,
  testID,
  withHeader,
  contentContainerStyle,
  style,
}: {
  children: PropsWithChildren['children'];
  testID?: string;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  withHeader?: boolean;
}) => {
  const {
    scrollEvents: { onScroll, onScrollBeginDrag, onScrollEndDrag },
  } = useContext(ScrollContext);

  return (
    <WrapperE
      testID={testID}
      scrollEventThrottle={1}
      onScroll={onScroll}
      onScrollBeginDrag={onScrollBeginDrag}
      onScrollEndDrag={onScrollEndDrag}
      contentContainerStyle={contentContainerStyle}
      withHeader={withHeader}
      style={style}>
      {children}
    </WrapperE>
  );
};
