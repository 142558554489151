import React from 'react';
import { Image, Platform, View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';

import { DeepLinkFirstAccessStepProps } from '../types';
import BandaidDesktop from '~/assets/images/bandaid-tem-desktop.png';
import BandaidMobile from '~/assets/images/bandaid-tem-mobile.png';

import * as S from '~/screens/Login/components/Welcome/styles';

import { Button, Typography, useSweetAlert } from '~/components/@hello-ui';

import { AuthService } from '~/auth/auth-service';

export const DeepLinkFirstAccessComplete = ({ setStep }: DeepLinkFirstAccessStepProps) => {
  const theme = useTheme();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const handleRedirect = async () => {
    await AuthService.updateProfileData();
    AuthService.triggerGatekeeperValidation().catch((error) => {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível acessar o serviço. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    });
  };

  return (
    <>
      <View
        style={Platform.select({
          web: {
            width: '100%',
            alignItems: 'center',
            height: '100dvh',
          },
          default: {
            width: '100%',
            flex: 1,
            alignItems: 'center',
          },
        })}>
        <S.WrapperIcon>
          <Image
            style={{
              width: '100%',
              height: '100%',
            }}
            source={Platform.OS === 'web' ? BandaidDesktop : BandaidMobile}
            resizeMode="contain"
          />
        </S.WrapperIcon>
        <S.Wrapper
          style={{
            minWidth: 328,
            maxWidth: 370,
          }}>
          <S.WrapperTexts
            style={{
              marginBottom: 12,
              width: '100%',
            }}>
            <Typography variant="title">Cadastro completo!</Typography>
            <Typography className="mt-12 w-[328px]" variant="body1" style={{ textAlign: 'center' }}>
              Comece a utilizar seus benefícios para cuidar da saúde.
            </Typography>
          </S.WrapperTexts>
          <S.WrapperButton>
            <Button
              variant="link"
              icon={<Icon name="chevron-right" size={24} color={theme.colors.primary} />}
              iconPosition="right"
              onPress={handleRedirect}>
              Começar
            </Button>
          </S.WrapperButton>
        </S.Wrapper>
        <S.WrapperFooter>
          <Typography variant="helperText" color="black20">
            Powered by TEM Saúde
          </Typography>
        </S.WrapperFooter>
      </View>
    </>
  );
};
