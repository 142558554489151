import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useApi } from '../../../hooks/api';
import Icon from 'react-native-vector-icons/dist/Feather';
import { CitiesTypes, CitiesPropsTypes } from './types';
import { Typography, Input, useDialog } from '../../@tem-ui';
import * as S from './styles';
import { capitalize } from '~/utils/strings';
import { useTheme } from 'styled-components/native';

export const Cities = ({ data, onSelect }: CitiesPropsTypes): JSX.Element => {
  const api = useApi();
  const { closeDialog } = useDialog();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [cities, setCities] = useState<CitiesTypes[]>([]);

  const stateId: number = data ?? 0;

  const keyExtractor = useCallback((item, idx) => idx.toString(), []);

  const renderItem = useCallback(
    ({ item }) => {
      const { title }: CitiesTypes = item;

      return (
        <S.WrapperItem onPress={() => onSelect(item)}>
          <Typography variant="body2" color="black">
            {title}
          </Typography>
          <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
        </S.WrapperItem>
      );
    },
    [closeDialog],
  );

  const getData = useCallback(
    debounce(async (value?: string) => {
      const { data } = await api.cities(stateId, value);
      const payload = data.map((city) => ({ id: city.id, title: capitalize(city.nome) }));

      setCities(payload);

      setLoading(false);
    }),
    [],
  );

  useEffect(() => {
    void getData();
  }, []);

  return (
    <S.Wrapper>
      {loading ? (
        <S.WrapperLoading>
          <S.Loading />
        </S.WrapperLoading>
      ) : (
        <>
          <S.WrapperSearch>
            <Input
              editable
              onChange={(value) => getData(value?.toString())}
              label="Pesquisar cidade"
              placeholder="Pesquisar cidade"
              variant="light"
              icon="search"
            />
          </S.WrapperSearch>
          <S.WrapperList
            data={cities}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            ItemSeparatorComponent={S.Divider}
          />
        </>
      )}
    </S.Wrapper>
  );
};
