import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import { DeepLinkStrategy } from '~/auth/strategies/deep-link/deep-link.strategy';
import { MagicLinkStrategy } from '~/auth/strategies/magic-link/magic-link.strategy';
import { UnauthenticatedStrategy } from '~/auth/strategies/unauthenticated/unauthenticated.strategy';
import { PasswordStrategy } from '~/auth/strategies/password/password.strategy';
import { CertificateStrategy } from '~/auth/strategies/certificate/certificate.strategy';
import { IStrategy } from '~/auth/interfaces/strategy';

export class StrategyFactory {
  static create(strategy: EAuthStrategy | IStrategy) {
    if (typeof strategy !== 'string') {
      return strategy;
    }

    switch (strategy) {
      case EAuthStrategy.Password:
        return new PasswordStrategy();
      case EAuthStrategy.DeepLink:
        return new DeepLinkStrategy();
      case EAuthStrategy.MagicLink:
        return new MagicLinkStrategy();
      case EAuthStrategy.Certificate:
        return new CertificateStrategy();
      default:
        return new UnauthenticatedStrategy();
    }
  }
}

export interface IStrategyByEnum {
  [EAuthStrategy.Password]: PasswordStrategy;
  [EAuthStrategy.DeepLink]: DeepLinkStrategy;
  [EAuthStrategy.MagicLink]: MagicLinkStrategy;
  [EAuthStrategy.Unauthenticated]: UnauthenticatedStrategy;
  [EAuthStrategy.Certificate]: CertificateStrategy;
}
