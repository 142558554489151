import React from 'react';
import { Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};

export const PsychologicalAssistanceIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M15.6159 7.23957C16.2268 6.77718 16.8975 6.39991 17.6101 6.11817C17.8855 8.00847 17.5176 9.93639 16.5657 11.5927C15.9069 12.737 14.9884 13.7103 13.884 14.4341C11.9987 15.6503 12.4129 15.4439 12.5487 15.6503C12.6848 15.8565 13.0987 16.0434 14.3881 15.1989C15.6127 14.3969 16.6309 13.3179 17.3608 12.0491C18.5356 10.0402 18.9063 7.66108 18.3989 5.38983C18.3318 5.14984 18.0856 5.00744 17.8442 5.06902C16.8378 5.37469 15.2192 6.32533 14.3881 6.96999C13.0895 7.86357 14.0461 7.74799 14.1987 7.95031C14.3513 8.15287 15.4148 7.39262 15.6173 7.24L15.6159 7.23957Z"
        fill="white"
        stroke="white"
        strokeWidth="0.1"
      />
      <Path
        d="M9.6096 15.1949C9.82054 15.324 11.8637 16.406 11.9995 16.1996C11.9995 15.6496 10.3155 14.5727 10.1137 14.4299C9.00972 13.7061 8.09129 12.7328 7.43264 11.5885C6.48471 9.93202 6.1177 8.00631 6.39001 6.1171C7.10182 6.40006 7.77189 6.77795 8.3824 7.24034C8.58475 7.39297 9.64691 8.15213 9.79952 7.94957C9.95215 7.74702 10.3495 7.39957 8.935 6.50707C8.04544 5.9458 7.16176 5.37503 6.15553 5.06894C5.9141 5.00756 5.66797 5.14996 5.60086 5.38975C5.09324 7.66042 5.46357 10.0391 6.63756 12.048C7.3676 13.3155 8.38591 14.3937 9.6096 15.1949Z"
        fill="white"
        stroke="white"
        strokeWidth="0.1"
      />
      <Path
        d="M22.6651 9.43438C21.6575 9.19909 20.6155 9.14774 19.5894 9.28257C17.4436 9.50757 17.4135 9.73862 17.4436 9.98986C17.5 10.7 17.7357 10.429 19.698 10.1928C20.4428 10.1032 21.1961 10.1135 21.9382 10.2231C21.2295 11.9964 19.9481 13.4816 18.298 14.4428C16.5452 15.4167 14.5176 15.7782 12.5365 15.4696C13.9703 13.7796 14.7551 11.6338 14.75 9.41739C14.7633 7.09047 13.8957 4.84457 12.321 3.13104C12.1428 2.95632 11.8576 2.95632 11.6794 3.13104C10.1048 4.84437 9.23709 7.09026 9.2504 9.41739C9.24528 11.6342 10.0303 13.7803 11.4644 15.4707C9.48285 15.7812 7.45427 15.4193 5.70234 14.4428C4.05182 13.4814 2.77025 11.9955 2.06148 10.2216C2.80377 10.1124 5.62319 10.2553 6.3681 10.3455C6.61853 10.3739 6.33823 10.2392 6.3681 9.98881C6.39777 9.73838 6.20043 9.46545 5.95 9.43434C4.92413 9.29931 2.34256 9.19885 1.33513 9.43434C1.09534 9.50125 0.952736 9.74759 1.01432 9.98881C1.71037 12.2101 3.22156 14.0853 5.24401 15.2376C6.71368 16.0742 8.37787 16.5089 10.0691 16.4983C10.7179 16.5022 11.3652 16.4371 12.0003 16.3039C14.3074 16.7694 16.7051 16.3909 18.7565 15.2376C20.7783 14.0853 22.2891 12.2105 22.9853 9.98986C23.0477 9.74844 22.9054 9.50168 22.6651 9.43438ZM12 4.15473C13.1873 5.65199 13.8333 7.50668 13.8333 9.41762C13.8333 11.3286 13.1873 13.1831 12 14.6805C10.8127 13.1833 10.1668 11.3286 10.1668 9.41762C10.1668 7.50668 10.8127 5.65215 12 4.15473Z"
        fill="white"
        stroke="white"
        strokeWidth="0.1"
      />
      <Path
        d="M12.0012 20.8735C11.5151 20.8735 11.0487 20.6803 10.7048 20.3366C10.3611 19.9927 10.168 19.5264 10.168 19.0402C10.168 18.5541 10.3611 18.0878 10.7048 17.7439C11.0488 17.4002 11.5151 17.207 12.0012 17.207C12.4873 17.207 12.9536 17.4002 13.2975 17.7439C13.6413 18.0878 13.8344 18.5541 13.8344 19.0402C13.8344 19.5264 13.6413 19.9927 13.2975 20.3366C12.9536 20.6803 12.4873 20.8735 12.0012 20.8735ZM12.0012 18.1236C11.7581 18.1236 11.5249 18.2202 11.353 18.3921C11.1811 18.5639 11.0846 18.7972 11.0846 19.0402C11.0846 19.2833 11.1811 19.5166 11.353 19.6884C11.5249 19.8603 11.7581 19.9569 12.0012 19.9569C12.2442 19.9569 12.4775 19.8603 12.6494 19.6884C12.8212 19.5166 12.9178 19.2833 12.9178 19.0402C12.9178 18.7972 12.8212 18.5639 12.6494 18.3921C12.4775 18.2202 12.2442 18.1236 12.0012 18.1236Z"
        fill="white"
        stroke="white"
        strokeWidth="0.1"
      />
    </Svg>
  );
};
