import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Feature, PartnerData, SignatureType, ClinicType, UserType, ProductType } from './types';
import { useApi } from '~/hooks/api';
import { mapDependent } from '~/helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

export interface DataUserTypes {
  tokenzeus: string;
  refreshtoken: string;
  usertoken?: string;
  registeraccess?: boolean;
  cpf?: string;
}

export interface OnixCode {
  cnpj: string;
  name: string;
  onix_code: string;
  slug: string;
  term_pdf: any;
  terms: any;
}

export interface AuthInstance {
  user: UserType | null;
  clinic: ClinicType | null;
  token?: object | null;
  signature: SignatureType | null;
  onixCode?: OnixCode | null;
  dependents?: Array<any> | undefined;
  partner?: PartnerData | null;
  routePartner: string;
  setUser: (_user: UserType | null) => void;
  setToken: (_token: object | null) => void;
  setOnixCode: (_onix: object | null) => void;
  setLoading: (loading: boolean) => void;
  setDependents: (dependents: Array<any>) => void;
  setPartner: (_partner: object | null) => void;
  getPartner: (_slug: string) => void;
  loading?: boolean;
  loadingPartner?: boolean;
  loadingDependets?: boolean;
  addressFirstAccess?: boolean | null;
  dataUser: (data: DataUserTypes, skipVerify?: boolean) => void;
  authenticateDeepLink: (params: AuthenticateDeepLinkParams) => Promise<void>;
  refreshPartnerData: (slug?: string) => void;
  checkIsEnabledFeature: (name: string) => boolean;
  setAddressFirstAccess: (status: boolean) => void;
  /**
   * calls `api.myProfile` and updates `user` state
   */
  dataMyProfile: () => Promise<void>;
  isDependent?: boolean | null;
  cleanAll: () => void;
  setRedirectTo: React.Dispatch<React.SetStateAction<RedirectToProps | null>>;
  redirectTo: RedirectToProps | null;
}

interface AuthProviderTypes {
  children: JSX.Element;
}

export interface RedirectToProps {
  screen: string;
  feature: string;
}

export interface AuthenticateDeepLinkParams {
  tokenZeus: string;
  refreshToken: string;
  onixCodeNumber: string;
}

export const AuthContext = createContext<AuthInstance>({} as AuthInstance);

export const AuthProvider = ({ children }: AuthProviderTypes): JSX.Element => {
  const [signature, setSignature] = useState<SignatureType | null>(null);
  const [clinic, setMyClinic] = useState<ClinicType | null | undefined>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [isDependent, setIsDependent] = useState<boolean | null>(null);
  const [dependents, setDependents] = useState<object | null>(null);
  const [partner, setPartner] = useState<PartnerData | null | undefined>(undefined);
  const [token, setToken] = useState<object | null>(null);
  const [onixCode, setOnixCode] = useState<object | null>(null);
  const [myProducts, setMyProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDependets, setLoadingDependents] = useState<boolean>(false);
  const [loadingPartner, setLoadingPartner] = useState<boolean>(true);
  const [routePartner, setRoutePartner] = useState<string>('');
  const [addressFirstAccess, setAddressFirstAccess] = useState<boolean>(false);
  const [chatIsLoaded, setChatIsloaded] = useState<boolean>(false);
  const [logout, setLogout] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<RedirectToProps | null>(null);

  const api = useApi();

  const checkIsEnabledFeature = useCallback(
    (name: string) => {
      const features = isDependent ? partner?.dependent_features : partner?.features;
      const activeFeatures = (feature: Feature) => feature.active === 1;
      const byName = (feature: Feature) => feature.name === name;

      return !features?.filter(activeFeatures).find(byName) ?? true;
    },
    [partner, isDependent],
  );

  const verifyProducts = useCallback(async (cpf?: string) => {
    const { data: productData } = await api.myProducts();

    if (productData.length === 0) {
      setOnixCode(null);
    } else if (productData.length === 1) {
      const onixCode = JSON.stringify(productData[0]);
      await AsyncStorage.setItem('@tem::OnixCode', onixCode);
      setOnixCode(productData[0]);
      await getPartner(productData[0]?.slug, productData);
    }
    setMyProducts(productData);
  }, []);

  const dataMySignature = useCallback(async () => {
    await api.getMySignature().then((resp: any) => {
      if (resp.data.plan) {
        setSignature(resp.data);
      }
    });
  }, []);

  const dataMyClinic = useCallback(async () => {
    await api.getMyClinic().then((resp: any) => {
      if (resp.data) {
        setMyClinic(resp.data);
      }
    });
  }, []);

  const dataMyProfile = useCallback(async () => {
    const data = await api.myProfile();
    setUser(data.data);
  }, []);

  const dataUser = useCallback(async (data: DataUserTypes | null, skipVerify?: boolean) => {
    let tokenInfo;
    setLoading(true);

    const address = await AsyncStorage.getItem('@tem::AddressFirstAccess');
    address && setAddressFirstAccess(true);

    if (data && data.tokenzeus && data.refreshtoken) {
      await AsyncStorage.setItem(
        '@tem::Token',
        JSON.stringify({
          tokenzeus: data.tokenzeus,
          refreshToken: data.refreshtoken,
        }),
      );
      if (!skipVerify) {
        await verifyProducts(data.cpf);
      }
      setToken({
        tokenzeus: data.tokenzeus,
        refreshToken: data.refreshtoken,
      });
    } else {
      tokenInfo = await AsyncStorage.getItem('@tem::Token');
      tokenInfo && setToken(JSON.parse(tokenInfo));

      const onixCodeInfo = await AsyncStorage.getItem('@tem::OnixCode');
      onixCodeInfo && setOnixCode(JSON.parse(onixCodeInfo));

      tokenInfo && (await verifyProducts());
    }

    if ((data && data.tokenzeus) || tokenInfo) {
      await dataMyProfile();
    }

    if (data && data.usertoken) {
      await AsyncStorage.setItem(
        '@tem::UserToken',
        JSON.stringify({
          usertoken: data.usertoken,
        }),
      );
    }

    if (data && data.registeraccess && data.cpf) {
      await api.accessByProduct(data.cpf.replace(/[^\d]/g, ''));
    }

    if (!user) {
      setLoading(false);
    }
  }, []);

  const authenticateDeepLink = useCallback(async (params: AuthenticateDeepLinkParams) => {
    try {
      setLoading(true);

      const structuredToken = {
        tokenzeus: params.tokenZeus,
        refreshToken: params.refreshToken,
      };

      await AsyncStorage.setItem('@tem::Token', JSON.stringify(structuredToken));

      // verifyProducts
      const { data: productsRes } = await api.myProducts();

      if (productsRes.length) {
        const productWithOnixNumber = productsRes.find(
          (product) => parseInt(product.onix_code) === parseInt(params.onixCodeNumber),
        );

        const product = productWithOnixNumber || productsRes[0];

        const productStringified = JSON.stringify(product);
        await AsyncStorage.setItem('@tem::OnixCode', productStringified);
        setOnixCode(product);

        await getPartner(product.slug, productsRes);
      }

      setMyProducts(productsRes);
      setToken(structuredToken);

      // populate user with data from API
      await dataMyProfile();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const getDependents = async () => {
        setLoadingDependents(true);
        await api.getDependents(user.cpf).then(({ data: { result } }) => {
          setLoadingDependents(false);
          setDependents(result.map((dependent: any) => mapDependent(dependent)));
        });
      };

      if (user) {
        try {
          if (!loadingDependets && onixCode) {
            await getDependents();
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingDependents(false);
        }
      }
    })();
  }, [user, onixCode]);

  const refreshPartnerData = async (slug?: string) => {
    const partnerData = await AsyncStorage.getItem('@tem::Partner');
    const partnerObject = JSON.parse(partnerData || '{}');

    await getPartner(slug || partnerObject.slug);
  };

  useEffect(() => {
    if (user && partner) {
      setLoading(false);

      if (Platform.OS === 'web' && user && !chatIsLoaded) {
        setChatIsloaded(true);
      }
    }
  }, [user, partner]);

  useEffect(() => {
    void dataUser(null);
  }, []);

  useEffect(() => {
    const validadeIsDependent = async () => {
      await api
        .getUsertokenCto((user?.cpf ?? '').replace(/[^\d]/g, ''))
        .then(({ tipo_usuario }) => {
          const isDependent = tipo_usuario === 'DEPENDENTE';
          setIsDependent(isDependent);
        })
        .catch(() => {
          setIsDependent(false);
        });
    };

    const loadPartnerAndValidadeIsDependent = async () => {
      await requestPartner(myProducts[0]?.slug, myProducts, user.cpf);
      await validadeIsDependent();
    };

    if (user && myProducts) {
      void loadPartnerAndValidadeIsDependent();
    }
  }, [user, myProducts]);

  const requestPartner = async (slug: string) => {
    if (!slug) {
      return;
    }

    if (user && !partner) {
      setLoading(true);
    }

    return await api.getPartner(slug).then(async ({ data }) => {
      if (data) {
        await AsyncStorage.setItem(
          '@tem::Partner',
          JSON.stringify({
            ...data?.theme_config,
            slug: data.slug,
            medicine_discount_provider: data.medicine_discount_provider,
          }),
        );

        setPartner(data);
        return data;
      }
    });
  };

  const getPartner = async (slugToSearch: string, products = myProducts) => {
    setLoadingPartner(true);
    try {
      let slug = '';
      const desiredProduct =
        products?.find((p: { slug: string }) => p.slug === slugToSearch) || false;
      if (desiredProduct) {
        slug = desiredProduct.slug || 'tem';
      } else {
        slug = onixCode?.slug || 'tem';
      }
      if (!products) {
        slug = slugToSearch;
      }

      const data = await requestPartner(slug);

      if (data) {
        setRoutePartner(data.slug);
        await loadAdditionalFeatureInfo(data.features);
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingPartner(false);
  };

  const loadAdditionalFeatureInfo = useCallback(
    async (features) => {
      features.find((f: { name: string }) => f.name === 'signature') && dataMySignature();
      features.find((f: { name: string }) => f.name === 'my_clinic') && dataMyClinic();
    },
    [dataMyClinic, dataMySignature],
  );

  const cleanAll = () => {
    setLogout(true);
    setUser(null);
    setOnixCode(null);
    setToken(null);
    setPartner(null);
    Platform.OS === 'web' && localStorage.clear();
  };

  useEffect(() => {
    if (logout && !user && !onixCode && !token && !partner) {
      if (Platform.OS === 'web') {
        setLogout(false);

        const isDeepLink = DeepLink.getParams().isDeepLink;
        if (!isDeepLink) {
          setTimeout(() => {
            window.location.replace(window.location.origin + window.location.pathname);
          }, 500);
        }
      }
    }
  }, [logout, user, onixCode, token, partner]);

  return (
    <AuthContext.Provider
      value={{
        user,
        clinic,
        token,
        signature,
        onixCode,
        dependents,
        partner,
        routePartner,
        isDependent,
        setUser,
        setToken,
        setOnixCode,
        setLoading,
        setDependents,
        setPartner,
        getPartner,
        loading,
        dataUser,
        loadingPartner,
        loadingDependets,
        refreshPartnerData,
        checkIsEnabledFeature,
        addressFirstAccess,
        setAddressFirstAccess,
        dataMyProfile,
        cleanAll,
        setRedirectTo,
        authenticateDeepLink,
        redirectTo,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
