import React from 'react';
import { FloatingButtonProps } from './types';
import { Button } from '~/components/@hello-ui/FloatingButton/styles';

export const FloatingButton: React.FC<FloatingButtonProps> = ({
  testID = 'floating-button',
  onPress,
  disabled,
  icon,
}) => {
  return (
    <Button disabled={disabled} testID={testID} onPress={onPress}>
      {icon}
    </Button>
  );
};
