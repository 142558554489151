import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const Wrapper = styled.View`
  flex-grow: 1;
  align-items: center;
  ${Platform.OS === 'web' &&
  `
    overflow: auto;
    max-height: 100%;
  `}
`;

export const WrapperContent = styled.View`
  width: 100%;
  max-width: 1200px;
`;
