import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Typography, Panel, Collapse } from '~/components/@tem-ui';
import { Header } from '~/components/Header';
import * as S from './styles';
import { Footer } from '~/components/Footer';
import { useTheme } from 'styled-components/native';
import { useMedia } from '~/components/@hello-ui';
export const SurgicalProcedureHospital = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Procedimentos cirúrgicos"
          onBack={() => {
            navigation.navigate('SurgicalProcedure');
          }}>
          <S.WrapperContainerView>
            <S.WrapperText>
              <Typography variant="heading1" color={theme.colors.black}>
                Hospitais disponíveis
              </Typography>

              <S.WrapperBox>
                <Typography variant="paragraph" color={theme.colors.black70}>
                  Os hospitais parceiros da Vidia estão localizados na Grande São Paulo, mas estamos
                  com um plano de expansão para outras capitais nos próximos meses.
                </Typography>
              </S.WrapperBox>
            </S.WrapperText>

            <S.WrapperText>
              <Typography variant="paragraph" color={theme.colors.black70}>
                Os hospitais parceiros são:
              </Typography>
            </S.WrapperText>

            <Collapse title="Hospital Alemão Oswaldo Cruz Unidade Vergueiro">
              <Typography variant="paragraph" color={theme.colors.black70}>
                Rua São Joaquim, Liberdade São Paulo, 01508-000 {'\n\n'}
              </Typography>

              <Typography variant="paragraph" color={theme.colors.black70}>
                Possui mais de 200 leitos, sendo 30 deles de UTI. O Centro Cirúrgico é composto por
                13 salas cirúrgicas preparadas para cirurgias de média e alta complexidade. A
                unidade dispõe de ambulatório de especialidades com consultórios, salas de
                endoscopia e boxes para o tratamento de quimioterapia. Possui também um centro de
                diagnósticos por imagem, com realização de exames de Ressonância Magnética,
                Tomografia Computadorizada, Raio-X, Ultrassonografia, Mamografia, Broncoscopia,
                Endoscopia e Colonoscopia.
              </Typography>
            </Collapse>

            <Collapse title="Portellinha Oftalmologia">
              <Typography variant="paragraph" color={theme.colors.black70}>
                Av. Indianópolis, 489 - Indianópolis São Paulo, 04063-000 {'\n\n'}
              </Typography>

              <Typography variant="paragraph" color={theme.colors.black70}>
                A clínica, fundada em 1978 pelo Dr. Waldir Portellinha, possui reconhecimento pela
                competência e seriedade nos tratamentos, diagnósticos e cirurgias oculares. É
                composta por modernas instalações, com aparelhos de última geração para todos os
                exames oftalmológicos, além de uma equipe altamente treinada e uma recepção
                informatizada para atendimento diferenciado aos pacientes. Conta com oftalmologistas
                e anestesistas com títulos de especialistas das respectivas áreas e reconhecidos
                pelo CBO (Conselho Brasileiro de Oftalmologia) e AMB (Associação Médica Brasileira).
              </Typography>
            </Collapse>

            <Collapse title="UPO Oftalmologia">
              <Typography variant="paragraph" color={theme.colors.black70}>
                Av. Brigadeiro Luís Antônio, 2504 - Jardins São Paulo, 04402-000 {'\n\n'}
              </Typography>

              <Typography variant="paragraph" color={theme.colors.black70}>
                Presta atendimento e serviços em todas as áreas da Oftalmologia. Conta com um centro
                de diagnóstico avançado, personalizado e com profissionais experientes e treinados
                nos melhores institutos, nacionais e internacionais, com especialização nos
                tratamentos e cirurgias de catarata, vitrectomia, glaucoma, miopia, estrabismo,
                hipermetropia, astigmatismo, tumores oculares/pálpebras, plástica ocular.
              </Typography>
            </Collapse>

            <Collapse title="BG Serviços Médicos">
              <Typography variant="paragraph" color={theme.colors.black70}>
                Rua Domingos de Morais, 2781, 14º andar Vila Mariana - São Paulo, 04402-000 {'\n\n'}
              </Typography>

              <Typography variant="paragraph" color={theme.colors.black70}>
                Clínica fundada pelo Dr. Bruno Garcia, formado em Medicina pela Universidade Federal
                de Sergipe, Residência Médica em Cirurgia Geral pela Fundação de Beneficência
                Hospital de Cirurgia e Urologia pelo Hospital do Servidor Público Municipal de São
                Paulo. Pós graduação em Cirurgia Robótica e Observership in Robotic Surgery
                University of Southern California. Atendimento voltado para especialidade de
                Urologia, através de procedimentos ambulatoriais de baixo risco.
              </Typography>
            </Collapse>
          </S.WrapperContainerView>
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};
