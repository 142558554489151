import { useContext } from 'react';
import { AuthContext, AuthInstance } from '../contexts/auth';

export const useAuth = (): AuthInstance => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
