import React, { useEffect } from 'react';
import { Animated } from 'react-native';
import { ModalProps } from './types';
import { SuccessIcon } from '~/components/Icons/SuccessIcon';
import { ErrorIcon } from '~/components/Icons/ErrorIcon';
import { AlertIcon } from '~/components/Icons/AlertIcon';
import { InfoIcon } from '~/components/Icons/InfoIcon';
import { LocationIcon } from '~/components/Icons/LocationIcon';
import { NewsIcon } from '~/components/Icons/NewsIcon';
import { PrivacyIcon } from '~/components/Icons/PrivacyIcon';

import * as S from './styles';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';

export const Modal = ({
  isOpen,
  children,
  variant,
  showIcon = true,
  footer,
  title,
  subtitle,
}: ModalProps) => {
  const theme = useTheme();
  const opacity = new Animated.Value(0);

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 0.7,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [isOpen]);

  const iconsVariant = {
    success: <SuccessIcon width={80} height={80} />,
    error: <ErrorIcon width={80} height={80} />,
    alert: <AlertIcon width={80} height={80} />,
    info: <InfoIcon width={80} height={80} />,
    location: <LocationIcon width={80} height={80} />,
    news: <NewsIcon width={80} height={80} />,
    privacy: <PrivacyIcon width={80} height={80} />,
  };

  return (
    <>
      {isOpen && (
        <S.ModalContainer transparent presentationStyle="overFullScreen" visible={isOpen}>
          <S.WrapperBackground as={Animated.View} style={{ opacity }} />
          <S.Wrapper>
            <S.BodyContainer>
              <S.BodyDataConatiner>
                {showIcon && <S.IconContainer>{iconsVariant[variant]}</S.IconContainer>}
                <S.Title>
                  <Typography
                    variant={'h3'}
                    color={theme.colors.title}
                    style={{ textAlign: 'center' }}>
                    {title!!}
                  </Typography>
                </S.Title>
                <Typography
                  variant={'body2'}
                  color={theme.colors.paragraph}
                  style={{ textAlign: 'center' }}>
                  {subtitle!!}
                </Typography>
                {children}
              </S.BodyDataConatiner>
              <S.FooterContainer>{footer}</S.FooterContainer>
            </S.BodyContainer>
          </S.Wrapper>
        </S.ModalContainer>
      )}
    </>
  );
};
