import React, { useEffect, useState, useCallback } from 'react';
import { useApi } from '../../../hooks/api';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Typography, Input } from '../../../components/@tem-ui';
import { Spacing } from '../../../components/Spacing';
import { Listing, ListingItem } from '../../../components/Listing';
import * as S from '../styles';
import { serializeString } from '~/utils/strings';
import { useTheme } from 'styled-components/native';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { Specialty } from '~/contexts/types';

export const BenefitsSpecialty = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();

  const { nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();

  const [term, setTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [fullSpecialties, setFullSpecialtie] = useState<Specialty[]>([]);

  const renderItem = useCallback(({ item }) => {
    const { id, name }: Specialty = item;

    return (
      <ListingItem
        onPress={() => {
          setAppointment({ ...appointment, specialty: { id, name } });
          nextStep();
        }}>
        <Typography variant="body2" color="black">
          {name}
        </Typography>
        <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
      </ListingItem>
    );
  }, []);

  const getData = useCallback(async () => {
    const { data } = await api.specialities({ type: 'network' });

    setSpecialties(data);
    setFullSpecialtie(data);

    setLoading(false);
  }, []);

  const handleSearch = useCallback(
    (text: string) => {
      const query: string = serializeString(text);
      const filteredData = fullSpecialties.filter(({ name }: Specialty) =>
        serializeString(name).includes(query),
      );

      setSpecialties(filteredData);
      setTerm(text);
    },
    [fullSpecialties],
  );

  useEffect(() => {
    void getData();
  }, []);

  return (
    <S.WrapperSchedule>
      <Spacing bottom={16}>
        <Typography variant="heading1" color="black" bold>
          Qual especialidade?
        </Typography>
      </Spacing>
      <S.WrapperSearch>
        <Input
          editable
          value={term}
          onChange={(value) => handleSearch(value)}
          label="Pesquisar especialidade"
          placeholder="Pesquisar especialidade"
          variant="light"
          icon="search"
        />
      </S.WrapperSearch>
      <Listing data={specialties} loading={loading} renderItem={renderItem} />
    </S.WrapperSchedule>
  );
};
