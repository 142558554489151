import React from 'react';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { NumberTypes } from '../types';
import { currency } from '~/utils/strings';
import { PrizeIcon } from '~/components/Icons/Prize';
import { Divider, Loader, Typography } from '~/components/@hello-ui';

export const Number = ({ prize, luckyNumber, loading }: NumberTypes): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <View className="relative mt-24 w-full rounded-[8px] border border-solid border-gray-disabled mobile:mt-18 mobile:h-[108px]">
        <View className="flex h-76 flex-row items-center justify-center mobile:h-56">
          <PrizeIcon />
          <Typography variant="helperText" className="ml-8 mr-16 mobile:mr-32">
            Seu Número da Sorte:
          </Typography>
          <Typography variant="h3" color={theme.colors.black}>
            {loading ? <Loader variant="circle-loader" /> : luckyNumber ?? '-'}
          </Typography>
        </View>
        <Divider />
        <View className="h-68 flex-row items-center justify-center mobile:h-52 ">
          <Typography variant="helperText" className="mr-8 mobile:mr-16">
            Prêmio:
          </Typography>
          <Typography variant="h3" color={theme.colors.primary}>
            {`R$ ${currency(prize)}`}
          </Typography>
        </View>
      </View>
    </>
  );
};
