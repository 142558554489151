import React, { useState } from 'react';
import * as S from './styles';
import { Typography } from '~/components/@hello-ui';
import { HelthAccount } from '~/components/@hello-ui/HelthAccount';
import { EyeClose } from '~/components/Icons/EyeClose';
import { EyeOpen } from '~/components/Icons/EyeOpen';

import { useAuth } from '~/auth/legacy/useAuth';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';

export const HealthAccount = (): JSX.Element => {
  const [hideCurrency, setHideCurrency] = useState(false);

  const { onixCode } = useAuth();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  const toggleCurrencyVisibility = () => {
    setHideCurrency(!hideCurrency);
  };

  return (
    <>
      <S.WrapperContent>
        <Typography variant="subtitle" color="black">
          {checkMercadoPago ? 'Conta saúde  ' : 'Carteira Digital  '}
        </Typography>
        <S.WrapperButton onPress={toggleCurrencyVisibility}>
          {hideCurrency ? <EyeOpen /> : <EyeClose />}
        </S.WrapperButton>
      </S.WrapperContent>
      <HelthAccount hideCurrency={!hideCurrency} />
    </>
  );
};
