export { default as Title1 } from './Title1';
export { default as Title2 } from './Title2';
export { default as Title3 } from './Title3';
export { default as Heading1 } from './Heading1';
export { default as Heading2 } from './Heading2';
export { default as Heading3 } from './Heading3';
export { default as Subtitle1 } from './Subtitle1';
export { default as Body1 } from './Body1';
export { default as Body2 } from './Body2';
export { default as Paragraph } from './Paragraph';
