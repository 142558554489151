import React, { useEffect, useRef, useState } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { format } from 'date-fns';
import Clipboard from '@react-native-clipboard/clipboard';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import IconIon from 'react-native-vector-icons/dist/Ionicons';
import MaterialIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { debounce } from 'lodash';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { Button, CardButton, IconBadge, Input, Typography } from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import { currency } from '~/utils/strings';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import CopyIcon from '~/components/Icons/CopyIcon';

export const PaymentPixSuccess = () => {
  const navigation = useNavigation();
  const { pixCode, pixQrCode, pixExpiresAt, rechargeAmount } = useRecharge();
  const theme = useTheme();
  const { isDeepLink, showFooter } = DeepLink.useParams();
  const { authStrategy } = useAuthStore();

  const [copyButtonWasPressed, setCopyButtonWasPressed] = useState(false);

  const debouncedResetCopyButtonRef = useRef(
    debounce(() => {
      setCopyButtonWasPressed(false);
    }, 10000),
  );

  useEffect(() => {
    DeepLink.sendEvent(DeepLinkEvent.Success);
  }, []);

  return (
    <>
      <View className="mt-80 w-full max-w-[562px] self-center mobile:mt-24">
        <View className="items-center px-16">
          <IconBadge
            icon={<IconIon name="alert-outline" size={32} color="white" />}
            backgroundColor="warning"
            size={64}
            borderWidth={5}
          />
          <Typography variant="h3" className="mb-24 mt-24">
            Aguardando pagamento
          </Typography>
        </View>
        <View className="mb-40 w-full flex-row border-y-[1px] border-y-background-gray p-16 mobile:mb-24">
          <View className="mr-16 h-24 w-24 items-center justify-center">
            <View className="inset-x-0 inset-y-0 h-full w-full rounded-full bg-warning opacity-20"></View>
            <View className="absolute">
              <MaterialIcon name="arrow-up" size={16} color={theme.colors.warning} />
            </View>
          </View>
          <View className="flex-1">
            <Typography variant="bodyHighlight2">Recarga Pendente</Typography>
            <Typography variant="body2">
              Pagar até {format(new Date(pixExpiresAt), 'dd/MM/yyyy, hh:mm')}
            </Typography>
          </View>
          <Typography variant="bodyHighlight2" className="pl-16">
            {currency(rechargeAmount, 'pt-BR', 'BRL', true)}
          </Typography>
        </View>
        <View className="items-center px-16">
          <View className="w-full">
            <Typography variant="body2" className="mb-40 text-center" color="paragraph">
              Copie o código Pix e faça o pagamento no banco ou carteira digital de sua escolha.
            </Typography>
          </View>
        </View>

        <View className="flex flex-row px-16">
          <Input
            value={pixCode}
            icon={{
              item: () => (
                <TouchableOpacity onPress={() => Clipboard.setString(pixCode)}>
                  <CopyIcon color={theme.colors.paragraph} />
                </TouchableOpacity>
              ),
              size: 24,
              position: 'right',
            }}
          />
        </View>

        <View className="p-16">
          <Button
            variant={'primary'}
            style={{
              backgroundColor: copyButtonWasPressed ? theme.colors.success : theme.colors.primary,
            }}
            onPress={() => {
              Clipboard.setString(pixCode);
              setCopyButtonWasPressed(true);
              debouncedResetCopyButtonRef.current();
            }}
            className="-mt-4 px-16">
            {copyButtonWasPressed ? 'Código copiado!' : 'Copiar código Pix'}
          </Button>
        </View>

        <Typography variant="body2" className="my-24 text-center">
          Ou escaneie o QR Code abaixo
        </Typography>

        <View className="flex flex-row items-center justify-center">
          <Image source={{ uri: pixQrCode }} style={{ width: 192, height: 192 }} />
        </View>

        <View className="mx-16 my-24 rounded-lg bg-background-gray p-16">
          <Typography variant={'bodyHighlight1'} className="mb-16">
            Como pagar?
          </Typography>
          <Typography variant="body2" className="mb-8">
            1. Acesse a área Pix no app do seu banco ou carteira digital;
          </Typography>
          <Typography variant="body2" className="mb-8">
            2. Escaneie o QR Code ou copie e cole o código Pix no app e confirme o pagamento;
          </Typography>
          <Typography variant="body2" className="mb-8">
            3. Em até 15 minutos o seu pagamento será processado e depositado na sua{' '}
            <Typography variant={'bodyHighlight2'}>Conta Saúde.</Typography>
          </Typography>
        </View>

        {[EAuthStrategy.DeepLink, EAuthStrategy.Certificate].includes(authStrategy) ? (
          <View className="mb-24 ml-16 w-[166px] mobile:w-[132px]">
            <CardButton
              icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
              title="Início"
              variant="small"
              onPress={() => navigation.navigate('Dashboard')}
            />
          </View>
        ) : (
          <View className="mb-24 flex-row justify-center self-stretch px-16 mobile:justify-start">
            <View className="mr-8 w-[166px] mobile:mr-8 mobile:w-[132px]">
              <CardButton
                icon={<StethoscopeIcon height={32} width={32} />}
                title="Agendar"
                variant="small"
                onPress={() => navigation.navigate('ScheduleAppointment')}
              />
            </View>

            <View className="ml-8 w-[166px] mobile:w-[132px]">
              <CardButton
                icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
                title="Início"
                variant="small"
                onPress={() => navigation.navigate('Dashboard')}
              />
            </View>
          </View>
        )}
      </View>

      {(!isDeepLink ||
        (isDeepLink && showFooter) ||
        authStrategy === EAuthStrategy.Certificate) && (
        <View
          className="w-full p-24 pb-[26px] mobile:p-16 mobile:pb-[18px]"
          style={{ backgroundColor: theme.colors.background }}>
          <View className="w-full max-w-[530px] self-center">
            <Typography variant="h3" className="mb-8">
              Central de atendimento
            </Typography>
            <Footer variant="simple-phone-footer-inverted" className="mt-0" />
          </View>
        </View>
      )}
    </>
  );
};
