import styled, { css } from 'styled-components/native';

export const LimitedContent = styled.View<{ fill?: boolean }>(
  ({ fill, theme: { tokens } }) => css`
    ${fill &&
    css`
      flex: 1;
    `}

    max-width: 530px;
    width: ${tokens.sizes.full};
  `,
);

export const WrapperCloseButton = styled.View`
  ${({ theme: { tokens } }) => css`
    position: absolute;
    align-items: flex-end;
    justify-content: flex-end;
    top: ${tokens.space.s32};
    right: ${tokens.space.s32};
  `}
`;

export const CloseButton = styled.TouchableOpacity`
  ${({ theme: { tokens, colors } }) => css`
    align-items: center;
    justify-content: center;
    width: ${tokens.space.s40};
    height: ${tokens.space.s40};
    border-radius: ${tokens.space.s20};
    background-color: ${colors.background};
  `}
`;
