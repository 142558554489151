import styled from 'styled-components/native';

export const ButtonWrapper = styled.View`
  width: 100%;
  height: 48px;
`;

export const ButtonContainer = styled.TouchableOpacity`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.white15 : colors.white25};
  flex: 1;
  height: 48px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;
