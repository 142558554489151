import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

const CheckUpIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.1741 11.0241C21.5528 10.1214 21.7311 9.15081 21.6197 8.15769C21.4414 6.4424 20.5279 4.88507 19.0798 3.91452C16.8742 2.42486 13.8443 2.78602 11.8837 4.77222L11.3045 5.359L10.6361 4.68186C9.43311 3.46316 7.76219 2.85373 6.0689 3.03429C4.37574 3.19235 2.86068 4.11761 1.90273 5.58474C0.432271 7.81922 0.788776 10.8887 2.74937 12.8749L10.3688 20.5938C10.6361 20.8646 10.9926 21 11.3267 21C11.6608 21 12.0173 20.8646 12.2846 20.5938L14.1338 18.7205C14.9358 19.217 15.8715 19.4878 16.8741 19.4878C19.7926 19.4878 22.1765 17.0728 22.1765 14.1162C22.1542 12.9878 21.7755 11.9045 21.174 11.0243L21.1741 11.0241ZM11.3045 19.6233L3.70737 11.9044C2.21467 10.3922 1.925 8.06749 3.03896 6.37469C3.77416 5.24619 4.93275 4.54656 6.22478 4.41114C6.3808 4.38863 6.51445 4.38863 6.64812 4.38863C7.78431 4.38863 8.87602 4.83999 9.67806 5.65251L10.8143 6.80354C10.9479 6.93894 11.1262 7.00664 11.3044 7.00664C11.4826 7.00664 11.6609 6.93894 11.7945 6.80354L12.8416 5.74275C14.3343 4.23056 16.629 3.9371 18.3 5.06561C19.414 5.81042 20.1046 6.98414 20.2382 8.29304C20.2828 8.83476 20.2382 9.39902 20.0822 9.91809C19.1911 9.19576 18.0548 8.76705 16.8294 8.76705C13.9109 8.76705 11.527 11.1821 11.527 14.1387C11.527 15.5832 12.1062 16.8923 13.0197 17.8627L11.3045 19.6233ZM16.852 18.1111C14.6909 18.1111 12.9087 16.328 12.9087 14.1387C12.9087 11.9494 14.6909 10.1438 16.852 10.1438C19.013 10.1438 20.7954 11.9267 20.7954 14.1387C20.7954 16.3505 19.013 18.1111 16.852 18.1111Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M17.9214 12.604L16.3619 14.1839L15.7827 13.5971C15.5154 13.3263 15.0921 13.3263 14.8247 13.5971C14.5574 13.8679 14.5574 14.2967 14.8247 14.5675L15.8941 15.6509C16.0277 15.7863 16.206 15.854 16.3842 15.854C16.5625 15.854 16.7407 15.7863 16.8744 15.6509L18.924 13.5744C19.1913 13.3036 19.1913 12.8748 18.924 12.604C18.6342 12.333 18.1887 12.333 17.9214 12.604V12.604Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default CheckUpIcon;
