import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

interface PageProps extends PropsWithChildren {}

export const Page: React.FC<PageProps> = ({ children }) => {
  const authStrategy = useAuthStore((st) => st.authStrategy);
  const { isMobile, isTablet, tokens } = useTheme();

  const calcPaddings = () => {
    if (isMobile) {
      return {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      };
    }

    if (isTablet) {
      return {
        top: 40,
        right: 16,
        bottom: 40,
        left: [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy) ? 16 : 0,
      };
    }

    return {
      top: 40,
      right: 92,
      bottom: 40,
      left: [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy) ? 92 : 0,
    };
  };

  const { top, right, bottom, left } = calcPaddings();

  return (
    <View
      className={clsx(
        'mobile:rounded-0 flex min-h-0 w-full flex-1 rounded-[8px] mobile:h-full',
        [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy) &&
          !isMobile &&
          'items-center',
      )}
      style={{
        elevation: isMobile ? 0 : 2,
        paddingRight: right,
        paddingLeft: left,
        paddingTop: top,
        paddingBottom: bottom,
      }}>
      {children}
    </View>
  );
};
