import { useState, useEffect } from 'react';
import axios from 'axios';

export const useBlobUrl = (imgUrl: string): string => {
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    void (async () => {
      try {
        const image = await axios.get(imgUrl, { responseType: 'blob' });
        const blobImgSrc = URL.createObjectURL(image.data);
        setBlobUrl(blobImgSrc);
      } catch (_error) {
        setBlobUrl(imgUrl);
      }
    })();

    return () => setBlobUrl('');
  }, [imgUrl]);

  return blobUrl;
};
