import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { Wrapper } from '~/components/@tem-ui';
export const Container = styled(Wrapper)``;

export const Title = styled.View`
  width: 100%;
  min-height: 104px;
  background: ${StyledDefaultTheme.colors.gray10};
  justify-content: flex-start;
  padding-left: 33px;
  padding-top: 38px;
  padding-bottom: 44px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `padding-left: 20px;
  justify-content: center;
`}
`;

export const WrapperContent = styled.TouchableOpacity`
  padding-top: 20px;
  justify-content: space-between;
`;
