import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';
import { Typography } from '~/components/@hello-ui';

interface TabProps {
  children: string;
  active?: boolean;
  onPress?: () => void;
}

export const Tab: React.FC<TabProps> = ({ children, active, onPress }) => {
  const theme = useTheme();

  return (
    <TouchableOpacity
      onPress={onPress}
      className={clsx(
        'flex-1 border-b-[2px] border-b-gray-disabled',
        active && 'border-b-black-title',
      )}>
      <Typography
        variant="bodyHighlight2"
        color={active ? theme.colors.title : theme.colors.textDisabled}
        className="mb-[3px] text-center">
        {children}
      </Typography>
    </TouchableOpacity>
  );
};
