import React from 'react';
import { useNavigation } from '@react-navigation/native';
import parse from 'date-fns/parse';
import { differenceInCalendarMonths, format } from 'date-fns';
import {
  Button,
  DetailsList,
  MobileAutoSpace,
  PageWithCard,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { capitalize, toCamelCase } from '~/utils/strings';
import { trackingEventType } from '~/contexts/tracking/types';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';
import { AuthService } from '~/auth/auth-service';

import { NavigationProps } from '~/@types/navigation/routes-helpers';

interface DependentsViewProps extends NavigationProps<'DependentsView'> {}

export const DependentsView: React.FC<DependentsViewProps> = ({ route }) => {
  const navigation = useNavigation();
  const { params } = route;
  const { dependents } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const api = useApi();
  const { tracking } = useTracking();

  const dependent = (dependents ?? []).find((d) => {
    return d.cpf === params?.cpf;
  });
  const birthdate = dependent?.birthdate
    ? format(parse(dependent?.birthdate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
    : '';

  if (dependent?.phone === '11999999999') {
    dependent.phone = '';
  }

  const formatCpf = (cpf?: string) => {
    if (!cpf) return '';

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatCellphone = (cellphone?: string) => {
    if (!cellphone) return '';

    return cellphone.replace(/(\d{2})(9)?(\d{4})(\d{4})/, '($1) $2$3-$4');
  };

  const showConfirmDelete = () => {
    showSweetAlert(
      'Excluir dependente',
      'Tem certeza que deseja excluir o(a) dependente?',
      'warning',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => hideSweetAlert(),
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: deleteDependent,
          },
        ],
      },
    );
  };

  const deleteDependent = async () => {
    try {
      if (
        dependent.onix === '7124' &&
        differenceInCalendarMonths(new Date(), new Date(dependent.associationStartDate)) < 12
      ) {
        showSweetAlert(
          '',
          'O(a) dependente não pode ser excluído(a) pois não se cumpriu o prazo mínimo de permanência no programa',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                text: 'Ok',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      } else {
        showSweetAlert('Deletando...', '', 'info', false, false, {
          layout: 'helloUi',
        });

        await api.deleteDependent(dependent.cpf);

        await AuthService.updateDependents();

        void tracking.addMovement({
          event: trackingEventType.DependentDeleted,
        });

        showSweetAlert('Tudo certo!', 'Dependente excluído(a)', 'success', false, false, {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => {
                hideSweetAlert();
                navigation.navigate('Dependents');
              },
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível excluir o dependente. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Gerenciar Cadastro',
        onBack: () => navigation.navigate('Dependents'),
      }}>
      <Typography variant="title" className="mb-24 mobile:mb-16">
        Dados Pessoais
      </Typography>
      <DetailsList
        items={[
          {
            title: 'Nome',
            description: dependent?.name ? toCamelCase(dependent?.name) : '-',
          },
          {
            title: 'CPF',
            description: formatCpf(dependent?.cpf) || '-',
          },
          {
            title: 'Sexo Biológico',
            description: dependent?.gender ? capitalize(dependent?.gender) : '-',
          },
          {
            title: 'Data de Nascimento',
            description: birthdate || '-',
          },
          {
            title: 'Celular',
            description: formatCellphone(dependent?.phone) || '-',
          },
          {
            title: 'E-mail',
            description: dependent?.email || '-',
          },
        ]}
      />
      <MobileAutoSpace heightMobile={24} heightDesktop={35} />
      <Button
        variant="primary"
        onPress={() =>
          navigation.navigate('EditDependents', {
            cpf: params?.cpf,
          })
        }>
        Atualizar Dados
      </Button>
      <Typography
        variant="link"
        disabled={!dependent}
        className="mt-24 text-center mobile:mt-16"
        onPress={showConfirmDelete}>
        Excluir Dependente
      </Typography>
    </PageWithCard>
  );
};
