import React from 'react';
import { Circle, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const SuccessIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="7 7 64 64" fill="none">
      <G filter="url(#filter0_d_10902_855)">
        <Circle cx="39" cy="39" r="32" fill="#40C26E" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="5" />
      </G>
      <Path
        d="M51.8 30.2L34.2 47.8L26.2 39.8"
        stroke="white"
        strokeWidth="5.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
