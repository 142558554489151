import { atom } from 'recoil';

export const tempInstallments = atom({
  key: 'tempInstallments',
  default: { installment: 1, value: 0, amount: 0 },
});

export const tempIsVisible = atom({
  key: 'tempIsVisible',
  default: false,
});

export const tempSelected = atom({
  key: 'tempSelected',
  default: { id: null, label: '', customer_id: null },
});

export const tempCreditCardsList = atom({
  key: 'tempCreditCardsList',
  default: [],
});

export const tempStep = atom({
  key: 'tempStep',
  default: 0,
});

export const tempAmount = atom({
  key: 'tempAmount',
  default: 0,
});

export const tempCreditCard = atom({
  key: 'tempCreditCard',
  default: {
    card: '',
    cardHolderName: '',
    cardValidate: '',
    cardCvv: '',
    savedCard: false,
  },
});

export const tempBilletUrl = atom({
  key: 'tempBilletUrl',
  default: '',
});

export const tempBillCode = atom({
  key: 'tempBillCode',
  default: '',
});

export const tempPixQrCode = atom({
  key: 'tempPixQrCode',
  default: '',
});

export const tempPixCode = atom({
  key: 'tempPixCode',
  default: '',
});

export const tempPixExpiresAt = atom({
  key: 'tempPixExpiresAt',
  default: '',
});
