import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';
import {
  IAuthenticateCertificateParams,
  IAuthenticateCertificateRes,
} from '~/services/api/olimpo/interfaces/sso-certificate-domain';

export class SSOCertificateDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async authenticateCertificate(
    params: IAuthenticateCertificateParams,
  ): Promise<IAuthenticateCertificateRes> {
    const { isInternal, ...bodyParams } = params;

    const { data } = await this.olimpoService.axios.post<IAuthenticateCertificateRes>(
      '/v1/hello/sso_certificate/authenticate',
      bodyParams,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-internal-certificate': isInternal ? 'true' : 'false',
        },
      },
    );

    return data;
  }
}
