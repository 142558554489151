import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { SelectItem } from '../@hello-ui/Select/types';
import { StatesTypes } from '~/components/Dialog/States/types';
import { CitiesTypes } from '~/components/Dialog/Cities/types';
import { NeighborhoodsTypes } from '~/components/Dialog/Neighborhoods/types';
import { Button, MobileAutoSpace, Select, Typography, useAdresses } from '~/components/@hello-ui';
import { localCapitalize } from '~/utils/strings';
import { useGeolocation } from '~/components/@hello-ui/Adresses/Geolocation';
import { GeolocationButton } from '~/components/@hello-ui/Adresses/Geolocation/component/GeolocationButton';

export interface Location {
  state: StatesTypes;
  city: CitiesTypes;
  neighborhood: NeighborhoodsTypes[];
}

interface ScheduleLocationProps {
  title: string;
  subtitle?: string;
  onConfirmedLocation?: (location: Location) => void;
  buttonLoading?: boolean;
}

export const SelectLocal = ({
  title,
  subtitle,
  onConfirmedLocation,
  buttonLoading,
}: ScheduleLocationProps): JSX.Element => {
  const { getState, getCity, getNeighborhoods, getStates, getCities } = useAdresses();
  const { geolocationAddress } = useGeolocation();

  const [state, setState] = useState<StatesTypes | undefined>(undefined);
  const [city, setCity] = useState<CitiesTypes | undefined>(undefined);
  const [neighborhood, setNeighborhood] = useState<NeighborhoodsTypes[] | undefined>(undefined);

  const [states, setStates] = useState<any>();
  const [cities, setCities] = useState<any>();
  const [neighborhoods, setNeighborhoods] = useState<SelectItem[]>([]);

  const stateSelectRef = useRef<any>(null);
  const citySelectRef = useRef<any>(null);
  const neighborhoodSelectRef = useRef<any>(null);

  useEffect(() => {
    fillFieldsWithGeolocationData();
  }, [geolocationAddress]);

  useEffect(() => {
    getAndSetStates();
  }, []);

  const updateSelectedState = async (
    newState?: StatesTypes | undefined,
    bulkSet: boolean = false,
  ) => {
    setState(newState);

    if (newState) {
      await getAndSetCities(newState.id);
    }

    if (!bulkSet) {
      setCity(undefined);
      setNeighborhood(undefined);
      setNeighborhoods([]);

      citySelectRef.current?.reset?.();
      neighborhoodSelectRef.current?.reset?.();
    }
  };

  const updateSelectedCity = async (
    newCity?: CitiesTypes | undefined,
    bulkSet: boolean = false,
  ) => {
    setCity(newCity);

    if (newCity) {
      await getAndSetNeighborhoods(newCity.id);
    }

    if (!bulkSet) {
      setNeighborhood(undefined);
      setNeighborhoods([]);
      neighborhoodSelectRef.current?.reset?.();
    }
  };

  const updateSelectedNeighborhood = (newNeighborhood?: NeighborhoodsTypes[]) => {
    setNeighborhood(newNeighborhood);
  };

  const getAndSetStates = async () => {
    try {
      const result = await getStates();
      setStates(
        result?.map((v) => ({
          ...v,
          title: localCapitalize(v.title),
          label: localCapitalize(v.title),
          value: v.id,
        })),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getAndSetCities = async (stateId: number) => {
    try {
      const result = await getCities(stateId);
      setCities(
        result.map((v: any) => ({
          ...v,
          title: localCapitalize(v?.title),
          label: localCapitalize(v?.title),
          value: v?.id,
        })),
      );
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const getAndSetNeighborhoods = async (cityId: number, value?: string) => {
    getNeighborhoods(cityId, value ?? '')
      .then((data) =>
        setNeighborhoods(
          data.map(({ title, id }) => ({
            title: localCapitalize(title),
            label: localCapitalize(title),
            value: id,
          })),
        ),
      )
      .catch((error) => console.error(error));
  };

  const fillFieldsWithGeolocationData = async () => {
    const hasGeolocationData = Object.keys(geolocationAddress).length;
    if (hasGeolocationData) {
      if (geolocationAddress.state) {
        const stateData = await getState(geolocationAddress.state);

        if (stateData) {
          await updateSelectedState(
            {
              initials: stateData.initials,
              title: localCapitalize(stateData.title),
              id: stateData.id,
            },
            true,
          );

          if (geolocationAddress.city) {
            await getCity(stateData?.id, geolocationAddress.city).then(
              async (cityData: CitiesTypes | undefined) => {
                if (cityData) {
                  await updateSelectedCity(
                    { title: localCapitalize(cityData?.title), id: cityData?.id },
                    true,
                  );

                  if (geolocationAddress.neighborhood) {
                    const neighborhoodData = await getNeighborhoods(
                      cityData.id,
                      geolocationAddress.neighborhood,
                    ).then((data) =>
                      data.filter(({ title }) => title === geolocationAddress.neighborhood),
                    );

                    if (neighborhoodData?.length > 0) {
                      setNeighborhood(neighborhoodData);
                    }
                  }
                }
              },
            );
          }
        }
      }
    }
  };

  const handleConfirmLocation = async () => {
    if (onConfirmedLocation) {
      onConfirmedLocation({
        state,
        city,
        neighborhood,
      });
    }
  };

  return (
    <View className="h-full w-full max-w-[530px]" testID="test-select-local">
      <View className={subtitle ? 'mb-16' : 'mb-40 mobile:mb-24'}>
        <Typography variant="title">{title}</Typography>
        {subtitle && (
          <Typography className="mt-8 mobile:mt-4" variant="body2">
            {subtitle}
          </Typography>
        )}
      </View>

      <View className="mb-40 w-full flex-row mobile:mb-24">
        <GeolocationButton />
      </View>

      <Select
        disabled={!states?.length}
        items={states}
        label="Estado"
        placeholder="Selecione"
        onSelect={async (value: any) => {
          await updateSelectedState(value);
        }}
        reference={stateSelectRef}
        value={
          state
            ? {
                value: state.id,
                label: state.title,
              }
            : undefined
        }
        testID="test-select-state"
      />
      <Select
        items={cities}
        label="Cidade"
        placeholder="Selecione"
        onSelect={(value) => {
          updateSelectedCity({
            title: value.label,
            id: value.value as number,
          });
        }}
        disabled={!city?.id && !cities?.length}
        value={
          city
            ? {
                value: city.id,
                label: city.title,
              }
            : undefined
        }
        reference={citySelectRef}
        testID="test-select-city"
      />
      <Select
        disabled={!neighborhoods.length}
        items={neighborhoods}
        label="Bairro"
        placeholder="Selecione"
        onSelect={(selectItem) => {
          updateSelectedNeighborhood([
            {
              id: Number(selectItem.value),
              title: selectItem.label,
            },
          ]);
        }}
        reference={neighborhoodSelectRef}
        value={
          neighborhood
            ? {
                value: neighborhood[0].id,
                label: neighborhood[0].title,
              }
            : undefined
        }
        testID="test-select-neighborhoods"
      />

      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button
        disabled={!state || !city || !neighborhood || buttonLoading}
        variant="primary"
        loading={buttonLoading}
        onPress={handleConfirmLocation}>
        Próximo
      </Button>
    </View>
  );
};
