import styled from 'styled-components/native';
import { FlatList } from 'react-native-gesture-handler';
import { Wrapper } from '~/components/@tem-ui';
import { Dimensions } from 'react-native';

export const Container = styled(Wrapper)``;

export const WrapperMap = styled.View`
  min-height: 764px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: -32px;
  position: relative;
  justify-content: space-between;
  height: ${Dimensions.get('window').height}px;
  max-height: ${Dimensions.get('window').height - 180}px}
`;

export const WrapperMapElement = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const WrapperClinics = styled.View`
  margin-bottom: 24px;
`;

export const ListClinics = styled(FlatList)`
  padding-left: 32px;
  padding-vertical: 16px;
  z-index: 2;
`;

export const DividerClinics = styled.View`
  width: 8px;
  height: 100%;
`;

export const WrapperSearchBar = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  z-index: 1;
`;
