import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  height: 84px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 80px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  elevation: 2;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 20px;
    height: 160px;
    align-items: flex-start;
    flex-direction: column;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  `}
`;

export const WrapperColumn = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const WrapperColumnTouch = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const WrapperColumnRight = styled(WrapperColumn)`
  justify-content: flex-end;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex-direction: column-reverse;
    width: 100%;
  `}
`;

export const WrapperClick = styled.TouchableOpacity`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    position: absolute;
    top: -64px;
    right: 0;
  `}
`;

export const WrapperUser = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: flex-end;
`;
