import React from 'react';
import styled from 'styled-components/native';
import * as S from '../styles';

const Secondary = styled(S.ButtonContainer)`
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.gray20 : colors.black5};
`;

export default ({ ...rest }): JSX.Element => (
  <S.ButtonWrapper>
    <Secondary {...rest} />
  </S.ButtonWrapper>
);
