import React, { useState } from 'react';
import { PropsWithChildren } from 'react';
import { Dimensions, Platform } from 'react-native';
import { WrapperHeightProvider } from '~/screens/Login/context/WrapperHeightContext';
import * as S from '~/screens/Login/styles';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';

export const PageWrapperWithScroll: React.FC<PropsWithChildren> = ({ children }) => {
  const [wrapperHeight, setWrapperHeight] = useState(Dimensions.get('window').height);

  return (
    <CustomKeyboardAvoidingView>
      <WrapperHeightProvider value={wrapperHeight}>
        <S.Wrapper
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flexGrow: 1,
          }}
          onLayout={(ev) => {
            setWrapperHeight(ev.nativeEvent.layout.height);
          }}>
          {children}
        </S.Wrapper>
      </WrapperHeightProvider>
    </CustomKeyboardAvoidingView>
  );
};
