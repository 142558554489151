import React, { useEffect, useState } from 'react';
import omit from 'lodash.omit';
import { useTheme } from 'styled-components/native';
import { addDays, parseISO, isSunday } from 'date-fns';
import { View } from 'react-native';
import * as S from '../styles';
import {
  Typography,
  Button,
  Calendar,
  Card,
  Dialog,
  TimeTable,
  MobileAutoSpace,
} from '~/components/@hello-ui';
import { Checkbox } from '~/components/@tem-ui';
import { Spacing } from '~/components/Spacing';

import { useAppointment } from '~/hooks/appointment';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';

interface SelectedScheduleProps {
  [day: string]: { hours: string[] };
}

// Can only pick a day on the next week in range of 30 days
const START_DATE_OFFSET = 5;
const DATE_RANGE = 30;

const calculateMinDate = (currentDate: Date, businessDays: number): Date => {
  let count = 0;
  let date = currentDate;

  while (count < businessDays) {
    date = addDays(date, 1);
    if (!isSunday(date)) {
      count++;
    }
  }

  return date;
};

export const ScheduleDate = (): JSX.Element => {
  const theme = useTheme();
  usePageWithCardSettings({ scrollEnabled: true });

  const [currentSelectedDateForDialog, setCurrentSelectedDateForDialog] = useState<string>();
  const [currentSelectedHours, setCurrentSelectedHours] = useState<string[]>([]);

  const [selectedDays, setSelectedDays] = useState<SelectedScheduleProps>({});

  const [isToClearHours, setIsToClearHours] = useState<boolean>(false);
  const [isToShowHourDialog, setIsToShowHourDialog] = useState<boolean>(false);
  const [isToShowClearHours, setIsToShowClearHours] = useState<boolean>(true);

  const minDate = calculateMinDate(new Date(), START_DATE_OFFSET);
  const [maxDate] = useState(addDays(minDate, DATE_RANGE));

  const { appointment, setAppointment } = useAppointment();
  const { nextStep } = useStepper();

  useEffect(() => {
    if (isToShowHourDialog) {
      const selectedHours = selectedDays[currentSelectedDateForDialog!];
      setCurrentSelectedHours(selectedHours?.hours || []);
    } else {
      setCurrentSelectedDateForDialog(undefined);
      setCurrentSelectedHours([]);
      setIsToClearHours(false);
    }
  }, [isToShowHourDialog]);

  const hasSelectedDays = () => {
    return Object.keys(selectedDays).length > 0;
  };

  const handleSelectHour = (time: string) => {
    const alreadyHasTime = currentSelectedHours.includes(time);

    if (alreadyHasTime) {
      setCurrentSelectedHours((currentSelectedHours) =>
        currentSelectedHours.filter((item) => item !== time),
      );
      return;
    }

    setCurrentSelectedHours((currentSelectedHours) => [...currentSelectedHours, time]);
  };

  const handleSelectCalendarDay = (day: string) => {
    setIsToShowHourDialog(true);
    setCurrentSelectedDateForDialog(day);
    setIsToShowClearHours(!!selectedDays[day]);
    setIsToClearHours(false);
  };

  const saveSelectedDate = () => {
    if (isToClearHours) {
      return clearSelectedDate();
    }

    if (currentSelectedHours.length === 0) {
      return clearSelectedDate();
    }

    setSelectedDays((selectedDays) => {
      return {
        ...selectedDays,
        [currentSelectedDateForDialog!]: { hours: currentSelectedHours },
      };
    });
  };

  const clearSelectedDate = () => {
    setSelectedDays((selectedDays) => omit(selectedDays, currentSelectedDateForDialog!));
  };

  const getDatesFromSelectedDays = () => {
    return Object.keys(selectedDays).map((day) => parseISO(day));
  };

  return (
    <S.FullContainer>
      <S.TitleWrapper>
        <Typography variant="title">Qual dia?</Typography>
      </S.TitleWrapper>
      <S.BodyWrapper>
        <Typography variant="body2">
          <>
            Selecione
            <Typography variant="bodyHighlight2">{' uma ou até cinco datas '}</Typography>
            para seu atendimento de acordo com sua preferência
          </>
        </Typography>
      </S.BodyWrapper>
      <Card style={{ width: '100%', borderRadius: theme.isMobile ? 10 : 24, padding: 0 }}>
        <S.WrapperCalendar>
          <Calendar
            onSelect={handleSelectCalendarDay}
            maxSelected={5}
            startDate={minDate}
            endDate={maxDate}
            selectedDays={getDatesFromSelectedDays()}
          />
        </S.WrapperCalendar>
      </Card>

      <S.WrapperInfo>
        <S.ScheduleDateDot />
        <Typography variant="body2" color="paragraph">
          Dias selecionados
        </Typography>
      </S.WrapperInfo>
      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button
        disabled={!hasSelectedDays()}
        variant={'primary'}
        onPress={() => {
          setAppointment({ ...appointment, days: selectedDays });
          nextStep();
        }}>
        Próximo
      </Button>

      <Dialog
        title="Qual o horário?"
        subtitle={
          <Typography variant="body2">
            <>
              Selecione <Typography variant="bodyHighlight2">até cinco horários</Typography> que
              gostaria de ser atendido na data selecionada
            </>
          </Typography>
        }
        visible={isToShowHourDialog}
        onClose={() => {
          setIsToShowHourDialog(false);
        }}>
        <View className="mt-16 justify-center desktop:mt-40 desktop:flex-1">
          <TimeTable
            onSelectTime={handleSelectHour}
            selected={currentSelectedHours}
            maxSelected={5}
          />

          <S.WrapperCheckBox isToShowClearHours={isToShowClearHours}>
            {isToShowClearHours && (
              <>
                <Checkbox
                  value={1}
                  checked={isToClearHours}
                  onPress={() => setIsToClearHours(!isToClearHours)}
                />
                <Spacing left={10}>
                  <Typography
                    variant="body2"
                    color="placeholder"
                    onPress={() => setIsToClearHours(!isToClearHours)}>
                    Desmarcar data e horários
                  </Typography>
                </Spacing>
              </>
            )}
          </S.WrapperCheckBox>
        </View>

        <View className="mb-16 mt-20 desktop:mb-0 desktop:mt-28">
          <Button
            variant={'primary'}
            onPress={() => {
              setIsToShowHourDialog(false);
              saveSelectedDate();
            }}>
            Próximo
          </Button>
        </View>
      </Dialog>
    </S.FullContainer>
  );
};
