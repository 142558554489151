import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';
import {
  IRechargeByPixPayload,
  IRechargeByPixRes,
} from '~/services/api/olimpo/interfaces/recharge-domain';
import { IOlimpoRes } from '~/services/api/olimpo/interfaces/olimpo-res';

export class RechargeDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async rechargeByPix({ amount, onix_code, cardNumber }: IRechargeByPixPayload) {
    const res = await this.olimpoService.axios.post<IOlimpoRes<IRechargeByPixRes>>(
      '/v1/hello/recharges/pix',
      {
        amount,
        onix_code,
        cardNumber,
      },
    );

    return res.data;
  }
}
