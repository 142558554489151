import styled from 'styled-components/native';
import { css } from 'styled-components';
import { getDimensions } from '~/components/@hello-ui/Theme/utils';

export const WrapperCard = styled.TouchableOpacity`
  ${() => css`
    width: 33.3%;

    ${getDimensions().width < 386 &&
    css`
      width: 50%;
    `}
  `}
`;
