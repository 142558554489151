import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useLogin } from './state/useLogin';
import { StepTypes } from './state/types';
import {
  Welcome,
  DocumentNumber,
  NotFound,
  VerifyCode,
  CodeError,
  Password,
  CreatePassword,
  NoProducts,
  Success,
  NoPhone,
  PhoneNumber,
} from './components';
import * as S from './styles';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import ActivityIndicator from '~/components/@tem-ui/ActivityIndicator';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';
import { WrapperHeightProvider } from '~/screens/Login/context/WrapperHeightContext';

type ComponentsStepsType = {
  step: StepTypes;
  component: JSX.Element;
};

export const Login = (): JSX.Element => {
  const [wrapperHeight, setWrapperHeight] = useState(Dimensions.get('window').height);
  const { step, setStep } = useLogin();

  useFocusEffect(
    useCallback(() => {
      setStep('welcome');
    }, []),
  );

  const componentsSteps: ComponentsStepsType[] = [
    {
      step: 'welcome',
      component: <Welcome />,
    },
    {
      step: 'document_number',
      component: <DocumentNumber />,
    },
    {
      step: 'not_found',
      component: <NotFound />,
    },
    {
      step: 'verify_code',
      component: <VerifyCode />,
    },
    {
      step: 'error_code',
      component: <CodeError />,
    },
    {
      step: 'password',
      component: <Password />,
    },
    {
      step: 'create_password',
      component: <CreatePassword />,
    },
    {
      step: 'error_products',
      component: <NoProducts />,
    },
    {
      step: 'success',
      component: <Success />,
    },
    {
      step: 'phone_number',
      component: <PhoneNumber />,
    },
    {
      step: 'no_phone',
      component: <NoPhone />,
    },
  ];

  const CurrentStepLogin = useMemo(
    () => componentsSteps.find((cs) => cs.step === step)?.component,
    [step],
  );

  return (
    <CustomKeyboardAvoidingView>
      <WrapperHeightProvider value={wrapperHeight}>
        <S.Wrapper
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flexGrow: 1,
          }}
          onLayout={(ev) => {
            setWrapperHeight(ev.nativeEvent.layout.height);
          }}>
          <Suspense fallback={<ActivityIndicator color={StyledDefaultTheme.colors.success} />}>
            {CurrentStepLogin}
          </Suspense>
        </S.Wrapper>
      </WrapperHeightProvider>
    </CustomKeyboardAvoidingView>
  );
};
