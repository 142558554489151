import React, { useCallback, useEffect, useState } from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { format } from 'date-fns';
import MaterialIcon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';
import IconIon from 'react-native-vector-icons/dist/Ionicons';
import Clipboard from '@react-native-clipboard/clipboard';
import { useNavigation } from '@react-navigation/native';
import { Button, CardButton, IconBadge, Typography, useSweetAlert } from '~/components/@hello-ui';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { currency } from '~/utils/strings';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export const PaymentBilletSuccess = () => {
  const navigation = useNavigation();
  const { rechargeAmount, billetUrl, billetCode } = useRecharge();
  const theme = useTheme();
  const { tracking } = useTracking();
  const { isDeepLink, showFooter } = DeepLink.useParams();
  const { authStrategy } = useAuthStore();

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const openUrl = useCallback(
    async (url: string) => {
      const linkValid = url && (await Linking.canOpenURL(url));

      if (linkValid) {
        await Linking.openURL(url);
      } else {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível abrir o boleto. Tente novamente mais tarde',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'OK',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      }
    },
    [showSweetAlert],
  );

  useEffect(() => {
    void tracking.addMovement({
      event: trackingEventType.BalanceAddedOnCard,
    });
  }, []);

  useEffect(() => {
    DeepLink.sendEvent(DeepLinkEvent.Success);
  }, []);

  return (
    <>
      <View className="mt-80 w-full max-w-[562px] self-center mobile:mt-48">
        <View className="items-center px-16">
          <IconBadge
            icon={<FeatherIcon name="check" size={38} color="white" />}
            size={64}
            backgroundColor="success"
          />
          <Typography variant="h3" className="mt-24">
            Seu boleto foi emitido
          </Typography>
          <Typography variant="body2" className="mb-40 text-center mobile:mb-24">
            e enviado para o e-mail cadastrado.
          </Typography>
        </View>
        <View className="mb-40 w-full flex-row border-y-[1px] border-y-background-gray p-16 mobile:mb-24">
          <View className="mr-16 h-24 w-24 items-center justify-center">
            <View className="inset-x-0 inset-y-0 h-full w-full rounded-full bg-warning opacity-20"></View>
            <View className="absolute">
              <MaterialIcon name="arrow-up" size={16} color={theme.colors.warning} />
            </View>
          </View>
          <View className="flex-1">
            <Typography variant="bodyHighlight2">Recarga Pendente</Typography>
            <Typography variant="body2">{format(new Date(), 'dd/MM/yyyy')}</Typography>
          </View>
          <Typography variant="bodyHighlight2" className="pl-16">
            {currency(rechargeAmount, 'pt-BR', 'BRL', true)}
          </Typography>
        </View>
        <View className="items-center px-16">
          <View className="mb-24 mobile:mb-16">
            <IconBadge
              icon={<IconIon name="alert-outline" size={theme.isMobile ? 18 : 24} color="white" />}
              backgroundColor="warning"
              size={theme.isMobile ? 24 : 32}
              borderWidth={1}
            />
          </View>
          <View className="w-full">
            <Typography variant="body2" className="mb-40 text-center">
              Para completar sua recarga,{' '}
              <Typography variant="bodyHighlight2">
                faça o pagamento do boleto antes da data de vencimento
              </Typography>{' '}
              em qualquer agência bancária, casa lotérica ou aplicativo do seu banco.
            </Typography>
            <Button
              variant="primary"
              className="mb-40 w-full mobile:mb-24"
              onPress={() => openUrl(billetUrl ?? '')}>
              Ver Boleto
            </Button>
            <Typography variant="body2" className="mb-16 text-center">
              Caso prefira, copie abaixo o código do seu boleto para realizar o pagamento:
            </Typography>
            <Typography variant="helperText" className="mb-40 text-center mobile:mb-16">
              {billetCode}
            </Typography>
            <TouchableOpacity
              className="flex-row items-center justify-center"
              onPress={() => Clipboard.setString(billetCode)}>
              <FeatherIcon
                name="link"
                size={theme.isMobile ? 16 : 24}
                color={theme.colors.primary}
              />
              <Typography variant="bodyHighlight2" color="title" className="ml-16 mobile:ml-8">
                copiar código (linha digitável)
              </Typography>
            </TouchableOpacity>
            <View className="mb-56 mobile:mb-40" />
          </View>
        </View>
      </View>
      {theme.isMobile && (
        <View className="mb-40 h-[1px] w-full border-b-[1px] border-b-background-gray" />
      )}
      {!isDeepLink && authStrategy !== 'CertificateStrategy' ? (
        <View className="flex-row justify-center self-stretch px-16 mobile:justify-start">
          <View className="mr-8 w-[166px] mobile:mr-8 mobile:w-[132px]">
            <CardButton
              icon={<StethoscopeIcon height={32} width={32} />}
              title="Agendar"
              variant="small"
              orientation="vertical"
              onPress={() => navigation.navigate('ScheduleAppointment')}
            />
          </View>

          <View className="ml-8 w-[166px] mobile:w-[132px]">
            <CardButton
              icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
              title="Início"
              variant="small"
              orientation="vertical"
              onPress={() => navigation.navigate('Dashboard')}
            />
          </View>
        </View>
      ) : (
        <View className="ml-8 w-[166px] mobile:w-[132px]">
          <CardButton
            icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
            title="Início"
            variant="small"
            orientation="vertical"
            onPress={() => navigation.navigate('Dashboard')}
          />
        </View>
      )}

      <View className="mobile:h-35 mt-auto h-80" />
      {!isDeepLink ||
        (isDeepLink && showFooter) ||
        (authStrategy === EAuthStrategy.Certificate && (
          <View
            className="w-full self-stretch p-24 pb-[26px] mobile:p-16 mobile:pb-[18px]"
            style={{ backgroundColor: theme.colors.background }}>
            <View className="w-full max-w-[530px] self-center">
              <View className="mb-8">
                <Typography variant="h3">Central de atendimento</Typography>
              </View>

              <View className="mb-16">
                <Typography variant="body2">Segunda à Sexta das 7h às 19h</Typography>
                <Typography variant="bodyItalic2">Exceto feriados nacionais</Typography>
              </View>

              <View className="flex-row justify-between mobile:flex-col mobile:items-start">
                <View className="mb-16">
                  <Typography
                    variant="link"
                    color="primary"
                    style={{ textAlign: 'left' }}
                    onPress={() => Linking.openURL('tel:1140001640')}>
                    (11) 4000 1640
                  </Typography>
                  <Typography variant="helperText">Capitais e regiões metropolitanas</Typography>
                </View>

                <View>
                  <Typography
                    variant="link"
                    color="primary"
                    style={{ textAlign: 'left' }}
                    onPress={() => Linking.openURL('tel:08008368836')}>
                    0800 836 88 36
                  </Typography>
                  <Typography variant="helperText">Demais localidades</Typography>
                </View>
              </View>
            </View>
          </View>
        ))}
    </>
  );
};
