import React, { ComponentType, ReactElement } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';
import { IconProps } from '~/components/Icons/types';

interface StepProps {
  stepIcons: ComponentType<IconProps>[];
  currentStep: number;
}

export const Steps: React.FC<StepProps> = ({ stepIcons, currentStep }) => {
  const theme = useTheme();
  const isCompleted = currentStep === stepIcons.length - 1;

  return (
    <View className="flex-row items-center justify-center">
      {stepIcons.map((Icon, index) => (
        <View className="max-w-[100px] flex-1 flex-row" key={index}>
          <View className="flex-1 flex-row items-center">
            <View
              className={clsx(index === 0 && 'hidden', 'h-4 flex-1')}
              style={{
                backgroundColor: isCompleted
                  ? theme.colors.success
                  : index <= currentStep
                  ? theme.colors.primary
                  : theme.colors['background-gray'],
              }}
            />
          </View>
          <View
            style={{
              backgroundColor: isCompleted
                ? theme.colors.success
                : index <= currentStep
                ? theme.colors.primary
                : theme.colors['background-gray'],
            }}
            className="h-56 w-56 items-center justify-center rounded-full mobile:h-40 mobile:w-40">
            <Icon
              width={theme.isMobile ? 20 : 28}
              height={theme.isMobile ? 20 : 28}
              color={
                isCompleted || index <= currentStep ? theme.colors.white : theme.colors.textDisabled
              }
            />
          </View>
          <View className="flex-1 flex-row items-center">
            <View
              className={clsx(index === stepIcons.length - 1 && 'hidden', 'h-4 flex-1')}
              style={{
                backgroundColor: isCompleted
                  ? theme.colors.success
                  : index + 1 <= currentStep
                  ? theme.colors.primary
                  : theme.colors['background-gray'],
              }}
            />
          </View>
        </View>
      ))}
    </View>
  );
};
