import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { StepTypes } from './types';
import { Wrapper } from '~/components/@tem-ui';

export const Container = styled(Wrapper)`
  padding-top: 0px;
`;

export const WrapperHeader = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
  padding-top: 48px;
  padding-bottom: 64px;
  margin-bottom: 124px;
`;

export const WrapperHeaderContainer = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
  `}
`;

export const ContainerCard = styled.View`
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: ${({ theme: { isMobile } }) => (isMobile ? '23%' : '14.5rem')};
  left: ${({ theme: { isMobile } }) => (isMobile ? '0' : '34.1vm')};
  margin: auto;
  z-index: 9999;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding: 16px;
  `}
`;

export const WrapperCard = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile
      ? `shadow-color: #000;
    shadow-offset: {width: 1, height: 2};
    shadow-opacity: 0.15;
    shadow-radius: 30px;
    elevation: 6;`
      : 'box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15)'};
  flex-direction: column;
  padding-top: 16px;
`;

export const WrapperCardContent = styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  margin-bottom: 16px;
`;

export const WrapperCardHeader = styled.View`
  flex: 1;
  justify-content: center;
`;

export const WrapperCardIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 56px;
  height: 56px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

export const WrapperCardActions = styled.View`
  flex-direction: row;
  border-top-width: 0.5px;
  border-top-color: ${({ theme: { colors } }) => colors.black16};
`;

export const Action = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-vertical: 12px;
  border-color: ${({ theme: { colors } }) => colors.black16};
`;

export const ActionLeft = styled(Action)`
  border-right-width: 0.25px;
`;

export const ActionRight = styled(Action)`
  border-left-width: 0.25px;
`;

export const WrapperCards = styled.View`
  flex-direction: row;
  margin-horizontal: -12px;
  margin-top: 40px;
  margin-bottom: 30px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    margin-top: 70px;
    flex-direction: row;
    padding-horizontal: 16px;
    margin-bottom: 40px;
  `
      : `
    margin-horizontal: -12px;
  `}
`;

export const CardItem = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  max-width: 288px;
  height: 118px;
  border-radius: 10px;
  padding: 16px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin-horizontal: 12px;
      height: 128px;
      max-width: 132px;
      margin-horizontal: 8px;
      shadow-color: #000;
      shadow-offset: {width: 1, height: 1};
      shadow-opacity: 0.15;
      shadow-radius: ${Platform.OS === 'android' ? '30px' : '4px'};
      elevation: 6;
  `
      : `
    margin-horizontal: 12px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    elevation: 2;
  `}
`;

export const CardItemIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: auto;
`;

export const WrapperContent = styled.View`
  width: 100%;
  max-width: 600px;
  margin-bottom: 64px;
  margin-horizontal: auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  padding: 16px;
`}
`;

export const WrapperSteps = styled.View`
  max-width: 316px;
  height: 52px;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px;
  position: relative;
`;

export const WrapperStep = styled.View<StepTypes>`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.primary50 : colors.white};
  width: 52px;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
`;

export const StepsBar = styled.View<{ right?: boolean; unloaded?: string }>`
  width: ${({ unloaded }) => unloaded ?? '100%'};
  height: 4px;
  position: absolute;
  top: 50%;
  z-index: -1;

  ${({ theme: { colors }, right }) =>
    right
      ? `
      background-color: ${colors.primary50};
      right: 0;
    `
      : `
      background-color: ${colors.white};
      left: 0;
  `}
`;

export const WrapperColumn = styled.View`
  flex: 1;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex: auto;
    margin-vertical: 16px;
  `}
`;

export const WrapperFooter = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 100%;
  min-height: 200px;
  flex-direction: column;
  min-height: auto;
  margin-top: -45px;
  padding: 30px 32px;
`;

export const WrapperIcon = styled.View`
  width: 100%;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    display:flex;
  `}
  justify-content:center;
  align-items: center;
`;

export const WrapperTelemedicineHeader = styled.View`
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    display:flex;
  `}
  justify-content:center;
  align-items: center;
  flex-direction: column;
`;
