import React from 'react';
import { Defs, G, Path, Rect, Svg, ClipPath } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const SchedulesAllTimeIcon = ({ color, width = 16, height = 16 }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <G clipPath="url(#clip0_7499_96842)">
        <Path
          d="M11.6465 12.0304H14.0615C14.2907 12.0304 14.4846 11.8365 14.4846 11.6073V3.26927C14.4846 3.04006 14.2907 2.84616 14.0615 2.84616H1.93338C1.70417 2.84616 1.51027 3.04008 1.51027 3.26927V11.625C1.51027 11.8542 1.70419 12.0481 1.93338 12.0481H4.36595C7.6838 12.0303 5.53709 12.0481 6.77092 12.0481C8.00476 12.0481 8.23481 12.0304 11.6465 12.0304ZM15.3307 3.2693V11.625C15.3307 12.3125 14.7666 12.8942 14.0615 12.8942H11.4349L8.66406 12.8941V14.1809H11.4525C11.5406 14.1809 11.6111 14.2514 11.6111 14.3395V14.5863C11.6111 14.6745 11.5406 14.7449 11.4525 14.7449L4.54226 14.7451C4.4541 14.7451 4.38364 14.6746 4.38364 14.5864V14.322C4.38364 14.2338 4.4541 14.1633 4.54226 14.1633H7.33073V12.8765H1.93328C1.24579 12.8765 0.664062 12.3123 0.664062 11.6073V3.26922C0.664062 2.56416 1.22822 2 1.93328 2H14.079C14.7665 2 15.3307 2.56411 15.3307 3.2693Z"
          fill={color ?? theme.colors.primary}
        />
        <Path
          d="M3.776 9.736C4.288 9.30933 4.696 8.95467 5 8.672C5.304 8.384 5.55733 8.08533 5.76 7.776C5.96267 7.46667 6.064 7.16267 6.064 6.864C6.064 6.592 6 6.37867 5.872 6.224C5.744 6.06933 5.54667 5.992 5.28 5.992C5.01333 5.992 4.808 6.08267 4.664 6.264C4.52 6.44 4.44533 6.68267 4.44 6.992H3.352C3.37333 6.352 3.56267 5.86667 3.92 5.536C4.28267 5.20533 4.74133 5.04 5.296 5.04C5.904 5.04 6.37067 5.20267 6.696 5.528C7.02133 5.848 7.184 6.272 7.184 6.8C7.184 7.216 7.072 7.61333 6.848 7.992C6.624 8.37067 6.368 8.70133 6.08 8.984C5.792 9.26133 5.416 9.59733 4.952 9.992H7.312V10.92H3.36V10.088L3.776 9.736ZM8.16975 9.864V8.984L10.7858 5.24H12.0898V8.888H12.7938V9.864H12.0898V11H10.9698V9.864H8.16975ZM11.0418 6.44L9.40175 8.888H11.0418V6.44Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7499_96842">
          <Rect width={width} height={height} fill={color ?? theme.colors.primary} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SchedulesAllTimeIcon;
