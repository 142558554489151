import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

export const WrapperImageAuthorizer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ImageAuthorizerWrapper = styled.View``;

export const ImageAuthorizer = styled.Image.attrs({
  resizeMode: 'contain',
})<{ marginLeft?: boolean; marginRight?: boolean; flex: number }>`
  height: 81px;
  flex-grow: ${({ flex }) => flex};
  flex-shrink: 1;
  flex-basis: 0%;

  margin-left: ${({ marginLeft }) => (marginLeft ? '4px' : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? '4px' : '0px')};

  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `}
`;
