import { Animated, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const WrapperDialog = styled.SafeAreaView`
  ${({ theme: { tokens } }) => css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    height: auto;
    min-height: 652px;
    align-items: center;
    justify-content: center;
    padding: ${tokens.space.s52} ${tokens.space.s0};
  `}
`;

export const WrapperPopUp = styled.View`
  ${({ theme: { tokens, colors } }) => css`
    height: auto;
    min-height: 652px;
    max-width: 770px;
    align-items: center;
    justify-content: center;
    width: ${tokens.sizes.full};
    background-color: ${colors.white};
    border-radius: ${tokens.borderRadius.xl};
    padding-bottom: ${tokens.space.s52};
  `}
`;

export const PopUpCard = styled.View`
  width: 100%;
  max-width: 530px;
  align-items: center;
  justify-content: center;
  /* 652 - 52px padding */
  min-height: 600px;
`;

export const WrapperBackground = styled(Animated.View)`
  background-color: ${({ theme: { colors, isMobile } }) =>
    isMobile ? colors.white : colors.black};
  flex: 1;
  width: 100%;
  height: 100%;
  position: ${Platform.OS !== 'web' ? 'absolute' : 'fixed'};
`;

export const WrapperTitle = styled.View`
  margin-top: 16px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
`;

export const WrapperDialogScroll = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})``;
