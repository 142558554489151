import React, { useCallback, useState } from 'react';
import { ListRenderItemInfo } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Typography, useDialog } from '../../@tem-ui';
import { DependentsTypes } from './types';
import * as S from './styles';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { capitalize } from '~/utils/strings';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';

interface Dependent {
  navigation: StackNavigationProp<any, any>;
  _nextStep?: boolean | undefined;
  toScreen?: string;
  onSelect?: () => {};
  onlyOwnerCallback?: () => {};
  origin?: 'telemedicine_24';
}

interface Loading {
  status: boolean;
  cpf?: '';
}

export const Dependents = ({
  navigation,
  _nextStep,
  toScreen,
  onSelect,
  origin,
}: Dependent): JSX.Element => {
  const theme = useTheme();

  const { closeDialog } = useDialog();
  const { setPatient } = useAppointment();
  const { nextStep } = useStepper();
  const { tracking } = useTracking();
  const { dependents, user, loadingDependets, isDependent } = useAuth();

  const [loading, setLoading] = useState<Loading>({ status: false });

  const dependentsLimit = theme.dependents_limit === '' ? 0 : theme.dependents_limit;

  const activeDependents =
    dependents?.filter((d, idx) => d.status !== 3 && idx < parseInt(dependentsLimit)) ?? [];

  const keyExtractor = useCallback((item, idx: number) => idx.toString(), []);

  const renderItem = useCallback(
    ({ item, index }: { item: DependentsTypes; index: number }) => {
      const { name, cpf } = item;
      return (
        <S.WrapperItem
          last={index === activeDependents.length}
          disabled={loading.status}
          onPress={() => {
            setLoading({ status: true, cpf });

            void tracking.addMovement({
              event:
                origin === 'telemedicine_24'
                  ? trackingEventType.ClickedOn24HVideoClickPatient
                  : trackingEventType.ClickedOnPatient,
              metadata: {
                patient_info: { ...item, isDependent },
              },
            });

            closeDialog();
            setPatient({ ...item, id: cpf.replace(/[^\d]/g, '') });
            onSelect && onSelect();
            setLoading({ status: false });

            if (_nextStep) {
              nextStep();
            } else {
              navigation.navigate(toScreen ?? 'ScheduleAppointment');
            }
          }}>
          <Typography variant="body2" color="black">
            {capitalize(name)}
          </Typography>
          {loading.status && loading.cpf === cpf ? (
            <S.Loading />
          ) : (
            <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
          )}
        </S.WrapperItem>
      );
    },
    [closeDialog],
  );

  const currentUser = useCallback(
    () => ({
      id: user?.id ?? '',
      name: user?.name ?? '',
      cpf: user?.cpf ?? '',
      birthdate: user?.birthdate ?? '',
    }),
    [user],
  );

  return (
    <S.Wrapper>
      {loadingDependets ? (
        <S.WrapperLoading>
          <S.Loading />
        </S.WrapperLoading>
      ) : (
        <S.WrapperList<any>
          ListHeaderComponent={renderItem({
            item: currentUser(),
            index: activeDependents.length,
          })}
          data={activeDependents}
          keyExtractor={keyExtractor}
          renderItem={({ item, index }: ListRenderItemInfo<DependentsTypes>) =>
            renderItem({ item, index })
          }
          ItemSeparatorComponent={S.Divider}
          showsVerticalScrollIndicator={false}
        />
      )}
    </S.Wrapper>
  );
};
