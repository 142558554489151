import React, { useEffect } from 'react';
import { Animated, Dimensions, Modal, Easing } from 'react-native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useMenu } from './hooks';
import * as S from './styles';
import { Typography } from '~/components/@tem-ui';

export const Menu = (): JSX.Element => {
  const theme = useTheme();
  const { menuState, menuTitle, menuContent, closeMenu } = useMenu();

  const animatedValue = new Animated.Value(472);

  const onClose = () => {
    Animated.timing(animatedValue, {
      duration: 250,
      toValue: Dimensions.get('window').width + 472,
      useNativeDriver: true,
      easing: Easing.back(0.1),
    }).start(() => closeMenu());
  };

  useEffect(() => {
    if (menuState) {
      Animated.timing(animatedValue, {
        duration: 250,
        toValue: 0,
        useNativeDriver: true,
        easing: Easing.elastic(0.6),
      }).start();
    }
  }, [menuState]);

  return (
    <Modal transparent animationType="none" presentationStyle="overFullScreen" visible={menuState}>
      {menuState && (
        <S.SafeWrap>
          <S.ModalMenuBackdrop onPress={onClose} />
          <S.Wrapper style={{ transform: [{ translateX: animatedValue }] }}>
            <S.WrapperClose onPress={onClose}>
              <Icon name="x" size={18} color={theme?.colors?.primary} />
            </S.WrapperClose>
            <S.WrapperTitle>
              <Typography variant="heading2" color="black70">
                {menuTitle}
              </Typography>
            </S.WrapperTitle>
            <S.WrapperItens>{menuContent}</S.WrapperItens>
          </S.Wrapper>
        </S.SafeWrap>
      )}
    </Modal>
  );
};
