import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const BusinessIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 14 14" fill="none" >
        <Path
          id="Vector"
          d="M13.4026 12.9666H12.9729V6.46664C12.9729 6.31932 12.8536 6.20001 12.7063 6.20001H10.1588V3C10.1588 2.85268 10.0395 2.73337 9.89221 2.73337H8.98272C8.8517 1.75649 8.01486 1 7.00258 1C5.99029 1 5.15357 1.75637 5.02243 2.73337H4.11317C3.96574 2.73337 3.84654 2.85279 3.84654 3V6.20001H1.29888C1.15145 6.20001 1.03225 6.31943 1.03225 6.46664V12.9666H0.602566C0.455132 12.9666 0.335938 13.0861 0.335938 13.2333C0.335938 13.3806 0.455246 13.4999 0.602566 13.4999H4.11314L5.91092 13.5H13.4026C13.5501 13.5 13.6693 13.3806 13.6693 13.2334C13.6693 13.0861 13.5501 12.9666 13.4026 12.9666L13.4026 12.9666ZM7.00258 1.53349C7.81118 1.53349 8.46921 2.1914 8.46921 3.00012C8.46921 3.80883 7.81118 4.46675 7.00258 4.46675C6.19397 4.46675 5.53595 3.80883 5.53595 3.00012C5.53595 2.1914 6.19397 1.53349 7.00258 1.53349ZM1.56571 6.73349H3.84674V12.9669L1.56571 12.9668V6.73349ZM4.38 6.46675V3.26675H5.02252C5.15354 4.24364 5.99037 5.00012 7.00266 5.00012C8.01495 5.00012 8.85166 4.24375 8.98281 3.26675H9.62567V12.9668H8.36104V10.7501C8.36104 10.6028 8.24173 10.4835 8.09441 10.4835H5.91103C5.7636 10.4835 5.6444 10.6029 5.6444 10.7501V12.9668H4.38011L4.38 6.46675ZM6.17766 12.9668V11.0168H7.82766V12.9668H6.17766ZM10.1588 12.9668V6.73333H12.4395V12.9668H10.1588Z"
          fill={color ?? theme.colors.primary}
        />
    </Svg>
  );
};

export default BusinessIcon;
