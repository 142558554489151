import React, { useEffect } from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import IonIcon from 'react-native-vector-icons/dist/Ionicons';
import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { CardButton, IconBadge, Typography } from '~/components/@hello-ui';
import StethoscopeIcon from '~/components/Icons/Stethoscope';

import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';

import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export const PaymentCreditCardSuccess = () => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { tracking } = useTracking();
  const { isDeepLink, showFooter } = DeepLink.useParams();
  const { authStrategy } = useAuthStore();

  useEffect(() => {
    void tracking.addMovement({
      event: trackingEventType.BalanceAddedOnCard,
    });
  }, []);

  useEffect(() => {
    DeepLink.sendEvent(DeepLinkEvent.Success);
  }, []);

  return (
    <>
      <View className="mt-80 max-w-[350px] items-center self-center pb-[128px] mobile:mx-16 mobile:mt-48 mobile:max-w-none mobile:pb-36">
        <IconBadge
          icon={<IonIcon name="alert-outline" size={38} color="white" />}
          size={64}
          backgroundColor="warning"
        />
        <Typography variant="h3" className="my-24 mobile:my-36">
          Pagamento em análise
        </Typography>
        <View className="mb-24 mobile:mb-36">
          <Typography variant="bodyHighlight2" className="mb-12 text-center">
            Sua recarga está quase concluída!
          </Typography>
          <Typography variant="body2" className="text-center">
            Aproveite e agende já seu atendimento para começar a cuidar da sua saúde!
          </Typography>
        </View>
        {!isDeepLink && authStrategy !== EAuthStrategy.Certificate && (
          <View className="flex-row self-stretch">
            <View className="mr-16 w-[166px] mobile:mr-8 mobile:w-[132px]">
              <CardButton
                icon={<StethoscopeIcon height={32} width={32} />}
                title="Agendar"
                variant="small"
                orientation="vertical"
                onPress={() => navigation.navigate('ScheduleAppointment')}
              />
            </View>

            <View className="w-[166px] mobile:w-[132px]">
              <CardButton
                icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
                title="Início"
                variant="small"
                orientation="vertical"
                onPress={() => navigation.navigate('Dashboard')}
              />
            </View>
          </View>
        )}
        {authStrategy === 'CertificateStrategy' && (
          <View className="flex-row justify-center self-stretch px-16 mobile:justify-start">
            <View className="ml-8 w-[166px] mobile:w-[132px]">
              <CardButton
                icon={<FeatherIcon name="home" size={24} color={theme?.colors.primary} />}
                title="Início"
                variant="small"
                orientation="vertical"
                onPress={() => navigation.navigate('Dashboard')}
              />
            </View>
          </View>
        )}
      </View>
      {(!isDeepLink || (isDeepLink && showFooter)) &&
        authStrategy !== EAuthStrategy.Certificate && (
          <View
            className="w-full self-stretch p-24 pb-[26px] mobile:p-16 mobile:pb-[18px]"
            style={{ backgroundColor: theme.colors.background }}>
            <View className="w-full max-w-[530px] self-center">
              <View className="mb-8">
                <Typography variant="h3">Central de atendimento</Typography>
              </View>

              <View className="mb-16">
                <Typography variant="body2">Segunda à Sexta das 7h às 19h</Typography>
                <Typography variant="bodyItalic2">Exceto feriados nacionais</Typography>
              </View>

              <View className="flex-row justify-between mobile:flex-col mobile:items-start">
                <View className="mb-16">
                  <Typography
                    variant="link"
                    color="primary"
                    style={{ textAlign: 'left' }}
                    onPress={() => Linking.openURL('tel:1140001640')}>
                    (11) 4000 1640
                  </Typography>
                  <Typography variant="helperText">Capitais e regiões metropolitanas</Typography>
                </View>

                <View>
                  <Typography
                    variant="link"
                    color="primary"
                    style={{ textAlign: 'left' }}
                    onPress={() => Linking.openURL('tel:08008368836')}>
                    0800 836 88 36
                  </Typography>
                  <Typography variant="helperText">Demais localidades</Typography>
                </View>
              </View>
            </View>
          </View>
        )}
    </>
  );
};
