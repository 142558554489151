import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { Linking, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { MEDEX24_URL } from '@env';
import { Queue } from './components/Queue';
import SwiperComponent from '~/components/Swiper';

import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { useAuth } from '~/auth/legacy/useAuth';
import { useAppointment } from '~/hooks/appointment';
import { useApi } from '~/hooks/api';

import WifiIcon from '~/components/Icons/WifiIcon';
import CameraIcon from '~/components/Icons/CameraIcon';
import MicrophoneIcon from '~/components/Icons/MicrophoneIcon';
import SoundIcon from '~/components/Icons/SoundIcon';

import { Button, Dialog, MobileAutoSpace, Typography, useSweetAlert } from '~/components/@hello-ui';

const slides: {
  icon: ReactNode;
  title: string;
  description: string;
}[] = [
  {
    icon: <WifiIcon width={48} height={48} />,
    title: 'Conexão com a internet',
    description:
      'Para garantir um atendimento estável no momento de sua consulta por vídeo, recomendamos que você utilize uma rede wi-fi confiável ou que permaneça em local onde o sinal do celular tenha boa qualidade.',
  },
  {
    icon: <CameraIcon width={48} height={48} />,
    title: 'Acesso à câmera',
    description:
      'Será necessário aceitar a permissão de acesso à câmera do seu celular, que irá aparecer como uma notificação.',
  },
  {
    icon: <MicrophoneIcon width={48} height={48} />,
    title: 'Acesso ao microfone',
    description:
      'Será necessário aceitar a permissão de acesso ao microfone, que irá aparecer como uma notificação.',
  },
  {
    icon: <SoundIcon width={48} height={48} />,
    title: 'Som do dispositivo',
    description: 'Confirme se o áudio do seu dispositivo está funcionando corretamente.',
  },
];

const afinsTelcom: string[] = [
  '5155',
  '7041',
  '7206',
  '7240',
  '7305',
  '7306',
  '7300',
  '7301',
  '7302',
  '7441',
  '7439',
  '7453',
  '7206',
  '7564',
];

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const ScheduleAllTimeSteps = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();
  const { user, onixCode: currentProduct } = useAuth();
  const { patient } = useAppointment();
  const { tracking } = useTracking();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [videoCallUrl, setVideoCallUrl] = useState<string | null>(null);
  const [showVideoCall, setShowVideoCall] = useState<boolean>(false);

  const swiperRef = useRef(null);

  const onConnectBrasilTelecom = useCallback(async () => {
    try {
      const { data } = await api.accessesBrasilTelecom(
        patient?.name,
        patient?.cpf,
        user?.email,
        user?.telephone_1,
        user?.gender === 'masculino' ? 1 : 0,
        formatDate(user?.birthdate),
        user?.address.postal_code,
        user?.address.address,
        user?.address.number,
        user?.address.neighborhood,
        user?.address.city,
        user?.address.state,
        currentProduct?.onix_code === '7305' ? 2 : 1,
        currentProduct?.onix_code,
      );

      void Linking.openURL(data as string);
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não consguimos conexão com a BrasilTelecom, tente novamente mais tarde.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } finally {
      setLoading(false);
    }
  }, [patient, user, currentProduct]);

  const connectMedex = useCallback(async () => {
    const { data } = await api.getMedexLink(patient?.cpf, currentProduct?.onix_code);

    if (data.message) {
      showSweetAlert(
        'Dados em processamento',
        data.message,
        'warning',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }

    if(data.url) {
      if (Platform.OS === "web"){
        return window.location.replace(data.url)
      } else {
        void Linking.openURL(data.url)
      }
    }
    
    setLoading(false);
  }, []);

  const onOpenVideoCall = useCallback(async (url: string) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      showSweetAlert(
        'Ops, algo deu errado',
        `Não encontramos nenhum App para abrir a url: ${url}`,
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  }, []);

  const onCreateSchedule = useCallback(async () => {
    setLoading(true);

    const isBrasilTelemedicine = afinsTelcom.filter((item) => item == currentProduct?.onix_code);

    const isMedex =
      currentProduct?.features?.filter(
        (item) => item.name === 'medex_telemedina24' && item.active,
      ) ?? [];

    void tracking.addMovement({
      event: trackingEventType.ClickedOn24HVideoClickStart,
      metadata: {
        patient_info: patient,
      },
    });

    if (isMedex.length !== 0) {
      return await connectMedex();
    }

    if (isBrasilTelemedicine.length === 0) {
      setDialogState(true);
    } else {
      await onConnectBrasilTelecom();
    }

    setLoading(false);
  }, [currentProduct, patient, tracking]);

  return (
    <>
      <View className="mb-16 items-center">
        <Typography variant="title">Antes de sua consulta, verifique:</Typography>
      </View>
      <View className="h-[364px]">
        <SwiperComponent elementRef={swiperRef}>
          {slides.map((item) => (
            <View className="h-[300px] items-center" key={item.title}>
              <View className="mb-16 mt-40">{item.icon}</View>
              <View className="mb-24">
                <Typography variant="bodyHighlight2" color={theme.colors.primary}>
                  {item.title}
                </Typography>
              </View>
              <Typography variant="body2" color={theme.colors.paragraph}>
                {item.description}
              </Typography>
            </View>
          ))}
        </SwiperComponent>
      </View>
      <MobileAutoSpace />
      <View className="mt-80">
        <Button disabled={loading} variant="primary" onPress={() => onCreateSchedule()}>
          {loading ? 'Aguarde...' : 'Próximo'}
        </Button>
      </View>
      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        {showVideoCall && videoCallUrl ? (
          <View className="h-full w-full items-center justify-center">
            <iframe
              src={videoCallUrl}
              sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-top-navigation-by-user-activation"
              allow="camera; microphone"
              style={{ width: '100%', height: '80vh', border: 'none' }}
            />
          </View>
        ) : (
          <Queue
            onCall={(url: string) => {
              if (Platform.OS === 'web') {
                setVideoCallUrl(url);
                setShowVideoCall(true);
              } else {
                void onOpenVideoCall(url);
              }
            }}
            onCancel={() => {
              setVideoCallUrl(null);
              setDialogState(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};
