import React, { useEffect } from 'react';
import { AuthService } from '~/auth/auth-service';

export const NotFound = () => {
  useEffect(() => {
    void AuthService.onNotFound();
  }, []);

  return <></>;
};
