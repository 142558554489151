import { useContext } from 'react';
import { AppointmentContext, AppointmentInstance } from '~/contexts/appointment';

export const useAppointment = (): AppointmentInstance => {
  const context = useContext(AppointmentContext);

  if (!context) {
    throw new Error('useAppointment must be used within an AppointmentProvider');
  }

  return context;
};
