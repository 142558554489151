import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';

import { useNavigation } from '@react-navigation/native';
import * as S from './styles';
import { Card, Typography, Wrapper } from '~/components/@tem-ui';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { StyledDefaultTheme, useMedia } from '~/components/@hello-ui';
export const PsychologicalTherapy = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();
  return (
    <>
      {!media.isMobile && <Header />}
      <Wrapper>
        <Card
          style={media.isDesktop ? { overflow: 'auto' } : {}}
          width="100%"
          height={!media.isMobile ? 769 : 'auto'}
          disableShadow={media.isMobile}>
          <S.WrapperHeader>
            <S.ImageFIlter opacity={0.7} color={StyledDefaultTheme.colors.blue}>
              <S.ImageFIlter opacity={0.15} color={StyledDefaultTheme.colors.black}>
                <S.Rectangle1
                  width={media.isMobile ? 120 : 160}
                  height={media.isMobile ? 300 : 400}
                />
                <S.Rectangle2
                  width={media.isMobile ? 120 : 160}
                  height={media.isMobile ? 300 : 400}
                />
              </S.ImageFIlter>
            </S.ImageFIlter>
          </S.WrapperHeader>
          <S.HeaderIcon onPress={() => navigation.navigate('Dashboard')}>
            <Icon name="arrow-left" size={30} color={StyledDefaultTheme.colors.white} />
          </S.HeaderIcon>
          <S.HeaderContent>
            <Typography style={{ marginBottom: 20 }} variant="title2" color="white">
              Terapia Pscicológica (por video)
            </Typography>
          </S.HeaderContent>
          <S.WrapperContent>
            <Typography variant="heading2" color="black">
              Consultas com profissionais de psicologia feitas por vídeo, pra você cuidar da saúde
              mental no conforto de casa.
            </Typography>
            <Typography variant="body2" color="black70"></Typography>
          </S.WrapperContent>
        </Card>
        <Footer />
      </Wrapper>
    </>
  );
};
