import { AppRegistry } from 'react-native';
import * as serviceWorker from './src/serviceWorker';
import App from './src/App';
import { name as appName } from './app.json';

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

// Register service worker to control making site work offline

serviceWorker.register();
