import React, { useEffect } from 'react';
import { Typography } from '../Typography';
import { WrapperHelthAccoutIcon } from './styles';
import { useTheme } from 'styled-components/native';

export const HelthAccountIcon = () => {
  const theme = useTheme();
  return (
    <WrapperHelthAccoutIcon>
      <Typography color={theme.colors.white} variant="body1">
        <>Conta </>
      </Typography>
      <Typography color={theme.colors.white} variant="bodyHighlight1">
        Saúde
      </Typography>
    </WrapperHelthAccoutIcon>
  );
};
