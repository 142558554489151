import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { Typography, Input } from '../../../../components/@tem-ui';
import { Spacing } from '../../../../components/Spacing';
import { Listing, ListingItem } from '../../../../components/Listing';
import * as S from '../../styles';
import { dateBmg } from './atom';
import { TermsConditions } from './TermsConditions';
import { serializeString } from '~/utils/strings';
import { useAppointment } from '~/hooks/appointment';
import { trackingEventType } from '~/contexts/tracking/types';
interface DoctorType {
  crmMedico: string;
  idCalendario: string;
  nomeMedico: string;
  horaDisponivel?: string;
}

export const DoctorsList: React.FC = () => {
  const theme = useTheme();
  const [term, setTerm] = useState<string>('');
  const [doctors, setDoctors] = useState<DoctorType[] | undefined>([]);
  const dataDoctors = useRecoilValue(dateBmg);
  const {
    appointment,
    setAppointment,
    submitAppointment,
    scheduleSubmissionStatus,
    appointmentLoading,
  } = useAppointment();
  const [showTerms, setShowTerms] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    scheduleSubmissionStatus && navigation.navigate('ScheduleAppointmentSuccess');
  }, [scheduleSubmissionStatus]);

  const renderItem = useCallback(({ item }) => {
    const { crmMedico, idCalendario, nomeMedico }: DoctorType = item;
    return (
      <ListingItem
        disabled={appointmentLoading ?? false}
        onPress={() => {
          setAppointment({
            ...appointment,
            idCalendario,
            providerId: crmMedico,
            days: [],
            type: 1,
          });
        }}>
        <S.WrapperClinicsList>
          <Typography variant="heading3" color="black">
            {nomeMedico}
          </Typography>
          <Typography variant="paragraph" color="black">
            {crmMedico}
          </Typography>
        </S.WrapperClinicsList>
        <Icon name="chevron-right" size={22} color={theme?.colors?.primary} />
      </ListingItem>
    );
  }, []);

  const handleSearch = useCallback((text: string) => {
    const query: string = serializeString(text);
    const filteredData = doctors?.filter(({ nomeMedico }: DoctorType) =>
      serializeString(nomeMedico).includes(query),
    );

    setDoctors(filteredData);
    setTerm(text);
  }, []);

  useEffect(() => {
    if (dataDoctors) {
      setDoctors(
        dataDoctors.schedule?.sheduleDoctors.filter((item) => item.date === dataDoctors.date)[0]
          .doctors,
      );
    }
  }, [dataDoctors]);

  useEffect(() => {
    if (!showTerms && appointment?.idCalendario) {
      setShowTerms(true);
    }
  }, [appointment]);

  useEffect(() => {
    if (appointment?.providerId) {
      submitAppointment(trackingEventType.RequestedTelemedicineConecta);
    }
  }, [appointment?.providerId]);

  return (
    <S.WrapperSchedule>
      <Spacing bottom={16}>
        <Typography variant="heading1" color="black" bold>
          Qual profissional?
        </Typography>
      </Spacing>
      {showTerms && (
        <TermsConditions
          onAccept={() => {
            setShowTerms(false);
          }}
          onDimiss={setShowTerms}
        />
      )}
      <S.WrapperSearch>
        <Input
          editable
          value={term}
          onChange={(value) => handleSearch(value)}
          label="Pesquisar profissional"
          placeholder="Pesquisar profissional"
          variant="light"
          icon="search"
        />
      </S.WrapperSearch>
      {(appointmentLoading || showTerms) && (
        <S.WrapperLoading>
          <S.Loading />
        </S.WrapperLoading>
      )}
      <Listing data={doctors} loading={false} renderItem={renderItem} />
    </S.WrapperSchedule>
  );
};
