interface TransactionsTypes {
  [key: string]: string;
}

export const TransactionStatusEnum: TransactionsTypes = {
  AQUISICAO_CARTAO: 'Aquisição de cartão',
  AQUISICAO_CREDITO: 'Recarga',
  CREDITO_BONUS_TEM: 'Recarga',
  CREDITO_BONUS_EMPRESARIAL: 'Recarga',
  TRANSFERENCIA_CREDITO: 'Recarga',
  TRANSFERENCIA_BONUS: 'Recarga',
  ESTORNO_MANUAL: 'Estorno',
  ESTORNO_AUTORIZADOR: 'Estorno',
  ESTORNO_MANUAL_BONUS: 'Estorno',
  ESTORNO_AUTORIZADOR_BONUS: 'Estorno',
  DEVOLUCAO_TOTAL: 'Estorno',
  DEVOLUCAO_TOTAL_BONUS: 'Estorno',
  DEVOLUCAO_PARCIAL: 'Estorno',
  DEVOLUCAO_PARCIAL_BONUS: 'Estorno',
  DEBITO: 'Pagamento',
  DEBITO_BONUS: 'Pagamento',
  COBRANCA_ASSINATURA: 'Pagamento',
  CANCELAMENTO_BONUS: 'Pagamento',
  TAXA_SAQUE: 'Pagamento',
  TAXA_BOLETO: 'Pagamento',
  TAXA_CONVENIENCIA: 'Pagamento',
};
