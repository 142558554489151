import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Plant = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 16} height={height ?? 13} viewBox="0 0 16 13" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M10.4114 3.82622C10.8187 3.51796 11.2658 3.26644 11.7409 3.07862C11.9245 4.33882 11.6792 5.62409 11.0446 6.72828C10.6054 7.49118 9.99309 8.14003 9.25681 8.6226C7.99997 9.43338 8.27606 9.29575 8.36663 9.43338C8.45734 9.57087 8.73326 9.69545 9.59289 9.13246C10.4093 8.59779 11.0881 7.8784 11.5747 7.03258C12.3579 5.69328 12.605 4.10722 12.2667 2.59306C12.222 2.43306 12.0579 2.33813 11.8969 2.37919C11.226 2.58296 10.1469 3.21672 9.59289 3.6465C8.72716 4.24222 9.36486 4.16516 9.46662 4.30004C9.56837 4.43508 10.2774 3.92825 10.4124 3.8265L10.4114 3.82622Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
        <Path
          id="Vector_2"
          d="M6.40665 9.13028C6.54727 9.21635 7.90935 9.93767 7.99992 9.80004C7.99992 9.43337 6.87723 8.71549 6.74274 8.62029C6.00673 8.13771 5.39443 7.48886 4.95534 6.72597C4.32338 5.62167 4.07871 4.33786 4.26025 3.07839C4.73479 3.26703 5.1815 3.51896 5.58851 3.82722C5.72341 3.92897 6.43152 4.43508 6.53326 4.30004C6.63501 4.165 6.89992 3.93337 5.95691 3.33837C5.36387 2.96419 4.77475 2.58368 4.10393 2.37962C3.94298 2.3387 3.77889 2.43363 3.73415 2.59349C3.39574 4.10727 3.64262 5.69306 4.42529 7.03231C4.91198 7.8773 5.59085 8.59613 6.40665 9.13028Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
        <Path
          id="Vector_3"
          d="M15.1101 5.28959C14.4383 5.13273 13.7436 5.09849 13.0595 5.18838C11.629 5.33838 11.609 5.49241 11.629 5.65991C11.6666 6.13333 11.8238 5.95263 13.132 5.79522C13.6285 5.73548 14.1307 5.7423 14.6254 5.81541C14.153 6.99757 13.2987 7.98772 12.1986 8.6285C11.0301 9.27777 9.67835 9.51878 8.3576 9.31308C9.31349 8.1864 9.8367 6.75587 9.83331 5.27826C9.84218 3.72698 9.26373 2.22972 8.21398 1.08736C8.09517 0.970879 7.90503 0.970879 7.78623 1.08736C6.73651 2.22958 6.15802 3.72684 6.16689 5.27826C6.16348 6.75615 6.68686 8.18685 7.64289 9.31377C6.32186 9.52083 4.96947 9.27954 3.80152 8.6285C2.70118 7.98758 1.84679 6.99701 1.37428 5.81443C1.86914 5.7416 3.74875 5.83684 4.24536 5.89699C4.41231 5.91595 4.22545 5.82616 4.24536 5.65921C4.26514 5.49226 4.13358 5.3103 3.96663 5.28956C3.28272 5.19954 1.56167 5.13257 0.890048 5.28956C0.730189 5.33416 0.635117 5.49839 0.676174 5.65921C1.1402 7.14009 2.14767 8.39021 3.49597 9.15837C4.47574 9.71613 5.5852 10.0059 6.71268 9.99886C7.14521 10.0015 7.57677 9.95807 8.00015 9.86928C9.5382 10.1796 11.1367 9.92725 12.5043 9.15838C13.8522 8.39017 14.8593 7.14034 15.3235 5.65991C15.3651 5.49896 15.2702 5.33446 15.1101 5.28959ZM7.99999 1.76982C8.79152 2.768 9.22213 4.00445 9.22213 5.27842C9.22213 6.55238 8.79152 7.78873 7.99999 8.78701C7.20846 7.78883 6.77784 6.55238 6.77784 5.27842C6.77784 4.00445 7.20846 2.7681 7.99999 1.76982Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
        <Path
          id="Vector_4"
          d="M7.99997 12.9168C7.67589 12.9168 7.36502 12.7881 7.13575 12.5589C6.90659 12.3296 6.77783 12.0188 6.77783 11.6947C6.77783 11.3706 6.90659 11.0597 7.13575 10.8304C7.36503 10.6013 7.6759 10.4725 7.99997 10.4725C8.32405 10.4725 8.63493 10.6013 8.8642 10.8304C9.09335 11.0597 9.22212 11.3706 9.22212 11.6947C9.22212 12.0188 9.09335 12.3296 8.8642 12.5589C8.63492 12.7881 8.32405 12.9168 7.99997 12.9168ZM7.99997 11.0836C7.83793 11.0836 7.68243 11.148 7.56786 11.2626C7.45329 11.3771 7.3889 11.5326 7.3889 11.6947C7.3889 11.8567 7.45329 12.0122 7.56786 12.1268C7.68243 12.2414 7.83793 12.3057 7.99997 12.3057C8.16202 12.3057 8.31751 12.2414 8.43209 12.1268C8.54666 12.0122 8.61105 11.8567 8.61105 11.6947C8.61105 11.5326 8.54666 11.3771 8.43209 11.2626C8.31751 11.148 8.16202 11.0836 7.99997 11.0836Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
      </G>
    </Svg>
  );
};

export default Plant;
