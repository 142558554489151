import styled from 'styled-components/native';
import { IconPosition } from '~/components/@hello-ui/Input/components/InputIcon/types';

export const TouchableContainer = styled.TouchableOpacity<{ iconPosition?: IconPosition }>`
  position: absolute;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;

  ${({ iconPosition }) => iconPosition === 'left' && 'left: 16px;'}

  ${({ iconPosition }) => iconPosition === 'right' && 'right: 16px;'}
`;
