import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

export const chipStyles = css`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  padding-horizontal: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;

  ${Platform.select({
    web: css`
      box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15);
    `,
    android: css`
      elevation: 4;
    `,
    ios: css`
      box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15);
      shadow-color: rgb(0, 0, 0);
      shadow-opacity: 0.3;
      shadow-radius: 3px;
      elevation: 9;
    `,
  })}
`;

export const BackgroundChip = styled.TouchableOpacity<{ color: string; shadow?: boolean }>`
  ${({ shadow }) => (shadow ? chipStyles : 'border: 1px solid #F5F5F5')}
  text-align: center;
  background-color: ${({ color }) => color};
`;

export const IconChip = styled.View<{ color: string }>`
  ${chipStyles}
  background-color: ${({ color }) => color};
`;

export const IconContainer = styled.TouchableOpacity`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 2px;
`;

export const EnabledText = styled.Text<{ isClicked: boolean }>`
  width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
  font-family: ${({ isClicked }) =>
    Platform.OS !== 'web' && isClicked ? 'Poppins-Bold' : 'Poppins'};
  font-size: ${Platform.OS !== 'web' ? '16px' : '14px'};
  font-style: normal;
  font-weight: ${({ isClicked }) => (isClicked ? 600 : 400)};
  line-height: 24px;
  letter-spacing: 0.25px;
  opacity: 0.8700000047683716;
  color: ${({ isClicked, theme: { colors } }) => (isClicked ? colors.white : colors.paragraph)};
`;

export const DisabledText = styled.Text`
  text-align: center;
  font-family: ${Platform.OS !== 'web' ? 'Poppins-Bold' : 'Poppins'};
  font-size: ${Platform.OS !== 'web' ? '16px' : '14px'};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  opacity: 0.8700000047683716;
  color: ${({ theme: { colors } }) => colors.primary40};
`;

export const IconText = styled.Text<{ isClicked: boolean }>`
  font-family: Poppins;
  font-size: ${Platform.OS !== 'web' ? '16px' : '14px'};
  font-family: ${Platform.OS !== 'web' ? 'Poppins-Bold' : 'Poppins'};
  font-weight: ${({ isClicked }) => (isClicked ? 600 : 400)};
  line-height: 24px;
  letter-spacing: 0.25px;
  opacity: 0.8700000047683716;
  color: ${({ isClicked, theme: { colors } }) => (isClicked ? colors.white : colors.primary)};
`;
