import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const WrapperHelthAccount = styled.TouchableOpacity`
  ${({ theme: { isMobile } }) =>
    Platform.OS === 'web' &&
    isMobile &&
    `
    width: 100%;
  `}
`;

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  height: 48px;
  padding: 2px 12px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  min-width: 370px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;
    min-width: auto;
  `}
`;

export const WrapperIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  box-shadow: 0px 2px 8px rgba(14, 4, 1, 0.12);
  border-width: 2px;
  border-color: ${({ theme: { colors } }) => colors.primary};
`;

export const WrapperColumn = styled.View`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const WrapperArrow = styled.View`
  margin-left: 16px;
`;

export const Logo = styled.View`
  width: 100%;
  height: 18px;
  flex: 1;
`;

export const WrapperHelthAccoutIcon = styled.View`
  flex: 1;
  flex-direction: row;
  min-height: 30px;
  top: -5px;
  left: 5px;
`;

export const Loading = styled.ActivityIndicator``;

export const BalanceWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    gap: 2px;
  `}
`;
