import { Platform, Image } from 'react-native';
import { Page } from '../../components/Page';
import { Wrapper } from '~/components/@tem-ui';

import styled from 'styled-components/native';

export const WrapperBody = styled(Platform.OS === 'web' ? Page : Wrapper)``;

export const WrapperButtonBack = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  position: absolute;
  top: 48px;
  left: 24px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  z-index: 99;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const WrapperHeader = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
  height: 220px;
  padding-top: 65px;
  align-items: center;
  justify-content: center;
`;

export const WrapperImageText = styled.Text`
  font-size: 60px;
  text-align: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const WrapperPage = styled.View`
  width: 100%;
  padding-top: 64px;
  text-align: ${Platform.OS !== 'web' ? 'left' : 'center'};
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-top: 5px;
  `}
`;

export const WrapperContentPage = styled.View`
  width: 100%;
  max-width: 600px;
  margin: auto;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex: 1;
    align-items: flex-start;
    text-align: left;
    padding: 30px 30px 0 30px;
  `}
`;

export const WrapperAlert = styled.View`
  background-color: ${({ theme: { colors } }) => colors.gray15};
  padding: 15px;
  text-align: left;
  border-radius: 5px;
  margin-top: 45px;
  width: 100%;
  margin-top: 25px;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 40px 10px 0 15px;
  `}
`;

export const WrapperFooter = styled.View`
  width: 100%;
  padding: 32px 0 50px 0;
  margin-top: 10px;
  text-align: left;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `display: flex;
    align-items: flex-start;
    text-align: left;
    margin-top: 0px;
  `}
`;

export const WrapperContentText = styled.View`
  height: 28px;
  width: 100%;
`;

export const WrapperItemText = styled.Text`
  margin-top: 20px;
  text-align: left;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperContentIconText = styled.Text`
  width: 30px;
  margin-left: 10px;
  position: absolute;
`;

export const WrapperText = styled.Text`
  position: absolute;
  margin-left: 50px;
  margin-top: 3px;
  font-size: 17px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperTitleText = styled.Text`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.black};
`;

export const WrapperTalkToUsText = styled.Text`
  font-size: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperIconText = styled.View`
  position: relative;
  top: 7px;
  margin-left: 0px;
  color: ${({ theme: { colors } }) => colors.white};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: -5px;
  `}
`;

export const WrapperRegisterDataText = styled.Text`
  line-height: 40px;
  margin-left: 5px;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const WrapperFlexChat = styled.View`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-bottom: 40px;
    width: 90%;
    max-width: 1200px;
    margin: 0px auto;
    margin-bottom: 20px;
  `}
`;
