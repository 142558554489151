import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { ProductGatekeeper } from '~/auth/gatekeepers/product-gatekeeper';
import { IMagicLinkAuthenticateOptions } from '~/auth/strategies/magic-link/interfaces/magic-link-authenticate-options';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class MagicLinkStrategy extends BaseStrategy {
  constructor() {
    super();
    this.currentAuthorizedRoutes = this.getAuthorizedRoutes();
  }

  static StrategyName = EAuthStrategy.MagicLink;

  async authenticate(options: IMagicLinkAuthenticateOptions): Promise<void> {
    useAuthStore.setState({
      token: options.token,
      refreshToken: options.refreshToken,
    });

    await this.updateMyProducts();
    await this.updateProfileData();
    await this.checkHasPassword();

    await this.triggerGatekeeperValidation();
  }

  getGateKeepers(): IGatekeeper[] {
    return [new ProductGatekeeper()];
  }
}
