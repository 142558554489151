import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M12 12a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2zm8.66 3.157l-.719-.239A8 8 0 0012 0a7.993 7.993 0 00-7.914 9.092 5.045 5.045 0 00-2.548 1.3A4.946 4.946 0 000 14v4.075a5.013 5.013 0 003.6 4.8l2.87.9A4.981 4.981 0 007.959 24a5.076 5.076 0 001.355-.186l5.78-1.71a2.987 2.987 0 011.573 0l2.387.8A4 4 0 0024 19.021v-5.149a5.015 5.015 0 00-3.34-4.716zM7.758 3.762a5.987 5.987 0 018.484 0 6.037 6.037 0 01.011 8.5L12.7 15.717a.992.992 0 01-1.389 0l-3.553-3.44a6.04 6.04 0 010-8.515zM22 19.021a1.991 1.991 0 01-.764 1.572 1.969 1.969 0 01-1.626.395l-2.345-.788a5.023 5.023 0 00-2.717-.016l-5.784 1.708a3 3 0 01-1.694-.029l-2.894-.9A3.013 3.013 0 012 18.075V14a2.964 2.964 0 01.92-2.163 3.024 3.024 0 011.669-.806A8.021 8.021 0 006.354 13.7l3.567 3.453a2.983 2.983 0 004.174 0l3.563-3.463a7.962 7.962 0 001.813-2.821l.537.178A3.006 3.006 0 0122 13.872z"
      />
    </Svg>
  );
}

export default Icon;
