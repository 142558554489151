import { Animated } from 'react-native';
import styled from 'styled-components/native';

export const Title = styled.TouchableOpacity`
  width: 100%;
  min-height: 68px;
  padding-horizontal: 33px;
  padding-top: 23px;
  padding-bottom: 28px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    max-height: auto;
    padding-vertical: auto;
    padding-bottom: 20px;
  `}
`;

export const WrapperIcon = styled.View``;

export const Icon = styled(Animated.View)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

export const WrapperContent = styled.View`
  padding-top: 10px;
  padding-bottom: 31px;
  padding-left: 33px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  padding-right: 20px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-right: 30px;
  `}
`;
