import React from 'react';

import { useTheme } from 'styled-components/native';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import * as S from './styles';
import { Typography } from '~/components/@hello-ui';

export const PoweredBy = (): JSX.Element => {
  const theme = useTheme();

  return (
    <S.Copywriting>
      <Typography variant="helperText" color={theme.colors.black}>
        Powered by ©TEM Saúde
      </Typography>
      {Platform.OS !== 'web' && (
        <Typography variant="helperText" color={theme.colors.black80}>
          {`versão ${DeviceInfo.getVersion()}`}
        </Typography>
      )}
    </S.Copywriting>
  );
};
