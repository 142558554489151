import styled, { css } from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

export const WrapperButton = styled(TouchableOpacity)``;

export const WrapperContent = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    margin-top: ${isMobile ? tokens.space.s24 : tokens.space.s32};
    margin-bottom: ${tokens.space.s16};
    flex-direction: row;
    align-items: center;
  `};
`;
