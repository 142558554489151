import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const WrapperSelect = styled.View`
  flex-direction: row;
  width: 100%;
  height: 40px;
  border: 1px solid ${StyledDefaultTheme.colors.gray20};
  border-radius: 10px;
  margin-top: 6px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
`;

export const WrapperClick = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;
