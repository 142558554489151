import React from 'react';
import { G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const ExitIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <G id="icon-logout">
        <Path
          id="Vector"
          d="M16.3713 7.97383C16.484 7.86026 16.6373 7.79637 16.7973 7.79637C16.9573 7.79637 17.1106 7.86026 17.2232 7.97383L20.8226 11.5738C20.9361 11.6865 21 11.8398 21 11.9999C21 12.1599 20.9361 12.3133 20.8226 12.4259L17.2232 16.0259C17.1106 16.1395 16.9573 16.2034 16.7973 16.2034C16.6373 16.2034 16.484 16.1395 16.3713 16.0259C16.2578 15.9133 16.1939 15.7599 16.1939 15.5999C16.1939 15.4398 16.2578 15.2865 16.3713 15.1738L18.9509 12.5999L9.55661 12.5999C9.34236 12.5999 9.14433 12.4855 9.03721 12.2999C8.92995 12.1142 8.92995 11.8855 9.03721 11.6999C9.14433 11.5142 9.34237 11.3999 9.55661 11.3999L18.9509 11.3999L16.3713 8.8259C16.2578 8.71326 16.1939 8.55992 16.1939 8.39986C16.1939 8.23981 16.2578 8.08647 16.3713 7.97383ZM11.3984 20.4C11.3984 20.2409 11.3352 20.0882 11.2228 19.9757C11.1103 19.8632 10.9576 19.8 10.7985 19.8L5.99944 19.8C5.52207 19.8 5.0644 19.6104 4.72682 19.2729C4.38938 18.9352 4.19978 18.4775 4.19978 18L4.19978 6C4.19978 5.52254 4.38938 5.06479 4.72682 4.72714C5.06439 4.38964 5.52207 4.2 5.99944 4.2L10.7985 4.2C11.0129 4.2 11.211 4.08563 11.3181 3.9C11.4252 3.71437 11.4252 3.48562 11.3181 3.3C11.211 3.11438 11.0129 3 10.7985 3L5.99944 3C5.20392 3 4.44107 3.31607 3.87854 3.87871C3.31602 4.44134 3 5.20433 3 6L3 18C3 18.7957 3.31601 19.5587 3.87854 20.1213C4.44107 20.6839 5.20392 21 5.99944 21L10.7985 21C10.9576 21 11.1103 20.9368 11.2228 20.8243C11.3352 20.7118 11.3984 20.5591 11.3984 20.4Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
      </G>
    </Svg>
  );
};

export default ExitIcon;
