import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconFa from 'react-native-vector-icons/dist/FontAwesome';

import { TypesEnum } from '~/enums/appointment';
import { useTheme } from 'styled-components/native';
import * as S from '../styles';
import { Logo } from '~/components/Logo';

export const Steps = ({type}: any): JSX.Element => {
  const theme = useTheme();
  return (
    <S.WrapperSteps>
      <S.StepsBar />
      <S.WrapperStep>
        <Icon name="user" size={30} color={theme?.colors.primary} />
      </S.WrapperStep>

      <S.WrapperStep>
        <Logo height={18} width={100} />
      </S.WrapperStep>

      {type === TypesEnum.Terapiaonline || type === TypesEnum.Video ? (
        <>
          <S.StepsBar right unloaded={'29%'} />
          <S.WrapperStep disabled>
            <Icon name="video" size={30} color={theme?.colors.primary} />
          </S.WrapperStep>
        </>
      ) : (
        <>
          <S.WrapperStep disabled>
            <IconFa name="stethoscope" size={30} color={theme?.colors.primary} />
          </S.WrapperStep>
          <S.StepsBar right unloaded={'50%'} />
          <S.WrapperStep disabled>
            <Icon name="credit-card" size={30} color={theme?.colors.primary} />
          </S.WrapperStep>
        </>
      )}
    </S.WrapperSteps>
  );
};
