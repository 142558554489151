import React, { useEffect, useMemo } from 'react';
import { useNavigation, NavigationProp } from '@react-navigation/core';
import { View } from 'react-native';
import { ScheduleSpecialty } from '../ScheduleAppointment/components/ScheduleSpecialty';
import { ScheduleLocalType } from '../ScheduleAppointment/components/ScheduleLocalType';
import { ScheduleLocal } from '../ScheduleAppointment/components/ScheduleLocal';
import { ScheduleUnit } from '../ScheduleAppointment/components/ScheduleUnit';
import { ScheduleDate } from '../ScheduleAppointment/components/ScheduleDate';
import { SelectDependents } from '../ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleReview } from '../ScheduleAppointment/components/ScheduleReview';
import { ScheduleSuccess } from '../ScheduleSuccess';

import StethoscopeIcon from '~/components/Icons/Stethoscope';
import ExamIcon from '~/components/Icons/Exam';
import ToothIcon from '~/components/Icons/ToothIcon';

import { Stepper } from '~/components/@hello-ui/Stepper/Stepper';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { trackingEventType } from '~/contexts/tracking/types';
import { TypesEnum } from '~/enums/appointment';
import { goBack } from '~/helpers';

import { NavigationProps } from '~/@types/navigation/routes-helpers';

interface BenefitsServicesConsultationsProps
  extends NavigationProps<'BenefitsServicesConsultations'> {}

export const BenefitsServicesConsultations = ({
  route,
}: BenefitsServicesConsultationsProps): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { params } = route;
  const {
    scrollDisabled,
    customPadding,
    currentStep,
    stepper,
    finalizeSteps,
    stepData,
    setCurrentStepState,
    toStepper,
  } = useStepper();

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments } = useAppointment();
  const { checkIsEnabledFeature } = useAuth();

  useEffect(() => {
    clearAppointments();
  }, []);

  const cards = useMemo(
    () => [
      {
        icon: <StethoscopeIcon />,
        iconSideOfTitle: true,
        title: 'Rede para Consultas Presenciais',
        description: 'Encontre um médico perto de você',
        disabled: !checkIsEnabledFeature('health_network'),
        visible: checkIsEnabledFeature('health_network'),
        visibilitySteps: false,
        steps: [
          <ScheduleSpecialty key="ScheduleSpecialty" />,
          <ScheduleLocalType key="ScheduleLocalType" />,
          <ScheduleLocal key="ScheduleLocal" />,
          <ScheduleUnit key="ScheduleUnit" />,
          <ScheduleDate key="ScheduleDate" />,
          <SelectDependents key="SelectDependent" />,
          <ScheduleReview
            key="ScheduleReview"
            scheduleType={TypesEnum.Appointment}
            trackingType={trackingEventType.RequestedInPresentialAppointment}
          />,
          <ScheduleSuccess key="presentionSuccess" />,
        ],
        tracking: trackingEventType.ClickedOnPresentialAppointment,
      },
      {
        icon: <ExamIcon />,
        iconSideOfTitle: true,
        title: 'Rede para Exames e Procedimentos',
        description: 'Encontre laboratórios perto de você',
        disabled: !checkIsEnabledFeature('health_network'),
        visible: checkIsEnabledFeature('health_network'),
        visibilitySteps: false,
        steps: [
          <ScheduleLocalType key="ScheduleLocalType" />,
          <ScheduleLocal key="ScheduleLocal" />,
          <ScheduleUnit key="ScheduleUnit" isExam={true} />,
          <ScheduleDate key="ScheduleDate" />,
          <SelectDependents key="SelectDependent" />,
          <ScheduleReview
            key="ScheduleReview"
            scheduleType={TypesEnum.Exam}
            trackingType={
              trackingEventType.RequestedInExamsAndProceduresForVideoWithoutDoctorRequest
            }
          />,
          <ScheduleSuccess key="presentionSuccess" />,
        ],
        tracking: trackingEventType.ClickedOnExamsAndProceduresForVideoWithoutDoctorRequest,
      },
      {
        icon: <ToothIcon />,
        iconSideOfTitle: true,
        title: 'Rede para Consulta Odontológica',
        description: 'Encontre um dentista perto de você',
        disabled: !checkIsEnabledFeature('dentistry'),
        visible: checkIsEnabledFeature('dentistry'),
        visibilitySteps: false,
        steps: [
          <ScheduleLocal key="ScheduleLocal" />,
          <ScheduleUnit key="ScheduleUnit" specialtyName="odontologia" />,
          <ScheduleDate key="ScheduleDate" />,
          <SelectDependents key="SelectDependent" />,
          <ScheduleReview
            key="ScheduleReview"
            specialtyName="odontologia"
            scheduleType={TypesEnum.Dentistry}
            trackingType={trackingEventType.RequestedInDentalConsultationsAndProcedures}
          />,
          <ScheduleSuccess key="presentionSuccess" />,
        ],
        tracking: trackingEventType.ClickedOnDentalConsultationsAndProcedures,
      },
    ],
    [],
  );

  const title = useMemo(
    () =>
      params?.toStepper
        ? cards[params.toStepper].customTitle
        : stepData?.customTitle
          ? stepData?.customTitle
          : stepData?.title,
    [params?.toStepper, cards, stepData],
  );

  const showCancelMessage = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              finalizeSteps();
              setCurrentStepState(0);
              clearAppointments();

              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  useEffect(() => {
    if (params?.toStepper) {
      toStepper(params.toStepper);
    }
  }, [params?.toStepper, toStepper]);

  return (
    <PageWithCard
      simpleOptions={{
        title: (title as string) ?? 'Buscar rede',
        onBack: () => {
          if (currentStep >= 0 && stepper !== null) {
            if (params?.toStepper) {
              goBack(navigation, 'Dashboard');
              return;
            }

            showCancelMessage();
          } else {
            finalizeSteps();
            clearAppointments();
            navigation.navigate('Dashboard');
          }
        },
      }}
      overrideScrollEnabled={!scrollDisabled}
      overridePadding={customPadding}>
      <View className="min-h-full">
        <Stepper items={cards} validateDependentFeatures />
      </View>
    </PageWithCard>
  );
};
