import React from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ContactFooter } from './components/ContactFooter';
import { SimpleFooter } from './components/SimpleFooter';
import { PhoneFooter } from './components/PhoneFooter';
import { SimplePhoneFooter } from './components/SimplePhoneFooter';
import { SimplePhoneFooterInverted } from '~/components/Footer/components/SimplePhoneFooterInverted';
import { FooterMobileButton } from '~/components/Footer/components/FooterMobileButton';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

interface FooterProps {
  variant?:
    | 'simple-footer'
    | 'contact-footer'
    | 'phone-footer'
    | 'simple-phone-footer'
    | 'simple-phone-footer-inverted';
  className?: string;
  showButtonOnMobile?: boolean;
  textButtonOnMobile?: string;
}

export const Footer = ({
  variant,
  showButtonOnMobile,
  textButtonOnMobile,
  className,
}: FooterProps): JSX.Element => {
  const theme = useTheme();
  const { showFooter, isDeepLink } = DeepLink.useParams();

  if (isDeepLink && !showFooter) {
    return <></>;
  }

  if (Platform.OS !== 'web' && !variant) {
    return <></>;
  }

  const footerVariants = {
    'simple-footer': <SimpleFooter />,
    'contact-footer': <ContactFooter />,
    'phone-footer': <PhoneFooter />,
    'simple-phone-footer': <SimplePhoneFooter className={className} />,
    'simple-phone-footer-inverted': <SimplePhoneFooterInverted />,
  };

  return theme.isMobile && showButtonOnMobile ? (
    <FooterMobileButton title={textButtonOnMobile} />
  ) : (
    footerVariants[variant ?? 'contact-footer']
  );
};
