import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-native-paper';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';
import { useSweetAlert } from '../hooks';

import {
  IconTitle,
  IconCustomAlert,
  CustomTitle,
  CustomMessage,
  CustomColumnButton,
  CustomContainerButton,
} from './style';
import { iconName } from './AlertsDefaults';
import { useMedia, Button } from '~/components/@hello-ui';

export const AlertIconTitle = (): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();

  const { alertType, statusAlert, alertTitle, alertMessage, hideSweetAlert, autoClose, custom } =
    useSweetAlert();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (statusAlert && autoClose) {
      setTimeout(() => hideSweetAlert(), 3000);
    }
  }, [statusAlert]);

  let icon = <></>;
  if (custom?.icon) {
    if (typeof custom?.icon === 'string') {
      icon = (
        <Icon
          name={custom ? custom?.icon ?? iconName[alertType] : ''}
          size={media.isMobile ? 68 : 80}
          color={theme.colors[custom?.color]}
        />
      );
    } else {
      icon = custom?.icon;
    }
  } else {
    icon = (
      <Icon
        name={iconName[alertType]}
        size={media.isMobile ? 68 : 80}
        color={theme.colors[custom?.color]}
      />
    );
  }

  return (
    <>
      <IconCustomAlert>{icon}</IconCustomAlert>
      <IconTitle>{custom?.iconTitle}</IconTitle>
      <CustomTitle>{alertTitle}</CustomTitle>
      {typeof alertMessage === 'string' ? (
        <CustomMessage>{alertMessage}</CustomMessage>
      ) : (
        alertMessage
      )}
      <CustomContainerButton>
        {custom?.buttons &&
          custom?.buttons.map((button, index) => (
            <CustomColumnButton key={index}>
              <Button
                variant={button.background ?? 'secondary'}
                testID={button.testID ?? ''}
                loading={loading}
                onPress={() => {
                  if (button.callback) {
                    setLoading(true);
                    button.callback();
                  } else {
                    hideSweetAlert();
                  }
                }}>
                {button.text}
              </Button>
            </CustomColumnButton>
          ))}
      </CustomContainerButton>
      {autoClose && (
        <ProgressBar
          style={{
            height: 4,
            width: media.isMobile ? 280 : 300,
            marginTop: 20,
          }}
          indeterminate
          color={theme.colors[custom?.color]}
        />
      )}
    </>
  );
};
