import { atom } from 'recoil';
import { FileType } from './types';

export const tempFiles = atom({
  key: 'tempFiles',
  default: <FileType[]>[],
});

export const tempLoading = atom({
  key: 'tempLoading',
  default: false,
});

export const tempFileWithBigSize = atom({
  key: 'tempFileWithBigSize',
  default: false,
});

export const tempMaxFileSize = atom({
  key: 'tempMaxFileSize',
  default: 5,
});
