import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Entypo';
import { useTheme } from 'styled-components/native';
import { Typography } from '~/components/@tem-ui';
import { HelpContent } from './../../types';
import * as S from './styles';

export const DropDown = ({ title, text }: HelpContent): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const animatedValue = useRef(new Animated.Value(0)).current;
  const theme = useTheme();
  const rotateZ = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  useEffect(() => {
    Animated.spring(animatedValue, {
      toValue: isOpen ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [isOpen]);

  return (
    <>
      <S.Title onPress={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}>
        <Typography variant="body1" color="black">
          {title}
        </Typography>
        <S.Icon style={{ transform: [{ rotateZ }] }}>
          <Icon name="chevron-down" size={30} color={theme?.colors?.primary} />
        </S.Icon>
      </S.Title>
      {isOpen && (
        <S.WrapperContent>
          {typeof text !== 'string' ? (
            text
          ) : (
            <Typography variant="body2" color="black">
              {text}
            </Typography>
          )}
        </S.WrapperContent>
      )}
    </>
  );
};
