import React, { ReactNode } from 'react';

import { PageWithCardProps } from './types';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { PageWithCardHeaderless } from '~/components/@hello-ui/PageWithCard/variants/PageWithCardHeaderless';
import { PageWithCardFeaturedImage } from '~/components/@hello-ui/PageWithCard/variants/PageWithCardFeaturedImage';
import { PageWithCardSimple } from '~/components/@hello-ui/PageWithCard/variants/PageWithCardSimple';

export const PageWithCard = (props: PageWithCardProps): ReactNode => {
  const { variant } = props;

  if (variant === PageWithCardVariant.Headerless) {
    return <PageWithCardHeaderless {...props} />;
  }

  if (variant === PageWithCardVariant.FeaturedImage) {
    return <PageWithCardFeaturedImage {...props} />;
  }

  return <PageWithCardSimple {...props} />;
};
