import React from 'react';
import { View } from 'react-native';
import { Typography } from '~/components/@hello-ui';

interface HelpTextProps {
  type: string;
  currentStep: number;
}

export const HelpText: React.FC<HelpTextProps> = ({ type, currentStep }) => {
  if (['medical', 'presential', 'exam'].includes(type)) {
    return (
      <>
        {currentStep === 0 && (
          <Typography variant="body2">
            Aguarde que, em breve nossa equipe vai te enviar as melhores opções de estabelecimentos
            e preços por e-mail e WhatsApp pra você escolher o que melhor te atende.
          </Typography>
        )}

        {currentStep === 1 && (
          <>
            <Typography variant="body2" className="mb-16">
              Nós te enviamos as opções de estabelecimentos por e-mail e WhatsApp.{' '}
              <Typography variant="bodyHighlight2">Responda para gente</Typography> qual opção é a
              melhor pra você para prosseguirmos com o agendamento.
            </Typography>
            <View className="rounded-lg bg-background-gray p-16">
              <Typography variant="body2" className="mb-8">
                <Typography variant="body2" color="primary">
                  *
                </Typography>{' '}
                Caso o local escolhido não tenha datas disponíveis nos próximos dias, o{' '}
                <Typography variant="bodyHighlight2">
                  prazo para agendamento pode demorar um pouco mais
                </Typography>{' '}
                que 48h úteis.
              </Typography>
              <Typography variant="body2">
                Mas não se preocupe, o agendamento está garantido!
              </Typography>
            </View>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Typography variant="body2" className="mb-16">
              Aguarde. Nossa{' '}
              <Typography variant="bodyHighlight2">
                equipe está buscando a data e horário
              </Typography>{' '}
              mais próximos para seu agendamento.
            </Typography>
            <View className="rounded-lg bg-background-gray p-16">
              <Typography variant="body2" className="mb-8">
                <Typography variant="body2" color="primary">
                  *
                </Typography>{' '}
                Caso o local escolhido não tenha datas disponíveis nos próximos dias, o{' '}
                <Typography variant="bodyHighlight2">
                  prazo para agendamento pode demorar um pouco mais
                </Typography>{' '}
                que 48h úteis.
              </Typography>
              <Typography variant="body2">
                Mas não se preocupe, o agendamento está garantido!
              </Typography>
            </View>
          </>
        )}

        {currentStep === 3 && (
          <>
            <Typography variant="bodyHighlight2">Seu atendimento está agendado!</Typography>
            <Typography variant="body2">
              Fique atento às demais orientações da nossa equipe e compareça no dia e horário
              agendado com 30 minutos de antecedência. Tenha um bom atendimento!
            </Typography>
          </>
        )}
      </>
    );
  }

  if (['online', 'bmgOnline', 'bmgOnlineNext', 'telemedicine'].includes(type)) {
    return (
      <>
        {currentStep === 0 && (
          <Typography variant="body2">
            Aguarde. Dentro de 2 dias úteis, vamos entrar em contato com você por e-mail e WhatsApp
            com{' '}
            <Typography variant="bodyHighlight2">
              opções de clínicas, para que possa escolher
            </Typography>{' '}
            a que melhor te atende, e confirmar seu horário.
          </Typography>
        )}

        {currentStep === 1 && (
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">Seu atendimento está agendado!</Typography>
            Fique atento às demais orientações da nossa equipe. No dia do seu agendamento, você
            receberá um SMS e e-mail com o link de acesso para a sala virtual de atendimento.
          </Typography>
        )}
      </>
    );
  }

  return <></>;
};
