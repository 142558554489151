import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';

const TextWrapper = ({ ...rest }): JSX.Element => {
  return <Text {...rest} className="font-sans" />;
};

export default styled(TextWrapper)`
  font-family: 'Poppins';
`;
