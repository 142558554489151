import styled from 'styled-components/native';
import { styledPropsTypes } from './types';

export const Wrapper = styled.TouchableOpacity<styledPropsTypes>`
  flex-direction: row;
  background-color: ${({ theme: { colors }, screenRoute }) =>
    screenRoute ? colors.white : 'transparent'};
  ${(p) => p.disabled && 'opacity: 0.3'}
  align-items: center;
  padding: 16px;
  height: 80px;
  max-height: 80px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`;

export const WrapperText = styled.View`
  flex-direction: column;
  padding: 0 16px;
  max-width: 100%;
`;
