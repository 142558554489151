import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { WrapperHeightProvider } from '../Login/context/WrapperHeightContext';
import s from './styles';
import {
  DeepLinkFirstAccessWelcome,
  DeepLinkFirstAccessGender,
  DeepLinkFirstAccessPhone,
  DeepLinkFirstAccessEmail,
  DeepLinkFirstAccessPassword,
  DeepLinkFirstAccessPhoneConfirmation,
  DeepLinkFirstAccessComplete,
} from './components';
import { DeepLinkFirstAccessData, DeepLinkFirstAccessSteps } from './types';
import { useAuthStore } from '~/store/auth.store';
import { useTemPay } from '~/hooks/temPay';
import { Loading } from '~/components/Loading';
import { instanceApi } from '~/App';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';

const stepComponents = {
  welcome: DeepLinkFirstAccessWelcome,
  gender: DeepLinkFirstAccessGender,
  phone: DeepLinkFirstAccessPhone,
  email: DeepLinkFirstAccessEmail,
  phone_confirmation: DeepLinkFirstAccessPhoneConfirmation,
  password: DeepLinkFirstAccessPassword,
  complete: DeepLinkFirstAccessComplete,
};

const DeepLinkFirstAccess: React.FC = () => {
  const { user } = useAuthStore.getState();
  const { card } = useTemPay();

  if (!card) {
    return <Loading />;
  }
  const loadPrivacyPolicy = async () => {
    try {
      const response = await instanceApi.returnPrivacyPolicy();
      const data = response.data as { url: string }[];
      if (data.length > 0) {
        const privacyPolicyLink = data[0].url;
        if (privacyPolicyLink) {
          setPrivacyPolicyLink(privacyPolicyLink);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [privacyPolicyLink, setPrivacyPolicyLink] = useState<string>('');

  useEffect(() => {
    void loadPrivacyPolicy();
  }, []);

  // Essa função é responsável por tratar o telefone inicial do usuário,
  // uma vez que o número (11) 99999-9999 é atribuído automaticamente ao criar uma adesão sem telefone e não desejamos exibi-lo.
  const handleInitialPhone = (phone = '') => {
    const number = phone.replace(/\D/g, '');
    return number === '11999999999' ? '' : phone;
  };

  const [firstAccessData, setFirstAccessData] = useState<DeepLinkFirstAccessData>({
    phone: handleInitialPhone(user?.telephone_1),
    email: user?.email || '',
    gender: user?.gender || '',
    cpf: user?.cpf || '',
    cardNumber: card.numero_cartao,
    privacyPolicyLink,
  });
  const [currentStep, setCurrentStep] = useState<DeepLinkFirstAccessSteps>('welcome');

  const StepComponent = stepComponents[currentStep];

  const [wrapperHeight, setWrapperHeight] = useState(Dimensions.get('window').height);

  return (
    <CustomKeyboardAvoidingView>
      <WrapperHeightProvider value={wrapperHeight}>
        <s.Wrapper
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flexGrow: 1,
          }}
          onLayout={(ev) => {
            setWrapperHeight(ev.nativeEvent.layout.height);
          }}>
          {StepComponent ? (
            <StepComponent
              firstAccessData={firstAccessData}
              setStep={setCurrentStep}
              setFirstAccessData={setFirstAccessData}
              privacyPolicyLink={privacyPolicyLink}
            />
          ) : null}
        </s.Wrapper>
      </WrapperHeightProvider>
    </CustomKeyboardAvoidingView>
  );
};

export default DeepLinkFirstAccess;
