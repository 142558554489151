import React from 'react';
import WebView from 'react-native-webview';
import { Platform } from 'react-native';
import { MEDEX } from '@env';
import { useAuth } from '~/auth/legacy/useAuth';

interface PatientDataType {
  document: string;
}

export const MedexWebView = ({ document }: PatientDataType): JSX.Element => {
  const { onixCode, user } = useAuth();

  const url = `${MEDEX}?cpf=${document.replace(/\D+/g, '')}&companyId=${onixCode?.onixCode}&name=${user?.name}`;

  return Platform.OS !== 'web' ? (
    <WebView
      nestedScrollEnabled={true}
      scrollEnabled={true}
      javaScriptEnabled={true}
      overScrollMode='always'
      source={{ uri: url }}
      style={{ flex: 1, minHeight: 200, height: 650, opacity: 0.99 }}
    />
  ) : (
    <iframe 
      src={url} 
      style={{ width: '100%', height: '80vh', border: 'none' }} 
      allow="camera; microphone" 
    />
  );
};
