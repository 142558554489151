import React from 'react';

import { Indicator, Container } from './styles';
import { useTheme } from 'styled-components/native';

const ActivityIndicator = ({ size = 'large', color }: any) => {
  const theme = useTheme();
  return (
    <Container>
      <Indicator size={size} color={color ?? theme?.colors?.primary} />
    </Container>
  );
};

export default ActivityIndicator;
