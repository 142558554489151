import { Platform } from 'react-native';
import {
  IBorderRadiusTokens,
  IBorderWidthTokens,
  IBreakpointTokens,
  IShadowTokens,
  ISpacingTokens,
  ITokens,
  IZIndex,
} from '~/@types/theme/tokens.d';

function getDefaultSpacing(): ISpacingTokens {
  return {
    px: '1px',
    s0: '0px',
    s4: '4px',
    s8: '8px',
    s12: '12px',
    s16: '16px',
    s20: '20px',
    s24: '24px',
    s28: '28px',
    s32: '32px',
    s36: '36px',
    s40: '40px',
    s44: '44px',
    s48: '48px',
    s52: '52px',
    s56: '56px',
    s60: '60px',
    s64: '64px',
    s68: '68px',
    s72: '72px',
    s76: '76px',
    s80: '80px',
    s84: '84px',
    s88: '88px',
    s92: '92px',
    s96: '96px',
    s100: '100px',
  };
}

export const zIndex: IZIndex = {
  auto: 'auto',
  z0: '0',
  z1: '1',
  z2: '2',
  z3: '3',
  z4: '4',
  z5: '5',
  z6: '6',
  z7: '7',
  z8: '8',
  z9: '9',
  z10: '10',
  z20: '20',
  z30: '30',
  z40: '40',
  z50: '50',
  z1000: '1000',
  z1001: '1001',
  z1002: '1002',
  z1003: '1003',
  z9999: '9999',
};

function getBorderRadius(): IBorderRadiusTokens {
  return {
    none: '0px',
    sm: '2px',
    base: '4px',
    md: '6px',
    lg: '8px',
    xl: '10px',
    '2xl': '12px',
    '3xl': '14px',
    '4xl': '16px',
    '5xl': '24px',
    full: '9999px',
    squared: '33%',
  };
}

const borderWidths: IBorderWidthTokens = {
  b0: '0px',
  b1: '1px',
  b2: '2px',
  b4: '4px',
  b8: '8px',
};

const shadowsAndroid: IShadowTokens = {
  none: 'none',
  buttons: 'elevation: 4',
  cards: 'elevation: 4',
  header: 'elevation: 4',
  modal: 'elevation: 4',
};

const shadowsIos: IShadowTokens = {
  none: 'none',
  buttons: `
    box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15);
    shadow-color: rgb(0, 0, 0);
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 9;
  `,
  cards: `
    box-shadow: 1px 2px 20px rgba(75, 63, 64, 0.20);
    shadow-color: rgb(0, 0, 0);
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 9;
  `,
  header: `
    box-shadow: 1px 2px 4px rgba(75, 63, 64, 0.10);
    shadow-color: rgb(0, 0, 0);
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 9;
  `,
  modal: `
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: rgb(0, 0, 0);
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 9;
  `,
};

export const shadowsWeb: IShadowTokens = {
  none: 'none',
  buttons: 'box-shadow: 1px 1px 8px rgba(75, 63, 64, 0.15)',
  cards: 'box-shadow: 1px 2px 20px rgba(75, 63, 64, 0.2)',
  header: 'box-shadow: 1px 2px 4px rgba(75, 63, 64, 0.10)',
  modal: 'box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15)',
};

function getBreakpoints(): IBreakpointTokens {
  return {
    xs: '360px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  };
}

export function getDefaultTokens(): ITokens {
  const breakpoints = getBreakpoints();

  return {
    space: getDefaultSpacing(),
    breakpoints,
    sizes: {
      auto: 'auto',
      full: '100%',
      wScreen: '100vw',
      hScreen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      screenXs: breakpoints.xs,
      screenSm: breakpoints.sm,
      screenMd: breakpoints.md,
      screenLg: breakpoints.lg,
      screenXl: breakpoints.xl,
    },
    borderWidths,
    zIndex,
    borderRadius: getBorderRadius(),
    shadows:
      Platform.OS === 'web' ? shadowsWeb : Platform.OS === 'android' ? shadowsAndroid : shadowsIos,
  };
}
