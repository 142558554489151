import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Psychology = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="22" height="18" viewBox="0 0 22 18" fill="none">
      <G id="Group">
        <Path d="M14.6172 4.23925C15.2281 3.77686 15.8988 3.39959 16.6114 3.11785C16.8868 5.00815 16.5189 6.93606 15.567 8.59235C14.9082 9.73669 13.9897 10.71 12.8853 11.4338C11 12.65 11.4142 12.4435 11.55 12.65C11.6861 12.8562 12.1 13.0431 13.3894 12.1986C14.614 11.3966 15.6322 10.3175 16.362 9.04879C17.5369 7.03985 17.9076 4.66076 17.4002 2.38951C17.3331 2.14952 17.0869 2.00712 16.8455 2.0687C15.8391 2.37437 14.2205 3.32501 13.3894 3.96967C12.0908 4.86325 13.0474 4.74767 13.2 4.94999C13.3526 5.15255 14.4161 4.3923 14.6186 4.23968L14.6172 4.23925Z" fill={color ?? theme.colors.primary}/>
        <Path d="M8.61006 12.1954C8.821 12.3245 10.8641 13.4064 11 13.2C11 12.65 9.31593 11.5732 9.11419 11.4304C8.01018 10.7065 7.09174 9.73324 6.4331 8.58889C5.48517 6.93246 5.11816 5.00674 5.39047 3.11754C6.10228 3.4005 6.77234 3.77839 7.38286 4.24077C7.58521 4.39341 8.64737 5.15256 8.79997 4.95001C8.95261 4.74745 9.34997 4.40001 7.93545 3.50751C7.0459 2.94623 6.16221 2.37547 5.15599 2.06938C4.91456 2.00799 4.66843 2.1504 4.60131 2.39019C4.0937 4.66086 4.46403 7.03953 5.63802 9.04842C6.36806 10.3159 7.38637 11.3941 8.61006 12.1954Z" fill={color ?? theme.colors.primary}/>
        <Path d="M21.6651 6.43439C20.6575 6.1991 19.6155 6.14774 18.5894 6.28258C16.4436 6.50758 16.4135 6.73862 16.4436 6.98987C16.5 7.70001 16.7357 7.42896 18.698 7.19284C19.4428 7.10323 20.1961 7.11346 20.9382 7.22312C20.2295 8.99636 18.9481 10.4816 17.298 11.4428C15.5452 12.4167 13.5176 12.7782 11.5365 12.4696C12.9703 10.7796 13.7551 8.63381 13.75 6.4174C13.7633 4.09048 12.8957 1.84458 11.321 0.131052C11.1428 -0.0436739 10.8576 -0.0436739 10.6794 0.131052C9.10483 1.84437 8.23709 4.09027 8.25039 6.4174C8.24528 8.63423 9.03035 10.7803 10.4644 12.4707C8.48284 12.7813 6.45427 12.4193 4.70234 11.4428C3.05182 10.4814 1.77025 8.99553 1.06148 7.22166C1.80377 7.1124 4.62319 7.25526 5.3681 7.34549C5.61853 7.37393 5.33823 7.23925 5.3681 6.98882C5.39777 6.73839 5.20043 6.46545 4.95 6.43435C3.92413 6.29932 1.34256 6.19886 0.335134 6.43435C0.0953442 6.50125 -0.0472641 6.7476 0.0143214 6.98882C0.710366 9.21015 2.22156 11.0853 4.24401 12.2376C5.71367 13.0742 7.37786 13.5089 9.06908 13.4983C9.71788 13.5022 10.3652 13.4371 11.0003 13.3039C13.3074 13.7694 15.7051 13.3909 17.7565 12.2376C19.7783 11.0853 21.2891 9.21052 21.9853 6.98987C22.0477 6.74844 21.9054 6.50169 21.6651 6.43439ZM11 1.15474C12.1873 2.652 12.8333 4.50669 12.8333 6.41763C12.8333 8.32857 12.1873 10.1831 11 11.6805C9.81275 10.1833 9.16683 8.32857 9.16683 6.41763C9.16683 4.50669 9.81275 2.65216 11 1.15474Z" fill={color ?? theme.colors.primary}/>
        <Path d="M11.0001 17.8752C10.5139 17.8752 10.0476 17.6821 9.70372 17.3383C9.36 16.9944 9.16685 16.5281 9.16685 16.042C9.16685 15.5559 9.36 15.0896 9.70372 14.7457C10.0477 14.4019 10.514 14.2088 11.0001 14.2088C11.4862 14.2088 11.9525 14.4019 12.2964 14.7457C12.6401 15.0896 12.8333 15.5559 12.8333 16.042C12.8333 16.5281 12.6401 16.9944 12.2964 17.3383C11.9525 17.6821 11.4862 17.8752 11.0001 17.8752ZM11.0001 15.1254C10.757 15.1254 10.5238 15.222 10.3519 15.3938C10.18 15.5657 10.0835 15.7989 10.0835 16.042C10.0835 16.2851 10.18 16.5183 10.3519 16.6902C10.5238 16.862 10.757 16.9586 11.0001 16.9586C11.2431 16.9586 11.4764 16.862 11.6482 16.6902C11.8201 16.5183 11.9167 16.2851 11.9167 16.042C11.9167 15.7989 11.8201 15.5657 11.6482 15.3938C11.4764 15.222 11.2431 15.1254 11.0001 15.1254Z" fill={color ?? theme.colors.primary}/>
      </G>
    </Svg>
  );
};

export default Psychology;
