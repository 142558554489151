import React from 'react';
import { Circle, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const InfoIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 80 80" fill="none">
      <G filter="url(#filter0_d_10902_857)">
        <Circle cx="39" cy="39" r="32" fill="#FA9F47" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M41.8947 50.8101C41.8947 49.3877 40.6911 48.2935 39.2687 48.2935C37.8463 48.2935 36.6427 49.4971 36.6427 50.9195C36.6427 52.3419 37.8463 53.5455 39.2687 53.5455C40.0346 53.5455 40.6911 53.2172 41.1288 52.7795C41.6758 52.2325 41.8947 51.576 41.8947 50.8101Z"
        fill="white"
      />
      <Path
        d="M43.5485 39.2971L44.3428 38.4979C46.3852 36.6711 48.6546 34.616 48.6546 31.8758C48.6546 29.9349 47.7469 27.9939 46.1583 26.738C44.4563 25.2538 42.1869 24.4546 39.6906 24.4546C35.3788 24.4546 30.7266 26.9664 30.2727 31.7616C30.2727 32.1042 30.2727 32.2183 30.7266 32.2183C31.8613 32.3325 32.9959 32.4467 34.1306 32.675C34.3576 32.675 34.5845 32.7892 34.698 32.7892C34.9249 32.7892 35.0384 32.7892 35.2653 32.1042C35.9461 30.049 37.4212 28.7931 39.4637 28.7931C41.0522 28.7931 42.5273 29.5924 43.0946 30.7341C43.435 31.5333 43.662 32.675 42.5273 34.2735C41.96 35.0727 41.0522 35.7577 40.2579 36.3286C39.2367 37.1278 38.2155 37.8128 37.6482 39.0687C36.8539 40.7813 36.8539 43.0648 36.9673 45.1199C36.9673 45.4624 37.0808 45.4624 37.3078 45.4624C37.9886 45.4624 38.6694 45.4624 39.3502 45.4624C39.4637 45.4624 39.5771 45.4624 39.8041 45.4624H40.4849C40.7118 45.4624 40.9387 45.4624 41.1657 45.4624C41.5061 45.4624 41.6196 45.3483 41.6196 45.3483C41.733 45.2341 41.733 45.1199 41.733 44.8916C41.733 43.864 41.733 42.8365 41.8465 41.9231C41.96 40.6672 42.7542 39.9821 43.5485 39.2971Z"
        fill="white"
      />
    </Svg>
  );
};
