import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Loader } from '../Loader';
import { CardListProps } from './types';
import { CardSelect } from './CardSelect';

export const CardList = ({ data }: CardListProps) => {
  const filteredData = data.filter((item) => item.visible);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (filteredData.length) {
      setLoading(false);
    }
  }, [filteredData]);

  return (
    <Fragment>
      {loading ? (
        <Loader variant="circle-loader" />
      ) : (
        filteredData.map((item, index) => (
          <View key={item.title}>
            <CardSelect key={index} {...item} iconSideOfTitle description={item.description} />
          </View>
        ))
      )}
    </Fragment>
  );
};
