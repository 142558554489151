import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { IconPosition } from '~/components/@hello-ui/Input/components/InputIcon/types';

type WrapperCodeProps = { error: boolean; isFirst?: boolean };

export const Container = styled.View`
  width: 100%;
  display: flex;
`;

export const WrapperContainer = styled.View<{ error?: boolean; isDisabled?: boolean }>`
  width: 100%;
  flex-direction: row;
  align-items: center;

  position: relative;

  ${({ theme: { isMobile } }) => css`
    /* As the border increases each size of the element by 1px, we will subtract 1px from the padding */
    padding: ${!isMobile ? '15px' : '11px 15px'};
  `}

  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme: { colors }, error, isDisabled }) =>
    error ? colors.error : isDisabled ? colors['gray-disabled'] : colors.placeholder};
`;

export const WrapperCode = styled.View<WrapperCodeProps>`
  flex: 1;
  margin-left: ${({ theme, isFirst }) => (isFirst ? 0 : theme.tokens.space.s8)};
  align-items: center;
  justify-content: center;

  height: 56px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;

  border-color: ${({ theme: { colors }, error }) => {
    if (error) {
      return colors.red;
    }

    return colors.placeholder;
  }};
`;

export const commonInputStyles = css<{ iconPosition?: IconPosition; editable?: boolean }>`
  ${({ theme, iconPosition, editable }) => css`
    flex: 1;
    min-width: 0;
    font-size: ${theme.isMobile ? 16 : 18}px;
    height: 28px;
    padding: 0;
    font-family: ${Platform.OS === 'web' ? 'Poppins' : 'Poppins-Regular'};
    color: ${editable ? theme.colors.paragraph : theme.colors['gray-disabled']};

    ${iconPosition === 'right' &&
    css`
      margin-right: ${theme.tokens.space.s32};
    `}

    ${iconPosition === 'left' &&
    css`
      margin-left: ${theme.tokens.space.s32};
    `}

  ${Platform.OS === 'web' &&
    css`
      outline-style: none;
    `}
    
    ${Platform.OS !== 'ios' &&
    css`
      line-height: 28px;
    `}

    /* Poppins font seems to have a extra height which force us to shift the text a little to bottom */
    ${Platform.OS === 'android'
      ? css`
          margin-top: 2px;
          margin-bottom: -2px;
        `
      : css`
          margin-top: 1px;
          margin-bottom: -1px;
        `}
  `}
`;
