import styled, { css } from 'styled-components/native';

export const WrapperCardButton = styled.TouchableOpacity<{
  size?: string;
  orientation?: string;
  cursor?: 'pointer' | 'default';
}>`
  cursor: ${({ cursor }) => cursor || 'pointer'};
  ${({ theme: { isMobile, tokens }, size, orientation }) => css`
    width: 100%;
    flex-direction: ${orientation};

    ${orientation === 'row' &&
    css`
      align-items: center;
    `}

    ${size === 'xsmall' &&
    css`
      ${isMobile
        ? css`
            height: 75px;
          `
        : css`
            height: 90px;
            padding: ${tokens.space.s0};
          `}
    `}

    ${size === 'password' &&
    css`
      height: 120px;
    `}
  `}
`;

export const WrapperIcon = styled.View<{ size?: string; hasIcon?: boolean }>`
  width: 32px;
  height: 32px;
  padding-top: 4px;

  ${({ size }) =>
    size === 'small' &&
    css`
      padding-top: 0px;
      padding-bottom: 8px;
    `}

  ${({ hasIcon }) =>
    !hasIcon &&
    css`
      padding-top: 0px;
      width: 0px;
      height: 0px;
    `};
  ${({ size, theme }) =>
    size === 'large' &&
    css`
      padding-top: 0px;
      width: 24px;
      height: 24px;
      margin-right: ${theme.isMobile ? '8px' : '16px'};
    `};
`;

export const WrapperText = styled.View`
  width: 95%;
  height: auto;
`;
