import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';

import Maternity from '~/components/Icons/Maternity';
import Family from '~/components/Icons/Family';
import MobileCare from '~/components/Icons/MobileCare';
import HandCare from '~/components/Icons/HandCare';

import { useAuth } from '~/auth/legacy/useAuth';
import Health from '~/components/Icons/Health';

export const BenefitsWoman = (): JSX.Element => {
  const navigation = useNavigation();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <Maternity />,
          title: 'Saúde da Mulher',
          description: 'Consultas com profissionais especialistas no cuidado feminino',
          onPress: () => navigation.navigate('BenefitsWomensHealth'),
          visible: checkIsEnabledFeature('bella_experts_woman'),
          iconSideOfTitle: true,
        },
        {
          icon: <Health />,
          title: 'Programa Hábitos Saudáveis',
          description: 'Consultas mensais com nutricionista, psicólogo e personal trainer',
          onPress: () => navigation.navigate('BenefitsHealthyHabitsProgram'),
          visible: checkIsEnabledFeature('medex_healthy_habits'),
          iconSideOfTitle: true,
        },
        {
          icon: <MobileCare />,
          title: 'Assistente Digital Feminina',
          description: 'Acesso a conteúdos sobre a saúde da mulher em todas as fases da vida.',
          onPress: () => navigation.navigate('BenefitsDigitalFemaleAssistance'),
          visible: checkIsEnabledFeature('bella_female_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <Family />,
          title: 'Assistente Familiar Digital',
          description: 'Acesso a conteúdos sobre educação infantil e relacionamento familiar',
          onPress: () => navigation.navigate('BenefitsParentalAssistance'),
          visible: checkIsEnabledFeature('bella_parental_assistance'),
          iconSideOfTitle: true,
        },
        {
          icon: <HandCare />,
          title: 'Acolhimento à Mulher',
          description: 'Apoio para mulheres em situação de vulnerabilidade doméstica',
          onPress: () => navigation.navigate('BenefitsWelcomingWomen'),
          visible: checkIsEnabledFeature('beneficiolegal_welcome_woman'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Benefício Mulher',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
