import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useTheme } from 'styled-components/native';
import { TouchableOpacity, View } from 'react-native';
import { clsx } from 'clsx';
import { Checkbox, useDialog } from '../../@tem-ui';
import { NeighborhoodsTypes, NeighborhoodsPropsTypes } from './types';
import { useApi } from '~/hooks/api';
import { localCapitalize } from '~/utils/strings';
import { Button, Loader, Typography } from '~/components/@hello-ui';
import { Input } from '~/components/@hello-ui/Input';
import { Listing } from '~/components/@hello-ui/Listing';

export const Neighborhoods = ({
  cityId = 0,
  onSelect,
  selected,
  single,
  initialSelectedValues,
}: NeighborhoodsPropsTypes): JSX.Element => {
  const api = useApi();
  const { closeDialog } = useDialog();
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodsTypes[]>([]);
  const [internalSelectedNeighborhoods, setInternalSelectedNeighborhoods] = useState<
    NeighborhoodsTypes[]
  >(initialSelectedValues ?? selected ?? []);

  useEffect(() => {
    setInternalSelectedNeighborhoods(selected ?? []);
  }, [selected]);

  const toggleNeighborhood = useCallback(
    (neighborhood) => {
      const exists = internalSelectedNeighborhoods.find((n) => n.id === neighborhood.id);

      if (exists) {
        setInternalSelectedNeighborhoods((oldNeighborhoods) => {
          return oldNeighborhoods.filter((n) => n.id !== neighborhood.id);
        });
      } else if (internalSelectedNeighborhoods.length < 3) {
        setInternalSelectedNeighborhoods((oldNeighborhoods) => [...oldNeighborhoods, neighborhood]);
      }
    },
    [internalSelectedNeighborhoods, setInternalSelectedNeighborhoods],
  );

  const getData = useCallback(
    debounce(async (value?: string) => {
      setLoading(true);
      const { data } = await api.neighborhoods(cityId, value);
      const payload = data.map((neighborhood) => ({
        id: neighborhood.id,
        title: localCapitalize(neighborhood.nome),
        latitude: neighborhood?.latitude,
        longitude: neighborhood?.longitude,
      }));

      setNeighborhoods(payload);

      setLoading(false);
    }),
    [],
  );

  useEffect(() => {
    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      const { title }: NeighborhoodsTypes = item;
      const isSelected =
        internalSelectedNeighborhoods.find((n) => n.id === item.id)?.id !== undefined;
      const isDisabled = isSelected ? false : internalSelectedNeighborhoods.length >= 3;

      return (
        <TouchableOpacity
          className={clsx('flex-row content-start items-center', {
            'py-0 pb-12 mobile:pb-8': index === 0,
            'py-12 mobile:py-8': index !== 0,
          })}
          onPress={() => {
            if (single) {
              onSelect(item);
            } else {
              toggleNeighborhood(item);
            }
          }}
          activeOpacity={!single ? 0.7 : 1}>
          {!single && (
            <View className="content-center items-center">
              <Checkbox
                disabled={isDisabled}
                value={item}
                checked={isSelected}
                onPress={() => toggleNeighborhood(item)}
              />
            </View>
          )}
          <View className="ml-8 flex-1">
            <Typography variant="body2" color={theme.colors.paragraph}>
              {title}
            </Typography>
          </View>
        </TouchableOpacity>
      );
    },
    [closeDialog, toggleNeighborhood, single],
  );

  return (
    <>
      <View className="h-auto w-full">
        <View className="relative h-auto w-full items-center">
          <Input
            variant="search"
            onChangeText={(value) => getData(value?.toString())}
            label="Pesquisar"
            placeholder="Digite o bairro"
          />
          <View className="mb-0 ml-0 mr-0 h-[244px] w-full ">
            {loading ? (
              <Loader variant="circle-loader" />
            ) : (
              <Listing data={neighborhoods} renderItem={renderItem} hideBackToTop />
            )}
          </View>
        </View>
        {!single && (
          <View className="mb-16 mt-16 w-full desktop:mt-40">
            <Button
              disabled={!internalSelectedNeighborhoods.length}
              variant="primary"
              onPress={() => {
                onSelect(internalSelectedNeighborhoods);
              }}>
              Próximo
            </Button>
          </View>
        )}
      </View>
    </>
  );
};
