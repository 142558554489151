import React, { useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { CustomKeyboardAvoidingView } from '~/components/CustomKeyboardAvoidingView/CustomKeyboardAvoidingView';
import { PageScroll } from '~/components/@hello-ui/PageWithCard/components/PageScroll';
import { Page } from '~/components/@hello-ui/PageWithCard/components/Page';
import { PageLimit } from '~/components/@hello-ui/PageWithCard/components/PageLimit';
import { PageWithCardProvider } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { BodyScroll } from '~/components/@hello-ui/PageWithCard/components/BodyScroll';
import { ContentLimit } from '~/components/@hello-ui/PageWithCard/components/ContentLimit';
import { PageWithCardProps } from '~/components/@hello-ui/PageWithCard/types';

export const PageWithCardHeaderless = ({
  overridePadding,
  onScrollViewRefUpdate,
  overrideBodyMaxWidth,
  overrideScrollEnabled,
  overridePageMaxWidth,
  overrideHeader,
  children,
}: PageWithCardProps) => {
  const pageScrollViewRef = useRef<ScrollView>(null);
  const bodyScrollRef = useRef<ScrollView>(null);

  useEffect(() => {
    onScrollViewRefUpdate?.(pageScrollViewRef);
  }, []);

  return (
    <PageWithCardProvider
      pageScroll={pageScrollViewRef}
      bodyScroll={bodyScrollRef}
      currentScroll={pageScrollViewRef}>
      <CustomKeyboardAvoidingView>
        <PageScroll
          scrollEnabled={overrideScrollEnabled !== undefined ? overrideScrollEnabled : true}
          ref={pageScrollViewRef}>
          <Page>
            <PageLimit overridePageMaxWidth={overridePageMaxWidth}>
              {overrideHeader && overrideHeader()}

              <BodyScroll
                ref={bodyScrollRef}
                topBorderRadius={8}
                scrollEnabled={overrideScrollEnabled !== undefined ? overrideScrollEnabled : false}
                overridePadding={overridePadding}>
                <ContentLimit overrideBodyMaxWidth={overrideBodyMaxWidth}>{children}</ContentLimit>
              </BodyScroll>
            </PageLimit>
          </Page>
        </PageScroll>
      </CustomKeyboardAvoidingView>
    </PageWithCardProvider>
  );
};
