import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import { TypographyTypes } from '../types';

const TextWrapper = ({ ...rest }: TypographyTypes): JSX.Element => <Text {...rest} />;

export default styled(TextWrapper)`
  font-family: 'Inter';

  ${({ underline }) =>
    underline &&
    `
    text-decoration: underline;
  `}
`;
