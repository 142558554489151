import React, { Fragment } from 'react';
import { Linking, Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Logo } from '../../../Logo';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import * as S from '../styles';
import { Typography } from '../../../@hello-ui';

interface ContentContact {
  title: string;
  description: string;
}

export const ContactFooter = (): JSX.Element => {
  const theme = useTheme();

  return (
    <S.Wrapper testID="contact-footer">
      <S.WrapperColumn>
        <S.Logo>
          <Logo height={56} width={120} />
        </S.Logo>
        <S.WrapperSocialButtons>
          {theme?.social_links &&
            typeof theme?.social_links !== 'string' &&
            theme?.social_links &&
            Object.keys(theme?.social_links).map((socialName, idx) => (
              <S.ButtonSocial
                testID={`button-${socialName}`}
                onPress={() => Linking.openURL(theme?.social_links[socialName])}
                key={`social-${idx}`}>
                <Icon
                  name={socialName}
                  color={theme?.colors.primary}
                  size={Platform.OS === 'web' ? 18 : 16}
                />
              </S.ButtonSocial>
            ))}
        </S.WrapperSocialButtons>
      </S.WrapperColumn>
      <S.WrapperColumn>
        {theme?.footer_phones &&
          typeof theme?.footer_phones !== 'string' &&
          theme?.footer_phones.map((item: ContentContact, idx: number) =>
            idx === 0 ? (
              <Fragment key={`phone-${idx}`}>
                <Typography variant="h2" color={theme.colors.black}>
                  {item.title}
                </Typography>
                <S.SpacingContainer top={5}>
                  <Typography variant="body2" color={theme.colors.black70}>
                    {item.description}
                  </Typography>
                </S.SpacingContainer>
              </Fragment>
            ) : (
              <S.SpacingContainer key={`phone-${idx}`}>
                <Typography variant="link" color={theme.colors.link}>
                  {item.title}
                </Typography>
                <Typography variant="helperText" color={theme.colors.black70}>
                  {item.description}
                </Typography>
              </S.SpacingContainer>
            ),
          )}
      </S.WrapperColumn>
      <S.WrapperColumn>
        {theme?.footer_disclaimers &&
          typeof theme?.footer_disclaimers !== 'string' &&
          theme.footer_disclaimers.map((item: string, idx: number) => (
            <Typography variant="body2" color={theme.colors.black70}>
              {item}
            </Typography>
          ))}
        <S.SpacingContainer>
          <Typography variant="body2" color={theme.colors.black}>
            {`© ${new Date().getFullYear()} ${theme?.copyright_text}`}
          </Typography>
        </S.SpacingContainer>
      </S.WrapperColumn>
    </S.Wrapper>
  );
};
