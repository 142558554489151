import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { FieldError } from 'react-hook-form';
import { Typography } from '../Typography';
import { RadioBox } from './RadioBox';

interface RadioProps {
  label: string;
  value: string | number;
}

interface DataProps {
  data: RadioProps[];
  error?: FieldError | string;
  onChange: () => void;
  value?: any;
  label?: string;
  disabled?: boolean;
  vertical?: boolean;
}

export const RadioBoxGroup = ({
  data,
  error,
  onChange,
  value = null,
  label,
  disabled = false,
  vertical = false,
}: DataProps) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  return (
    <>
      <Typography variant="subtitle" color={disabled ? 'gray-disabled' : 'paragraph'}>
        {label}
      </Typography>

      <View className={`flex ${vertical ? 'flex-col' : 'flex-row'}`}>
        {data.map(({ label, value }: RadioProps, idx: number) => (
          <View className={`${vertical ? 'mb-8' : idx > 0 ? 'ml-32' : ''}`} key={`radio-${label}`}>
            <RadioBox
              label={label}
              selected={selectedOption ? selectedOption === value : false}
              onSelect={() => setSelectedOption(value)}
              disabled={disabled}
            />
          </View>
        ))}
      </View>

      <View className="mb-0 mt-4 h-24 mobile:mb-[3px] mobile:h-[18px]">
        {error ? (
          <Typography variant="helperText" color={theme.colors.error}>
            {error}
          </Typography>
        ) : (
          <></>
        )}
      </View>
    </>
  );
};
