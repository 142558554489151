import React, { useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/core';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconS from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { View } from 'react-native';
import * as S from './styles';
import { Card } from './components/Card';
import { CardButton, Typography } from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import { SpacingToken } from '~/components/SpacingToken';
import { useAppointment } from '~/hooks/appointment';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { Spacing } from '~/components/Spacing';

export const ScheduleSuccess = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { appointment, clearAppointments, scheduleSubmissionResponse } = useAppointment();

  const { isDeepLink: isDeepLinkValue } = DeepLink.useParams();

  const isTelemedicine = appointment?.specialty?.type === 'telemedicine';

  useEffect(() => {
    DeepLink.sendEvent(DeepLinkEvent.Success);
  }, []);

  return (
    <PageWithCard
      variant={PageWithCardVariant.Headerless}
      overridePadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
      overrideBodyMaxWidth="none">
      <S.WrapperHeader>
        <S.WrapperHeaderContainer>
          <S.WrapperCenterHeader>
            <S.WrapperIcon>
              <IconS name={'check-circle-outline'} size={60} color={theme.colors.white} />
            </S.WrapperIcon>
            <SpacingToken mTop={'s16'} mBottom={'s4'}>
              <Typography variant="subtitle" color={theme?.colors.white}>
                Agendamento solicitado
              </Typography>
            </SpacingToken>
            <Typography variant="body2" color={theme?.colors.white}>
              Seu atendimento está quase marcado!
            </Typography>
            <Card />
          </S.WrapperCenterHeader>
        </S.WrapperHeaderContainer>
      </S.WrapperHeader>
      <S.WrapperContent>
        <Typography variant="bodyHighlight1" color={theme.colors.black}>
          O que fazer agora?
        </Typography>
        <SpacingToken mTop="s8">
          <Typography variant="body2" color={theme.colors.black70}>
            É só aguardar!
          </Typography>
          {appointment ? (
            <Typography variant="body2" className="mb-8" color={theme.colors.black70}>
              <Typography variant="bodyHighlight2">Em breve</Typography>{' '}
              <Typography variant="bodyHighlight2">entraremos em contato por e-mail e</Typography>{' '}
              <Typography variant="bodyHighlight2">WhatsApp</Typography> com atualizações sobre sua
              solicitação.
            </Typography>
          ) : (
            <Typography variant="body2" color={theme.colors.black70}>
              -
            </Typography>
          )}
          <>
            {isTelemedicine && (
              <Typography variant="body2" color={theme.colors.black70}>
                Depois, no dia do seu agendamento, você receberá um{' '}
                <Typography variant="bodyHighlight2">SMS</Typography> e{' '}
                <Typography variant="bodyHighlight2">e-mail com o link de acesso</Typography> para a
                sala virtual de atendimento.
              </Typography>
            )}
          </>
          {isDeepLinkValue && (
            <Spacing bottom={15} top={15}>
              <Typography variant="body2" color={theme.colors.black70}>
                O número do seu protocolo é{' '}
                <Typography variant="bodyHighlight2">
                  {scheduleSubmissionResponse?.response?.ocorrencia}
                </Typography>
                .
              </Typography>
            </Spacing>
          )}
        </SpacingToken>

        {!isDeepLinkValue && (
          <S.WrapperCards>
            <S.CardItem>
              <CardButton
                icon={<Icon name="home" size={24} color={theme?.colors.primary} />}
                title="Início"
                variant="small"
                orientation="vertical"
                onPress={() => {
                  clearAppointments();
                  navigation.navigate('Dashboard');
                }}
              />
            </S.CardItem>
            <S.CardItem>
              <CardButton
                icon={<Icon name="credit-card" size={24} color={theme?.colors.primary} />}
                title="Conta Saúde"
                variant="small"
                orientation="vertical"
                onPress={() => {
                  clearAppointments();
                  navigation.navigate('TemPayDashboard');
                }}
              />
            </S.CardItem>
          </S.WrapperCards>
        )}
      </S.WrapperContent>
      <View className="mt-auto" />
      <Footer variant="phone-footer" />
    </PageWithCard>
  );
};
