import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const VideoCall24Icon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M16.4736 15.0457H20.0961C20.4399 15.0457 20.7308 14.7548 20.7308 14.411V1.9039C20.7308 1.5601 20.4399 1.26924 20.0961 1.26924H1.90397C1.56017 1.26924 1.26931 1.56012 1.26931 1.9039V14.4374C1.26931 14.7813 1.56019 15.0721 1.90397 15.0721H5.55284C10.5296 15.0455 7.30954 15.0721 9.16029 15.0721C11.011 15.0721 11.3561 15.0457 16.4736 15.0457ZM22 1.90395V14.4375C22 15.4687 21.1538 16.3413 20.0962 16.3413L12 16.3411V18.2714H16.1826C16.3148 18.2714 16.4205 18.3771 16.4205 18.5093V18.8795C16.4205 19.0117 16.3148 19.1174 16.1826 19.1174L5.8173 19.1176C5.68506 19.1176 5.57937 19.0119 5.57937 18.8797V18.4829C5.57937 18.3507 5.68506 18.245 5.8173 18.245H10V16.3148H1.90383C0.872589 16.3148 0 15.4685 0 14.4109V1.90383C0 0.846241 0.846241 0 1.90383 0H20.1224C21.1537 0 22 0.84617 22 1.90395Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M11.4622 10.58V9.48005L14.7322 4.80005H16.3622V9.36005H17.2422V10.58H16.3622V12H14.9622V10.58H11.4622ZM15.0522 6.30005L13.0022 9.36005H15.0522V6.30005Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M5.96994 10.42C6.60994 9.88672 7.11994 9.44338 7.49994 9.09005C7.87994 8.73005 8.19661 8.35672 8.44994 7.97005C8.70327 7.58338 8.82994 7.20338 8.82994 6.83005C8.82994 6.49005 8.74994 6.22338 8.58994 6.03005C8.42994 5.83672 8.18327 5.74005 7.84994 5.74005C7.51661 5.74005 7.25994 5.85338 7.07994 6.08005C6.89994 6.30005 6.80661 6.60338 6.79994 6.99005H5.43994C5.46661 6.19005 5.70327 5.58338 6.14994 5.17005C6.60327 4.75672 7.17661 4.55005 7.86994 4.55005C8.62994 4.55005 9.21328 4.75338 9.61994 5.16005C10.0266 5.56005 10.2299 6.09005 10.2299 6.75005C10.2299 7.27005 10.0899 7.76672 9.80994 8.24005C9.52994 8.71338 9.20994 9.12672 8.84994 9.48005C8.48994 9.82672 8.01994 10.2467 7.43994 10.74H10.3899V11.9H5.44994V10.86L5.96994 10.42Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default VideoCall24Icon;
