import { css } from 'styled-components';
import styled from 'styled-components/native';

export const WrapperContent = styled.View`
  ${({ theme: { tokens } }) => css`
    margin-top: ${tokens.space.s44};
  `};
`;

export const WrapperServicesContent = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    margin-top: ${isMobile ? tokens.space.s8 : tokens.space.s16};
  `};
`;

