import styled, { css } from 'styled-components/native';
import { FlatList } from 'react-native';

export const WrapperMapElement = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    flex: 1;
    z-index: -1;
    overflow: hidden;
  `};
`;

export const MapBottomItemList = styled(FlatList).attrs(({ theme }) => ({
  contentContainerStyle: {
    paddingHorizontal: theme.isDesktop ? 96 : 16,
  },
}))`
  ${({ theme: { tokens, isMobile } }) => css`
    position: absolute;
    z-index: ${tokens.zIndex.z10};
    bottom: ${isMobile ? tokens.space.s16 : tokens.space.s24};
    left: 0;
    right: 0;
    max-height: 165px;
    height: auto;
  `};
`;

export const ItemSeparator = styled.View`
  width: ${({ theme }) => (theme.isMobile ? theme.tokens.space.s8 : theme.tokens.space.s16)};
`;
