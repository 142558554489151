import styled from 'styled-components/native';

export const ArrowIcon = styled.TouchableOpacity`
  padding-horizontal: 8px;
`;

export const NumberContainer = styled.TouchableOpacity<{ isCurrentPage: boolean }>`
  padding-horizontal: 8px;
`;

export const NumberItem = styled.Text<{ isCurrentPage: boolean }>`
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins';
  color: ${({ isCurrentPage, theme: { colors } }) =>
    isCurrentPage ? colors.primary : colors.primary40};
  ${({ isCurrentPage }) =>
    isCurrentPage &&
    `
    font-weight: 600;
  `}

  ${({ theme: { isMobile }, isCurrentPage }) =>
    isMobile &&
    `
    font-size: 14px;
    font-family: ${isCurrentPage ? 'Poppins-Bold' : 'Poppins-Regular'};
  `}
`;

export const DotContainer = styled.TouchableOpacity<{ isCurrentPage: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 5px;
  background-color: ${({ isCurrentPage, theme: { colors } }) =>
    isCurrentPage ? colors.primary : colors.primary40};
`;
export const PaginationWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const PaginationContainer = styled.View`
  flex-direction: column;
  align-items: center;
`;

export const PageRangeText = styled.Text`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.gray60};
  text-align: center;
  margin-top: 8px;
  padding: 4px 8px;
  border-radius: 4px;
`;
