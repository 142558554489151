import {
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import React, { ComponentType } from 'react';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Button, Typography } from '../@hello-ui';
import * as S from './styles';
import { ListItem, ListItemAddress } from '~/components/MapViewList';
import { toCamelCase } from '~/utils/strings';

export type Location = {
  name: string;
  cnpj: string;
  email: string;
  address: ListItemAddress;
};

interface LocationCardProps<T> {
  locationData: ListItem<T>;
  onPressButton?: (item: ListItem<T>) => void;
  onPressWrapper?: (item: ListItem<T>) => void;
  withGap?: boolean;
  cutText?: boolean;
  hasShadow?: boolean;
  hasButton?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

export const LocationCard = <T,>({
  onPressButton,
  onPressWrapper,
  locationData,
  withGap,
  wrapperStyle,
  contentStyle,
  style,
  cutText,
  hasButton = true,
  hasShadow = true,
}: LocationCardProps<T>) => {
  const theme = useTheme();

  const WrapperComponent: ComponentType<TouchableOpacityProps | ViewProps> = onPressWrapper
    ? TouchableOpacity
    : View;

  return (
    <WrapperComponent
      onPress={() => onPressWrapper?.(locationData)}
      disabled={!onPressWrapper}
      style={wrapperStyle}>
      <S.StyledCard hasShadow={hasShadow} withGap={withGap} style={style as ViewStyle}>
        <S.Wrapper style={contentStyle}>
          <S.SwitchUnitIconWrapper>
            <Icon name="map-marker-outline" color={theme.colors.primary} size={24} />
            <S.SwitchUnitTitleWrapper>
              <Typography variant="subtitle" numberOfLines={cutText ? 1 : undefined}>
                {toCamelCase(locationData.name)}
              </Typography>
            </S.SwitchUnitTitleWrapper>
          </S.SwitchUnitIconWrapper>
          <S.InfoWrapper>
            <Typography variant="body2" numberOfLines={cutText ? 1 : undefined}>
              {toCamelCase(locationData.address.street)}, n° {locationData.address.number}
            </Typography>
            <Typography variant="body2" numberOfLines={cutText ? 1 : undefined}>
              {toCamelCase(locationData.address.city)}, {locationData.address.zipCode}
            </Typography>
          </S.InfoWrapper>

          {locationData.telephone && (
            <View className="mt-8 flex-row items-center">
              <View className="mr-12">
                <FeatherIcon name="phone-call" color={theme.colors.paragraph} size={20} />
              </View>
              <Typography variant="body2">{locationData.telephone}</Typography>
            </View>
          )}

          {hasButton && (
            <S.ButtonWrapper>
              <Button
                variant="primary"
                onPress={() => {
                  onPressButton && onPressButton(locationData);
                }}>
                Solicitar
              </Button>
            </S.ButtonWrapper>
          )}
        </S.Wrapper>
      </S.StyledCard>
    </WrapperComponent>
  );
};
