import { FileInfo } from './types';
import { fileTypeValidate, getFileSize } from './utils';
import imageCompression from 'browser-image-compression';

const getBase64Web = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const pickerDocument: FileInfo = async (typePicker: number, target?: any) => {
  let fileToUpload: FileInfo;
  const base64: string[] = [];

  await Promise.all(
    Object.keys(target.files).map(async (item) => {
      const uncompressedFile = target.files[item];

      const options = {
        maxSizeMB: 5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      let file = uncompressedFile;
      if (uncompressedFile.type !== 'application/pdf') {
        file = await imageCompression(uncompressedFile, options);
      }

      const dataurl = await getBase64Web(file);
      const arr = dataurl.split(',');
      base64.push(arr[1]);
    }),
  );

  fileToUpload = Object.keys(target.files).map((key) => {
    const type = target.files[key].type;

    fileTypeValidate(type);

    return {
      name: target.files[key].name?.replace(/\.[^/.]+$/, ''),
      type,
      base64: base64[key],
      size: getFileSize(base64[key]),
    };
  });

  return fileToUpload;
};
