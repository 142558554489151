import React, { useEffect, useRef, useState } from 'react';
import { MaterialSelect } from './components/MaterialSelect';
import { SelectTypes } from './types';

export const Select = ({
  error,
  onChange,
  onFocus,
  variant,
  style,
  required,
  inputRef,
  ...rest
}: SelectTypes) => {
  if (required) {
    rest.label = `${rest.label} *`;
    rest.placeholder = `${rest.placeholder} *`;
  }

  const commonProps = {
    onChangeText: onChange,
    onFocus,
    error,
    variant,
    style,
    ...rest,
  };

  const ref = useRef<null>(null);
  return <MaterialSelect {...commonProps} ref={inputRef ?? ref} />;
};
