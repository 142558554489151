import styled from 'styled-components/native';

export const WrapperImageAuthorizer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ImageAuthorizer = styled.Image.attrs({
  resizeMode: 'contain',
})<{ width: number }>`
  height: ${({ theme }) => (theme.isMobile ? '40px' : '50px')};
  width: ${({ width }) => width}px;
`;

export const ContainerLogoUnivers = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
