import { useAuth } from '~/auth/legacy/useAuth';
import { validateUserPermissions } from '~/utils/validateUserPermissions';

interface useCanParams {
  features?: string[];
  onix?: string[];
  config?: {
    feature: 'every' | 'some';
    onix: 'every' | 'some';
  };
}

export function useCan({ features, onix, config }: useCanParams) {
  const { onixCode, partner } = useAuth();

  if (!onixCode || !partner) {
    return false;
  }

  const userHasValidPermissions = validateUserPermissions({
    userOnix: onixCode.onix_code,
    userFeatures: partner.features,
    features,
    onix,
    config,
  });

  return userHasValidPermissions;
}
