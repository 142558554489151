import React, { useCallback, useEffect } from 'react';
import { Typography, Button } from '../../../../components/@tem-ui';
import { Spacing } from '../../../../components/Spacing';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { TypesEnum } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';
import { UploadField } from '~/components/UploadField';
import { useUploader } from '~/components/@tem-ui/Uploader/useUploader';
import * as S from './styles';
import { ItemType } from '~/components/@tem-ui/Stepper/types';

type ScheduleUploadExamProps = {
  stepperData: ItemType;
};

export const BenefitsUploadExam = ({ stepperData }: ScheduleUploadExamProps): JSX.Element => {
  const { appointment, setAppointment } = useAppointment();
  const { files, maxFileSize, clearUploader } = useUploader();
  const { nextStep, toStepper, setStepData, finalizeSteps } = useStepper();

  const mapOnSelect = () => {
    setAppointment({ ...appointment, attatchment: files, type: TypesEnum.Exam });
    nextStep();
  };

  useEffect(() => {
    clearUploader();
  }, []);

  const changeStep = useCallback(async () => {
    finalizeSteps();
    setStepData(stepperData);
  }, []);

  return (
    <>
      <S.WrapperSchedule>
        <Spacing bottom={16}>
          <Typography variant="heading1" color="black" bold>
            Precisamos de uma foto do seu pedido médico
          </Typography>
        </Spacing>
        <S.WrapperScheduleExam>
          <Spacing bottom={24}>
            <Typography variant="body2" color="black">
              Para seguirmos com seu agendamento, precisamos que nos envie seu pedido médico.
            </Typography>
          </Spacing>
          <Spacing bottom={24}>
            <Typography variant="body2" color="black">
              Fotografe o pedido ou selecione o arquivo da sua biblioteca clicando no botão "Enviar
              foto" e a imagem será anexada na sua solicitação de exame.
            </Typography>
          </Spacing>
          <Typography variant="paragraph" color="black" bold>
            {`Formatos permitidos: PDF, JPG ou PNG até ${maxFileSize}MB.`}
          </Typography>
        </S.WrapperScheduleExam>
        <S.WrapperButton>
          <UploadField mapOnSelect={mapOnSelect} />
          {(!files || files.length === 0) && (
            <Button
              variant="default"
              title="Não tenho pedido médico"
              onPress={async () => {
                await changeStep();
                toStepper(0);
              }}
            />
          )}
        </S.WrapperButton>
      </S.WrapperSchedule>
    </>
  );
};
