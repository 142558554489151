import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Wrapper } from '../../components/@tem-ui';

export const WrapperContent = styled.View`
  width: 100%;
  max-width: 1200px;
`;

export const Container = styled(Wrapper)``;

export const BackgroundContainer = styled.View`
  max-width: 1240px;
  ${Platform.OS === 'web' && 'width: calc(100% - 64px)'}
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 32px;
  overflow: hidden;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 0px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  `}
`;

export const ContentCenter = styled.View`
  z-index: 0;
  margin: auto;
  height: 120px;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    left: -28px;
  `}
`;

export const WrapperContainer = styled.View`
  width: 100%;
  max-height: 112px;
  max-width: 608px;
  margin: 0 auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
    max-height: 608px;
  `}
`;

export const WrapperTitle = styled.View`
  color: ${({ theme: { colors } }) => colors.black80};
  font-size: 20px;
  font-family: Inter;
  font-weight: 600;
  width: 100%;
  margin: 6px 0;
  margin-top: 28px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.Text`
  color: ${({ theme: { colors } }) => colors.gray70};
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 20px;
    margin-bottom: 40px;
    height: 25px;
  `}
`;

export const Icon = styled.View`
  height: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WrapperDescription = styled.View`
  color: ${({ theme: { colors } }) => colors.black70};
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin: 6px 0;
  margin-top: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 40px;
  `}
`;

export const DescriptionText = styled.Text`
  color: ${({ theme: { colors } }) => colors.gray70};
  margin-bottom: 17px;
  font-size: 16px;
  width: 100%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-bottom: 10px;
  `}
`;

export const WrapperRectangle = styled.View`
  background-color: #fafafa;
  padding: 15px;
  border-radius: 10;
  margin-bottom: 30px;
  border: 1px solid #bdbdbd;
  margin-top: 20px;
  height: 150px;
  text-align: center;
`;

export const RectangleTitle = styled.Text`
  font-weight: 600;
`;
