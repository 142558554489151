import React from 'react';

import { FlatList, Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsDigitalFamaleAssistance/desktop.png';
import mobileBackground from '../../assets/images/benefitsDigitalFamaleAssistance/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { BenefitsDigitalFemaleAssistanceIcon } from '~/components/Icons/BenefitsDigitalFemaleAssistanceIcon';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsDigitalFemaleAssistance = (): JSX.Element => {
  const navigation = useNavigation();
  const { showSweetAlert } = useSweetAlert();
  const assistanceWhatsAppNumber = '+551149498158';
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const knowMore = [
    'Sexualidade e anticoncepção;',
    'Gestação e pós-parto;',
    'Desenvolvimento infantil;',
    'Menopausa e sintomas.',
  ];

  const redirectWhatsapp = async () => {
    try {
      if (Platform.OS === 'web') {
        window.open(`https://wa.me/${assistanceWhatsAppNumber}`, '_blank');
      } else {
        await Linking.openURL(`whatsapp://send?phone=${assistanceWhatsAppNumber}`);
      }
    } catch (err) {
      showSweetAlert('Ops, algo deu errado', 'Erro ao abrir whatsapp', 'error', true, false, {
        layout: 'helloUi',
        touchOutside: false,
      });
    }
  };

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <BenefitsDigitalFemaleAssistanceIcon />,
        title: 'Assistente Digital Feminina',
        onBack: () => {
          navigation.navigate('BenefitsWoman');
        },
      }}>
      <View>
        <Typography variant="body2">
          Tenha uma Assistente Digital por WhatsApp, especializada no universo feminino, para{' '}
          <Typography variant="bodyHighlight2">
            ajudá-la com dúvidas rápidas sobre saúde,
          </Typography>{' '}
          independentemente da fase de vida em que você se encontra.
        </Typography>
      </View>
      <View className="pt-24 mobile:pb-56">
        <Collapse title="Saiba mais">
          <Typography variant="body2" className="mb-8">
            A assistente digital oferece conteúdos em formato de{' '}
            <Typography variant="bodyHighlight2">e-book, vídeo e podcast</Typography> com dicas,
            como:
          </Typography>
          <FlatList
            data={knowMore}
            renderItem={({ item }) => (
              <View className="mb-8">
                <Typography key={`key-${Math.random()}`} variant="body2">
                  • {item}
                </Typography>
              </View>
            )}
          />
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mt-auto">
        <Typography variant="bodyHighlight2" className="text-center">
          Para utilizar o benefício, entre em contato{'\n'} com a assistente digital:
        </Typography>
      </View>
      <Button variant="primary" onPress={async () => redirectWhatsapp()}>
        Falar com a assistente
      </Button>
    </PageWithCard>
  );
};
