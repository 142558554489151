import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledDefaultTheme } from '../Theme';

export const BreadcrumbItem = styled.TouchableOpacity``;

export const BreadcrumbText = styled.Text<{ color?: string; actual: boolean }>`
  font-size: ${Platform.OS === 'web' ? '12px' : '14px'};
  font-style: normal;
  font-family: 'Poppins';
  font-weight: ${({ actual }) => (actual ? 600 : 400)};
  line-height: ${Platform.OS === 'web' ? '24px' : '18px'};
  letter-spacing: 0.25px;
  color: ${({ color }) => color ?? StyledDefaultTheme.colors.black};

  ${({ actual }) =>
    Platform.select({
      web: css`
        font-family: 'Poppins';
        line-height: 24px;
      `,
      android: css`
        font-family: ${actual ? 'Poppins-Bold' : 'Poppins'};
        line-height: 18px;
      `,
      ios: css`
        font-family: ${actual ? 'Poppins-Bold' : 'Poppins'};
        line-height: 18px;
      `,
    })}
`;
