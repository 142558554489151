import styled, { css } from 'styled-components/native';

export const SelectContainer = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
  `}
`;

export const HelperTextContainer = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    width: ${tokens.sizes.full};
    padding-horizontal: ${tokens.space.s16};
    margin-top: ${tokens.space.s4};
    margin-bottom: ${isMobile ? 3 : 0};
    height: ${isMobile ? 18 : tokens.space.s24};
  `}
`;

export const SelectToPressContainer = styled.TouchableOpacity<{ disabled?: boolean }>`
  ${({ theme: { tokens, colors, isMobile }, disabled }) => css`
    width: ${tokens.sizes.full};
    border-color: ${disabled ? colors['gray-disabled'] : colors.placeholder};
    border-width: 1px;
    border-radius: ${tokens.borderRadius.lg};
    color: ${colors.black20};
    justify-content: center;

    ${isMobile
      ? css`
          padding: 11px 15px 11px 15px;
        `
      : css`
          padding: 15px 15px 15px 15px;
        `};
  `}
`;

export const SelectTextFake = styled.Text<{ disabled?: boolean }>`
  ${({ theme: { colors, platform, isMobile, tokens }, disabled }) => css`
    font-size: ${isMobile ? '16px' : '18px'};
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-align-vertical: center;
    color: ${disabled ? colors['gray-disabled'] : colors.paragraph};

    ${platform?.OS !== 'web' &&
    css`
      font-family: 'Poppins-Regular';
    `}
  `}
`;
