import React, { useState, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useSetRecoilState } from 'recoil';
import { useNavigation } from '@react-navigation/native';
import { parseISO, format } from 'date-fns';
import * as S from '../styles';
import { dateBmg } from '../atom';
import { TermsConditions } from '../TermsConditions';
import { CalendarInput } from './CalendarInput';
import { SelectDate } from './SelectDate/Index';
import { ScheduleTimes } from './ScheduleTimes';
import { ConectaDataTimes } from './types';
import { Spacing } from '~/components/Spacing';

import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useApi } from '~/hooks/api';
import { serializeAndUpperString } from '~/utils/strings';
import { trackingEventType } from '~/contexts/tracking/types';
import { Button, useMedia, Typography, Dialog, MobileAutoSpace } from '~/components/@hello-ui';

export const BmgScheduleDate = (): JSX.Element => {
  const api = useApi();
  const today = new Date();
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();

  const { nextStep } = useStepper();

  const {
    appointment,
    setAppointment,
    submitAppointment,
    scheduleSubmissionStatus,
    appointmentLoading,
  } = useAppointment();

  const setBmgDate = useSetRecoilState(dateBmg);

  const isGeneralClinic = serializeAndUpperString(appointment?.specialty?.name) === 'CLÍNICO GERAL';

  const [currentDate, setCurrentDate] = useState<Date | string>(today);
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataTimes, setDataTimes] = useState<ConectaDataTimes | undefined>();
  const [nextDisabled, setNextDisbled] = useState<boolean>(true);
  const [showTerms, setShowTerms] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  useEffect(() => {
    scheduleSubmissionStatus && navigation.navigate('ScheduleAppointmentSuccess');
    setButtonLoading(false);
  }, [scheduleSubmissionStatus]);

  useEffect(() => {
    if (!showTerms && appointment?.idCalendario && isGeneralClinic) {
      setShowTerms(true);
    }
  }, [appointment]);

  useEffect(() => {
    if (appointment?.idCalendario && appointment?.specialty?.id === 12) {
      submitAppointment(trackingEventType.RequestedTelemedicineConecta);
    }
  }, [appointment?.idCalendario]);

  const submitScheduleDate = () => {
    if (buttonLoading) {
      return;
    }

    try {
      setButtonLoading(true);

      setAppointment({
        ...appointment,
        idCalendario: dataTimes?.sheduleDoctors[0]?.doctors[0]?.idCalendario,
        days: [],
        type: 1,
        horaDisponivel: selectedDate,
      });

      appointment?.specialty?.id !== 12 && nextStep();
      //setAppointment({ ...appointment, horaDisponivel: selectedDate });
      setBmgDate({ date: selectedDate, schedule: dataTimes });
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const handleGetDate = useCallback(async (day: Date | string) => {
    setLoading(true);
    try {
      if (typeof day === 'string') {
        const { data } = await api.getScheduleTimes(
          format(parseISO(day), 'yyyy-MM-dd'),
          appointment?.specialty?.id,
        );
        setDataTimes(data);
      } else if (day instanceof Date) {
        const { data } = await api.getScheduleTimes(
          format(day, 'yyyy-MM-dd'),
          appointment?.specialty?.id,
        );
        setDataTimes(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    void handleGetDate(today);
  }, []);

  return (
    <>
      <S.WrapperSchedule>
        <Spacing bottom={16}>
          <Typography variant="title" color="black">
            Selecione a data e o horário
          </Typography>
        </Spacing>
        {showTerms && (
          <TermsConditions
            onAccept={() => {
              appointment?.specialty?.id === 12 &&
                submitAppointment(trackingEventType.RequestedTelemedicineConecta);
              setShowTerms(false);
            }}
            onDimiss={setShowTerms}
          />
        )}
        <S.WrapperDays>
          <SelectDate
            onSelect={(day: Date) => {
              void handleGetDate(format(new Date(day), 'yyyy-MM-dd'));
            }}
            ourDate={currentDate}
          />
          <S.WrapperLinkCalendar
            onPress={() => {
              setDialogIsOpen(true);
            }}>
            <Icon name="calendar" size={26} color={theme?.colors?.black} />
            <Typography variant="link" color="black" style={{ marginLeft: 10, paddingTop: 2 }}>
              Visualizar mais datas
            </Typography>
            <Icon
              name="chevron-right"
              style={{ marginLeft: 2, marginTop: 5 }}
              size={22}
              color={theme?.colors?.primary}
            />
          </S.WrapperLinkCalendar>
          <Spacing top={media.isMobile ? 16 : 26}>
            <Typography variant="body2" color="black" style={{ height: 30 }}>
              Horários disponíveis
            </Typography>
          </Spacing>
          <Spacing top={2}>
            <ScheduleTimes
              loading={loading}
              times={dataTimes}
              onSelect={(disable, item) => {
                setNextDisbled(disable);
                setSelectedDate(item);
              }}
            />
          </Spacing>
        </S.WrapperDays>
      </S.WrapperSchedule>
      <MobileAutoSpace heightDesktop={24} />
      <Button
        variant={'primary'}
        disabled={nextDisabled || (isGeneralClinic && appointmentLoading) || buttonLoading}
        onPress={() => {
          submitScheduleDate();
        }}
        loading={appointmentLoading ?? false}>
        Próximo
      </Button>

      <Dialog
        visible={dialogIsOpen}
        onClose={() => {
          setDialogIsOpen(false);
        }}>
        <CalendarInput
          onSelect={(day) => {
            void handleGetDate(day.dateString);
            setCurrentDate(parseISO(day.dateString));
            setDialogIsOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};
