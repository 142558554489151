import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { ServiceCardsTypes } from '../types';
import * as S from './styles';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useTracking } from '~/services/tracking';
import { useAppointment } from '~/hooks/appointment';
import { CardButton } from '~/components/@hello-ui';

interface serviceFilterType {
  servicesFilter: ServiceCardsTypes[];
}

export const ServicesCard = ({ servicesFilter }: serviceFilterType): JSX.Element => {
  const navigation = useNavigation();
  const trackingApi = useTracking();
  const { finalizeSteps } = useStepper();
  const { clearAppointments } = useAppointment();

  const services = servicesFilter.map(
    ({ tracking, route, icon, title, visible, routeParams, description }, idx: number) =>
      visible ? (
        <S.WrapperCard key={`service-${idx}`}>
          <CardButton
            variant="large"
            iconSideOfTitle
            icon={icon}
            title={title}
            description={description}
            key={`servicebtn-${idx}`}
            onPress={() => {
              if (tracking) {
                void trackingApi.tracking.addMovement({
                  event: tracking,
                });
              }

              if (route === 'BenefitsNetwork') {
                clearAppointments();
              }

              finalizeSteps();
              route ? navigation.navigate(route, routeParams ?? {}) : null;
            }}
          />
        </S.WrapperCard>
      ) : (
        <></>
      ),
  );

  return <>{services}</>;
};
