import React from 'react';
import { useTheme } from 'styled-components/native';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { View } from 'react-native';
import * as S from '../styles';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { CardButton, Typography } from '~/components/@hello-ui';

export const ScheduleLocalType = (): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const { nextStep } = useStepper();
  const theme = useTheme();

  return (
    <>
      <S.TitleWrapper>
        <Typography variant="title">Qual o local?</Typography>
      </S.TitleWrapper>

      <View className="mt-8 mobile:mt-4">
        <Typography variant="body2">
          Selecione as proximidades de onde gostaria de ser atendido(a)
        </Typography>
      </View>

      <View className="mt-24">
        <CardButton
          variant="xlarge"
          title="Especificar o local"
          description="Escolha a sua localização do atendimento"
          icon={<Ionicons name="location-outline" size={26} color={theme?.colors.primary} />}
          onPress={() => {
            nextStep();
          }}
        />
      </View>
    </>
  );
};
