import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const WifiIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 44 28" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3844 11.1969C17.7613 11.1969 13.279 12.8642 9.71764 15.9173C9.17255 16.3846 8.35185 16.3215 7.88457 15.7764C7.41728 15.2313 7.48035 14.4106 8.02545 13.9433C12.0518 10.4916 17.1324 8.59692 22.3844 8.59692C27.6364 8.59692 32.717 10.4916 36.7434 13.9433C37.2885 14.4106 37.3515 15.2313 36.8843 15.7764C36.417 16.3215 35.5963 16.3846 35.0512 15.9173C31.4898 12.8642 27.0075 11.1969 22.3844 11.1969Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3077 3.29995C15.1576 3.29995 8.2471 5.9845 2.87345 10.8596C2.3417 11.342 1.51956 11.302 1.03714 10.7703C0.554729 10.2385 0.594722 9.41638 1.12647 8.93397C6.97166 3.63108 14.5026 0.699951 22.3077 0.699951C30.1127 0.699951 37.6436 3.63108 43.4888 8.93397C44.0206 9.41638 44.0606 10.2385 43.5782 10.7703C43.0957 11.302 42.2736 11.342 41.7419 10.8596C36.3682 5.9845 29.4577 3.29995 22.3077 3.29995Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3175 18.069C20.206 18.069 18.143 18.745 16.4147 20.0087C15.8352 20.4325 15.0218 20.3062 14.598 19.7267C14.1743 19.1471 14.3005 18.3337 14.8801 17.9099C17.0491 16.324 19.6483 15.469 22.3175 15.469C24.9866 15.469 27.5859 16.324 29.7549 17.9099C30.3344 18.3337 30.4607 19.1471 30.0369 19.7267C29.6132 20.3062 28.7998 20.4325 28.2202 20.0087C26.4919 18.745 24.429 18.069 22.3175 18.069Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3076 26C20.3076 24.8954 21.203 24 22.3076 24H22.3296C23.4342 24 24.3296 24.8954 24.3296 26C24.3296 27.1045 23.4342 28 22.3296 28H22.3076C21.203 28 20.3076 27.1045 20.3076 26Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default WifiIcon;
