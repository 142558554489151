import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};

export const WarningIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 75} height={height ?? 75} viewBox="7 7 64 64" fill="none">
      <G id="icon-large-warning-color">
        <G id="Ellipse 54">
          <Circle cx="39" cy="39" r="32" fill="#FA9F47" />
          <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
        </G>
        <G id="Group 1556">
          <Path
            id="Vector"
            d="M38.9987 45.7792C37.4091 45.7792 36.0908 44.4609 36.0908 42.8713V27.3625C36.0908 25.7728 37.4091 24.4546 38.9987 24.4546C40.5884 24.4546 41.9066 25.7728 41.9066 27.3625V42.8713C41.9066 44.4609 40.5884 45.7792 38.9987 45.7792Z"
            fill="white"
          />
          <Path
            id="Vector_2"
            d="M38.9987 56.0157C38.611 56.0157 38.2233 55.9381 37.8743 55.783C37.5254 55.6667 37.2152 55.4341 36.9438 55.1627C36.6724 54.8913 36.4398 54.5811 36.3235 54.2322C36.1684 53.8832 36.0908 53.4955 36.0908 53.1078C36.0908 52.7201 36.1684 52.3711 36.3235 51.9834C36.4785 51.6344 36.6724 51.3243 36.9438 51.0529C38.0294 49.9672 39.968 49.9672 41.0536 51.0529C41.325 51.3243 41.5189 51.6344 41.674 51.9834C41.8291 52.3711 41.9066 52.7201 41.9066 53.1078C41.9066 53.4955 41.8291 53.8832 41.674 54.2322C41.5577 54.5811 41.325 54.8913 41.0536 55.1627C40.5108 55.7055 39.7742 56.0157 38.9987 56.0157Z"
            fill="white"
          />
        </G>
      </G>
    </Svg>
  );
};
