import React, { createElement } from 'react';
import { Typography } from '../Typography';
import { ButtonTypes } from './types';
import { useTheme } from 'styled-components/native';
import { Primary, Secondary, White, Default } from './elements';
import { AnimatedDots } from '../AnimatedDots';

const Components = {
  primary: Primary,
  secondary: Secondary,
  white: White,
  default: Default,
};

export const Button = ({
  testID = 'button-component',
  title,
  onPress,
  disabled,
  variant = 'white',
  colorText,
  loading,
}: ButtonTypes): React.FunctionComponentElement<any> => {
  const theme = useTheme();

  const children = loading ? (
    <AnimatedDots color={theme.colors.white} />
  ) : (
    <Typography
      bold
      variant="body2"
      color={
        colorText
          ? colorText
          : ['secondary', 'default'].includes(variant)
          ? theme.colors.primary
          : variant === 'primary' && disabled
          ? theme.colors.black16
          : 'white'
      }>
      {title}
    </Typography>
  );

  let style = {};
  if (loading) {
    disabled = true;
    style = { backgroundColor: theme.colors.primary };
  }

  return createElement(Components[variant], {
    onPress: () => (disabled || loading ? null : onPress()),
    disabled,
    testID,
    children,
    style,
  });
};
