import React, { useCallback, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ScheduleSuccess } from '../../ScheduleSuccess';
import { ScheduleLocal } from './ScheduleLocal';
import { ScheduleSpecialty } from './ScheduleSpecialty';
import { ScheduleDate } from './ScheduleDate';
import { ScheduleUploadExamEmpty } from './ScheduleUploadExamEmpty';
import { ScheduleUploadExam } from './ScheduleUploadExam';
import { SelectDependents } from './SelectDependents/SelectDependents';
import { BmgScheduleDate } from './Bmg/BmgScheduleDate/Index';
import { DoctorsList } from './Bmg/DoctorsList';
import { ScheduleLocalType } from './ScheduleLocalType';
import { SchedulePreference } from './SchedulePreference';

import { ScheduleReview } from './ScheduleReview';
import { ScheduleUnit } from './ScheduleUnit';
import { ScheduleUploadExamFiles } from './ScheduleUploadExamFiles';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import ExamIcon from '~/components/Icons/Exam';
import VideoIcon from '~/components/Icons/Video';
import { trackingEventType } from '~/contexts/tracking/types';
import { AppointmentFlowType, TypesEnum } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';
import { ItemType } from '~/components/@tem-ui/Stepper/types';
import { useAuth } from '~/auth/legacy/useAuth';
import { useStepper } from '~/components/@tem-ui/Stepper';
import ToothIcon from '~/components/Icons/ToothIcon';
import CheckUpIcon from '~/components/Icons/CheckUpIcon';
import { isMercadoPagoOnix } from '~/helpers/mercado-pago';

const bmgOnixs = ['7208', '7209', '7210', '7309', '7310', '7311'];

export const checkBmgTelemedicine = (onix_code?: string) => {
  const checkOnix = onix_code ? bmgOnixs.filter((item) => Number(item) === Number(onix_code)) : [];

  if (checkOnix.length > 0) {
    return [
      <ScheduleSpecialty key="ScheduleSpecialty" isTelemedicine={true} />,
      <BmgScheduleDate key="BmgScheduleDate" />,
      <DoctorsList key="DoctorsList" />,
    ];
  }

  return [
    <ScheduleSpecialty key="ScheduleSpecialty" isTelemedicine={true} />,
    <ScheduleDate key="ScheduleDate" />,
    <SelectDependents key="SelectDependent" />,
    <ScheduleReview
      key="ScheduleReview"
      scheduleType={TypesEnum.Video}
      trackingType={trackingEventType.RequestedInVideoAppointment}
    />,
    <ScheduleSuccess key="ScheduleSuccess" />,
  ];
};

export const scheduleType = (): ItemType[] => {
  const { checkIsEnabledFeature, onixCode } = useAuth();
  const [flowDate, setFlowDate] = useState<boolean>(false);
  const navigation = useNavigation();

  const { stepper } = useStepper();

  useEffect(() => {
    if (stepper !== null) {
      setFlowDate(false);
    }
  }, [stepper]);
  
  const { appointment, setAppointment } = useAppointment();
  useEffect(() => {
    flowDate &&
      appointment &&
      setAppointment({ ...appointment, flowType: AppointmentFlowType.Indications });
  }, [flowDate]);

  const checkAppoitmentB2C = useCallback(() => {
    return [
      <ScheduleSpecialty key="ScheduleSpecialty" />,
      <ScheduleLocalType key="ScheduleLocalType" />,
      <ScheduleLocal key="ScheduleLocal" />,
      <SchedulePreference key="SchedulePreference" setFlowDate={() => setFlowDate(true)} />,
      ...(!flowDate
        ? [<ScheduleUnit key="ScheduleUnit" />, <ScheduleDate key="ScheduleDate" />]
        : [<ScheduleDate key="ScheduleDate" />]),
      <SelectDependents key="SelectDependent" />,
      <ScheduleReview
        key="ScheduleReview"
        scheduleType={TypesEnum.Appointment}
        trackingType={trackingEventType.RequestedInPresentialAppointment}
      />,
      <ScheduleSuccess key="presentionSuccess" />,
    ];
  }, [flowDate]);

  const checkExamtB2C = useCallback(() => {
    return [
      appointment?.notFoundAttatchment ? (
        <ScheduleUploadExamEmpty key="ScheduleUploadExamEmpty" />
      ) : (
        <ScheduleUploadExam key="ScheduleUploadExam" stepperData={presentialAppointment} />
      ),
      <ScheduleUploadExamFiles key="ScheduleUploadExamFiles" />,
      <ScheduleLocalType key="ScheduleLocalType" />,
      <ScheduleLocal key="ScheduleLocal" />,
      <SchedulePreference
        key="SchedulePreference"
        setFlowDate={() => {
          setFlowDate(true);
        }}
      />,
      ...(!flowDate
        ? [<ScheduleUnit key="ScheduleUnit" />, <ScheduleDate key="ScheduleDate" />]
        : [<ScheduleDate key="ScheduleDate" />]),
      <SelectDependents key="SelectDependent" />,
      <ScheduleReview
        key="ScheduleReview"
        scheduleType={TypesEnum.Exam}
        trackingType={trackingEventType.RequestedInPresentialAppointment}
      />,
      <ScheduleSuccess key="ScheduleSuccess" />,
    ];
  }, [flowDate, appointment?.notFoundAttatchment]);

  const presentialAppointment = {
    icon: <StethoscopeIcon />,
    title: 'Consulta Presencial',
    description: 'Especialidades médicas e terapias',
    customTitle: 'Solicitação de consulta',
    description: 'Especialidades médicas e terapias',
    disabled: !checkIsEnabledFeature('health_network'),
    visible: checkIsEnabledFeature('health_network'),
    visibilitySteps: false,
    steps: checkAppoitmentB2C(),
    tracking: trackingEventType.ClickedOnPresentialAppointment,
    iconSideOfTitle: true,
  };

  return [
    presentialAppointment,
    {
      icon: <ExamIcon />,
      title: 'Exames e Procedimentos',
      description: 'Lista de laboratórios e exames',
      customTitle: 'Solicitação de exames',
      description: 'Lista de laboratórios e exames',
      disabled: !checkIsEnabledFeature('health_network'),
      visible: checkIsEnabledFeature('health_network'),
      visibilitySteps: false,
      steps: checkExamtB2C(),
      tracking: trackingEventType.ClickedOnExamAppointment,
      iconSideOfTitle: true,
    },
    {
      icon: <VideoIcon />,
      title: 'Consulta por vídeo',
      description: 'Telemedicina com especialidades médicas',
      customTitle: 'Agendar consulta por vídeo',
      description: 'Telemedicina com especialidades médicas',
      disabled: !checkIsEnabledFeature('telemedicine'),
      visible: checkIsEnabledFeature('telemedicine'),
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnVideoAppointment,
      steps: checkBmgTelemedicine(onixCode?.onix_code),
      iconSideOfTitle: true,
    },
    {
      icon: <VideoIcon />,
      title: 'Psicologia por Vídeo',
      description: 'Telemedicina com psicólogos',
      customTitle: 'Solicitação terapia por vídeo',
      description: 'Telemedicina com psicólogos',
      disabled: !checkIsEnabledFeature('psicologia_online'),
      visible: checkIsEnabledFeature('psicologia_online'),
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnPsychologicalVideoTherapy,
      steps: [
        <ScheduleDate key="ScheduleDate" />,
        <SelectDependents key="SelectDependent" />,
        <ScheduleReview
          key="ScheduleReview"
          scheduleType={TypesEnum.VideoPsychology}
          trackingType={trackingEventType.RequestedInPsychologicalVideoTherapy}
          specialtyName="psicologia"
        />,
        <ScheduleSuccess key="presentionSuccess" />,
      ],
      iconSideOfTitle: true,
    },
    {
      icon: <ToothIcon />,
      title: 'Consulta Odontológica',
      description: 'Especialidades odontológicas',
      disabled: !checkIsEnabledFeature('dentistry'),
      visible: checkIsEnabledFeature('dentistry'),
      visibilitySteps: false,
      steps: [
        <ScheduleLocal key="ScheduleLocal" />,
        <ScheduleUnit key="ScheduleUnit" specialtyName="odontologia" />,
        <ScheduleDate key="ScheduleDate" />,
        <SelectDependents key="SelectDependent" />,
        <ScheduleReview
          key="ScheduleReview"
          specialtyName="odontologia"
          scheduleType={TypesEnum.Dentistry}
          trackingType={trackingEventType.RequestedInDentalConsultationsAndProcedures}
        />,
        <ScheduleSuccess key="presentionSuccess" />,
      ],
      tracking: trackingEventType.ClickedOnDentalConsultationsAndProcedures,
      iconSideOfTitle: true,
    },
    {
      icon: <CheckUpIcon />,
      title: 'Checkup Médico',
      onPress: () => navigation.navigate('BenefitsCheckup'),
      description: 'Solicite um checkup médico',
      disabled: !checkIsEnabledFeature('checkup'),
      visible: checkIsEnabledFeature('checkup'),
      visibilitySteps: false,
      steps: isMercadoPagoOnix(onixCode?.onixCode) && [
        <SelectDependents key="SelectDependent" />,
        <ScheduleReview
          key="ScheduleReview"
          specialtyName="checkup"
          scheduleType={TypesEnum.CheckUp}
          trackingType={trackingEventType.RequestedInDentalConsultationsAndProcedures}
        />,
        <ScheduleSuccess key="presentionSuccess" />,
      ],
      tracking: trackingEventType.ClickedOnPresentialAppointment, // <- Checar a chave correta para esse item
      iconSideOfTitle: true,
    },
  ];
};
