import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { Platform, TouchableOpacity, View } from 'react-native';

import { clsx } from 'clsx';
import { useAuth } from '~/auth/legacy/useAuth';
import { Button, Loader, PageWithCard, Typography } from '~/components/@hello-ui';
import { toCamelCase } from '~/utils/strings';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';

export const Dependents = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { dependents, loadingDependets } = useAuth();
  const { isDeepLink } = DeepLink.useParams();

  const [activeDependents, setActiveDependents] = useState([]);

  const dependentsLimit = theme.dependents_limit === '' ? 0 : theme.dependents_limit;

  const maxDependentsReached = activeDependents.length >= dependentsLimit;
  const handleGoBack = () => {
    if (isDeepLink) {
      DeepLink.sendEvent(DeepLinkEvent.Cancel);
    } else {
      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.navigate('DependentsPresentation', { fromDependents: true });
    }
  };

  useEffect(() => {
    const result = dependents?.length
      ? dependents?.filter((d, idx) => d.status !== 3 && idx < parseInt(dependentsLimit))
      : [];

    setActiveDependents(result);
  }, [dependents]);

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Gerenciar Dependentes',
        onBack: isDeepLink ? undefined : handleGoBack,
      }}>
      <Typography variant="subtitle" className="mb-16">
        Cadastrar
      </Typography>
      <Button
        variant="simple"
        iconPosition="left"
        className="justify-start px-24 py-16 mobile:py-12"
        onPress={() => navigation.navigate('CreateDependents')}
        icon={
          <FeatherIcon
            name="plus"
            size={24}
            color={maxDependentsReached ? theme.colors['primary20'] : theme?.colors.primary}
          />
        }
        textStyle={{
          fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
          fontWeight: maxDependentsReached ? '600' : '700',
          fontSize: 19,
          lineHeight: 28,
          color: maxDependentsReached ? theme.colors['gray-disabled'] : theme.colors.paragraph,
        }}
        disabled={maxDependentsReached}>
        Adicionar dependente
      </Button>
      <>
        {maxDependentsReached && (
          <Typography variant="helperText" color={theme.colors.warning} className="mt-8 pl-20">
            Você atingiu o limite máximo de dependentes cadastrados.
          </Typography>
        )}
      </>
      <Typography variant="subtitle" className="mb-8 mt-80 mobile:mt-40">
        Gerenciar cadastros
      </Typography>
      <Typography variant="body2" className="mb-24 mobile:mb-16">
        Atualize os dados ou exclua dependentes
      </Typography>

      {loadingDependets && <Loader variant="circle-loader" />}
      {!loadingDependets && (activeDependents || []).length === 0 ? (
        <View className="rounded-lg bg-background-gray p-24">
          <Typography variant="helperText">Você ainda não tem dependentes cadastrados.</Typography>
        </View>
      ) : (
        activeDependents?.map((dependent, index) => (
          <TouchableOpacity
            key={dependent.id}
            className={clsx(
              'flex-row items-center rounded-lg border-[1px] border-gray-disabled p-16',
              index !== 0 ? (theme.isMobile ? 'mt-8' : 'mt-16') : '',
            )}
            onPress={() => navigation.navigate('DependentsView', { cpf: dependent.cpf })}>
            <Typography variant="body2">{toCamelCase(dependent.name)}</Typography>
            <View className="ml-auto shrink-0 flex-row pl-24">
              <FeatherIcon
                name="chevron-right"
                size={24}
                color={theme?.colors?.primary}
                testID="chevron-icon"
              />
            </View>
          </TouchableOpacity>
        ))
      )}
    </PageWithCard>
  );
};
