import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

type WrapperProps = { error: any };
type CodeProps = { codeStyle?: 'boxed' };

const codeStyleCss = css`
  background-color: #f6f6f6;
`;

export const Wrapper = styled.View<WrapperProps>`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  margin-top: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme: { colors }, error }) => (error ? colors.red70 : colors.white15)};
`;

export const WrapperContainer = styled.View`
  width: 100%;
  height: auto;
  position: relative;
  z-index: -1;
`;

export const WrapperIcon = styled.TouchableOpacity`
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  top: 23px;
  align-items: center;
  justify-content: center;
`;

export const WrapperInput = styled.TextInput`
  height: 56px;
  font-size: 16px;
  border-radius: 8px;
  ${Platform.OS === 'web' && 'outline-style: none;'}
  box-shadow: none;
  padding-horizontal: 16px;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const WrapperCode = styled(Wrapper)<CodeProps>`
  width: 56px;
  align-items: center;
  justify-content: center;
  ${({ codeStyle }) => (codeStyle === 'boxed' ? codeStyleCss : css``)}
  border-color: ${({ theme: { colors }, error, codeStyle }) => {
    if (error) {
      return colors.red70;
    }

    return codeStyle === 'boxed' ? '#b7b7b7' : colors.white15;
  }};
`;

export const TextCode = styled.Text<CodeProps>`
  font-size: 24px;
  text-align: center;
  color: ${({ theme: { colors }, codeStyle }) =>
    codeStyle === 'boxed' ? '#1E1E1E' : colors.white};
`;

export const ErrorText = styled.Text`
  color: ${({ theme: { colors } }) => colors.red70};
  font-size: 13px;
  margin: auto;
  margin-top: 8px;
`;

export const MaterialTextInputWrapper = styled.View`
  min-height: 56px;
  margin-top: 16px;
  margin-bottom: 16px;
  z-index: -1;
`;

export const SelectIcon = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 50%;
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const SelectWrapper = styled.TouchableOpacity`
  width: 100%;
`;

export const ModalBackdrop = styled.Pressable`
  flex: 1;
`;

export const ModalContent = styled.View`
  ${({ theme: { isMobile } }) =>
    isMobile
      ? `shadow-color: #000;
        shadow-offset: {width: 1, height: 2};
        shadow-opacity: 0.15;
        elevation: 8;
      `
      : 'box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);'}

  background-color: ${({ theme: { colors } }) => colors.white};
  height: 150px;
  width: 150px;
  position: absolute;
  z-index: 1;
`;

export const SelectFlatList = styled.FlatList`
  flex: 1;
`;

export const Option = styled.TouchableOpacity`
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  ${(option) => option.selected && `background: ${option.theme?.colors.black8}`}
  padding: 16px;
`;

export const OptionText = styled.Text`
  color: ${({ theme }) => theme?.colors.black60};
  font-size: 14px;
  font-family: Inter;
`;

export const ClickInputField = styled.TouchableOpacity<{ backgroundColor?: string }>`
  height: 56px;
  margin: 23px 0 10px 0;
  border-radius: 3px;
  border: 1px solid #615d5c;
  background-color: ${({ theme: { colors }, backgroundColor }) => backgroundColor ?? colors.white};
  justify-content: center;
  padding-left: 15px;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const ClickInputPlaceholder = styled.Text<{
  filled: boolean;
  color?: string;
  placeholderBackground?: string;
}>`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme: { colors }, color }) => color ?? colors.gray70};
  ${({ theme: { isMobile }, filled }) => {
    return filled
      ? isMobile
        ? 'position: absolute; top: -30px; padding-left: 10px; font-size: 12px; left: 9px'
        : 'position: absolute; top: -10px; padding-left: 0px; font-size: 12px; left: 9px'
      : '';
  }};
  ${({ placeholderBackground }) =>
    placeholderBackground
      ? `background-color: ${placeholderBackground}; padding: 0px 3px 0px 3px`
      : ''};
  ${({ theme: { isMobile } }) => isMobile && 'padding-top: 20px'}
`;

export const RadioButtonWrapper = styled.TouchableOpacity`
  ${({ theme: { isMobile } }) => !isMobile && 'display: flex;'};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
`;

export const OptionTextNewCreditCardButton = styled.Text`
  color: ${({ theme }) => theme?.colors.black60};
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
`;
