import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { clsx } from 'clsx';
import { Sidebar } from '../Sidebar/Sidebar';
import * as S from './styles';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { useAuthStore } from '~/store/auth.store';

interface WebThemeProps {
  children: ReactNode;
}

export const DesktopAuthenticatedWrapper: React.FC<WebThemeProps> = ({ children }) => {
  const { isDeepLink } = DeepLink.useParams();
  const { authStrategy } = useAuthStore();

  return (
    <S.Container>
      <View className={'flex-1 flex-row bg-background-gray'}>
        <View
          className={clsx(
            'flex-1 grow-0 mobile:hidden',
            !isDeepLink && authStrategy !== "CertificateStrategy" && 'tablet:min-w-[320px] desktop:min-w-[455px]',
          )}>
          {!isDeepLink && authStrategy !== "CertificateStrategy" && <Sidebar />}
        </View>
        <View className={`flex-[8] bg-background-${authStrategy === "CertificateStrategy" ? 'white' : 'gray'}`}>{children}</View>
      </View>
    </S.Container>
  );
};
