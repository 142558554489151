import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { View, Platform, Linking } from 'react-native';
import desktopBackground from '../../assets/images/BenefitsPetTransport/desktop.png';
import mobileBackground from '../../assets/images/BenefitsPetTransport/mobile.png';
import {
  PageWithCard,
  Button,
  Collapse,
  MobileAutoSpace,
  Typography,
  useMedia,
} from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';
import PetTransportIcon from '~/components/Icons/PetTransportIcon';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsPetTransport = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;
  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <PetTransportIcon width={24} height={24} color={theme.colors.white} />,
        title: 'Transporte',
        onBack: () => {
          navigation.navigate('BenefitsPetAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          O benefício está à disposição para{' '}
          <Typography variant="bodyHighlight2">transportar seu pet</Typography> em casos
          emergenciais com segurança e praticidade.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2" className="mb-8">
            O transporte animal tem o objetivo de{' '}
            <Typography variant="bodyHighlight2">
              cobrir os custos ou parte deles, quando em consequência de acidente ou doença súbita
            </Typography>
            , oferecendo suporte de forma eficiente e conveniente.
          </Typography>
          <Typography variant="body2">A assistência oferece:</Typography>
          <View className="flex gap-8 p-8">
            <Typography variant="body2">• Cobertura de até R$200,00 por evento;</Typography>
            <Typography variant="body2">• Limite de 3 intervenções por ano.</Typography>
          </View>
          <Typography variant="body2">
            A cobertura e o atendimento acontecem em casos de consequência de acidente ou doença
            súbita.
          </Typography>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para utilizar o benefício, ligue e solicite a assistência:
        </Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.gray70} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 702 9897</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
