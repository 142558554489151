import React, { useCallback } from 'react';
import MapView from 'react-native-maps';
import { ListRenderItemInfo } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Loader } from '../Loader';
import * as S from './styles';
import { ListItem } from '~/components/MapViewList';
import { LocationCard } from '~/components/LocationCardList/LocationCard';

export interface MapProps<T> {
  data: ListItem<T>[];
  loading?: boolean;
  onPress?: (item: ListItem<T>) => void;
  clickEnabled?: boolean;
}

export const Map = <T,>({ loading, data = [], onPress, clickEnabled = true }: MapProps<T>) => {
  const theme = useTheme();
  const keyExtractor = useCallback((_item: unknown, idx: number) => idx.toString(), []);

  const renderMapItems = useCallback(
    (info: ListRenderItemInfo<ListItem<T>>) => (
      <LocationCard
        locationData={info.item}
        cutText
        onPressWrapper={clickEnabled ? onPress : undefined}
        style={{
          width: theme.isMobile ? 284 : 348,
        }}
        hasButton={false}
      />
    ),
    [],
  );

  const getLatLong = () => {
    const deltas = {
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0421,
    };

    if (data.length) {
      const { lat, long } = data[0].address;

      return {
        latitude: parseFloat(lat),
        longitude: parseFloat(long),
        ...deltas,
      };
    }

    return { latitude: 0, longitude: 0, ...deltas };
  };

  return (
    <>
      {loading ? (
        <Loader variant="circle-loader" />
      ) : (
        <S.WrapperMapElement testID="map-list-test">
          <MapView
            initialRegion={getLatLong()}
            style={{ height: '100%' }}
            toolbarEnabled={false}
            showsMyLocationButton={false}
            showsCompass={false}
            showsScale={false}
            zoomControlEnabled={false}>
            {data.map(({ address: { lat, long }, name }, idx) => {
              return (
                <MapView.Marker
                  key={`marker_${idx}`}
                  coordinate={{
                    latitude: parseFloat(lat),
                    longitude: parseFloat(long),
                  }}
                  title={name}
                />
              );
            })}
          </MapView>
          <S.MapBottomItemList
            horizontal
            showsHorizontalScrollIndicator={true}
            data={data}
            keyExtractor={keyExtractor}
            renderItem={renderMapItems}
            ItemSeparatorComponent={S.ItemSeparator}
          />
        </S.WrapperMapElement>
      )}
    </>
  );
};
