import React from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Icon, InputIconProps } from '~/components/@hello-ui/Input/components/InputIcon/types';
import { TouchableContainer } from '~/components/@hello-ui/Input/components/InputIcon/styles';

export const InputIcon: React.FC<InputIconProps> = ({ icon, onPress }) => {
  const theme = useTheme();

  const iconAsIcon = icon as Partial<Icon>;

  const getIconComponent = () => {
    if (typeof icon === 'string' || typeof icon?.item === 'string') {
      return (
        <FeatherIcon
          name={typeof icon === 'string' ? icon : icon?.item}
          size={iconAsIcon?.size ?? 24}
          color={iconAsIcon?.color ?? theme.colors.primary}
        />
      );
    }

    const CustomIcon = icon.item as React.ComponentType;

    return <CustomIcon />;
  };

  return (
    <TouchableContainer
      onPress={onPress}
      activeOpacity={onPress ? 1 : 0.2}
      iconPosition={iconAsIcon?.position ?? 'left'}>
      {getIconComponent()}
    </TouchableContainer>
  );
};
