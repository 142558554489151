import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Linking, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsFinancialClub/desktop.png';
import mobileBackground from '../../assets/images/benefitsFinancialClub/mobile.png';
import { Button, MobileAutoSpace, PageWithCard, Typography } from '~/components/@hello-ui';
import StarIcon from '~/components/Icons/Star';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsFinancialClub = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <StarIcon color={theme.colors.white} />,
        title: 'Clube de Benefícios',
        onBack: () => {
          navigation.navigate('BenefitsFinancial');
        },
      }}>
      <View>
        <Typography variant="body2">
          Benefícios especiais para compras online e lazer. Aproveite descontos na compra de
          produtos e serviços das marcas que você mais gosta!
        </Typography>
        <Typography variant="body2">
          Preços exclusivos no{' '}
          <Typography variant="bodyHighlight2">Cinemark, Netshoes, Natura</Typography> e muito mais.
        </Typography>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar os descontos, é só acessar</Typography>
        <Typography variant="bodyHighlight2">o Clube com seu CPF:</Typography>
      </View>
      <Button
        variant="primary"
        onPress={() => Linking.openURL('https://tem.convenia.com.br/entrar?redirectTo=%2F')}>
        Acessar o clube
      </Button>
    </PageWithCard>
  );
};
