import React, { useState } from 'react';

import { FlatList } from 'react-native';

import { useTheme } from 'styled-components/native';

import { Map as GoogleMap, useMap } from '@vis.gl/react-google-maps';

import { Loader } from '../Loader';

import * as S from './styles';
import { Marker } from './Marker';
import { LocationCard } from '~/components/LocationCardList/LocationCard';

import { ListItem } from '~/components/MapViewList/MapViewListDTO';

export interface MapProps<T> {
  data: ListItem<T>[];
  loading?: boolean;
  onPress?: (item: ListItem<T>) => void;
  clickEnabled?: boolean;
  mapId: string;
}

export const Map = <T,>({ loading, data, onPress, clickEnabled = true, mapId }: MapProps<T>) => {
  const theme = useTheme();

  const [currentLocation, setCurrentLocation] = useState<ListItem<T> | null>(() => {
    if (theme.isMobile) return data[0];

    return null;
  });

  const map = useMap(mapId);

  const handleLocationChange = (data: ListItem<T>) => {
    map?.panTo({ lat: data.address.latitude, lng: data.address.longitude });
    setCurrentLocation(data);
  };

  return (
    <>
      {loading ? (
        <Loader variant="circle-loader" />
      ) : (
        <>
          <GoogleMap
            mapId={mapId}
            id={mapId}
            defaultCenter={{
              lat: data[0].address.latitude,
              lng: data[0].address.longitude,
            }}
            defaultZoom={15}
            zoomControl={false}
            mapTypeControl={false}
            streetViewControl={false}
            rotateControl={false}
            scaleControl={false}
            fullscreenControl={false}
            style={{
              flex: 1,
              height: '100%',
            }}>
            {data.map(({ address: { latitude, longitude }, name, id }) => {
              return (
                <Marker
                  key={id}
                  latitude={latitude}
                  longitude={longitude}
                  title={name}
                  focused={
                    latitude === currentLocation?.address.latitude &&
                    longitude === currentLocation.address.longitude
                  }
                />
              );
            })}
          </GoogleMap>
          <FlatList
            className="absolute bottom-0 left-0 right-0 p-16"
            horizontal
            showsHorizontalScrollIndicator={true}
            data={data}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <LocationCard
                locationData={item}
                focused={item.id === currentLocation?.id}
                cutText
                onPressWrapper={handleLocationChange}
                onPressButton={onPress}
                style={{
                  width: 312,
                  height: '100%',
                }}
                showButtonOnCardFocus={Boolean(onPress)}
                hasButton={false}
              />
            )}
            ItemSeparatorComponent={S.ItemSeparator}
          />
        </>
      )}
    </>
  );
};
