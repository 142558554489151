import { DefaultTheme } from 'styled-components/native';
import { TypographyVariantTypes } from '~/components/@hello-ui/Typography/typography-variants';

const colorByVariant: Record<keyof typeof TypographyVariantTypes, (theme: DefaultTheme) => string> =
  {
    h1: (theme) => theme.colors.title,
    h2: (theme) => theme.colors.title,
    h3: (theme) => theme.colors.title,
    title: (theme) => theme.colors.title,
    titleWelcome: (theme) => theme.colors.title,
    subtitle: (theme) => theme.colors.title,
    description: (theme) => theme.colors.paragraph,
    body1: (theme) => theme.colors.paragraph,
    bodyItalic1: (theme) => theme.colors.paragraph,
    bodyHighlight1: (theme) => theme.colors.paragraph,
    body2: (theme) => theme.colors.paragraph,
    bodyItalic2: (theme) => theme.colors.paragraph,
    bodyHighlight2: (theme) => theme.colors.paragraph,
    bodyHighlight2Bold: (theme) => theme.colors.paragraph,
    helperText: (theme) => theme.colors.paragraph,
    button: (theme) => theme.colors.white,
    link: (theme) => theme.colors.primary,
    titleXsmall: (theme) => theme.colors.paragraph,
    titleXsmallBold: (theme) => theme.colors.paragraph,
    nav: (theme) => theme.colors.black, // TODO this type of typography doesn't exists on Figma
  };

export function getColorByVariant(
  variant: keyof typeof TypographyVariantTypes,
  theme: DefaultTheme,
) {
  return colorByVariant[variant](theme) ?? '#000';
}
