import React, { PropsWithChildren } from 'react';
import { Platform, View } from 'react-native';
import { clsx } from 'clsx';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export interface ContentLimitProps extends PropsWithChildren {
  overrideBodyMaxWidth?: string;
}

export const ContentLimit: React.FC<ContentLimitProps> = ({ children, overrideBodyMaxWidth }) => {
  const authStrategy = useAuthStore((st) => st.authStrategy);

  const transformedOverrideBodyMaxWidth =
    Platform.OS === 'web'
      ? overrideBodyMaxWidth
      : overrideBodyMaxWidth === 'none'
        ? undefined
        : overrideBodyMaxWidth;

  return (
    <View
      className={clsx(
        'flex-1',
        [EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy)
          ? 'w-full'
          : 'max-w-[530px]',
      )}
      style={transformedOverrideBodyMaxWidth ? { maxWidth: transformedOverrideBodyMaxWidth } : {}}>
      {children}
    </View>
  );
};
