import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import {
  TWILIO_AUTHOR_NAME,
  TWILIO_ACCOUNT_SID,
  TWILIO_ATTENDANCE_FLOW_ID,
  TWILIO_AUTHOR_IMAGE,
} from '@env';
import { Panel } from '~/components/@tem-ui';
import * as S from '~/components/@tem-ui/WebChat/styles';
import { useSweetAlert } from '~/components/@tem-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { capitalize, split } from '~/utils/strings';
import { WebChat } from '~/components/@tem-ui/WebChat/WebChat';

export const AttendanceWebChat = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { user } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  return (
    <S.Container>
      <Panel
        title="Chat de atendimento"
        onBack={() => {
          showSweetAlert(
            'Você tem certeza?',
            'A conversa será encerrada e caso deseje você terá que começar de novo.',
            'warning',
            false,
            false,
            {
              color: 'warning',
              buttons: [
                {
                  text: 'Sair do chat',
                  color: theme.colors.black60,
                  background: 'secondary',
                  callback: () => {
                    hideSweetAlert();
                    navigation.navigate('Dashboard');
                  },
                },
                {
                  text: 'Continuar',
                  color: 'white',
                  background: 'primary',
                  callback: () => {
                    hideSweetAlert();
                  },
                },
              ],
            },
          );
        }}>
        <S.WrapperChat>
          <WebChat
            authorName={TWILIO_AUTHOR_NAME}
            authorImage={TWILIO_AUTHOR_IMAGE}
            accountSid={TWILIO_ACCOUNT_SID}
            flowID={TWILIO_ATTENDANCE_FLOW_ID}
            friendlyName={capitalize(split(user?.name ?? '')[0])}
          />
        </S.WrapperChat>
      </Panel>
    </S.Container>
  );
};
