import React, { useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useNavigation } from '@react-navigation/native';
import * as S from './styles';
import { Cards } from './components';
import { Card, Typography, Wrapper } from '~/components/@tem-ui';
import ActivityIndicator from '~/components/@tem-ui/ActivityIndicator';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMedia } from '~/components/@hello-ui';
export const BenefitsPresentation = (): JSX.Element => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const media = useMedia();
  const navigation = useNavigation();
  const { partner, signature, isDependent } = useAuth();

  const getBenefitsList = async () => {
    if (isDependent === null) return [];
    const features = isDependent ? partner?.dependent_features : partner?.features;
    const activeList = features.filter((item) => item.active === 1) ?? [];
    setList(activeList.map((item: string) => item.name));
    setLoading(false);
  };

  useEffect(() => {
    void getBenefitsList();
  }, [partner, isDependent]);

  return (
    <>
      {!media.isMobile && <Header />}
      <Wrapper>
        <Card height="auto" disableShadow={media.isMobile}>
          <S.WrapperHeader>
            <S.WrapperBackButton
              onPress={() => navigation.navigate(signature?.plan ? 'Signature' : 'Dashboard')}>
              <Icon name="arrow-left" size={26} color="black" />
            </S.WrapperBackButton>
          </S.WrapperHeader>
          <S.WrapperContent>
            <S.Text>
              <S.WrapperTitleContent testID="my-benefits">
                <Typography variant="title2" color="black">
                  Meus Benefícios
                </Typography>
              </S.WrapperTitleContent>
              <S.WrapperDescriptionContent>
                <Typography variant="body2" color="black60">
                  Para cuidar da saúde e bem estar, com a qualidade de um serviço particular e
                  preços que cabem no seu bolso.
                </Typography>
              </S.WrapperDescriptionContent>
            </S.Text>
          </S.WrapperContent>
          <S.WrapperContentCards>
            {loading && (
              <S.WrapperContent>
                <ActivityIndicator />
              </S.WrapperContent>
            )}
            {!loading && <Cards list={list} />}
          </S.WrapperContentCards>
        </Card>
        <Footer />
      </Wrapper>
    </>
  );
};
