export interface FileSizeType {
  text: string;
  number: number;
}

export interface FileType {
  uri?: string;
  type?: string;
  name?: string;
  file?: string;
  base64: string;
  size: FileSizeType;
}

export interface ListFileType {
  items: FileType[];
  maxFileSize: number;
  setFile: (files: FileType[]) => void;
}

export interface UploadType {
  type: number;
  target?: any;
}

export interface usePickerDocument {
  setMaxFileSize: (maxFileSize: number) => void;
  maxFileSize: number;
  setFiles: (files: FileType[]) => void;
  files: FileType[];
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  setFileWithBigSize: (haveLargeSizeFile: boolean) => void;
  haveLargeSizeFile: boolean;
  addFile: (typePicker: PickerOptionsEnum, target?: any) => Promise<any>;
  deleteFile: (items: FileType[], idx: number, options?: DeleteOptionsType) => void;
  clearUploader: () => void;
}

export type FileInfo = {
  file: string[];
  base64?: string[] | null;
};

export interface UploadFieldType {
  addOtherFileText?: string;
  mapOnSelect?: () => void;
}

export enum PickerOptionsEnum {
  Gallery,
  Archiver,
  Camera,
  Web,
}

export interface differenceObjTypes {
  oldValue: object;
  newValue: object;
}

export type DeleteOptionsType = {
  title: string;
  subtitle: string;
};
