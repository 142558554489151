import React, { forwardRef, PropsWithChildren } from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useAuthStore } from '~/store/auth.store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

interface BodyScrollProps extends PropsWithChildren {
  scrollEnabled: boolean;
  style?: StyleProp<ViewStyle>;
  topBorderRadius?: number;
  overridePadding?: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  };
}

export const BodyScroll = forwardRef<ScrollView, BodyScrollProps>(
  ({ children, scrollEnabled, overridePadding, style, topBorderRadius }, ref) => {
    const { isDesktop } = useTheme();
    const authStrategy = useAuthStore((st) => st.authStrategy);

    const calcPadding = () => {
      if (isDesktop) {
        return {
          top: 40,
          right: 91,
          bottom: 40,
          left: 91,
        };
      }

      return {
        top: 24,
        right: 16,
        bottom: 24,
        left: 16,
      };
    };

    const { top, right, bottom, left } = calcPadding();

    const borderRadiusStyle = topBorderRadius
      ? { borderTopLeftRadius: topBorderRadius, borderTopRightRadius: topBorderRadius }
      : {};

    return (
      <ScrollView
        style={style ? { ...(style as ViewStyle), ...borderRadiusStyle } : borderRadiusStyle}
        scrollEnabled={scrollEnabled}
        className="webkit-overflow-scrolling-touch -mt-1 flex-1 rounded-b-lg border-[1px] border-solid border-gray-disabled bg-white mobile:rounded-b-none mobile:border-0"
        ref={ref}
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: overridePadding?.top ?? top,
          paddingRight: overridePadding?.right ?? right,
          paddingBottom: overridePadding?.bottom ?? bottom,
          paddingLeft: overridePadding?.left ?? left,
          ...([EAuthStrategy.Certificate, EAuthStrategy.DeepLink].includes(authStrategy) && {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }),
        }}>
        {children}
      </ScrollView>
    );
  },
);
