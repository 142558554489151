import { useAuthStore } from '~/store/auth.store';
import { IGatekeeper, IGatekeeperContext } from '~/auth/interfaces/gatekeeper';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';

export class CompleteDataSSOGatekeeper implements IGatekeeper {
  async canPass(ctx: IGatekeeperContext) {
    const { user } = useAuthStore.getState();

    if (!user) {
      await ctx.strategy.onLogout();
      console.warn('User not found on CompleteDataSSOGatekeeper. Logging out...');
      return false;
    }

    const { firstAccess } = await OlimpoService.deepLink.checkDeepLinkFirstAccess(user.cpf);

    return !!user.telephone_1 && !!user.email && !firstAccess;
  }

  getGatekeeperRoutes(): Routes[] {
    return ['DeepLinkFirstAccess'];
  }
}
