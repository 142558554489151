import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Platform } from 'react-native';
import * as S from '../../styles';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { useAppointment } from '~/hooks/appointment';
import { Button, Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';

export const RequestSchedule = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { checkIsEnabledFeature, isDependent, onixCode } = useAuth();
  const [haveBenefits, setHaveBenefits] = useState<boolean>();
  const { tracking } = useTracking();
  const { finalizeSteps } = useStepper();
  const { clearAppointments } = useAppointment();

  const b2c = onixCode?.onix_code === '7305' || onixCode?.onix_code === '7306';
  const benefits = ['health_network', 'telemedicine', 'psicologia_online', 'dentistry', 'checkup'];

  const style = Platform.OS === 'android' ? { paddingTop: 38, width: '100%' } : {};

  useEffect(() => {
    const hasBenefit = benefits.some((benefit) => checkIsEnabledFeature(benefit));
    setHaveBenefits(hasBenefit);
  }, [isDependent]);

  return (
    <>
      {haveBenefits && isDependent !== null && (
        <S.WrapperContent>
          <Button
            style={style}
            variant={'primary'}
            onPress={() => {
              void tracking.addMovement({
                event: trackingEventType.ClickedOnRequestedAppointment,
              });
              finalizeSteps();
              clearAppointments();
              navigation.navigate('ScheduleAppointment');
            }}>
            <S.WrapperSchedule>
              <Typography variant="button" color={theme.colors.white}>
                <>
                  AGENDAR
                  <Icon name="chevron-right" size={18} color={theme.colors.white} />
                </>
              </Typography>
              <Typography variant="helperText" color={theme.colors.white}>
                <>consultas, exames{b2c ? '' : ', dentistas'} e mais...</>
              </Typography>
            </S.WrapperSchedule>
          </Button>
        </S.WrapperContent>
      )}
    </>
  );
};
