import React from 'react';
import { View } from 'react-native';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { CardButton, Typography } from '~/components/@hello-ui';
import IWantToChooseIcon from '~/components/Icons/IWantToChoose';
import RecommendationIcon from '~/components/Icons/Recommendation';

interface SchedulePreferenceProp {
  setFlowDate?: () => void;
}

export const SchedulePreference = ({ setFlowDate }: SchedulePreferenceProp): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const { nextStep } = useStepper();

  return (
    <>
      <Typography variant="title">Como prefere?</Typography>

      <View className="mb-32 mt-8 mobile:mt-4">
        <Typography variant="body2">
          Para prosseguir, você pode escolher entre unidades da rede de saúde ou receber indicações
          da nossa equipe
        </Typography>
      </View>

      <View className="mb-8">
        <CardButton
          variant="xlarge"
          title="Quero escolher"
          description="Escolha uma unidade da rede"
          icon={<IWantToChooseIcon />}
          onPress={() => {
            nextStep();
          }}
        />
      </View>

      <CardButton
        variant="xlarge"
        title="Quero indicações"
        description="Receba indicações de especialistas"
        icon={<RecommendationIcon />}
        onPress={() => {
          setFlowDate && setFlowDate();
          nextStep();
        }}
      />
    </>
  );
};
