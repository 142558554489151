import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from './styles';

import { useTheme } from 'styled-components/native';

interface OnCloseType {
  onClose?: () => void;
}

export const CloseButton: React.FC<OnCloseType> = ({ onClose }) => {
  const theme = useTheme();

  return (
    <S.WrapperCloseButton>
      <S.CloseButton onPress={onClose} testID="close-button">
        <Icon name="x" size={23} color={theme.colors.paragraph} />
      </S.CloseButton>
    </S.WrapperCloseButton>
  );
};
