import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsNutritionalAssistance/desktop.png';
import mobileBackground from '../../assets/images/benefitsNutritionalAssistance/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';
import Nutrition from '~/components/Icons/Nutrition';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsNutritionalAssistance = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <Nutrition color="white" />,
        title: 'Orientação Nutricional',
        onBack: () => {
          navigation.navigate('BenefitsWellBeing');
        },
      }}>
      <View>
        <Typography variant="body2">
          <Typography variant="bodyHighlight2">Disponível por telefone</Typography>, de forma rápida
          e acessível para todos para esclarecer dúvidas sobre uma{' '}
          <Typography variant="bodyHighlight2">alimentação adequada</Typography> e uma{' '}
          <Typography variant="bodyHighlight2">rotina mais saudável</Typography>.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2" className="mb-8">
            Seus hábitos alimentares são analisados por um nutricionista e as orientações
            necessárias para adequação da sua rotina alimentar será realizada por telefone. Receba
            também relatório com os detalhes das orientações e sugestões por e-mail
          </Typography>
          <Typography variant="body2" className="mb-8">
            Esse serviço pode auxiliar com:
          </Typography>
          <Typography variant="body2" className="mb-8 ml-8">
            • Cálculo do seu peso ideal e índice de massa corporal;
          </Typography>
          <Typography variant="body2" className="mb-8 ml-8">
            • Criação de planos de dieta e rotinas de exercícios;
          </Typography>
          <Typography variant="body2" className="mb-8 ml-8">
            • Informações sobre suplementos e distúrbios alimentares;
          </Typography>
          <Typography variant="body2" className="mb-8 ml-8">
            • Referências de especialistas;
          </Typography>
          <Typography variant="body2" className="mb-8 ml-8">
            • Dicas de eventos esportivos.
          </Typography>
          <View className="mb-8">
            <Typography variant="body2">
              Serviço disponível de segunda a sexta, das 9h às 18h.
            </Typography>
          </View>
          <View>
            <Typography variant="body2">
              <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem acesso a 3
              atendimentos durante a vigência de plano.
            </Typography>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício, é só ligar</Typography>
        <Typography variant="bodyHighlight2">e solicitar a Orientação Nutricional:</Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 702 9897</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
