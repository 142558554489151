import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import { DeepLinkFirstAccessStepProps } from '../types';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';
import { Button, RadioBoxGroup, Typography, useSweetAlert } from '~/components/@hello-ui';
import { capitalize } from '~/utils/strings';
import { instanceApi } from '~/App';
import { useAuth } from '~/auth/legacy/useAuth';


interface FormData {
  gender: string;
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  gender: yup
    .string()
    .oneOf(['Masculino', 'Feminino', 'Indiferente'], 'Escolha um sexo')
    .required('Escolha um sexo'),
});

export const DeepLinkFirstAccessGender = ({
  setStep,
  firstAccessData,
  setFirstAccessData,
  privacyPolicyLink,
}: DeepLinkFirstAccessStepProps) => {
  const theme = useTheme();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const [loading, setLoading] = React.useState(false);

  const defaultValues: FormData = {
    gender: '',
  };
  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });

  const handleForm = async (data: FormData) => {
    try {
      setLoading(true);
      await instanceApi.updateDeepLinkFirstAccessData(data);
      setFirstAccessData({ ...firstAccessData, gender: data.gender });
      setStep('email');
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cadastrar seu gênero. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      onGoBack={() => setStep('welcome')}
      bellowButtonComponent={
        <View className="h-full mobile:px-16">
          <Typography variant={theme.isMobile ? 'body1' : 'body2'}>
            Ao prosseguir você concorda com os{' '}
            <Typography
              variant="link"
              className="cursor-pointer"
              onPress={() => Linking.openURL(privacyPolicyLink)}>
              Termos de aceite
            </Typography>
          </Typography>
        </View>
      }>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="title">Qual o seu sexo biológico?</Typography>
      </View>
      <Typography variant={theme.isMobile ? 'body1' : 'body2'} className="mb-24 mobile:mb-32">
        Informar seu sexo biológico nos permite oferecer um atendimento médico personalizado,
        conectando você com o especialista adequado para sua necessidade.
      </Typography>
      <Controller
        name="gender"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RadioBoxGroup
            data={[
              { label: 'Feminino', value: 'Feminino' },
              { label: 'Masculino', value: 'Masculino' },
              { label: 'Prefiro não informar', value: 'Indiferente' },
            ]}
            vertical
            value={value}
            onChange={onChange}
            error={error?.message ?? ''}
          />
        )}
      />
      <View className="mb-24 mt-auto mobile:mb-68">
        <Button
          className="z-50"
          variant="outLine"
          onPress={handleSubmit(handleForm)}
          disabled={!isFormValid || loading}
          loading={loading}>
          Próximo
        </Button>
      </View>
    </PageWrapper>
  );
};
