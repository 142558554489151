import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { Card, Typography } from '~/components/@tem-ui';
import { StyledDefaultTheme } from '~/components/@hello-ui';

const Wrapper = styled.View`
  padding: 14px;
  height: 100%;
  justify-content: space-between;
`;

const WrapperAddress = styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const Clinics = ({ credenciado }: any): JSX.Element => (
  <Card width={304} height={176}>
    <Wrapper>
      <>
        <Typography variant="heading2" color="black70" bold>
          {credenciado[0]['NOME FANTASIA']}
        </Typography>
        <Typography variant="paragraph" color="black70" numberOfLines={1}>
          {credenciado[0]['RAZAO SOCIAL']}
        </Typography>
        <WrapperAddress>
          <Icon name="room" size={26} color={StyledDefaultTheme.colors.blue} />
          <Typography variant="subtitle1" color="black70" style={{ marginLeft: 10 }}>
            {`${credenciado[0]['LOGRADOURO']}, ${credenciado[0]['NUMERO']} - ${credenciado[0]['BAIRRO']}, ${credenciado[0]['MUNICIPIO']} - ${credenciado[0]['ESTADO']},  ${credenciado[0]['CEP']}`}
          </Typography>
        </WrapperAddress>
      </>
    </Wrapper>
  </Card>
);
