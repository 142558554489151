import { useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useWrapperHeight } from '~/screens/Login/context/WrapperHeightContext';

export function useCalculateMargins(bellowButtonComponent: boolean, bellowButtonHeight: number) {
  const theme = useTheme();
  const [contentHeight, setContentHeight] = useState(theme.isMobile ? 402 : 464);
  const wrapperHeight = useWrapperHeight();

  const calculateFreeSpace = () => {
    return wrapperHeight - contentHeight;
  };

  const calculateTopHeight = () => {
    if (theme.isMobile) {
      const TOP_SPACING = 16;
      const BUTTON_HEIGHT = 40;
      const BOTTOM_SPACING = 48;

      return TOP_SPACING + BUTTON_HEIGHT + BOTTOM_SPACING;
    }

    const TOP_SPACING = 70;
    const BUTTON_HEIGHT = 40;
    const BOTTOM_SPACING = 24;

    return TOP_SPACING + BUTTON_HEIGHT + BOTTOM_SPACING;
  };

  const calculateBottomHeight = () => {
    if (theme.isMobile) {
      const BOTTOM_SPACING = 16;

      return BOTTOM_SPACING + (bellowButtonComponent ? bellowButtonHeight : 0);
    }

    const BOTTOM_SPACING = 70;

    return BOTTOM_SPACING + (bellowButtonComponent ? bellowButtonHeight : 0);
  };

  const calculateIntrinsicHeight = () => {
    return { top: calculateTopHeight(), bottom: calculateBottomHeight() };
  };

  const consumeFreeSpaceIfLessThanIntrinsic = (
    intrinsicHeight: { top: number; bottom: number },
    freeSpace: number,
  ) => {
    let finalFreeSpace = freeSpace;
    const { top, bottom } = intrinsicHeight;
    const marginFreeSpace = freeSpace / 2;

    [top, bottom].forEach((value) => {
      const bellowMinimumMargin = value - marginFreeSpace;

      if (bellowMinimumMargin > 0) {
        finalFreeSpace -= bellowMinimumMargin * 2;
      }
    });

    return finalFreeSpace;
  };

  const calculateFinalHeights = () => {
    const freeSpace = calculateFreeSpace();
    const intrinsicHeight = calculateIntrinsicHeight();
    const finalFreeSpace = consumeFreeSpaceIfLessThanIntrinsic(intrinsicHeight, freeSpace);
    const marginFreeSpace = finalFreeSpace / 2;

    return {
      topMargin: Math.max(intrinsicHeight.top, marginFreeSpace),
      bottomMargin: Math.max(intrinsicHeight.bottom, marginFreeSpace),
    };
  };

  const setContentSize = useCallback(
    (ev: LayoutChangeEvent) => {
      const isDifferenceRelevant = Math.abs(contentHeight - ev.nativeEvent.layout.height) > 10;
      if (isDifferenceRelevant) {
        setContentHeight(ev.nativeEvent.layout.height);
      }
    },
    [contentHeight],
  );

  const { topMargin, bottomMargin } = useMemo(() => {
    return calculateFinalHeights();
  }, [bellowButtonComponent, bellowButtonHeight, contentHeight, wrapperHeight, theme.isMobile]);

  return { topMargin, bottomMargin, setContentSize };
}
