import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import DesktopImage from '~/assets/images/dependents/desktop.png';
import MobileImage from '~/assets/images/dependents/mobile.png';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';
import Family from '~/components/Icons/Family';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { UseRoute } from '~/@types/navigation/routes-helpers';

export const DependentsPresentation = (): JSX.Element => {
  const navigation = useNavigation();
  const { params } = useRoute<UseRoute<'DependentsPresentation'>>();
  const theme = useTheme();
  const { isDeepLink } = DeepLink.useParams();

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        title: 'Cadastro de Dependentes',
        backgroundImage: theme.isMobile ? MobileImage : DesktopImage,
        titleStartContent: <Family color={theme.colors.white} width={24} height={24} />,
        onBack: () => {
          if (isDeepLink) {
            DeepLink.sendEvent(DeepLinkEvent.Cancel);
          } else {
            navigation.canGoBack()
              ? params?.fromDependents
                ? navigation.navigate('Dashboard')
                : navigation.goBack()
              : navigation.navigate('Dashboard');
          }
        },
      }}>
      <Typography variant="body2" className="mb-32 mobile:mb-24">
        O <Typography variant="bodyHighlight2">Programa Familiar</Typography> permite o cadastro de
        dependentes e dá{' '}
        <Typography variant="bodyHighlight2">acesso a serviços e benefícios</Typography> também a
        seus familiares.
      </Typography>
      <Collapse title="Saiba mais">
        <Typography variant="body2" className="mb-16">
          Seus dependentes têm acesso a{' '}
          <Typography variant="bodyHighlight2">consultas médicas</Typography> e{' '}
          <Typography variant="bodyHighlight2">odontológicas</Typography>,{' '}
          <Typography variant="bodyHighlight2">exames</Typography> e{' '}
          <Typography variant="bodyHighlight2">procedimentos com desconto</Typography> na Rede de
          Saúde. Para saber quais outros benefícios o dependente possui, consulte o Regulamento do
          seu produto.
        </Typography>
        <View className="mb-24 mobile:mb-16">
          {!isDeepLink && (
            <TouchableOpacity
              className="flex-row items-center"
              onPress={() => navigation.navigate('RegulationAndPrivacy')}>
              <Typography variant="link" className="mr-8">
                Regulamento
              </Typography>
              <FeatherIcon
                name="chevron-right"
                size={24}
                color={theme?.colors?.primary}
                testID="chevron-icon"
              />
            </TouchableOpacity>
          )}
        </View>
        <Typography variant="bodyHighlight2" className="mb-16 mobile:mb-8">
          Agendar atendimentos para dependentes:
        </Typography>
        <Typography variant="body2">
          Ao finalizar a solicitação de agendamento, é só indicar para quem é o atendimento.
        </Typography>
      </Collapse>
      <MobileAutoSpace heightMobile={24} heightDesktop={80} />
      <Button variant="primary" onPress={() => navigation.navigate('Dependents')}>
        Visualizar Dependentes
      </Button>
    </PageWithCard>
  );
};
