import styled, { css } from 'styled-components/native';
import { SpacingTypes } from './types';

export const Wrapper = styled.View<SpacingTypes>`
  ${({ mTop }) =>
    mTop &&
    css`
      margin-top: ${({ theme }) => theme.tokens.space[mTop]};
    `}

  ${({ mLeft }) =>
    mLeft &&
    css`
      margin-left: ${({ theme }) => theme.tokens.space[mLeft]};
    `}

  ${({ mRight }) =>
    mRight &&
    css`
      margin-right: ${({ theme }) => theme.tokens.space[mRight]};
    `}

  ${({ mBottom }) =>
    mBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.tokens.space[mBottom]};
    `}
`;
