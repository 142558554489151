import React from 'react';
import { Circle, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const LocationIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 80 80" fill="none">
      <G filter="url(#filter0_d_10902_902)">
        <Circle cx="39" cy="39" r="32" fill="#EC6338" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M39 24C32.3998 24 27 29.4499 27 36.1C27 38.4999 27.5999 40.9499 28.8499 43.4001C29.8 45.3002 31.1501 47.2 32.85 49C35.7001 52.05 38.55 53.85 38.6501 53.8999C38.7501 53.9498 38.8499 54 38.95 54C39.0501 54 39.1498 53.9501 39.2499 53.8999C39.35 53.85 42.1998 52.05 45.05 49C46.7499 47.2 48.1 45.2999 49.0501 43.4001C50.3998 40.9999 50.9997 38.5501 50.9997 36.1C50.9997 29.45 45.6002 24 39 24ZM41.1667 39.5C38.9666 41.8999 39.0166 39.5456 38.1667 40.0956C37.3666 39.5456 38.8667 41.85 36.6667 39.5C34.1168 36.8002 35.6667 39 34.3722 37.1789C35.6667 31.5 34.1998 34 39 31.1C43.7723 33.5 41.561 32.619 43.1667 34.5C43.1667 39.5499 43.6666 36.8 41.1667 39.5ZM39 31.1C36.35 31.1 34.1998 33.2498 34.1998 35.9001C34.1998 38.5501 36.3496 40.7003 39 40.7003C41.65 40.7003 43.8001 38.5505 43.8001 35.9001C43.8001 33.2501 41.65 31.1 39 31.1ZM39 39.5001C37.0001 39.5001 35.4 37.9 35.4 35.9001C35.4 33.9003 37.0001 32.3002 39 32.3002C40.9998 32.3002 42.5999 33.9003 42.5999 35.9001C42.5999 37.9 40.9998 39.5001 39 39.5001Z"
        fill="white"
      />
    </Svg>
  );
};
