import styled, { css } from 'styled-components/native';
import { StepTypes } from './types';

export const WrapperHeader = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
  height: ${({ theme: { isMobile } }) => (isMobile ? '290px' : '300px')};
  padding: 50px 0px 50px 0px;
`;

export const WrapperHeaderContainer = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  position: relative;
`;

export const ContainerCard = styled.View`
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: ${({ theme: { isMobile } }) => (isMobile ? '135px' : '161px')};
  left: ${({ theme: { isMobile } }) => (isMobile ? '0' : '34.1vm')};
  margin: auto;
  z-index: 9999;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding: 16px;
  `}

  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    display:flex;
  `}
  justify-content:center;
  align-items: center;
  flex-direction: column;
`;

export const WrapperCard = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  min-height: 148px;
  border-radius: 10px;
  ${({ theme: { tokens } }) => css`
    ${tokens.shadows.cards}
  `};
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
`;

export const WrapperCardContent = styled.View`
  flex: 1;
  width: 100%;
  min-height: 56px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding-horizontal: 16px;
  justify-content: ${({ theme: { isMobile } }) => (isMobile ? 'flex-start' : 'center')};
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    css`
      display: flex;
    `}
`;

export const WrapperActionIcon = styled.View`
  width: 100%;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    css`
      display: flex;
    `}
  flex:1;
  min-height: 47px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const WrapperIconSize = styled.View`
  max-height: 30px;
`;

export const WrapperCardHeader = styled.View`
  ${({ theme: { isMobile } }) => css`
    justify-content: center;
    width: ${isMobile ? '80%' : '40%'};
  `};
`;

export const WrapperCardIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 56px;
  height: 56px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

export const WrapperCardActions = styled.View<{ hasBorder: boolean }>`
  ${({ theme: { colors }, hasBorder }) => css`
    flex-direction: row;
    border-top-color: ${colors.black16};
    ${hasBorder &&
    css`
      border-top-width: 0.5px;
    `}
  `};
`;

export const Action = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-vertical: 12px;
  border-color: ${({ theme: { colors } }) => colors.black16};
`;

export const ActionLeft = styled(Action)`
  border-right-width: 0.25px;
`;

export const ActionRight = styled(Action)``;

export const WrapperCards = styled.View`
  flex-direction: row;
  margin-horizontal: -12px;
  margin-top: 24px;
  margin-bottom: 30px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? css`
          margin-bottom: 0px;
          flex-direction: row;
          padding-horizontal: 16px;
        `
      : `
    margin-horizontal: -12px;
  `}
`;

export const CardItem = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  min-height: 100px;
  max-width: 166px;
  min-width: 166px;
  border-radius: 10px;
  margin-right: 16px;
`;

export const CardItemIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: auto;
`;

export const WrapperContent = styled.View`
  width: 100%;
  max-width: 600px;
  margin-bottom: ${({ theme: { isMobile } }) => (isMobile ? '0px' : '15px')};
  margin-horizontal: auto;
  margin-top: ${({ theme: { isMobile } }) => (isMobile ? '75px' : '95px')};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  padding: 16px;
`}
`;

export const WrapperSteps = styled.View`
  width: 100%;
  max-width: 316px;
  height: 52px;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px;
  position: relative;
`;

export const WrapperStep = styled.View<StepTypes>`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.primary50 : colors.white};
  width: 52px;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
`;

export const StepsBar = styled.View<{ right?: boolean; unloaded?: string }>`
  width: ${({ unloaded }) => unloaded ?? '100%'};
  height: 4px;
  position: absolute;
  top: 50%;
  z-index: -1;

  ${({ theme: { colors }, right }) =>
    right
      ? `
      background-color: ${colors.primary50};
      right: 0px;
    `
      : `
      background-color: ${colors.white};
      left: 0px;
  `}
`;

export const WrapperColumn = styled.View`
  flex: 1;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    flex: auto;
    margin-vertical: 16px;
  `}
`;

export const WrapperFooter = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 100%;
  min-height: 200px;
  flex-direction: column;
  min-height: auto;
  margin-top: -45px;
  padding: 30px 32px;
`;

export const WrapperIcon = styled.View`
  width: 100%;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    display: flex;
  `}
  justify-content:center;
  align-items: center;
`;

export const WrapperCenterHeader = styled.View`
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    display:flex;
  `}
  justify-content:center;
  align-items: center;
  flex-direction: column;
`;
