import React from 'react';
import CurrencyInput from 'react-native-currency-input';
import { Platform, View } from 'react-native';
import { clsx } from 'clsx';
import { Button, Typography } from '~/components/@hello-ui';
import { useRecharge } from '~/components/@tem-ui/Recharge';

export const PaymentAmount = () => {
  const { setRechargeStep, rechargeAmount, setRechargeAmount } = useRecharge();

  const onConfirmValue = () => {
    setRechargeStep(1);
  };

  return (
    <View className="w-full items-center">
      <Typography variant="title" className="mb-40 mobile:mb-24">
        Quanto você quer carregar?
      </Typography>
      <CurrencyInput
        value={rechargeAmount}
        prefix="R$ "
        placeholder="R$ 0,00"
        className={clsx(
          'mb-24 w-full text-center font-sans text-[40px] leading-[52px] text-gray-placeholder mobile:text-[32px] mobile:leading-[40px]',
          { 'text-success': rechargeAmount >= 20 },
        )}
        style={{
          fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-ExtraBold',
          fontWeight: Platform.OS === 'web' ? '800' : undefined,
        }}
        onChangeValue={(value) => {
          setRechargeAmount(value ?? 0);
        }}
      />
      <Typography variant="helperText" className="mb-[120px] mobile:mb-80">
        Recarga mínima de R$ 20,00
      </Typography>
      <Button variant="primary" disabled={rechargeAmount < 20} onPress={onConfirmValue}>
        Próximo
      </Button>
    </View>
  );
};
