import React from 'react';

import { useTheme } from 'styled-components/native';
import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsKeychain/desktop.png';
import mobileBackground from '../../assets/images/benefitsKeychain/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';
import { KeyIcon } from '~/components/Icons/Key';
import { goBack } from '~/helpers';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsBusinessAssistanceKeychain = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <KeyIcon color={theme.colors.white} />,
        title: 'Chaveiro',
        onBack: () => {
          goBack(navigation, 'BenefitsBusinessAssistant');
        },
      }}>
      <View>
        <Typography variant="body2">
          Serviço de chaveiro para <Typography variant="bodyHighlight2">emergências </Typography>
          com <Typography variant="bodyHighlight2">atendimento 24 horas.</Typography> Oferece mão de
          obra e materiais básicos necessários para a execução do serviço.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Confira em que situações você pode acionar o serviço de chaveiro:{' '}
          </Typography>
          <View className="mb-8">
            <Typography key={`key-${Math.random()}`} variant="body2">
              • <Typography variant="bodyHighlight2">Arrombamento, roubo</Typography> ou{' '}
              <Typography variant="bodyHighlight2">furto</Typography>, em que o local fique
              vulnerável;
            </Typography>
            <Typography key={`key-${Math.random()}`} variant="body2">
              • <Typography variant="bodyHighlight2">Conserto de portas </Typography>ou{' '}
              <Typography variant="bodyHighlight2">fechaduras;</Typography>
            </Typography>
            <Typography variant="body2">
              • Em casos emergenciais de{' '}
              <Typography variant="bodyHighlight2">
                perda, quebra de chaves na fechadura, roubo
              </Typography>{' '}
              ou <Typography variant="bodyHighlight2">furto de chaves</Typography>, que impeçam o
              acesso do usuário ao local.
            </Typography>
          </View>
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
            atendimentos durante a vigência de plano, com limite de R$200 por uso.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o serviço de chaveiro ligue:</Typography>
      </View>

      {theme.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors['black-title']} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 702 9897</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
