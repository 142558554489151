import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { Typography } from '~/components/@hello-ui';

export const AccountText = styled(Typography).attrs({
  variant: 'body1',
})(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.isMobile ? 20 : 24};
    line-height: 34;
    font-weight: 500;
  `,
);

export const AccountHealth = styled(Typography).attrs({
  variant: 'bodyHighlight1',
})(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.isMobile ? 20 : 24};
    line-height: 34;
    font-weight: 700;
    font-family: ${Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold'};
  `,
);
