import React from 'react';
import {
  createNavigationContainerRef,
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { Loading } from '~/components/Loading';
import { AuthService } from '~/auth/auth-service';
import { useGlobalStore } from '~/store/global.store';
import { routes } from '~/router/routes';
import { IRouteConfig } from '~/router/interfaces/route-config';
import { useAuthStore } from '~/store/auth.store';

export const Stack = createStackNavigator<
  ReactNavigation.RootParamList & { [key: string]: object }
>();

export const navigationRef = createNavigationContainerRef<ReactNavigation.RootParamList>();

const Navigator = (): JSX.Element => {
  const selectedProduct = useAuthStore((st) => st.selectedProduct);
  const storesLoading = useGlobalStore((st) => st.storesLoading);

  // Watch for invalidGatekeepers and autStrategy changes
  useAuthStore((st) => st.authenticationIndex);
  useAuthStore((st) => st.authStrategy);

  const getLinkingFor = (
    ...routeNames: Routes[][]
  ): LinkingOptions<ReactNavigation.RootParamList> => {
    const flatRouteNames = routeNames.flat();

    const linking = flatRouteNames.reduce((acc, routeName) => {
      const route = routes[routeName];

      if (!route.path) {
        return acc;
      }

      let path = route.path;
      if (route.pathTemplate) {
        path = route.pathTemplate(route.path);
      }

      return {
        ...acc,
        [routeName]: path,
      };
    }, {});

    return {
      prefixes: [],
      config: {
        screens: {
          ...linking,
          NotFound: '*',
        },
      },
    };
  };

  function getPartnerName() {
    return selectedProduct?.themeConfig?.partnerFullName;
  }

  function renderTitle(routeConfig: IRouteConfig) {
    const title = typeof routeConfig.title === 'function' ? routeConfig.title() : routeConfig.title;

    return `${routeConfig.includePartnerOnTitle && getPartnerName() ? `${getPartnerName()} -` : ''} ${title}`;
  }

  const renderRoutes = (routesToRender: Routes[], isCommon?: boolean) => {
    return routesToRender.map((route) => {
      const routeConfig = routes[route];

      const Page = routeConfig.component;
      const Wrapper = routeConfig.wrapperComponent;

      return (
        <Stack.Screen
          key={route}
          navigationKey={isCommon ? String(useAuthStore.getState().authenticationIndex) : undefined}
          name={route}
          initialParams={AuthService.getInitialParams()}
          options={{
            title: renderTitle(routeConfig),
            ...(routeConfig.screenOptions as Record<string, unknown>),
          }}>
          {(props) => {
            if (Wrapper) {
              return (
                <Wrapper>
                  <Page {...props} />
                </Wrapper>
              );
            }

            return <Page {...props} />;
          }}
        </Stack.Screen>
      );
    });
  };

  const forFade = ({ current }: any) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });

  const isLoading = () => {
    return storesLoading.length > 0;
  };

  const getInitialRouteName = (routes: Routes[]) => {
    return routes[0];
  };

  const authorizedRoutes = AuthService.getAuthorizedRoutes();
  const commonRoutes = AuthService.getCommonRoutes();

  return isLoading() ? (
    <Loading />
  ) : (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: StyledDefaultTheme.colors.white,
        },
      }}
      linking={getLinkingFor(authorizedRoutes, commonRoutes)}
      ref={navigationRef}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          cardStyleInterpolator: forFade,
          animationEnabled: true,
          title: `${selectedProduct?.themeConfig?.partnerFullName}`,
        }}
        initialRouteName={getInitialRouteName(authorizedRoutes)}>
        {renderRoutes(authorizedRoutes)}
        {renderRoutes(commonRoutes, true)}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigator;
