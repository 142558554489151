import React from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import { Typography } from '~/components/@hello-ui';
import { currency } from '~/utils/strings';
import { useRecharge } from '~/components/@tem-ui/Recharge';

interface RechargeValueProps {
  className?: string;
  style?: StyleProp<ViewStyle>;
}

export const RechargeValue: React.FC<RechargeValueProps> = ({ style }) => {
  const { rechargeAmount } = useRecharge();

  return (
    <View
      className="flex-row items-center justify-evenly rounded-lg bg-background-gray p-20 mobile:justify-between mobile:p-16"
      style={style}>
      <Typography variant="body2">Valor da Recarga</Typography>
      <View className="flex-row items-end">
        <Typography
          variant="helperText"
          className="mb-4 mr-4"
          color="paragraph"
          style={{
            fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
            fontWeight: Platform.OS === 'web' ? 'bold' : undefined,
          }}>
          R$
        </Typography>
        <Typography variant="h3" color="paragraph">
          {currency(rechargeAmount)}
        </Typography>
      </View>
    </View>
  );
};
