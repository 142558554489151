import { Modal } from 'react-native';
import styled, { css } from 'styled-components/native';

export const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Wrapper = styled.View`
  ${({ theme: { isMobile, tokens, colors } }) => css`
    margin: 0 auto;
    background-color: ${colors?.white};
    margin-top: ${tokens.space.s52};
    border-radius: ${tokens.borderRadius.xl};
    height: ${tokens.sizes.full};
    max-height: ${tokens.sizes.screenXs};
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenXs};
    ${tokens.shadows.modal};
  `}
`;

export const IconContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const BodyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyDataConatiner = styled.View`
  ${({ theme: { tokens, platform, isMobile } }) => css`
    height: ${tokens.sizes.full};
    ${platform?.OS === 'web' ? css`min-height: ${tokens.sizes.screenXs};` : css`min-height: ${tokens.sizes.full}`}
    padding: ${tokens.space.s16};
    ${!isMobile
      ? css`margin: ${tokens.space.s8} ${tokens.space.s24} ${tokens.space.s8} ${tokens.space.s24};`
      : css`margin: ${tokens.space.s8} ${tokens.space.s16} ${tokens.space.s8} ${tokens.space.s16};`}
  `}
`

export const FooterContainer = styled.View`
  ${({ theme: { tokens } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: ${tokens.space.s0};
    width: ${tokens.sizes.full};
    max-width: ${tokens.sizes.screenSm};
    padding: ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s16};
    margin: ${tokens.space.s16};
  `}
`;

export const Title = styled.View`
  ${({ theme: { tokens } }) => css`
    padding: ${tokens.space.s24} ${tokens.space.s0} ${tokens.space.s12} ${tokens.space.s0}  ;
  `}
`;

export const WrapperBackground = styled.View`
  ${({ theme: { tokens, colors, platform } }) => css`
    background-color: ${colors.black};
    flex: 1;
    width: ${tokens.sizes.full};
    height: ${tokens.sizes.full};
    position: ${platform?.OS !== 'web' ? 'absolute' : 'fixed'};
  `}
`;