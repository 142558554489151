import { useContext } from 'react';
import { TemPayContext, TemPayInstance } from '../contexts/temPay';

export const useTemPay = (): TemPayInstance => {
  const context = useContext(TemPayContext);

  if (!context) {
    throw new Error('useTemPay must be used within an TemPayProvider');
  }

  return context;
};
