import * as yup from 'yup';
import { useTheme } from 'styled-components/native';
import React, { useCallback, useRef } from 'react';
import { TextInput, View } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { Button, Input, MobileAutoSpace, Typography, useSweetAlert } from '~/components/@hello-ui';
import { useApi } from '~/hooks/api';
import { useTemPay } from '~/hooks/temPay';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { instanceApi } from '~/App';
import { useLogin } from '~/screens/Login/state/useLogin';
import { useAuthStore } from '~/store/auth.store';

const schema = yup.object().shape({
  password: yup
    .string()
    .length(6, 'Sua senha precisa ter 6 números')
    .test('password', 'Sua senha não pode começar com 0', (value) => value?.charAt(0) !== '0')
    .test('password', 'Sua senha deve conter apenas números', (value) => !!value?.match(/^[0-9]+$/))
    .required('Campo obrigatório'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Por favor, verificar se as senhas estão iguais')
    .required('Campo obrigatório'),
});

export const Password = () => {
  const api = useApi();
  const theme = useTheme();
  const navigation = useNavigation();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { card } = useTemPay();
  const { isDeepLink } = DeepLink.useParams();
  const { user } = useLogin();
  const {
    user: { cpf },
    selectedProduct: { onixCode },
  } = useAuthStore();

  const confirmPasswordInput = useRef<TextInput>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const nextInput = useCallback(
    () => confirmPasswordInput.current?.focus(),
    [confirmPasswordInput],
  );

  const onNext = async (values) => {
    try {
      await api.updateTemPayPassword(card.numero_cartao, values.password).then(() => {
        if (isDeepLink) DeepLink.sendEvent(DeepLinkEvent.Success);
        showSweetAlert('Tudo certo!', 'Sua senha foi atualizada', 'success', false, false, {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => {
                navigation.canGoBack()
                  ? navigation.goBack()
                  : navigation.navigate('TemPayDashboard');
                hideSweetAlert();
              },
            },
          ],
        });

        reset();
      });
      await instanceApi.sendSmsConfirmation({
        cpf: user?.document_number?.replace(/\D/g, '') || cpf,
        onix_code: onixCode,
        message: 'A senha da sua conta saúde foi alterada com sucesso.',
      });
    } catch (err) {
      console.error(err);
      showSweetAlert('Ops, algo deu errado', 'Erro ao atualizar', 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    }
  };

  return (
    <>
      <View className="mb-8">
        <Typography variant="title">Crie uma nova senha</Typography>
      </View>
      <View className="mb-8">
        <Typography variant={theme.isMobile ? 'body1' : 'body2'}>
          Crie uma nova senha para a Conta Saúde
        </Typography>
      </View>
      <View className="mb-24">
        <Typography variant="helperText">
          Sua senha deve ter 6 números e não pode começar por zero
        </Typography>
      </View>
      <View className="mb-8">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              isPassword
              value={value}
              onChangeText={(text) => {
                onChange(text.replace(/[^0-9]/g, ''));
              }}
              label="Nova senha"
              placeholder="Digite"
              error={error?.message ?? ''}
              onSubmitEditing={nextInput}
              keyboardType="numeric"
              maxLength={6}
            />
          )}
        />
      </View>

      <Controller
        name="confirm_password"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            ref={confirmPasswordInput}
            isPassword
            value={value}
            onChangeText={(text) => {
              onChange(text.replace(/[^0-9]/g, ''));
            }}
            label="Confirmar senha"
            placeholder="Digite"
            error={error?.message ?? ''}
            onSubmitEditing={handleSubmit(onNext)}
            keyboardType="numeric"
            maxLength={6}
          />
        )}
      />
      <MobileAutoSpace heightDesktop={80} heightMobile={40} />
      <Button
        disabled={!isValid || isSubmitting}
        variant="primary"
        onPress={handleSubmit(onNext)}
        loading={isSubmitting}>
        Próximo
      </Button>
    </>
  );
};
