import { createGlobalStyle } from 'styled-components';
import Feather from 'react-native-vector-icons/Fonts/Feather.ttf';
import Entypo from 'react-native-vector-icons/Fonts/Entypo.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import SimpleLineIcons from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';

export const GlobalStyle = createGlobalStyle`
   @font-face {
    font-family: 'Feather';
    src: url(${Feather}) format('truetype');
  }
  @font-face {
    font-family: 'Entypo';
    src: url(${Entypo}) format('truetype');
  }
  @font-face {
    font-family: 'MaterialCommunityIcons';
    src: url(${MaterialCommunityIcons}) format('truetype');
  }
  @font-face {
    font-family: 'MaterialIcons';
    src: url(${MaterialIcons}) format('truetype');
  }
  @font-face {
    font-family: 'FontAwesome';
    src: url(${FontAwesome}) format('truetype');
  }
  @font-face {
    font-family: 'Ionicons';
    src: url(${Ionicons}) format('truetype');
  }
  @font-face {
    font-family: 'SimpleLineIcons';
    src: url(${SimpleLineIcons}) format('truetype');
  }
  
  a,
  button,
  input {
    box-shadow: none;
    outline: none;
  }
  
  html, body {
    height: 100dvh;
    width: 100vw;
    overflow: hidden;
      
    --rsbs-backdrop-bg: rgba(75, 63, 64, 0.25); // edits the react-spring-bottom-sheet backdrop background color
  }
  
  #root {
    height: 100dvh;
    display: flex;
    flex: 1 1 100%;
      
    [data-rsbs-overlay] {
      max-height: 90%;
      border-radius: 10px 10px 0 0;
    }
  }

  .webkit-overflow-scrolling-touch {
      -webkit-overflow-scrolling: touch;
  }
  
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear{
    display: none;
  }
`;
