import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Linking, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsResidencialHomeAppliance/desktop.png';
import mobileBackground from '../../assets/images/benefitsResidencialHomeAppliance/mobile.png';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
  useMedia,
} from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';
import { HomeApplianceIcon } from '~/components/Icons/HomeAppliance';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsResidentialAssistanceHomeAppliance = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <HomeApplianceIcon color={theme.colors.white} />,
        title: 'Conserto de Eletrodomésticos',
        onBack: () => {
          navigation.navigate('BenefitsResidentialAssistant');
        },
      }}>
      <View>
        <Typography variant="body2">
          Serviço disponível para{' '}
          <Typography variant="bodyHighlight2">
            consertos de eletrodomésticos de uso residencial{' '}
          </Typography>
          ou <Typography variant="bodyHighlight2">corrigir defeitos técnicos. </Typography>
          Válido mesmo em casos em que a garantia do fabricante já tenha expirado. Oferece mão de
          obra e materiais básicos para a execução do serviço.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">
              Atende consertos de eletrodomésticos pertencentes à residência cadastrada em casos de
              defeitos técnicos de origem elétrica, eletrônica ou mecânica, que impeçam o
              funcionamento ou uso normal do aparelho.
            </Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2">Os eletrodomésticos elegíveis incluem:</Typography>
          </View>
          <View className="mb-8">
            <Typography variant="body2">
              • <Typography variant="bodyHighlight2">Linha Branca:</Typography> Fogão, Cooktop,
              Forno Elétrico, Refrigerador (Geladeira), Freezer, Lavadora de Louças, Micro-ondas
              Lavadora de Roupas, Tanquinho, Secadora de Roupas e Lava e Seca;
            </Typography>
            <Typography variant="body2">
              • <Typography variant="bodyHighlight2">Linha Marrom:</Typography> Televisão
              (Convencional, LED, LCD e Plasma), Blu-ray Player, DVD Player, Aparelho de Som e Home
              Theater.
            </Typography>
          </View>
          <View>
            <Typography variant="body2">
              <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
              atendimentos durante a vigência de plano, com limite de R$200 por uso.
            </Typography>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para solicitar um conserto de eletrodoméstico ligue:
        </Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 702 9897</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
