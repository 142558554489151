import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const CircleGroup = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 14} height={height ?? 14} viewBox="0 0 14 14" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M5.87157 2.20497C5.68583 2.30426 5.2057 2.58417 4.99624 2.92422C4.94765 3.00307 4.9723 3.10622 5.05103 3.1547C5.07848 3.17164 5.10886 3.17946 5.13864 3.17946C5.19495 3.17946 5.24986 3.15119 5.28151 3.09979C5.43162 2.85576 5.81538 2.61536 6.01969 2.50531C6.28919 2.63474 7.16835 2.99501 7.97672 2.63497C8.58266 2.36536 8.99792 1.75965 9.21123 0.835039C9.22829 0.760859 9.19313 0.68446 9.12561 0.649184C9.07853 0.624535 7.96573 0.0528442 7.00877 0.510056C6.44033 0.781422 6.05799 1.3515 5.87156 2.20496L5.87157 2.20497ZM7.84226 2.32833C7.3313 2.5567 6.76125 2.42832 6.41662 2.30602L8.78347 1.12524C8.58173 1.73492 8.26608 2.13876 7.84226 2.32833V2.32833ZM7.83 0.669636C8.14448 0.669636 8.44072 0.744515 8.65239 0.816356L6.26781 2.00602C6.44479 1.41012 6.74127 1.00957 7.15154 0.813067C7.37116 0.707699 7.60526 0.669615 7.83003 0.669615L7.83 0.669636Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_2"
          d="M4.20549 3.60037C4.3723 2.95846 4.15771 2.25628 3.56791 1.51284C3.52048 1.45338 3.43882 1.43282 3.36909 1.46378C3.32072 1.48527 2.17988 1.99902 1.94051 3.03216C1.79835 3.6459 2.0057 4.3002 2.55662 4.97809C2.51842 5.18509 2.43793 5.73505 2.57333 6.11063C2.59797 6.17897 2.66223 6.22137 2.73091 6.22137C2.74984 6.22137 2.76888 6.21822 2.78768 6.21132C2.87471 6.18002 2.9198 6.08399 2.8885 5.99708C2.79142 5.72782 2.84259 5.27783 2.88394 5.04935C3.15332 4.91945 3.98308 4.45639 4.20548 3.60036L4.20549 3.60037ZM3.52807 2.02838C3.8791 2.56598 3.99803 3.06465 3.88179 3.51431C3.74185 4.05586 3.28578 4.42199 2.97564 4.61509L3.52807 2.02838ZM2.64832 4.54403C2.29272 4.03412 2.16446 3.55282 2.26667 3.10951C2.39762 2.54095 2.8823 2.14519 3.20495 1.93818L2.64832 4.54403Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_3"
          d="M2.65852 9.29883C2.79672 9.45771 3.17615 9.86353 3.55451 9.99216C3.57238 9.99823 3.59049 10.001 3.60848 10.001C3.67834 10.001 3.7434 9.95711 3.76712 9.88737C3.79691 9.79999 3.75006 9.70467 3.66245 9.67488C3.3912 9.58283 3.07122 9.26228 2.91856 9.08751C2.98491 8.79582 3.14016 7.85847 2.60958 7.15067C2.21169 6.61997 1.52889 6.3499 0.58 6.34766H0.57965C0.503601 6.34766 0.437134 6.39894 0.41774 6.47242C0.40419 6.5237 0.0947341 7.73603 0.753247 8.56707C1.14435 9.06074 1.7845 9.30289 2.65854 9.29882L2.65852 9.29883ZM2.34042 7.35009C2.67673 7.79738 2.6785 8.38182 2.6362 8.7449L0.958128 6.70025C1.59711 6.76111 2.06124 6.97898 2.34044 7.35009H2.34042ZM0.685943 6.89661L2.37639 8.95669C1.7561 8.91662 1.29957 8.71686 1.01675 8.36068C0.653795 7.90358 0.646558 7.2779 0.685943 6.89661Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_4"
          d="M2.49677 11.6984C2.44899 11.7576 2.44771 11.8419 2.49303 11.903C2.52469 11.9456 3.27945 12.9434 4.33992 12.9467H4.34541C4.9733 12.9467 5.56324 12.599 6.09977 11.9129C6.31027 11.904 6.86411 11.8603 7.20043 11.6444C7.27835 11.5943 7.30089 11.4908 7.25101 11.413C7.20113 11.3351 7.09728 11.3123 7.01959 11.3624C6.7786 11.5172 6.32863 11.5674 6.09663 11.5778C5.90996 11.3442 5.27388 10.6384 4.38982 10.6118C3.72021 10.5937 3.08987 10.958 2.49679 11.6984L2.49677 11.6984ZM4.33968 10.9462C4.35241 10.9462 4.36514 10.9463 4.37799 10.9469C4.93731 10.9628 5.39558 11.3259 5.65294 11.5853L3.0083 11.6225C3.44169 11.1735 3.88841 10.9462 4.33968 10.9462L4.33968 10.9462ZM5.65631 11.9205C5.23917 12.3796 4.79923 12.6119 4.34539 12.6119H4.3427C3.75943 12.6107 3.26552 12.2262 2.99206 11.9577L5.65631 11.9205Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_5"
          d="M10.6166 9.64058C10.525 9.6324 10.4423 9.69805 10.4329 9.79011C10.4034 10.075 10.1622 10.4582 10.0259 10.6459C9.72661 10.6464 8.77829 10.7035 8.20635 11.3782C7.77763 11.8842 7.66608 12.61 7.8753 13.5357C7.89224 13.6099 7.95707 13.6636 8.033 13.666C8.03767 13.6661 8.05204 13.6667 8.07505 13.6667C8.30612 13.6667 9.39638 13.6255 10.0004 12.8729C10.3948 12.3816 10.492 11.7022 10.2897 10.8525C10.414 10.6823 10.7251 10.2219 10.766 9.82458C10.7755 9.73253 10.7086 9.65006 10.6167 9.6406L10.6166 9.64058ZM8.46077 11.5962C8.82196 11.1688 9.39157 11.037 9.75498 10.9975L8.13483 13.0886C8.05178 12.4518 8.161 11.951 8.46074 11.5962H8.46077ZM9.74038 12.6618C9.37581 13.1172 8.76742 13.2633 8.38693 13.3099L10.0189 11.2035C10.118 11.8174 10.0247 12.3068 9.74037 12.6618H9.74038Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_6"
          d="M11.1848 8.87205C11.3131 9.52285 11.8112 10.0623 12.6651 10.476C12.6886 10.4872 12.7133 10.4927 12.7381 10.4927C12.786 10.4927 12.8328 10.4722 12.8656 10.4339C12.8999 10.3937 13.7048 9.43558 13.4719 8.40104C13.3337 7.78622 12.863 7.28683 12.0723 6.91523C12.0169 6.71208 11.8507 6.1815 11.5657 5.90183C11.5 5.83688 11.3938 5.83817 11.3289 5.90417C11.2641 5.97028 11.2652 6.07612 11.3313 6.14096C11.5357 6.34141 11.6848 6.76908 11.7466 6.9929C11.5604 7.227 11.0138 8.00417 11.1848 8.87202L11.1848 8.87205ZM13.1449 8.473C13.2736 9.04188 13.0085 9.60892 12.8077 9.9353L12.1782 7.34605C12.7198 7.65117 13.0444 8.02908 13.1449 8.473ZM11.8525 7.4241L12.4771 9.99418C11.9276 9.6623 11.6043 9.26465 11.5136 8.80919C11.4049 8.26028 11.6568 7.73284 11.8525 7.4241Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
        <Path
          id="Vector_7"
          d="M11.9124 5.17599C12.3182 5.17599 12.7683 5.05824 13.2591 4.82344C13.3278 4.7905 13.3657 4.71527 13.3512 4.64039C13.3411 4.58852 13.0938 3.36194 12.1399 2.89889C11.573 2.62343 10.889 2.68021 10.1058 3.06664C9.91231 2.98347 9.3947 2.78125 8.99762 2.83113C8.9058 2.84223 8.8405 2.92576 8.85172 3.01758C8.86293 3.10951 8.94587 3.17645 9.03828 3.16348C9.32226 3.12937 9.74981 3.27866 9.96324 3.36978C10.0302 3.66135 10.297 4.57334 11.0822 4.98044C11.3332 5.11081 11.6105 5.176 11.9123 5.176L11.9124 5.17599ZM11.9919 3.19932C12.5171 3.4534 12.7951 4.01388 12.9252 4.37462L10.5085 3.25258C11.0847 3.01906 11.5826 3.00107 11.9919 3.19931V3.19932ZM10.3663 3.55573L12.7655 4.6697C12.1634 4.89188 11.6508 4.89714 11.238 4.68407C10.7411 4.42707 10.4857 3.90139 10.3663 3.55574L10.3663 3.55573Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.15"
        />
      </G>
    </Svg>
  );
};

export default CircleGroup;
