import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import IconIon from 'react-native-vector-icons/dist/Ionicons';
import { useTheme } from 'styled-components/native';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { Collapse, IconBadge, PageWithCard, Typography } from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import { AccountHealth, AccountText } from '~/screens/TemPayHelp/styles';
import { useAuth } from '~/auth/legacy/useAuth';
import { isItauOnix } from '~/helpers/itau';

const MERCADO_PAGO_ONIX = ['7569', '7570', '7571'];

export const TemPayHelp = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { onixCode } = useAuth();
  const { isDeepLink, showFooter } = DeepLink.useParams();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  return (
    <PageWithCard
      overrideBodyMaxWidth="none"
      simpleOptions={{
        title: 'Principais Dúvidas',
        onBack: () => navigation.navigate('TemPayDashboard'),
      }}
      overridePadding={{ top: 0, bottom: 0, left: 0, right: 0 }}>
      <View className="px-16 pt-24 desktop:px-92 desktop:pt-40">
        <View className="max-w-[530px]">
          <View className="mb-40 mobile:mb-24">
            <Typography variant="body2">Saiba mais sobre a sua</Typography>
            <AccountText>
              Conta <AccountHealth>Saúde</AccountHealth>
            </AccountText>
          </View>
          <Collapse title="O que é?">
            {isItauOnix(onixCode?.onix_code) ? (
              <>
                <View className="mb-8">
                <Typography variant='body2'>A conta saúde é usada para se identificar nas clínicas e laboratórios da rede quando for realizar o seu atendimento.</Typography>
                </View>
              </>
              ) : (
              <>
                <View className="mb-8">
                <Typography variant="body2">
                  Com ela, você pode <Typography variant="bodyHighlight2">fazer recargas</Typography>{' '}
                  e usar o saldo para{' '}
                  <Typography variant="bodyHighlight2">
                    pagar pelos seus atendimentos com desconto.
                  </Typography>
                </Typography>
                
              </View>

              <View>
                <Typography variant="body2">
                  Além disso, você usa a sua Conta Saúde{' '}
                  <Typography variant="bodyHighlight2">para se identificar</Typography> nas clínicas e
                  laboratórios da rede quando for realizar o seu atendimento.
                </Typography>
              </View>
            </>
            )}
          </Collapse>
          {!isItauOnix(onixCode?.onix_code) &&
          <Collapse title="Recargas">
            <View className="mb-8">
              <Typography variant="body2">
                Realize recargas na sua Conta Saúde e use o saldo para pagar seus atendimentos.
              </Typography>
            </View>

            <>
              {checkMercadoPago ? (
                <>
                  <View className="mb-8">
                    <Typography variant="body2">
                      <Typography variant="bodyHighlight2">Para realizar a recarga</Typography>, é
                      bem simples:
                    </Typography>
                  </View>

                  <View className="mb-8 gap-8 pl-12">
                    <Typography variant="body2">
                      1. Acesse o APP Mercado Pago e selecione Seguros.
                    </Typography>
                    <Typography variant="body2">2. Depois selecione “Saúde Protegida”.</Typography>
                    <Typography variant="body2">
                      3. No “Detalhe do seu seguro” selecione “Seu perfil TEM Saúde”.
                    </Typography>
                    <Typography variant="body2">
                      4. No menu da Conta Saúde, selecione a opção de recarga;
                    </Typography>
                    <Typography variant="body2">5. Digite o valor da sua recarga;</Typography>
                    <Typography variant="body2">
                      6. Escolha a forma de pagamento: cartão de crédito ou boleto bancário;
                    </Typography>
                    <Typography variant="body2">
                      7. Para <Typography variant="bodyHighlight2">cartões de crédito</Typography>,
                      insira os dados do cartão, escolha o número de parcelas (até 12x, com parcela
                      mínima de R$20);
                    </Typography>
                    <Typography variant="body2">
                      8. Para <Typography variant="bodyHighlight2">boletos</Typography>, será
                      cobrado uma taxa de R$2,50 por emissão, confirme a sua solicitação e realize o
                      pagamento;
                    </Typography>
                    <Typography variant="body2">
                      9. Após o pagamento ser aprovado e/ou compensado, o saldo será liberado na sua
                      Conta Saúde;
                    </Typography>
                    <Typography variant="body2">
                      10. Agora, basta pagar pelos seus atendimentos no local utilizando sua Conta
                      Saúde.
                    </Typography>
                  </View>
                </>
              ) : 
              (
                <>
                  <View className="mb-8">
                    <Typography variant="body2">
                      <Typography variant="bodyHighlight2">Para realizar a recarga,</Typography> no
                      site ou aplicativo, é bem simples:
                    </Typography>
                  </View>

                  <View className="mb-8 gap-8 pl-12">
                    <Typography variant="body2">1. No menu fixo, clique em Recargas;</Typography>
                    <Typography variant="body2">2. Digite o valor da sua recarga;</Typography>
                    <Typography variant="body2">
                      3. Escolha a forma de pagamento: cartão de crédito ou boleto bancário;
                    </Typography>
                    <Typography variant="body2">
                      4. Para <Typography variant="bodyHighlight2">cartões de crédito</Typography>,
                      insira os dados do cartão, escolha o número de parcelas (até 12x, com parcela
                      mínima de R$20);
                    </Typography>
                    <Typography variant="body2">
                      5. Para <Typography variant="bodyHighlight2">boletos</Typography>, basta
                      confirmar a sua solicitação e realizar o pagamento do boleto;
                    </Typography>
                    <Typography variant="body2">
                      6. Após o pagamento ser aprovado e/ou compensado, o saldo será liberado na sua
                      Conta Saúde;
                    </Typography>
                    <Typography variant="body2">
                      7. Agora, basta pagar pelos seus atendimentos no local utilizando sua Conta
                      Saúde.
                    </Typography>
                  </View>
                </>
              )}
            </>

            <View className="mb-8">
              <Typography variant="body2">
                <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Para pagar suas
                consultas e exames com desconto na rede, é obrigatório fazer as recargas na sua
                Conta Saúde.
              </Typography>
            </View>

            <Typography variant="body2">
              As recargas precisam ser feitas{' '}
              <Typography variant="bodyHighlight2">
                em até 2 dias úteis antes do horário agendado
              </Typography>{' '}
              para garantir a sua reserva na clínica ou laboratório.
            </Typography>
          </Collapse>
}
          <Collapse title="Senha da Conta Saúde">
            <View className="mb-8">
              <Typography variant="body2">
                A senha {!checkMercadoPago ? 'do cartão' : 'da Conta Saúde'} serve para{' '}
                <Typography variant="bodyHighlight2">confirmar o pagamento</Typography> das suas
                consultas e exames na rede.
              </Typography>
            </View>
            <View className="mb-8">
              <Typography variant="body2">
                Use a sua senha quando estiver na clínica ou laboratório para realizar a sua
                consulta ou exame agendado.
              </Typography>
            </View>
            <View className="mb-16">
              <Typography variant="body2">
                É só{' '}
                <Typography variant="bodyHighlight2">
                  informar a senha{' '}
                  {!checkMercadoPago ? 'do seu cartão digital' : 'da sua Conta Saúde'} ao atendente
                </Typography>{' '}
                no estabelecimento que será atendido e ele(a) irá confirmar o recebimento do seu
                pagamento.
              </Typography>
            </View>
            <>
              {!checkMercadoPago ? (
                <>
                  <View className="mb-24 rounded-lg bg-background-gray p-16 mobile:mb-16">
                    <Typography variant="bodyHighlight2">Trocar senha:</Typography>
                    <Typography variant="body2">
                      Você pode trocar a sua senha da sua conta Saúde sempre que precisar, em nosso
                      aplicativo e também no site, acessando:
                    </Typography>
                    <Typography
                      variant="link"
                      color="primary"
                      onPress={() => navigation.navigate('Dashboard')}
                      style={{
                        fontSize: theme.isMobile ? 14 : 16,
                        lineHeight: theme.isMobile ? 24 : 28,
                      }}>
                      app.temsaude.com/login
                    </Typography>
                  </View>

                  <View className="mb-16 flex-row items-center mobile:mb-8">
                    <View className="mr-8">
                      <IconBadge
                        icon={
                          <IconIon
                            name="alert-outline"
                            size={theme.isMobile ? 12 : 16}
                            color="white"
                          />
                        }
                        size={theme.isMobile ? 16 : 24}
                        borderWidth={1}
                        backgroundColor="warning"
                      />
                    </View>
                    <Typography variant="bodyHighlight2">Não confunda suas senhas!</Typography>
                  </View>
                  <View className="mb-8">
                    <Typography variant="body2">
                      Sua senha do APP é diferente da sua senha da Conta Saúde.
                    </Typography>
                  </View>

                  <View className="gap-8 pl-8">
                    <Typography variant="body2">
                      • <Typography variant="bodyHighlight2">Senha do APP:</Typography> você usa
                      para acessar a sua conta no site ou aplicativo.
                    </Typography>
                    <Typography variant="body2">
                      • <Typography variant="bodyHighlight2">Senha da Conta Saúde:</Typography> você
                      usa para pagar seus atendimentos no estabelecimento agendado.
                    </Typography>
                  </View>
                </>
              ) : (
                <>
                  <View className="mb-24 rounded-lg bg-background-gray p-16 mobile:mb-16">
                    <Typography variant="bodyHighlight2">Trocar senha:</Typography>
                    <Typography variant="body2">
                      Você pode trocar a sua senha da sua Conta Saúde sempre que precisar.
                    </Typography>
                  </View>
                </>
              )}
            </>
          </Collapse>
          {!isItauOnix(onixCode?.onix_code) && (
          <Collapse title="Estorno">
            <Typography variant="body2">
              Caso tenha feito uma recarga na sua Conta Saúde e queira receber o seu dinheiro de
              volta,{' '}
              <Typography variant="bodyHighlight2">
                entre em contato com a Central de Atendimento
              </Typography>{' '}
              e a nossa equipe realiza o estorno para você.
            </Typography>
          </Collapse>
          )}
        </View>
      </View>

      <View className="mt-auto h-40 mobile:h-16" />
      {(!isDeepLink || (isDeepLink && showFooter)) && (
        <View
          className="w-full p-24 pb-[26px] mobile:p-16 mobile:pb-[18px]"
          style={{ backgroundColor: theme.colors.background }}>
          <View className="w-full max-w-[530px] self-center">
            <Typography variant="h3" className="mb-8">
              Central de atendimento
            </Typography>
            <Footer variant="simple-phone-footer-inverted" />
          </View>
        </View>
      )}
    </PageWithCard>
  );
};
