import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsWelcomingWomen/desktop.png';
import mobileBackground from '../../assets/images/benefitsWelcomingWomen/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { BenefitsWelcomingWomenIcon } from '~/components/Icons/BenefitsWelcomingWomenIcon';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsWelcomingWomen = (): JSX.Element => {
  const navigation = useNavigation();
  const { showSweetAlert } = useSweetAlert();
  const bellaWhatsAppNumber = '+5511937762742';
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const redirectWhatsapp = async () => {
    try {
      if (Platform.OS === 'web') {
        window.open(`https://wa.me/${bellaWhatsAppNumber}`, '_blank');
      } else {
        await Linking.openURL(`whatsapp://send?phone=${bellaWhatsAppNumber}`);
      }
    } catch (err) {
      showSweetAlert('Ops, algo deu errado', 'Erro ao abrir whatsapp', 'error', true, false, {
        layout: 'helloUi',
        touchOutside: false,
      });
    }
  };

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <BenefitsWelcomingWomenIcon />,
        title: 'Acolhimento à Mulher',
        onBack: () => {
          navigation.navigate('BenefitsWoman');
        },
      }}>
      <View>
        <Typography variant="body2">
          Esse é um serviço dedicado às mulheres{' '}
          <Typography variant="bodyHighlight2">vítimas de violência doméstica</Typography>. Crie uma
          rede de apoio com contatos de confiança, conheça os serviços de apoio a vítimas e{' '}
          <Typography variant="bodyHighlight2">tenha acesso à conteúdos</Typography> sobre como agir
          nessas situações.
        </Typography>
      </View>
      <View className="pt-24 mobile:pb-56">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">Ative seu cadastro</Typography> no Programa de
            Apoio Contra a Violência Doméstica para ter acesso aos serviços:
          </Typography>
          <View className="mb-8 gap-8">
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                <Typography variant="bodyHighlight2">Auxílio</Typography> em questões do{' '}
                <Typography variant="bodyHighlight2">universo jurídico</Typography>, em um canal de
                escuta formado por advogadas para acolhimento, baseado na compreensão e empatia;
              </Typography>
            </View>
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                <Typography variant="bodyHighlight2">Orientações</Typography> sobre a rede de
                atendimento existente na sua região;
              </Typography>
            </View>
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                Mecanismo rápido de acionamento da{' '}
                <Typography variant="bodyHighlight2">rede de proteção</Typography>, composta por até
                três pessoas que serão notificadas caso a função AJUDA seja acionada;
              </Typography>
            </View>
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                <Typography variant="bodyHighlight2">
                  Indicação de advogadas especializadas
                </Typography>{' '}
                que atendem na sua localidade;
              </Typography>
            </View>
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                <Typography variant="bodyHighlight2">
                  Suporte psicológico 24 horas por telefone
                </Typography>{' '}
                para atendimento às vítimas;
              </Typography>
            </View>
            <View className="flex flex-row gap-4">
              <Typography variant="bodyHighlight2">•</Typography>
              <Typography key={`key-${Math.random()}`} variant="body2">
                <Typography variant="bodyHighlight2">Conteúdo informativo</Typography> para auxiliar
                em situações de vulnerabilidade doméstica.
              </Typography>
            </View>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mt-auto">
        <Typography variant="bodyHighlight2">
          Para acionar o programa de apoio clique abaixo:
        </Typography>
      </View>
      <Button variant="primary" onPress={async () => redirectWhatsapp()}>
        Acionar
      </Button>
    </PageWithCard>
  );
};
