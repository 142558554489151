import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const PinIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12.2 3C8.24 3 5 6.27 5 10.26c0 1.44.36 2.91 1.11 4.38.57 1.14 1.38 2.28 2.4 3.36 1.71 1.83 3.42 2.91 3.48 2.94.06.03.12.06.18.06s.12-.03.18-.06 1.77-1.11 3.48-2.94c1.02-1.08 1.83-2.22 2.4-3.36.81-1.44 1.17-2.91 1.17-4.38C19.4 6.27 16.16 3 12.2 3h0zm3.18 14.49c-1.32 1.44-2.67 2.4-3.18 2.73-.48-.33-1.83-1.29-3.15-2.7-1.53-1.62-3.33-4.2-3.33-7.23 0-3.6 2.91-6.54 6.48-6.54 3.57 0 6.48 2.94 6.48 6.54 0 3.03-1.8 5.58-3.3 7.2h0zM12.2 7.26a2.88 2.88 0 10.001 5.762A2.88 2.88 0 0012.2 7.26zm0 5.04c-1.2 0-2.16-.96-2.16-2.16 0-1.2.96-2.16 2.16-2.16 1.2 0 2.16.96 2.16 2.16 0 1.2-.96 2.16-2.16 2.16z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth={0.5}
      />
    </Svg>
  );
};

export default PinIcon;
