import React, { useEffect, useRef } from 'react';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { Animated } from 'react-native';
import * as S from './styles';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';

interface AnimatedProps {
  color?: string;
  description?: string;
}

export const AnimatedDots = ({ color, description }: AnimatedProps): JSX.Element => {
  const theme = useTheme();

  const dot1 = useRef(new Animated.Value(0)).current;
  const dot2 = useRef(new Animated.Value(0)).current;
  const dot3 = useRef(new Animated.Value(0)).current;

  const fadeIn = (dot: Animated.Value) => {
    Animated.timing(dot, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  };

  const fadeOut = (dot: Animated.Value) => {
    Animated.timing(dot, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  };

  useEffect(() => {
    const dots = [dot1, dot2, dot3];

    for (let i = 0; i < 100; i++) {
      const timeout = i === 0 ? 0 : 3900 * i;
      setTimeout(() => {
        dots.forEach((dot, index) => {
          const time = index === 0 ? 1 : 1000 * index;

          setTimeout(() => {
            fadeIn(dot);

            if (index === dots.length - 1) {
              dots.forEach((dot, index) => {
                const time2 = index === 0 ? 1 : 500 * index;

                setTimeout(() => {
                  fadeOut(dot);
                }, time2);
              });
            }
          }, time);
        });
      }, timeout);
    }
  }, []);

  return (
    <S.WrapperLoading>
      <S.SafeAreaView>
        <S.AnimatedDot
          style={[
            {
              opacity: dot1,
            },
          ]}>
          <Icon name="checkbox-blank-circle" size={10} color={color ?? theme?.colors?.black} />
        </S.AnimatedDot>
        <S.AnimatedDot
          style={[
            {
              opacity: dot2,
            },
          ]}>
          <Icon name="checkbox-blank-circle" size={10} color={color ?? theme?.colors?.black} />
        </S.AnimatedDot>
        <S.AnimatedDot
          style={[
            {
              opacity: dot3,
            },
          ]}>
          <Icon name="checkbox-blank-circle" size={10} color={color ?? theme?.colors?.black} />
        </S.AnimatedDot>
      </S.SafeAreaView>
      <Typography variant="paragraph" color={theme.colors.black} style={{ paddingTop: 15 }}>
        {description}
      </Typography>
    </S.WrapperLoading>
  );
};
