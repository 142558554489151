import React from 'react';
import { G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const PlumberIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 19} height={height ?? 22} viewBox="0 0 19 22" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M1.70833 0.95H1.65833V1V7.37857H1H0.95V7.42857V10.2857C0.95 10.6674 1.02456 11.0454 1.16942 11.3981C1.31429 11.7508 1.52664 12.0713 1.79436 12.3412C2.06208 12.6112 2.37994 12.8254 2.72979 12.9715C3.07965 13.1176 3.45463 13.1929 3.83333 13.1929H6.66667H6.71667V13.1429V12.4786H12.2833V13.1429V13.1929H12.3333H13.7V14.5714V14.6214H13.75H14.4083V21V21.05H14.4583H17.2917H17.3417V21V14.6214H18H18.05V14.5714V11.7143C18.05 11.3326 17.9754 10.9546 17.8306 10.6019C17.6857 10.2492 17.4734 9.92875 17.2056 9.65877C16.9379 9.3888 16.6201 9.17462 16.2702 9.02849C15.9204 8.88236 15.5454 8.80714 15.1667 8.80714H12.3333H12.2833V8.85714V9.52143H6.71667V8.85714V8.80714H6.66667H5.3V7.42857V7.37857H5.25H4.59167V1V0.95H4.54167H1.70833ZM4.54167 9.62143H5.90833V12.3786H3.83333C3.28314 12.3786 2.75541 12.1582 2.36623 11.7657C1.97705 11.3733 1.75833 10.8409 1.75833 10.2857V8.19286H4.49167V9.57143V9.62143H4.54167ZM14.4583 12.3786H13.0917V9.62143H15.1667C15.7169 9.62143 16.2446 9.84182 16.6338 10.2343C17.023 10.6267 17.2417 11.1591 17.2417 11.7143V13.8071H14.5083V12.4286V12.3786H14.4583ZM2.46667 1.76429H3.78333V7.37857H2.46667V1.76429ZM6.71667 10.3357H12.2833V11.6643H6.71667V10.3357ZM15.2167 14.6214H16.5333V20.2357H15.2167V14.6214Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.1"
        />
      </G>
    </Svg>
  );
};
