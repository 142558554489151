import React from 'react';
import { Circle, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const ErrorIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 80 80" fill="none">
      <G filter="url(#filter0_d_10902_858)">
        <Circle cx="39" cy="39" r="32" fill="#B00020" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M38.9079 45.7792C37.3182 45.7792 36 44.4609 36 42.8713V27.3625C36 25.7728 37.3182 24.4546 38.9079 24.4546C40.4976 24.4546 41.8158 25.7728 41.8158 27.3625V42.8713C41.8158 44.4609 40.4976 45.7792 38.9079 45.7792Z"
        fill="white"
      />
      <Path
        d="M38.9079 56.0157C38.5202 56.0157 38.1325 55.9381 37.7835 55.783C37.4346 55.6667 37.1244 55.4341 36.853 55.1627C36.5816 54.8913 36.3489 54.5811 36.2326 54.2322C36.0775 53.8832 36 53.4955 36 53.1078C36 52.7201 36.0775 52.3711 36.2326 51.9834C36.3877 51.6344 36.5816 51.3243 36.853 51.0529C37.9386 49.9672 39.8772 49.9672 40.9628 51.0529C41.2342 51.3243 41.4281 51.6344 41.5832 51.9834C41.7383 52.3711 41.8158 52.7201 41.8158 53.1078C41.8158 53.4955 41.7383 53.8832 41.5832 54.2322C41.4669 54.5811 41.2342 54.8913 40.9628 55.1627C40.42 55.7055 39.6833 56.0157 38.9079 56.0157Z"
        fill="white"
      />
    </Svg>
  );
};
