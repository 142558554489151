import React from 'react';
import { Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};

export const BenefitsWomensHealthIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12.5001 12.1945C12.1671 11.7556 11.6379 11.4688 11.0421 11.4688C10.0398 11.4688 9.22656 12.2706 9.22656 13.2589C9.22656 13.6425 9.35268 14.0123 9.59458 14.3303L12.2233 17.565C12.29 17.648 12.3951 17.6962 12.5037 17.6962C12.6123 17.6962 12.714 17.6478 12.7841 17.565L15.4688 14.2472C15.4759 14.2369 15.4829 14.2266 15.4898 14.2196C15.6791 13.9293 15.7807 13.5975 15.7807 13.2589C15.7807 12.2706 14.964 11.4688 13.9615 11.4688C13.3622 11.4688 12.8331 11.7522 12.5001 12.1945L12.5001 12.1945ZM15.0621 13.2591C15.0621 13.4561 15.0025 13.6496 14.8939 13.8259L12.5001 16.7806L10.1588 13.8983C10.0152 13.7082 9.93801 13.487 9.93801 13.2589C9.93801 12.661 10.4322 12.1737 11.0387 12.1737C11.645 12.1737 12.1427 12.6609 12.1427 13.2589C12.1427 13.4524 12.3039 13.6114 12.5002 13.6114C12.6964 13.6114 12.8578 13.4525 12.8578 13.2589C12.8578 12.661 13.3519 12.1737 13.9584 12.1737C14.568 12.1738 15.0622 12.661 15.0622 13.259L15.0621 13.2591Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
      <Path
        d="M12.5003 6.17359C13.9478 6.17359 15.1219 5.01247 15.1219 3.58502C15.1219 2.15773 13.9444 1 12.5003 1C11.0527 1 9.875 2.16112 9.875 3.58502C9.875 5.01231 11.0527 6.17359 12.5003 6.17359ZM12.5003 1.70494C13.5518 1.70494 14.4071 2.54827 14.4071 3.58506C14.4071 4.62524 13.5518 5.46857 12.5003 5.46857C11.4453 5.46857 10.5901 4.62524 10.5901 3.58506C10.5901 2.54827 11.4488 1.70494 12.5003 1.70494Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
      <Path
        d="M19.9694 17.9203L15.1434 7.39681C15.0838 7.27246 14.9577 7.18945 14.8175 7.18945H9.87528C9.73164 7.18945 9.60192 7.27246 9.54575 7.40375L5.02775 17.9304C4.98222 18.041 4.9927 18.1654 5.05936 18.2621C5.12601 18.3624 5.23804 18.421 5.35728 18.421H9.07598L10.7549 22.772C10.8075 22.9102 10.9407 23 11.0879 23H14.1477C14.302 23 14.4386 22.9033 14.4877 22.7581L15.9352 18.421H19.64C19.7627 18.421 19.8749 18.3588 19.9414 18.2585C20.008 18.1585 20.0185 18.0306 19.9694 17.92L19.9694 17.9203ZM15.6794 17.7165C15.5251 17.7165 15.3885 17.8132 15.3393 17.9584L13.8918 22.2955H11.3366L9.6577 17.9446C9.60513 17.8063 9.47197 17.7166 9.32473 17.7166H5.89692L10.1133 7.89452H14.5892L19.0896 17.7166L15.6794 17.7165Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </Svg>
  );
};
