import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const CalendarIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 29} height={height ?? 26} fill="none" viewBox="0 0 29 26">
      <Rect
        width={26.667}
        height={21.333}
        x={1.166}
        y={3.668}
        stroke={color ?? theme.colors.primary}
        strokeLinejoin="round"
        strokeWidth={1.733}
        rx={1.333}
      />
      <Path stroke={color ?? theme.colors.primary} strokeWidth={1.733} d="M1.166 10.332h26.667" />
      <Path
        stroke={color ?? theme.colors.primary}
        strokeLinecap="round"
        strokeMiterlimit={2.366}
        strokeWidth={1.733}
        d="M7.833 1v5.333M21.166 1v5.333"
      />
      <Path stroke={color ?? theme.colors.primary} strokeWidth={6.667} d="M9.166 14.332v6.667" />
    </Svg>
  );
};

export default CalendarIcon;
