import { useState } from 'react';
import { useTokenSmsTypes } from './types';

export const useTokenSMS = (): useTokenSmsTypes => {
  const [opt] = useState<string>('');

  const getSMSHash = (getOpt?: boolean) => {
    return '';
  };

  return { getSMSHash, opt };
};
