import React from 'react';
import { View } from 'react-native';
import { clsx } from 'clsx';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { CardStatementType } from '~/contexts/types';
import { Typography } from '~/components/@hello-ui';
import { TransactionStatusEnum } from '~/enums/type';

interface StatementProps {
  statement: CardStatementType;
  showTopLine?: boolean;
}

export const Statement: React.FC<StatementProps> = ({ statement, showTopLine }) => {
  const theme = useTheme();
  const isRecharge = TransactionStatusEnum[statement.tipo_transacao] === 'Recarga';

  return (
    <View>
      {showTopLine && <View className="h-1 w-full bg-background-gray"></View>}
      <View className="flex-row p-16">
        <View className="mr-16 h-24 w-24 items-center justify-center">
          <View
            className={clsx(
              'absolute h-24 w-24 rounded-full opacity-30',
              isRecharge ? 'bg-success' : 'bg-error',
            )}></View>
          <FeatherIcon
            name="arrow-up"
            size={16}
            color={isRecharge ? theme.colors.success : theme.colors.red}
          />
        </View>
        <View className="mr-auto pr-16">
          <Typography variant="bodyHighlight2">
            {TransactionStatusEnum[statement.tipo_transacao]}
          </Typography>
          <Typography variant="body2">
            {Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(
              new Date(statement.data_transacao),
            )}
          </Typography>
        </View>
        <Typography variant="bodyHighlight2" color={isRecharge ? 'success' : 'error'}>
          R${' '}
          {Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: 'BRL',
          }).format(statement.valor_transacao_d)}
        </Typography>
      </View>

      <View className="h-1 w-full bg-background-gray"></View>
    </View>
  );
};
