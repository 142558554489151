import React, { useCallback, useEffect } from 'react';
import { Linking, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DeepLinkFirstAccessStepProps } from '../types';
import { Button, Input, Typography, useSweetAlert } from '~/components/@hello-ui';
import { ReSendCode } from '~/screens/Login/components/ReSendCode';
import { instanceApi } from '~/App';
import { useTokenSMS } from '~/components/@tem-ui/TokenSMS';
import { useAuth } from '~/auth/legacy/useAuth';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';

interface FormData {
  token: string;
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  token: yup.string().required('Campo obrigatório').min(4, 'Preencha os 4 dígitos'),
});

const maskPhoneNumber = (phoneNumber) => {
  const visibleDigits = 3;
  const firstPart = phoneNumber.slice(0, 4);
  const middlePart = '*'.repeat(phoneNumber.length - visibleDigits - 8);
  const lastPart = phoneNumber.slice(-3);
  return `${firstPart} 9 ${middlePart}-*${lastPart}`;
};

export const DeepLinkFirstAccessPhoneConfirmation = ({
  setStep,
  firstAccessData,
  privacyPolicyLink,
}: DeepLinkFirstAccessStepProps) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { getSMSHash } = useTokenSMS();
  const { partner } = useAuth();

  const defaultValues: FormData = {
    token: '',
  };

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });

  const sendCode = useCallback(async () => {
    try {
      const smsHash = await getSMSHash(true);
      const {
        data: { validationToken },
      } = await instanceApi.sendToken(
        firstAccessData.cpf,
        partner?.onix_code as unknown as string,
        smsHash,
      );

      if (validationToken) {
        await AsyncStorage.setItem('@tem::ValidationToken', validationToken);
      }
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível obter o token.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    }
  }, [firstAccessData, partner, showSweetAlert, AsyncStorage]);

  const resend = async () => {
    await sendCode();
    showSweetAlert('Tudo certo!', 'Um novo código de acesso foi enviado', 'success', false, false, {
      layout: 'helloUi',
      touchOutside: false,
      buttons: [
        {
          text: 'Ok',
          variant: 'successPrimary',
          onPress: () => hideSweetAlert(),
        },
      ],
    });
  };

  const handleForm = async (data: FormData) => {
    try {
      setLoading(true);
      const response = await instanceApi.checkToken(+data.token);
      const { isValid } = response.data;
      if (!isValid) {
        showSweetAlert(
          'Ops, algo deu errado',
          'Código inválido. Tente novamente',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'OK',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
        return;
      }
      setStep('password');
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível confirmar seu celular. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void sendCode();
  }, []);

  return (
    <PageWrapper
      bellowButtonComponent={
        <View className="h-full mobile:px-16">
          <View className="mb-24 mobile:mb-20">
            <ReSendCode onPress={resend} />
          </View>
        </View>
      }>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="title">Qual o código?</Typography>
      </View>
      <View className="mb-16 mobile:mb-8">
        <Typography variant={theme.isMobile ? 'body1' : 'body2'}>
          Digite o código de acesso enviado por SMS para o celular cadastrado
        </Typography>
      </View>
      <View className="mb-24 mobile:mb-8">
        <Typography variant="helperText">
          Número: {maskPhoneNumber(firstAccessData.phone)}
        </Typography>
      </View>
      <Controller
        name="token"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            variant="code"
            value={value}
            onChangeText={onChange}
            isAutoFocus
            label="Código de acesso"
            keyboardType="number-pad"
          />
        )}
      />
      <View className="mb-24 mt-auto mobile:mb-68">
        <Button
          disabled={!isFormValid || loading}
          variant="outLine"
          onPress={handleSubmit(handleForm)}
          loading={loading}>
          Próximo
        </Button>
      </View>
    </PageWrapper>
  );
};
