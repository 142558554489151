import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { IPasswordAuthenticateOptions } from '~/auth/strategies/password/interfaces/default-authenticate-options';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useAuthStore } from '~/store/auth.store';
import { CompleteDataGatekeeper } from '~/auth/gatekeepers/complete-data-gatekeeper';
import { ProductGatekeeper } from '~/auth/gatekeepers/product-gatekeeper';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class PasswordStrategy extends BaseStrategy {
  static StrategyName = EAuthStrategy.Password;

  async authenticate(options: IPasswordAuthenticateOptions): Promise<void> {
    const { clientType, firstAccess, elegible } = await OlimpoService.login.checkFirstAccess(
      options.cpf,
    );

    if (!elegible) {
      throw new Error('User is not elegible');
    }

    if (options.firstAccess) {
      if (!options.firstAccess?.code || !options.firstAccess?.validationToken) {
        throw new Error('Validation token and code are required for first access');
      }

      await OlimpoService.login.passwordFirstAccess({
        cpf: options.cpf,
        password: options.password,
        validationToken: options.firstAccess.validationToken,
        code: options.firstAccess.code,
      });
    }

    const { user, token, refreshToken } = await OlimpoService.login.login(
      options.cpf,
      options.password,
    );

    useAuthStore.setState({
      user: { ...user, isDependent: clientType === 'DEPENDENTE' },
      token,
      refreshToken,
      isFirstAccess: firstAccess,
    });

    await this.updateMyProducts();
  }

  getGateKeepers(): IGatekeeper[] {
    return [new ProductGatekeeper(), new CompleteDataGatekeeper()];
  }
}
