import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import CardIcon from '~/components/Icons/CardIcon';
import { CardButton, Typography } from '~/components/@hello-ui';
import StethoscopeIcon from '~/components/Icons/Stethoscope';

import { useAuth } from '~/auth/legacy/useAuth';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';

export const EmptyPage = () => {
  const theme = useTheme();
  const navigation = useNavigation();

  const { onixCode } = useAuth();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  return (
    <View>
      <View className="mb-80 items-center justify-center rounded-lg bg-background-gray px-16 py-24 mobile:mb-40">
        <Typography variant="helperText">Você ainda não fez nenhuma movimentação.</Typography>
      </View>
      <Typography variant="body2" className="mb-24 mobile:mb-16">
        Aproveite para{' '}
        <Typography variant="bodyHighlight2">fazer a sua primeira recarga</Typography> e agendar um
        cuidado especial para sua saúde:
      </Typography>
      <View className="flex-row">
        <>
          {checkMercadoPago ? (
            <View className="mr-16 max-w-[132px] flex-1 mobile:mr-8 mobile:max-w-[166px]">
              <CardButton
                icon={<CardIcon height={24} width={24} color={theme.colors.primary} />}
                title="Recarga"
                variant="small"
                orientation="vertical"
                onPress={() => {
                  navigation.navigate('TemPayRecharge');
                }}
              />
            </View>
          ) : (
            <>
              <View className="mr-16 max-w-[132px] flex-1 mobile:mr-8 mobile:max-w-[166px]">
                <CardButton
                  icon={<StethoscopeIcon height={24} width={24} color={theme.colors.primary} />}
                  title="Agendar"
                  variant="small"
                  orientation="vertical"
                  onPress={() => {
                    navigation.navigate('ScheduleAppointment');
                  }}
                />
              </View>
              <View className="max-w-[132px] flex-1 mobile:max-w-[166px]">
                <CardButton
                  icon={<Icon name="home" size={22} color={theme?.colors.primary} />}
                  title="Início"
                  variant="small"
                  orientation="vertical"
                  onPress={() => {
                    navigation.navigate('Dashboard');
                  }}
                />
              </View>
            </>
          )}
        </>
      </View>
    </View>
  );
};
