import React from 'react';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { clsx } from 'clsx';
import { Typography } from '../../../@hello-ui';
import { SpacingToken } from '~/components/SpacingToken';

interface ContentContact {
  title: string;
  description: string;
}

interface ItemProps {
  title: string;
  description: string;
  idx: number;
}

interface SimplePhoneFooter {
  className?: string;
}

export const SimplePhoneFooter = ({ className }: SimplePhoneFooter): JSX.Element => {
  const theme = useTheme();

  const renderDescription = ({ description }: ItemProps) => {
    return (
      <View key="phonefooter-description" className="float-left w-full">
        <SpacingToken mTop="s8">
          <Typography variant="body2" color={theme.colors.black70}>
            {description}
          </Typography>
        </SpacingToken>
      </View>
    );
  };

  const renderItem = ({ title, description, idx }: ItemProps) => {
    return (
      <View className="w-1/2" key={`phonefooter-item-${idx}`}>
        <Typography variant="link" color={theme.colors.primary}>
          {title}
        </Typography>

        <Typography variant="helperText" color={theme.colors.black70}>
          {description}
        </Typography>
      </View>
    );
  };

  const items =
    theme?.footer_phones && typeof theme?.footer_phones !== 'string' && theme?.footer_phones;

  const descriptionData = {
    title: items[0].title,
    description: items[0].description,
  };

  return (
    <View
      className={clsx('w-full rounded-lg', className)}
      style={{ backgroundColor: theme.colors.black5 }}>
      <View className="w-full p-24">
        <View className="w-full flex-row">
          {items.map(({ title, description }: ContentContact, idx: number) => {
            return idx !== 0 && renderItem({ title, description, idx });
          })}
        </View>

        {renderDescription(descriptionData)}
      </View>
    </View>
  );
};
