import React, { useEffect, useState } from 'react';
import { ScheduleToChoose } from './ScheduleToChoose';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useApi } from '~/hooks/api';
import { useAppointment } from '~/hooks/appointment';
import { serializeAndUpperString } from '~/utils/strings';
import { AppointmentFlowType } from '~/enums/appointment';
import { ListItem, MapViewList } from '~/components/MapViewList';
import { Location } from '~/components/LocationCardList/LocationCard';
import { SearchNetworkResAccredited } from '~/contexts/types';

const convertToUnitType = (data: SearchNetworkResAccredited): Location => {
  return {
    name: data['RAZAO SOCIAL'] || '',
    cnpj: data['CNPJ'] || '',
    email: data['EMAIL'] || '',
    address: {
      number: data['NUMERO'] || '',
      street: data['LOGRADOURO'] || '',
      city: data['MUNICIPIO'] || '',
      neighbourhood: data['BAIRRO'] || '',
      state: data['ESTADO'] || '',
      zipCode: data['CEP'] || '',
      lat: data['LATITUDE']?.toString() || '',
      long: data['LONGITUDE']?.toString() || '',
    },
  };
};

interface ScheduleUnitProps {
  specialtyName?: string;
  isExam?: boolean;
}

export const ScheduleUnit = ({ specialtyName, isExam }: ScheduleUnitProps): JSX.Element => {
  usePageWithCardSettings({
    scrollEnabled: false,
    overrideMaxBodyWidth: 'auto',
  });

  const api = useApi();

  const { nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();

  const [loading, setLoading] = useState(false);
  const [unities, setUnities] = useState<ListItem<Location>[]>();

  const handleSelect = (item: ListItem<Location>) => {
    appointment &&
      setAppointment({ ...appointment, clinic: item.data, flowType: AppointmentFlowType.Clinic });
    nextStep();
  };

  const convertToListItem = (data: Location[]): ListItem<Location>[] => {
    return data.map((unit) => ({
      name: unit.name,
      address: unit.address,
      data: unit,
    }));
  };

  const getUnities = async () => {
    if (appointment?.address) {
      setLoading(true);
      try {
        const { city, state, neighborhood } = appointment?.address || {};

        if (specialtyName) {
          appointment.specialty = { name: specialtyName };
        }

        const { data } = await api.searchZipCode(`${neighborhood.join(', ')}, ${city} / ${state}`);
        const { lat, lng } = data;

        await api
          .searchNetwork({
            latitude: String(lat),
            longitude: String(lng),
            distancia: 30,
            especialidadeMedica: serializeAndUpperString(appointment?.specialty?.name || ''),
          })
          .then(({ data }) => {
            const accredited = data?.map(({ credenciado }) => convertToUnitType(credenciado[0]));

            setUnities(convertToListItem(accredited));
          });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getUnities();
  }, []);

  return unities?.length === 0 ? (
    <ScheduleToChoose />
  ) : (
    <MapViewList
      title="Escolha uma unidade da rede"
      data={unities}
      onClickCard={handleSelect}
      loading={loading}
      hasButton={!isExam}
    />
  );
};
