import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

const WrapperVariations = {
  web: css`
    shadow-color: #4b3f4033;
    shadow-offset: 1px 2px;
    shadow-radius: 20px;
  `,
  mobile: css`
    shadow-color: #4b3f4085;
    shadow-offset: 1px 2px;
    shadow-opacity: 0.4;
    shadow-radius: 3px;
    elevation: 12;
  `,
};

export const GeoCard = styled.TouchableOpacity<{ backgroundColor?: string }>`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  border-radius: 100px;
  padding: ${({ theme }) =>
    `${theme.isMobile ? theme.tokens.space.s12 : theme.tokens.space.s16} ${
      theme.tokens.space.s24
    }`};

  ${({ backgroundColor }) => (backgroundColor ? `backgroundColor: ${backgroundColor}` : '')};

  ${Platform.OS === 'web' ? WrapperVariations.web : WrapperVariations.mobile};
`;

export const WrapperButtonLayout = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const CardIcon = styled.View``;

export const CardText = styled.View`
  margin-left: ${(props) => props.theme.tokens.space.s8};
`;
