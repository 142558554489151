import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Maternity = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="17" height="24" viewBox="0 0 17 24" fill="none">
      <Path d="M8.50046 12.1937C8.16749 11.7547 7.63832 11.4679 7.04247 11.4679C6.04013 11.4679 5.22694 12.2697 5.22694 13.2581C5.22694 13.6416 5.35306 14.0114 5.59496 14.3294L8.22369 17.5641C8.29035 17.6471 8.39549 17.6954 8.50408 17.6954C8.61267 17.6954 8.71438 17.6469 8.78448 17.5641L11.4692 14.2464C11.4762 14.236 11.4833 14.2257 11.4902 14.2188C11.6795 13.9284 11.781 13.5967 11.781 13.258C11.781 12.2697 10.9644 11.4679 9.96191 11.4679C9.36263 11.4679 8.83341 11.7513 8.50046 12.1937ZM11.0625 13.2582C11.0625 13.4552 11.0029 13.6487 10.8943 13.8251L8.50045 16.7798L6.15918 13.8974C6.01554 13.7073 5.93839 13.4861 5.93839 13.2581C5.93839 12.6602 6.43254 12.1728 7.03903 12.1728C7.64537 12.1728 8.14308 12.66 8.14308 13.2581C8.14308 13.4515 8.30424 13.6106 8.50062 13.6106C8.69683 13.6106 8.85815 13.4517 8.85815 13.2581C8.85815 12.6602 9.35229 12.1728 9.95879 12.1728C10.5684 12.173 11.0625 12.6602 11.0625 13.2582Z" fill={color ?? theme.colors.primary}/>
      <Path d="M8.50048 6.17359C9.948 6.17359 11.1221 5.01247 11.1221 3.58502C11.1221 2.15773 9.94456 1 8.50048 1C7.05295 1 5.8752 2.16112 5.8752 3.58502C5.8752 5.01231 7.05295 6.17359 8.50048 6.17359ZM8.50048 1.70494C9.55196 1.70494 10.4073 2.54827 10.4073 3.58506C10.4073 4.62524 9.55196 5.46857 8.50048 5.46857C7.44554 5.46857 6.59025 4.62524 6.59025 3.58506C6.59025 2.54827 7.44899 1.70494 8.50048 1.70494Z" fill={color ?? theme.colors.primary}/>
      <Path d="M15.9694 17.9203L11.1434 7.3968C11.0838 7.27244 10.9577 7.18944 10.8175 7.18944H5.87528C5.73164 7.18944 5.60192 7.27244 5.54575 7.40374L1.02775 17.9304C0.982215 18.041 0.992698 18.1654 1.05936 18.2621C1.12601 18.3624 1.23804 18.421 1.35728 18.421H5.07598L6.75492 22.772C6.80749 22.9102 6.94065 23 7.08789 23H10.1477C10.302 23 10.4386 22.9033 10.4877 22.7581L11.9352 18.4209H15.64C15.7627 18.4209 15.8749 18.3588 15.9414 18.2585C16.008 18.1585 16.0186 18.0309 15.9694 17.9203ZM11.6794 17.7165C11.5251 17.7165 11.3885 17.8132 11.3393 17.9584L9.89182 22.2955H7.33664L5.6577 17.9446C5.60513 17.8063 5.47197 17.7165 5.32473 17.7165H1.89692L6.11329 7.89451H10.5892L15.0896 17.7165L11.6794 17.7165Z" fill={color ?? theme.colors.primary}/>
      <Path d="M8.50046 12.1937C8.16749 11.7547 7.63832 11.4679 7.04247 11.4679C6.04013 11.4679 5.22694 12.2697 5.22694 13.2581C5.22694 13.6416 5.35306 14.0114 5.59496 14.3294L8.22369 17.5641C8.29035 17.6471 8.39549 17.6954 8.50408 17.6954C8.61267 17.6954 8.71438 17.6469 8.78448 17.5641L11.4692 14.2464C11.4762 14.236 11.4833 14.2257 11.4902 14.2188C11.6795 13.9284 11.781 13.5967 11.781 13.258C11.781 12.2697 10.9644 11.4679 9.96191 11.4679C9.36263 11.4679 8.83341 11.7513 8.50046 12.1937ZM11.0625 13.2582C11.0625 13.4552 11.0029 13.6487 10.8943 13.8251L8.50045 16.7798L6.15918 13.8974C6.01554 13.7073 5.93839 13.4861 5.93839 13.2581C5.93839 12.6602 6.43254 12.1728 7.03903 12.1728C7.64537 12.1728 8.14308 12.66 8.14308 13.2581C8.14308 13.4515 8.30424 13.6106 8.50062 13.6106C8.69683 13.6106 8.85815 13.4517 8.85815 13.2581C8.85815 12.6602 9.35229 12.1728 9.95879 12.1728C10.5684 12.173 11.0625 12.6602 11.0625 13.2582Z" stroke={color ?? theme.colors.primary} strokeWidth="0.3"/>
      <Path d="M8.50048 6.17359C9.948 6.17359 11.1221 5.01247 11.1221 3.58502C11.1221 2.15773 9.94456 1 8.50048 1C7.05295 1 5.8752 2.16112 5.8752 3.58502C5.8752 5.01231 7.05295 6.17359 8.50048 6.17359ZM8.50048 1.70494C9.55196 1.70494 10.4073 2.54827 10.4073 3.58506C10.4073 4.62524 9.55196 5.46857 8.50048 5.46857C7.44554 5.46857 6.59025 4.62524 6.59025 3.58506C6.59025 2.54827 7.44899 1.70494 8.50048 1.70494Z" stroke={color ?? theme.colors.primary} strokeWidth="0.3"/>
      <Path d="M15.9694 17.9203L11.1434 7.3968C11.0838 7.27244 10.9577 7.18944 10.8175 7.18944H5.87528C5.73164 7.18944 5.60192 7.27244 5.54575 7.40374L1.02775 17.9304C0.982215 18.041 0.992698 18.1654 1.05936 18.2621C1.12601 18.3624 1.23804 18.421 1.35728 18.421H5.07598L6.75492 22.772C6.80749 22.9102 6.94065 23 7.08789 23H10.1477C10.302 23 10.4386 22.9033 10.4877 22.7581L11.9352 18.4209H15.64C15.7627 18.4209 15.8749 18.3588 15.9414 18.2585C16.008 18.1585 16.0186 18.0309 15.9694 17.9203ZM11.6794 17.7165C11.5251 17.7165 11.3885 17.8132 11.3393 17.9584L9.89182 22.2955H7.33664L5.6577 17.9446C5.60513 17.8063 5.47197 17.7165 5.32473 17.7165H1.89692L6.11329 7.89451H10.5892L15.0896 17.7165L11.6794 17.7165Z" stroke={color ?? theme.colors.primary} strokeWidth="0.3"/>
    </Svg>
  );
};

export default Maternity;
