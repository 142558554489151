import React from 'react';

import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/subsidizedMedicines/desktop.png';
import mobileBackground from '../../assets/images/subsidizedMedicines/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { SubsidyFoMedicinesIcon } from '~/components/Icons/SubsidyFoMedicines';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const SubsidizedMedicines = (): JSX.Element => {
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        title: 'Subsídio em Medicamentos',
        titleStartContent: <SubsidyFoMedicinesIcon />,
        onBack: () => {
          navigation.navigate('PharmacyBenefit');
        },
      }}>
      <View>
        <Typography variant="body2">
          Esse benefício foi criado para auxiliar no seu tratamento. Com ele você tem um valor
          disponível para{' '}
          <Typography variant="bodyHighlight2">
            retirada de medicamentos gratuitamente nas farmácias parceiras.
          </Typography>{' '}
          O valor disponível e a quantidade de utilizações permitidas são variáveis de acordo com
          seu plano. Verifique sempre os termos e condições de uso do produto.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Como usar?">
          <View className="flex-row">
            <Typography variant="body2">1.</Typography>
            <Typography variant="body2" className="mb-8 ml-4 flex-1">
              Com receita médica em mãos, entre em contato com a Central de Atendimento ou WhatsApp
              e envie a receita médica;
            </Typography>
          </View>
          <View className="flex-row">
            <Typography variant="body2">2.</Typography>
            <Typography variant="body2" className="mb-8 ml-4 flex-1">
              Aguarde 48 horas para a validação da receita e liberação do valor no sistema;
            </Typography>
          </View>
          <View className="flex-row">
            <Typography variant="body2">3.</Typography>
            <Typography variant="body2" className="mb-8 ml-4 flex-1">
              Dirija-se a uma das farmácias da rede credenciada para utilizar seu benefício.
            </Typography>
          </View>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para acionamento ou dúvidas, ligue para a Central de Atendimento:
        </Typography>
      </View>

      <Footer variant="simple-phone-footer" showButtonOnMobile />
    </PageWithCard>
  );
};
