import React, { useState } from 'react';
import { View } from 'react-native';
import { MapViewListHeader } from './MapViewListHeader';
import { usePageWithCardSettings } from '~/components/@tem-ui/Stepper';
import { Map } from '~/components/@hello-ui/Map';
import { LocationCardList } from '~/components/LocationCardList';
import { ListItem } from '~/components/MapViewList/MapViewListDTO';

interface MapViewListProps<TData> {
  title: string;
  onClickCard?: (item: ListItem<TData>) => void;
  data?: ListItem<TData>[];
  hasButton?: boolean;
  showHomeButton?: boolean;
  showNextPageButton?: boolean;
  handleNextPage?: () => void;
  onHomePress?: () => void;
  loading?: boolean;
  mapId: string;
}

enum DisplayType {
  List = 'list',
  Map = 'map',
}

export const MapViewList = <TData,>({
  title,
  data = [],
  loading,
  onClickCard,
  handleNextPage,
  mapId,
  showNextPageButton = false,
  hasButton = true,
}: MapViewListProps<TData>): JSX.Element => {
  usePageWithCardSettings({
    scrollEnabled: false,
    overrideMaxBodyWidth: '100%',
    customPadding: { top: 0, left: 0, right: 0, bottom: 0 },
  });

  const [displayType, setDisplayType] = useState(DisplayType.List);

  const switchDisplayType = () => {
    setDisplayType((displayType) =>
      displayType === DisplayType.List ? DisplayType.Map : DisplayType.List,
    );
  };

  return (
    <>
      <MapViewListHeader
        location={title}
        currentDisplayType={displayType}
        handleDisplayType={switchDisplayType}
      />

      {displayType === DisplayType.List ? (
        <View className="flex-1 pt-64 mobile:pt-80">
          <LocationCardList
            loading={loading}
            data={data}
            onPress={onClickCard}
            showNextPageButton={showNextPageButton}
            handleNextPage={handleNextPage}
            hasButton={hasButton}
          />
        </View>
      ) : (
        <View className="flex-1">
          <Map
            loading={loading}
            data={data}
            onPress={onClickCard}
            clickEnabled={hasButton}
            mapId={mapId}
          />
        </View>
      )}
    </>
  );
};
