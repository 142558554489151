import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';

export const Container = styled(Wrapper)``;

export const WrapperPage = styled.View`
  width: 100%;
  margin: 0 auto;
  align-items: center;
`;

export const WrapperSearch = styled.View`
  width: 100%;
  max-width: 600px;
  height: 56px;
  margin-bottom: 48px;
`;

export const WrapperSchedule = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 0;
    padding: 16px;
    width: 100%;
  `}
`;

export const WrapperScheduleInfo = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperScheduleForm = styled.View`
  flex-direction: column;
`;

export const WrapperInput = styled.View`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;

export const InputField = styled.TouchableOpacity`
  height: 58px;
  top: 22px;
  margin-bottom: -58px;
`;

export const DeleteFile = styled.TouchableOpacity``;

export const WrapperSteps = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
  `}
`;

export const WrapperStepsRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-horizontal: -7px;
`;

export const WrapperStep = styled.View<{ current: boolean }>`
  background-color: ${({ theme: { colors }, current }) => (!current ? colors.blue20 : colors.blue)};
  height: 2px;
  border-radius: 2px;
  flex: 1;
  margin-horizontal: 7px;
`;

export const WrapperScheduleDays = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  margin-horizontal: -12px;
`;

export const WrapperDay = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  padding: 24px 16px;
  flex: 1 1 100%;
  border-radius: 4px;
  margin-horizontal: 12px;
  margin-bottom: 24px;
`;

export const WrapperDayHeader = styled.Pressable`
  flex-direction: row;
  align-items: center;
`;

export const WrapperDayPeriods = styled.View`
  flex-direction: row;
  align-items: center;
  margin-horizontal: -4px;
  margin-top: 24px;
`;

export const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 15px auto 0;
`;

export const WrapperScheduleExam = styled.View`
  align-items: center;
`;

export const WrapperScheduleFile = styled.View`
  align-items: center;
  justify-content: center;
`;

export const WrapperTime = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-horizontal: 4px;
  height: 48px;
`;

export const NewUploadFile = styled.TouchableOpacity`
  height: auto;
  margin: 25px auto 25px;
  align-items: center;
`;

export const WrapperAlert = styled.View`
  align-items: center;
`;

export const WrapperDrawer = styled.View`
  flex: 1;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
`;

export const DrawerItems = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  padding: 15px 5px 15px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black8};
`;

export const SpaceItem = styled.View`
  width: 10px;
`;

const WrapperVariations = {
  web: css`
    height: 74px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    height: 64px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: ${({ theme: { colors } }) => colors.black};
    shadow-offset: ${Platform.OS === 'ios' ? '{width: 5, height: 5}' : '{width: 1, height: 2}'};
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
  `,
};

export const WrapperScheduleType = styled.View<{
  redShadow?: boolean;
}>`
  background-color: ${({ theme: { colors }, redShadow }) =>
    redShadow ? colors.redlight : colors.white};
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 16px 24px 24px;

  ${({ theme: { isMobile } }) =>
    isMobile ? WrapperVariations['mobile'] : WrapperVariations['web']}
`;

export const File = styled.View`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 240;
  `}
`;
