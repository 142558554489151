import React from 'react';
import { Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};
export const BenefitsDigitalFemaleAssistanceIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 22 22" fill="none">
      <Path
        d="M14.8559 18.7964C14.1392 20.1204 12.7381 21 11.1271 21H5.23729C2.89711 21 1 19.1027 1 16.7627V5.23733C1 2.89689 2.89711 1 5.23729 1H11.1271C12.7302 1 14.136 1.89023 14.8559 3.20356M9.26267 4.43199H7.14404M16.2542 6.212C13.6271 6.212 11.4834 8.37243 11.5087 11.004L11.5084 15.7036L11.5851 15.6267C12.2539 14.9578 13.2753 14.7898 14.1221 15.2111C14.7559 15.5267 15.4721 15.7036 16.2331 15.7036C18.8453 15.7044 20.9747 13.6164 20.9998 11.004C21.025 8.37243 18.9237 6.212 16.2542 6.212Z"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.2828 9.70497L16.25 9.72899L16.2172 9.70497C15.7821 9.38669 15.1947 9.44421 14.8207 9.84194C14.3931 10.2969 14.3931 11.0372 14.8207 11.4921L16.2381 12.9999L16.2499 12.9873L16.2618 13L17.6793 11.4921C18.1069 11.0372 18.1069 10.2969 17.6793 9.84194C17.3053 9.44434 16.718 9.38657 16.2828 9.70497ZM17.4113 11.2072L16.2618 12.4298L16.25 12.4173L16.2381 12.43L15.0884 11.2072C14.8086 10.9094 14.8086 10.4248 15.0884 10.127C15.2238 9.98285 15.4042 9.90353 15.5962 9.90353C15.7883 9.90353 15.9686 9.98285 16.104 10.1268L16.2498 10.282L16.3956 10.1268C16.6757 9.82913 17.1313 9.82926 17.4111 10.1268C17.6912 10.4248 17.6913 10.9094 17.4113 11.2072Z"
        fill="white"
        stroke="white"
        strokeWidth="0.833333"
      />
    </Svg>
  );
};
