import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input, PageWithCard, Typography, useSweetAlert } from '~/components/@hello-ui';
import { trackingEventType } from '~/contexts/tracking/types';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';

const schema = yup.object().shape({
  currentPassword: yup.string().required('Campo obrigatório'),
  password: yup
    .string()
    .min(8, 'Sua senha precisa ter no mínimo 8 caracteres')
    .required('Campo obrigatório'),
  confirmPassword: yup
    .string()
    .min(8, 'Sua senha precisa ter no mínimo 8 caracteres')
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'Por favor, verificar se as senhas estão iguais'),
});

export const ProfilePassword = () => {
  const navigation = useNavigation();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const trackingApi = useTracking();
  const api = useApi();

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleGoBack = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Profile');
  };

  const password = watch('password');

  useEffect(() => {
    if (password) {
      trigger('confirmPassword');
    }
  }, [password]);

  const updatePassword = async (values) => {
    try {
      await api.updatePassword(values.currentPassword, values.password);
      void trackingApi.tracking.addMovement({
        event: trackingEventType.PasswordChanged,
      });

      reset();

      showSweetAlert('Tudo certo!', 'Sua senha foi atualizada', 'success', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            onPress: () => {
              handleGoBack();
              hideSweetAlert();
            },
          },
        ],
      });
    } catch (err) {
      console.error(err);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível atualizar sua senha.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Senha de Acesso',
        onBack: handleGoBack,
      }}>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="body2">
          Aqui você pode atualizar a sua senha de acesso à sua conta no aplicativo e site
        </Typography>
      </View>
      <View className="mb-24">
        <Typography variant="helperText">Mínimo 8 caracteres entre letras e números</Typography>
      </View>
      <View className="mb-8">
        <Controller
          name="currentPassword"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              isPassword
              value={value}
              onChangeText={onChange}
              label="Senha Atual"
              placeholder="Digite"
              error={error?.message ?? ''}
            />
          )}
        />
      </View>
      <View className="mb-8">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              isPassword
              value={value}
              onChangeText={onChange}
              label="Nova senha"
              placeholder="Digite"
              error={error?.message ?? ''}
            />
          )}
        />
      </View>
      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            isPassword
            value={value}
            onChangeText={onChange}
            label="Confirme a nova senha"
            placeholder="Digite"
            error={error?.message ?? ''}
          />
        )}
      />
      <View className="mt-auto min-h-[40px] mobile:min-h-[24px]" />
      <Button
        variant="primary"
        onPress={handleSubmit(updatePassword)}
        disabled={!isValid || isSubmitting}>
        Atualizar senha
      </Button>
    </PageWithCard>
  );
};
