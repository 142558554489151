import React from 'react';

import { View } from 'react-native';
import { CardButton } from '../CardButton';

import { CardSelectProps } from './types';

export const CardSelect = ({
  icon,
  title,
  onPress,
  description,
  iconSideOfTitle,
}: CardSelectProps) => {
  return (
    <View className="mb-[8px]">
      <CardButton
        variant="xlarge"
        icon={icon}
        title={title}
        onPress={onPress}
        description={description}
        iconSideOfTitle={iconSideOfTitle}
      />
    </View>
  );
};
