import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { ActivityIndicator } from 'react-native';
import { LoaderProps } from './types';
import * as S from './styles';

export const Loader = ({ color, size = 10, variant = 'dot-loader' }: LoaderProps): JSX.Element => {
  return variant === 'dot-loader' ? (
    <PulseLoader color={color} speedMultiplier={0.5} size={size} />
  ) : (
    <S.WrapperLoading>
      <ActivityIndicator color={color ?? '#000000'} />
    </S.WrapperLoading>
  );
};
