import * as React from 'react';
import Svg, { G, Path, Circle, Defs, ClipPath } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const QuestionMarkIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <G clipPath="url(#a)">
        <Path
          fill={color ?? theme.colors.primary}
          d="M12.995 16.06c0-.49-.414-.865-.903-.865a.915.915 0 0 0-.902.902c0 .49.413.903.902.903a.908.908 0 0 0 .64-.263.907.907 0 0 0 .263-.677ZM13.564 12.102l.273-.275c.702-.628 1.482-1.334 1.482-2.276 0-.667-.312-1.334-.858-1.766-.585-.51-1.366-.785-2.224-.785C10.755 7 9.156 7.863 9 9.512c0 .118 0 .157.156.157.39.039.78.078 1.17.157.078 0 .156.039.195.039.078 0 .117 0 .195-.235.234-.707.741-1.139 1.443-1.139.546 0 1.053.275 1.248.668.118.274.196.667-.194 1.216-.195.275-.507.51-.78.707-.352.274-.703.51-.898.942-.273.588-.273 1.373-.234 2.08 0 .118.04.118.117.118h1.327c.117 0 .155-.04.155-.04.04-.039.04-.078.04-.157 0-.353 0-.706.039-1.02.039-.432.312-.667.585-.903Z"
        />
        <Circle cx={12} cy={12} r={11} stroke={color ?? theme.colors.primary} strokeWidth={1.3} />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default QuestionMarkIcon;
