import styled from 'styled-components/native';

export const WrapperCard = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const Crads = styled.View`
  margin: 0 20px 20px 0;
`;

export const CardContent = styled.View`
  height: 100%;
  padding: 15px;
  align-items: flex-start;
  justify-content: space-around;
  text-align: justify;
`;

export const WrapperContent = styled.View`
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
`;

export const WrapperText = styled.View`
  width: 116px;
  justify-content: flex-end;
  width: 100%;
`;

export const WrapperTextCard = styled.View`
  width: 275px;
`;

export const TextCard = styled.Text`
  width: 275px;
  font-size: 16px;
  font-weight: 400,
  color: ${({ theme: { colors } }) => colors.black70};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 0px;
  `}
`;

export const WrapperCards = styled.View`
  margin-left: 80px;
  margin-right: 10px;
  flex-direction: row;
  width: 100%;
  max-width: 1020px;
  padding-horizontal: 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 16px;
  `}
`;
