import React, { useEffect } from 'react';
import * as publicIp from 'public-ip';
import { Linking } from 'react-native';
import { useSweetAlert } from '../../../components/@tem-ui';
import * as S from './styles';
import { useTemPay } from '~/hooks/temPay';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import PrivacyIcon from '~/components/Icons/Privacy';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { AuthService } from '~/auth/auth-service';

export const PrivacyPolicy: React.FC = () => {
  const api = useApi();

  const { card } = useTemPay();
  const { user, addressFirstAccess } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { tracking } = useTracking();

  const acceptPolicyPrivacy = async () => {
    try {
      const ip = await publicIp.v4();
      const response = await api.acceptPrivacyPolicy(card?.numero_cartao, user.cpf, ip);

      if (response.status === 200) {
        hideSweetAlert();
        void tracking.addMovement({
          event: trackingEventType.ClickedOnAcceptedThePrivacyPolicy,
        });
      }
    } catch (err) {
      hideSweetAlert();
      //showSweetAlert('Ops, algo deu errado', 'Falha ao aceitar termo', 'error', true, false);
    }
  };

  const returnPrivacyPolicy = async (update: boolean) => {
    const { data } = await api.returnPrivacyPolicy();
    const privacyPolicyLink = data[0].url;

    if (privacyPolicyLink && user && !addressFirstAccess) {
      showSweetAlert(
        'Política de Privacidade',
        <S.Description>
          {update ? (
            <>
              <S.Text>
                Queremos garantir a confiança e transparência de nossa comunicação com você, por
                isso, para continuar é necessário estar de acordo com a{' '}
                <S.TextLink onPress={() => void Linking.openURL(privacyPolicyLink)}>
                  atualização de nossa Política de Privacidade.
                </S.TextLink>
              </S.Text>
            </>
          ) : (
            <>
              <S.Text>
                Queremos garantir a confiança e transparência de nossa comunicação com você, por
                isso, para continuar navegando é necessário estar de acordo com nossa{' '}
                <S.TextLink onPress={() => void Linking.openURL(privacyPolicyLink)}>
                  Política de Privacidade.
                </S.TextLink>
              </S.Text>
            </>
          )}
        </S.Description>,
        'success',
        false,
        false,
        {
          touchOutside: false,
          icon: <PrivacyIcon />,
          iconTitle: update ? 'Nossa Política foi atualizada' : 'Comunicação Transparente',
          color: 'primary',
          buttons: [
            {
              text: 'Li e Concordo',
              color: 'white',
              background: 'primary',
              testID: 'accept-button',
              callback: () => void acceptPolicyPrivacy(),
            },
          ],
        },
      );
    }
  };

  const hasPrivacyPolicyUpdate = async () => {
    const { data } = await api.hasPrivacyPolicyUpdate(user.cpf);

    void returnPrivacyPolicy(data[0].atualizacao);
  };

  const hasPrivacyPolicy = async () => {
    try {
      await api.hasPrivacyPolicy(card.numero_cartao, user.cpf);
    } catch (error) {
      void hasPrivacyPolicyUpdate();
    }
  };

  useEffect(() => {
    if (AuthService.isFullAuthenticated() && card) {
      void hasPrivacyPolicy();
    }
  }, [user, addressFirstAccess, card]);

  return <S.PrivacyWrapper testID="privacy-policy-component"></S.PrivacyWrapper>;
};
