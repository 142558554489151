import styled from 'styled-components/native';

export const BackButton = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.textDisabled};
  width: 40px;
  height: 40px;
  border-radius: 20px;

  justify-content: center;
  align-items: center;

  z-index: 1;
`;
