import React from 'react';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { TouchableOpacity } from 'react-native';
import { Listing, Typography } from '~/components/@hello-ui';
import { PickerOptionsEnum } from '~/components/@tem-ui/Uploader/types';

type DialogOptionsType = {
  name: string;
  icon: string;
  onPress: () => void;
};

type SelectFilesProps = {
  onAddFile: (type: PickerOptionsEnum) => void;
};

export const SelectFiles = ({ onAddFile }: SelectFilesProps) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="title">Como prefere enviar o pedido?</Typography>
      <View className="mt-24">
        <Listing
          useSeparator
          hideBackToTop
          separatorColor={theme.colors.black5}
          loading={false}
          data={[
            {
              name: 'Câmera',
              icon: 'camera',
              onPress: () => onAddFile(PickerOptionsEnum.Camera),
            },
            {
              name: 'Galeria',
              icon: 'image',
              onPress: () =>
                onAddFile(
                  Platform.OS === 'ios' ? PickerOptionsEnum.Gallery : PickerOptionsEnum.Archiver,
                ),
            },
          ]}
          containerStyle={{ height: 160 }}
          renderItem={({ item }: { item: DialogOptionsType }) => {
            const { name, icon, onPress } = item;

            return (
              <TouchableOpacity onPress={onPress} className="h-[70px] items-center justify-center">
                <View className="h-full w-full flex-row items-center gap-16">
                  <View className="h-24 w-24 items-center justify-center">
                    <FeatherIcon name={icon} color={theme.colors.primary} size={18} />
                  </View>
                  <Typography variant="body2">{name}</Typography>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </>
  );
};
