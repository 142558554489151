import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const AnimatedDot = styled(Animated.View)`
  padding: 2px;
`;

export const WrapperLoading = styled.View`
  justify-content: center;
  align-items: center;
`;
