import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const ProfileIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <G id="Group 1565">
        <Path
          id="Vector"
          d="M0.705822 13.9172C0.243545 14.7462 0.000847727 15.6757 0 16.6206V18.3702C0.000581245 18.8025 0.175973 19.217 0.487672 19.5225C0.799371 19.8281 1.22191 19.9998 1.66252 20H18.3389C18.7793 19.9994 19.2015 19.8275 19.5128 19.522C19.8242 19.2165 19.9994 18.8023 20 18.3702V16.6206C20 15.73 19.7843 14.8521 19.3707 14.0594C18.957 13.2666 18.3573 12.5816 17.6209 12.0609C16.8845 11.5401 16.0326 11.1985 15.1352 11.0643C14.2379 10.93 13.3209 11.0068 12.4598 11.2885L11.4131 11.6308C10.8776 11.8062 10.3127 11.8793 9.74912 11.8461C9.35322 11.8252 8.9619 11.7528 8.58543 11.6308L7.53877 11.2885C6.26613 10.8725 4.88507 10.9077 3.63619 11.388C2.38731 11.8684 1.34978 12.7634 0.704359 13.9172H0.705822ZM7.1928 12.3097L8.2402 12.652C8.70847 12.8055 9.1955 12.8969 9.68841 12.924H9.72206C10.4132 12.9587 11.1048 12.8662 11.7613 12.6513L12.8087 12.309C13.5048 12.0814 14.2462 12.0193 14.9716 12.1279C15.697 12.2365 16.3857 12.5127 16.981 12.9337C17.5763 13.3547 18.0611 13.9084 18.3955 14.5493C18.7299 15.1902 18.9043 15.8999 18.9043 16.6199V18.3695C18.9043 18.4423 18.8897 18.5144 18.8613 18.5817C18.8329 18.649 18.7912 18.7101 18.7387 18.7615C18.6862 18.813 18.6238 18.8538 18.5553 18.8816C18.4867 18.9094 18.4132 18.9236 18.3389 18.9235H1.66252C1.51302 18.9233 1.36969 18.865 1.26398 18.7613C1.15826 18.6575 1.09879 18.5169 1.0986 18.3702V16.6206C1.09862 15.9006 1.27301 15.1909 1.6074 14.55C1.94179 13.9091 2.4266 13.3554 3.0219 12.9344C3.61719 12.5134 4.30592 12.2372 5.03134 12.1286C5.75676 12.02 6.4981 12.0821 7.19427 12.3097H7.1928Z"
          fill={color ?? theme.colors.primary}
        />
        <Circle
          id="Ellipse 86"
          cx="10"
          cy="5"
          r="4.45"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.1"
        />
      </G>
    </Svg>
  );
};

export default ProfileIcon;
