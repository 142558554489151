import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const SendIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 40} height={height ?? 40} fill="none">
      <Path
        stroke={color ?? theme.colors.primary}
        strokeWidth={2}
        d="m8.72 33.112 22.694-12.968a.167.167 0 0 0 0-.289L20 13.333 8.72 6.888a.167.167 0 0 0-.24.2l4.5 12.857a.166.166 0 0 1 0 .11l-4.5 12.857a.167.167 0 0 0 .24.2Z"
      />
    </Svg>
  );
};

export default SendIcon;
