import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({
  size,
  color = 'currentColor',
  width: iconWidth = 24,
  height: iconHeight = 24,
  ...rest
}: IconProps) {
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
      <Path
        fill={color}
        d="M12 .042a9.992 9.992 0 00-9.981 9.98c0 2.57 1.99 6.592 5.915 11.954a5.034 5.034 0 008.132 0c3.925-5.362 5.915-9.384 5.915-11.954A9.992 9.992 0 0012 .042zM12 14a4 4 0 114-4 4 4 0 01-4 4z"
      />
    </Svg>
  );
}

export default Icon;
