import React from 'react';
import { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useWindowSize } from '~/hooks/useWindowSize';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

interface PageWithCardPaddingProps {
  hasPadding?: boolean;
}

// DEV remove
export const BodyPadding: React.FC<PropsWithChildren<PageWithCardPaddingProps>> = ({
  children,
  hasPadding = true,
}) => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { isDeepLink } = DeepLink.useParams();

  return (
    <View
      className={`${
        hasPadding
          ? (windowSize.width < 1138 && (theme.isDesktop || theme.isTablet)) || isDeepLink
            ? 'p-16 pt-24'
            : 'p-16 pt-24 tablet:p-64 tablet:pt-40 desktop:p-92 desktop:pt-40'
          : ''
      } h-full`}>
      {children}
    </View>
  );
};
