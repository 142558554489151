import React, { useCallback } from 'react';
import IconFa from 'react-native-vector-icons/dist/FontAwesome';
import IconIo from 'react-native-vector-icons/dist/Ionicons';
import IconMa from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';
import { NetworkTypes } from '../types';
import { BenefitsLocalization } from './BenefitsLocalization';
import { BenefitsSpecialty } from './BenefitsSpecialty';
import { BenefitsUploadExam } from './BenefitsUploadExam';
import { SelectedSpecialty } from './SelectedSpecialty';
import { trackingEventType } from '~/contexts/tracking/types';
import { TypesEnum } from '~/enums/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { useAppointment } from '~/hooks/appointment';
import { ScheduleDate } from '~/screens/ScheduleAppointment/components/ScheduleDate';
import { SelectDependents } from '~/screens/ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleSpecialty } from '~/screens/ScheduleAppointment/components/ScheduleSpecialty';

export const benefitsTypes = (): NetworkTypes[] => {
  const theme = useTheme();
  const { checkIsEnabledFeature } = useAuth();

  const { submitAppointment } = useAppointment();

  const checkAppoitmentB2C = useCallback(() => {
    return [
      <BenefitsSpecialty key="BenefitsSpecialty" />,
      <BenefitsLocalization key="BenefitsLocalization" />,
      <SelectedSpecialty key="SelectedSpecialty" />,
      <ScheduleDate
        key="ScheduleDate"
        type={TypesEnum.Appointment}
        validateDependents={true}
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInMedicalConsultationaAndTherapy);
        }}
      />,
      <SelectDependents
        key="SelectDependent"
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInMedicalConsultationaAndTherapy);
        }}
      />,
    ];
  }, []);

  const checkExamtB2C = useCallback(() => {
    return [
      <BenefitsLocalization key="BenefitsLocalization" />,
      <SelectedSpecialty key="SelectedSpecialty" />,
      <BenefitsUploadExam key="BenefitsUploadExam" stepperData={presentialAppointment} />,
      <ScheduleDate
        key="ScheduleDate"
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInExamsAndProcedures);
        }}
        type={TypesEnum.Exam}
        validateDependents={true}
      />,
      <SelectDependents
        key="SelectDependent"
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInExamsAndProcedures);
        }}
      />,
    ];
  }, []);

  const presentialAppointment = {
    icon: <IconFa name="stethoscope" size={24} color={theme?.colors?.primary} />,
    title: 'Consulta Presencial',
    description: 'Solicitar agendamento de consulta',
    ref: 'presential',
    disabled: !checkIsEnabledFeature('health_network'),
    visible: false,
    visibilitySteps: false,
    steps: [
      <ScheduleSpecialty key="ScheduleSpecialty" />,
      <ScheduleDate
        key="ScheduleDate"
        type={TypesEnum.Appointment}
        validateDependents={true}
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInPresentialAppointment);
        }}
      />,
      <SelectDependents
        key="SelectDependent"
        onSelect={() => {
          submitAppointment(trackingEventType.RequestedInPresentialAppointment);
        }}
      />,
    ],
    tracking: trackingEventType.ClickedOnPresentialAppointment,
  };

  return [
    presentialAppointment,
    {
      icon: <IconFa name="stethoscope" size={24} color={theme?.colors?.primary} />,
      title: 'Clínicas e Consultórios',
      description: 'Consultas médicas e terapia',
      ref: 'clinic',
      disabled: !checkIsEnabledFeature('health_network'),
      visible: true,
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnMedicalConsultationaAndTherapy,
      steps: checkAppoitmentB2C(),
    },
    {
      icon: <IconIo name="medkit-outline" size={24} color={theme?.colors?.primary} />,
      title: 'Laboratórios',
      description: 'Exames e Procedimentos',
      ref: 'laboratory',
      disabled: !checkIsEnabledFeature('health_network'),
      visible: true,
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnExamsAndProcedures,
      steps: checkExamtB2C(),
    },
    {
      icon: <IconIo name="medkit-outline" size={24} color={theme?.colors?.primary} />,
      title: 'Laboratórios',
      description: 'Exames e Procedimentos sem pedido médico',
      ref: 'laboratory',
      disabled: true,
      visible: false,
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnExamsAndProceduresWithoutMedicalOrder,
      steps: [
        <BenefitsSpecialty key="BenefitsSpecialty" />,
        <ScheduleDate
          key="ScheduleDate"
          type={TypesEnum.Appointment}
          validateDependents={true}
          onSelect={() => {
            submitAppointment(trackingEventType.RequestedInExamsAndProceduresWithoutMedicalOrder);
          }}
        />,
        <SelectDependents
          key="SelectDependent"
          onSelect={() => {
            submitAppointment(trackingEventType.RequestedInExamsAndProceduresWithoutMedicalOrder);
          }}
        />,
      ],
    },
    {
      icon: <IconIo name="medkit-outline" size={24} color={theme?.colors?.primary} />,
      title: 'Laboratórios',
      description: 'Exames e Procedimentos sem pedido médico por vídeo',
      ref: 'laboratory',
      disabled: true,
      visible: false,
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnExamsAndProceduresForVideoWithoutDoctorRequest,
      steps: [
        <BenefitsSpecialty key="BenefitsSpecialty" />,
        <ScheduleDate
          key="ScheduleDate"
          type={TypesEnum.Video}
          validateDependents={true}
          onSelect={() => {
            submitAppointment(
              trackingEventType.RequestedInExamsAndProceduresForVideoWithoutDoctorRequest,
            );
          }}
        />,
        <SelectDependents
          key="SelectDependent"
          onSelect={() => {
            submitAppointment(
              trackingEventType.RequestedInExamsAndProceduresForVideoWithoutDoctorRequest,
            );
          }}
        />,
      ],
    },
    {
      icon: <IconMa name="tooth-outline" size={24} color={theme?.colors?.primary} />,
      title: 'Clínicas de Dentistas',
      description: 'Consultas e procedimentos odontológicos',
      ref: 'dentist',
      disabled: !checkIsEnabledFeature('dentistry'),
      visible: checkIsEnabledFeature('dentistry'),
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOnDentalConsultationsAndProcedures,
      steps: [
        <BenefitsLocalization key="BenefitsLocalization" />,
        <SelectedSpecialty key="SelectedSpecialty" />,
        <ScheduleDate
          key="ScheduleDate"
          type={TypesEnum.Appointment}
          validateDependents={true}
          onSelect={() => {
            submitAppointment(trackingEventType.RequestedInDentalConsultationsAndProcedures);
          }}
        />,
        <SelectDependents
          key="SelectDependent"
          onSelect={() => {
            submitAppointment(trackingEventType.RequestedInDentalConsultationsAndProcedures);
          }}
        />,
      ],
    },
  ];
};
