import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import * as S from '../styles';
import { searchZipCodeType } from '../types';
import { Card, Button } from '~/components/@tem-ui';
import { Spacing } from '~/components/Spacing';
import { useApi } from '~/hooks/api';
import { useAppointment } from '~/hooks/appointment';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { StyledDefaultTheme } from '~/components/@hello-ui';
export const SelectedSpecialty = (): JSX.Element => {
  const api = useApi();

  const { stepData, nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();

  const [loading, setLoading] = useState<boolean>(false);
  const [clinics, setClinics] = useState([]);
  const [loadingClinics, setLoadingClinics] = useState<boolean>(true);

  const [pageSize] = useState<number>(4);
  const [page, setPage] = useState<number>(1);
  const [list, setList] = useState([]);

  const handleClinics = useCallback(
    async (specialty: string) => {
      setClinics([]);

      const neighborhood = appointment?.address?.neighborhood ?? '';
      const state = appointment?.address?.stateInitials ?? '';
      const city = appointment?.address?.city ?? '';

      await api
        .searchZipCode(`${neighborhood}, ${city} / ${state}`)
        .then(async (response: searchZipCodeType) => {
          await api
            .searchNetwork({
              latitude: response.data.lat.toString(),
              longitude: response.data.lng.toString(),
              distancia: 30,
              especialidadeMedica: specialty,
            })
            .then((network) => {
              setClinics(network?.data);
              paginate(network?.data);
              setLoading(false);
              setLoadingClinics(false);
            });
        })
        .catch(() => setLoading(true));
    },
    [clinics],
  );

  useEffect(() => {
    if (stepData?.specialty !== undefined) {
      setAppointment({
        ...appointment,
        specialty: { id: stepData?.specialty?.id, name: stepData?.specialty?.title },
      });

      void handleClinics(stepData?.specialty?.title);
    } else {
      void handleClinics(appointment?.specialty?.name ?? '');
    }
  }, []);

  const paginate = (allClinics?: any[]) => {
    const listClinics = allClinics ?? clinics;

    if (listClinics.length > 0) {
      setList(listClinics.slice((page - 1) * pageSize, page * pageSize));
    }
  };

  useEffect(() => {
    paginate();
  }, [page, pageSize]);

  const previousPage = () => {
    setPage(page > 1 ? page - 1 : 1);
  };

  const nextPage = () => {
    const total = clinics.length / pageSize;
    setPage(total > page ? page + 1 : page);
  };

  const firstPage = () => {
    setPage(1);
  };

  const lastPage = () => {
    const total = clinics.length / pageSize;
    setPage(total);
  };

  const Item = ({ data }) => {
    return (
      <S.WrapperItem>
        <S.WrapperItemTitle>{data.credenciado[0]['NOME FANTASIA']}</S.WrapperItemTitle>
        <S.WrapperItemAddress>
          {`${data.credenciado[0]['LOGRADOURO']}, ${data.credenciado[0]['NUMERO']} - ${data.credenciado[0]['BAIRRO']}, ${data.credenciado[0]['MUNICIPIO']} - ${data.credenciado[0]['ESTADO']}, ${data.credenciado[0]['CEP']} `}
        </S.WrapperItemAddress>
      </S.WrapperItem>
    );
  };

  const renderItem = ({ item }) => <Item data={item} />;

  return (
    <>
      <S.WrapperSchedule>
        <S.WrapperTop>
          {loading ? (
            <S.WrapperLoading>
              <S.Loading />
            </S.WrapperLoading>
          ) : (
            <>
              <S.WrapperCard>
                <Card width={'100%'}>
                  <S.WrapperContentCard>
                    <S.WrapperCardIcon>
                      <S.WrapperBackgroundIcon>{stepData.icon}</S.WrapperBackgroundIcon>
                    </S.WrapperCardIcon>
                    <S.WrapperCardText>
                      <S.WrapperTitleCard>{appointment?.specialty?.name ?? ''}</S.WrapperTitleCard>
                      <S.WrapperSubTitleCard>
                        {`${appointment?.address?.city ?? ''} - ${
                          appointment?.address?.state ?? ''
                        }`}
                      </S.WrapperSubTitleCard>
                    </S.WrapperCardText>
                    <Spacing top={Platform.OS === 'web' ? 75 : 35}>
                      <Button
                        variant="primary"
                        title="Solicitar Agendamento"
                        onPress={() => {
                          nextStep();
                        }}
                      />
                    </Spacing>
                    <Spacing top={Platform.OS === 'web' ? 15 : 24}>
                      <S.WrapperText>
                        Ao solicitar um agendamento, nossa Central te indica as melhores clínicas,
                        preços e datas disponíveis.
                      </S.WrapperText>
                    </Spacing>
                  </S.WrapperContentCard>
                </Card>
              </S.WrapperCard>

              {clinics.length > 0 ? (
                <>
                  <S.WrapperTitle>
                    <Icon name="map-pin" size={20} /> Algumas unidades na sua região
                  </S.WrapperTitle>
                  <S.Divider />
                  <S.WrapperList
                    data={list}
                    renderItem={renderItem}
                    keyExtractor={({ clinic }, idx) => idx.toString()}
                    ItemSeparatorComponent={S.Divider}
                  />

                  <S.WrapperControl>
                    <S.WrapperControlLeftList>
                      <S.ControlListFirst onPress={() => firstPage()} disabled={page === 1}>
                        <Icon
                          name="chevrons-left"
                          size={30}
                          color={
                            page === 1
                              ? StyledDefaultTheme.colors.black16
                              : StyledDefaultTheme.colors.primary
                          }
                        />
                      </S.ControlListFirst>
                      <S.ControlListPrevious onPress={() => previousPage()} disabled={page === 1}>
                        <Icon
                          name="chevron-left"
                          size={30}
                          color={
                            page === 1
                              ? StyledDefaultTheme.colors.black16
                              : StyledDefaultTheme.colors.primary
                          }
                        />
                      </S.ControlListPrevious>
                    </S.WrapperControlLeftList>
                    <S.WrapperControlCenter>
                      <S.Text>{`${
                        pageSize * page - pageSize === 0 ? 1 : pageSize * page - pageSize
                      } até ${pageSize * page} de ${clinics.length}`}</S.Text>
                    </S.WrapperControlCenter>
                    <S.WrapperControlRightList>
                      <S.ControlListNext
                        onPress={() => nextPage()}
                        disabled={clinics.length / pageSize === page}>
                        <Icon
                          name="chevron-right"
                          size={30}
                          color={
                            clinics.length / pageSize === page
                              ? StyledDefaultTheme.colors.black16
                              : StyledDefaultTheme.colors.primary
                          }
                        />
                      </S.ControlListNext>
                      <S.ControlListLast
                        onPress={() => lastPage()}
                        disabled={clinics.length / pageSize === page}>
                        <Icon
                          name="chevrons-right"
                          size={30}
                          color={
                            clinics.length / pageSize === page
                              ? StyledDefaultTheme.colors.black16
                              : StyledDefaultTheme.colors.primary
                          }
                        />
                      </S.ControlListLast>
                    </S.WrapperControlRightList>
                  </S.WrapperControl>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </S.WrapperTop>

        {loadingClinics ? (
          <S.WrapperLoading>
            <S.Loading />
          </S.WrapperLoading>
        ) : (
          <></>
        )}
      </S.WrapperSchedule>
    </>
  );
};
