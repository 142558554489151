import { TextStyle } from 'react-native';
import { themeColors } from '~/components/@hello-ui';
export const TypographyVariantTypes = {
  title1: {
    fontSize: 48,
    lineHeight: 67,
    fontWeight: '700',
  },
  title2: {
    fontSize: 34,
    lineHeight: 42,
    fontWeight: '700',
  },
  title3: {
    fontSize: 28,
    lineHeight: 24,
    fontWeight: '600',
  },
  heading1: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '600',
  },
  heading2: {
    fontSize: 20,
    lineHeight: 24,
    fontWeight: '600',
  },
  heading3: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
  },
  body1: {
    fontSize: 18,
    lineHeight: 28,
    fontWeight: '400',
  },
  bodyHighlight1: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 28,
  },
  body2: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
  bodyHighlight2: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 28,
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
  },
};

export const TypographyVariantTypesMobile = {
  title1: {
    fontSize: 36,
    lineHeight: 67,
    fontWeight: '700',
  },
  title2: {
    fontSize: 28,
    lineHeight: 42,
    fontWeight: '700',
  },
  title3: {
    fontSize: 28,
    lineHeight: 24,
    fontWeight: '600',
  },
  heading1: {
    fontSize: 22,
    lineHeight: 24,
    fontWeight: '600',
  },
  heading2: {
    fontSize: 20,
    lineHeight: 20,
    fontWeight: '600',
  },
  heading3: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: '600',
  },
  subtitle1: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: '500',
  },
  body1: {
    fontSize: 16,
    lineHeight: 28,
    fontWeight: '400',
  },
  bodyHighlight1: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 28,
  },
  body2: {
    fontSize: 14,
    lineHeight: 24,
    fontWeight: '400',
  },
  bodyHighlight2: {
    fontSize: 14,
    lineHeight: 24,
    fontWeight: '600',
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: '400',
  },
};

export interface TypographyTypes {
  style?: TextStyle;
  children: string | JSX.Element | JSX.Element[];
  bold?: boolean;
  underline?: boolean;
  numberOfLines?: number;
  ellipsizeMode?: string;
  variant: keyof typeof TypographyVariantTypes;
  color: keyof typeof themeColors | string;
  weight?: string;
  onPress?: () => void;
}
