import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.background};
  height: 70px;
  width: 100%;
  bottom: 0px;

  ${Platform.select({
    web: css`
      position: fixed;
      box-shadow: 1px 2px 20px rgba(75, 63, 64, 0.2);
    `,
    android: css`
      elevation: 4;
    `,
    ios: css`
      box-shadow: 1px 2px 20px rgba(75, 63, 64, 0.2);
      shadow-color: rgb(0, 0, 0);
      shadow-opacity: 0.3;
      shadow-radius: 3px;
      elevation: 9;
    `,
  })}
`;
