import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

const FamilyIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M19.5505 7.8559C19.5505 6.25407 18.2442 4.95 16.6433 4.95C15.0424 4.95 13.7361 6.25411 13.7361 7.8559C13.7361 9.45747 15.0423 10.7643 16.6433 10.7643C18.2443 10.7643 19.5505 9.45767 19.5505 7.8559ZM14.5504 7.8557C14.5504 6.69532 15.4836 5.76188 16.6433 5.76188C17.803 5.76188 18.7362 6.69532 18.7362 7.8557C18.7362 9.01607 17.803 9.94951 16.6433 9.94951C15.4836 9.94951 14.5504 9.01607 14.5504 7.8557Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M16.5898 12.0787C16.6659 12.1547 16.7688 12.1975 16.8764 12.1979C19.3158 12.3199 21.2354 14.3018 21.2354 16.7536V17.8059H19.1376C18.9128 17.8059 18.7305 17.9882 18.7305 18.213C18.7305 18.4378 18.9128 18.6201 19.1376 18.6201H21.6439L21.644 18.6201C21.8677 18.6194 22.0487 18.4382 22.0496 18.2146V18.2144V16.7538C22.0496 13.898 19.8159 11.5694 16.9921 11.3898L16.9984 11.3836H16.8777C16.6528 11.3836 16.4705 11.5659 16.4705 11.7907C16.4705 11.8987 16.5134 12.0023 16.5897 12.0786L16.5898 12.0787Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M10.2643 7.8559C10.2643 6.25407 8.9581 4.95 7.35717 4.95C5.75623 4.95 4.45 6.25411 4.45 7.8559C4.45 9.45747 5.75617 10.7643 7.35717 10.7643C8.95816 10.7643 10.2643 9.45767 10.2643 7.8559ZM5.26429 7.8557C5.26429 6.69532 6.19744 5.76188 7.35717 5.76188C8.51689 5.76188 9.45004 6.69532 9.45004 7.8557C9.45004 9.01607 8.51689 9.94951 7.35717 9.94951C6.19744 9.94951 5.26429 9.01607 5.26429 7.8557Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M4.86212 17.8057H2.76508V16.7535C2.76508 14.3015 4.68275 12.3196 7.12211 12.1979H7.1241C7.23206 12.1979 7.33566 12.155 7.41201 12.0786C7.48835 12.0023 7.53125 11.8987 7.53125 11.7907C7.53125 11.5659 7.34894 11.3836 7.1241 11.3836L7.12231 11.3912L7.12199 11.3837C4.2444 11.5068 1.95 13.8596 1.95 16.7537L1.95 18.2143L1.95 18.2144C1.95073 18.4384 2.13252 18.6196 2.35643 18.62H2.35651H4.86212C5.08696 18.62 5.26927 18.4377 5.26927 18.2128C5.26927 17.988 5.08696 17.8057 4.86212 17.8057Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M9.14385 12.0945C7.9373 12.0945 6.95098 13.0814 6.95098 14.2887C6.95098 15.496 7.93738 16.4803 9.14385 16.4803C10.3503 16.4803 11.3367 15.4961 11.3367 14.2887C11.3367 13.0814 10.3504 12.0945 9.14385 12.0945ZM9.14385 12.9093C9.90928 12.9093 10.5224 13.5229 10.5224 14.2887C10.5224 15.0546 9.90927 15.6682 9.14385 15.6682C8.37843 15.6682 7.76527 15.0546 7.76527 14.2887C7.76527 13.5228 8.37842 12.9093 9.14385 12.9093Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M4.8084 21.7861V21.4304C4.8084 19.0422 6.73579 17.1059 9.12216 17.0947L9.12237 17.0945H9.14309C11.5388 17.0945 13.4801 19.0351 13.4801 21.4303L13.4801 21.7859C13.4801 21.786 13.4801 21.786 13.4801 21.7861V21.7862H13.4301C13.4305 21.8813 13.3928 21.9729 13.3255 22.0401C13.2582 22.1074 13.1668 22.145 13.0716 22.1447L4.8084 21.7861ZM4.8084 21.7861V21.7862V21.7863H4.8584C4.85812 21.881 4.89543 21.9719 4.9622 22.0392M4.8084 21.7861C4.80806 21.894 4.85057 21.9977 4.92667 22.0744M4.9622 22.0392L4.92667 22.0744M4.9622 22.0392L4.92672 22.0744C4.9267 22.0744 4.92669 22.0744 4.92667 22.0744M4.9622 22.0392C5.02884 22.1065 5.11957 22.1444 5.21443 22.1447H13.0714L13.0713 22.1947L4.92667 22.0744M9.14293 17.909C11.0835 17.909 12.6387 19.4473 12.6651 21.3804H5.62332C5.64967 19.4472 7.20232 17.909 9.14285 17.909H9.14293Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M14.858 12.0945C13.6507 12.0945 12.6639 13.0814 12.6639 14.2887C12.6639 15.496 13.6508 16.4803 14.858 16.4803C16.0653 16.4803 17.0496 15.496 17.0496 14.2887C17.0496 13.0814 16.0654 12.0945 14.858 12.0945ZM14.858 12.9093C15.6239 12.9093 16.2375 13.5229 16.2375 14.2887C16.2375 15.0546 15.6239 15.6682 14.858 15.6682C14.0921 15.6682 13.4785 15.0546 13.4785 14.2887C13.4785 13.5228 14.0921 12.9093 14.858 12.9093Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M14.8586 17.9088C16.7991 17.9088 18.3513 19.447 18.3777 21.3803L19.1431 21.786H19.1931V21.7859C19.1931 21.7859 19.1931 21.7858 19.1931 21.7858L19.1931 21.4303C19.1931 19.0352 17.2546 17.0945 14.859 17.0945H14.8478L14.8474 17.0947C14.1691 17.0964 13.5262 17.253 12.9547 17.5322L12.9546 17.5321L12.9516 17.5338C12.757 17.6461 12.6901 17.8948 12.8022 18.0896L12.8023 18.0897C12.917 18.2885 13.1553 18.3275 13.3538 18.2409L13.3538 18.2409L13.355 18.2404C13.8101 18.0274 14.319 17.9088 14.8586 17.9088Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M12 2.15497C11.4733 1.79637 10.7651 1.86295 10.3135 2.31434L10.3135 2.31436C9.7955 2.8325 9.79549 3.67534 10.3135 4.19336L11.9333 5.81331L11.9864 5.86635L11.9999 5.85285L12.0135 5.86648L12.0665 5.81344L13.6865 4.19336C14.2045 3.67534 14.2045 2.8325 13.6865 2.31436L13.6865 2.31434C13.235 1.8631 12.5268 1.79622 12 2.15497ZM13.2742 3.78119L12.0135 5.04176L12 5.02825L11.9864 5.04189L10.7256 3.7812C10.7256 3.7812 10.7255 3.78119 10.7255 3.78119C10.435 3.49048 10.435 3.01734 10.7256 2.72664L10.7256 2.72663C10.8661 2.58593 11.0532 2.50855 11.2528 2.50855C11.4525 2.50855 11.6395 2.58592 11.7801 2.7265C11.7801 2.7265 11.7801 2.72651 11.7801 2.72652L11.9467 2.89324L11.9997 2.94634L12.0528 2.89324L12.2194 2.72654C12.5102 2.43593 12.9835 2.43608 13.2739 2.72652C13.5648 3.01738 13.5649 3.49051 13.2742 3.78119Z"
        fill={theme?.colors.primary}
        stroke={theme?.colors.primary}
        strokeWidth="0.15"
      />
    </Svg>
  );
};

export default FamilyIcon;
