import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};

export const SuccessIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 75} height={height ?? 75} viewBox="7 7 64 64" fill="none">
      <G id="icon-large-success-color">
        <G id="Ellipse 54">
          <Circle cx="39" cy="39" r="32" fill="#40C26E" />
          <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
        </G>
        <Path
          id="Vector"
          d="M51.8002 30.2L34.2002 47.8L26.2002 39.8"
          stroke="white"
          strokeWidth="5.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};
