import React, { useEffect, useState } from 'react';
import { Typography, Button, Input, useDialog } from '../../../components/@tem-ui';
import { Spacing } from '../../../components/Spacing';
import * as S from '../styles';

import { States } from '../../../components/Dialog/States';
import { Cities } from '../../../components/Dialog/Cities';
import { Neighborhoods } from '../../../components/Dialog/Neighborhoods';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { GeolocationButton } from '~/components/@hello-ui/Adresses/Geolocation/component/GeolocationButton';
import { useGeolocation } from '~/components/@hello-ui/Adresses/Geolocation';
import { CityType, NeighborhoodType, StateType } from '~/components/@hello-ui/Adresses/types';
import { useAdresses } from '~/components/@hello-ui';

export const BenefitsLocalization = (): JSX.Element => {
  const { nextStep } = useStepper();
  const { openDialog, closeDialog } = useDialog();
  const { appointment, setAppointment } = useAppointment();
  const { geolocationAddress } = useGeolocation();
  const { getState, getCity, getNeighborhoods } = useAdresses();

  const [state, setState] = useState<StateType | null>(null);
  const [city, setCity] = useState<CityType | null>(null);
  const [neighborhood, setNeighborhood] = useState<NeighborhoodType[] | null>(null);

  useEffect(() => {
    closeDialog();
  }, [state, city]);

  const fillFieldsWithGeolocationData = async () => {
    if (Object.keys(geolocationAddress).length) {
      if (geolocationAddress.state) {
        const stateData = await getState(geolocationAddress.state);
        if (stateData) {
          setState(stateData);

          if (geolocationAddress.city) {
            await getCity(stateData?.id, geolocationAddress.city).then(
              async (cityData: CityType | undefined) => {
                if (cityData) {
                  setCity(cityData);

                  if (geolocationAddress.neighborhood) {
                    await getNeighborhoods(cityData.id, geolocationAddress.neighborhood).then(
                      (neighborhoodData: NeighborhoodType[]) => {
                        neighborhoodData && setNeighborhood(neighborhoodData);
                      },
                    );
                  }
                }
              },
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    void fillFieldsWithGeolocationData();
  }, [geolocationAddress]);

  return (
    <S.WrapperSchedule>
      <Spacing bottom={16}>
        <Typography variant="heading1" color="black" bold>
          Onde você precisa?
        </Typography>
      </Spacing>
      <S.WrapperScheduleForm>
        <Spacing bottom={8}>
          <GeolocationButton />
        </Spacing>
        <S.WrapperInput>
          <S.InputField
            onPress={() => {
              openDialog('Qual o estado?', <States onSelect={(s) => setState(s)} />);
            }}
          />
          <Input editable={false} variant="light" label="Estado" value={state?.title} />
        </S.WrapperInput>
        <S.WrapperInput disabled={state === null}>
          <S.InputField
            onPress={() => {
              openDialog(
                'Qual a cidade?',
                <Cities data={state?.id} onSelect={(c) => setCity(c)} />,
              );
            }}
            disabled={state === null}
          />
          <Input editable={false} variant="light" label="Cidade" value={city?.title} />
        </S.WrapperInput>
        <S.WrapperInput disabled={city === null}>
          <S.InputField
            onPress={() => {
              openDialog(
                'Quais bairros?',
                <Neighborhoods
                  cityId={city?.id}
                  onSelect={(n) => {
                    setNeighborhood(n);
                    closeDialog();
                  }}
                  initialSelectedValues={neighborhood}
                />,
                'Escolha até três bairros de sua preferência',
              );
            }}
            disabled={city === null}
          />
          <Input
            editable={false}
            variant="light"
            label="Bairro (Não Obrigatório)"
            value={neighborhood?.map((n) => n.title).join(', ')}
          />
        </S.WrapperInput>
      </S.WrapperScheduleForm>
      <S.WrapperButton>
        <Button
          disabled={!state || !city}
          variant="primary"
          title="Próximo"
          onPress={() => {
            const neighborhoods = [];

            if (neighborhood) {
              for (const n of neighborhood) {
                neighborhoods?.push(n.title);
              }
            }

            const address = {
              state: state?.title ?? '',
              stateInitials: state?.initials ?? '',
              city: city?.title ?? '',
              neighborhood: neighborhoods,
            };

            setAppointment({ ...appointment, address });
            nextStep();
          }}
        />
      </S.WrapperButton>
    </S.WrapperSchedule>
  );
};
