import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const DiscountMedicines = ({ width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_4718_41377)">
        <Path
          d="M12.7098 4.02336C12.6131 3.92681 12.4798 3.87629 12.3436 3.88447L9.00999 4.0641C8.69622 2.83486 7.82811 1.82232 6.66182 1.32546C5.49565 0.828432 4.1646 0.90394 3.0619 1.52964C1.9592 2.15531 1.21099 3.25961 1.03807 4.5164C0.865151 5.7732 1.28742 7.0387 2.18003 7.93943C2.70261 8.46405 3.359 8.83535 4.07775 9.01244L3.89827 12.3441C3.89106 12.4785 3.94058 12.6097 4.03481 12.7058L14.1814 22.8586C14.2709 22.9486 14.3922 22.9994 14.5189 22.9999C14.6475 23.0026 14.7717 22.9534 14.8635 22.8634L22.8588 14.8613C22.9505 14.7702 23.0014 14.6457 23 14.5164C22.9995 14.3896 22.9487 14.2682 22.8588 14.1787L12.7098 4.02336ZM2.85521 7.26162C2.06643 6.47217 1.75846 5.32155 2.04706 4.24324C2.33584 3.16493 3.17734 2.32275 4.25469 2.03373C5.33208 1.74487 6.48171 2.05314 7.27049 2.84257C7.62674 3.19799 7.8905 3.63551 8.0386 4.11669L5.75566 4.24132C5.36656 4.27003 5.00088 4.4378 4.72491 4.71383C4.44911 4.99002 4.28148 5.35605 4.2528 5.74546L4.12828 8.03519C3.64718 7.88507 3.21016 7.61946 2.85521 7.26162ZM14.5194 21.8481L4.86296 12.1835L5.2075 5.79784C5.22401 5.64419 5.29275 5.50048 5.40189 5.39109C5.51118 5.28187 5.65461 5.21306 5.80813 5.19654L8.1821 5.06967C8.18082 5.89231 7.85279 6.68078 7.27041 7.26124C7.0834 7.44841 7.0834 7.75186 7.27041 7.93905C7.45743 8.12623 7.76062 8.12623 7.94765 7.93905C8.72293 7.16582 9.15435 6.11239 9.14409 5.01691L12.1929 4.85171L21.8444 14.5164L14.5194 21.8481Z"
          fill={theme.colors.primary}
          stroke={theme.colors.primary}
          strokeWidth="0.3"
        />
        <Path
          d="M8.3638 11.2692C8.03208 11.6011 7.8457 12.0515 7.8457 12.5207C7.8457 12.9902 8.03192 13.4405 8.36364 13.7724C8.69536 14.1044 9.1452 14.2909 9.61423 14.2909C10.0833 14.2909 10.5331 14.1044 10.8647 13.7724C11.1964 13.4405 11.3828 12.9902 11.3826 12.5207C11.3826 12.0514 11.1962 11.6011 10.8645 11.2692C10.5326 10.938 10.0829 10.752 9.61419 10.752C9.14528 10.752 8.69577 10.938 8.36376 11.2692H8.3638ZM10.1873 13.0943C10.0352 13.2468 9.82898 13.3325 9.61362 13.3326C9.3984 13.3328 9.19201 13.2473 9.03977 13.0949C8.88753 12.9427 8.80195 12.7361 8.80195 12.5207C8.80195 12.3053 8.88752 12.0986 9.03977 11.9464C9.192 11.794 9.39841 11.7085 9.61362 11.7087C9.829 11.7089 10.0352 11.7947 10.1873 11.947C10.3378 12.0991 10.4221 12.3044 10.4221 12.5184C10.4221 12.7323 10.3378 12.9374 10.1873 13.0895V13.0943Z"
          fill={theme.colors.primary}
        />
        <Path
          d="M15.4244 14.2842C15.9744 14.2843 16.493 14.0283 16.8278 13.5916C17.1625 13.155 17.2753 12.5872 17.1329 12.0557C16.9904 11.5242 16.609 11.089 16.1009 10.8786C15.5929 10.6682 15.0158 10.7063 14.5397 10.9817C14.0635 11.2571 13.7426 11.7386 13.6713 12.2843C13.5998 12.8301 13.7862 13.378 14.1752 13.7667C14.5063 14.0988 14.9558 14.285 15.4244 14.2842L15.4244 14.2842ZM14.8645 11.9416C15.0166 11.7893 15.223 11.7035 15.4382 11.7033C15.6534 11.7033 15.8598 11.7888 16.0121 11.941C16.1643 12.0934 16.2499 12.3 16.2499 12.5154C16.2499 12.7308 16.1643 12.9373 16.0121 13.0895C15.8598 13.2419 15.6534 13.3274 15.4382 13.3272C15.223 13.3271 15.0166 13.2414 14.8645 13.0889C14.7121 12.937 14.6264 12.7306 14.6264 12.5153C14.6264 12.2999 14.7121 12.0935 14.8645 11.9416V11.9416Z"
          fill={theme.colors.primary}
        />
        <Path
          d="M12.0977 16.8503C12.0977 17.1148 12.3121 17.3293 12.5767 17.3293C12.8412 17.3293 13.0556 17.1148 13.0556 16.8503V8.18221C13.0556 7.91757 12.8412 7.70312 12.5767 7.70312C12.3121 7.70312 12.0977 7.91757 12.0977 8.18221V16.8503Z"
          fill={theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4718_41377">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default DiscountMedicines;
