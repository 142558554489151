import React from 'react';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { BreadcrumbItem, BreadcrumbText } from './styles';
import Icon from 'react-native-vector-icons/dist/Feather';

export interface BreadcrumbItem {
  label: string;
  onPress: () => void;
  actual: boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const theme = useTheme();

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {items.map(({ label, onPress, actual }, index) => (
        <React.Fragment key={label}>
          {index > 0 && (
            <Icon
              name="chevron-right"
              size={Platform.OS === 'web' ? 15 : 18}
              color={theme.colors.primary}
            />
          )}
          <BreadcrumbItem onPress={onPress} testID={`breadcrumb-item-${label}`}>
            <BreadcrumbText actual={actual} color={theme.colors.black} testID="breadcrumb-item">
              {label}
            </BreadcrumbText>
          </BreadcrumbItem>
        </React.Fragment>
      ))}
    </View>
  );
};
