import axios, { AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API_URL } from '@env';
import { refreshAuthLogic, requestInterceptor } from '../apiConfig';
import { useOlimpoApiTypes } from './types';
import { contextTracking } from '~/contexts/tracking';

export const useTracking = (): useOlimpoApiTypes => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: {},
  });

  createAuthRefreshInterceptor(instance, refreshAuthLogic, {
    statusCodes: [401],
  });

  const _initializeRequestInterceptor = () => {
    instance.interceptors.request.use(requestInterceptor);
  };

  const _handleResponse = ({ data, headers }: AxiosResponse): AxiosResponse => {
    const dataReturned = data.data ? data : { data };
    return { ...dataReturned, headers };
  };

  const _handleError = (error: any): Promise<any> => Promise.reject(error);

  const _initializeResponseInterceptor = () =>
    instance.interceptors.response.use(_handleResponse, _handleError);

  _initializeResponseInterceptor();
  _initializeRequestInterceptor();

  const tracking = contextTracking(instance);

  return {
    tracking,
  };
};
