import { create } from 'zustand';
import { IGlobalStore, IGlobalStoreKeys } from '~/store/interfaces/global-store';

export const useGlobalStore = create<IGlobalStore>((set) => ({
  storesLoading: [],
  loadStore: (store: IGlobalStoreKeys) =>
    set((st) => ({
      storesLoading: st.storesLoading.filter((loadingStore) => loadingStore !== store),
    })),
  addLoadingStore: (store: IGlobalStoreKeys) =>
    set((st) => ({ storesLoading: [...st.storesLoading, store] })),
}));
