import React, { useCallback, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { FlatList, ListRenderItemInfo, Platform, View, ViewStyle } from 'react-native';
import { Loader } from '../Loader/Loader';
import { ListingProps } from './types';
import * as S from './styles';
import { ToTopButton } from './components/toTop';
import { Button } from '~/components/@hello-ui';
import { useWindowSize } from '~/hooks/useWindowSize';
import FlatIconArrowUp from '~/components/Icons/ArrowUp';
import FlatIconAngleSmallDown from '~/components/Icons/AngleSmallDown';

export const Listing = ({
  data,
  renderItem,
  contentStyle,
  listHeaderComponent,
  loading = false,
  twoColumns = false,
  hideBackToTop = false,
  fixedBackToTop = false,
  useSeparator = false,
  separatorColor,
  extraHeight = 0,
  containerStyle,
  keyExtractor,
  style,
  horizontal,
  heightValForCalc = 74,
  testID,
  showNextPageButton = false,
  handleNextPage,
}: ListingProps): JSX.Element => {
  const flatListRef = useRef<FlatList>(null);
  const { isMobile, isDesktop, ...theme } = useTheme();
  const windowSize = useWindowSize();

  // TODO: refactor to dynamically grow
  const containerHeight = useCallback(() => {
    return Platform?.OS === 'web'
      ? windowSize.height - (isDesktop ? 420 : isMobile ? 230 : 320) + extraHeight
      : windowSize.height - 310 + extraHeight;
  }, [data]);

  const defaultKeyExtractor = useCallback((_item: any, idx: number) => {
    return idx.toString();
  }, []);

  const columnsCount = twoColumns && windowSize.width >= 1290 ? 2 : 1;

  const hasScroll = () => {
    const maxRows = Math.round(containerHeight() / heightValForCalc);
    const rows = data?.length / columnsCount;
    return rows >= maxRows;
  };

  const renderItemWithSeparator = (info: ListRenderItemInfo<any>) => {
    return (
      /* Used for filling the double columns correctly, web only because this flex-1 breaks the FlatList on mobile */
      <View className={Platform.OS === 'web' ? 'flex-1' : undefined}>
        {useSeparator && <S.Divider color={separatorColor} />}
        {renderItem(info, { columnsCount })}
        {useSeparator && info.index === data.length - 1 && <S.Divider color={separatorColor} />}
      </View>
    );
  };

  return (
    <>
      {loading && (!handleNextPage || data.length === 0) ? (
        <Loader variant="circle-loader" />
      ) : (
        <>
          <View
            className={'h-[100px] flex-grow basis-auto'}
            style={{ ...(containerStyle as ViewStyle) }}>
            <FlatList
              testID={testID ?? 'list-test'}
              numColumns={columnsCount}
              /* Force re-render, since React Native can't change numColumns on the fly */
              key={columnsCount}
              data={data}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              dataSet={{
                // To fix safari scroll issue: https://github.com/stipsan/react-spring-bottom-sheet#scrolllocking
                bodyScrollLockIgnore: true,
              }}
              horizontal={horizontal ?? false}
              contentContainerStyle={{
                flexGrow: 1,
                ...(contentStyle as ViewStyle),
              }}
              ref={flatListRef}
              style={style}
              keyExtractor={keyExtractor ?? defaultKeyExtractor}
              renderItem={renderItemWithSeparator}
              ListHeaderComponent={listHeaderComponent}
              ListFooterComponent={
                !hideBackToTop && !fixedBackToTop && !loading && !!data?.length && hasScroll() ? (
                  <ToTopButton scrollRef={flatListRef} />
                ) : showNextPageButton ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="mx-auto my-16 max-w-[530px]"
                    variant="outline"
                    iconPosition="right"
                    icon={<FlatIconAngleSmallDown size={24} color={theme.colors.primary} />}
                    onPress={handleNextPage}>
                    Ver mais
                  </Button>
                ) : (
                  <></>
                )
              }
            />
            {!hideBackToTop && fixedBackToTop && !!data?.length && hasScroll() && (
              <Button
                variant="primary"
                className="absolute bottom-0 right-0 m-[21px] h-48 w-48 justify-center mobile:m-16"
                style={{
                  paddingHorizontal: 7,
                }}
                textStyle={{ display: 'none' }}
                icon={<FlatIconArrowUp color={theme.colors.white} />}
                iconPosition="center"
                onPress={() => {
                  flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
                }}>
                {''}
              </Button>
            )}
          </View>
        </>
      )}
    </>
  );
};
