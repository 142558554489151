import React, { useEffect, useState, useCallback } from 'react';
import { useApi } from '../../../hooks/api';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Typography, Input, useDialog } from '../../@tem-ui';
import * as S from './styles';
import { serializeString } from '~/utils/strings';
import { useTheme } from 'styled-components/native';
import { Specialty } from '~/contexts/types';

export interface SpecialtiesProps {
  onSelect: (specialty: Specialty) => void;
}

export const Specialties = ({ onSelect }: SpecialtiesProps): JSX.Element => {
  const api = useApi();
  const theme = useTheme();

  const { closeDialog } = useDialog();

  const [term, setTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [fullSpecialties, setFullSpecialtie] = useState<Specialty[]>([]);

  const keyExtractor = useCallback((item, idx) => idx.toString(), []);

  const renderItem = useCallback(
    ({ item }) => {
      const { name }: Specialty = item;
      return (
        <S.WrapperItem onPress={() => onSelect(item)}>
          <Typography variant="body2" color="black">
            {name}
          </Typography>
          <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
        </S.WrapperItem>
      );
    },
    [closeDialog],
  );

  const getData = useCallback(async () => {
    const { data } = await api.specialities({ type: 'medical' });

    setSpecialties(data);
    setFullSpecialtie(data);

    setLoading(false);
  }, []);

  const handleSearch = useCallback(
    (text: string) => {
      const query: string = serializeString(text);
      const filteredData = fullSpecialties.filter((specialty: Specialty) => {
        if (serializeString(specialty.name).includes(query)) {
          return specialty;
        }
      });

      setSpecialties(filteredData);
      setTerm(text);
    },
    [fullSpecialties],
  );

  useEffect(() => {
    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      {loading ? (
        <S.WrapperLoading>
          <S.Loading />
        </S.WrapperLoading>
      ) : (
        <>
          <S.WrapperSearch>
            <Input
              editable
              value={term}
              onChange={(value) => handleSearch(value)}
              label="Pesquisar especialidade"
              placeholder="Pesquisar especialidade"
              variant="light"
              icon="search"
            />
          </S.WrapperSearch>
          <S.WrapperList
            data={specialties}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            ItemSeparatorComponent={S.Divider}
          />
        </>
      )}
    </S.Wrapper>
  );
};
