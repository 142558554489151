import { atom } from 'recoil';
import { Appointment } from './types';

export const tempAppointments = atom<Appointment[]>({
  key: 'tempAppointments',
  default: [],
});

export const tempAppointment = atom<Appointment | null>({
  key: 'tempAppointment',
  default: null,
});

export const tempAppointmentLoad = atom({
  key: 'tempAppointmentLoad',
  default: false,
});
