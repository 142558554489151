import React, { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { Card, Typography, CardHeader } from '~/components/@tem-ui';
import { useNavigation } from '@react-navigation/native';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import { DropDown } from './components/DropDown';
import { HelpContent } from './types';
import * as S from './styles';
import { useTheme } from 'styled-components/native';
import { useApi } from '~/hooks/api';
import { useMedia } from '~/components/@hello-ui';
export const HelpDependents = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();
  const api = useApi();

  const [regulationLink, setRegulationLink] = useState<string>('');

  useEffect(() => {
    if (regulationLink === '') {
      void api.getRegulation().then((product) => {
        setRegulationLink(product.data.arquivo_contrato);
      });
    }
  }, [regulationLink]);

  const content: (args: any) => HelpContent[] = ({ partner_full_name, partner_name }) => [
    {
      title: `O que é o programa ${partner_name} Familiar`,
      text: `
É o programa que dá acesso aos benefícios da ${partner_full_name} também para os seus Dependentes.
      
Seus Dependentes tem acesso a:
• Consultas médicas e Odontológicas com descontos na Rede ${partner_full_name};
• Exames e procedimentos com descontos na Rede ${partner_full_name};
      
Confira todos os benefícios dos seus dependentes no contrato de adesão.`,
    },
    {
      title: 'Quem pode ser Dependente',
      text: (
        <>
          <S.WrapperContent
            onPress={() => regulationLink !== '' && Linking.openURL(regulationLink)}>
            <Typography variant="heading3" color="black">
              *Clique aqui para consultar o regulamento do seu produto.
            </Typography>
          </S.WrapperContent>
        </>
      ),
    },
    {
      title: 'Agendamentos para Dependentes',
      text: (
        <>
          <Typography variant="body1" color={theme.colors.black70}>
            <>
              Agendar consultas ou exames para dependentes na Conta Saúde é fácil:{'\n'}
              {'\n'}
              1. Na tela inicial, clique em{' '}
              <Typography variant="body1" color={theme.colors.black70} bold>
                Agendar
              </Typography>
              {'\n'}
              2. Escolha qual atendimento o(a) dependente precisa: consulta ou exames{'\n'}
              3. Para consultas, escolha a especialidade{'\n'}4. Defina a localização do atendimento
              {'\n'}
              5. Escolha a preferência de dias e horários{'\n'}6. Na opção “Para quem é o
              agendamento?”, selecione o(a) dependente.{'\n'}
              {'\n'}
              Pronto! Nossa equipe vai receber a solicitação de agendamento e, em até 2 dias úteis,
              vamos enviar as melhores opções de preços e localidades.
            </>
          </Typography>
        </>
      ),
    },
    {
      title: 'Excluir Dependentes',
      text: `
A permanência mínima para cada dependente é de 12 meses. Ou seja, você só poderá excluir e/ou substituir dependentes que estiverem no mínimo a 12 meses cadastrados.`,
    },
  ];
  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card height={media.isMobile ? 'auto' : 769} disableShadow={media.isMobile}>
          <CardHeader title="Preciso de ajuda" onBackPress={() => navigation.goBack()} />
          <S.Title>
            <Typography variant={media.isMobile ? 'title2' : 'title3'} color="black">
              Programa {theme?.partner_name} Familiar
            </Typography>
          </S.Title>
          <>
            {content(theme).map((data, idx) => (
              <DropDown key={`item_${idx}`} title={data.title} text={data.text} />
            ))}
          </>
        </Card>
        <Footer />
      </S.Container>
    </>
  );
};
