import React from 'react';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import * as S from './styles';
import { Card, CardHeader, Typography } from '~/components/@tem-ui';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMedia } from '~/components/@hello-ui';

export const Signature = (): JSX.Element => {
  const navigation = useNavigation();
  const media = useMedia();
  const theme = useTheme();

  const { signature } = useAuth();

  const finalNumber = signature
    ? signature?.subscription_card.pan
      ? signature?.subscription_card.pan.substr(-4)
      : '-'
    : '-';

  const signatureCancel = (
    <S.SignatureCancel>
      <S.SignatureDecorateCancel onPress={() => navigation.navigate('SignatureCancel')}>
        <Typography variant="body2" color={theme?.colors.primary}>
          Cancelar Assinatura
        </Typography>
      </S.SignatureDecorateCancel>
      <Icon name="chevron-right" size={26} color={theme?.colors.primary} />
    </S.SignatureCancel>
  );

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Card height={!media.isMobile ? 769 : 'auto'} disableShadow={media.isMobile}>
          <CardHeader
            title=""
            titleElement={
              <Typography variant="heading2" color="black">
                Assinatura
              </Typography>
            }
            onBackPress={() => navigation.navigate('Dashboard')}
            noBorderBottom={false}
          />

          <S.WrapperCard>
            <S.WrapperBackground resizeMode="cover">
              <S.WrapperTitle>
                <Typography variant="heading1" color="white">
                  Programa
                </Typography>
                <Typography variant="title2" color="white">
                  TEM Saúde Familiar:
                </Typography>
              </S.WrapperTitle>
            </S.WrapperBackground>
            <S.WrapperBottom>
              <S.WrapperTextCards>
                <S.TextCard>
                  <Typography variant="body2" color="black70">
                    Assinatura
                  </Typography>
                  <Typography variant="body1" color="black">
                    {signature ? signature?.plan.name ?? '-' : '-'}
                  </Typography>
                </S.TextCard>
              </S.WrapperTextCards>
              <S.WrapperTextCards>
                <S.TextCard>
                  <Typography variant="body2" color="black70">
                    Situação
                  </Typography>
                  <Typography variant="body1" color="black">
                    {signature
                      ? signature?.subscription_status === 'active'
                        ? 'Ativo'
                        : 'Desativado'
                      : '-'}
                  </Typography>
                </S.TextCard>
              </S.WrapperTextCards>
              <S.WrapperTextCards>
                <S.TextCard>
                  <Typography variant="body2" color="black70">
                    Valor
                  </Typography>
                  <Typography variant="body1" color="black">
                    {signature
                      ? signature?.plan
                        ? signature?.plan.amount.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '-'
                      : '-'}{' '}
                    mensal
                  </Typography>
                </S.TextCard>
              </S.WrapperTextCards>
              <S.WrapperTextCards>
                <S.TextCard>
                  <Typography variant="body2" color="black70">
                    Pagamento
                  </Typography>
                  <Typography variant="body1" color="black">
                    {`${signature ? signature?.plan.payment_methods[0].label : '-'} ${
                      signature ? signature?.subscription_card.effectiveBrand : '-'
                    } - final ${finalNumber}`}
                  </Typography>
                </S.TextCard>
              </S.WrapperTextCards>
              <S.WrapperCards>
                <Card width={media.isMobile ? 290 : ''} height={112}>
                  <S.WrapperContent onPress={() => navigation.navigate('BenefitsPresentation')}>
                    <Icon name="star" size={26} color={theme?.colors.primary} />
                    <S.WrapperText>
                      <Typography variant="heading3" color="black">
                        Meus benefícios
                      </Typography>
                      <S.KnowMore>
                        <Typography variant="body2" color="black70">
                          Saiba mais
                        </Typography>

                        <S.ArrowKnowMore>
                          <Icon name="chevron-right" size={23} color={theme?.colors.primary} />
                        </S.ArrowKnowMore>
                      </S.KnowMore>
                    </S.WrapperText>
                  </S.WrapperContent>
                </Card>
              </S.WrapperCards>

              <S.SignatureDeskCancel>{signatureCancel}</S.SignatureDeskCancel>
            </S.WrapperBottom>
          </S.WrapperCard>
        </Card>

        <S.SignatureMobileCancel>{signatureCancel}</S.SignatureMobileCancel>
        <Footer />
      </S.Container>
    </>
  );
};
