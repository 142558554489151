import React, { useState, useEffect, useCallback } from 'react';
import * as S from './styles';
import { Typography } from '~/components/@tem-ui';
import { format, parseISO } from 'date-fns';
import { ConectaDataTimes } from '../types';
import { View } from 'react-native';
interface ScheduleTimesProps {
  loading: boolean;
  times: ConectaDataTimes | undefined;
  onSelect: (disable: boolean, item?: string) => void;
}
interface timeProps {
  id: number;
  time: string;
  selected: boolean;
}

export const ScheduleTimes = ({ loading, times, onSelect }: ScheduleTimesProps): JSX.Element => {
  const [listTime, setListTime] = useState<timeProps[] | []>([]);

  useEffect(() => {
    if (times) {
      setListTime(
        times?.shedules.map((item: string, idx: number) => {
          return { id: idx, time: item, selected: false };
        }),
      );
    }

    onSelect(true);
  }, [times]);

  const handleSelected = useCallback((id: number, datItem: timeProps[], item: timeProps) => {
    onSelect(false, item.time);
    setListTime(
      datItem.map((item: timeProps) => {
        return { id: item.id, time: item.time, selected: item.id === id };
      }),
    );
  }, []);

  return (
    <>
      <S.TimetableWrapper>
        {loading ? (
          <S.WrapperLoading>
            <S.Loading />
          </S.WrapperLoading>
        ) : listTime?.length > 0 ? (
          listTime?.map((item: timeProps) => (
            <S.WrapperPeriod
              key={item.id}
              selected={item.selected}
              onPress={() => {
                handleSelected(item.id, listTime, item);
              }}>
              <S.WrapperHour selected={item.selected}>
                <Typography variant="body2" color={item.selected ? 'white' : 'black'}>
                  {format(parseISO(item.time), 'HH:mm')}
                </Typography>
              </S.WrapperHour>
            </S.WrapperPeriod>
          ))
        ) : (
          <Typography variant="paragraph" color="black">
            Sem horários para esta data
          </Typography>
        )}
      </S.TimetableWrapper>
    </>
  );
};
