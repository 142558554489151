import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const MicrophoneIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 42" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.69995 8.99995C2.69995 4.25102 7.0238 0.699951 12 0.699951C16.9761 0.699951 21.3 4.25102 21.3 8.99995L21.2733 23C21.2733 23.0005 21.2733 23.001 21.2733 23.0015C21.2723 27.7444 16.9815 31.3 12 31.3C7.0238 31.3 2.69995 27.7489 2.69995 23V8.99995ZM12 3.29995C8.12277 3.29995 5.29995 6.00222 5.29995 8.99995V23C5.29995 25.9977 8.12277 28.7 12 28.7C15.8712 28.7 18.6733 26.0029 18.6733 23L18.7 8.99995C18.7 8.99949 18.7 8.99904 18.7 8.99858C18.6991 6.00139 15.8765 3.29995 12 3.29995Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M23.8888 27.4518C24.0114 26.6564 23.3475 26 22.5428 26C21.738 26 21.101 26.659 20.9487 27.4492C20.1755 31.4628 16.2391 34.16 11.9999 34.16C7.76075 34.16 3.82432 31.4628 3.05112 27.4492C2.89889 26.659 2.26182 26 1.45707 26C0.652309 26 -0.0115167 26.6564 0.111094 27.4518C0.765673 31.698 4.23797 35.1662 8.58335 36.3943C9.54359 36.6657 10.2856 37.4946 10.2856 38.4925V40.2857C10.2856 41.2325 11.0531 42 11.9999 42C12.9467 42 13.7142 41.2325 13.7142 40.2857V38.4925C13.7142 37.4946 14.4563 36.6657 15.4165 36.3943C19.7619 35.1662 23.2342 31.698 23.8888 27.4518Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default MicrophoneIcon;
