import React, { useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';
import * as S from './styles';
import { HelthAccountIcon } from './HelthAccoutIcon';
import { currency } from '~/utils/strings';
import { useTemPay } from '~/hooks/temPay';
/**
 * @component HelthAccount
 * @description A component that displays health account information.
 * @returns JSX.Element
 */
export const HelthAccount = ({ hideCurrency = false }: { hideCurrency?: boolean }): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { card, loadingCard } = useTemPay();

  return (
    <S.WrapperHelthAccount
      testID="tempay-component"
      onPress={() => {
        navigation.navigate('TemPayDashboard');
      }}>
      <S.Wrapper>
        <S.WrapperIcon>
          {loadingCard ? (
            <S.Loading testID="loading" color={theme?.colors?.primary} />
          ) : (
            <Icon name="credit-card" size={30} color={theme?.colors?.primary} />
          )}
        </S.WrapperIcon>
        <S.Logo>
          <HelthAccountIcon />
        </S.Logo>
        <S.WrapperColumn testID="balance">
          <Typography variant="helperText" color={theme.colors.white} style={{ marginBottom: -8 }}>
            saldo
          </Typography>
          <S.BalanceWrapper>
            <Typography variant="body2" color={theme.colors.white70}>
              R$
            </Typography>
            <Typography variant="bodyHighlight1" color="white">
              {loadingCard || hideCurrency ? '****' : currency(card?.saldo_d ?? 0)}
            </Typography>
          </S.BalanceWrapper>
        </S.WrapperColumn>
        <S.WrapperArrow>
          <Icon name="chevron-right" size={24} color={theme?.colors.white} />
        </S.WrapperArrow>
      </S.Wrapper>
    </S.WrapperHelthAccount>
  );
};
