import styled from 'styled-components/native';
import { ImageBackground } from 'react-native';

import memorialLogo from '~/assets/images/logo-memorial.png';
import logoIGS from '~/assets/images/logo-igs.png';

export const ImageMemorial = styled(ImageBackground).attrs({
  source: memorialLogo,
})`
  min-width: 220px;
  height: 55px;
`;

export const ImageIGS = styled(ImageBackground).attrs({
  source: logoIGS,
})`
  min-width: 160px;
  height: 83px;
`;
