import { createContext, useContext } from 'react';

export const WrapperHeightContext = createContext(0);

export const WrapperHeightProvider = WrapperHeightContext.Provider;

export const useWrapperHeight = () => {
  const context = useContext(WrapperHeightContext);

  if (context === undefined) {
    throw new Error('useWrapperHeight must be used within a WrapperHeightProvider');
  }

  return context;
};
