import React, { useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import * as S from '../styles';
import { Button, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { AppointmentFlowType } from '~/enums/appointment';
import { useWindowSize } from '~/hooks/useWindowSize';

export const ScheduleToChoose = (): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const { nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();
  const theme = useTheme();
  const windowSize = useWindowSize();

  useEffect(() => {
    appointment && setAppointment({ ...appointment, flowType: AppointmentFlowType.ClinicNotFound });
  }, []);

  return (
    <S.FullContainer>
      <S.TitleWrapper>
        <Typography variant="title" color={theme.colors.black}>
          Quero escolher:
        </Typography>
      </S.TitleWrapper>
      <S.SimpleWrapper
        style={{
          height: theme.isMobile
            ? (theme.platform?.OS === 'web' ? windowSize.height : windowSize.height) - 280
            : '100%',
        }}>
        <S.BodyWrapper>
          <Typography variant="body2" color={theme.colors.black20}>
            Nossa central irá buscar as clínicas mais próximas da localização informada.
          </Typography>
        </S.BodyWrapper>
        <S.BodyWrapper>
          <Typography variant="body2" color={theme.colors.black20}>
            <>
              Enviaremos uma lista de opções e orçamentos por e-mail e WhatsApp para que você{' '}
              <Typography variant="subtitle" color={theme.colors.black20}>
                escolha o que melhor te atende!
              </Typography>
            </>
          </Typography>
        </S.BodyWrapper>
        <MobileAutoSpace heightMobile={24} heightDesktop={100} />
        <S.SimpleWrapper>
          <Button variant="primary" onPress={() => nextStep()}>
            Próximo
          </Button>
        </S.SimpleWrapper>
      </S.SimpleWrapper>
    </S.FullContainer>
  );
};
