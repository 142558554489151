import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import * as yup from 'yup';
import { DeepLinkFirstAccessStepProps } from '../types';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';
import { Button, Input, Typography, useSweetAlert } from '~/components/@hello-ui';
import { instanceApi } from '~/App';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useAuthStore } from '~/store/auth.store';

interface FormData {
  password: string;
  confirmPassword: string;
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  password: yup
    .string()
    .length(6, 'Sua senha precisa ter 6 números')
    .test('password', 'Sua senha não pode começar com 0', (value) => value?.charAt(0) !== '0')
    .test('password', 'Sua senha deve conter apenas números', (value) => !!value?.match(/^[0-9]+$/))
    .required('Campo obrigatório'),
  confirmPassword: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
});

export const DeepLinkFirstAccessPassword = ({
  setStep,
  firstAccessData,
}: DeepLinkFirstAccessStepProps) => {
  const user = useAuthStore((state) => state.user);
  const onix = useAuthStore((state) => state.selectedProduct?.onixCode);

  const [loading, setLoading] = React.useState(false);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const theme = useTheme();
  const defaultValues: FormData = {
    password: '',
    confirmPassword: '',
  };
  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues,
  });

  const handleForm = async (values: FormData) => {
    try {
      setLoading(true);
      await instanceApi.updateTemPayPassword(firstAccessData.cardNumber, values.password);
      await OlimpoService.deepLink.completeDeepLinkFirstAccess(user?.cpf as string, onix as string);
      setStep('complete');
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cadastrar sua senha. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper onGoBack={() => setStep('phone_confirmation')}>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="title">Crie sua senha</Typography>
      </View>
      <Typography variant={theme.isMobile ? 'body1' : 'body2'} className="mb-4 mobile:mb-8">
        Crie sua senha para a Conta Saúde
      </Typography>
      <Typography variant="helperText" className="mb-24 mobile:mb-18">
        Essa senha será utilizada para confirmar o pagamento de seus atendimentos
      </Typography>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <View className={error?.message && 'mb-8'}>
            <Input
              isPassword
              value={value}
              onChangeText={(text) => {
                onChange(text.replace(/[^0-9]/g, ''));
              }}
              label="Senha"
              placeholder="Crie sua senha"
              error={error?.message ?? ''}
              keyboardType="numeric"
              maxLength={6}
            />
          </View>
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <View className={error?.message && 'mb-8'}>
            <Input
              isPassword
              value={value}
              onChangeText={(text) => {
                onChange(text.replace(/[^0-9]/g, ''));
              }}
              label="Confirmar senha"
              placeholder="Confirme sua senha"
              error={error?.message ?? ''}
              keyboardType="numeric"
              maxLength={6}
            />
          </View>
        )}
      />
      <View className="mb-24 mt-auto mobile:mb-68">
        <Button
          variant="outLine"
          onPress={handleSubmit(handleForm)}
          disabled={!isFormValid || loading}
          loading={loading}>
          Concluir
        </Button>
      </View>
    </PageWrapper>
  );
};
