import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const Wrapper = styled.View`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${({ theme: { colors } }) => colors.background};
`;

export const WrapperHeader = styled.TouchableOpacity<{ toggle?: boolean }>`
  padding-top: ${({ theme }) => theme.tokens.space.s24};
  padding-bottom: ${({ theme, toggle }) =>
    toggle ? theme.tokens.space.s16 : theme.tokens.space.s24};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperHeaderIcon = styled(Animated.View)`
  justify-content: center;
  align-items: flex-end;
`;

export const WrapperContent = styled.View`
  padding-bottom: 24px;
`;
