import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Star = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6.12604 21.3891C5.961 21.3673 5.81165 21.2788 5.71136 21.1438C5.61107 21.0089 5.56856 20.8384 5.59297 20.6712L6.52239 14.4607L2.18457 10.0013H2.18479C2.01865 9.8347 1.95848 9.58767 2.02912 9.36158C2.09976 9.13571 2.28922 8.96892 2.51946 8.93055L8.60487 7.92282L11.4431 2.3423V2.34208C11.5493 2.13212 11.7623 2 11.9945 2C12.2269 2 12.4399 2.13212 12.5461 2.34208L15.3844 7.9226L21.4698 8.93033V8.93055C21.7022 8.96521 21.8954 9.13026 21.9689 9.357C22.0423 9.58353 21.9835 9.83294 21.8169 10.0013L17.4791 14.4607L18.4085 20.6711C18.4423 20.904 18.3459 21.1364 18.1582 21.2742C17.9705 21.412 17.723 21.4321 17.5161 21.3263L12.0008 18.492L6.48544 21.3263H6.48566C6.3749 21.3834 6.24954 21.4053 6.12636 21.3891L6.12604 21.3891ZM7.00601 19.6382L11.7158 17.2321H11.7156C11.8911 17.1422 12.098 17.1422 12.2733 17.2321L16.9953 19.6382L16.2021 14.3347H16.2023C16.1759 14.1404 16.24 13.9449 16.3758 13.8056L20.094 9.98859L14.8763 9.11934H14.8761C14.6781 9.08794 14.5074 8.96127 14.4176 8.77922L11.9884 4.00493L9.57156 8.77922C9.48413 8.958 9.31865 9.08423 9.12548 9.11934L3.90775 9.97597L7.62592 13.8056H7.6257C7.76131 13.9449 7.82541 14.1404 7.79925 14.3347L7.00601 19.6382Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default Star;
