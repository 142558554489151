import styled, { css } from 'styled-components/native';

export const Wrapper = styled.View`
  ${({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    height: auto;
    padding-top: 0px;
    margin-top: 0px;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    margin-top: ${tokens.space.s52};
  `}
`;
