import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import {
  ArrowIcon,
  DotContainer,
  NumberContainer,
  NumberItem,
  PageRangeText,
  PaginationContainer,
  PaginationWrapper,
} from './styles';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  variant: 'dots' | 'numbers';
  visiblePages?: number;
  currentPage?: number;
  hideNavigators?: boolean;
  showPageRangeText?: boolean;
}

interface IconProps {
  disabled: boolean;
  onPress: () => void;
  name: string;
  enabled: boolean;
}

export const PaginationIcon: React.FC<IconProps> = ({ disabled, onPress, name, enabled }) => {
  const theme = useTheme();

  return (
    <ArrowIcon disabled={disabled} onPress={onPress} activeOpacity={0.8} accessibilityRole="button">
      <Icon name={name} size={25} color={enabled ? theme.colors.primary40 : theme.colors.primary} />
    </ArrowIcon>
  );
};

/**
 * Pagination Component.
 *
 * @example
 * // Usage example:
 * const handlePageChange = (pageNumber: number) => {
 *   console.log('Selected page:', pageNumber);
 * };
 *
 * <Pagination
 *  totalItems={100}
 *  itemsPerPage={10}
 *  onPageChange={handlePageChange}
 *  variant="numbers"
 * />
 *
 * @param {number} props.totalItems - The total number of items to be paginated.
 * @param {number} props.itemsPerPage - The number of items per page.
 * @param {Function} props.onPageChange - Callback function to handle page change.
 * @param {('dots'|'numbers')} props.variant - The pagination style variant ('dots' or 'numbers').
 * @param {number} [props.visiblePages=5] - The number of visible pages in pagination (only for the 'numbers' variant).
 * @param {number} [props.currentPage=1] - The initial page for pagination.
 * @param {boolean} [props.hideNavigators] - Hides the navigation buttons.
 * @param {boolean} [props.showPageRangeText] - Displays the page range text (e.g., 1 to 10 of 100).
 *
 * @returns {JSX.Element} Pagination Component.
 */
export const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  variant,
  visiblePages = 5,
  currentPage: externalCurrentPage,
  hideNavigators,
  showPageRangeText = false,
}) => {
  const [currentPage, setCurrentPage] = useState(externalCurrentPage || 1);

  useEffect(() => {
    setCurrentPage(externalCurrentPage || 1);
  }, [externalCurrentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  const getPageRangeText = () => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    return `${startItem} até ${endItem} de ${totalItems}`;
  };

  const renderDots = (page: number | string, key: number) => {
    const isCurrentPage = page === currentPage;

    return (
      <DotContainer
        key={key}
        testID="pagination-dot"
        isCurrentPage={isCurrentPage}
        onPress={() => handlePageChange(page as number)}
      />
    );
  };

  const renderNumbers = (page: number | string, key: number) => {
    const isCurrentPage = page === currentPage;

    return (
      <NumberContainer
        key={key}
        activeOpacity={0.8}
        testID="pagination-number"
        isCurrentPage={isCurrentPage}
        onPress={() => handlePageChange(page as number)}>
        <NumberItem isCurrentPage={isCurrentPage}>{page}</NumberItem>
      </NumberContainer>
    );
  };

  const renderFirstPagination = () => {
    const firstNumber = getVisiblePages()[0] >= 2 && (
      <NumberContainer
        key={0}
        activeOpacity={0.8}
        testID="pagination-number"
        isCurrentPage={currentPage === 1}
        onPress={() => handlePageChange(1)}>
        <NumberItem isCurrentPage={currentPage === 1}>1</NumberItem>
      </NumberContainer>
    );

    return (
      <>
        {!hideNavigators && (
          <PaginationIcon
            disabled={currentPage === 1}
            onPress={() => handlePageChange(currentPage - 1)}
            name="chevron-left"
            enabled={currentPage === 1}
          />
        )}

        {firstNumber}
        {getVisiblePages()[0] > 2 && <NumberItem isCurrentPage={false}> ... </NumberItem>}
      </>
    );
  };

  const renderLastPagination = () => {
    const lastNumber =
      getVisiblePages()[visiblePages - 1] < totalPages - 1 ||
      getVisiblePages()[visiblePages - 1] === totalPages - 1 ? (
        <NumberContainer
          key={0}
          activeOpacity={0.8}
          testID="pagination-number"
          isCurrentPage={currentPage === totalPages}
          onPress={() => handlePageChange(totalPages)}>
          <NumberItem isCurrentPage={currentPage === totalPages}>{totalPages}</NumberItem>
        </NumberContainer>
      ) : (
        <></>
      );

    return (
      <>
        {getVisiblePages()[visiblePages - 1] < totalPages - 1 && (
          <NumberItem isCurrentPage={false}> ... </NumberItem>
        )}

        {lastNumber}

        {!hideNavigators && (
          <PaginationIcon
            disabled={currentPage === totalPages}
            onPress={() => handlePageChange(currentPage + 1)}
            name="chevron-right"
            enabled={currentPage === totalPages}
          />
        )}
      </>
    );
  };

  const getVisiblePages = () => {
    const totalVisiblePages = Math.min(visiblePages, totalPages);
    const visiblePagesArray = [];

    let startPage = currentPage - Math.floor(visiblePages / 2);
    startPage = Math.max(startPage, 1);
    startPage = Math.min(startPage, totalPages - visiblePages + 1);

    if (startPage === 0) {
      startPage = 1;
    }

    for (let i = 0; i < totalVisiblePages; i++) {
      visiblePagesArray.push(startPage + i);
    }

    return visiblePagesArray;
  };

  const render = {
    dots: renderDots,
    numbers: renderNumbers,
  };

  return (
    <>
      <PaginationWrapper>
        {renderFirstPagination()}
        {getVisiblePages().map((pageNumber, index) => render[variant](pageNumber, index))}
        {renderLastPagination()}
      </PaginationWrapper>

      {showPageRangeText && (
        <PaginationContainer>
          <PageRangeText>{getPageRangeText()}</PageRangeText>
        </PaginationContainer>
      )}
    </>
  );
};
