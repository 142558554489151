import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import IconFeather from 'react-native-vector-icons/dist/Feather';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import RBSheet from 'react-native-raw-bottom-sheet';
import { PickerOptionsEnum, UploadFieldType } from '../@tem-ui/Uploader/types';
import { Spacing } from '../Spacing';
import { useMedia } from '../@hello-ui';
import * as S from './styles';
import { Files } from './Files';
import { useUploader } from '~/components/@tem-ui/Uploader/useUploader';
import { Typography, Button } from '~/components/@tem-ui';

export const UploadField = ({ mapOnSelect, addOtherFileText }: UploadFieldType): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();

  const refRBSheet = useRef();

  const { files, isLoading, haveLargeSizeFile, addFile } = useUploader();

  const openCameraOrGalleryOptions = () => {
    refRBSheet.current.open();
  };

  const closeCameraOrGalleryOptions = () => {
    media.isMobile && refRBSheet.current.close();
  };

  const selectFile = async (type: PickerOptionsEnum, target?: any) => {
    await addFile(type, target).then(() => {
      closeCameraOrGalleryOptions();
    });
  };

  addOtherFileText = addOtherFileText ?? 'Anexar outro pedido';

  const pickerOptions = [
    {
      name: 'Câmera',
      icon: 'camera',
      type: PickerOptionsEnum.Camera,
      isVisible: Platform.OS !== 'web',
    },
    {
      name: 'Galeria',
      icon: 'image',
      type: Platform.OS === 'ios' ? PickerOptionsEnum.Gallery : PickerOptionsEnum.Archiver,
      isVisible: Platform.OS !== 'web',
    },
    {
      name: 'Arquivos',
      icon: 'file',
      type: PickerOptionsEnum.Archiver,
      isVisible: Platform.OS === 'ios',
    },
  ];

  return (
    <>
      {files && files.length > 0 && (
        <S.WrapperScheduleFile>
          <Spacing top={2} bottom={Platform.OS === 'web' ? 15 : 5}>
            <Typography bold variant="paragraph" color={theme.colors.black}>
              <Icon name="file" color={theme.colors.primary} size={15} /> Arquivos Selecionados:
            </Typography>
          </Spacing>

          <Files />
        </S.WrapperScheduleFile>
      )}
      {media.isMobile || (files && files.length > 0) ? (
        <>
          <Button
            disabled={isLoading || haveLargeSizeFile ? true : false}
            variant="primary"
            title={
              isLoading ? 'Carregando...' : files && files.length > 0 ? 'Próximo' : 'Enviar Foto'
            }
            onPress={() => {
              if (files && files.length && mapOnSelect) {
                mapOnSelect();
              } else {
                openCameraOrGalleryOptions();
              }
            }}
          />
        </>
      ) : (
        <>
          <label>
            <Button variant="primary" title={'Enviar Foto'} onPress={() => {}} />
            <input
              type="file"
              style={{ display: 'none' }}
              accept="image/*,.pdf"
              onChange={async (e) => {
                await selectFile(PickerOptionsEnum.Web, e.target);
              }}
              multiple
            />
          </label>
        </>
      )}
      <Spacing top={5} bottom={10}>
        <>
          {files &&
            files.length > 0 &&
            (media.isMobile ? (
              <S.NewUploadFile
                onPress={async () => {
                  if (media.isMobile) {
                    openCameraOrGalleryOptions();
                  } else {
                    await selectFile(PickerOptionsEnum.Gallery);
                  }
                }}>
                <Typography variant="body2" color="primary" bold>
                  {addOtherFileText}
                </Typography>
              </S.NewUploadFile>
            ) : (
              <>
                <label style={{ textAlign: 'center', marginTop: 30 }}>
                  <Typography variant="body2" color="primary" bold>
                    {addOtherFileText}
                  </Typography>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*,.pdf"
                    onChange={async (e) => {
                      await selectFile(3, e.target);
                    }}
                  />
                </label>
              </>
            ))}
        </>
      </Spacing>
      <RBSheet
        ref={refRBSheet}
        closeOnDragDown={true}
        closeOnPressMask={true}
        customStyles={{
          wrapper: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
          draggableIcon: {
            backgroundColor: '#000',
          },
        }}>
        <S.WrapperDrawer>
          <S.DrawerItems onPress={() => closeCameraOrGalleryOptions()}>
            <Typography variant="body1" color={theme.colors.black} bold>
              <IconFeather name="x-circle" color={theme?.colors.black60} size={23} />
            </Typography>
          </S.DrawerItems>
          {pickerOptions.map(({ icon, type, name, isVisible }) => (
            <>
              {isVisible && (
                <S.DrawerItems
                  onPress={async () => {
                    await selectFile(type);
                  }}>
                  <IconFeather name={icon} color={theme?.colors.blue} size={23} />
                  <S.SpaceItem />
                  <Typography variant="body2" color={theme.colors.black} bold>
                    {name}
                  </Typography>
                </S.DrawerItems>
              )}
            </>
          ))}
        </S.WrapperDrawer>
      </RBSheet>
    </>
  );
};
