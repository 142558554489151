import { Dimensions, Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import tailwindConfig from '../../../../tailwind.config.js';

export const getDimensions = () => {
  const width = Dimensions.get(Platform.OS === 'web' ? 'window' : 'screen').width ?? 0;
  const height = Dimensions.get(Platform.OS === 'web' ? 'window' : 'screen').height ?? 0;

  return { width, height };
};

export const getDeviceTypes = () => {
  const isTablet = useMediaQuery({
    minWidth: tailwindConfig.theme.extend.screenSizes.mobile,
    maxWidth: tailwindConfig.theme.extend.screenSizes.desktop,
  });
  const isDesktop = useMediaQuery({ minWidth: tailwindConfig.theme.extend.screenSizes.desktop });
  const isMobile =
    Platform.OS === 'web'
      ? useMediaQuery({ maxWidth: tailwindConfig.theme.extend.screenSizes.mobile })
      : true;

  return {
    isTablet,
    isDesktop,
    isMobile,
  };
};
