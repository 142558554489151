import { getDeviceTypes } from '~/components/@hello-ui/Theme/utils';

export interface MediaQueryType {
  isTablet: boolean;
  isDesktop: boolean;
  isMobile: boolean;
}

export const useMedia = (): MediaQueryType => {
  return getDeviceTypes();
};
