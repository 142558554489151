import React, { useCallback, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';
import { useAuth } from '~/auth/legacy/useAuth';
import { useApi } from '~/hooks/api';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { Button, Loader, PageWithCard, Typography, useSweetAlert } from '~/components/@hello-ui';
import { capitalize } from '~/utils/strings';

export const MyCreditCards = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const api = useApi();
  const { user } = useAuth();
  const { setCreditCardsList, creditCards } = useRecharge();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [isLoading, setIsLoading] = useState(false);

  const getCreditCards = async () => {
    setIsLoading(true);
    try {
      await api.getCreditCards(user?.cpf.replace(/\D/g, '')).then(({ data }) => {
        setCreditCardsList(data);
      });
    } catch (err) {
      console.error(err);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível carregar seus cartões. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getCreditCards();
    }, []),
  );

  const askConfirmDeleteCard = useCallback(async (id: number) => {
    showSweetAlert(
      'Excluir cartão',
      'Tem certeza que deseja excluir o cartão?',
      'warning',
      false,
      false,
      {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => hideSweetAlert(),
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => deleteCard(id),
          },
        ],
      },
    );
  }, []);

  const deleteCard = async (id: number) => {
    try {
      await api.deleteCreditCard(id);
      getCreditCards();
      showSweetAlert('Tudo certo!', 'Seu cartão foi excluído', 'success', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'OK',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    } catch (err) {
      console.error(err);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível deletar o cartão. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  return (
    <PageWithCard
      simpleOptions={{ title: 'Meus Cartões', onBack: () => navigation.navigate('Dashboard') }}>
      <Typography variant="title" className="mb-8 mobile:mb-4">
        Gerenciar Cartões
      </Typography>
      <Typography variant="body2" className="mb-24">
        Visualizar cartões, cadastrar ou excluir.
      </Typography>
      <View className="mb-80 mobile:mb-40">
        <Button
          variant="simple"
          iconPosition="left"
          className="justify-start px-24 py-16 mobile:py-12"
          onPress={() => navigation.navigate('RegisterCreditCard')}
          icon={<Icon name="plus" size={24} color={theme?.colors.primary} />}
          textStyle={{
            fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
            fontWeight: '700',
            fontSize: 19,
            lineHeight: 28,
            color: theme.colors.paragraph,
          }}>
          Novo cartão
        </Button>
      </View>
      <Typography variant="subtitle" className="mb-24 mobile:mb-16">
        Cartões cadastrados
      </Typography>

      {isLoading && (
        <View className="mb-24">
          <Loader variant="circle-loader" />
        </View>
      )}

      {!isLoading &&
        ((creditCards || []).length === 0 ? (
          <View className="rounded-lg bg-background-gray p-24">
            <Typography variant="helperText">Você ainda não tem cartões cadastrados.</Typography>
          </View>
        ) : (
          creditCards?.map((card, index) => (
            <View
              key={card.id}
              className={clsx(
                'flex-row items-center rounded-lg border-[1px] border-gray-disabled p-16',
                index !== 0 ? (theme.isMobile ? 'mt-8' : 'mt-16') : '',
              )}>
              <Typography variant="body2">
                {capitalize(card.brand ?? '')} **** {card.last_four_digits}
              </Typography>
              <View className="ml-auto shrink-0 flex-row pl-24">
                <Icon name="trash-2" size={22} color={theme?.colors.primary} />
                <Typography
                  variant="link"
                  className="ml-8 text-[16px] leading-[24px] mobile:text-[14px]"
                  onPress={() => askConfirmDeleteCard(card.id)}>
                  excluir
                </Typography>
              </View>
            </View>
          ))
        ))}
    </PageWithCard>
  );
};
