import styled, { css } from 'styled-components/native';

export const WrapperCard = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    width: ${tokens.sizes.full};
    padding-bottom: 5px;
    margin-top: ${tokens.space.s8};
    margin-bottom: ${tokens.space.s8};
    ${isMobile && css`
      width: ${tokens.sizes.full};
      margin-top: ${tokens.space.s4};
      margin-bottom: ${tokens.space.s4};
    `}
  `}
`;
