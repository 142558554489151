import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';

interface MobileAutoSpaceProps {
  heightMobile?: number;
  heightDesktop?: number;
}

export const MobileAutoSpace = ({ heightMobile = 0, heightDesktop = 0 }: MobileAutoSpaceProps) => {
  const { isMobile } = useTheme();
  return (
    <View className="mobile:mt-auto" style={{ height: isMobile ? heightMobile : heightDesktop }} />
  );
};
