import React, { useEffect, useState } from 'react';
import * as publicIp from 'public-ip';
import { Linking } from 'react-native';
import * as S from './styles';
import { useSweetAlert } from '~/components/@tem-ui';
import PrivacyIcon from '~/components/Icons/Privacy';
import { useTemPay } from '~/hooks/temPay';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';

interface TermsConditionsProps {
  onAccept: () => void;
  onDimiss: (v: boolean) => void;
}

export const TermsConditions = ({ onAccept, onDimiss }: TermsConditionsProps): JSX.Element => {
  const api = useApi();
  const { card } = useTemPay();
  const { user } = useAuth();
  const [accepted, setAccepted] = useState(undefined);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const checkTerms = async () => {
    try {
      await api.hasPrivacyPolicy(card?.numero_cartao, user?.cpf, 'CONECTA');
      setAccepted(true);
    } catch (error) {
      if (error?.response?.status === 499) {
        setAccepted(false);
        return;
      }
      showSweetAlert('Ops, algo deu errado', 'Falha ao aceitar termo', 'error', true, false);
      onDimiss(false);
      console.log('Erro ao checar politica', error);
    }
  };

  const acceptPolicyPrivacy = async () => {
    try {
      const ip = await publicIp.v4();
      await api.acceptPrivacyPolicy(card?.numero_cartao, user?.cpf, ip, 'CONECTA');
      onAccept();
      hideSweetAlert();
      onDimiss(false);
    } catch (err) {
      showSweetAlert('Ops, algo deu errado', 'Falha ao aceitar termo', 'error', true, false);
    }
  };

  useEffect(() => {
    if (!accepted && accepted !== undefined) {
      showSweetAlert(
        'TERMOS DE USO E POLÍTICA DE PROTEÇÃO',
        <S.Description>
          <>
            <S.Text>
              Queremos garantir a confiança e transparência na comunicação com você, por isso, para
              continuar é necessário estar de acordo com os{' '}
              <S.TextLink
                onPress={() =>
                  void Linking.openURL(
                    'https://s3.sa-east-1.amazonaws.com/media.meutem/politica/conecta/Termos_de_uso_e_Politica_de_Privacidade_-_Protetor_Saude-1.pdf',
                  )
                }>
                Termos de uso e política de proteção de dados
              </S.TextLink>
            </S.Text>
          </>
        </S.Description>,
        'success',
        false,
        false,
        {
          touchOutside: false,
          icon: <PrivacyIcon />,
          iconTitle: 'Termo e política foi atualizado',
          color: 'primary',
          buttons: [
            {
              text: 'Li e Concordo',
              color: 'white',
              background: 'primary',
              testID: 'accept-button',
              callback: () => void acceptPolicyPrivacy(),
            },
          ],
        },
      );
    }
    if (accepted) {
      onAccept();
    }
  }, [accepted]);

  useEffect(() => {
    checkTerms();
  }, []);

  return <></>;
};
