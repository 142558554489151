import React, { useState } from 'react';
import { PaymentConfirm } from '~/screens/TemPayRecharge/components/PaymentConfirm';
import { Typography, useSweetAlert } from '~/components/@hello-ui';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useTemPay } from '~/hooks/temPay';
import { useAuthStore } from '~/store/auth.store';

export const PaymentPix = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onixCode = useAuthStore((st) => st.selectedProduct!.onixCode);

  const { card } = useTemPay();
  const { setRechargeStep, rechargeAmount, setPixCode, setPixQrCode, setPixExpiresAt } =
    useRecharge();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const onConfirm = async () => {
    setIsLoading(true);

    try {
      const res = await OlimpoService.recharge.rechargeByPix({
        amount: rechargeAmount,
        onix_code: onixCode,
        cardNumber: card?.numero_cartao as string,
      });

      setPixQrCode(res.data.qrCode);
      setPixCode(res.data.code);
      setPixExpiresAt(res.data.expiresAt);

      setRechargeStep(7);
    } catch (err) {
      console.log(err, err?.response?.data);

      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível fazer a recarga. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaymentConfirm
      title="Pagamento por Pix"
      onConfirm={onConfirm}
      isLoading={isLoading}
      description="Escaneie o QR Code ou copie o código Pix na próxima etapa e faça o pagamento no banco ou
          carteira digital de sua escolha. O QR Code e código tem validade de 1 hora."
    />
  );
};
