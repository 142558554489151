import React from 'react';
import { IconProps } from '~/components/Icons/types';

const CircleGoBackIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width ?? 40}
      height={height ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_1_18)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0C8.95431 -2.7141e-06 2.7141e-06 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM15.5039 20.4835L26.9732 20.4835C27.5379 20.4835 28 20.0387 28 19.4951C28 18.9514 27.5379 18.5066 26.9732 18.5066H15.5039L20.5147 13.6829C20.9151 13.2974 20.9151 12.6746 20.5147 12.2891C20.1142 11.9036 19.4674 11.9036 19.0669 12.2891L12.3003 18.8031C11.8999 19.1886 11.8999 19.8114 12.3003 20.1969L19.0669 26.7109C19.4674 27.0964 20.1142 27.0964 20.5147 26.7109C20.9151 26.3254 20.9151 25.6928 20.5147 25.3072L15.5039 20.4835Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1_18"
          x="-10"
          y="-10"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_18" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1_18"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CircleGoBackIcon;
