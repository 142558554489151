import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { ServiceCardsTypes } from './types';
import { ServicesCard } from './components/ServicesCards';
import * as S from './styles';
import { Loader, Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';

import Star from '~/components/Icons/Star';
import Family from '~/components/Icons/Family';
import { useAuthStore } from '~/store/auth.store';
import SchedulesIcon from '~/components/Icons/Schedules';
import ProfileIcon from '~/components/Icons/Profile';
import DiscountMedicines from '~/components/Icons/DiscountMedicines';

export const Services = (): JSX.Element => {
  const theme = useTheme();
  const { checkIsEnabledFeature, isDependent } = useAuth();
  const { authStrategy } = useAuthStore();

  const [services, setServices] = useState<ServiceCardsTypes[]>([]);
  const [visibelFeatures, setVisibleFeatures] = useState<boolean>(true);

  const featuresList: ServiceCardsTypes[] = [
    {
      order: 3,
      title: 'Meus Benefícios',
      route: 'MyBenefits',
      description: 'Veja todos seus benefícios',
      icon: <Star />,
      visible: authStrategy !== "CertificateStrategy" ? true : false,
    },
    {
      order: 0,
      title: 'Meus Agendamentos',
      route: 'MyAppointments',
      description: 'Acompanhe seus agendamentos',
      icon: <SchedulesIcon />,
      visible: authStrategy === "CertificateStrategy" ? true : false,
    },
    {
      order: 1,
      title: 'Desconto em Farmácia',
      route: 'PharmacyBenefit',
      description: 'Farmácias credenciadas em todo o Brasil',
      icon: <DiscountMedicines />,
      visible: authStrategy === "CertificateStrategy" ? true : false,
    },{
      order: 2,
      title: 'Gerenciar dependentes',
      route: 'DependentsPresentation',
      description: 'Adicione, visualize ou edite seus dependentes',
      icon: <Family />,
      visible: !isDependent && checkIsEnabledFeature('dependents'),
    },{
      order: 4,
      title: 'Meu Perfil',
      route: 'Profile',
      description: 'Visualize ou edite seus dados',
      icon: <ProfileIcon />,
      visible: authStrategy === "CertificateStrategy" ? true : false,
    },
  ];

  useEffect(() => {
    setVisibleFeatures(false);
    const visibleFeatures = featuresList.filter((feature) => feature.visible === true);
    setServices(visibleFeatures);
  }, [isDependent]);

  useEffect(() => {
    if (services.length) {
      setVisibleFeatures(true);
    }
  }, [services]);

  return (
    <S.WrapperContent>
      <Typography variant="subtitle" color={theme.colors.black}>
        {authStrategy === "CertificateStrategy" ? 'O que deseja fazer?' : 'Serviços'}
      </Typography>
      <S.WrapperServicesContent>
        {!visibelFeatures || isDependent === null ? (
          <Loader variant="circle-loader" />
        ) : (
          <ServicesCard servicesFilter={services} />
        )}
      </S.WrapperServicesContent>
    </S.WrapperContent>
  );
};
