import React, { ReactElement } from 'react';

import { View } from 'react-native';
import { LimitedContent } from '../styles';
import * as S from '~/components/@hello-ui/Dialog/components/DialogHeader/styles';

import { Typography } from '~/components/@hello-ui/Typography';

export interface DialogHeaderProps {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ title, subtitle }) => {
  return (
    <>
      <S.Wrapper testID="dialog-header-padding">
        <LimitedContent>
          {title && (
            <Typography variant="title" testID="title">
              {title}
            </Typography>
          )}

          {subtitle && (
            <View className="mt-4">
              <Typography variant="body2" testID="subtitle">
                {subtitle}
              </Typography>
            </View>
          )}
        </LimitedContent>
      </S.Wrapper>
    </>
  );
};

export default DialogHeader;
