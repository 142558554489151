import { View } from 'react-native';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { RechargeValue } from '~/screens/TemPayRecharge/components/RechargeValue';
import { Button, MobileAutoSpace, Typography } from '~/components/@hello-ui';

export interface IPaymentReviewProps {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const PaymentConfirm = ({
  title,
  description,
  onConfirm,
  isLoading,
}: IPaymentReviewProps) => {
  const theme = useTheme();

  return (
    <>
      <View className="mb-80 mobile:mb-[93px]">
        <RechargeValue />
      </View>
      <Typography
        variant="title"
        className="mb-24 text-center"
        style={{ color: theme.colors.title }}>
        {title}
      </Typography>

      {typeof description === 'string' ? (
        <Typography variant="body2" className="text-center">
          {description}
        </Typography>
      ) : (
        description
      )}

      <MobileAutoSpace heightMobile={40} heightDesktop={52} />
      <Button variant="primary" onPress={onConfirm} disabled={isLoading} loading={isLoading}>
        Confirmar
      </Button>
    </>
  );
};
