import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const Wrapper = styled.View`
  width: 100%;
  max-width: 900px;
  height: 850px;
  align-self: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-horizontal: 340px;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    box-shadow: 1px 1px 5px ${StyledDefaultTheme.colors.gray};
    margin-vertical: 10px;
    border-radius: 20px;
  `}
`;

export const WrapperBackButton = styled.TouchableOpacity`
  color: ${({ theme: { colors } }) => colors.white};
  position: absolute;
  z-index: 1;
  left: 32px;
`;

export const Logo = styled.View`
  position: absolute;
  z-index: 1;
  margin: auto;
`;

export const SwiperView = styled.View`
  height: 850px;
  border-top-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const Header = styled.View`
  flex-direction: row;
  width: 100%;
  height: 96px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.white};
  position: absolute;
  top: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

export const ButtonSwiper = styled.TouchableOpacity`
  margin: 64px;
  margin-bottom: 0px;
  position: absolute;
  ${(p) => (p.left ? 'left: 64px;' : 'right: 64px;')}
`;
