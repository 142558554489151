import React from 'react';
import { Platform, StatusBar } from 'react-native';
import { StyledDefaultTheme } from '../@hello-ui';
import * as S from './styles';
import ActivityIndicator from '~/components/@tem-ui/ActivityIndicator';

export const Loading = (): JSX.Element => {
  return (
    <S.WrapperBackground testID="login-page">
      <StatusBar barStyle={`${Platform.OS === 'android' ? 'light' : 'dark'}-content`} />

      <S.Wrapper>
        <ActivityIndicator color={StyledDefaultTheme.colors.success} />
      </S.Wrapper>
    </S.WrapperBackground>
  );
};
