import styled from 'styled-components/native';
import { ImageBackground } from 'react-native';

import image from '~/assets/images/benefits-funerals.jpg';
import image2 from '~/assets/images/logo-memorial.png';

export interface FilterProps {
  color: string;
  opacity: number;
}

export interface RectangleProps {
  width: number;
  height: number;
}

export const WrapperHeader = styled(ImageBackground).attrs({
  source: image,
})`
  width: 100%;
  height: 568px;
  /* filter: contrast(4) brightness(0.6) sepia(0.3); */
`;

export const ImageFIlter = styled.View<FilterProps>`
  width: 100%;
  height: 568px;
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  overflow: hidden;
`;

export const HeaderIcon = styled.TouchableOpacity`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-top: 26px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const HeaderContent = styled.View`
  position: absolute;
  align-self: center;
  justify-content: flex-end;
  margin-top: 380px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: auto;
`;

export const WrapperContent = styled.View`
  align-items: center;
  margin: 50px 20px;
  height: 280px;
  justify-content: space-between;
  text-align: center;
`;

export const WrapperCard = styled.View`
  align-items: center;
  padding: 10px 20px;
  height: 100%;
  justify-content: space-between;
  text-align: center;
`;

export const ImageCard = styled(ImageBackground).attrs({
  source: image2,
})`
  width: 189px;
  height: 45px;
`;

export const Rectangle1 = styled.View<RectangleProps>`
  position: absolute;
  right: 0;
  top: ${(props) => -(props.width / 2)}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${({ theme: { colors } }) => colors.white25};
  border-radius: ${(props) => props.width / 2}px;
`;

export const Rectangle2 = styled.View<RectangleProps>`
  position: absolute;
  top: ${(props) => props.height / 2 - props.width}px;
  right: ${(props) => -(props.height / 2 - props.width / 2)}px;
  width: ${(props) => props.height}px;
  height: ${(props) => props.width}px;
  background-color: ${({ theme: { colors } }) => colors.white25};
  border-radius: ${(props) => props.width / 2}px;
`;

export const Line = styled.View`
  flex-direction: row;
`;
