import React, { useRef } from 'react';
import { Modal } from 'react-native';
import { TextInput, HelperText } from 'react-native-paper';
import { useTheme } from 'styled-components/native';
import * as S from '../styles';

export const MaterialSelect = React.forwardRef((props: any, ref) => {
  const theme = useTheme();
  const refWrapper = useRef();

  const [visible, setVisible] = React.useState(false);
  const [menuPos, setMenuPos] = React.useState({});

  const calculatePostion = async () => {
    return new Promise<void>((resolve) => {
      refWrapper?.current?.measure(
        (x: any, y: number, width: any, height: any, pageX: any, pageY: number) => {
          setMenuPos({ x, y, width, height, left: pageX, top: pageY - y });
          resolve();
        },
      );
    });
  };

  const openMenu = () => {
    void calculatePostion().then(() => {
      setVisible(true);
    });
  };

  const closeMenu = () => {
    setVisible(false);
  };

  return (
    <S.MaterialTextInputWrapper style={props.style}>
      <S.SelectWrapper onPress={openMenu} ref={refWrapper}>
        <TextInput
          ref={ref}
          {...props}
          outlineColor={props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white15}
          placeholderTextColor={theme?.colors.white15}
          editable={false}
          theme={{
            colors: {
              primary: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white,
              placeholder:
                props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white15,
              text: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white,
              background: props.variant === 'light' ? theme?.colors.white : 'rgba(64,64,64, 0.8)',
              disabled: props.variant === 'light' ? theme?.colors.black60 : theme?.colors.white,
            },
          }}
          value={
            props.value
              ? props.options.find((o: any) => o.value === props.value)?.label
              : props.value
          }
          mode="outlined"
          onPressIn={openMenu}
        />
        <S.SelectIcon name="chevron-down" size={16} />
        <Modal
          visible={visible}
          onDismiss={closeMenu}
          animationType="fade"
          presentationStyle="overFullScreen"
          transparent={true}>
          <S.ModalContent
            style={{
              width: menuPos.width,
              top: menuPos.top + 64,
              left: menuPos.left,
              zIndex: 1,
            }}>
            <S.SelectFlatList
              data={props.options}
              renderItem={({ item }: any) => (
                <S.Option
                  selected={props.value === item.value}
                  onPress={() => {
                    props.onChangeText(item.value);
                    closeMenu();
                  }}>
                  <S.OptionText>{item.label}</S.OptionText>
                </S.Option>
              )}
              keyExtractor={(o) => o.value}
            />
          </S.ModalContent>
          <S.ModalBackdrop onPress={closeMenu} />
        </Modal>
      </S.SelectWrapper>
      {props.error && <HelperText type="error">{props.error.message}</HelperText>}
    </S.MaterialTextInputWrapper>
  );
});
