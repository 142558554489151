import React, { ReactNode } from 'react';
import { View } from 'react-native';

interface TitleLeftIconRendererProps {
  titleStartContent?: ReactNode;
}

export const TitleStartContentRenderer = ({ titleStartContent }: TitleLeftIconRendererProps) => {
  return <>{titleStartContent && <View className="mr-8">{titleStartContent}</View>}</>;
};
