import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const MenuIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M4 6H20"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.40721"
        strokeLinecap="round"
      />
      <Path
        d="M4 12H20"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.40721"
        strokeLinecap="round"
      />
      <Path
        d="M4 18H20"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.40721"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default MenuIcon;
