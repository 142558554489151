import React, { Fragment, PropsWithChildren, ReactElement, useMemo } from 'react';
import { Platform, View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { BackButton } from '~/screens/Login/components/PageWrapper/styles';
import { useCalculateMargins } from '~/screens/Login/components/PageWrapper/useCalculateMargins';

interface PageWrapperProps extends PropsWithChildren {
  bellowButtonComponent?: ReactElement;
  bellowButtonHeight?: number;
  onGoBack?: () => void;
  className?: string;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  bellowButtonComponent,
  bellowButtonHeight = 52,
  onGoBack,
  className,
}) => {
  const theme = useTheme();

  const memoizedBellowButtonComponent = useMemo(
    () => !!bellowButtonComponent,
    [bellowButtonComponent],
  );
  const memoizedBellowButtonHeight = useMemo(() => bellowButtonHeight, [bellowButtonHeight]);

  const { setContentSize, topMargin, bottomMargin } = useCalculateMargins(
    memoizedBellowButtonComponent,
    memoizedBellowButtonHeight,
  );

  return (
    <Fragment>
      {onGoBack && (
        <View className="absolute left-[13%] top-[70px] z-[1] mobile:left-16 mobile:top-16">
          <BackButton onPress={onGoBack}>
            <Icon name="arrow-left" size={24} color={theme.colors.white} />
          </BackButton>
        </View>
      )}

      <View
        style={{
          height: topMargin,
        }}
      />

      <View
        className={`relative w-full flex-shrink-0 items-center px-16 ${className}`}
        onLayout={setContentSize}>
        <View className="min-h-[464px] w-full max-w-[530px] mobile:min-h-[402px]">{children}</View>
        {bellowButtonComponent && (
          <View
            className="absolute z-10 w-full items-center justify-center"
            style={{
              bottom: -bellowButtonHeight,
              height: bellowButtonHeight,
              left: Platform.OS === 'web' ? 0 : 16,
            }}>
            {bellowButtonComponent}
          </View>
        )}
      </View>

      <View
        style={{
          zIndex: -1,
          height: bottomMargin,
        }}
      />
    </Fragment>
  );
};
