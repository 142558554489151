import * as React from 'react';
import Svg, { Mask, Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const PetTransportIcon = ({ width = 24, height = 24, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Mask
        id="path-1-outside-1_7974_94583"
        maskUnits="userSpaceOnUse"
        x="-0.632812"
        y="3.36328"
        width="25"
        height="17"
        fill="black">
        <Rect fill="white" x="-0.632812" y="3.36328" width="25" height="17" />
        <Path d="M3.73529 18.0869H0.826194C0.729752 18.0869 0.637259 18.0486 0.569064 17.9804C0.500869 17.9122 0.462558 17.8197 0.462558 17.7233V4.72692C0.462558 4.63048 0.500869 4.53798 0.569064 4.46979C0.637259 4.40159 0.729752 4.36328 0.826194 4.36328H16.368C16.4645 4.36328 16.5569 4.40159 16.6251 4.46979C16.6933 4.53798 16.7316 4.63048 16.7316 4.72692V6.42146C16.7451 6.47508 16.7461 6.53106 16.7347 6.58514C16.7232 6.63923 16.6996 6.68999 16.6656 6.73357C16.6316 6.77715 16.5881 6.8124 16.5384 6.83665C16.4888 6.8609 16.4342 6.8735 16.3789 6.8735C16.3236 6.8735 16.2691 6.8609 16.2194 6.83665C16.1697 6.8124 16.1262 6.77715 16.0922 6.73357C16.0582 6.68999 16.0346 6.63923 16.0232 6.58514C16.0117 6.53106 16.0128 6.47508 16.0262 6.42146V5.07964H1.16801V17.3815H3.73529C3.81391 17.4012 3.8837 17.4466 3.93357 17.5105C3.98344 17.5744 4.01052 17.6531 4.01052 17.7342C4.01052 17.8152 3.98344 17.894 3.93357 17.9579C3.8837 18.0218 3.81391 18.0672 3.73529 18.0869Z" />
        <Path d="M16.4844 18.0869H7.39347C7.31484 18.0672 7.24505 18.0218 7.19518 17.9579C7.14532 17.894 7.11823 17.8152 7.11823 17.7342C7.11823 17.6531 7.14532 17.5744 7.19518 17.5105C7.24505 17.4466 7.31484 17.4012 7.39347 17.3815H16.4844C16.563 17.4012 16.6328 17.4466 16.6827 17.5105C16.7325 17.5744 16.7596 17.6531 16.7596 17.7342C16.7596 17.8152 16.7325 17.894 16.6827 17.9579C16.6328 18.0218 16.563 18.0672 16.4844 18.0869Z" />
        <Path d="M23.3716 18.0869H22.0225C21.9439 18.0672 21.8741 18.0218 21.8243 17.9579C21.7744 17.894 21.7473 17.8153 21.7473 17.7342C21.7473 17.6531 21.7744 17.5744 21.8243 17.5105C21.8741 17.4466 21.9439 17.4012 22.0225 17.3815H23.0298V11.6215L19.5462 6.77419H16.7244L16.8262 17.3815H18.3316C18.4103 17.4012 18.4801 17.4466 18.5299 17.5105C18.5798 17.5744 18.6069 17.6531 18.6069 17.7342C18.6069 17.8153 18.5798 17.894 18.5299 17.9579C18.4801 18.0218 18.4103 18.0672 18.3316 18.0869H16.4771C16.3806 18.0869 16.2882 18.0486 16.22 17.9804C16.1518 17.9122 16.1135 17.8197 16.1135 17.7233L15.9971 6.41056C15.9971 6.31411 16.0354 6.22162 16.1036 6.15343C16.1718 6.08523 16.2643 6.04692 16.3607 6.04692H19.7353C19.7914 6.0479 19.8466 6.06188 19.8964 6.08774C19.9463 6.11361 19.9894 6.15067 20.0225 6.19601L23.6589 11.2869C23.702 11.346 23.7249 11.4174 23.7244 11.4906V17.7342C23.7216 17.8269 23.6836 17.915 23.618 17.9806C23.5525 18.0461 23.4643 18.0842 23.3716 18.0869Z" />
        <Path d="M23.3716 15.1051H0.826187C0.772257 15.1198 0.715674 15.1218 0.660833 15.111C0.605991 15.1003 0.554367 15.077 0.509969 15.0431C0.465571 15.0091 0.429594 14.9654 0.404831 14.9153C0.380069 14.8652 0.367188 14.8101 0.367188 14.7542C0.367187 14.6983 0.380069 14.6432 0.404831 14.5931C0.429594 14.543 0.465571 14.4993 0.509969 14.4653C0.554367 14.4314 0.605991 14.4081 0.660833 14.3974C0.715674 14.3866 0.772257 14.3886 0.826187 14.4033H23.3716C23.4256 14.3886 23.4822 14.3866 23.537 14.3974C23.5918 14.4081 23.6435 14.4314 23.6879 14.4653C23.7323 14.4993 23.7682 14.543 23.793 14.5931C23.8178 14.6432 23.8306 14.6983 23.8306 14.7542C23.8306 14.8101 23.8178 14.8652 23.793 14.9153C23.7682 14.9654 23.7323 15.0091 23.6879 15.0431C23.6435 15.077 23.5918 15.1003 23.537 15.111C23.4822 15.1218 23.4256 15.1198 23.3716 15.1051Z" />
        <Path d="M5.55347 19.9306C5.12194 19.9306 4.70011 19.8026 4.34131 19.5629C3.98252 19.3231 3.70287 18.9824 3.53773 18.5837C3.37259 18.185 3.32939 17.7463 3.41357 17.3231C3.49776 16.8999 3.70556 16.5111 4.01069 16.206C4.31582 15.9008 4.70458 15.693 5.12782 15.6088C5.55105 15.5247 5.98974 15.5679 6.38841 15.733C6.78709 15.8981 7.12784 16.1778 7.36758 16.5366C7.60732 16.8954 7.73529 17.3172 7.73529 17.7487C7.73529 18.3274 7.50542 18.8823 7.09625 19.2915C6.68708 19.7007 6.13212 19.9306 5.55347 19.9306ZM5.55347 16.2469C5.25844 16.2469 4.97004 16.3345 4.72479 16.4984C4.47953 16.6624 4.28844 16.8955 4.1757 17.1681C4.06297 17.4408 4.03366 17.7407 4.09148 18.03C4.1493 18.3193 4.29166 18.585 4.50053 18.7933C4.7094 19.0017 4.97539 19.1434 5.26484 19.2005C5.55429 19.2576 5.85417 19.2276 6.12654 19.1142C6.3989 19.0008 6.6315 18.8091 6.79488 18.5635C6.95827 18.3178 7.0451 18.0292 7.04438 17.7342C7.04341 17.3394 6.88591 16.9611 6.60642 16.6823C6.32692 16.4035 5.94825 16.2469 5.55347 16.2469Z" />
        <Path d="M20.1826 19.9306C19.751 19.9306 19.3292 19.8026 18.9704 19.5629C18.6116 19.3231 18.332 18.9824 18.1668 18.5837C18.0017 18.185 17.9585 17.7463 18.0427 17.3231C18.1268 16.8999 18.3346 16.5111 18.6398 16.206C18.9449 15.9008 19.3337 15.693 19.7569 15.6088C20.1801 15.5247 20.6188 15.5679 21.0175 15.733C21.4162 15.8981 21.7569 16.1778 21.9967 16.5366C22.2364 16.8954 22.3644 17.3172 22.3644 17.7487C22.3644 18.3274 22.1345 18.8823 21.7253 19.2915C21.3162 19.7007 20.7612 19.9306 20.1826 19.9306ZM20.1826 16.2469C19.8874 16.2462 19.5986 16.3331 19.3529 16.4967C19.1071 16.6602 18.9155 16.893 18.8022 17.1656C18.6889 17.4382 18.6591 17.7382 18.7165 18.0278C18.7739 18.3173 18.916 18.5833 19.1247 18.7921C19.3334 19.0008 19.5994 19.1429 19.889 19.2003C20.1785 19.2577 20.4786 19.2278 20.7511 19.1145C21.0237 19.0013 21.2565 18.8096 21.4201 18.5639C21.5836 18.3181 21.6705 18.0294 21.6698 17.7342C21.6689 17.34 21.5119 16.9623 21.2332 16.6836C20.9544 16.4049 20.5767 16.2479 20.1826 16.2469Z" />
        <Path d="M21.3826 11.2215H17.7898C17.6934 11.2215 17.6009 11.1832 17.5327 11.115C17.4645 11.0468 17.4262 10.9543 17.4262 10.8578V7.89783C17.4262 7.80138 17.4645 7.70889 17.5327 7.6407C17.6009 7.5725 17.6934 7.53419 17.7898 7.53419H19.2698C19.3257 7.53475 19.3806 7.54816 19.4305 7.57339C19.4803 7.59861 19.5236 7.63497 19.5571 7.67965L21.6698 10.6542C21.7034 10.7077 21.7225 10.7691 21.7252 10.8323C21.7278 10.8954 21.714 10.9582 21.685 11.0143C21.656 11.0705 21.6128 11.1181 21.5598 11.1526C21.5068 11.187 21.4457 11.207 21.3826 11.2106V11.2215ZM18.1426 10.516H20.688L19.0771 8.25056H18.1317L18.1426 10.516Z" />
        <Path d="M6.31346 10.8978C6.08478 10.8794 5.8646 10.8029 5.67371 10.6756C5.48283 10.5484 5.32755 10.3745 5.22255 10.1706C5.0653 9.90755 4.98938 9.60389 5.00437 9.29783C5.00677 9.1478 5.04703 9.00081 5.1214 8.87049C5.19578 8.74017 5.30187 8.63075 5.42982 8.55237C5.56181 8.48171 5.7092 8.44474 5.85891 8.44474C6.00862 8.44474 6.15602 8.48171 6.288 8.55237C6.55784 8.69628 6.78196 8.91292 6.93493 9.17773C7.0879 9.44253 7.16361 9.74491 7.15346 10.0506C7.15006 10.2004 7.10939 10.347 7.0351 10.4771C6.96082 10.6073 6.85527 10.7169 6.728 10.796C6.60133 10.8662 6.45823 10.9013 6.31346 10.8978ZM5.82982 9.14146C5.81188 9.13728 5.79322 9.13728 5.77527 9.14146C5.75021 9.16448 5.73041 9.19265 5.71723 9.22403C5.70405 9.25541 5.6978 9.28926 5.69891 9.32328C5.69411 9.48992 5.73837 9.6543 5.82618 9.79601C6.008 10.116 6.27709 10.2178 6.368 10.1596C6.39345 10.1371 6.41328 10.1089 6.42591 10.0774C6.43854 10.0458 6.44361 10.0117 6.44073 9.97783C6.44641 9.81158 6.40344 9.64728 6.31709 9.5051C6.23114 9.35775 6.10501 9.23793 5.95346 9.15965C5.91373 9.14596 5.8718 9.13979 5.82982 9.14146Z" />
        <Path d="M7.33892 8.87964C6.77892 8.87964 6.29892 8.32692 6.22255 7.57782C6.14619 6.82873 6.54983 6.12328 7.14255 6.06873C7.30321 6.05803 7.46402 6.0873 7.6106 6.15393C7.75719 6.22056 7.88498 6.32247 7.98255 6.45055C8.1893 6.71472 8.31555 7.03286 8.34619 7.36692C8.42255 8.15601 8.01892 8.82146 7.42619 8.87601L7.33892 8.87964ZM7.22619 6.76692C7.08074 6.76692 6.89528 7.07601 6.93892 7.49419C6.98255 7.91237 7.2371 8.17419 7.36801 8.15964C7.49892 8.1451 7.69892 7.85055 7.65528 7.43237C7.63968 7.23844 7.56903 7.05299 7.45164 6.89782C7.42422 6.86322 7.39014 6.83445 7.35142 6.81321C7.3127 6.79198 7.27012 6.77871 7.22619 6.77419V6.76692Z" />
        <Path d="M9.53165 8.87965C8.93529 8.87965 8.46256 8.26874 8.46256 7.47965C8.46256 6.69055 8.92801 6.06874 9.52438 6.06874C10.1207 6.06874 10.5862 6.69055 10.5862 7.47965C10.5862 8.26874 10.128 8.87965 9.53165 8.87965ZM9.53165 6.76692C9.38619 6.76692 9.16801 7.04328 9.16801 7.47237C9.16801 7.90146 9.38256 8.17783 9.53165 8.17783C9.68074 8.17783 9.89529 7.90146 9.89529 7.47237C9.89529 7.04328 9.6771 6.77419 9.53165 6.77419V6.76692Z" />
        <Path d="M10.7571 10.8724C10.6455 10.8734 10.535 10.8498 10.4335 10.8033C10.3006 10.7354 10.1874 10.6345 10.1047 10.5104C10.0219 10.3862 9.97235 10.2429 9.96073 10.0942C9.93378 9.79366 9.98687 9.49135 10.1146 9.21799C10.2424 8.94463 10.4402 8.70996 10.688 8.53783C10.8074 8.4476 10.9492 8.3917 11.0981 8.37616C11.247 8.36062 11.3973 8.38604 11.5327 8.44966C11.6682 8.51329 11.7838 8.61271 11.8669 8.73719C11.95 8.86167 11.9975 9.00648 12.0044 9.15601C12.0367 9.45823 11.9825 9.76342 11.848 10.036C11.7228 10.3124 11.5225 10.548 11.2698 10.716C11.1166 10.8143 10.9391 10.8684 10.7571 10.8724ZM11.2116 9.08328C11.1607 9.0875 11.1118 9.10506 11.0698 9.13419C10.931 9.22969 10.8215 9.36188 10.7535 9.51601C10.6741 9.66572 10.6364 9.83401 10.6444 10.0033C10.6444 10.0978 10.6916 10.1524 10.7171 10.1633C10.7426 10.1742 10.808 10.1633 10.888 10.116C11.0243 10.0255 11.1336 9.89984 11.2044 9.75237C11.2891 9.59686 11.3258 9.41967 11.3098 9.24328C11.31 9.21275 11.3039 9.1825 11.2921 9.15437C11.2802 9.12624 11.2627 9.10082 11.2407 9.07964L11.2116 9.08328Z" />
        <Path d="M9.42255 13.4106C9.23769 13.4123 9.05367 13.3854 8.8771 13.3306C8.45481 13.2225 8.01212 13.2225 7.58983 13.3306C7.45014 13.3606 7.30685 13.3704 7.16437 13.3596C6.95355 13.3695 6.74324 13.3314 6.54925 13.2483C6.35526 13.1651 6.18265 13.0391 6.04437 12.8796C5.95833 12.7561 5.90205 12.6144 5.87997 12.4655C5.85789 12.3166 5.8706 12.1646 5.9171 12.0215C6.01598 11.6697 6.23629 11.3644 6.53892 11.1596C6.96723 10.8238 7.35635 10.4408 7.69892 10.0178C7.81499 9.88582 7.96125 9.78381 8.12523 9.7205C8.28921 9.65719 8.46607 9.63444 8.64074 9.65419C8.86288 9.6797 9.07639 9.75509 9.2653 9.87473C9.45421 9.99437 9.61363 10.1552 9.73165 10.3451L10.328 11.2106C10.6698 11.6287 11.088 12.2433 10.8553 12.7778C10.6226 13.3124 9.81528 13.3996 9.55346 13.4178L9.42255 13.4106ZM8.33165 12.5487C8.59025 12.5461 8.84776 12.5829 9.09528 12.6578C9.23809 12.6989 9.38718 12.7137 9.53528 12.7015C10.1462 12.6578 10.2298 12.5051 10.2371 12.4869C10.2698 12.4106 10.2153 12.1233 9.80074 11.636V11.6105L9.18983 10.7306C9.12746 10.6194 9.03886 10.5252 8.93175 10.4561C8.82464 10.387 8.70227 10.3452 8.57528 10.3342C8.51411 10.3265 8.45197 10.3344 8.39472 10.3573C8.33747 10.3802 8.28699 10.4173 8.24801 10.4651C7.86521 10.934 7.43098 11.3585 6.95346 11.7306C6.78695 11.8409 6.66564 12.0073 6.61165 12.1996C6.57724 12.286 6.57724 12.3823 6.61165 12.4687C6.6371 12.5051 6.7571 12.6251 7.1971 12.6542C7.27682 12.6637 7.35738 12.6637 7.4371 12.6542C7.72822 12.5873 8.02569 12.552 8.32437 12.5487H8.33165Z" />
      </Mask>
      <Path
        d="M3.73529 18.0869H0.826194C0.729752 18.0869 0.637259 18.0486 0.569064 17.9804C0.500869 17.9122 0.462558 17.8197 0.462558 17.7233V4.72692C0.462558 4.63048 0.500869 4.53798 0.569064 4.46979C0.637259 4.40159 0.729752 4.36328 0.826194 4.36328H16.368C16.4645 4.36328 16.5569 4.40159 16.6251 4.46979C16.6933 4.53798 16.7316 4.63048 16.7316 4.72692V6.42146C16.7451 6.47508 16.7461 6.53106 16.7347 6.58514C16.7232 6.63923 16.6996 6.68999 16.6656 6.73357C16.6316 6.77715 16.5881 6.8124 16.5384 6.83665C16.4888 6.8609 16.4342 6.8735 16.3789 6.8735C16.3236 6.8735 16.2691 6.8609 16.2194 6.83665C16.1697 6.8124 16.1262 6.77715 16.0922 6.73357C16.0582 6.68999 16.0346 6.63923 16.0232 6.58514C16.0117 6.53106 16.0128 6.47508 16.0262 6.42146V5.07964H1.16801V17.3815H3.73529C3.81391 17.4012 3.8837 17.4466 3.93357 17.5105C3.98344 17.5744 4.01052 17.6531 4.01052 17.7342C4.01052 17.8152 3.98344 17.894 3.93357 17.9579C3.8837 18.0218 3.81391 18.0672 3.73529 18.0869Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M16.4844 18.0869H7.39347C7.31484 18.0672 7.24505 18.0218 7.19518 17.9579C7.14532 17.894 7.11823 17.8152 7.11823 17.7342C7.11823 17.6531 7.14532 17.5744 7.19518 17.5105C7.24505 17.4466 7.31484 17.4012 7.39347 17.3815H16.4844C16.563 17.4012 16.6328 17.4466 16.6827 17.5105C16.7325 17.5744 16.7596 17.6531 16.7596 17.7342C16.7596 17.8152 16.7325 17.894 16.6827 17.9579C16.6328 18.0218 16.563 18.0672 16.4844 18.0869Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M23.3716 18.0869H22.0225C21.9439 18.0672 21.8741 18.0218 21.8243 17.9579C21.7744 17.894 21.7473 17.8153 21.7473 17.7342C21.7473 17.6531 21.7744 17.5744 21.8243 17.5105C21.8741 17.4466 21.9439 17.4012 22.0225 17.3815H23.0298V11.6215L19.5462 6.77419H16.7244L16.8262 17.3815H18.3316C18.4103 17.4012 18.4801 17.4466 18.5299 17.5105C18.5798 17.5744 18.6069 17.6531 18.6069 17.7342C18.6069 17.8153 18.5798 17.894 18.5299 17.9579C18.4801 18.0218 18.4103 18.0672 18.3316 18.0869H16.4771C16.3806 18.0869 16.2882 18.0486 16.22 17.9804C16.1518 17.9122 16.1135 17.8197 16.1135 17.7233L15.9971 6.41056C15.9971 6.31411 16.0354 6.22162 16.1036 6.15343C16.1718 6.08523 16.2643 6.04692 16.3607 6.04692H19.7353C19.7914 6.0479 19.8466 6.06188 19.8964 6.08774C19.9463 6.11361 19.9894 6.15067 20.0225 6.19601L23.6589 11.2869C23.702 11.346 23.7249 11.4174 23.7244 11.4906V17.7342C23.7216 17.8269 23.6836 17.915 23.618 17.9806C23.5525 18.0461 23.4643 18.0842 23.3716 18.0869Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M23.3716 15.1051H0.826187C0.772257 15.1198 0.715674 15.1218 0.660833 15.111C0.605991 15.1003 0.554367 15.077 0.509969 15.0431C0.465571 15.0091 0.429594 14.9654 0.404831 14.9153C0.380069 14.8652 0.367188 14.8101 0.367188 14.7542C0.367187 14.6983 0.380069 14.6432 0.404831 14.5931C0.429594 14.543 0.465571 14.4993 0.509969 14.4653C0.554367 14.4314 0.605991 14.4081 0.660833 14.3974C0.715674 14.3866 0.772257 14.3886 0.826187 14.4033H23.3716C23.4256 14.3886 23.4822 14.3866 23.537 14.3974C23.5918 14.4081 23.6435 14.4314 23.6879 14.4653C23.7323 14.4993 23.7682 14.543 23.793 14.5931C23.8178 14.6432 23.8306 14.6983 23.8306 14.7542C23.8306 14.8101 23.8178 14.8652 23.793 14.9153C23.7682 14.9654 23.7323 15.0091 23.6879 15.0431C23.6435 15.077 23.5918 15.1003 23.537 15.111C23.4822 15.1218 23.4256 15.1198 23.3716 15.1051Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M5.55347 19.9306C5.12194 19.9306 4.70011 19.8026 4.34131 19.5629C3.98252 19.3231 3.70287 18.9824 3.53773 18.5837C3.37259 18.185 3.32939 17.7463 3.41357 17.3231C3.49776 16.8999 3.70556 16.5111 4.01069 16.206C4.31582 15.9008 4.70458 15.693 5.12782 15.6088C5.55105 15.5247 5.98974 15.5679 6.38841 15.733C6.78709 15.8981 7.12784 16.1778 7.36758 16.5366C7.60732 16.8954 7.73529 17.3172 7.73529 17.7487C7.73529 18.3274 7.50542 18.8823 7.09625 19.2915C6.68708 19.7007 6.13212 19.9306 5.55347 19.9306ZM5.55347 16.2469C5.25844 16.2469 4.97004 16.3345 4.72479 16.4984C4.47953 16.6624 4.28844 16.8955 4.1757 17.1681C4.06297 17.4408 4.03366 17.7407 4.09148 18.03C4.1493 18.3193 4.29166 18.585 4.50053 18.7933C4.7094 19.0017 4.97539 19.1434 5.26484 19.2005C5.55429 19.2576 5.85417 19.2276 6.12654 19.1142C6.3989 19.0008 6.6315 18.8091 6.79488 18.5635C6.95827 18.3178 7.0451 18.0292 7.04438 17.7342C7.04341 17.3394 6.88591 16.9611 6.60642 16.6823C6.32692 16.4035 5.94825 16.2469 5.55347 16.2469Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M20.1826 19.9306C19.751 19.9306 19.3292 19.8026 18.9704 19.5629C18.6116 19.3231 18.332 18.9824 18.1668 18.5837C18.0017 18.185 17.9585 17.7463 18.0427 17.3231C18.1268 16.8999 18.3346 16.5111 18.6398 16.206C18.9449 15.9008 19.3337 15.693 19.7569 15.6088C20.1801 15.5247 20.6188 15.5679 21.0175 15.733C21.4162 15.8981 21.7569 16.1778 21.9967 16.5366C22.2364 16.8954 22.3644 17.3172 22.3644 17.7487C22.3644 18.3274 22.1345 18.8823 21.7253 19.2915C21.3162 19.7007 20.7612 19.9306 20.1826 19.9306ZM20.1826 16.2469C19.8874 16.2462 19.5986 16.3331 19.3529 16.4967C19.1071 16.6602 18.9155 16.893 18.8022 17.1656C18.6889 17.4382 18.6591 17.7382 18.7165 18.0278C18.7739 18.3173 18.916 18.5833 19.1247 18.7921C19.3334 19.0008 19.5994 19.1429 19.889 19.2003C20.1785 19.2577 20.4786 19.2278 20.7511 19.1145C21.0237 19.0013 21.2565 18.8096 21.4201 18.5639C21.5836 18.3181 21.6705 18.0294 21.6698 17.7342C21.6689 17.34 21.5119 16.9623 21.2332 16.6836C20.9544 16.4049 20.5767 16.2479 20.1826 16.2469Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M21.3826 11.2215H17.7898C17.6934 11.2215 17.6009 11.1832 17.5327 11.115C17.4645 11.0468 17.4262 10.9543 17.4262 10.8578V7.89783C17.4262 7.80138 17.4645 7.70889 17.5327 7.6407C17.6009 7.5725 17.6934 7.53419 17.7898 7.53419H19.2698C19.3257 7.53475 19.3806 7.54816 19.4305 7.57339C19.4803 7.59861 19.5236 7.63497 19.5571 7.67965L21.6698 10.6542C21.7034 10.7077 21.7225 10.7691 21.7252 10.8323C21.7278 10.8954 21.714 10.9582 21.685 11.0143C21.656 11.0705 21.6128 11.1181 21.5598 11.1526C21.5068 11.187 21.4457 11.207 21.3826 11.2106V11.2215ZM18.1426 10.516H20.688L19.0771 8.25056H18.1317L18.1426 10.516Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M6.31346 10.8978C6.08478 10.8794 5.8646 10.8029 5.67371 10.6756C5.48283 10.5484 5.32755 10.3745 5.22255 10.1706C5.0653 9.90755 4.98938 9.60389 5.00437 9.29783C5.00677 9.1478 5.04703 9.00081 5.1214 8.87049C5.19578 8.74017 5.30187 8.63075 5.42982 8.55237C5.56181 8.48171 5.7092 8.44474 5.85891 8.44474C6.00862 8.44474 6.15602 8.48171 6.288 8.55237C6.55784 8.69628 6.78196 8.91292 6.93493 9.17773C7.0879 9.44253 7.16361 9.74491 7.15346 10.0506C7.15006 10.2004 7.10939 10.347 7.0351 10.4771C6.96082 10.6073 6.85527 10.7169 6.728 10.796C6.60133 10.8662 6.45823 10.9013 6.31346 10.8978ZM5.82982 9.14146C5.81188 9.13728 5.79322 9.13728 5.77527 9.14146C5.75021 9.16448 5.73041 9.19265 5.71723 9.22403C5.70405 9.25541 5.6978 9.28926 5.69891 9.32328C5.69411 9.48992 5.73837 9.6543 5.82618 9.79601C6.008 10.116 6.27709 10.2178 6.368 10.1596C6.39345 10.1371 6.41328 10.1089 6.42591 10.0774C6.43854 10.0458 6.44361 10.0117 6.44073 9.97783C6.44641 9.81158 6.40344 9.64728 6.31709 9.5051C6.23114 9.35775 6.10501 9.23793 5.95346 9.15965C5.91373 9.14596 5.8718 9.13979 5.82982 9.14146Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M7.33892 8.87964C6.77892 8.87964 6.29892 8.32692 6.22255 7.57782C6.14619 6.82873 6.54983 6.12328 7.14255 6.06873C7.30321 6.05803 7.46402 6.0873 7.6106 6.15393C7.75719 6.22056 7.88498 6.32247 7.98255 6.45055C8.1893 6.71472 8.31555 7.03286 8.34619 7.36692C8.42255 8.15601 8.01892 8.82146 7.42619 8.87601L7.33892 8.87964ZM7.22619 6.76692C7.08074 6.76692 6.89528 7.07601 6.93892 7.49419C6.98255 7.91237 7.2371 8.17419 7.36801 8.15964C7.49892 8.1451 7.69892 7.85055 7.65528 7.43237C7.63968 7.23844 7.56903 7.05299 7.45164 6.89782C7.42422 6.86322 7.39014 6.83445 7.35142 6.81321C7.3127 6.79198 7.27012 6.77871 7.22619 6.77419V6.76692Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M9.53165 8.87965C8.93529 8.87965 8.46256 8.26874 8.46256 7.47965C8.46256 6.69055 8.92801 6.06874 9.52438 6.06874C10.1207 6.06874 10.5862 6.69055 10.5862 7.47965C10.5862 8.26874 10.128 8.87965 9.53165 8.87965ZM9.53165 6.76692C9.38619 6.76692 9.16801 7.04328 9.16801 7.47237C9.16801 7.90146 9.38256 8.17783 9.53165 8.17783C9.68074 8.17783 9.89529 7.90146 9.89529 7.47237C9.89529 7.04328 9.6771 6.77419 9.53165 6.77419V6.76692Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M10.7571 10.8724C10.6455 10.8734 10.535 10.8498 10.4335 10.8033C10.3006 10.7354 10.1874 10.6345 10.1047 10.5104C10.0219 10.3862 9.97235 10.2429 9.96073 10.0942C9.93378 9.79366 9.98687 9.49135 10.1146 9.21799C10.2424 8.94463 10.4402 8.70996 10.688 8.53783C10.8074 8.4476 10.9492 8.3917 11.0981 8.37616C11.247 8.36062 11.3973 8.38604 11.5327 8.44966C11.6682 8.51329 11.7838 8.61271 11.8669 8.73719C11.95 8.86167 11.9975 9.00648 12.0044 9.15601C12.0367 9.45823 11.9825 9.76342 11.848 10.036C11.7228 10.3124 11.5225 10.548 11.2698 10.716C11.1166 10.8143 10.9391 10.8684 10.7571 10.8724ZM11.2116 9.08328C11.1607 9.0875 11.1118 9.10506 11.0698 9.13419C10.931 9.22969 10.8215 9.36188 10.7535 9.51601C10.6741 9.66572 10.6364 9.83401 10.6444 10.0033C10.6444 10.0978 10.6916 10.1524 10.7171 10.1633C10.7426 10.1742 10.808 10.1633 10.888 10.116C11.0243 10.0255 11.1336 9.89984 11.2044 9.75237C11.2891 9.59686 11.3258 9.41967 11.3098 9.24328C11.31 9.21275 11.3039 9.1825 11.2921 9.15437C11.2802 9.12624 11.2627 9.10082 11.2407 9.07964L11.2116 9.08328Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M9.42255 13.4106C9.23769 13.4123 9.05367 13.3854 8.8771 13.3306C8.45481 13.2225 8.01212 13.2225 7.58983 13.3306C7.45014 13.3606 7.30685 13.3704 7.16437 13.3596C6.95355 13.3695 6.74324 13.3314 6.54925 13.2483C6.35526 13.1651 6.18265 13.0391 6.04437 12.8796C5.95833 12.7561 5.90205 12.6144 5.87997 12.4655C5.85789 12.3166 5.8706 12.1646 5.9171 12.0215C6.01598 11.6697 6.23629 11.3644 6.53892 11.1596C6.96723 10.8238 7.35635 10.4408 7.69892 10.0178C7.81499 9.88582 7.96125 9.78381 8.12523 9.7205C8.28921 9.65719 8.46607 9.63444 8.64074 9.65419C8.86288 9.6797 9.07639 9.75509 9.2653 9.87473C9.45421 9.99437 9.61363 10.1552 9.73165 10.3451L10.328 11.2106C10.6698 11.6287 11.088 12.2433 10.8553 12.7778C10.6226 13.3124 9.81528 13.3996 9.55346 13.4178L9.42255 13.4106ZM8.33165 12.5487C8.59025 12.5461 8.84776 12.5829 9.09528 12.6578C9.23809 12.6989 9.38718 12.7137 9.53528 12.7015C10.1462 12.6578 10.2298 12.5051 10.2371 12.4869C10.2698 12.4106 10.2153 12.1233 9.80074 11.636V11.6105L9.18983 10.7306C9.12746 10.6194 9.03886 10.5252 8.93175 10.4561C8.82464 10.387 8.70227 10.3452 8.57528 10.3342C8.51411 10.3265 8.45197 10.3344 8.39472 10.3573C8.33747 10.3802 8.28699 10.4173 8.24801 10.4651C7.86521 10.934 7.43098 11.3585 6.95346 11.7306C6.78695 11.8409 6.66564 12.0073 6.61165 12.1996C6.57724 12.286 6.57724 12.3823 6.61165 12.4687C6.6371 12.5051 6.7571 12.6251 7.1971 12.6542C7.27682 12.6637 7.35738 12.6637 7.4371 12.6542C7.72822 12.5873 8.02569 12.552 8.32437 12.5487H8.33165Z"
        fill={color ?? theme.colors.primary}
      />
      <Path
        d="M3.73529 18.0869H0.826194C0.729752 18.0869 0.637259 18.0486 0.569064 17.9804C0.500869 17.9122 0.462558 17.8197 0.462558 17.7233V4.72692C0.462558 4.63048 0.500869 4.53798 0.569064 4.46979C0.637259 4.40159 0.729752 4.36328 0.826194 4.36328H16.368C16.4645 4.36328 16.5569 4.40159 16.6251 4.46979C16.6933 4.53798 16.7316 4.63048 16.7316 4.72692V6.42146C16.7451 6.47508 16.7461 6.53106 16.7347 6.58514C16.7232 6.63923 16.6996 6.68999 16.6656 6.73357C16.6316 6.77715 16.5881 6.8124 16.5384 6.83665C16.4888 6.8609 16.4342 6.8735 16.3789 6.8735C16.3236 6.8735 16.2691 6.8609 16.2194 6.83665C16.1697 6.8124 16.1262 6.77715 16.0922 6.73357C16.0582 6.68999 16.0346 6.63923 16.0232 6.58514C16.0117 6.53106 16.0128 6.47508 16.0262 6.42146V5.07964H1.16801V17.3815H3.73529C3.81391 17.4012 3.8837 17.4466 3.93357 17.5105C3.98344 17.5744 4.01052 17.6531 4.01052 17.7342C4.01052 17.8152 3.98344 17.894 3.93357 17.9579C3.8837 18.0218 3.81391 18.0672 3.73529 18.0869Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M16.4844 18.0869H7.39347C7.31484 18.0672 7.24505 18.0218 7.19518 17.9579C7.14532 17.894 7.11823 17.8152 7.11823 17.7342C7.11823 17.6531 7.14532 17.5744 7.19518 17.5105C7.24505 17.4466 7.31484 17.4012 7.39347 17.3815H16.4844C16.563 17.4012 16.6328 17.4466 16.6827 17.5105C16.7325 17.5744 16.7596 17.6531 16.7596 17.7342C16.7596 17.8152 16.7325 17.894 16.6827 17.9579C16.6328 18.0218 16.563 18.0672 16.4844 18.0869Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M23.3716 18.0869H22.0225C21.9439 18.0672 21.8741 18.0218 21.8243 17.9579C21.7744 17.894 21.7473 17.8153 21.7473 17.7342C21.7473 17.6531 21.7744 17.5744 21.8243 17.5105C21.8741 17.4466 21.9439 17.4012 22.0225 17.3815H23.0298V11.6215L19.5462 6.77419H16.7244L16.8262 17.3815H18.3316C18.4103 17.4012 18.4801 17.4466 18.5299 17.5105C18.5798 17.5744 18.6069 17.6531 18.6069 17.7342C18.6069 17.8153 18.5798 17.894 18.5299 17.9579C18.4801 18.0218 18.4103 18.0672 18.3316 18.0869H16.4771C16.3806 18.0869 16.2882 18.0486 16.22 17.9804C16.1518 17.9122 16.1135 17.8197 16.1135 17.7233L15.9971 6.41056C15.9971 6.31411 16.0354 6.22162 16.1036 6.15343C16.1718 6.08523 16.2643 6.04692 16.3607 6.04692H19.7353C19.7914 6.0479 19.8466 6.06188 19.8964 6.08774C19.9463 6.11361 19.9894 6.15067 20.0225 6.19601L23.6589 11.2869C23.702 11.346 23.7249 11.4174 23.7244 11.4906V17.7342C23.7216 17.8269 23.6836 17.915 23.618 17.9806C23.5525 18.0461 23.4643 18.0842 23.3716 18.0869Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M23.3716 15.1051H0.826187C0.772257 15.1198 0.715674 15.1218 0.660833 15.111C0.605991 15.1003 0.554367 15.077 0.509969 15.0431C0.465571 15.0091 0.429594 14.9654 0.404831 14.9153C0.380069 14.8652 0.367188 14.8101 0.367188 14.7542C0.367187 14.6983 0.380069 14.6432 0.404831 14.5931C0.429594 14.543 0.465571 14.4993 0.509969 14.4653C0.554367 14.4314 0.605991 14.4081 0.660833 14.3974C0.715674 14.3866 0.772257 14.3886 0.826187 14.4033H23.3716C23.4256 14.3886 23.4822 14.3866 23.537 14.3974C23.5918 14.4081 23.6435 14.4314 23.6879 14.4653C23.7323 14.4993 23.7682 14.543 23.793 14.5931C23.8178 14.6432 23.8306 14.6983 23.8306 14.7542C23.8306 14.8101 23.8178 14.8652 23.793 14.9153C23.7682 14.9654 23.7323 15.0091 23.6879 15.0431C23.6435 15.077 23.5918 15.1003 23.537 15.111C23.4822 15.1218 23.4256 15.1198 23.3716 15.1051Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M5.55347 19.9306C5.12194 19.9306 4.70011 19.8026 4.34131 19.5629C3.98252 19.3231 3.70287 18.9824 3.53773 18.5837C3.37259 18.185 3.32939 17.7463 3.41357 17.3231C3.49776 16.8999 3.70556 16.5111 4.01069 16.206C4.31582 15.9008 4.70458 15.693 5.12782 15.6088C5.55105 15.5247 5.98974 15.5679 6.38841 15.733C6.78709 15.8981 7.12784 16.1778 7.36758 16.5366C7.60732 16.8954 7.73529 17.3172 7.73529 17.7487C7.73529 18.3274 7.50542 18.8823 7.09625 19.2915C6.68708 19.7007 6.13212 19.9306 5.55347 19.9306ZM5.55347 16.2469C5.25844 16.2469 4.97004 16.3345 4.72479 16.4984C4.47953 16.6624 4.28844 16.8955 4.1757 17.1681C4.06297 17.4408 4.03366 17.7407 4.09148 18.03C4.1493 18.3193 4.29166 18.585 4.50053 18.7933C4.7094 19.0017 4.97539 19.1434 5.26484 19.2005C5.55429 19.2576 5.85417 19.2276 6.12654 19.1142C6.3989 19.0008 6.6315 18.8091 6.79488 18.5635C6.95827 18.3178 7.0451 18.0292 7.04438 17.7342C7.04341 17.3394 6.88591 16.9611 6.60642 16.6823C6.32692 16.4035 5.94825 16.2469 5.55347 16.2469Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M20.1826 19.9306C19.751 19.9306 19.3292 19.8026 18.9704 19.5629C18.6116 19.3231 18.332 18.9824 18.1668 18.5837C18.0017 18.185 17.9585 17.7463 18.0427 17.3231C18.1268 16.8999 18.3346 16.5111 18.6398 16.206C18.9449 15.9008 19.3337 15.693 19.7569 15.6088C20.1801 15.5247 20.6188 15.5679 21.0175 15.733C21.4162 15.8981 21.7569 16.1778 21.9967 16.5366C22.2364 16.8954 22.3644 17.3172 22.3644 17.7487C22.3644 18.3274 22.1345 18.8823 21.7253 19.2915C21.3162 19.7007 20.7612 19.9306 20.1826 19.9306ZM20.1826 16.2469C19.8874 16.2462 19.5986 16.3331 19.3529 16.4967C19.1071 16.6602 18.9155 16.893 18.8022 17.1656C18.6889 17.4382 18.6591 17.7382 18.7165 18.0278C18.7739 18.3173 18.916 18.5833 19.1247 18.7921C19.3334 19.0008 19.5994 19.1429 19.889 19.2003C20.1785 19.2577 20.4786 19.2278 20.7511 19.1145C21.0237 19.0013 21.2565 18.8096 21.4201 18.5639C21.5836 18.3181 21.6705 18.0294 21.6698 17.7342C21.6689 17.34 21.5119 16.9623 21.2332 16.6836C20.9544 16.4049 20.5767 16.2479 20.1826 16.2469Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M21.3826 11.2215H17.7898C17.6934 11.2215 17.6009 11.1832 17.5327 11.115C17.4645 11.0468 17.4262 10.9543 17.4262 10.8578V7.89783C17.4262 7.80138 17.4645 7.70889 17.5327 7.6407C17.6009 7.5725 17.6934 7.53419 17.7898 7.53419H19.2698C19.3257 7.53475 19.3806 7.54816 19.4305 7.57339C19.4803 7.59861 19.5236 7.63497 19.5571 7.67965L21.6698 10.6542C21.7034 10.7077 21.7225 10.7691 21.7252 10.8323C21.7278 10.8954 21.714 10.9582 21.685 11.0143C21.656 11.0705 21.6128 11.1181 21.5598 11.1526C21.5068 11.187 21.4457 11.207 21.3826 11.2106V11.2215ZM18.1426 10.516H20.688L19.0771 8.25056H18.1317L18.1426 10.516Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M6.31346 10.8978C6.08478 10.8794 5.8646 10.8029 5.67371 10.6756C5.48283 10.5484 5.32755 10.3745 5.22255 10.1706C5.0653 9.90755 4.98938 9.60389 5.00437 9.29783C5.00677 9.1478 5.04703 9.00081 5.1214 8.87049C5.19578 8.74017 5.30187 8.63075 5.42982 8.55237C5.56181 8.48171 5.7092 8.44474 5.85891 8.44474C6.00862 8.44474 6.15602 8.48171 6.288 8.55237C6.55784 8.69628 6.78196 8.91292 6.93493 9.17773C7.0879 9.44253 7.16361 9.74491 7.15346 10.0506C7.15006 10.2004 7.10939 10.347 7.0351 10.4771C6.96082 10.6073 6.85527 10.7169 6.728 10.796C6.60133 10.8662 6.45823 10.9013 6.31346 10.8978ZM5.82982 9.14146C5.81188 9.13728 5.79322 9.13728 5.77527 9.14146C5.75021 9.16448 5.73041 9.19265 5.71723 9.22403C5.70405 9.25541 5.6978 9.28926 5.69891 9.32328C5.69411 9.48992 5.73837 9.6543 5.82618 9.79601C6.008 10.116 6.27709 10.2178 6.368 10.1596C6.39345 10.1371 6.41328 10.1089 6.42591 10.0774C6.43854 10.0458 6.44361 10.0117 6.44073 9.97783C6.44641 9.81158 6.40344 9.64728 6.31709 9.5051C6.23114 9.35775 6.10501 9.23793 5.95346 9.15965C5.91373 9.14596 5.8718 9.13979 5.82982 9.14146Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M7.33892 8.87964C6.77892 8.87964 6.29892 8.32692 6.22255 7.57782C6.14619 6.82873 6.54983 6.12328 7.14255 6.06873C7.30321 6.05803 7.46402 6.0873 7.6106 6.15393C7.75719 6.22056 7.88498 6.32247 7.98255 6.45055C8.1893 6.71472 8.31555 7.03286 8.34619 7.36692C8.42255 8.15601 8.01892 8.82146 7.42619 8.87601L7.33892 8.87964ZM7.22619 6.76692C7.08074 6.76692 6.89528 7.07601 6.93892 7.49419C6.98255 7.91237 7.2371 8.17419 7.36801 8.15964C7.49892 8.1451 7.69892 7.85055 7.65528 7.43237C7.63968 7.23844 7.56903 7.05299 7.45164 6.89782C7.42422 6.86322 7.39014 6.83445 7.35142 6.81321C7.3127 6.79198 7.27012 6.77871 7.22619 6.77419V6.76692Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M9.53165 8.87965C8.93529 8.87965 8.46256 8.26874 8.46256 7.47965C8.46256 6.69055 8.92801 6.06874 9.52438 6.06874C10.1207 6.06874 10.5862 6.69055 10.5862 7.47965C10.5862 8.26874 10.128 8.87965 9.53165 8.87965ZM9.53165 6.76692C9.38619 6.76692 9.16801 7.04328 9.16801 7.47237C9.16801 7.90146 9.38256 8.17783 9.53165 8.17783C9.68074 8.17783 9.89529 7.90146 9.89529 7.47237C9.89529 7.04328 9.6771 6.77419 9.53165 6.77419V6.76692Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M10.7571 10.8724C10.6455 10.8734 10.535 10.8498 10.4335 10.8033C10.3006 10.7354 10.1874 10.6345 10.1047 10.5104C10.0219 10.3862 9.97235 10.2429 9.96073 10.0942C9.93378 9.79366 9.98687 9.49135 10.1146 9.21799C10.2424 8.94463 10.4402 8.70996 10.688 8.53783C10.8074 8.4476 10.9492 8.3917 11.0981 8.37616C11.247 8.36062 11.3973 8.38604 11.5327 8.44966C11.6682 8.51329 11.7838 8.61271 11.8669 8.73719C11.95 8.86167 11.9975 9.00648 12.0044 9.15601C12.0367 9.45823 11.9825 9.76342 11.848 10.036C11.7228 10.3124 11.5225 10.548 11.2698 10.716C11.1166 10.8143 10.9391 10.8684 10.7571 10.8724ZM11.2116 9.08328C11.1607 9.0875 11.1118 9.10506 11.0698 9.13419C10.931 9.22969 10.8215 9.36188 10.7535 9.51601C10.6741 9.66572 10.6364 9.83401 10.6444 10.0033C10.6444 10.0978 10.6916 10.1524 10.7171 10.1633C10.7426 10.1742 10.808 10.1633 10.888 10.116C11.0243 10.0255 11.1336 9.89984 11.2044 9.75237C11.2891 9.59686 11.3258 9.41967 11.3098 9.24328C11.31 9.21275 11.3039 9.1825 11.2921 9.15437C11.2802 9.12624 11.2627 9.10082 11.2407 9.07964L11.2116 9.08328Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
      <Path
        d="M9.42255 13.4106C9.23769 13.4123 9.05367 13.3854 8.8771 13.3306C8.45481 13.2225 8.01212 13.2225 7.58983 13.3306C7.45014 13.3606 7.30685 13.3704 7.16437 13.3596C6.95355 13.3695 6.74324 13.3314 6.54925 13.2483C6.35526 13.1651 6.18265 13.0391 6.04437 12.8796C5.95833 12.7561 5.90205 12.6144 5.87997 12.4655C5.85789 12.3166 5.8706 12.1646 5.9171 12.0215C6.01598 11.6697 6.23629 11.3644 6.53892 11.1596C6.96723 10.8238 7.35635 10.4408 7.69892 10.0178C7.81499 9.88582 7.96125 9.78381 8.12523 9.7205C8.28921 9.65719 8.46607 9.63444 8.64074 9.65419C8.86288 9.6797 9.07639 9.75509 9.2653 9.87473C9.45421 9.99437 9.61363 10.1552 9.73165 10.3451L10.328 11.2106C10.6698 11.6287 11.088 12.2433 10.8553 12.7778C10.6226 13.3124 9.81528 13.3996 9.55346 13.4178L9.42255 13.4106ZM8.33165 12.5487C8.59025 12.5461 8.84776 12.5829 9.09528 12.6578C9.23809 12.6989 9.38718 12.7137 9.53528 12.7015C10.1462 12.6578 10.2298 12.5051 10.2371 12.4869C10.2698 12.4106 10.2153 12.1233 9.80074 11.636V11.6105L9.18983 10.7306C9.12746 10.6194 9.03886 10.5252 8.93175 10.4561C8.82464 10.387 8.70227 10.3452 8.57528 10.3342C8.51411 10.3265 8.45197 10.3344 8.39472 10.3573C8.33747 10.3802 8.28699 10.4173 8.24801 10.4651C7.86521 10.934 7.43098 11.3585 6.95346 11.7306C6.78695 11.8409 6.66564 12.0073 6.61165 12.1996C6.57724 12.286 6.57724 12.3823 6.61165 12.4687C6.6371 12.5051 6.7571 12.6251 7.1971 12.6542C7.27682 12.6637 7.35738 12.6637 7.4371 12.6542C7.72822 12.5873 8.02569 12.552 8.32437 12.5487H8.33165Z"
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_7974_94583)"
      />
    </Svg>
  );
};

export default PetTransportIcon;
