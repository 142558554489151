import {
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import React, { ComponentType } from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Button, Typography, Card } from '../@hello-ui';
import * as S from './styles';
import { toCamelCase } from '~/utils/strings';
import { ListItem, ListItemAddress } from '~/components/MapViewList/MapViewListDTO';

import IconMarker from '~/components/Icons/Marker';
import IconMarkerFocused from '~/components/Icons/MarkerFilled';
import IconPhoneCall from '~/components/Icons/PhoneCall';

export type Location = {
  name: string;
  cnpj: string;
  email: string;
  address: ListItemAddress;
};

interface LocationCardProps<T> {
  locationData: ListItem<T>;
  onPressButton?: (item: ListItem<T>) => void;
  onPressWrapper?: (item: ListItem<T>) => void;
  withGap?: boolean;
  cutText?: boolean;
  hasShadow?: boolean;
  hasButton?: boolean;
  focused?: boolean;
  showButtonOnCardFocus?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

export const LocationCard = <T,>({
  onPressButton,
  onPressWrapper,
  locationData,
  withGap,
  wrapperStyle,
  contentStyle,
  style,
  cutText,
  hasButton = true,
  focused = false,
  showButtonOnCardFocus = false,
}: LocationCardProps<T>) => {
  const theme = useTheme();

  const Icon = focused ? IconMarkerFocused : IconMarker;

  const WrapperComponent: ComponentType<TouchableOpacityProps | ViewProps> = onPressWrapper
    ? TouchableOpacity
    : View;

  const gapStyle: StyleProp<ViewStyle> = {
    marginHorizontal: 8,
    marginBottom: 16,
  };

  return (
    <WrapperComponent
      onPress={() => onPressWrapper?.(locationData)}
      disabled={!onPressWrapper}
      style={withGap ? [wrapperStyle, gapStyle] : wrapperStyle}>
      <Card style={style as ViewStyle}>
        <S.Wrapper style={contentStyle}>
          <S.SwitchUnitIconWrapper>
            <Icon size={24} color={theme.colors.primary} />
            <S.SwitchUnitTitleWrapper>
              <Typography variant="subtitle" numberOfLines={cutText ? 1 : undefined}>
                {toCamelCase(locationData.name)}
              </Typography>
            </S.SwitchUnitTitleWrapper>
          </S.SwitchUnitIconWrapper>
          <S.InfoWrapper>
            <Typography variant="body2" numberOfLines={cutText ? 2 : undefined}>
              {`${toCamelCase(locationData.address.street)} - ${toCamelCase(locationData.address.neighborhood)}, ${toCamelCase(locationData.address.city)} - ${locationData.address.state}, ${locationData.address.zipCode}`}
            </Typography>
          </S.InfoWrapper>

          {locationData.telephone && (
            <View className="mt-8 flex-row items-center gap-8">
              <IconPhoneCall color={theme.colors.primary} size={24} />
              <Typography variant="link">{locationData.telephone}</Typography>
            </View>
          )}

          {(hasButton || showButtonOnCardFocus) && (
            <S.ButtonWrapper>
              <Button
                variant="primary"
                style={showButtonOnCardFocus && !focused && { opacity: 0 }}
                disabled={showButtonOnCardFocus && !focused}
                onPress={() => {
                  onPressButton && onPressButton(locationData);
                }}>
                Selecionar
              </Button>
            </S.ButtonWrapper>
          )}
        </S.Wrapper>
      </Card>
    </WrapperComponent>
  );
};
