import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

const PhonePsycoIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
        stroke={theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5 18H18.4615"
        stroke={theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <Path
        d="M10.3846 20H13.6154"
        stroke={theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <Path
        d="M13.9182 7.58141C14.2422 7.3362 14.5978 7.13613 14.9757 6.98673C15.1218 7.98916 14.9267 9.01154 14.4219 9.88987C14.0725 10.4967 13.5854 11.0129 12.9998 11.3967C12 12.0417 12.2196 11.9322 12.2917 12.0417C12.3638 12.151 12.5833 12.2501 13.2671 11.8023C13.9165 11.377 14.4565 10.8047 14.8435 10.1319C15.4665 9.06658 15.6631 7.80494 15.394 6.60049C15.3584 6.47322 15.2279 6.3977 15.0999 6.43036C14.5661 6.59246 13.7078 7.09659 13.2671 7.43845C12.5784 7.91232 13.0857 7.85103 13.1666 7.95832C13.2476 8.06573 13.8115 7.66257 13.919 7.58164L13.9182 7.58141Z"
        fill="#F26144"
        stroke={theme.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M10.7326 11.8006C10.8445 11.869 11.9279 12.4428 12 12.3333C12 12.0417 11.1069 11.4706 10.9999 11.3949C10.4145 11.011 9.92743 10.4949 9.57814 9.88805C9.07546 9.00964 8.88083 7.98843 9.02524 6.98657C9.40271 7.13663 9.75805 7.33702 10.0818 7.58223C10.1891 7.66317 10.7524 8.06575 10.8333 7.95834C10.9142 7.85092 11.125 7.66667 10.3748 7.19337C9.90311 6.89573 9.4345 6.59305 8.90089 6.43073C8.77286 6.39818 8.64234 6.4737 8.60674 6.60086C8.33756 7.805 8.53394 9.06642 9.15651 10.1317C9.54365 10.8039 10.0837 11.3757 10.7326 11.8006Z"
        fill="#F26144"
        stroke={theme.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M17.6558 8.7455C17.1214 8.62073 16.5688 8.59349 16.0247 8.665C14.8867 8.78431 14.8708 8.90684 14.8867 9.04007C14.9167 9.41666 15.0417 9.27292 16.0823 9.14771C16.4772 9.10019 16.8767 9.10561 17.2703 9.16377C16.8944 10.1041 16.2149 10.8917 15.3398 11.4015C14.4103 11.9179 13.3351 12.1096 12.2845 11.946C13.0448 11.0498 13.461 9.91186 13.4583 8.73649C13.4654 7.50252 13.0053 6.31151 12.1702 5.40282C12.0757 5.31016 11.9245 5.31016 11.83 5.40282C10.995 6.3114 10.5348 7.50241 10.5419 8.73649C10.5392 9.91208 10.9555 11.0501 11.716 11.9466C10.6651 12.1113 9.58937 11.9193 8.66032 11.4015C7.78505 10.8916 7.10543 10.1037 6.72956 9.16299C7.1232 9.10505 8.61835 9.18081 9.01338 9.22866C9.14618 9.24374 8.99754 9.17232 9.01338 9.03952C9.02911 8.90671 8.92446 8.76197 8.79166 8.74548C8.24764 8.67387 6.87862 8.6206 6.34438 8.74548C6.21722 8.78096 6.14159 8.91159 6.17425 9.03952C6.54337 10.2175 7.34476 11.2119 8.41727 11.8229C9.19664 12.2666 10.0792 12.4972 10.976 12.4915C11.3201 12.4936 11.6634 12.4591 12.0001 12.3884C13.2236 12.6353 14.4951 12.4346 15.583 11.8229C16.6552 11.2119 17.4563 10.2177 17.8255 9.04007C17.8586 8.91204 17.7831 8.78119 17.6558 8.7455ZM12 5.94569C12.6296 6.73969 12.9722 7.72324 12.9722 8.73661C12.9722 9.74999 12.6296 10.7335 12 11.5275C11.3704 10.7335 11.0279 9.74999 11.0279 8.73661C11.0279 7.72324 11.3704 6.73977 12 5.94569Z"
        fill="#F26144"
        stroke={theme.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M12 14.8126C11.7422 14.8126 11.4949 14.7102 11.3126 14.5279C11.1303 14.3455 11.0279 14.0982 11.0279 13.8404C11.0279 13.5827 11.1303 13.3354 11.3126 13.153C11.495 12.9707 11.7422 12.8683 12 12.8683C12.2578 12.8683 12.5051 12.9707 12.6875 13.153C12.8698 13.3354 12.9722 13.5827 12.9722 13.8404C12.9722 14.0982 12.8698 14.3455 12.6875 14.5279C12.5051 14.7102 12.2578 14.8126 12 14.8126ZM12 13.3544C11.8711 13.3544 11.7474 13.4056 11.6563 13.4967C11.5652 13.5879 11.5139 13.7115 11.5139 13.8404C11.5139 13.9693 11.5652 14.093 11.6563 14.1842C11.7474 14.2753 11.8711 14.3265 12 14.3265C12.1289 14.3265 12.2526 14.2753 12.3437 14.1842C12.4349 14.093 12.4861 13.9693 12.4861 13.8404C12.4861 13.7115 12.4349 13.5879 12.3437 13.4967C12.2526 13.4056 12.1289 13.3544 12 13.3544Z"
        fill="#F26144"
        stroke={theme.colors.primary}
        strokeWidth="0.1"
      />
    </Svg>
  );
};

export default PhonePsycoIcon;
