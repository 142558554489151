import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const Wrapper = styled.View`
  border-top-color: ${({ theme: { colors } }) => colors.black16};
  border-top-width: 0.5px;
`;

export const WrapperHeader = styled.TouchableOpacity`
  height: 56px;
  padding-horizontal: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperHeaderIcon = styled(Animated.View)`
  justify-content: center;
  align-items: flex-end;
`;

export const WrapperContent = styled.View`
  padding-horizontal: 24px;
  padding-bottom: 32px;
`;
