import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';
import desktopBackground from '~/assets/images/benefitsFinancialTeleorientation/desktop.png';
import mobileBackground from '~/assets/images/benefitsFinancialTeleorientation/mobile.png';
import Finance from '~/components/Icons/Finance';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const FinancialTeleorientation = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();

  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        title: 'Orientação Financeira',
        backgroundImage: backgroundImage,
        titleStartContent: <Finance color="white" height={24} width={24} />,
        onBack: () => {
          navigation.navigate('BenefitsFinancial');
        },
      }}>
      <View className="mb-24">
        <Typography variant="body2">
          <Typography variant="bodyHighlight2">Disponível por telefone, </Typography>
          de forma rápida e acessível para todos para{' '}
          <Typography variant="bodyHighlight2">
            esclarecer dúvidas sobre organização de finanças pessoais, despesas{' '}
          </Typography>
          e <Typography variant="bodyHighlight2">orientação </Typography>
          para iniciar projetos de investimento.
        </Typography>
      </View>
      <Collapse title="Saiba mais">
        <View className="mb-8">
          <Typography variant="body2">
            Agende sua orientação financeira para obter dicas sobre os seguintes temas:
          </Typography>
        </View>

        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">• Questões de finanças domésticas;</Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">• Organização de despesas pessoais;</Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">• Orientações sobre pequenos investimentos;</Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            Serviço disponível de segunda a sexta, das 9h às 18 h.
          </Typography>
        </View>

        <Typography variant="body2">
          <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem acesso a 3
          atendimentos durante a vigência de plano.
        </Typography>
      </Collapse>

      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Typography
        variant="bodyHighlight2"
        className="mb-24 max-w-[368px] self-center text-center mobile:mb-16">
        Para utilizar o benefício, é só ligar e solicitar a Orientação Financeira:
      </Typography>

      {theme.isMobile ? (
        <Button
          icon={<CallIcon color="white" />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007029897')}>
          Ligar
        </Button>
      ) : (
        <View className="w-full items-center justify-center self-center rounded-lg bg-background-gray p-24 text-center">
          <View className="mb-4 flex-row items-center">
            <View className="mr-[6px]">
              <CallIcon color={theme.colors.paragraph} />
            </View>
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle" color="paragraph">
            0800 702 9897
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};
