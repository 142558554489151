import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import TermsIcon from './Svg/Icons/Terms';
import SchedulesIcon from './Svg/Icons/Schedules';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const HomeIcon = () => <Icon name="home" size={25} color={StyledDefaultTheme.colors.blue} />;
export const UserIcon = () => <Icon name="user" size={25} color={StyledDefaultTheme.colors.blue} />;
export const PlusIcon = () => <Icon name="plus" size={25} color={StyledDefaultTheme.colors.blue} />;
export const FamilyIcon = () => (
  <Icon name="users" size={25} color={StyledDefaultTheme.colors.blue} />
);
export const HelpIcon = () => (
  <Icon name="help-circle" size={25} color={StyledDefaultTheme.colors.blue} />
);
export const RulesIcon = () => (
  <Icon name="alert-circle" size={25} color={StyledDefaultTheme.colors.blue} />
);
export const SignOutIcon = () => (
  <Icon name="log-out" size={25} color={StyledDefaultTheme.colors.blue} />
);
export const CreditCardIcon = () => (
  <Icon name="credit-card" size={25} color={StyledDefaultTheme.colors.blue} />
);
export const TermIcon = () => <TermsIcon />;
export const ScheduleIcon = () => <SchedulesIcon />;
