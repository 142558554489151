import styled, { css } from 'styled-components/native';
import { getDimensions } from '~/components/@hello-ui/Theme/utils';

const cardVariations = {
  info: css`
    background-color: ${({ theme: { colors } }) => colors.black5};
  `,
  outline: css`
    border: 1px solid ${({ theme: { colors } }) => colors.gray60};
  `,
  large: css``,
  xsmall: css`
    ${({ theme: { isMobile, tokens } }) => css`
      width: auto;
      flex: 1;
      ${isMobile
        ? css`
            padding: ${tokens.space.s8} ${tokens.space.s4} ${tokens.space.s8} ${tokens.space.s8};
          `
        : css`
            ${getDimensions().width < 1195
              ? css`
                  padding: ${tokens.space.s8} ${tokens.space.s4} ${tokens.space.s8}
                    ${tokens.space.s8};
                `
              : css`
                  padding: ${tokens.space.s16} ${tokens.space.s4} ${tokens.space.s16}
                    ${tokens.space.s16};
                `}
          `};
    `}
  `,
};

export const WrapperCard = styled.View<{ variant: string }>`
  ${({ theme: { tokens, colors }, variant }) => css`
    width: ${tokens.sizes.full};
    background-color: ${colors.white};
    border-radius: ${tokens.borderRadius.lg};
    flex-direction: column;
    max-width: ${tokens.sizes.screenSm};
    padding: ${tokens.space.s16};
    ${tokens.shadows.cards}
    ${cardVariations[variant as 'info' | 'outline' | 'large']}
  `}
`;
