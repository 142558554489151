import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Button, Typography, useSweetAlert } from '~/components/@hello-ui';

import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { useAppointment } from '~/hooks/appointment';
import { useTracking } from '~/services/tracking';

import VideoCall24Icon from '~/components/Icons/VideoCall24Icon';
import { trackingEventType } from '~/contexts/tracking/types';
import { Schedule } from '~/@types/schedules';
import ActivityIndicator from '~/components/@tem-ui/ActivityIndicator';

type QueueProps = {
  onCancel: () => void;
  onCall: (url: string) => void;
};

const TIME_TO_REFETCH: number = 30000;

export const Queue = ({ onCall, onCancel }: QueueProps): JSX.Element => {
  const api = useApi();
  const { user, onixCode: currentProduct } = useAuth();
  const { patient } = useAppointment();
  const { tracking } = useTracking();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);
  const [patientId, setPatientId] = useState<number | null>(null);
  const [loadingSchedule, setLoadingSchedule] = useState<boolean>(true);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [errorQueue, setErrorQueue] = useState<boolean>(false);

  const position = useMemo<number>(
    () => schedule?.appointment?.posicaoFila ?? 0,
    [schedule?.appointment?.posicaoFila],
  );
  const time = useMemo<number>(
    () => schedule?.appointment?.tempoEstimado ?? 0,
    [schedule?.appointment?.tempoEstimado],
  );

  const onQueueCancel = useCallback(async () => {
    try {
      setCancelling(true);
      intervalId && clearTimeout(intervalId);
      await api.telemedicine24hCancelQueue(patientId!);
      setIntervalId(null);
      setLoadingSchedule(false);
      onCancel();
    } catch (error) {
      showSweetAlert('Ops, algo deu errado', 'Falha ao sair da fila.', 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            testID: 'accept-button',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    } finally {
      setCancelling(false);
    }
  }, [patient, schedule]);

  const checkQueue = useCallback(
    async (scheduleData, userToken) => {
      setLoadingSchedule(true);
      setErrorQueue(false);

      try {
        if (user && currentProduct) {
          const { user_token } = await api.getUsertokenCto(user.cpf);

          !userToken && (userToken = user_token);

          const { data: createCallResponse } = await api.telemedicine24h(
            user?.cpf.replace(/\D/g, ''),
            user?.birthdate,
            user?.name,
            currentProduct?.onix_code,
            patient?.cpf,
          );

          setPatientId(createCallResponse.data.patientId);

          const { data: data } = await api.telemedicine24hUrl(createCallResponse.data.patientId);

          void tracking.addMovement({
            event: trackingEventType.ClickedOn24HVideoClickStart,
            metadata: {
              patient_info: patient,
              schedule_info: data.data,
            },
          });

          const schedulePayload = {
            appointment: {
              posicaoFila: data.data.queuePosition,
              tempoEstimado: data.data.estimatedRemainingTime,
            },
            call: {
              zoomMeetingUrlPatient: data.data.url,
            },
          };
          setSchedule({ ...(schedulePayload ?? null), userToken });
        }
      } catch (error) {
        if (error?.response?.status === 469 || error?.response?.status === 429) {
          onCancel();
          showSweetAlert(
            'Ops, algo deu errado',
            'Falha ao tentar entrar na fila, você ainda tem um ticket ativo. Aguarde 30 segundos para solicitar um novo atendimento.',
            'error',
            false,
            false,
            {
              layout: 'helloUi',
              touchOutside: false,
              buttons: [
                {
                  text: 'Ok',
                  testID: 'accept-button',
                  variant: 'primary',
                  onPress: () => hideSweetAlert(),
                },
              ],
            },
          );
          return;
        }

        if (error?.response?.status) {
          const { user_token } = await api.getUsertokenCto(user.cpf);

          !userToken && (userToken = user_token);

          const resp = await api.telemedicine24h(
            user?.cpf.replace(/\D/g, ''),
            user?.birthdate,
            user?.name,
            currentProduct?.onix_code,
            patient?.cpf,
          );

          setSchedule({ ...(resp ?? null), userToken });
        }

        if (error?.response?.data?.error !== 'TokenZeus invalido.') {
          showSweetAlert('Ops, algo deu errado', 'Token inválido.', 'error', false, false, {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          });

          setErrorQueue(true);
        }
      } finally {
        setIntervalId(null);
        setLoadingSchedule(false);
      }
    },
    [user, patient, schedule],
  );

  useEffect(() => {
    const shouldUpdateQueue: boolean =
      !intervalId &&
      !!schedule?.appointment &&
      !schedule?.call.zoomMeetingUrlPatient &&
      !loadingSchedule;

    if (shouldUpdateQueue) {
      setIntervalId(setTimeout(() => checkQueue(schedule, schedule?.userToken), TIME_TO_REFETCH));
    }
  }, [checkQueue, loadingSchedule]);

  useEffect(() => {
    void checkQueue(schedule, schedule?.userToken);
  }, []);

  useEffect(() => {
    if (schedule?.call.zoomMeetingUrlPatient && intervalId) {
      const url: string = schedule.call.zoomMeetingUrlPatient;

      intervalId && clearTimeout(intervalId);
      setIntervalId(null);

      onCall(url);

      void tracking.addMovement({
        event: trackingEventType.Started24HVideo,
        metadata: {
          patient_info: patient,
          schedule_info: schedule,
        },
      });
    }
  }, [tracking]);

  return (
    <View className="m-0 w-full items-center justify-center self-center pb-16 mobile:m-auto">
      <View className="mb-16 items-center justify-center">
        <VideoCall24Icon width={40} height={40} />
      </View>
      <View className="items-center text-center">
        <Typography variant="h3">Aguarde</Typography>
        <Typography variant="subtitle">Sua consulta será iniciada em breve</Typography>
      </View>
      <View className="my-24 h-[112px] w-full justify-center rounded-lg bg-background-gray p-24">
        {(loadingSchedule && !schedule) || cancelling ? (
          <ActivityIndicator />
        ) : (
          <Fragment>
            <Typography variant="body2">Sua posição na fila: {position}</Typography>
            <Typography variant="body2">Tempo médio de espera: {time} minutos</Typography>
          </Fragment>
        )}
      </View>
      <Button
        disabled={schedule?.call?.zoomMeetingUrlPatient !== null}
        variant="secondary"
        onPress={async () => {
          await onQueueCancel();
          onCancel();
        }}>
        Cancelar
      </Button>
    </View>
  );
};
