import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

const StethoscopeIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.4519 8.10256C19.1339 8.10256 18.0795 9.17949 17.9916 10.5256C15.5314 12.0513 15.2678 15.5513 15.8828 17.5256C16.3222 19.0513 16.3222 20.0385 15.8828 20.6667C15.4435 21.2949 14.3891 21.4744 12.8075 21.3846C11.6653 21.2949 10.7866 20.9359 10.2594 20.2179C9.64435 19.4103 9.64435 18.4231 9.64435 17.7051C9.90795 17.6154 10.1715 17.5256 10.3473 17.4359C13.9498 15.1923 15.795 9.98718 15.795 7.83333C15.795 5.67949 14.3013 4.42308 13.5105 3.88462C13.5105 2.80769 12.6318 2 11.6653 2C10.6987 2 9.82008 2.89744 9.82008 3.88462C9.82008 4.87179 10.6987 5.76923 11.6653 5.76923C12.1046 5.76923 12.4561 5.58974 12.8075 5.41026C13.3347 5.85897 14.2134 6.57692 14.2134 7.74359C14.2134 9.53846 12.5439 14.0256 9.55649 15.9103C9.20502 16.1795 8.67782 16.1795 8.32636 15.9103C5.33891 14.0256 3.66946 9.53846 3.66946 7.74359C3.66946 6.57692 4.46025 5.76923 5.07531 5.41026C5.42678 5.67949 5.77824 5.76923 6.21757 5.76923C7.27197 5.76923 8.06276 4.87179 8.06276 3.88462C8.06276 2.89744 7.1841 2 6.21757 2C5.16318 2 4.28452 2.80769 4.28452 3.88462C3.49372 4.33333 2 5.67949 2 7.74359C2 9.89744 3.84519 15.1026 7.4477 17.3462C7.62343 17.4359 7.79916 17.5256 7.9749 17.6154C7.88703 18.6026 8.06276 19.9487 8.85356 21.1154C9.64435 22.1923 10.8745 22.8205 12.6318 23C12.8954 23 13.159 23 13.4226 23C15.0921 23 16.3222 22.5513 17.113 21.5641C17.9038 20.4872 18.0795 18.8718 17.3766 16.8077C16.9372 15.5513 17.113 13.2179 18.431 12.0513C18.8703 12.6795 19.6611 13.0385 20.4519 13.0385C21.8577 13.0385 23 11.8718 23 10.4359C23 9 21.8577 8.10256 20.4519 8.10256ZM21.3305 10.6154C21.3305 11.0641 20.9791 11.5128 20.4519 11.5128C20.0126 11.5128 19.5732 11.1538 19.5732 10.6154C19.5732 10.1667 19.9247 9.71795 20.4519 9.71795C20.8912 9.80769 21.3305 10.1667 21.3305 10.6154Z"
        fill={theme.colors.primary}
      />
    </Svg>
  );
};

export default StethoscopeIcon;
