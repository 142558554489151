import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const BarCodeIcon = ({ color, height, width }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        fill={color ?? theme.colors.primary}
        d="M21.866 4h-4.789a.513.513 0 0 0-.515.515c0 .285.23.515.515.515h4.275v4.274c0 .286.23.515.515.515.285 0 .514-.23.514-.515V4.515A.513.513 0 0 0 21.867 4ZM6.304 4h-4.79A.513.513 0 0 0 1 4.515v4.79c0 .285.23.514.515.514.285 0 .515-.23.515-.515V5.03h4.274a.516.516 0 0 0 0-1.03ZM21.867 14.18a.513.513 0 0 0-.515.515v4.274h-4.275a.513.513 0 0 0-.515.515c0 .285.23.515.515.515h4.79c.285 0 .514-.23.514-.515v-4.79a.51.51 0 0 0-.515-.514ZM6.304 18.969H2.03v-4.274a.513.513 0 0 0-.515-.515.513.513 0 0 0-.515.515v4.789c0 .285.23.514.515.514h4.789c.286 0 .515-.229.515-.514a.516.516 0 0 0-.515-.515ZM18.79 15.76V8.242a.513.513 0 0 0-.515-.514.513.513 0 0 0-.515.514v7.516c0 .285.229.514.515.514.285 0 .515-.229.515-.51ZM15.64 7.727a.513.513 0 0 0-.514.514v6.451c0 .286.23.515.515.515.285 0 .515-.23.515-.515v-6.45a.516.516 0 0 0-.515-.515ZM13.005 7.727a.513.513 0 0 0-.515.514v6.451c0 .286.23.515.515.515.286 0 .515-.23.515-.515v-6.45a.513.513 0 0 0-.515-.515ZM10.375 7.727a.513.513 0 0 0-.515.514v6.451c0 .286.23.515.515.515.286 0 .515-.23.515-.515v-6.45a.519.519 0 0 0-.515-.515ZM7.74 7.727a.513.513 0 0 0-.514.514v6.451a.513.513 0 1 0 1.03 0v-6.45a.519.519 0 0 0-.516-.515ZM5.62 15.76V8.242a.513.513 0 0 0-.514-.514.513.513 0 0 0-.515.514v7.516c0 .285.23.514.515.514.285 0 .515-.229.515-.51Z"
      />
    </Svg>
  );
};

export default BarCodeIcon;
