import React from 'react';
import { Circle, G, Path, Rect, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

export const EyeClose = ({ color }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 18.1223C5.67193 18.1223 2.20815 12.0607 2.07492 11.8276C1.97503 11.661 1.97503 11.4944 2.07492 11.3281C2.20805 11.0616 5.67193 5 12 5C18.3281 5 21.7919 11.0616 21.9251 11.2948C22.025 11.4613 22.025 11.6279 21.9251 11.7943C21.792 12.0607 18.3281 18.1223 12 18.1223ZM3.1074 11.5611C3.80694 12.6602 6.97091 17.1232 12 17.1232C17.0291 17.1232 20.1932 12.6935 20.8926 11.5611C20.1933 10.462 17.0291 5.99907 12 5.99907C6.93749 5.99907 3.80682 10.462 3.1074 11.5611ZM12 15.5577C9.80177 15.5577 8.00338 13.7593 8.00338 11.5611C8.00338 9.36288 9.80177 7.5645 12 7.5645C14.1982 7.5645 15.9966 9.36288 15.9966 11.5611C15.9966 13.7593 14.1982 15.5577 12 15.5577ZM12 8.56355C10.3347 8.56355 9.00261 9.92914 9.00261 11.5609C9.00261 13.2262 10.3682 14.5583 12 14.5583C13.6653 14.5583 14.9974 13.1927 14.9974 11.5609C14.9974 9.89565 13.6653 8.56355 12 8.56355Z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.1"
      />
      <Path
        d="M4 4L20 20"
        stroke={color ?? theme.colors.primary}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </Svg>
  );
};
