import React, { useEffect } from 'react';
import { Modal, TouchableWithoutFeedback, Animated } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useDialog } from './useDialog';
import { Typography } from '../Typography';
import * as S from './styles';

export const Dialog = (): JSX.Element => {
  const { propsDialog, closeDialog } = useDialog();

  const opacity = new Animated.Value(0);
  const hasTitle = ![null, ''].includes(propsDialog.title);
  const onClose = () => {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 200,
      useNativeDriver: false,
    }).start();

    closeDialog();
  };

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 0.7,
      duration: 200,
      useNativeDriver: false,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsDialog.state]);

  return (
    <Modal transparent presentationStyle="overFullScreen" visible={propsDialog.state}>
      <S.WrapperDialog>
        <TouchableWithoutFeedback onPress={() => (propsDialog.closeButton ? onClose() : null)}>
          <S.WrapperBackground as={Animated.View} style={{ opacity }} />
        </TouchableWithoutFeedback>
        <S.WrapperDialogScroll
          centerContent
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}>
          <S.Wrapper
            noPadding={!propsDialog.title && !propsDialog.closeButton}
            widthAuto={propsDialog.widthAuto}>
            {propsDialog.closeButton && (
              <S.WrapperCloseButton onPress={onClose}>
                <Icon name="x" size={18} />
              </S.WrapperCloseButton>
            )}
            {hasTitle && propsDialog.title && (
              <S.WrapperTitle>
                <Typography variant="heading1" color="black">
                  {propsDialog.title}
                </Typography>
                {!!propsDialog.subtitle && (
                  <Typography variant="subtitle1" color="black">
                    {propsDialog?.subtitle}
                  </Typography>
                )}
              </S.WrapperTitle>
            )}
            {propsDialog.content && propsDialog.content}
          </S.Wrapper>
        </S.WrapperDialogScroll>
      </S.WrapperDialog>
    </Modal>
  );
};
