import { atom } from 'recoil';
import { ConectaDataTimes } from './BmgScheduleDate/types';

export interface propsDateBmg {
  date: Date | string | undefined;
  schedule: ConectaDataTimes | null | undefined;
}

export const dateBmg = atom<propsDateBmg>({
  key: 'dateBmg',
  default: {
    date: '',
    schedule: null,
  },
});
