import styled, { css } from 'styled-components/native';

export const WrapperDialog = styled.SafeAreaView`
  ${({ theme: { tokens, isMobile } }) => css`
    margin-top: ${tokens.space.s0};
    padding: ${tokens.space.s0};

    ${isMobile &&
    css`
      margin-left: ${tokens.space.s16};
      margin-right: ${tokens.space.s16};
    `};
  `}
`;
