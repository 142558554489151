import React from 'react';
import { useTheme } from 'styled-components/native';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Feather from 'react-native-vector-icons/dist/Feather';
import { View } from 'react-native';
import { useMyAppointments } from '../../state/useMyAppointments';
import * as S from '../styles';
import { capitalize, serializeAndUpperString } from '~/utils/strings';
import { useApi } from '~/hooks/api';
import { Spacing } from '~/components/Spacing';
import { Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { isConectaOnix } from '~/helpers/conecta';
import { Appointment } from '~/screens/MyAppointments/state/types';
import { makeInfoByType } from '~/screens/MyAppointments/helpers/make-info-by-type';
import { makeTextByStatus } from '~/screens/MyAppointments/helpers/make-text-by-status';

export const AppointmentItem = ({
  id,
  type,
  specialty,
  specialty_online,
  online_process,
  status,
  cpf,
  date,
}: Appointment): JSX.Element => {
  const api = useApi();
  const theme = useTheme();

  const infoByType = makeInfoByType();
  const textByStatus = makeTextByStatus(theme)[status];

  const { onixCode } = useAuth();
  const { setSelectedAppointment, setMyAppointmentLoad } = useMyAppointments();

  const { resume, description } = infoByType[online_process]
    ? infoByType[online_process]
    : infoByType[type];

  const getIcon = () => {
    if (!isConectaOnix(onixCode?.onix_code)) {
      return infoByType[online_process] ? infoByType[online_process].icon : infoByType[type].icon;
    }

    if (serializeAndUpperString(specialty?.name) === 'CLÍNICO GERAL') {
      return infoByType?.presential?.icon;
    }

    return infoByType?.bmgOnline?.icon;
  };

  let dateInfo = '';
  if (date && status === 'finished') {
    dateInfo = ` - ${capitalize(
      format(new Date(`${date}`), 'dd/MM/yyyy', {
        locale: ptBR,
      }),
    )}`;
  }

  return (
    <S.ContainerCard
      onPress={async () => {
        setMyAppointmentLoad(true);
        const { data } = await api.getMyAppointmentById(id, cpf, type);
        const appointment: Appointment = data[0];

        setMyAppointmentLoad(false);
        setSelectedAppointment(appointment);
      }}>
      <S.WrapperCard>
        <S.WrapperCardContent>
          <S.WrapperCardIcon status={status}>{getIcon()}</S.WrapperCardIcon>
          <S.WrapperCardHeader>
            <Typography variant="bodyHighlight2" color="title">
              {description ?? ''}
            </Typography>
            <Typography variant="body2">
              {specialty?.name ?? specialty_online?.name ?? resume}
            </Typography>
          </S.WrapperCardHeader>
          <Spacing top={15}>
            <Feather name="chevron-right" size={24} color={theme?.colors?.primary} />
          </Spacing>
        </S.WrapperCardContent>
        <S.WrapperCardActions>
          <View className="mx-16 flex-1 items-center justify-center rounded-lg bg-background-gray p-8">
            <Typography
              variant="bodyHighlight2"
              color={textByStatus?.color ?? theme.colors.paragraph}>
              {`${textByStatus?.text ?? ''} ${dateInfo}`}
            </Typography>
          </View>
        </S.WrapperCardActions>
      </S.WrapperCard>
    </S.ContainerCard>
  );
};
