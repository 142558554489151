import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Feather from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsCheckup/desktop.png';
import mobileBackground from '../../assets/images/benefitsCheckup/mobile.png';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { isItauOnix } from '~/helpers/itau';
import { useAuth } from '~/auth/legacy/useAuth';

export const BenefitsCheckup = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { onixCode } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <Feather name="heart" size={22} color="white" />,
        title: 'Checkup Médico',
        onBack: () => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.navigate('BenefitsServicesConsultations');
          }
        },
      }}>
      <View>
        <Typography variant="body2">
          O Checkup Médico é a melhor{' '}
          <Typography variant="bodyHighlight2">
            medida preventiva para um diagnóstico rápido e completo de sua saúde.
          </Typography>{' '}
          Através de consultas e exames regulares, podemos manter e melhorar nossa qualidade de
          vida.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">
              O benefício Checkup é um pacote completo de cuidados com sua saúde, que inclui
              consulta, exames (previstos em seu plano) e retorno.
            </Typography>
          </View>
          <View>
            <Typography variant="body2">Limite de utilização: {isItauOnix(onixCode?.onix_code) ? 'Mensal.' : '1 a cada 12 meses.'}</Typography>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício, ligue para</Typography>
        <Typography variant="bodyHighlight2">a Central de Atendimento:</Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08008368836')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="body1" color={theme.colors.paragraph}>
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              4000 1640
            </Typography>{' '}
            ou{' '}
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              0800 836 8836
            </Typography>
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};
