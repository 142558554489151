import { useRecoilState } from 'recoil';
import { stepState, userState, attemptState, tokenState } from './atom';
import { useLoginTypes } from './types';

export const useLogin = (): useLoginTypes => {
  const [step, setStep] = useRecoilState(stepState);
  const [user, setUser] = useRecoilState(userState);
  const [attempts, setAttempts] = useRecoilState(attemptState);
  const [token, setToken] = useRecoilState(tokenState);

  return {
    setStep,
    step,
    setUser,
    user,
    setAttempts,
    attempts,
    setToken,
    token,
  };
};
