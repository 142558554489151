import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const TimeIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 31} height={height ?? 30} fill="none" viewBox="0 0 31 30">
      <Path
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth={0.2}
        d="M15.5.332C7.409.332.833 6.907.833 14.999c0 8.09 6.575 14.666 14.667 14.666 8.09 0 14.666-6.575 14.666-14.666C30.166 6.907 23.591.332 15.5.332Zm0 1.504A13.151 13.151 0 0 1 28.662 15 13.151 13.151 0 0 1 15.5 28.16 13.151 13.151 0 0 1 2.337 14.999 13.151 13.151 0 0 1 15.5 1.836Zm0 2.633a.752.752 0 0 0-.752.752v9.777c0 .416.336.753.752.753h9.025a.752.752 0 1 0 0-1.505h-8.273V5.221a.752.752 0 0 0-.752-.752Z"
      />
    </Svg>
  );
};

export default TimeIcon;
