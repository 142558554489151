import React, { useEffect, useCallback } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { useRoute } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { format } from 'date-fns';
import { useTheme } from 'styled-components/native';
import { Panel, Typography, Button, useSweetAlert } from '../../components/@tem-ui';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import * as S from './styles';
import { Spacing } from '~/components/Spacing';
import { currency } from '~/utils/strings';
import CheckIcon from '~/assets/images/check.svg';
import { useMedia } from '~/components/@hello-ui';
import { UseRoute } from '~/@types/navigation/routes-helpers';

export const TemPayRechargeBillet = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const media = useMedia();
  const route = useRoute<UseRoute<'TemPayRechargeBillet'>>();

  const { showSweetAlert } = useSweetAlert();

  const openUrl = useCallback(
    async (url: string) => {
      const linkValid = await Linking.canOpenURL(url);

      if (linkValid) {
        await Linking.openURL(url);
      } else {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível abrir o boleto.',
          'error',
          true,
          false,
        );
      }
    },
    [showSweetAlert],
  );

  useEffect(() => {
    if (!route?.params?.billetUrl) {
      navigation.navigate('TemPayRecharge');
    }
  }, [route]);

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Recarga Boleto"
          onBack={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('Dashboard');
            }
          }}>
          <S.WrapperBillet>
            <Spacing bottom={10}>
              <CheckIcon />
            </Spacing>
            <Typography variant="heading1" color="black" bold>
              Seu boleto foi emitido :)
            </Typography>
            <Spacing top={16}>
              <Typography variant="body1" color="black" style={styles.center}>
                Enviamos o boleto para o seu e-mail cadastrado
              </Typography>
            </Spacing>

            <S.WrapperList>
              <S.WrapperItem>
                <S.ItemIcon>
                  <Icon name="arrow-up" size={24} color={theme?.colors.green} />
                </S.ItemIcon>
                <S.ItemContent>
                  <Typography variant="body1" color="black" style={{ marginBottom: 4 }} bold>
                    Recarga Pendente
                  </Typography>
                  <Typography variant="paragraph" color="black60">
                    {format(new Date(), 'dd/MM/yyyy')}
                  </Typography>
                </S.ItemContent>
                <S.ItemAmount>
                  <Typography variant="body2" color="success" bold>
                    {`R$ ${currency(route?.params?.amount ?? 0)}`}
                  </Typography>
                </S.ItemAmount>
              </S.WrapperItem>
            </S.WrapperList>

            <Spacing bottom={10}>
              <S.ItemIconContent>
                <Icon name="alert-circle-outline" size={38} color={theme?.colors.orange14} />
              </S.ItemIconContent>

              <Typography variant="body1" color="black" style={styles.center}>
                <S.CenterText>
                  Para completar sua recarga, faça o{' '}
                  <S.StrongText>pagamento do boleto antes da data de vencimento </S.StrongText>
                  em qualquer agência bancária, casa lotérica ou aplicativo do seu banco.
                </S.CenterText>
              </Typography>
            </Spacing>
            <Button
              variant="primary"
              title="Ver boleto"
              onPress={() => openUrl(route?.params?.billetUrl ?? '')}
            />
          </S.WrapperBillet>
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};

const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
  },
});
