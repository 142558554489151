import React, { useCallback } from 'react';
import { View } from 'react-native';
import { ListingTypes } from './types';
import * as S from './styles';

export const ListingItem = S.WrapperItem;

export const Listing = ({ data, loading, renderItem, listingRef }: ListingTypes): JSX.Element => {
  const keyExtractor = useCallback((item, idx) => idx.toString(), []);

  return (
    <S.Wrapper>
      <View />
      {loading ? (
        <S.WrapperLoading>
          <S.Loading />
        </S.WrapperLoading>
      ) : (
        <S.WrapperListScroll
          contentInsetAdjustmentBehavior="automatic"
          nestedScrollEnabled
          ref={listingRef}>
          <S.WrapperList
            data={data}
            keyExtractor={keyExtractor}
            ItemSeparatorComponent={S.Divider}
            showsVerticalScrollIndicator={false}
            renderItem={renderItem}
          />
        </S.WrapperListScroll>
      )}
    </S.Wrapper>
  );
};
