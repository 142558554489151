import React, { useEffect, useRef } from 'react';
import { Animated, Modal } from 'react-native';
import { DialogComponentProps } from '../../Dialog';
import DialogHeader from '../DialogHeader/DialogHeader';
import { CloseButton } from '../CloseButton';
import * as S from './styles';

import { DialogContent } from '~/components/@hello-ui/Dialog/components/DialogContent/DialogContent';

export const PopUp = ({ visible, title, subtitle, onClose, children }: DialogComponentProps) => {
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (visible) {
      Animated.timing(opacity, {
        toValue: 0.25,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
  }, [visible]);

  return (
    <Modal transparent presentationStyle="fullScreen" visible={visible}>
      <S.WrapperBackground style={{ opacity }} />
      <S.WrapperDialogScroll centerContent contentInsetAdjustmentBehavior="automatic">
        <S.WrapperDialog>
          <S.WrapperPopUp>
            <CloseButton onClose={onClose} />
            <S.PopUpCard>
              <DialogHeader title={title} subtitle={subtitle} />
              <DialogContent fill>{children}</DialogContent>
            </S.PopUpCard>
          </S.WrapperPopUp>
        </S.WrapperDialog>
      </S.WrapperDialogScroll>
    </Modal>
  );
};
