import { KeyboardAvoidingView, Platform } from 'react-native';
import React, { PropsWithChildren } from 'react';
import { useStatusBarHeight } from '~/hooks/useStatusBarHeight';

export const CustomKeyboardAvoidingView: React.FC<PropsWithChildren> = ({ children }) => {
  const statusBarHeight = useStatusBarHeight();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
      keyboardVerticalOffset={['android', 'ios'].includes(Platform.OS) ? statusBarHeight : 0}>
      {children}
    </KeyboardAvoidingView>
  );
};
