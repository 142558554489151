import React from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import styled, { css, useTheme } from 'styled-components/native';
import { Platform, View } from 'react-native';
import * as S from '~/components/@hello-ui/PageWithCard/styles';
import { Typography } from '~/components/@hello-ui';
import { HeaderCommon } from '~/components/@hello-ui/PageWithCard/types';
import { TitleStartContentRenderer } from '~/components/@hello-ui/PageWithCard/components/TitleStartContentRenderer';

interface SimpleHeaderProps extends HeaderCommon {
  onHelp?: () => void;
}

export const HeaderSimple: React.FC<SimpleHeaderProps> = ({
  title,
  onBack,
  onHelp,
  onChangeHeaderHeight,
  titleStartContent,
}) => {
  const theme = useTheme();

  return (
    <View
      className="z-10"
      style={
        Platform.OS === 'web' && !theme.isMobile
          ? { position: 'sticky' as any, top: 40, backgroundColor: theme.colors['background-gray'] }
          : {}
      }>
      <Container onLayout={(ev) => onChangeHeaderHeight?.(ev.nativeEvent.layout.height)}>
        {onBack && (
          <S.ButtonBack onPress={onBack} testID="on-back-button">
            <FeatherIcon name="arrow-left" size={24} />
          </S.ButtonBack>
        )}
        <TitleStartContentRenderer titleStartContent={titleStartContent} />
        {title && (
          <Typography variant="title" color={theme.colors.black}>
            {title}
          </Typography>
        )}
        {onHelp && (
          <S.ButtonHelp onPress={onHelp}>
            <FeatherIcon name="help-circle" size={24} color={theme?.colors?.primary} />
          </S.ButtonHelp>
        )}
      </Container>
    </View>
  );
};

const Container = styled.View`
  ${({ theme: { isDesktop, isMobile, colors, tokens } }) => css`
    align-items: center;

    flex-direction: row;
    background-color: ${colors.white};
    border: 1px solid ${colors['gray-disabled']};
    border-top-left-radius: ${tokens.borderWidths.b8};
    border-top-right-radius: ${tokens.borderWidths.b8};
    border-bottom-width: 0px;

    z-index: 20;

    ${tokens.shadows.header};

    ${isDesktop
      ? css`
          padding: ${tokens.space.s40} 0 ${tokens.space.s32} ${tokens.space.s88};
        `
      : css`
          padding: ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s16} ${tokens.space.s16};
        `}

    ${isMobile &&
    css`
      border-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  `};
`;
