import React, { useEffect } from 'react';
import { Button } from '~/components/@tem-ui';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { Typography, useMedia } from '~/components/@hello-ui';
import { iconType } from '../types';
import { useSweetAlert } from '../hooks';
import { ProgressBar } from '../ProgressBar';
import { useTheme } from 'styled-components/native';

import { IconAlert, Title, ContainerButton, ColumnButton } from './style';

export const iconName: iconType = {
  success: 'check-circle-outline',
  error: 'cancel',
  warning: 'alert-circle-outline',
  info: 'information-outline',
};

export const iconColor = {
  success: 'success',
  error: 'red',
  warning: 'primary',
  info: 'blue',
};

export const AlertDefaults = (): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();

  const {
    alertType,
    statusAlert,
    alertTitle,
    alertMessage,
    hideSweetAlert,
    autoClose,
    hideButtons,
  } = useSweetAlert();

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;
    if (statusAlert && autoClose) {
      timeOut = setTimeout(() => hideSweetAlert(), 3000);
    }

    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [statusAlert]);

  return (
    <>
      <IconAlert>
        <Icon
          name={iconName[alertType]}
          size={media.isMobile ? 64 : 80}
          color={theme.colors[iconColor[alertType]]}
        />
      </IconAlert>
      <Title>{alertTitle}</Title>
      <Typography
        variant={theme.isMobile ? 'body2' : 'body1'}
        color={theme.colors.black}
        style={{
          width: theme.isMobile ? 400 : 248,
          textAlign: 'center',
        }}>
        {alertMessage}
      </Typography>

      {!hideButtons && (
        <ContainerButton>
          {alertType == 'success' && (
            <Button variant="secondary" title="OK" onPress={() => hideSweetAlert()} />
          )}
          {(alertType == 'error' || alertType == 'info') && (
            <Button variant="secondary" title="FECHAR" onPress={() => hideSweetAlert()} />
          )}
          {alertType == 'warning' && (
            <>
              <ColumnButton>
                <Button variant="secondary" title="CANCELAR" onPress={() => hideSweetAlert(true)} />
              </ColumnButton>
              <ColumnButton>
                <Button
                  variant="secondary"
                  colorText="black"
                  title="VOLTAR"
                  onPress={() => hideSweetAlert()}
                />
              </ColumnButton>
            </>
          )}
        </ContainerButton>
      )}
      {autoClose && <ProgressBar color={theme.colors[iconColor[alertType]]} />}
    </>
  );
};
