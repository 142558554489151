import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Wrapper } from '~/components/@tem-ui';

export const WrapperHeader = styled.ImageBackground`
  width: 100%;
  height: 350px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    height: 200px;
  `}
`;

export const WrapperContainer = styled.View`
  margin-bottom: 60px;
  ${({ theme: { isMobile } }) =>
  isMobile &&
  `
  margin-bottom: 60px;
`}
`;

export const HeaderIcon = styled.TouchableOpacity`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-top: 26px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const WrapperContent = styled.View`
  align-items: center;
  margin: 30px 10px 0px 10px;
  height: 40px;
  justify-content: space-between;
  text-align: left;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 30px 15px 80px 15px;
  `}
`;

export const PanelHeaderImage = styled.View`
  width: 387px;
  height: 387px;
  align-items: center;
  position: absolute;
  top: -140px;
`;

export const WrapperTitle = styled.Text`
  font-size: 35px;
  line-height: 42px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${({ theme: { colors } }) => colors.blue};

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    font-size: 25px;
  `}
`;

export const WrapperContentTitle = styled.Text`
  font-size: 19px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.black};
`;

export const WrapperText = styled.Text`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme: { colors } }) => colors.black70};
`;

export const WrapperTextBold = styled.Text`
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.black60};
`;

export const WrapperCard = styled.View`
  align-items: center;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
  text-align: left;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top:50px; 
    height: 230px;
    width: 100%;
  `}
`;

export const Container = styled(Wrapper)`
  padding-top: 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-top: 0px;
  `}
`;

export const Help = styled.View`
  margin-top: 60px;
  margin-left: 24%;
  width: 650px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;  
    margin-top: 5px;
    margin-left:0%;
  `}
`;

export const between_lines = styled.Text`
  line-height: 28px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    line-height: 18px;
  `}
`;

export const PanelContent = styled.View`
  top: 10px;
  width: 100%;
  margin: 0px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding-horizontal: 16px;
      top: 10px;
    `};
`;

export const Info = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 10px;
  width: 100%;
  max-width: 608px;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-vertical: 24px;
  text-align: center;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  elevation: 2;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      margin-top:30px;
      background-color: white;
      box-shadow: 8px 8px rgba(1, 1, 1, 1);
      elevation: 5;
      padding-horizontal: 16px;
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction:row;
    `}
`;

export const InfoContact = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const TitlePosition = styled.Text`
  width: 100%;
  margin-left: 70px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  `}
`;

export const IconPosition = styled.View`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: -1;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top:-6px;
  `}
`;

const WrapperVariations = {
  web: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    shadow-color: ${({ theme: { colors } }) => colors.black};
    shadow-offset: ${Platform.OS === 'ios' ? '{width: 5, height: 5}' : '{width: 1, height: 2}'};
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
  `,
};

export const PanelHeaderFeatures = styled.View`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  align-self: flex-start;
  text-align: left;
  border-radius: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile ? WrapperVariations['mobile'] : WrapperVariations['web']}
`;

export const Features = styled.View`
  width: 100%;
  max-width: 608px;
  align-items: center;
  padding: 20px;
`;

export const Phone = styled.View`
  display: flex;
  flex-direction: row;
`;