import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const Wrapper = styled.View`
  max-width: 100%;
  width: 852px;
  height: 400px;
  align-items: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    width: 100%;
  `}
`;

export const WrapperItem = styled.TouchableOpacity`
  width: 100%;
  height: 68px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const WrapperBody = styled.View`
  display: flex;
  flex-direction: column;
`;
