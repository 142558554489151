import React from 'react';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { Button, Typography } from '~/components/@hello-ui';
import { isMercadoPagoOnix } from '~/helpers/mercado-pago';
import { useAuth } from '~/auth/legacy/useAuth';

export const ScheduleUploadExamEmpty = (): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });
  const theme = useTheme();
  const navigation = useNavigation();
  const { finalizeSteps } = useStepper();
  const { clearAppointments } = useAppointment();
  const { onixCode } = useAuth();

  return (
    <>
      <View className="mb-8">
        <Typography variant="title" color={theme.colors.black}>
          Não tem o pedido?
        </Typography>
      </View>
      <View className="mb-16">
        <Typography variant="body2" color="black">
          Para o agendamento do exame, é necessário que você possua um pedido médico.
        </Typography>
      </View>
      <View className="mb-8">
        <Typography variant="body2" color="black">
          Mas não se preocupe!
        </Typography>
      </View>
      <Typography variant="body2" color="black">
        Você pode agendar uma consulta e solicitar o seu pedido médico a qualquer momento.
      </Typography>

      {!isMercadoPagoOnix(String(onixCode?.onixCode)) && (
        <View className="mt-[152px] mobile:mt-auto">
          <Button
            variant="primary"
            onPress={() => {
              finalizeSteps();
              clearAppointments();
              navigation.navigate('ScheduleAppointment');
            }}>
            Solicitar consulta
          </Button>
          <Button variant="link" onPress={() => navigation.navigate('Dashboard')}>
            Voltar para o início
          </Button>
        </View>
      )}
    </>
  );
};
