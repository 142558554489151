const screenSizes = {
  mobile: 960,
  desktop: 1080,
};

const sizes = {
  0: '0px',
  1: '1px',
  4: '4px',
  8: '8px',
  12: '12px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  44: '44px',
  48: '48px',
  52: '52px',
  56: '56px',
  60: '60px',
  64: '64px',
  68: '68px',
  72: '72px',
  76: '76px',
  80: '80px',
  84: '84px',
  88: '88px',
  92: '92px',
  96: '96px',
  100: '100px',
};

module.exports = {
  content: ['./public/**/*.html', './src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      screens: {
        mobile: { max: `${screenSizes.mobile}px` },
        tablet: { min: `${screenSizes.mobile}px`, max: `${screenSizes.desktop}px` },
        desktop: { min: `${screenSizes.desktop}px` },
      },
      fontFamily: {
        sans: [
          'Poppins',
          'Poppins-Bold',
          'Poppins-BoldItalic',
          'Poppins-ExtraBold',
          'Poppins-Italic',
          'Poppins-Light',
          'Poppins-Regular',
          'Poppins-SemiBold',
          'sans',
        ],
      },
      dropShadow: {
        card: '1px 2px 20px rgba(75, 63, 64, 0.20)',
        button: '1px 1px 8px rgba(75, 63, 64, 0.15)',
        header: '1px 2px 4px rgba(75, 63, 64, 0.10)',
        toggle: '0px 1px 1px rgba(75, 63, 64, 0.25)',
      },
      spacing: sizes,
      screenSizes,
      colors: {
        primary: '#EC6338',
        secondary: '#f5a481',
        'black-title': '#1E1E1E',
        'gray-paragraph': '#4B3F40',
        'gray-placeholder': '#757575',
        'gray-disabled': '#CCCCCC',
        'background-gray': '#F5F5F5',
        'background-orange': '#FDF8F6',
        'background-blue': '#F3F8FA',
        'background-white': '#FFFFFC',
        'orange-hover': '#D45932',
        'orange-pressed': '#BF502D',
        'orange-disabled': '#F9CFBD',
        success: '#40C26E',
        error: '#B00020',
        warning: '#FA9F47',
        info: '#19759B',
      },
    },
  },
};
