import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

export const Copywriting = styled.View`
  ${({ theme: { tokens } }) => css`
    background-color: #f5f5f5;
    align-items: center;
    min-height: ${tokens.space.s48};
    padding-top: ${tokens.space.s4};
    padding-bottom: ${tokens.space.s4};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    ${Platform.OS === 'web'
      ? css`
          width: 100vw;
        `
      : css`
          width: 100%;
        `}
  `}
`;
