import { Platform } from 'react-native';
import styled, { css, DefaultTheme } from 'styled-components/native';
import { HeaderDisplayState } from '~/components/@hello-ui/PageWithCard/enums/HeaderDisplayState.enum';
import { getDimensions } from '~/components/@hello-ui/Theme/utils';

const HEADER_DEFAULT_HEIGHT = 61;

export const HeaderWrapper = styled.View<{ displayState?: HeaderDisplayState }>(
  ({ displayState, theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    z-index: 1;
    position: relative;
    top: 0px;
    margin-top: 0px;

    ${displayState === HeaderDisplayState.SimpleDesktop &&
    css`
      position: sticky;
      top: ${tokens.space.s40};
      margin-top: -${tokens.space.s8};
      margin-bottom: ${tokens.space.s8};
    `};
  `,
);

export const ContentInsideCard = styled.View<{ hasPadding: boolean; isDeepLink: boolean }>`
  ${({ hasPadding, isDeepLink, theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    max-width: ${!isDeepLink && hasPadding ? '530px' : '100%'}
      ${Platform.OS === 'web' && 'min-height: 100%;'};
    height: 100%;
  `};
`;

export const CardBottom = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})<{
  headerHeight?: number;
  hasHeader?: boolean;
  displayState: HeaderDisplayState;
  isCertificate: boolean;
}>(
  ({
    headerHeight,
    displayState,
    hasHeader,
    isCertificate,
    theme: { colors, tokens, isMobile },
  }) => css`
    background-color: ${colors.white};
    border-bottom-left-radius: ${tokens.borderRadius.lg};
    border-bottom-right-radius: ${tokens.borderRadius.lg};

    margin-top: -1px;

    border-top-left-radius: ${hasHeader ? 0 : tokens.borderRadius.lg};
    border-top-right-radius: ${hasHeader ? 0 : tokens.borderRadius.lg};
    border-top-width: ${hasHeader ? 0 : 1}px;

    border: 1px solid ${colors.black16};

    ${isMobile
      ? css`
          border-width: 0px;
          flex-grow: ${Platform.OS === 'web' && !isMobile ? 0 : 1};
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 0%;
        `}

    ${displayState === HeaderDisplayState.SimpleMobile &&
    css`
      height: ${Platform.OS === 'web'
        ? `calc(100svh - ${headerHeight ?? HEADER_DEFAULT_HEIGHT}px)`
        : '0px'};
    `}

    ${displayState === HeaderDisplayState.WithImageMobile &&
    css`
      margin-top: -${tokens.space.s8};
      border-top-left-radius: ${tokens.borderRadius['2xl']};
      border-top-right-radius: ${tokens.borderRadius['2xl']};
      z-index: 2;
    `}

    ${isCertificate &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
  `,
);

export const ScrollContainer = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
  },
}))(
  ({ theme: { tokens } }) => css`
    width: ${tokens.sizes.full};
    height: ${tokens.sizes.full};

    ${Platform.OS === 'web' &&
    css`
      -webkit-overflow-scrolling: touch;
    `};
  `,
);

const calcMargin = (
  theme: DefaultTheme,
  headerDisplayState: HeaderDisplayState,
  hasHeader?: boolean,
) => {
  const { tokens, isMobile, isDesktop } = theme;

  if (isMobile) {
    return '0 0 0 0';
  } else if (isDesktop) {
    return `${
      hasHeader && headerDisplayState === HeaderDisplayState.SimpleDesktop ? 0 : tokens.space.s40
    } ${getDimensions().width <= 986 ? tokens.space.s16 : tokens.space.s92} ${tokens.space.s40} 0`;
  } else {
    return `${
      hasHeader && headerDisplayState === HeaderDisplayState.SimpleDesktop ? 0 : tokens.space.s40
    } ${tokens.space.s16} ${tokens.space.s40} 0`;
  }
};

export const PageBody = styled.View<{
  hasHeader?: boolean;
  displayState: HeaderDisplayState;
  isDeepLink: boolean;
  isCertificate: boolean;
}>`
  ${({ hasHeader, isDeepLink, displayState, theme, isCertificate }) => css`
    min-height: 0px;
    max-width: ${isDeepLink ? 'auto' : '894px'};
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    ${theme.isMobile || isCertificate
      ? css`
          height: ${theme.tokens.sizes.full};
        `
      : css`
          margin: ${calcMargin(theme, displayState, hasHeader)};
          border-radius: ${theme.tokens.borderWidths.b8};
          elevation: 2;
        `}

        ${isCertificate &&
    css`
          width: 0 auto;
          height: 0 auto;
          min-width: 380px;
         
          max-width: auto;
          margin-left: auto;
          margin-right: auto;
        `}

        ${isCertificate && theme.isMobile && css`
          width: 100%;
        `}

        ${isCertificate && theme.isDesktop && css`
          min-height: 1000px;
          max-height: 2000px;
        `}
  `};
`;

export const BackgroundHider = styled.View(
  <
    {
      isCertificate?: boolean;
    }
  >`
  ${({ theme: { colors, tokens, isMobile }, isCertificate }) => css`
    width: ${tokens.sizes.full};
    background-color: ${isCertificate ? colors.white : colors.background};
    height: ${tokens.space.s48};
    position: ${Platform.OS == 'web' ? 'sticky' : 'absolute'};
    margin-bottom: -${tokens.space.s8};
    z-index: 1;
    top: 0;
    ${isMobile &&
    css`
      display: none;
    `},
  `}`,
);

export const ButtonBack = styled.TouchableOpacity`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    justify-content: center;
    margin-right: ${tokens.space.s8};
    z-index: ${tokens.zIndex.z1};
  `};
`;

export const ButtonHelp = styled.TouchableOpacity`
  ${({ theme: { tokens } }) => css`
    align-items: flex-end;
    justify-content: center;
    margin: auto ${tokens.space.s16} auto auto;
    z-index: ${tokens.zIndex.z1};
  `};
`;
