import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Linking, View } from 'react-native';
import { DeepLinkFirstAccessStepProps } from '../types';
import { Button, Input, Typography, useSweetAlert } from '~/components/@hello-ui';
import { instanceApi } from '~/App';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';

interface FormData {
  phone: string;
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  phone: yup
    .string()
    .matches(
      /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\s?\d|[2-9])\d{3})-?(\d{4}))$/,
      'Digite um número válido',
    )
    .required('Digite um número de celular'),
});

export const DeepLinkFirstAccessPhone = ({
  setStep,
  firstAccessData,
  setFirstAccessData,
  privacyPolicyLink,
}: DeepLinkFirstAccessStepProps) => {
  const theme = useTheme();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const [loading, setLoading] = React.useState(false);

  const defaultValues: FormData = {
    phone: firstAccessData?.phone,
  };
  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });

  const handleForm = async (data: FormData) => {
    try {
      setLoading(true);
      await instanceApi.updateDeepLinkFirstAccessData({
        phone: data.phone.replace(/\D/g, ''),
      });
      setFirstAccessData({ ...firstAccessData, phone: data.phone });
      setStep('phone_confirmation');
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cadastrar seu celular. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper onGoBack={() => setStep('email')}>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="title">Qual o seu celular?</Typography>
      </View>
      <Typography variant={theme.isMobile ? 'body1' : 'body2'} className="mb-24 mobile:mb-32">
        Neste celular você receberá todas atualizações e informações.
      </Typography>
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            mask="(99) 9 9999-9999"
            onChangeText={onChange}
            variant="mask"
            placeholder="(00) 0 0000-0000"
            label="Celular"
            error={error?.message}
            keyboardType="phone-pad"
          />
        )}
      />
      <View className="mb-24 mt-auto mobile:mb-68">
        <Button
          variant="outLine"
          onPress={handleSubmit(handleForm)}
          disabled={!isFormValid || loading}
          loading={loading}>
          Próximo
        </Button>
      </View>
    </PageWrapper>
  );
};
