import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { Sidebar } from '../Sidebar';
import * as S from './styles';
import { Navbar as NavbarMenu } from '~/components/@hello-ui/Navbar';
import MenuIcon from '~/components/Icons/Menu';
import MyCardsIcon from '~/components/Icons/MyCards';
import RechargeIcon from '~/components/Icons/Recharge';
import SchedulesIcon from '~/components/Icons/Schedules';
import { useAuth } from '~/auth/legacy/useAuth';

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  return (
    <View className={`${isOpen ? 'flex' : 'hidden'} absolute h-full w-full bg-white`}>
      <Sidebar
        onClose={() => {
          onClose && onClose();
        }}
      />
    </View>
  );
};

export const Navbar = (): JSX.Element => {
  const navigation = useNavigation();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { checkIsEnabledFeature } = useAuth();

  useEffect(() => {
    setMenuVisible(false);
  }, []);

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <>
      <S.Wrapper>
        <NavbarMenu
          items={[
            {
              onPress: () => {
                navigation.navigate('MyAppointments');
              },
              icon: <SchedulesIcon />,
              name: 'AGENDA',
              visible: true,
            },
            {
              onPress: () => {
                navigation.navigate('TemPayRecharge');
              },
              icon: <RechargeIcon />,
              name: 'RECARGA',
              visible: !checkIsEnabledFeature('hide_recharge'),
            },
            {
              onPress: () => {
                navigation.navigate('TemPayDashboard');
              },
              icon: <MyCardsIcon />,
              name: 'CONTA',
              visible: true,
            },
            {
              onPress: () => {
                openMenu();
              },
              icon: <MenuIcon />,
              name: 'MENU',
              visible: true,
            },
          ]}
        />
      </S.Wrapper>

      <SideMenu isOpen={isMenuVisible} onClose={closeMenu} />
    </>
  );
};
