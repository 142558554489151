import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
};

export const ErrorIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width ?? 75} height={height ?? 75} viewBox="7 7 64 64" fill="none">
      <G>
        <Circle cx="39" cy="39" r="32" fill="#B00020" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M38.9079 45.7787C37.3182 45.7787 36 44.4604 36 42.8708V27.362C36 25.7723 37.3182 24.4541 38.9079 24.4541C40.4976 24.4541 41.8158 25.7723 41.8158 27.362V42.8708C41.8158 44.4604 40.4976 45.7787 38.9079 45.7787Z"
        fill="white"
      />
      <Path
        d="M38.9079 56.0153C38.5202 56.0153 38.1325 55.9378 37.7835 55.7827C37.4346 55.6664 37.1244 55.4337 36.853 55.1623C36.5816 54.8909 36.3489 54.5807 36.2326 54.2318C36.0775 53.8828 36 53.4951 36 53.1074C36 52.7197 36.0775 52.3707 36.2326 51.983C36.3877 51.6341 36.5816 51.3239 36.853 51.0525C37.9386 49.9669 39.8772 49.9669 40.9628 51.0525C41.2342 51.3239 41.4281 51.6341 41.5832 51.983C41.7383 52.3707 41.8158 52.7197 41.8158 53.1074C41.8158 53.4951 41.7383 53.8828 41.5832 54.2318C41.4669 54.5807 41.2342 54.8909 40.9628 55.1623C40.42 55.7051 39.6833 56.0153 38.9079 56.0153Z"
        fill="white"
      />
    </Svg>
  );
};
