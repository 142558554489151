type rgbColor = {
  r: number;
  g: number;
  b: number;
};

const splitHexColorToRGB = (color: string): rgbColor => {
  const r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16);

  return { r, g, b };
};

export const hexToRGB = (hex: string, alpha: number): string => {
  const rgbColor = splitHexColorToRGB(hex);

  if (alpha) {
    return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${alpha})`;
  } else {
    return `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`;
  }
};

export const changeColorOpacity = (color: string, alpha: number): string => {
  return hexToRGB(color, alpha);
};

const colorChannelMixer = (colorChannelA: number, colorChannelB: number, alpha: number): number => {
  const channelA = colorChannelA * alpha;
  const channelB = colorChannelB * (1 - alpha);
  return channelA + channelB;
};

export const colorMixer = (colorP: string, colorS: string, alpha: number): string => {
  const primaryColor = splitHexColorToRGB(colorP);
  const secondaryColor = splitHexColorToRGB(colorS);

  const r = colorChannelMixer(primaryColor.r, secondaryColor.r, alpha);
  const g = colorChannelMixer(primaryColor.g, secondaryColor.g, alpha);
  const b = colorChannelMixer(primaryColor.b, secondaryColor.b, alpha);

  return `rgb(${r},${g},${b})`;
};

export const getDarkerColor = (hexCor: string) => {
  const r = parseInt(hexCor.slice(1, 3), 16);
  const g = parseInt(hexCor.slice(3, 5), 16);
  const b = parseInt(hexCor.slice(5, 7), 16);
  const newR = Math.round(r * 0.75);
  const newG = Math.round(g * 0.75);
  const newB = Math.round(b * 0.75);
  const newColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  return newColor;
};
