import React from 'react';
import styled from 'styled-components/native';
import * as S from '../styles';

const Primary = styled(S.ButtonContainer)`
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.black5 : colors.primary};
`;

export default ({ ...rest }): JSX.Element => (
  <S.ButtonWrapper>
    <Primary {...rest} />
  </S.ButtonWrapper>
);
