import styled, { css } from 'styled-components/native';
import React from 'react';
import { ImageSourcePropType } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import CircleGoBackIcon from '~/components/Icons/CircleGoBackIcon';
import { HeaderCommon } from '~/components/@hello-ui/PageWithCard/types';
import { Typography } from '~/components/@hello-ui';
import { TitleStartContentRenderer } from '~/components/@hello-ui/PageWithCard/components/TitleStartContentRenderer';

interface HeaderWithImageProps extends HeaderCommon {
  backgroundImage?: ImageSourcePropType;
}

export const FeaturedHeader = ({
  onBack,
  title,
  backgroundImage,
  titleStartContent,
  onChangeHeaderHeight,
}: HeaderWithImageProps) => {
  return (
    <Container
      source={backgroundImage}
      resizeMode="cover"
      onLayout={(ev) => onChangeHeaderHeight?.(ev.nativeEvent.layout.height)}>
      <LinearGradient colors={['rgba(255, 255, 255, 0)', '#000000bc']} style={{ flex: 1 }}>
        {onBack && (
          <GoBackSpace>
            <GoBackButton onPress={onBack}>
              <CircleGoBackIcon />
            </GoBackButton>
          </GoBackSpace>
        )}
        <BottomSpace>
          <TitleStartContentRenderer titleStartContent={titleStartContent} />
          {title && (
            <Typography variant="subtitle" color="white">
              {title}
            </Typography>
          )}
        </BottomSpace>
      </LinearGradient>
    </Container>
  );
};

const Container = styled.ImageBackground(
  ({ theme: { tokens, isMobile } }) => css`
    height: ${isMobile ? '216px' : '268px'};
    border-top-left-radius: ${tokens.borderRadius.lg};
    border-top-right-radius: ${tokens.borderRadius.lg};
    overflow: hidden;

    ${isMobile &&
    css`
      margin-bottom: -12px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `}
  `,
);

const GoBackSpace = styled.View(
  ({ theme }) => css`
    margin: ${theme.isMobile ? theme.tokens.space.s12 : theme.tokens.space.s24};
    margin-bottom: 0px;
  `,
);

const GoBackButton = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  opacity: ${({ theme }) => (theme.isMobile ? 0.5 : 1)};
`;

const BottomSpace = styled.View(
  ({ theme }) => css`
    flex-direction: row;
    align-items: center;
    margin: auto ${theme.isDesktop ? theme.tokens.space.s92 : theme.tokens.space.s16};
    margin-bottom: ${theme.tokens.space.s24};
  `,
);
