import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';
import desktopBackground from '~/assets/images/benefitsTeleOrientation/desktop.png';
import mobileBackground from '~/assets/images/benefitsTeleOrientation/mobile.png';
import { CallIcon } from '~/components/Icons/Call';
import PhoneIcon from '~/components/Icons/PhoneIcon';
import { goBack } from '~/helpers';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsTeleOrientation = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();

  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <PhoneIcon color="white" height={24} width={24} />,
        title: 'Orientação Médica por Telefone',
        onBack: () => {
          goBack(navigation, 'BenefitsServicesConsultations');
        },
      }}>
      <View className="mb-24">
        <Typography variant="body2">
          Atendimento médico por telefone, disponível 24 horas para qualquer{' '}
          <Typography variant="bodyHighlight2">dúvida ou orientação sobre saúde.</Typography>{' '}
          Suporte profissional e qualificado, reconhecido pelo Conselho Regional de Medicina.
        </Typography>
        <Typography variant="body2">• 24 horas por dia, 7 dias por semana</Typography>
        <Typography variant="body2">• Uso ilimitado</Typography>
      </View>
      <Collapse title="Saiba mais">
        <View className="mb-8">
          <Typography variant="body2">
            Você pode utilizar a orientação sempre que precisar, como nas seguintes situações:
          </Typography>
        </View>

        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Dúvidas relacionadas a doenças, tratamentos e seus sintomas;
          </Typography>
          <Typography variant="body2">
            • Dúvidas sobre medicações, dosagens e reações adversas;
          </Typography>
          <Typography variant="body2">
            • Dúvidas sobre a gravidez e orientações de cuidados para gestantes;
          </Typography>
          <Typography variant="body2">
            • Orientações de primeiros socorros em situações de urgência e emergência médica como:
            intoxicações, quedas, traumas e queimaduras;
          </Typography>
          <Typography variant="body2">
            • Informações sobre doenças crônicas como diabetes, hipertensão e obesidade;
          </Typography>
          <Typography variant="body2">
            • Orientações sobre qual especialidade procurar diante de um conjunto de sintomas;
          </Typography>
          <Typography variant="body2">
            • Esclarecimento sobre cuidados básicos com crianças.
          </Typography>
        </View>
      </Collapse>

      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Typography
        variant="bodyHighlight2"
        className="mb-24 max-w-[368px] self-center text-center mobile:mb-16">
        Para utilizar o benefício, ligue para a Central de Atendimento:
      </Typography>

      {theme.isMobile ? (
        <Button
          icon={<CallIcon color="white" />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08009415455')}>
          Ligar
        </Button>
      ) : (
        <View className="w-full items-center justify-center self-center rounded-lg bg-background-gray p-24 text-center">
          <View className="mb-4 flex-row items-center">
            <View className="mr-[6px]">
              <CallIcon color={theme.colors.paragraph} />
            </View>
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle" color="paragraph">
            0800 941 5455
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};
