import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Segurity = ({ color, width, height }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 16} height={height ?? 16} viewBox="0 0 16 16" fill="none">
      <G id="icon-insurance">
        <Path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3333 3.86363L13.2367 8.87405C13.1884 12.0534 10.4213 13.5181 7.99188 14.6667C5.56252 13.5181 2.81152 12.0534 2.74704 8.87405L2.66663 3.86363C5.1282 3.56398 6.25435 3.21445 7.99177 1.33337C9.74538 3.23097 10.8877 3.56388 13.333 3.86363H13.3333ZM7.99209 2.3489C6.67285 3.71386 5.19277 4.2465 3.35876 4.4962L3.43917 8.87392C3.47139 11.5374 5.93287 12.9354 7.99221 13.8843C10.0515 12.9355 12.5131 11.5373 12.5613 8.87392L12.6417 4.4962C10.8077 4.2465 9.3275 3.71386 7.99232 2.3489H7.99209Z"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
          strokeWidth="0.2"
        />
      </G>
    </Svg>
  );
};

export default Segurity;
