import React from 'react';
import { Loader } from '../Loader';
import * as S from './styles';
import { Typography } from './../Typography';
import { StepperProviderTypes } from './types';
import { StepperList } from './StepperList';
import { Spacing } from '~/components/Spacing';
import { useAuth } from '~/auth/legacy/useAuth';
import { useStepper } from '~/components/@tem-ui/Stepper';

export const Stepper = ({
  title,
  items,
  description,
  validateDependentFeatures,
}: StepperProviderTypes): JSX.Element => {
  const { stepper, currentStep } = useStepper();
  const { isDependent } = useAuth();
  if (isDependent === null && validateDependentFeatures)
    return (
      <S.WrapperSteps>
        <Spacing bottom={100} top={100}>
          <Loader variant="circle-loader" />
        </Spacing>
      </S.WrapperSteps>
    );
  let view = <></>;
  if (items && stepper === null) {
    view = <StepperList title={title ?? ''} description={description} items={items} />;
  } else {
    view = items[stepper].steps[currentStep];
  }

  let steps = <></>;
  if (stepper !== null && items[stepper].visibilitySteps !== false) {
    steps = (
      <S.WrapperSteps>
        <Typography variant="title">
          {`Passo ${currentStep + 1} de ${items[stepper].steps.length}`}
        </Typography>
        <S.WrapperStepsRow>
          {Array.from(items[stepper].steps).map((step, idx) => (
            <S.WrapperStep current={currentStep === idx} key={`step_${idx}`} />
          ))}
        </S.WrapperStepsRow>
      </S.WrapperSteps>
    );
  }

  return (
    <>
      {steps}
      {view}
    </>
  );
};
