export const TELPHONE_MASK = /^\(?([0-9]{2})\)?[-. ]?([0-9]{1})?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/g;

export const BIRTHDATE_MASK = /([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g;

export const CPF_MASK = /^(\d{3}\.){2}\d{3}-\d{2}$/g;

export const NAME_CANNOT_CONTAIN_ONLY_BLANKS = /^\s*\S[\s\S]*$/g;

export const EMAIL_MASK =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
