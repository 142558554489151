import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const WrapperDialog = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ theme: { isMobile, colors } }) =>
    isMobile &&
    `
    background: ${colors.white};
  `}
`;

export const WrapperDialogScroll = styled.ScrollView`
  width: 100%;
`;

export const Wrapper = styled.View<{ noPadding: boolean, widthAuto?: boolean }>`
  background-color: ${({ theme: { colors } }) => colors.white};
  max-width: 900px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  ${Platform.OS === 'web' && 'width: calc(100% - 112px)'}
  width:  ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  border-radius: 24px;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
  ${({ theme: { isMobile } }) =>
    Platform.OS === 'web' &&
    isMobile &&
    `
    max-width: 100%;
  `}
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border-radius: 0;
    height: 100%;
    padding: 16px;
    width: 100%;
  `}
`;

export const WrapperBackground = styled.View`
  background-color: ${({ theme: { colors, isMobile } }) =>
    isMobile ? colors.white : colors.black};
  flex: 1;
  width: 100%;
  height: 100%;
  position: ${Platform.OS !== 'web' ? 'absolute' : 'fixed'};
`;

export const WrapperCloseButton = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.black8};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

export const WrapperTitle = styled.View`
  margin-top: 16px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
`;
