import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useAuth } from '~/auth/legacy/useAuth';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import CalendarIcon from '~/components/Icons/CalendarIcon';
import ClinicIcon from '~/components/Icons/ClinicIcon';
import ExamIcon from '~/components/Icons/Exam';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import TimeIcon from '~/components/Icons/TimeIcon';
import { isConectaOnix } from '~/helpers/conecta';
import { isMercadoPagoOnix, MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { useApi } from '~/hooks/api';
import { HelpText } from '~/screens/MyAppointments/components/AppointmentDetails/HelpText';
import { Steps } from '~/screens/MyAppointments/components/Steps/Steps';
import { makeInfoByType } from '~/screens/MyAppointments/helpers/make-info-by-type';
import { makeTextByStatus } from '~/screens/MyAppointments/helpers/make-text-by-status';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { toCamelCase } from '~/utils/strings';

export const InProgressAppointment = () => {
  const theme = useTheme();
  const api = useApi();
  const { myAppointment } = useMyAppointments();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { onixCode } = useAuth();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  const {
    clinic_address,
    preferred_periods,
    specialty,
    specialty_online,
    status,
    online_process,
    type,
    created_at,
    address,
  } = myAppointment!;

  const statusType = makeTextByStatus(theme);
  const appointmentType = makeInfoByType(theme);

  const cancelAppointment = async () => {
    try {
      showSweetAlert('Cancelando solicitação...', '', 'success', false, false, {
        layout: 'helloUi',
      });

      await api.cancelConectaAppointment(myAppointment?.id as unknown as string);

      showSweetAlert('Tudo certo!', 'Sua solicitação foi cancelada', 'success', false, false, {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
          },
        ],
      });
    } catch (error) {
      console.error(error);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cancelar sua solicitação. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );
    }
  };

  const askForConfirmation = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja cancelar solicitação de agendamento?',
      'warning',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: cancelAppointment,
          },
        ],
      },
    );
  };

  const getCurrentStep = () => {
    let bindings: Record<string, number> = {
      requested: 0,
      accepted: 1,
    };

    if (['medical', 'presential', 'exam'].includes(type)) {
      bindings = {
        requested: 0,
        suggested: 1,
        scheduling: 2,
        accepted: 3,
      };
    }

    return bindings[status] ?? 0;
  };

  const getStepIcons = () => {
    if (['medical', 'presential', 'exam'].includes(type)) {
      return [type === 'exam' ? ExamIcon : StethoscopeIcon, ClinicIcon, TimeIcon, CalendarIcon];
    }

    return [TimeIcon, CalendarIcon];
  };

  const isToShowDeadline = () => {
    const currentStep = getCurrentStep();
    return ['medical', 'presential', 'exam'].includes(type) && currentStep < 3;
  };

  const isToShowAsterisk = () => {
    const currentStep = getCurrentStep();
    return isToShowDeadline() && currentStep > 1;
  };

  const getAddress = () => {
    if (address) {
      return `${clinic_address ? `Unidade ${clinic_address} - ` : ''}${address.neighborhood}, ${
        address.city
      }, ${address.state}`;
    } else {
      return '-';
    }
  };

  const getRequestDate = () => {
    if (created_at) {
      return format(new Date(created_at), "d 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      });
    } else {
      return '-';
    }
  };

  const getPreferredPeriods = () => {
    try {
      return JSON.parse(preferred_periods);
    } catch (e) {
      return [];
    }
  };

  const checkCancel = () => {
    if (onixCode?.onix_code) {
      if (isConectaOnix(onixCode.onix_code)) {
        return (
          <>
            <Typography variant="body2" className="mb-24 mobile:mb-16">
              Se precisar cancelar seu atendimento, é só clicar no botão abaixo.
            </Typography>
            <Button variant="disabled" onPress={askForConfirmation}>
              Cancelar solicitação
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="body2" className="mb-24 mobile:mb-16">
              Se precisar cancelar, é só entrar em contato com nossa Central de Agendamento
            </Typography>
          </>
        );
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      <Steps currentStep={getCurrentStep()} stepIcons={getStepIcons()} />
      <View className="mb-24 mt-40 mobile:mt-32">
        <Typography variant="body2">
          Etapa {getCurrentStep() + 1} de {getStepIcons().length}:
        </Typography>
        <Typography variant="bodyHighlight2" className="mb-8 mobile:mb-4">
          {statusType[status]?.text}
        </Typography>
        {isToShowDeadline() && (
          <Typography variant="body2">
            Prazo: <Typography variant="bodyHighlight2">2 dias úteis</Typography>
            {isToShowAsterisk() && (
              <Typography variant="bodyHighlight2" color={theme.colors.primary}>
                *
              </Typography>
            )}
          </Typography>
        )}
        <Typography variant="body2">
          Protocolo: <Typography variant="bodyHighlight2">{myAppointment?.protocol}</Typography>
        </Typography>
      </View>
      <Collapse title="O que fazer agora?">
        <HelpText type={type} currentStep={getCurrentStep()} />
      </Collapse>
      <Collapse title="Detalhes da solicitação">
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Protocolo
          </Typography>
          <Typography variant="body2">{myAppointment?.protocol}</Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Tipo
          </Typography>
          <Typography variant="body2">
            {appointmentType[online_process]
              ? appointmentType[online_process].description
              : appointmentType[type].description ?? '-'}
          </Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Especialidade
          </Typography>
          <Typography variant="body2">
            {specialty?.name ?? specialty_online?.name ?? '-'}
          </Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Para quem
          </Typography>
          <Typography variant="body2">{`${myAppointment?.patient?.name} - ${myAppointment?.patient?.holder ? 'Titular' : 'Dependente'}`}</Typography>
        </View>
        {myAppointment?.type !== 'telemedicine' && myAppointment?.type !== 'checkup' && (
          <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Preferência de endereço
            </Typography>
            <Typography variant="body2">{getAddress()}</Typography>
          </View>
        )}
        {myAppointment?.type !== 'checkup' && (
          <>
            <Typography variant="body2" color="placeholder" className="mb-8">
              Preferência de dia e período
            </Typography>
            <View className="mb-16">
              {getPreferredPeriods().length === 0
                ? '-'
                : getPreferredPeriods().map((period) => (
                    <Typography variant="body2">{toCamelCase(period.replace('\n', ''))}</Typography>
                  ))}
            </View>
          </>
        )}
        <View className="mb-24">
          <Typography variant="body2" color="placeholder">
            Data da solicitação
          </Typography>
          <Typography variant="body2">{getRequestDate()}</Typography>
        </View>
      </Collapse>
      {!isMercadoPagoOnix(String(onixCode?.onixCode)) && (
        <Collapse title="Cancelamento">{checkCancel()}</Collapse>
      )}
      <MobileAutoSpace />
      {!theme.isMobile && !checkMercadoPago && (
        <Typography variant="bodyHighlight2" className="mt-24 text-center">
          Dúvidas? Fale conosco:
        </Typography>
      )}
      {!checkMercadoPago && (
        <View className="mt-16 mobile:mt-24">
          <Footer
            variant="simple-phone-footer"
            showButtonOnMobile
            textButtonOnMobile="Dúvidas? Fale conosco:"
          />
        </View>
      )}
    </>
  );
};
