import styled from 'styled-components/native';
import { SpacingTypes } from './types';

const spacingProps = ['top', 'left', 'bottom', 'right'];

export const Wrapper = styled.View.withConfig({
  shouldForwardProp: (prop) => !spacingProps.includes(prop),
})<SpacingTypes>`
  ${({ top }) =>
    top &&
    `
      margin-top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ left }) =>
    left &&
    `
      margin-left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    right &&
    `
      margin-right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  ${({ bottom }) =>
    bottom &&
    `
      margin-bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}
`;
