import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

const iconAlertVariations = {
  web: css`
    height: 75px;
    width: 75px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  `,
  mobile: css`
    height: 64px;
    width: 64px;

    ${Platform.OS === 'ios' &&
    css`
      shadow-color: #000;
      shadow-offset: 0 0;
      shadow-opacity: 0.15;
      shadow-radius: 2px;
    `}
    ${Platform.OS === 'web' &&
    css`
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    `}
    
    elevation: ${Platform.OS === 'ios' ? 1 : 8};
  `,
};

export const IconAlert = styled.View<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ theme: { colors }, backgroundColor }) => backgroundColor ?? colors.white};

  ${({ theme: { isMobile } }) =>
    isMobile ? iconAlertVariations['mobile'] : iconAlertVariations['web']}
`;

export const IconCustomAlert = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #ffffff;
`;

const titleVariations = {
  web: css`
    font-size: 24px;
    margin-bottom: 10px;
  `,
  mobile: css`
    font-size: 20px;
    margin-bottom: 5px;
  `,
};

export const Title = styled.Text`
  ${({ theme: { isMobile } }) => (isMobile ? titleVariations['mobile'] : titleVariations['web'])}
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: center;
  color: #3b3b3b;
  opacity: 0.87;
`;

export const CustomTitle = styled.Text`
  ${({ theme: { isMobile } }) => (isMobile ? titleVariations['mobile'] : titleVariations['web'])}
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${StyledDefaultTheme.colors.black};
  opacity: 0.87;
  margin-bottom: 20px;
`;

export const IconTitle = styled.Text`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5px;
  color: ${StyledDefaultTheme.colors.black60};
`;

const messageVariations = {
  web: css`
    width: 400px;
  `,
  mobile: css`
    width: 248px;
  `,
};

export const CustomMessage = styled.Text`
  ${({ theme: { isMobile } }) =>
    isMobile ? messageVariations['mobile'] : messageVariations['web']}
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.25px;
  text-align: left;
`;

export const ContainerButton = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const ColumnButton = styled.View`
  ${({ theme: { isMobile } }) => (isMobile ? 'width: 120px;' : 'width: 192px;')};
`;

export const CustomContainerButton = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 60px;
  ${({ theme: { isMobile } }) => (isMobile ? ' width: 290px' : 'width: 380px')};
  ${({ theme: { isMobile } }) => (isMobile ? ' margin: 40px 0 0 13px' : 'margin: 40px 0 0 1px')};
`;

export const CustomColumnButton = styled.View`
  width: 100%;
  margin-right: 15px;
`;
