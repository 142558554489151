import React from 'react';

import { AdvancedMarker as GoogleMarker } from '@vis.gl/react-google-maps';

import { useTheme } from 'styled-components/native';

import MarkerFocusedIcon from '~/components/Icons/MarkerFilled';
import MarkerUnfocusedIcon from '~/components/Icons/Marker';

interface MarkerProps {
  focused?: boolean;
  latitude: number;
  longitude: number;
  title: string;
}

export const Marker = ({ focused, latitude, longitude, title }: MarkerProps) => {
  const theme = useTheme();

  const PinIcon = focused ? MarkerFocusedIcon : MarkerUnfocusedIcon;

  return (
    <GoogleMarker title={title} position={{ lat: latitude, lng: longitude }}>
      <PinIcon size={24} color={theme.colors.primary} />
    </GoogleMarker>
  );
};
