import React, { ReactElement } from 'react';
import { ColorValue, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import MaterialIcon from 'react-native-vector-icons/dist/MaterialIcons';

interface IconBadgeProps {
  icon: string | ReactElement;
  backgroundColor: 'success' | 'red' | 'primary' | 'blue' | 'warning' | 'white';
  size?: number;
  borderWidth?: number;
}

export const IconBadge: React.FC<IconBadgeProps> = ({
  icon,
  backgroundColor,
  size,
  borderWidth,
}) => {
  const theme = useTheme();

  return (
    <View
      className="items-center justify-center rounded-full border-white mobile:h-64 mobile:w-64"
      style={{
        backgroundColor: theme.colors[backgroundColor] as ColorValue,
        borderWidth: borderWidth || 5,
        shadowColor: theme.colors.paragraph,
        width: size || 80,
        height: size || 80,
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowOpacity: 0.15,
        shadowRadius: 8,
        elevation: 8,
      }}>
      {typeof icon === 'string' ? (
        <MaterialIcon size={theme.isMobile ? 40 : 30} name={icon} color={theme.colors.white} />
      ) : (
        icon
      )}
    </View>
  );
};
