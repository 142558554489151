import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const StethoscopeIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <G id="icon-stethoscope">
        <Path
          id="Vector"
          d="M19.3412 16.4615C18.8198 15.958 18.1337 15.7343 17.4201 15.9021C16.5968 16.0699 15.9107 16.7413 15.746 17.6084C15.6363 18.1678 15.746 18.7552 16.0205 19.2308L15.6088 19.5944C14.6757 20.4336 13.3859 20.6294 12.343 20.1259C11.3001 19.6224 10.6415 18.5594 10.6415 17.4126V14.8112C13.4956 14.4196 15.6912 11.9021 15.6912 8.93706V3.84615C15.6912 2.83916 14.8678 2 13.8799 2H13.2486C12.8095 2 12.4528 2.36364 12.4528 2.81119C12.4528 3.25874 12.8095 3.62238 13.2486 3.62238H13.8799C13.9896 3.62238 14.0994 3.73427 14.0994 3.84615V8.93706C14.0994 11.3147 12.2058 13.2727 9.84558 13.2727C7.51284 13.2727 5.59175 11.3427 5.59175 8.93706V3.84615C5.59175 3.73427 5.70153 3.62238 5.81131 3.62238H6.44252C6.88162 3.62238 7.2384 3.25874 7.2384 2.81119C7.2384 2.36364 6.88162 2 6.44252 2H5.81131C4.82332 2 4 2.83916 4 3.84615V8.93706C4 11.9021 6.19552 14.4196 9.0497 14.8112V17.2727C9.0497 18.5035 9.48881 19.6504 10.2572 20.5175C11.108 21.4685 12.3155 22 13.578 22C14.6757 22 15.746 21.5804 16.5694 20.8531L17.3104 20.1818C17.6671 20.2657 18.0513 20.2657 18.4081 20.1538C19.2314 19.9301 19.8352 19.2308 19.9724 18.3916C20.0822 17.6923 19.8627 16.965 19.3412 16.4615ZM18.4356 18.0559C18.4356 18.3636 18.1886 18.6434 17.8592 18.6434C17.5299 18.6434 17.2829 18.3916 17.2829 18.0559C17.2829 17.7483 17.5299 17.4685 17.8592 17.4685C18.1886 17.4685 18.4356 17.7203 18.4356 18.0559Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
    </Svg>
  );
};

export default StethoscopeIcon;
