import React from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Typography } from '../Typography';
import { PanelTypes } from './types';
import * as S from './styles';
import { useTheme } from '@react-navigation/native';

export const Panel = ({ children, title, onBack, onHelp }: PanelTypes): JSX.Element => {
  const theme = useTheme();
  return (
    <S.Wrapper>
      {(title || onHelp) && (
        <S.WrapperTitle>
          {onBack && (
            <S.WrapperButtonBack onPress={onBack} testID="on-back-button">
              <Icon name="arrow-left" size={26} />
            </S.WrapperButtonBack>
          )}
          {title && (
            <Typography variant="heading1" color="black" bold>
              {title}
            </Typography>
          )}
          {onHelp && (
            <S.WrapperButtonHelp onPress={onHelp}>
              <Icon name="help-circle" size={24} color={theme?.colors?.primary} />
            </S.WrapperButtonHelp>
          )}
        </S.WrapperTitle>
      )}
      {children}
    </S.Wrapper>
  );
};
