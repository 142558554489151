import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const LocalizationIcon = ({ width, height, color }: IconProps) => {
  return (
    <Svg width={width ?? 75} height={height ?? 75} viewBox="8 8 79 79" fill="none">
      <G id="icon-large-local">
        <G id="Ellipse 54">
          <Circle cx="47" cy="47" r="40" fill={color} />
          <Circle cx="47" cy="47" r="37.8333" stroke="white" strokeWidth="4.33333" />
        </G>
        <Path
          id="Vector"
          d="M47 28.25C38.7498 28.25 32 35.0624 32 43.375C32 46.3748 32.7499 49.4374 34.3124 52.5001C35.5 54.8753 37.1876 57.25 39.3125 59.5C42.8752 63.3125 46.4375 65.5625 46.5626 65.6249C46.6877 65.6872 46.8124 65.75 46.9375 65.75C47.0626 65.75 47.1873 65.6877 47.3124 65.6249C47.4376 65.5625 50.9998 63.3125 54.5625 59.5C56.6874 57.25 58.375 54.8749 59.5626 52.5001C61.2498 49.4998 61.9997 46.4377 61.9997 43.375C61.9997 35.0625 55.2503 28.25 47 28.25ZM49.7083 47.625C46.9582 50.6249 47.0207 47.6821 45.9583 48.3696C44.9582 47.682 46.8334 50.5625 44.0833 47.625C40.8959 44.2502 42.8333 47 41.2152 44.7236C42.8333 37.625 40.9997 40.75 47 37.125C52.9654 40.125 50.2012 39.0237 52.2083 41.375C52.2083 47.6874 52.8333 44.25 49.7083 47.625ZM47 37.125C43.6875 37.125 40.9997 39.8122 40.9997 43.1252C40.9997 46.4377 43.687 49.1254 47 49.1254C50.3124 49.1254 53.0002 46.4381 53.0002 43.1252C53.0002 39.8127 50.3124 37.125 47 37.125ZM47 47.6252C44.5002 47.6252 42.5 45.6249 42.5 43.1252C42.5 40.6254 44.5002 38.6252 47 38.6252C49.4997 38.6252 51.4999 40.6254 51.4999 43.1252C51.4999 45.6249 49.4997 47.6252 47 47.6252Z"
          fill="white"
        />
      </G>
    </Svg>
  );
};
