import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { StyledDefaultTheme } from '~/components/@hello-ui';

export const Description = styled.View`
  background-color: ${StyledDefaultTheme.colors.white};
  bottom: 0;
  margin: 20px;
  ${({ theme: { isMobile } }) => (isMobile ? ' width: 280px' : 'width: 400px')};
  ${({ theme: { isMobile } }) => (isMobile ? ' height: 90px;' : 'height: 90px;')};
`;

export const PrivacyWrapper = styled.View<{ show?: boolean }>`
  background-color: ${StyledDefaultTheme.colors.white};
  width: 100%;
  height: 200px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  ${({ show }) =>
    !show &&
    `
    display: none;
  `}

  ${({ theme: { isMobile } }) =>
    Platform.OS === 'web' &&
    isMobile &&
    `
    text-align: center;
  `}
`;

export const TextLink = styled.Text`
  font-size: 14px;
  text-decoration: underline;
  color: ${StyledDefaultTheme.colors.primary};
  text-decoration-color: ${StyledDefaultTheme.colors.primary};
`;

export const Text = styled.Text`
  font-size: 14px;
  color: ${StyledDefaultTheme.colors.black60};
`;
