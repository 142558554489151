import styled, { css } from 'styled-components/native';

export const WrapperCloseButton = styled.View`
  ${({ theme: { tokens, isMobile } }) => css`
    position: absolute;
    align-items: flex-end;
    justify-content: flex-end;
    top: ${isMobile ? tokens.space.s16 : tokens.space.s32};
    right: ${isMobile ? tokens.space.s16 : tokens.space.s32};
    z-index: ${tokens.zIndex.z1000};
  `}
`;

export const CloseButton = styled.TouchableOpacity`
  ${({ theme: { tokens, colors } }) => css`
    z-index: ${tokens.zIndex.z1000};
    align-items: center;
    justify-content: center;
    width: ${tokens.space.s40};
    height: ${tokens.space.s40};
    border-radius: ${tokens.space.s20};
    background-color: ${colors.background};
  `}
`;
