import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { Linking, View } from 'react-native';
import { DeepLinkFirstAccessStepProps } from '../types';
import { PageWrapper } from '~/screens/Login/components/PageWrapper';
import { Button, Input, Typography, useSweetAlert } from '~/components/@hello-ui';
import { instanceApi } from '~/App';

interface FormData {
  email: string;
}

const validationSchema: yup.SchemaOf<FormData> = yup.object().shape({
  email: yup.string().email('Digite um e-mail válido').required('Campo obrigatório'),
});

export const DeepLinkFirstAccessEmail = ({
  setStep,
  firstAccessData,
  setFirstAccessData,
  privacyPolicyLink,
}: DeepLinkFirstAccessStepProps) => {
  const theme = useTheme();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const [loading, setLoading] = React.useState(false);

  const defaultValues: FormData = {
    email: firstAccessData.email,
  };
  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });

  const handleForm = async (data: FormData) => {
    try {
      setLoading(true);
      await instanceApi.updateDeepLinkFirstAccessData(data);
      setFirstAccessData({ ...firstAccessData, email: data.email });
      setStep('phone');
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cadastrar seu e-mail. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper onGoBack={() => setStep('gender')}>
      <View className="mb-16 mobile:mb-8">
        <Typography variant="title">Qual o seu e-mail?</Typography>
      </View>
      <Typography variant={theme.isMobile ? 'body1' : 'body2'} className="mb-24 mobile:mb-32">
        Neste e-mail você receberá todas atualizações e informações.
      </Typography>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            variant="simple"
            placeholder="seu@email.com"
            label="E-mail"
            error={error?.message}
            keyboardType="email-address"
          />
        )}
      />
      <View className="mb-24 mt-auto mobile:mb-68">
        <Button
          variant="outLine"
          onPress={handleSubmit(handleForm)}
          disabled={!isFormValid || loading}
          loading={loading}>
          Próximo
        </Button>
      </View>
    </PageWrapper>
  );
};
