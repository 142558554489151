import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const LockPasswordIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        fill={color ?? theme.colors.primary}
        fillRule="evenodd"
        d="M17.5 10.499h1c.827 0 1.5.673 1.5 1.5V13h-1v-1.001c0-.276-.224-.5-.5-.5h-13c-.276 0-.5.224-.5.5V13H4v-1.001c0-.827.673-1.5 1.5-1.5h1v-4C6.5 3.467 8.97 1 12.002 1c3.032 0 5.5 2.467 5.5 5.5v3.999ZM4 20v1.498c0 .827.673 1.5 1.5 1.5h13c.827 0 1.5-.673 1.5-1.5V20h-1v1.498c0 .276-.225.501-.501.501h-13a.501.501 0 0 1-.5-.5V20H4Zm8-18C9.52 2 7.5 4.018 7.5 6.5v3.999h9v-4C16.5 4.02 14.48 2 12 2Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? theme.colors.primary}
        d="M17.5 10.499h-.1v.1h.1v-.1Zm2.5 1.5h.1-.1ZM20 13v.1h.1V13H20Zm-1 0h-.1v.1h.1V13Zm0-1.001h.1-.1Zm-14 0h.1H5ZM5 13v.1h.1V13H5Zm-1 0h-.1v.1H4V13Zm2.5-2.501v.1h.1v-.1h-.1ZM4 20v-.1h-.1v.1H4Zm16 1.498h-.1.1ZM20 20h.1v-.1H20v.1Zm-1 0v-.1h-.1v.1h.1Zm0 1.498.02.099.08-.016v-.082H19Zm0 0-.02-.098-.08.016v.083h.1Zm-.501.501v.1-.1Zm-13 0v-.1.1Zm-.5-.5h-.1.1ZM5 20h.1v-.1H5v.1ZM7.5 6.5h.1-.1Zm0 3.999h-.1v.1h.1v-.1Zm9 0v.1h.1v-.1h-.1Zm2-.1h-1v.2h1v-.2Zm0 0v.2-.2Zm1.6 1.6c0-.882-.718-1.6-1.6-1.6v.2c.771 0 1.4.628 1.4 1.4h.2Zm0 1.001v-1.001h-.2V13h.2Zm-1.1.1h1v-.2h-1v.2Zm-.1-1.101V13h.2v-1.001h-.2Zm-.4-.4c.22 0 .4.18.4.4h.2c0-.331-.269-.6-.6-.6v.2Zm-13 0h13v-.2h-13v.2Zm-.4.4c0-.22.18-.4.4-.4v-.2a.6.6 0 0 0-.6.6h.2ZM5.1 13v-1.001h-.2V13h.2Zm-1.1.1h1v-.2H4v.2Zm-.1-1.101V13h.2v-1.001h-.2Zm1.6-1.6c-.882 0-1.6.718-1.6 1.6h.2c0-.772.628-1.4 1.4-1.4v-.2Zm1 0h-1v.2h1v-.2Zm-.1-3.9v4h.2v-4h-.2ZM12.002.9a5.607 5.607 0 0 0-5.6 5.6h.2c0-2.978 2.424-5.4 5.4-5.4V.9Zm5.6 5.6C17.6 3.41 15.087.9 12 .9v.2c2.976 0 5.4 2.422 5.4 5.4h.2Zm0 3.999v-4h-.2v4h.2ZM3.9 20v1.498h.2V20h-.2Zm0 1.498c0 .882.718 1.6 1.6 1.6v-.2c-.771 0-1.4-.628-1.4-1.4h-.2Zm1.6 1.6h13v-.2h-13v.2Zm13 0c.882 0 1.6-.718 1.6-1.6h-.2c0 .771-.629 1.4-1.4 1.4v.2Zm1.6-1.6V20h-.2v1.498h.2ZM20 19.9h-1v.2h1v-.2Zm-1.1.1v1.498h.2V20h-.2Zm.081 1.4.037.197h.001l-.038-.197Zm-.082.099c0 .22-.18.4-.4.4v.2c.331 0 .6-.27.6-.6h-.2Zm-.4.4h-13v.2h13v-.2Zm-13 0c-.22 0-.4-.18-.4-.4h-.2c0 .33.27.6.6.6v-.2Zm-.4-.4V20h-.2v1.498h.2ZM5 19.9H4v.2h1v-.2ZM7.6 6.5c0-2.427 1.974-4.4 4.4-4.4v-.2a4.605 4.605 0 0 0-4.6 4.6h.2Zm0 3.999v-4h-.2v4h.2Zm8.9-.1h-9v.2h9v-.2Zm-.1-3.9v4h.2v-4h-.2ZM12 2.1c2.426 0 4.4 1.975 4.4 4.4h.2c0-2.536-2.064-4.6-4.6-4.6v.2Z"
      />
      <Rect
        width={20}
        height={7}
        x={2}
        y={13}
        stroke={color ?? theme.colors.primary}
        strokeWidth={1.2}
        rx={1}
      />
      <Path
        fill={color ?? theme.colors.primary}
        d="m5.305 17.33.554-.664-.793-.22.159-.461.776.32-.059-.86h.52l-.063.877.76-.313.155.467-.805.22.534.646-.42.288-.449-.717-.449.693-.42-.276ZM9.305 17.33l.554-.664-.793-.22.159-.461.776.32-.059-.86h.52l-.063.877.76-.313.155.467-.805.22.534.646-.42.288-.449-.717-.449.693-.42-.276ZM13.305 17.33l.554-.664-.793-.22.159-.461.776.32-.059-.86h.52l-.063.877.76-.313.155.467-.805.22.534.646-.42.288-.449-.717-.449.693-.42-.276ZM17.305 17.33l.554-.664-.793-.22.159-.461.776.32-.059-.86h.52l-.063.877.76-.313.155.467-.805.22.535.646-.42.288-.45-.717-.449.693-.42-.276Z"
      />
    </Svg>
  );
};

export default LockPasswordIcon;
