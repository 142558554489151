import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from '~/components/Icons/types';

const BarCodeIcon = ({ color, height, width }: IconProps) => {
  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        fill={color ?? '#4DB6AC'}
        d="M5.95 5.999h-.34l4.02-4.02a3.359 3.359 0 0 1 4.74 0l4.02 4.02h-.34c-.8 0-1.555.31-2.12.88l-3.4 3.385a.745.745 0 0 1-1.06 0l-3.4-3.385a2.964 2.964 0 0 0-2.12-.88ZM18.05 18.002h.34l-4.02 4.02a3.359 3.359 0 0 1-4.74 0l-4.02-4.02h.34c.8 0 1.555-.31 2.12-.88l3.4-3.385a.745.745 0 0 1 1.06 0l3.4 3.385c.565.57 1.32.88 2.12.88Z"
      />
      <Path
        fill={color ?? '#4DB6AC'}
        d="M22.02 14.37 19.39 17h-1.34c-.535 0-1.035-.21-1.415-.585l-3.4-3.39a1.75 1.75 0 0 0-2.47 0l-3.4 3.39c-.38.375-.88.585-1.415.585H4.61l-2.63-2.63a3.359 3.359 0 0 1 0-4.74L4.61 7h1.34c.535 0 1.035.21 1.415.585l3.4 3.39c.34.34.79.51 1.235.51.445 0 .895-.17 1.235-.51l3.4-3.39c.38-.375.88-.585 1.415-.585h1.34l2.63 2.63a3.359 3.359 0 0 1 0 4.74Z"
      />
    </Svg>
  );
};

export default BarCodeIcon;
