import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import React, { useState } from 'react';
import * as S from '~/components/@hello-ui/Input/styles';
import { Typography } from '~/components/@hello-ui';
import { CodeInputProps } from '~/components/@hello-ui/Input/components/CodeInput/types';

const CELL_COUNT = 4;

export const CodeInput: React.FC<CodeInputProps> = ({
  error,
  onChangeText,
  value,
  testID,
  isAutoFocus,
  onSubmitEditing,
  keyboardType,
}) => {
  const [internalValue, setInternalValue] = useState('');

  const ref = useBlurOnFulfill({ value: value ?? internalValue, cellCount: CELL_COUNT });
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: internalValue,
    setValue: setInternalValue,
  });

  return (
    <CodeField
      {...codeFieldProps}
      ref={ref}
      value={value ?? internalValue}
      onSubmitEditing={onSubmitEditing}
      onChangeText={(text) => {
        const newText = text.replace(/\D+/gim, '');

        setInternalValue(newText);
        onChangeText?.(newText);
      }}
      autoFocus={isAutoFocus}
      testID={testID}
      caretHidden={false}
      cellCount={CELL_COUNT}
      textContentType="oneTimeCode"
      keyboardType={keyboardType}
      renderCell={({ index, symbol, isFocused }) => (
        <S.WrapperCode key={index} error={!!error} isFirst={index === 0}>
          <Typography variant="title" color="paragraph" onLayout={getCellOnLayoutHandler(index)}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Typography>
        </S.WrapperCode>
      )}
    />
  );
};
