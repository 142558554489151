import { RouteProp, useRoute } from '@react-navigation/native';
import React, { memo, useEffect } from 'react';
import axios from 'axios';
import { DeeplinkErrorDialog } from './components/DeeplinkErrorDialog';
import { AuthService } from '~/auth/auth-service';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

type RouteProps = {
  params: {
    code: string;
  };
};

const DeepLink: React.FC = () => {
  const { params: queryParams } = useRoute<RouteProp<RouteProps, 'params'>>();
  const [error, setError] = React.useState<string | null>(null);

  const url = window.location.href;

  const slugParameter = url.split('/');

  const slug = slugParameter[3];

  useEffect(() => {
    const signInWithDeepLink = async (code: string, slug: string) => {
      try {
        await AuthService.authenticate(EAuthStrategy.DeepLink, { code, slug });
      } catch (error) {
        console.error('DeepLink error', error);
        const errorMessage = resolveErrorMessage(error);
        setError(errorMessage);
      }
    };

    if (queryParams?.code) {
      signInWithDeepLink(queryParams.code, slug).catch((err) => console.error(err));
    }
  }, [queryParams.code]);

  const resolveErrorMessage = (error: unknown): string => {
    const defaultErrorMessage = 'Ocorreu um erro inesperado. Tente novamente.';
    const errorMessages: Record<string, string> = {
      '400': 'O link não é válido. Tente novamente.',
      '422': 'O link não é válido. Tente novamente.',
      '410': 'Link expirado. Tempo não é mais valido.',
      '404': 'O link não é válido. Tente novamente.',
      '403': 'Link já utilizado',
    };
    if (axios.isAxiosError(error)) {
      if (error.response?.data?.message === 'Disabled card') {
        return 'Cartão desabilitado';
      }

      const statusCode = error.response?.status?.toString();
      if (statusCode) {
        return errorMessages[statusCode] || defaultErrorMessage;
      }
    }
    return defaultErrorMessage;
  };

  return <>{error && <DeeplinkErrorDialog errorMessage={error} />}</>;
};

export default memo(DeepLink, () => true);
