import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button, DetailsList, MobileAutoSpace, PageWithCard } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { toCamelCase } from '~/utils/strings';

export const ProfileAddress: React.FC = () => {
  const { user } = useAuth();
  const navigation = useNavigation();

  const items = useMemo(
    () => [
      {
        title: 'CEP',
        description: user?.address?.postal_code || '-',
      },
      {
        title: 'Estado',
        description: user?.address?.state || '-',
      },
      {
        title: 'Cidade',
        description: toCamelCase(user?.address?.city ?? '') || '-',
      },
      {
        title: 'Rua',
        description: toCamelCase(user?.address?.address ?? '') || '-',
      },
      {
        title: 'Bairro',
        description: toCamelCase(user?.address?.neighborhood ?? '') || '-',
      },
      {
        title: 'Número',
        description: user?.address?.number?.trim() || '-',
      },
      {
        title: 'Complemento',
        description: toCamelCase(user?.address?.complement ?? '') || '-',
      },
    ],
    [user],
  );

  return (
    <PageWithCard
      simpleOptions={{ title: 'Endereço', onBack: () => navigation.navigate('Profile') }}>
      <DetailsList items={items} />
      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button variant="primary" onPress={() => navigation.navigate('UpdateProfileAddress')}>
        Atualizar endereço
      </Button>
    </PageWithCard>
  );
};
