import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { ScheduleToChoose } from './ScheduleToChoose';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useApi } from '~/hooks/api';
import { useAppointment } from '~/hooks/appointment';
import { AppointmentFlowType } from '~/enums/appointment';
import { MapViewList } from '~/components/MapViewList';
import { AppointmentTypes, Certified, Specialty } from '~/contexts/types';
import { CertifiedsToMapLocationsDTO, ListItem } from '~/components/MapViewList/MapViewListDTO';

interface ScheduleUnitProps {
  specialty?: Specialty;
  isExam?: boolean;
}

export const ScheduleUnit = ({ specialty, isExam }: ScheduleUnitProps): JSX.Element => {
  usePageWithCardSettings({
    scrollEnabled: false,
    overrideMaxBodyWidth: 'auto',
  });

  const api = useApi();

  const { nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [unities, setUnities] = useState<ListItem<Certified>[]>();
  const [nextPage, setNextPage] = useState<number | undefined>(undefined);
  const [currentCoordinates, setCurrentCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const certifiedToAppointmentClinic = (certified: Certified): AppointmentTypes['clinic'] => {
    return {
      name: certified.corporate_name,
      cnpj: certified.cpf_cnpj,
      email: '',
      address: {
        number: String(certified.number),
        street: certified.street,
        city: certified.city,
        neighbourhood: certified.neighborhood,
        state: certified.state,
        zipCode: certified.postal_code,
        lat: String(certified.lat),
        long: String(certified.long),
      },
    };
  };

  const handleSelect = (item: ListItem<Certified>) => {
    appointment &&
      setAppointment({
        ...appointment,
        clinic: item.data ? certifiedToAppointmentClinic(item.data) : undefined,
        flowType: AppointmentFlowType.Clinic,
      });
    nextStep();
  };

  const pageSize = theme.isDesktop ? 20 : 8;

  const handleNextUnitPage = async () => {
    setLoading(true);

    api
      .searchCertifiedTemRede('clinic', {
        specialty: Number(appointment?.specialty?.id),
        latitude: currentCoordinates?.lat,
        longitude: currentCoordinates?.lng,
        page: nextPage,
        page_size: pageSize,
        sort: 'distance',
        order: 'asc',
      })
      .then(({ data }) => {
        setUnities((prev) => (prev ? [...prev, ...CertifiedsToMapLocationsDTO(data)] : []));
        setNextPage((prev) => (prev ? (prev < data.last_page ? prev + 1 : undefined) : undefined));
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const getUnities = async () => {
    if (appointment?.address) {
      setLoading(true);
      try {
        const { city, state, neighborhood } = appointment?.address || {};

        if (specialty) {
          appointment.specialty = specialty;
        }

        const { data } = await api.searchZipCode(`${neighborhood.join(', ')}, ${city} / ${state}`);
        const { lat, lng } = data;
        await api
          .searchCertifiedTemRede('clinic', {
            specialty: Number(appointment.specialty?.id),
            latitude: lat,
            longitude: lng,
            page_size: pageSize,
            sort: 'distance',
            order: 'asc',
          })
          .then(({ data }) => {
            setUnities(CertifiedsToMapLocationsDTO(data));
            setNextPage(data.last_page >= 2 ? 2 : undefined);
            setCurrentCoordinates({ lat, lng });
          });
      } catch {
        setUnities([]);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getUnities();
  }, []);

  const title = `${appointment?.address?.neighborhood[0]}, ${appointment?.address?.city} - ${appointment?.address?.state}`;

  const showNextPageButton = nextPage !== undefined;

  return unities?.length === 0 ? (
    <ScheduleToChoose />
  ) : (
    <MapViewList
      title={title}
      mapId="unit-map"
      data={unities}
      onClickCard={!isExam ? handleSelect : undefined}
      loading={loading}
      showNextPageButton={showNextPageButton}
      handleNextPage={handleNextUnitPage}
      hasButton={!isExam}
    />
  );
};
