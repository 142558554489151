import React from 'react';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { View } from 'react-native';
import { FloatingButton } from '../../@tem-ui';
import { downloadImage } from './downloadImage';

interface ComponentToImageProps {
  viewRef?: React.RefObject<View>;
}

export const DownloadComponentImage: React.FC<ComponentToImageProps> = ({ viewRef }) => {
  const theme = useTheme();

  return (
    <FloatingButton
      onPress={async () => {
        await downloadImage(viewRef);
      }}
      icon={<Icon name="download" size={24} color={theme?.colors?.primary} />}
    />
  );
};
