import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const PetAccommodationIcon = ({ width = 24, height = 24, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.6 20.95H20.9554V11.2C20.9554 10.979 20.7765 10.8 20.5555 10.8H16.7343V6C16.7343 5.77902 16.5554 5.60006 16.3344 5.60006H14.9702C14.7736 4.13473 13.5184 3 12 3C10.4815 3 9.22645 4.13456 9.02974 5.60006H7.66585C7.4447 5.60006 7.26591 5.77919 7.26591 6V10.8H3.44442C3.22326 10.8 3.04447 10.9791 3.04447 11.2V20.95H2.39994C2.17879 20.95 2 21.1291 2 21.3499C2 21.5709 2.17896 21.7499 2.39994 21.7499H7.66581L10.3625 21.75H21.6001C21.8212 21.75 22 21.5709 22 21.3501C22 21.1291 21.8212 20.95 21.6001 20.95L21.6 20.95ZM12 3.80023C13.2129 3.80023 14.1999 4.7871 14.1999 6.00018C14.1999 7.21325 13.2129 8.20012 12 8.20012C10.7871 8.20012 9.80001 7.21325 9.80001 6.00018C9.80001 4.7871 10.7871 3.80023 12 3.80023ZM3.84466 11.6002H7.26621V20.9504L3.84466 20.9502V11.6002ZM8.06609 11.2001V6.40012H9.02987C9.22641 7.86545 10.4817 9.00019 12.0001 9.00019C13.5185 9.00019 14.7736 7.86563 14.9703 6.40012H15.9346V20.9501H14.0376V17.6252C14.0376 17.4042 13.8587 17.2253 13.6377 17.2253H10.3626C10.1415 17.2253 9.9627 17.4044 9.9627 17.6252V20.9501H8.06627L8.06609 11.2001ZM10.7626 20.9501V18.0251H13.2376V20.9501H10.7626ZM16.7343 20.9501V11.6H20.1553V20.9501H16.7343Z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.15"
      />
      <Rect
        x="11.725"
        y="4.85"
        width="0.55"
        height="2.3"
        rx="0.275"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.3"
      />
      <Rect
        x="13.15"
        y="5.725"
        width="0.55"
        height="2.3"
        rx="0.275"
        transform="rotate(90 13.15 5.725)"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth="0.3"
      />
    </Svg>
  );
};

export default PetAccommodationIcon;
