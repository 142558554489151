import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  max-width: 1240px;
  margin-top: 0px;

  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    ${Platform.OS === 'web' && 'width: calc(100% - 64px)'}
    margin-top: 32px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    elevation: 2;
  `}
  border-radius: 20px;
`;

export const WrapperTitle = styled.View`
  height: 86px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 0.5px;
  border-bottom-color: ${({ theme: { colors } }) => colors.black16};
  margin-bottom: 32px;
`;

export const WrapperButtonBack = styled.TouchableOpacity`
  margin-left: 16px;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  z-index: 1;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const WrapperButtonHelp = styled.TouchableOpacity`
  align-items: flex-end;
  width: 32px;
  height: 32px;
  margin: auto;
  margin-right: 24px;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-right: 16px;
  `}
`;
