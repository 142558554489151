import { Platform } from 'react-native';

import './extensiveNumber';

export const split = (value: string): string[] => value.split(' ');

export const capitalize = (value: string): string => {
  if (!value) return '';

  return value
    .split('')
    .map((letter, index) => (index ? letter.toLowerCase() : letter.toUpperCase()))
    .join('');
};

export const localCapitalize = (input: string): string => {
  if (!input) return '';

  const exceptions = ['da', 'dos', 'de', 'do', 'das'];
  return input
    .toLowerCase()
    .split(' ')
    .map((word) =>
      exceptions.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(' ');
};

export const toCamelCase = (value: string) => {
  if (!value) return '';

  return value
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

if (Platform.OS === 'android') {
  require('intl');
  require('intl/locale-data/jsonp/en-IN');
}

export const currency = (
  value: number,
  localization = 'pt-BR',
  coin = 'BRL',
  currency = false,
  extraParams?: { currencyDisplay?: string },
): string => {
  const params: {
    currency: string;
    style?: string;
    minimumFractionDigits?: number;
  } = {
    currency: coin,
  };

  if (currency) {
    params.style = 'currency';
  } else {
    params.minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat(localization, {
    ...params,
    ...extraParams,
  }).format(value);
};

export const fileSize = (value: string, decimals?: number): string => {
  if (!value || value === '') {
    return '0 B';
  }

  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(Number(value)) / Math.log(k));

  return `${parseFloat((Number(value) / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const serializeString = (value: string): string => {
  value = value.replace(/[áàãâä]/iu, 'a');
  value = value.replace(/[éèêë]/iu, 'e');
  value = value.replace(/[íìîï]/iu, 'i');
  value = value.replace(/[óòõôö]/iu, 'o');
  value = value.replace(/[úùûü]/iu, 'u');
  value = value.replace(/[ç]/iu, 'c');

  return value.toLowerCase();
};

export const serializeAndUpperString = (value: string): string => {
  value = value?.replace(/[áàãâä]/iu, 'a');
  value = value?.replace(/[éèêë]/iu, 'e');
  value = value?.replace(/[íìîï]/iu, 'i');
  value = value?.replace(/[óòõôö]/iu, 'o');
  value = value?.replace(/[úùûü]/iu, 'u');
  value = value?.replace(/[ç]/iu, 'c');

  return value?.toUpperCase();
};

export const cleanString = (input: string): string => {
  const normalizedString = input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  const cleanedString = normalizedString.replace(/[^a-z0-9]/g, '');

  return cleanedString;
};

export const extensiveNumber = (value: string, isCurrent = false): string => {
  return value.extensiveNumber(isCurrent);
};

export const orderByKey = (arr: any[], key: string) => {
  arr.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const extractDigits = (value: string | undefined): string => {
  if (typeof value !== 'string' || !value) {
    return '';
  }

  const digits = value.match(/\d/g);

  if (digits) {
    return digits.join('');
  } else {
    return '';
  }
};

export const abbreviateName = (name: string): string => {
  const texts: string[] = name.split(' ');

  if (texts.length > 2) {
    for (let i = 1; i < texts.length - 1; i++) {
      if (texts[i].length !== 2) {
        texts[i] = texts[i][0];
      }
    }
  }

  return texts.join(' ');
};

export const maskTelephone = (telephone: string): string => {
  if (!telephone) return '';

  const cleanTelephone = telephone.replace(/[^0-9]/g, '');

  const ddd = cleanTelephone.substring(0, 2);
  const lastNumbers = cleanTelephone.substring(cleanTelephone.length - 3);
  const hasNine = cleanTelephone.length === 11;

  return `(${ddd}) ${hasNine ? '9' : ''}****-*${lastNumbers}`;
};
