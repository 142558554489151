import { useRecoilState } from 'recoil';
import { tempAppointments, tempAppointment, tempAppointmentLoad } from './atom';
import { Appointment, useAppointmentsTypes } from './types';

export const useMyAppointments = (): useAppointmentsTypes => {
  const [myAppointments, setMyAppointments] = useRecoilState(tempAppointments);
  const [myAppointment, setMyAppointment] = useRecoilState(tempAppointment);
  const [myAppointmentLoad, setMyAppointmentLoad] = useRecoilState(tempAppointmentLoad);

  const setSelectedAppointment = (appointment: Appointment | null) => {
    setMyAppointment(appointment);
  };

  const clearState = () => {
    setMyAppointments([]);
    setMyAppointment(null);
    setMyAppointmentLoad(false);
  };

  return {
    setMyAppointments,
    setSelectedAppointment,
    myAppointments,
    myAppointment,
    clearState,
    setMyAppointmentLoad,
    myAppointmentLoad,
  };
};
