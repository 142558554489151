import React, { useEffect, useState } from 'react';

import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsHealthyHabitsProgram/desktop.png';
import mobileBackground from '../../assets/images/benefitsHealthyHabitsProgram/mobile.png';
import { MedexWebView } from './components/MedexWebView';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, Dialog, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import Health from '~/components/Icons/Health';
import { useAuth } from '~/auth/legacy/useAuth';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsHealthyHabitsProgram = (): JSX.Element => {
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;
  const [dialogState, setDialogState] = useState<boolean>(false);

  const { user } = useAuth();

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <Health color="white" />,
          title: 'Programa Hábitos Saudáveis',
          onBack: () => {
            navigation.goBack();
          },
        }}>
        <View>
          <Typography variant="body2">
            Receba apoio para realizar mudanças positivas em sua{' '}
            <Typography variant="bodyHighlight2">alimentação</Typography>,{' '}
            <Typography variant="bodyHighlight2">corpo</Typography> e{' '}
            <Typography variant="bodyHighlight2">mente</Typography>. Tenha acompanhamento de
            profissionais especializados para montar seu cardápio, preparar seu treino e cuidar da
            sua saúde mental.
          </Typography>
        </View>
        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="mb-8">
              <Typography variant="body2">
                No programa de hábitos saudáveis você realiza{' '}
                <Typography variant="bodyHighlight2">consultas mensais</Typography> com o
                nutricionista, psicólogo e profissional de educação física e também pode tirar suas
                dúvidas do dia a dia na área de suporte na Central de Atendimento.
              </Typography>
            </View>
          </Collapse>
        </View>
        <MobileAutoSpace />
        <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
          <Typography variant="bodyHighlight2">Para utilizar o benefício, é só acessar</Typography>
          <Typography variant="bodyHighlight2">o canal e solicitar o serviço:</Typography>
        </View>
        <Button variant="primary" onPress={() => setDialogState(true)} loading={false}>
          Acessar
        </Button>
      </PageWithCard>

      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <MedexWebView document={user?.cpf ?? ''} />
      </Dialog>
    </>
  );
};
