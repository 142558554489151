import React, { useState } from 'react';

import { View, Platform } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';

import desktopBackground from '../../assets/images/benefitsMentalHealthProgram/desktop.png';
import mobileBackground from '../../assets/images/benefitsMentalHealthProgram/mobile.png';

import { MedexWebView } from './components/MedexWebView';
import { useAuth } from '~/auth/legacy/useAuth';

import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
  Dialog,
} from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

import MentalHealthIcon from '~/components/Icons/MentalHealth';

export const BenefitsMentalHealthProgram = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const [dialogState, setDialogState] = useState<boolean>(false);

  const { user } = useAuth();

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <MentalHealthIcon color={theme.colors.white} />,
          title: 'Programa de Saúde Mental',
          onBack: () => {
            navigation.goBack();
          },
        }}>
        <View>
          <Typography variant="body2">
            Tenha apoio para{' '}
            <Typography variant="bodyHighlight2">cuidar da sua saúde mental,</Typography> contando
            com o acompanhamento de psicólogos para realizar sua terapia.
          </Typography>
        </View>

        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="mb-8 space-y-8">
              <Typography variant="body2">
                Com o Programa de Saúde Mental você possui um conjunto de serviços dedicados a
                identificar, tratar e acompanhar a sua saúde mental.
              </Typography>

              <Typography variant="body2">
                <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> O programa inclui 2
                atendimentos mensais de 30 minutos cada, realizados por vídeo chamada. Esses
                atendimentos não são acumulativos e não podem ser transferidos para outros meses
                caso não sejam utilizados.
              </Typography>
            </View>
          </Collapse>
        </View>

        <MobileAutoSpace />

        <View className="mb-24 mt-40 w-full items-center self-center mobile:mb-16 mobile:mt-24">
          <Typography variant="bodyHighlight2" className="text-center mobile:w-[288px]">
            Para utilizar o benefício, é só acessar o canal e solicitar o serviço:
          </Typography>
        </View>

        <Button variant="primary" onPress={() => setDialogState(true)} loading={false}>
          Acessar
        </Button>
      </PageWithCard>

      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <MedexWebView document={user?.cpf ?? ''} />
      </Dialog>
    </>
  );
};
