import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const LockIcon = ({ width = 24, height = 24, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} fill="none">
      <Path
        d="M15.5001 10.4987H14.5004V6.49922C14.5004 3.46653 12.0326 1 9.00122 1C5.96966 1 3.5006 3.46667 3.5006 6.49922V10.4987H2.49985C1.67323 10.4987 1 11.1721 1 11.9986V21.4983C1 22.325 1.67342 22.9982 2.49985 22.9982H15.5C16.3266 22.9982 16.9998 22.3248 16.9998 21.4983L17 11.9986C17 11.172 16.3268 10.4987 15.5001 10.4987H15.5001ZM4.50026 6.49922C4.50026 4.01824 6.51916 1.99953 8.99995 1.99953C11.4809 1.99953 13.4996 4.01842 13.4996 6.49922V10.4987H4.50007L4.50026 6.49922ZM15.9994 21.4987C15.9994 21.7745 15.7748 21.999 15.499 21.999L2.4998 21.9989C2.22396 21.9989 1.99943 21.7743 1.99943 21.4985L1.99961 11.9987C1.99961 11.7229 2.22414 11.4984 2.49999 11.4984H15.5001C15.7759 11.4984 16.0005 11.7229 16.0005 11.9987L16.0003 21.4985L15.9994 21.4987Z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth={0.267}
      />
      <Path
        d="M10.5523 13.5417C9.95124 13.0633 9.16946 12.8902 8.40509 13.0688C7.5106 13.2768 6.79221 13.9875 6.57443 14.8798C6.33027 15.8774 6.69926 16.8912 7.49844 17.4911V19.001C7.49844 19.8277 8.17192 20.501 8.99842 20.501C9.8251 20.501 10.4984 19.8275 10.4984 19.001V17.4911C11.1214 17.0213 11.4981 16.2834 11.4981 15.5016C11.4983 14.733 11.1535 14.0192 10.5523 13.5417ZM9.7465 16.7903C9.59326 16.8802 9.49794 17.0444 9.49794 17.2228V18.9999C9.49794 19.2757 9.2734 19.5003 8.99753 19.5003C8.72166 19.5003 8.49712 19.2757 8.49712 18.9999V17.2229C8.49712 17.0444 8.4018 16.8802 8.24856 16.7905C7.65612 16.4456 7.38025 15.7886 7.54449 15.1164C7.67151 14.5953 8.10834 14.1638 8.63072 14.0422C9.10274 13.9339 9.56242 14.0335 9.92924 14.3236C10.2905 14.6105 10.4975 15.0387 10.4975 15.4995C10.4986 16.0349 10.2172 16.5167 9.74646 16.7904L9.7465 16.7903Z"
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth={0.133}
      />
    </Svg>
  );
};

export default LockIcon;
