import { persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { IAuthStore } from '~/store/interfaces/auth-store';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import { useGlobalStore } from '~/store/global.store';
import { AuthService } from '~/auth/auth-service';

// IMPORTANT: Don't modify these directly, use the `AuthService` instead
export const useAuthStore = createWithEqualityFn(
  persist<IAuthStore>(
    () => ({
      isFirstAccess: false,
      authStrategy: EAuthStrategy.Unauthenticated,
      user: null,
      dependents: null,
      userProducts: [],
      selectedProduct: null,
      invalidGatekeepers: [],
      blockRenders: false,
      authenticationIndex: 0,

      // Don't access these directly, use the `AuthService` instead
      token: null,
      // Don't access these directly, use the `AuthService` instead
      refreshToken: null,
    }),
    {
      partialize: (state) =>
        // Don't save these to AsyncStorage
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['invalidGatekeepers', 'blockRenders', 'authenticationIndex'].includes(key),
          ),
        ) as IAuthStore,
      name: '@tem::auth',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => {
        useGlobalStore.getState().addLoadingStore('auth');

        return async (state, error) => {
          useGlobalStore.getState().addLoadingStore('update-auth');
          useGlobalStore.getState().loadStore('auth');
          useAuthStore.setState({ blockRenders: true });
          try {
            if (!error && state) {
              await AuthService.onStoreLoaded();
            }
          } finally {
            useGlobalStore.getState().loadStore('update-auth');
            useAuthStore.setState({ blockRenders: false });
          }
        };
      },
    },
  ),
  (a, b) => {
    // Custom logic to block re-renders when blockRenders is true
    if (useAuthStore.getState().blockRenders) return true;

    if (typeof a == 'object' && typeof b == 'object') {
      return shallow(a, b);
    }

    return Object.is(a, b);
  },
);
