import React from 'react';
import { G, Rect, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const HealthCardIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="22" height="17" viewBox="0 0 22 17" fill="none">
      <G id="Group 896">
        <Rect
          id="Rectangle 354"
          x="1"
          y="1"
          width="20.1496"
          height="15.1122"
          rx="2"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.3"
        />
        <Rect
          id="Rectangle 355"
          x="1.83887"
          y="9.39502"
          width="18.4704"
          height="2.5187"
          fill={color ?? theme.colors.primary}
          stroke={color ?? theme.colors.primary}
        />
      </G>
    </Svg>
  );
};

export default HealthCardIcon;
