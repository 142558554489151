import { useTheme } from 'styled-components/native';
import React from 'react';
import { Platform } from 'react-native';
import { Typography } from '../Typography';
import * as S from './styles';
import { NavbarProps } from './types';
import { Spacing } from '~/components/Spacing';
/**
 * Navbar component to create a navigation bar with clickable items.
 *
 * @component
 * @param {NavbarProps} props - The component props
 * @param {NavbarItem[]} props.items - An array of objects representing navigation items.
 * @param {string} props.items.name - The name of the navigation item.
 * @param {React.ReactNode} props.items.icon - The icon component for the navigation item.
 * @param {string} props.items.goTo - The target screen to navigate when the item is pressed.
 * @param {boolean} [props.items.emphasis=false] - Flag indicating whether the item should have emphasis styling.
 * @returns {JSX.Element} JSX.Element
 */
export const Navbar = ({ items }: NavbarProps): JSX.Element => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      {items?.map(
        ({ name, icon, onPress, emphasis, visible }, i) =>
          visible && (
            <S.Link
              key={`${i}-${name}`}
              onPress={() => {
                if (onPress) {
                  onPress();
                }
              }}>
              <S.IconWrapper emphasis={emphasis}>
                {emphasis ? (
                  <Spacing bottom={Platform.OS === 'web' ? 5 : 2}>
                    <S.EmphasisIconWrapper emphasis={emphasis}>{icon}</S.EmphasisIconWrapper>
                  </Spacing>
                ) : (
                  icon
                )}
                <Typography variant="nav" color={theme.colors.black}>
                  {name?.toUpperCase()}
                </Typography>
              </S.IconWrapper>
            </S.Link>
          ),
      )}
    </S.Wrapper>
  );
};
