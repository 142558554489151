import styled, { css } from 'styled-components/native';

export const Wrapper = styled.View`
  flex: 1;
  width: 100%;
  height: 70px;
  padding: 12px;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
`;

export const IconWrapper = styled.View<{ emphasis?: boolean }>`
  flex: 1;
  min-height: 100%;
  max-height: 100%;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  justify-content: ${(emphasis) => (emphasis ? 'flex-end' : 'space-between')};
  ${({ theme: { isMobile } }) => (!isMobile && `display: flex; width: fit-content;`)};
`;

export const Link = styled.TouchableOpacity`
  width: auto;
`;

export const EmphasisIconWrapper = styled.View<{ emphasis?: boolean }>`
  ${({ emphasis }) => emphasis && css`
    min-width: 48px;
    min-height: 48px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme: { colors } }) => colors.primary};
  `}
`;

