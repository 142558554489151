import React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

import { useTheme } from 'styled-components/native';

interface IconProps extends SvgProps {
  size?: number;
}

function Icon({ size, color, width: iconWidth = 24, height: iconHeight = 24, ...rest }: IconProps) {
  const theme = useTheme();
  const width = size ?? iconWidth;
  const height = size ?? iconHeight;

  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
      <G clipPath="url(#clip0_13685_52429)">
        <Path
          fill={color ?? theme.colors.primary}
          d="M13 24H6c-1.65 0-3-1.35-3-3v-.97c0-.9-.26-1.73-.74-2.35C.58 15.48-.2 12.75.05 9.98.5 4.95 4.46.79 9.45.11c4.04-.56 7.91 1.06 10.35 4.31C21.07 5.99 24 11.58 24 13c0 .97-.7 2-2 2h-.33l-.22 1.66A5.012 5.012 0 0116.49 21H16c0 1.65-1.35 3-3 3zM11.01 2c-.42 0-.85.03-1.28.09-4.08.56-7.31 3.96-7.69 8.07-.21 2.27.44 4.5 1.81 6.3.74.97 1.15 2.24 1.15 3.57V21c0 .55.45 1 1 1h7c.55 0 1-.45 1-1v-1c0-.55.45-1 1-1h1.49c1.5 0 2.78-1.12 2.97-2.61l.33-2.52c.07-.5.49-.87.99-.87h1.2c-.12-.9-2.47-5.74-3.78-7.35C16.44 3.31 13.82 2 10.98 2h.03zm0 14.01c-.39 0-.77-.12-1.1-.38-1.78-1.38-3.9-3.44-3.9-5.47 0-1.74 1.35-3.16 3-3.16.78 0 1.48.27 2 .73.52-.46 1.22-.73 2-.73 1.65 0 3 1.42 3 3.16 0 2.03-2.12 4.09-3.9 5.47-.32.25-.71.38-1.1.38zm-2-7c-.55 0-1 .52-1 1.16 0 .87 1.17 2.35 3 3.79 1.83-1.44 3-2.92 3-3.79 0-.64-.45-1.16-1-1.16-.5 0-1 .31-1 1 0 .55-.45 1-1 1s-1-.45-1-1c0-.69-.5-1-1-1z"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_13685_52429">
          <Path fill="none" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default Icon;
