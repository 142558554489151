/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useTopLoadingBar } from '../hooks/useTopLoaderBar';
import { TopLoadingBarContainer } from './styles';

export const TopLoadingBar = () => {
  const theme = useTheme();

  const {
    processTopLoadingBar: { progress, hideOnCompletion = true, type },
  } = useTopLoadingBar();

  const [isVisible, setIsVisible] = useState(true);

  const animatedWidth = useRef(new Animated.Value(0)).current;

  const changeProgress = () => {
    return Animated.timing(animatedWidth, {
      toValue: progress,
      duration: 300,
      useNativeDriver: false,
    });
  };

  useEffect(() => {
    if (progress >= 100 && hideOnCompletion) {
      setIsVisible(false);
      changeProgress().stop();
    } else {
      setIsVisible(true);
      changeProgress().start();
    }
  }, [progress]);

  if (!isVisible) return null;

  const progressBarColor = {
    success: theme.colors.success,
    warning: theme.colors.warning,
    error: theme.colors.error,
  };

  const currentWidth = animatedWidth?._value;

  return (
    <>
      <TopLoadingBarContainer>
        <Animated.View
          testID="progress-bar"
          style={{
            width: `${currentWidth}%`,
            height: 5,
            backgroundColor: progressBarColor[type],
          }}
        />
      </TopLoadingBarContainer>
    </>
  );
};
