import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { IconProps } from '~/components/Icons/types';

const ClinicIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 27} height={height ?? 27} fill="none" viewBox="0 0 27 27">
      <Path
        fill={color ?? theme.colors.primary}
        stroke={color ?? theme.colors.primary}
        strokeWidth={0.15}
        d="M26.3 24.933h-.86v-13a.533.533 0 0 0-.532-.533h-5.095V5a.533.533 0 0 0-.534-.533h-1.818A4.003 4.003 0 0 0 13.5 1 4.003 4.003 0 0 0 9.54 4.467H7.721A.533.533 0 0 0 7.188 5v6.4H2.093a.533.533 0 0 0-.533.533v13H.7A.533.533 0 1 0 .7 26h25.6a.533.533 0 1 0 0-1.067ZM13.5 2.067A2.937 2.937 0 0 1 16.433 5 2.937 2.937 0 0 1 13.5 7.934 2.937 2.937 0 0 1 10.567 5 2.937 2.937 0 0 1 13.5 2.067Zm-10.873 10.4h4.562v12.467H2.627V12.467Zm5.628-.534v-6.4H9.54A4.003 4.003 0 0 0 13.5 9a4.003 4.003 0 0 0 3.96-3.466h1.286v19.4h-2.529V20.5a.533.533 0 0 0-.533-.533h-4.367a.533.533 0 0 0-.533.533v4.433H8.255v-13Zm3.595 13v-3.9h3.3v3.9h-3.3Zm7.963 0V12.467h4.561v12.466h-4.561Z"
      />
    </Svg>
  );
};

export default ClinicIcon;
