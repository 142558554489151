import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Panel } from '~/components/@tem-ui';
import { Header } from '~/components/Header';
import * as S from './styles';
import { Footer } from '~/components/Footer';
import { TypesEnum } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';
import { trackingEventType } from '~/contexts/tracking/types';
import { Form } from './components/Form';
import { useMedia } from '~/components/@hello-ui';
export const SurgicalProcedureForm = (): JSX.Element => {
  const media = useMedia();
  const navigation = useNavigation();

  const { submitAppointment } = useAppointment();

  return (
    <>
      {!media.isMobile && <Header />}
      <S.Container>
        <Panel
          title="Procedimentos cirúrgicos"
          onBack={() => {
            navigation.navigate('SurgicalProcedure');
          }}>
          <Form
            onSelect={() => {
              submitAppointment(trackingEventType.RequestedInSurgicalProcedure, 'clinica medica');
            }}
            type={TypesEnum.Surgical}
          />
        </Panel>
        <Footer />
      </S.Container>
    </>
  );
};
