import React, { forwardRef, ReactElement } from 'react';
import { TextInput, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { InputProps, InputPropsAllVariants, InputVariant } from './types';
import * as S from './styles';
import { Typography } from '~/components/@hello-ui';
import { CodeInput } from '~/components/@hello-ui/Input/components/CodeInput';
import { SimpleInput } from '~/components/@hello-ui/Input/components/SimpleInput';
import { MaskInput } from '~/components/@hello-ui/Input/components/MaskInput';

const InputComponent: React.ForwardRefRenderFunction<TextInput, InputProps> = (
  props,
  ref,
): JSX.Element => {
  const theme = useTheme();
  const { isRequired, label, variant = 'simple', ...restProps } = props;

  const variantsProps = restProps as InputPropsAllVariants;
  const { error } = variantsProps;

  const formattedLabel = label ? `${label} ${isRequired ? '*' : ''}` : undefined;

  const getInputComponent = () => {
    const componentByVariant: Record<InputVariant, ReactElement> = {
      simple: <SimpleInput {...variantsProps} ref={ref} />,
      mask: <MaskInput {...variantsProps} ref={ref} />,
      search: (
        <SimpleInput
          {...variantsProps}
          icon={{ item: 'search', position: 'left', size: 24 }}
          ref={ref}
        />
      ),
      code: <CodeInput {...variantsProps} />,
    };

    return componentByVariant[variant];
  };

  return (
    <View className="w-full">
      {formattedLabel && (
        <Typography
          variant="subtitle"
          color={variantsProps.isDisabled ? 'gray-disabled' : 'paragraph'}>
          {formattedLabel}
        </Typography>
      )}

      {variant === 'code' && getInputComponent()}
      {variant !== 'code' && (
        <>
          <S.WrapperContainer error={!!error} isDisabled={variantsProps.isDisabled}>
            {getInputComponent()}
          </S.WrapperContainer>
          <View className="mb-0 mt-4 h-24 mobile:mb-[3px] mobile:h-[18px]">
            <Typography variant="helperText" color={theme.colors.error}>
              {error}
            </Typography>
          </View>
        </>
      )}
    </View>
  );
};

export const Input = forwardRef(InputComponent);
