import React, { useCallback, useEffect, useState } from 'react';

import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/benefitsWomensHealth/desktop.png';
import mobileBackground from '../../assets/images/benefitsWomensHealth/mobile.png';
import { BellaIntegrationHealth } from '../BellaIntegrationHealth';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, Dialog, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { BenefitsWomensHealthIcon } from '~/components/Icons/BenefitsWomensHealthIcon';
import { useAuth } from '~/auth/legacy/useAuth';
import { useApi } from '~/hooks/api';

import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { extractDigits } from '~/utils/strings';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsWomensHealth = (): JSX.Element => {
  const api = useApi();
  const navigation = useNavigation();

  const { user } = useAuth();
  const { showSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState<boolean>(false);
  const [uri, setUri] = useState<string | null>(null);

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const validSlugs = [
    'habitos-saudaveis',
    'educando-com-afeto',
    'assistente-digital',
    'acolhimento-a-mulher',
  ];

  const handleMessage = (message: string) => {
    const slugMap: Record<string, string> = {
      'habitos-saudaveis': 'BenefitsHealthyHabits',
      'educando-com-afeto': 'BenefitsParentalAssistance',
      'assistente-digital': 'BenefitsDigitalFemaleAssistance',
      'acolhimento-a-mulher': 'BenefitsWelcomingWomen',
    };

    if (!validSlugs?.includes(message)) return;
    const redirectUrl = slugMap[message];
    setUri(null);
    navigation.navigate(redirectUrl);
  };

  const openViewBella = async () => {
    setLoading(true);
    await getUriBella();
    setLoading(false);
  };

  const getUriBella = useCallback(async () => {
    try {
      const { data } = await api.getWomensHealthBella(
        user?.name as string,
        user?.email ?? extractDigits(user?.cpf),
        (user?.telephone_1 ?? user?.telephone_2) as string,
        extractDigits(user?.cpf),
        user?.birthdate as string,
      );

      setUri(data.url);
    } catch (error) {
      showSweetAlert(
        'Falha na autenticação',
        'Por favor, entrar em contato com a Central de Atendimento',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: true,
        },
      );

      setUri(null);
    }
  }, [user]);

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <BenefitsWomensHealthIcon />,
          title: 'Saúde da Mulher',
          onBack: () => {
            navigation.navigate('BenefitsWoman');
          },
        }}>
        <>
          <View>
            <Typography variant="body2">
              Acompanhamento contínuo para mulheres que desejam apoio no{' '}
              <Typography variant="bodyHighlight2">cuidado com sua saúde.</Typography> Esse serviço
              oferece atendimento com enfermeiras e outros profissionais especializados no bem-estar
              feminino.
            </Typography>
          </View>
          <View className="pt-24">
            <Collapse title="Serviços oferecidos:">
              <View className="mb-8">
                <View className="mb-16 flex flex-row gap-4">
                  <Typography variant="bodyHighlight2">1.</Typography>
                  <Typography key={`key-${Math.random()}`} variant="body2">
                    <Typography variant="bodyHighlight2">Consultas por vídeo agendadas:</Typography>{' '}
                    crie uma agenda de atendimentos mensais para acompanhar sua saúde com
                    enfermeiras especialistas em saúde da mulher, obstetrícia, pediatria e
                    consultoria de amamentação.
                  </Typography>
                </View>
                <View className="flex flex-row gap-4">
                  <Typography variant="bodyHighlight2">2.</Typography>
                  <Typography key={`key-${Math.random()}`} variant="body2">
                    <Typography variant="bodyHighlight2">Pronto atendimento por vídeo:</Typography>{' '}
                    Conte com uma enfermeira disponível 24 horas para atendimento por chamada de
                    vídeo para apoiá-la em situações de emergência.
                  </Typography>
                </View>
              </View>
            </Collapse>
          </View>
          <MobileAutoSpace />
          <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
            <Typography variant="bodyHighlight2">Para utilizar o benefício,</Typography>
            <Typography variant="bodyHighlight2">acesse a plataforma:</Typography>
          </View>
          <Button
            variant="primary"
            onPress={() => {
              void openViewBella();
            }}
            loading={loading}>
            Acessar
          </Button>
        </>
      </PageWithCard>

      <Dialog
        visible={uri !== null}
        onClose={() => {
          setUri(null);
        }}>
        <BellaIntegrationHealth uri={uri} onMessage={handleMessage} />
      </Dialog>
    </>
  );
};
