import React, { forwardRef, Ref } from 'react';
import { TextInput, View } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { MaskInputProps } from '~/components/@hello-ui/Input/components/MaskInput/types';
import { InputIcon } from '~/components/@hello-ui/Input/components/InputIcon';
import { StyledTextInputMask } from '~/components/@hello-ui/Input/components/MaskInput/styles';
import { Loader } from '~/components/@hello-ui';

export const MaskInputComponent: React.ForwardRefRenderFunction<TextInput, MaskInputProps> = (
  props,
  ref,
) => {
  const { mask, icon, isDisabled, isLoading, ...inputProps } = props;

  return (
    <>
      <StyledTextInputMask
        {...inputProps}
        type="custom"
        editable={!isDisabled}
        options={{ mask }}
        ref={ref as Ref<TextInputMask>}
      />
      {isLoading ? (
        <View className="h-24, w-24">
          <Loader size={24} variant="circle-loader" />
        </View>
      ) : (
        icon && <InputIcon icon={icon} />
      )}
    </>
  );
};

export const MaskInput = forwardRef(MaskInputComponent);
