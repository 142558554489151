import React, { useEffect, useState } from 'react';
import { Typography } from '~/components/@tem-ui';
import * as S from './styles';
import { useTheme } from 'styled-components/native';

interface CustomInputType {
  onPress: () => void;
  placeholder: string;
  value?: string | undefined;
  placeholderColor?: string;
  color?: string;
  backgroundColor?: string;
  placeholderBackground?: string;
  disabled: boolean;
}

export const CustomInput = ({
  onPress,
  placeholder,
  value,
  placeholderColor,
  color,
  backgroundColor,
  placeholderBackground,
  disabled,
}: CustomInputType) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const filled = inputValue !== undefined && inputValue !== '';

  return (
    <S.ClickInputField onPress={onPress} disabled={disabled} backgroundColor={backgroundColor}>
      <S.ClickInputPlaceholder
        filled={filled}
        color={disabled ? theme.colors.black : placeholderColor}
        placeholderBackground={placeholderBackground}>
        {placeholder}
      </S.ClickInputPlaceholder>
      <Typography variant="body2" color={color ?? theme.colors.gray70}>
        {value ?? ''}
      </Typography>
    </S.ClickInputField>
  );
};
