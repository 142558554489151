import styled from 'styled-components/native';
import { Wrapper } from '../../components/@tem-ui';
import { Platform, ImageBackground } from 'react-native';

import BannerImageDesk from '../../assets/images/img_temfamiliar_desk.jpeg';

export const WrapperContent = styled.TouchableOpacity`
  width: 100%;
  height: 100%;
  padding: 20px;
  max-width: 1200px;
  justify-content: space-between;
`;

export const Container = styled(Wrapper)``;

export const WrapperBackground = styled(ImageBackground).attrs({
  source: BannerImageDesk,
})`
  max-width: 1240px;
  min-height: 568px;
  z-index: 1;
  width: 100%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    min-height: 200px;
    margin-top: 0px;
    height: 200px;
  `}
`;

export const WrapperBackButton = styled.TouchableOpacity`
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  margin-left: 16px;
  margin-top: 26px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-left: 8px;
  `}
`;

export const WrapperContainer = styled.View`
  width: 100%;
  max-height: 112px;
  margin: 0 auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
    height: 608px;
  `}
`;

export const WrapperTitle = styled.View`
  width: 100%;
  max-width: 712px;
  padding-horizontal: 16px;
  position: absolute;
  bottom: 40px;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    padding-horizontal: 106px;
  `}
`;

export const Title = styled.Text`
  color: ${({ theme: { colors } }) => colors.gray70};
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 20px;
    margin-bottom: 40px;
    height: 25px;
  `}
`;

export const Icon = styled.View`
  height: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WrapperDescription = styled.View`
  color: ${({ theme: { colors } }) => colors.black70};
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin: 6px 0;
  margin-top: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 40px;
  `}
`;

export const DescriptionText = styled.Text`
  color: ${({ theme: { colors } }) => colors.gray70};
  margin-bottom: 17px;
  font-size: 16px;
  width: 100%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-bottom: 10px;
  `}
`;

export const WrapperRectangle = styled.View`
  background-color: #fafafa;
  padding: 15px;
  border-radius: 10;
  margin-bottom: 30px;
  border: 1px solid #bdbdbd;
  margin-top: 20px;
  height: 280px;
`;

export const RectangleTitle = styled.Text`
  font-weight: 600;
`;

export const SignatureCancel = styled.View`
  padding-top: 40px;
  margin-right: 10px;
  flex-direction: row;
  width: 100%;
  max-width: 1020px;
  padding-horizontal: 32px;
  align-items: center;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 16px;
  `}
`;

export const SignatureDecorateCancel = styled.TouchableOpacity`
  ${({ theme: { colors } }) =>
    `
    text-decoration: underline ${colors.primary}
  `}
`;

export const WrapperBottom = styled.View`
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 88px;
`;

export const WrapperCard = styled.View`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 20px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
`}
`;

export const WrapperTextCards = styled.View`
  margin-right: 10px;
  flex-direction: row;
  width: 100%;
  max-width: 1020px;
  padding-horizontal: 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 16px;
  `}
`;

export const TextCard = styled.View`
  margin-horizontal: auto;
  text-align: left;
  width: 100%;
  padding: 20px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding-horizontal: 16px;
  `}
`;

export const WrapperCards = styled.View`
  margin: 0 0 0 80px;
  flex-direction: row;
  width: 100%;
  max-width: 1020px;
  padding-horizontal: 32px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: 20px 7px -60px 0px;
  `}
`;

export const KnowMore = styled.View`
  padding-top: 5px;
  flex-direction: row;
  width: 100%;
  max-width: 1020px;
`;

export const ArrowKnowMore = styled.Text`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    right: 0px;
    position: absolute;
`}
`;

export const WrapperText = styled.View`
  width: 116px;
  justify-content: flex-end;
  width: 100%;
`;

export const SignatureDeskCancel = styled.View`
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    display: none;
`}
`;

export const BackgroundContainer = styled.View`
  max-width: 1240px;
  ${Platform.OS === 'web' && 'width: calc(100% - 64px)'}
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 32px;
  overflow: hidden;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 0px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  `}
`;

export const SignatureMobileCancel = styled.View`
  display: none;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin: -30px 0 20px 10px;
    display: flex;
    padding-bottom: 30px;
`}
`;
