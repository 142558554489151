import React from 'react';
import { Circle, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const NewsIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 80 80" fill="none">
      <G filter="url(#filter0_d_10921_994)">
        <Circle cx="39" cy="39" r="32" fill="#EC6338" />
        <Circle cx="39" cy="39" r="29.8333" stroke="white" strokeWidth="4.33333" />
      </G>
      <Path
        d="M29.567 52.7157C29.7175 52.9182 29.9415 53.051 30.1891 53.0837C30.3738 53.1079 30.562 53.0752 30.7282 52.9895L39.0012 48.738L47.2742 52.9895C47.5845 53.1481 47.9557 53.118 48.2373 52.9113C48.5189 52.7046 48.6634 52.356 48.6127 52.0067L47.2186 42.6911L53.7253 36.0019C53.9752 35.7494 54.0635 35.3753 53.9533 35.0355C53.8431 34.6954 53.5533 34.4475 53.2047 34.3955L44.0766 32.8839L39.8192 24.5131C39.6599 24.1982 39.3404 24 38.9918 24C38.6435 24 38.324 24.1985 38.1647 24.5134L33.9073 32.8842L24.7792 34.3958C24.4338 34.4534 24.1496 34.7036 24.0437 35.0424C23.9377 35.3815 24.0277 35.752 24.2769 36.0019L30.7836 42.6911L29.3895 52.0067C29.3528 52.2576 29.4166 52.5133 29.567 52.7157Z"
        fill="white"
      />
    </Svg>
  );
};
