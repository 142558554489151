import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsInsuranceDeathDisability/desktop.png';
import mobileBackground from '../../assets/images/benefitsInsuranceDeathDisability/mobile.png';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';

import CircleGroup from '~/components/Icons/CircleGroup';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const BenefitsInsuranceDeathDisability = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <CircleGroup width={24} height={24} color={theme.colors.white} />,
        title: 'Morte e Invalidez por Acidente',
        onBack: () => {
          navigation.navigate('BenefitsInsurance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Seguro que garante o{' '}
          <Typography variant="bodyHighlight2">pagamento de indenização</Typography> em caso de
          acidente que ocasione a <Typography variant="bodyHighlight2">morte</Typography> ou{' '}
          <Typography variant="bodyHighlight2">lesão física permanente</Typography> sofrida pelo
          segurado.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Indenização garantida aos beneficiários legais ou ao segurando em acidente pessoal
            coberto, que ocasione a{' '}
            <Typography variant="bodyHighlight2">perda total, redução</Typography> ou{' '}
            <Typography variant="bodyHighlight2">impotência funcional definitiva</Typography> dos
            membros ou órgãos. O valor da indenização é variável de acordo com seu plano. Verifique
            sempre os termos e condições de uso do produto. Para consultar Condições Gerais do
            Seguro{' '}
            <Typography variant="link" onPress={() => navigation.navigate('TemPayDashboard')}>
              clique aqui
            </Typography>
            .
          </Typography>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício ligue:</Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007782052')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.gray70} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="subtitle">0800 778 2052</Typography>
        </View>
      )}
    </PageWithCard>
  );
};
