import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { ItemType, StepsProviderTypes, IStepperContext, StepSettings } from './types';
import { SizingInsets } from '~/components/@hello-ui/PageWithCard/types';

const StepperContext = createContext<IStepperContext | null>(null);

export const usePageWithCardSettings = ({
  scrollEnabled,
  overrideMaxBodyWidth,
  customPadding,
}: StepSettings) => {
  const { setScrollDisabled, setCustomPadding, setOverrideBodyMaxWidth } = useStepper();

  useLayoutEffect(() => {
    if (scrollEnabled !== undefined) {
      setScrollDisabled(!scrollEnabled);
    }

    if (customPadding !== undefined) {
      setCustomPadding(customPadding);
    }

    if (overrideMaxBodyWidth !== undefined) {
      setOverrideBodyMaxWidth(overrideMaxBodyWidth);
    }

    return () => {
      setScrollDisabled(false);
      setCustomPadding(undefined);
      setOverrideBodyMaxWidth(undefined);
    };
  }, []);
};

export const StepperProvider = ({ children }: StepsProviderTypes): JSX.Element => {
  const [stepper, setStepper] = useState<number | null>(null);
  const [steps, setStep] = useState<JSX.Element | JSX.Element[]>();
  const [currentStep, setCurrentStepState] = useState<number>(0);
  const [stepData, setActualStep] = useState<ItemType>();
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const [customPadding, setCustomPadding] = useState<undefined | SizingInsets>(undefined);
  const [overrideBodyMaxWidth, setOverrideBodyMaxWidth] = useState<string | undefined>(undefined);

  const setSteps = (steps: JSX.Element | JSX.Element[] | any[]) => {
    setStep(steps);
  };

  const toStepper = (step: number) => {
    setStepper(step);
  };

  const toCurrentStep = (step: number) => {
    setCurrentStepState(step);
  };

  const previousStep = () => {
    const previous = currentStep - 1;
    if (previous >= 0) {
      setCurrentStepState(previous);
    }
  };

  const nextStep = () => {
    const next = currentStep + 1;
    setCurrentStepState(next);
  };

  const finalizeSteps = () => {
    setStepper(null);
    setStep(<></>);
    setCurrentStepState(0);
    setActualStep(<></>);
  };

  const setStepData = (step: ItemType) => {
    setActualStep(step);
  };

  return (
    <StepperContext.Provider
      value={{
        steps,
        setSteps,
        currentStep,
        toStepper,
        stepper,
        previousStep,
        nextStep,
        finalizeSteps,
        setStepData,
        stepData,
        toCurrentStep,
        setCurrentStepState,
        scrollDisabled,
        setScrollDisabled,
        customPadding: customPadding,
        setCustomPadding: setCustomPadding,
        overrideBodyMaxWidth,
        setOverrideBodyMaxWidth,
      }}>
      {children}
    </StepperContext.Provider>
  );
};

export const useStepper = (): IStepperContext => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error('useStepper must be used within an StepperProvider');
  }

  return context;
};
