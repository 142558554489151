import styled from 'styled-components/native';

export const Wrapper = styled.View`
  background-color: ${({ theme: { colors } }) => colors.gray10};
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 30px;
`;

export const WrapperContent = styled.View`
  width: ${({ theme: { isMobile } }) => (isMobile ? '80%' : '50%')};
`;

export const AlertCard = styled.View`
  height: auto;
  background-color: ${({ theme: { colors } }) => colors.black8};
  padding: 15px;
  border-radius: 10;
  margin-bottom: 30px;
`;

export const WrapperDescription = styled.View`
  height: auto;
  margin-top: 40px;
  text-align: justify;
`;

export const WrapperButton = styled.View`
  margin-top: 50px;
`;
