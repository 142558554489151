import React from 'react';
import { G, Path, Rect, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const SchedulesIcon = ({ color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width="22" height="20" viewBox="0 0 22 20" fill="none">
      <G id="Group 1077">
        <Rect
          id="Rectangle 356"
          x="1"
          y="3"
          width="20"
          height="16"
          rx="1"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.3"
          strokeLinejoin="round"
        />
        <Path id="Vector 1" d="M1 8H21" stroke={color ?? theme.colors.primary} strokeWidth="1.3" />
        <Path
          id="Vector 2"
          d="M6 1L6 5"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.3"
          strokeMiterlimit="2.3662"
          strokeLinecap="round"
        />
        <Path
          id="Vector 3"
          d="M16 1L16 5"
          stroke={color ?? theme.colors.primary}
          strokeWidth="1.3"
          strokeMiterlimit="2.3662"
          strokeLinecap="round"
        />
        <Path id="Vector 4" d="M7 11L7 16" stroke={color ?? theme.colors.primary} strokeWidth="5" />
      </G>
    </Svg>
  );
};

export default SchedulesIcon;
