import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';
import { IOlimpoRes } from '~/services/api/olimpo/interfaces/olimpo-res';
import { IProductConfig } from '~/@types/auth/product';
import { IGetPartnerRes } from '~/services/api/olimpo/interfaces/partners-domain';

export class PartnersDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async getPartner(slug: string): Promise<IProductConfig> {
    const res = await this.olimpoService.axios.get<IOlimpoRes<IGetPartnerRes>>(
      `/v1/hello/partners/${slug}`,
    );

    return {
      id: res.data.data.id,
      slug: res.data.data.slug,
      onixCode: res.data.data.onix_code,
      themeConfig: {
        colors: {
          primary: res.data.data.theme_config.colors.primary,
          secondary: res.data.data.theme_config.colors.secondary,
          contrastText: res.data.data.theme_config.colors.contrast_text,
          primaryAlt: res.data.data.theme_config.colors.primary_alt,
        },
        logo: res.data.data.theme_config.logo,
        dependentsLimit: res.data.data.theme_config.dependents_limit,
        dependentsMaxAge: res.data.data.theme_config.dependents_max_age,
        partnerFullName: res.data.data.theme_config.partner_full_name,
        partnerName: res.data.data.theme_config.partner_name,
        footerPhones: res.data.data.theme_config.footer_phones,
        socialLinks: res.data.data.theme_config.social_links,
        footerDisclaimers: res.data.data.theme_config.footer_disclaimers,
        cardDesignUrl: res.data.data.theme_config.card_design_url,
        copyrightText: res.data.data.theme_config.copyright_text,
        temPayLogoUrl: res.data.data.theme_config.tem_pay_logo_url,
        temPayHeaderLogoUrl: res.data.data.theme_config.tem_pay_header_logo_url,
        payName: res.data.data.theme_config.pay_name,
      },
      dependentFeatures: res.data.data.dependent_features,
      features: res.data.data.features,
      funeralProvider: res.data.data.funeral_provider,
      medicineDiscountProvider: res.data.data.medicine_discount_provider,
      pureTem: res.data.data.pure_tem ?? false,
    };
  }
}
